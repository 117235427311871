import { createSelector } from "@reduxjs/toolkit";
import { constants } from "@qgiv/core-js";

const {
    ENUMS: { FieldType },
} = constants;

export const selectReceiptSelector = createSelector(
    [(state) => state.currentReceipt || "", (state) => state.receipts],
    (currentReceipt, receipts) => receipts[currentReceipt] || {},
);

export const selectReceiptFieldsSelector = createSelector(
    [(state) => state.currentReceipt || "", (state) => state.receipts],
    (currentReceipt, receipts) => {
        const allTransactionFields =
            receipts[currentReceipt].transaction.fields;

        const fieldsWithoutHidden = allTransactionFields.filter(
            (transactionField) =>
                Number(transactionField.type) !== FieldType.HIDDEN_FIELD,
        );

        return fieldsWithoutHidden;
    },
);
