import React from "react";
import { PropTypes } from "prop-types";
import { LoadingDots } from "@qgiv/core-react";
import ConnectedPayThisInvoice from "./ConnectedPayThisInvoice";
import ConnectedPayOutStandingInvoices from "./ConnectedPayOutstandingInvoices";
import ConnectedPayFullInvoiceBalance from "./ConnectedPayFullInvoiceBalance";

/**
 * @param {object} props - Component properties
 * @param {number} props.controlId - The control ID for the component
 * @param {Function} props.handleSubtotalChange - Function to handle subtotal changes
 * @param {boolean} props.hasOutstandingInvoices - Flag to indicate if there are outstanding invoices
 * @param {boolean} props.hasFullInvoiceBalance - Flag to indicate if there is a full invoice balance
 * @param {boolean} props.displaySendingToReceipt - Flag to indicate we are redirecting to confirmation page
 * @returns {React.ReactElement} The DonatedGiftInvoiceTabs component.
 */
const DonatedGiftInvoiceTabs = ({
    controlId,
    handleSubtotalChange,
    hasOutstandingInvoices,
    hasFullInvoiceBalance,
    displaySendingToReceipt,
}) =>
    displaySendingToReceipt ? (
        <div className="col col--12">
            <div className="-text--center">
                <LoadingDots height={32} />
                <p className="ignore-typography -type--xlarge -margin-top--30">
                    You&apos;ve paid this invoice! <br />
                    Getting your receipt...
                </p>
            </div>
        </div>
    ) : (
        <div className="col col--12">
            {/* eslint-disable-next-line tailwindcss/classnames-order */}
            <div className="grid -margin-top--15">
                <div className="col col--12">
                    <ConnectedPayThisInvoice
                        handleSubtotalChange={handleSubtotalChange}
                        controlId={controlId}
                    />
                    {hasOutstandingInvoices && (
                        <div className="col col--12 col--no-gutter -margin-top--15">
                            <ConnectedPayOutStandingInvoices
                                handleSubtotalChange={handleSubtotalChange}
                                controlId={controlId}
                            />
                        </div>
                    )}
                    {hasFullInvoiceBalance && (
                        <div className="col col--12 col--no-gutter -margin-top--15">
                            <ConnectedPayFullInvoiceBalance
                                handleSubtotalChange={handleSubtotalChange}
                                controlId={controlId}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

DonatedGiftInvoiceTabs.propTypes = {
    controlId: PropTypes.number.isRequired,
    displaySendingToReceipt: PropTypes.bool.isRequired,
    handleSubtotalChange: PropTypes.func.isRequired,
    hasOutstandingInvoices: PropTypes.bool.isRequired,
    hasFullInvoiceBalance: PropTypes.bool.isRequired,
};

export default DonatedGiftInvoiceTabs;
