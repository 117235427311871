import React from "react";
import PropTypes from "prop-types";
import { useBreakpoints } from "@qgiv/core-react";
import StandardAmountCard from "./StandardAmountCard";

const ConnectedStandardAmountCard = ({
    checked,
    descr = "",
    donationAmountAlignment,
    image = {},
    label,
    title = "",
}) => {
    const { isLessThanExtraSmallMobile } = useBreakpoints();

    const displayDescriptionOnNewLine = isLessThanExtraSmallMobile;
    const showTitle = title.length > 0;
    const showImage = !!image && image.url?.length > 0;

    let amountAlignment = "right";

    if (donationAmountAlignment) {
        amountAlignment = donationAmountAlignment;
    }
    if (!title && !descr) {
        amountAlignment = "centered";
    }

    const standardAmountCardProps = {
        checked,
        descr,
        image,
        displayDescriptionOnNewLine,
        label,
        showImage,
        showTitle,
        amountAlignment,
        title,
    };

    return <StandardAmountCard {...standardAmountCardProps} />;
};

ConnectedStandardAmountCard.propTypes = {
    checked: PropTypes.bool.isRequired,
    descr: PropTypes.string,
    donationAmountAlignment: PropTypes.string.isRequired,
    image: PropTypes.shape({ url: PropTypes.string }),
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default ConnectedStandardAmountCard;
