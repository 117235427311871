import { constants } from "@qgiv/core-js";

const { ENUMS } = constants;

export const defaultDonationAmountsOneTime = [
    {
        id: 1234,
        org: 21213,
        form: 12345,
        amount: 15,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.CUSTOM,
        image: "",
        displayForOneTime: true,
        displayForRecurring: false,
    },
    {
        id: 1235,
        org: 21213,
        form: 12345,
        amount: 25,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.CUSTOM,
        image: "",
        displayForOneTime: true,
        displayForRecurring: false,
    },
];

export const defaultDonationAmountsRecurring = [
    {
        id: 1236,
        org: 21213,
        form: 12345,
        amount: 50,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.CUSTOM,
        image: "",
        displayForOneTime: false,
        displayForRecurring: true,
    },
    {
        id: 1237,
        org: 21213,
        form: 12345,
        amount: 75,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.CUSTOM,
        image: "",
        displayForOneTime: false,
        displayForRecurring: true,
    },
];

const bothOneAndRecurring = [
    {
        id: 1260,
        org: 21213,
        form: 12345,
        amount: 60,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.CUSTOM,
        image: "",
        displayForOneTime: true,
        displayForRecurring: true,
    },
];

export const defaultDonationAmountsWithExtraContent = [
    {
        id: 1238,
        org: 21213,
        form: 12345,
        amount: 100,
        name: "Additional Content Example",
        descr: "Description goes here",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.CUSTOM,
        image: "",
        displayForOneTime: false,
        displayForRecurring: true,
    },
];

export const defaultDonationAmountsWithImageOnly = [
    {
        id: 1238,
        org: 21213,
        form: 12345,
        amount: 250,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.CUSTOM,
        image: "https://a-z-animals.com/media/2019/11/Chipmunk-eating.jpg",
        displayForOneTime: false,
        displayForRecurring: true,
    },
];

export const defaultOtherAmountType = [
    {
        id: 1230,
        org: 21213,
        form: 12345,
        amount: 0,
        name: "Other",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.OTHER,
        image: "",
        displayForOneTime: true,
        displayForRecurring: true,
    },
];

export const defaultOtherAmountTypeLongLabel = [
    {
        id: 1230,
        org: 21213,
        form: 12345,
        amount: 0,
        name: "Give Another Amount",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.OTHER,
        image: "",
        displayForOneTime: true,
        displayForRecurring: true,
    },
];

export const defaultRecurringOnlyHaveExtraContent = [
    {
        id: 1230,
        org: 21213,
        form: 12345,
        amount: 0,
        name: "Give Another Amount",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: 2,
        image: "",
        displayForOneTime: false,
        displayForRecurring: true,
    },
    {
        id: 1230,
        org: 21213,
        form: 12345,
        amount: 0,
        name: "",
        descr: "This is a description",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: 2,
        image: "",
        displayForOneTime: false,
        displayForRecurring: true,
    },
    {
        id: 1238,
        org: 21213,
        form: 12345,
        amount: 250,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: 1,
        image: "https://a-z-animals.com/media/2019/11/Chipmunk-eating.jpg",
        displayForOneTime: false,
        displayForRecurring: true,
    },
    {
        id: 1239,
        org: 21213,
        form: 12345,
        amount: 25,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: 1,
        image: "",
        displayForOneTime: true,
        displayForRecurring: false,
    },
    {
        id: 1240,
        org: 21213,
        form: 12345,
        amount: 25,
        name: "",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: 1,
        image: "",
        displayForOneTime: true,
        displayForRecurring: false,
    },
];

export const defaultDonationAmountsBoth = [
    ...defaultDonationAmountsOneTime,
    ...defaultDonationAmountsRecurring,
];

export const defaultActiveAmountsWithExtraContent = [
    ...defaultDonationAmountsBoth,
    ...defaultDonationAmountsWithExtraContent,
];

export const defaultActiveAmountsWithImage = [
    ...defaultDonationAmountsBoth,
    ...defaultDonationAmountsWithImageOnly,
];

export const defaultActiveAmountsWithOther = [
    ...defaultDonationAmountsBoth,
    ...defaultOtherAmountType,
];

export const defaultActiveAmountsWithOtherLongLabel = [
    ...defaultDonationAmountsBoth,
    ...defaultOtherAmountTypeLongLabel,
];

export const defaultActiveAmountsWithOtherAndSame = [
    ...defaultActiveAmountsWithOther,
    ...bothOneAndRecurring,
];

export const defaultOtherDisplayVariations = [
    {
        id: 1230,
        org: 21213,
        form: 12345,
        amount: 0,
        name: "Other 1",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.OTHER,
        image: "",
        displayForOneTime: false,
        displayForRecurring: true,
    },
    {
        id: 1230,
        org: 21213,
        form: 12345,
        amount: 0,
        name: "Other 2",
        descr: "",
        weight: 0,
        status: 1,
        created: "0000-00-00 00:00:00",
        lastAction: "0000-00-00 00:00:00",
        amountType: ENUMS.ItemType.OTHER,
        image: "",
        displayForOneTime: true,
        displayForRecurring: false,
    },
];

export const defaultFormikOneTimeStandard = { Selected_One_Time_Id: "1234" };

export const defaultFormikRecurringStandard = { Selected_Recurring_Id: "1236" };

export const defaultFormikOneTimeOther = { Selected_One_Time_Id: "1230" };

export const defaultFormikRecurringOther = { Selected_Recurring_Id: "1230" };

export const defaultFormikOneTimeWithEmptyRecurring = {
    Selected_One_Time_Id: "1260",
    Selected_Recurring_Id: "",
};

export const defaultFormikRecurringWithEmptyOneTime = {
    Selected_One_Time_Id: "",
    Selected_Recurring_Id: "1260",
};

export const defaultFormikBothSet = {
    Selected_One_Time_Id: "1230",
    Selected_Recurring_Id: "1260",
};
