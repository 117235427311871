import {
    Yup,
    VALIDATION_ERROR_MESSAGE,
    getFieldValidation,
    getAddress2Validations,
    getMatchingGiftValidations,
    getCaptchaFieldValidation,
    getDedicationValidations,
} from "@qgiv/core-validation";
import { constants } from "@qgiv/core-js";
import {
    isMailingFieldGroupEnabled,
    getAllCustomFieldValidation,
} from "@qgiv/core-donor";

const getTitleFieldValidation = ({ titleFieldIsRequired, total, label }) => {
    const validation = {
        Title: Yup.string().when("Company_Donation", {
            is: false,
            then: Yup.string().required(
                `${label} is ${VALIDATION_ERROR_MESSAGE.REQUIRED}`,
            ),
            otherwise: Yup.string(),
        }),
    };
    return getFieldValidation(titleFieldIsRequired, total, validation);
};

const getNamesValidation = ({
    titleFieldIsRequired,
    total,
    requireNameForCompanyDonation,
    fieldLabels,
    FieldType,
}) => ({
    First_Name: Yup.string().when("Company_Donation", {
        is: true,
        then: requireNameForCompanyDonation
            ? Yup.string()
                  .required(
                      `${fieldLabels[FieldType.FIRST_NAME]} is ${
                          VALIDATION_ERROR_MESSAGE.REQUIRED
                      }`,
                  )
                  .validateIsNotACreditCardNumberString()
            : Yup.string().validateIsNotACreditCardNumberString(),
        otherwise: Yup.string()
            .required(
                `${fieldLabels[FieldType.FIRST_NAME]} is ${
                    VALIDATION_ERROR_MESSAGE.REQUIRED
                }`,
            )
            .validateIsNotACreditCardNumberString(),
    }),
    Last_Name: Yup.string().when("Company_Donation", {
        is: true,
        then: requireNameForCompanyDonation
            ? Yup.string()
                  .required(
                      `${fieldLabels[FieldType.LAST_NAME]} is ${
                          VALIDATION_ERROR_MESSAGE.REQUIRED
                      }`,
                  )
                  .validateIsNotACreditCardNumberString()
            : Yup.string().validateIsNotACreditCardNumberString(),
        otherwise: Yup.string()
            .required(
                `${fieldLabels[FieldType.LAST_NAME]} is ${
                    VALIDATION_ERROR_MESSAGE.REQUIRED
                }`,
            )
            .validateIsNotACreditCardNumberString(),
    }),
    ...getTitleFieldValidation({
        titleFieldIsRequired,
        total,
        label: fieldLabels[FieldType.SALUTATION],
    }),
});

const getCompanyValidation = ({ fieldLabels, FieldType }) => {
    const companyFieldLabel = fieldLabels[FieldType.COMPANY];
    return {
        Company: Yup.string().when("Company_Donation", {
            is: true,
            then: Yup.string().required(
                `${companyFieldLabel} is ${VALIDATION_ERROR_MESSAGE.REQUIRED}`,
            ),
            otherwise: Yup.string(),
        }),
    };
};

const getMailingAddressValidation = ({
    total,
    mailingAddressIsDisplayable,
    hideZeroDollarMailingAddress,
    fieldLabels,
    FieldType,
    allFieldsAndGroups,
}) => {
    const mailingAddressGroupIsActive =
        isMailingFieldGroupEnabled(allFieldsAndGroups);
    const removeMailingAddressDisplay =
        (total === 0 && hideZeroDollarMailingAddress) ||
        !mailingAddressGroupIsActive;

    if (!mailingAddressIsDisplayable.exists || removeMailingAddressDisplay) {
        return {};
    }

    return {
        ...getAddress2Validations(),
        Address: Yup.string()
            .required(
                `${fieldLabels[FieldType.ADDRESS]} is ${
                    VALIDATION_ERROR_MESSAGE.REQUIRED
                }`,
            )
            .validateIsNotACreditCardNumberString(),
        City: Yup.string().required(
            `${fieldLabels[FieldType.CITY]} is ${
                VALIDATION_ERROR_MESSAGE.REQUIRED
            }`,
        ),
        State: Yup.string().when(["Country"], {
            is: (Country) => Country === "US",
            then: Yup.string().required(
                `${fieldLabels[FieldType.STATE]} is ${
                    VALIDATION_ERROR_MESSAGE.REQUIRED
                }`,
            ),
            otherwise: Yup.string(),
        }),
        Zip: Yup.string().when(["Country"], {
            is: (Country) => Country === "US",
            then: Yup.string()
                .required(
                    `${fieldLabels[FieldType.ZIP]} is ${
                        VALIDATION_ERROR_MESSAGE.REQUIRED
                    }`,
                )
                .matches(/^[0-9]+$/, VALIDATION_ERROR_MESSAGE.ZIP)
                .min(5, VALIDATION_ERROR_MESSAGE.ZIP)
                .max(5, VALIDATION_ERROR_MESSAGE.ZIP),
            otherwise: Yup.string(),
        }),
        Country: Yup.string().required(
            `${fieldLabels[FieldType.COUNTRY]} is ${
                VALIDATION_ERROR_MESSAGE.REQUIRED
            }`,
        ),
    };
};

const getLegalFieldValidation = ({ hasLegalField }) => {
    if (hasLegalField) {
        return {
            Legal: Yup.boolean().oneOf([true], "This checkbox is required."),
        };
    }
    return {};
};

const getEmailValidation = ({ hasEmailField }) => {
    if (hasEmailField) {
        return {
            Email: Yup.string()
                .email()
                .required(VALIDATION_ERROR_MESSAGE.EMAIL_FORMAT),
        };
    }
    return {};
};

const getPhoneFieldValidation = ({
    phoneFieldIsRequired,
    total,
    fieldLabels,
    FieldType,
}) => {
    const phoneLabel = fieldLabels[FieldType.SYSTEM_PHONE];
    const phoneErrorMessage = `${phoneLabel} is ${VALIDATION_ERROR_MESSAGE.REQUIRED}`;
    const baseValidation = Yup.string().when("Country", {
        is: (Country) => Country === "US",
        then: Yup.string().validatePhoneString(),
        otherwise: Yup.string().validateInternationalPhoneString(),
    });
    const requiredValidation = baseValidation.required(phoneErrorMessage);
    return getFieldValidation(
        phoneFieldIsRequired,
        total,
        // required validation
        { Phone: requiredValidation },
        // formatting optional field validation
        { Phone: baseValidation },
    );
};

const getEmployerFieldValidation = ({
    employerFieldIsRequired,
    total,
    fieldLabels,
    FieldType,
}) => {
    const employerFieldLabel = fieldLabels[FieldType.EMPLOYER];
    const validation = {
        Employer: Yup.string().when("Company_Donation", {
            is: false,
            then: Yup.string().required(
                `${employerFieldLabel} is ${VALIDATION_ERROR_MESSAGE.REQUIRED}`,
            ),
            otherwise: Yup.string(),
        }),
    };

    return getFieldValidation(employerFieldIsRequired, total, validation);
};

export const getDetailsPageValidations = (validationSettings) => {
    const {
        ENUMS: { FieldType, EntityType },
    } = constants;

    const {
        allFieldsAndGroups,
        allFieldsInSystemFields,
        conditionalLogicOptions,
        currentDisplay,
        dedicationSettings,
        detailsPageFieldsData = {},
        Has_Dedication,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        hideZeroDollarMailingAddress = false,
        matchingRatio,
        requireNameForCompanyDonation,
        shouldCaptchaBeRequired,
        subtotal,
        total = 0,
        visibleDetailsPageCustomFields,
    } = validationSettings;

    const {
        employerFieldIsRequired,
        hasEmailField,
        hasLegalField,
        mailingAddressIsDisplayable,
        phoneFieldIsRequired,
        titleFieldIsRequired,
    } = detailsPageFieldsData;

    const fieldLabels = {};
    allFieldsInSystemFields.forEach((_field) => {
        fieldLabels[_field.type] = _field.display_text;
    });

    const detailsSchema = {
        ...getNamesValidation({
            requireNameForCompanyDonation,
            titleFieldIsRequired,
            total,
            fieldLabels,
            FieldType,
        }),
        ...getCompanyValidation({
            fieldLabels,
            FieldType,
        }),
        // need to work on validation being dependant on display settings
        ...getEmailValidation({
            hasEmailField,
        }),
        ...getMailingAddressValidation({
            total,
            mailingAddressIsDisplayable,
            hideZeroDollarMailingAddress,
            fieldLabels,
            FieldType,
            allFieldsAndGroups,
            EntityType,
        }),
        ...getLegalFieldValidation({ hasLegalField }),
        ...getPhoneFieldValidation({
            phoneFieldIsRequired,
            total,
            fieldLabels,
            FieldType,
        }),
        ...getEmployerFieldValidation({
            employerFieldIsRequired,
            total,
            fieldLabels,
            FieldType,
        }),
        ...getCaptchaFieldValidation(shouldCaptchaBeRequired),
        ...getAllCustomFieldValidation({
            allFieldsAndGroups,
            allCustomFields: visibleDetailsPageCustomFields,
            options: conditionalLogicOptions,
            savedValues: {},
            total,
        }),
        ...(hasDisplayableMatchingGiftOnThisPage && {
            ...getMatchingGiftValidations(matchingRatio, subtotal),
        }),
        ...(hasDisplayableDedicationOnThisPage && {
            ...getDedicationValidations({
                dedicationSettings,
                currentDisplay,
                Has_Dedication,
            }),
        }),
    };

    return detailsSchema;
};

const getValidationSchema = (validationSettings) => {
    const validationShape = {
        ...getDetailsPageValidations(validationSettings),
    };
    const schema = Yup.object().shape(validationShape);

    return schema;
};

export default getValidationSchema;
