import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import countries from "country-region-data/data.json";

import {
    constants,
    translateOldFieldType,
    decodeHTMLEntities,
    getRegions,
} from "@qgiv/core-js";

import { Icon } from "@qgiv/core-react";
import { Checkbox, CheckboxGroup } from "../Fields/Checkbox";
import { Radio, RadioGroup } from "../Fields/Radio";
import { Select } from "../Fields/Select";
import TextField from "../Fields/TextField";
import ExpandableTextContainer from "../ExpandableTextContainer";
import DetailsStepAnonymity from "../DetailsStep/DetailsStepAnonymity";
import { PrivacyOptionsCheckbox } from "../PrivacyOptions";

const QFieldInput = ({
    errors,
    field,
    handleChange,
    setFieldValue,
    touched,
    values,
    useFormikRequired = true,
    preventDescText = false,
    privacyCheckboxProps,
    donationAmountFromRedux = 0,
    ...props
}) => {
    const { FieldType, FieldContext, TicketDeliveryType } = constants.ENUMS;
    const { billingCountryDefault } = props;

    const resetStateFieldOrBillingStateValue = (stateFieldPrefix = "") => {
        const baseStateFieldName = "State";
        const stateFieldName =
            stateFieldPrefix.length > 0
                ? `${stateFieldPrefix}_${baseStateFieldName}`
                : baseStateFieldName;
        // Reset the State field if the Country field was changed or the
        // Billing_State field if the Billing_Country was changed
        setFieldValue(stateFieldName, "");
    };

    const _handleChange = (e, QMask) => {
        const currentTarget = e.currentTarget || QMask.el.input;
        const { name } = currentTarget;
        let value = "";
        if (QMask) {
            value = QMask.unmaskedValue;
        } else {
            value = currentTarget.value;
        }
        setFieldValue(name, value);

        if (
            typeof handleChange === "function" &&
            typeof QMask === "undefined"
        ) {
            handleChange(e);
        }

        // Located after handleChange to ensure that this update is not
        // unintentionally overwritten by handleChange logic
        if (name === "Country" || name === "Billing_Country") {
            const stateFieldPrefix = name.includes("Billing") ? "Billing" : "";
            resetStateFieldOrBillingStateValue(stateFieldPrefix);
        }
    };

    // Uses the required field setting, the amount that can be associated with
    // that setting and the donation amount entered by the user to determine
    // whether or not a field is required. Note: this is not used for validation
    // but for "Optional" display and ariaRequired.
    const getIsFieldRequired = (_useFormikRequired) => {
        // If the yes radio button or the required if donation is above radio
        // buttons have been checked
        if (field.required === "1" || field.required === true) {
            if (_useFormikRequired) {
                // Set amounts to 0 if initial value is ""
                const donationAmount = parseFloat(values.Donation_Amount) || 0;
                const amountRequired = parseFloat(field.amtReq) || 0;
                const hasCheckedYes = amountRequired === 0;
                const hasInitiatedRequirementDonation =
                    !hasCheckedYes && donationAmount > amountRequired;
                // PDR stores total of donation in redux
                // need to evaluate that value as well for Optional display
                const hasInitiatedRequirementTotalFromRedux =
                    !hasCheckedYes && donationAmountFromRedux > amountRequired;

                // The yes radio button has been checked or the donation amount
                // exceeds the amount necessary to trigger the requirement
                if (
                    hasCheckedYes ||
                    hasInitiatedRequirementDonation ||
                    hasInitiatedRequirementTotalFromRedux
                ) {
                    return true;
                }
            } else {
                // This field does not come from a formik setup that
                // contains "Donation_Amount" and/or does not care about
                // amount required such as from Event Redesign.
                return true;
            }
        }

        return false;
    };

    const isFieldRequired = getIsFieldRequired(useFormikRequired);
    const ariaRequired = isFieldRequired ? "true" : "false";
    const descText = isFieldRequired || preventDescText ? "" : "Optional";
    const inputProps = {
        ...props,
        ariaRequired,
        descText,
        handleChange: _handleChange,
        label: field.display_text ? field.display_text : field.description,
        helpText:
            field.helpText && field.helpText.length > 0
                ? field.helpText
                : undefined,
        maxChar: field.maxChar ? field.maxChar : undefined,
    };

    const fieldType = translateOldFieldType(field.type);
    // fieldNamePath is the unique identifier for custom fields
    // on event redesign. If this key exists on the field, we
    // need to use it for formik integration.
    const hasFieldNamePath = !!field?.fieldNamePath;
    const fieldId = hasFieldNamePath ? field.fieldNamePath : field.id;
    // Field props for any field that's not a system field
    if (Number(field.contextId) !== FieldContext.SYSTEM_FIELD) {
        inputProps.id = fieldId;
        inputProps.name = `${fieldId}`;
        inputProps.error =
            touched[fieldId] && errors[fieldId] && errors[fieldId].length > 0;
        inputProps.errorText = errors[fieldId];
        if (Number(fieldType) === FieldType.CHECKBOX) {
            inputProps.checked = values[fieldId];
        } else {
            inputProps.value = values[`${fieldId}`];
        }
    }

    const [regions, setRegions] = useState([]);
    const [billingRegions, setBillingRegions] = useState([]);

    useEffect(() => {
        let apiMounted = true;
        if (String(field.type) === String(FieldType.STATE)) {
            getRegions(values.Country, field).then((res) => {
                if (apiMounted) {
                    setRegions(res.data);
                }
            });
        }
        return () => {
            apiMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.Country]);

    useEffect(() => {
        let apiMounted = true;
        if (String(field.type) === String(FieldType.BILLING_STATE)) {
            getRegions(values.Billing_Country, field).then((res) => {
                if (apiMounted) {
                    setBillingRegions(res.data);
                }
            });
        }
        return () => {
            apiMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.Billing_Country]);

    const getFieldComponent = () => {
        switch (parseInt(fieldType, 10)) {
            case FieldType.SHORT_TEXT:
            case FieldType.ORGANIZATION:
            case FieldType.SMSPLEDGETAG:
            case FieldType.DEDICATION:
                return <TextField type="text" {...inputProps} />;
            case FieldType.ANONYMOUS: {
                const { display_text } = field;
                return (
                    <DetailsStepAnonymity
                        {...inputProps}
                        values={values}
                        display_text={display_text}
                    />
                );
            }
            case FieldType.ADDRESS:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="address-line1"
                        name="Address"
                        value={values.Address || ""}
                        error={
                            touched.Address &&
                            errors.Address &&
                            errors.Address.length > 0
                        }
                        errorText={errors.Address}
                    />
                );
            case FieldType.BILLING_ADDRESS:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="address-line1"
                        name="Billing_Address"
                        value={values.Billing_Address || ""}
                        error={
                            touched.Billing_Address &&
                            errors.Billing_Address &&
                            errors.Billing_Address.length > 0
                        }
                        errorText={errors.Billing_Address}
                    />
                );
            case FieldType.ADDRESS_2:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="address-line2"
                        ariaLabel="Address Line 2, Apartment Suite Etc."
                        name="Address_2"
                        value={values.Address_2 || ""}
                        error={
                            touched.Address_2 &&
                            errors.Address_2 &&
                            errors.Address_2.length > 0
                        }
                        errorText={errors.Address_2}
                    />
                );
            case FieldType.BILLING_ADDRESS_2:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="address-line2"
                        name="Billing_Address_2"
                        value={values.Billing_Address_2 || ""}
                        error={
                            touched.Billing_Address_2 &&
                            errors.Billing_Address_2 &&
                            errors.Billing_Address_2.length > 0
                        }
                        errorText={errors.Billing_Address_2}
                    />
                );
            case FieldType.CITY:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="address-level2"
                        name="City"
                        value={values.City || ""}
                        error={
                            touched.City &&
                            errors.City &&
                            errors.City.length > 0
                        }
                        errorText={errors.City}
                    />
                );
            case FieldType.BILLING_CITY:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="address-level2"
                        name="Billing_City"
                        value={values.Billing_City || ""}
                        error={
                            touched.Billing_City &&
                            errors.Billing_City &&
                            errors.Billing_City.length > 0
                        }
                        errorText={errors.Billing_City}
                    />
                );
            case FieldType.BILLING_NAME:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="cc-name"
                        name="Billing_Name"
                        value={values.Billing_Name || ""}
                        error={
                            touched.Billing_Name &&
                            errors.Billing_Name &&
                            errors.Billing_Name.length > 0
                        }
                        errorText={errors.Billing_Name}
                    />
                );
            case FieldType.EMAIL_ADDRESS:
                return (
                    <TextField
                        {...inputProps}
                        type="email"
                        autoComplete="email"
                    />
                );
            case FieldType.SYSTEM_EMAIL: {
                const { display_text } = field;
                return (
                    <TextField
                        {...inputProps}
                        type="email"
                        autoComplete="email"
                        name="Email"
                        label={display_text}
                        value={values.Email || ""}
                        error={
                            touched.Email &&
                            errors.Email &&
                            errors.Email.length > 0
                        }
                        errorText={errors.Email}
                    />
                );
            }
            case FieldType.EVENT_EMAIL: {
                const { ticketDeliveryType, name, enableGuestManagement } =
                    props;
                const {
                    display_text = "",
                    display_text_individuals = "",
                    help_text_individuals = "",
                    helpText = "",
                } = field;

                const renderEventEmailLabel = () =>
                    enableGuestManagement &&
                    ticketDeliveryType === TicketDeliveryType.INDIVIDUALS ? (
                        <>
                            <Icon
                                classNames={[
                                    "-margin-right--10",
                                    "-type--xlarge",
                                ]}
                                glyph="paper-plane-duotone"
                                type="FontAwesome"
                            />
                            <span className="-padding-right--10">
                                {display_text_individuals}
                            </span>
                        </>
                    ) : (
                        display_text
                    );
                const helperText =
                    enableGuestManagement &&
                    ticketDeliveryType === TicketDeliveryType.INDIVIDUALS
                        ? help_text_individuals
                        : helpText;

                return (
                    <TextField
                        {...inputProps}
                        type="email"
                        autoComplete="email"
                        value={values[name] || ""}
                        label={renderEventEmailLabel()}
                        helpText={helperText}
                        error={
                            touched[name] &&
                            errors[name] &&
                            errors[name].length > 0
                        }
                        errorText={errors[name]}
                    />
                );
            }
            case FieldType.WORK_EMAIL: {
                const { display_text } = field;
                return (
                    <TextField
                        {...inputProps}
                        type="email"
                        autoComplete="email"
                        name="Work_Email"
                        label={display_text}
                        value={values.Work_Email || ""}
                        error={
                            touched.Work_Email &&
                            errors.Work_Email &&
                            errors.Work_Email.length > 0
                        }
                        errorText={errors.Work_Email}
                    />
                );
            }
            case FieldType.COMPANY: {
                const { display_text } = field;
                return (
                    <TextField
                        {...inputProps}
                        name="Company"
                        label={display_text}
                        value={values.Company}
                        error={
                            touched.Company &&
                            errors.Company &&
                            errors.Company.length > 0
                        }
                        errorText={errors.Company}
                    />
                );
            }
            case FieldType.EMPLOYER: {
                const { display_text = "Employer" } = field;
                return (
                    <TextField
                        {...inputProps}
                        name="Employer"
                        label={display_text}
                        type="text"
                        autoComplete="organization"
                        value={values.Employer || ""}
                        error={
                            touched.Employer &&
                            errors.Employer &&
                            errors.Employer.length > 0
                        }
                        errorText={errors.Employer}
                    />
                );
            }
            case FieldType.FIRST_NAME:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="given-name"
                        name="First_Name"
                        value={values.First_Name || ""}
                        error={
                            touched.First_Name &&
                            errors.First_Name &&
                            errors.First_Name.length > 0
                        }
                        errorText={errors.First_Name}
                    />
                );
            case FieldType.EVENT_FIRST_NAME: {
                const { name } = props;
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="given-name"
                        value={values[name] || ""}
                        error={
                            touched[name] &&
                            errors[name] &&
                            errors[name].length > 0
                        }
                        errorText={errors[name]}
                    />
                );
            }
            case FieldType.LAST_NAME:
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="family-name"
                        name="Last_Name"
                        value={values.Last_Name || ""}
                        error={
                            touched.Last_Name &&
                            errors.Last_Name &&
                            errors.Last_Name.length > 0
                        }
                        errorText={errors.Last_Name}
                    />
                );
            case FieldType.EVENT_LAST_NAME: {
                const { name } = props;
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="family-name"
                        value={values[name] || ""}
                        error={
                            touched[name] &&
                            errors[name] &&
                            errors[name].length > 0
                        }
                        errorText={errors[name]}
                    />
                );
            }

            case FieldType.WEBSITE:
                return (
                    <TextField type="text" autoComplete="url" {...inputProps} />
                );
            case FieldType.PARAGRAPH_TEXT:
                return <TextField type="text" multiline {...inputProps} />;
            case FieldType.ZIP: {
                const { Country } = values;
                const {
                    display_text = "Zip Code",
                    display_text_int = "Postal Code",
                } = field;
                if (Country === "US") {
                    inputProps.type = "zip";
                    inputProps.label = display_text;
                } else {
                    inputProps.type = "text";
                    inputProps.label = display_text_int;
                }
                return (
                    <TextField
                        {...inputProps}
                        autoComplete="postal-code"
                        name="Zip"
                        value={values.Zip || ""}
                        error={
                            touched.Zip && errors.Zip && errors.Zip.length > 0
                        }
                        errorText={errors.Zip}
                    />
                );
            }
            case FieldType.BILLING_ZIP: {
                const { Billing_Country, Country } = values;
                const {
                    display_text = "Zip Code",
                    display_text_int = "Postal Code",
                } = field;
                // If No Billing Country field is displayed then we have no value for
                // Billing_Country. Default to Country which is derived from donor details,
                // if not signed in to donor account it will default to field default country
                // setting
                if (
                    // user has selected US from country dropdown
                    Billing_Country === "US" ||
                    // no billing field displayed and donor account based in US
                    (Billing_Country === "" && Country === "US") ||
                    //  no billing field displayed, not signed in and field default
                    // country is US
                    (Billing_Country === "" &&
                        Country === "" &&
                        billingCountryDefault === "US")
                ) {
                    inputProps.type = "zip";
                    inputProps.label = display_text;
                } else {
                    inputProps.type = "text";
                    inputProps.label = display_text_int;
                }
                return (
                    <TextField
                        {...inputProps}
                        autoComplete="postal-code"
                        name="Billing_Zip"
                        value={values.Billing_Zip || ""}
                        error={
                            touched.Billing_Zip &&
                            errors.Billing_Zip &&
                            errors.Billing_Zip.length > 0
                        }
                        errorText={errors.Billing_Zip}
                    />
                );
            }
            case FieldType.DATE:
                return (
                    <TextField
                        {...inputProps}
                        autoComplete="off"
                        type="dateMDY"
                        descIcon={{
                            glyph: "calendar-alt-regular",
                            type: "FontAwesome",
                        }}
                    />
                );
            case FieldType.NUMBER:
                return <TextField type="number" {...inputProps} />;
            case FieldType.SYSTEM_PHONE:
                return (
                    <TextField
                        {...inputProps}
                        type="tel"
                        autoComplete="tel"
                        name="Phone"
                        value={values.Phone || ""}
                        error={
                            touched.Phone &&
                            errors.Phone &&
                            errors.Phone.length > 0
                        }
                        errorText={errors.Phone}
                        country={values.Country}
                    />
                );
            case FieldType.PHONE_NUMBER:
                return (
                    <TextField
                        {...inputProps}
                        type="tel"
                        autoComplete="tel"
                        country={values.Country}
                    />
                );
            case FieldType.EVENT_PHONE: {
                const { name } = props;
                return (
                    <TextField
                        {...inputProps}
                        type="tel"
                        autoComplete="tel"
                        value={values[name] || ""}
                        error={
                            touched[name] &&
                            errors[name] &&
                            errors[name].length > 0
                        }
                        errorText={errors[name]}
                        country={values.Country}
                    />
                );
            }
            case FieldType.CHECKBOX: {
                const { display_text } = field;
                return (
                    <Checkbox {...inputProps} inset={false}>
                        {display_text}
                    </Checkbox>
                );
            }
            case FieldType.LEGAL: {
                const { display_text, legal_statements } = field;
                return (
                    <Checkbox
                        {...inputProps}
                        id="Legal"
                        name="Legal"
                        checked={values.Legal}
                        error={
                            touched.Legal &&
                            errors.Legal &&
                            errors.Legal.length > 0
                        }
                        errorText={errors.Legal}
                    >
                        {display_text}
                        {legal_statements && legal_statements.length > 0 && (
                            <ExpandableTextContainer
                                showMoreLink="Show more"
                                showLessLink="Show less"
                                expandableText={legal_statements}
                            />
                        )}
                    </Checkbox>
                );
            }
            case FieldType.OPT_IN: {
                const { display_text } = field;
                return (
                    <Checkbox
                        {...inputProps}
                        id="Opt-In"
                        name="Opt_In"
                        checked={values.Opt_In}
                    >
                        {display_text}
                    </Checkbox>
                );
            }
            // -------------------------------------------------------------------------
            // NOTE: FieldType SHOW_NAME and SHOW_AMOUNT have inverted labels. The db value
            // is stored as showAmount and showName while the Label reads the opposite
            // (Do not show my name publicly). We are going make the naming align with
            // the label through out the donation. When preparing for the submit we can
            // adjust for the inversion.
            // -------------------------------------------------------------------------
            case FieldType.SHOW_NAME:
            case FieldType.SHOW_AMOUNT: {
                const { display_text } = field;
                const checkboxProps = {
                    display_text,
                    inputProps,
                    privacyCheckboxProps,
                };

                return <PrivacyOptionsCheckbox {...checkboxProps} />;
            }
            case FieldType.COUNTRY: {
                return (
                    <Select
                        {...inputProps}
                        autoComplete="country-name"
                        name="Country"
                        value={values.Country || ""}
                        error={
                            touched.Country &&
                            errors.Country &&
                            errors.Country.length > 0
                        }
                        errorText={errors.Country}
                    >
                        {countries.map(({ countryName, countryShortCode }) => (
                            <option
                                key={countryShortCode}
                                value={countryShortCode}
                            >
                                {countryName}
                            </option>
                        ))}
                    </Select>
                );
            }
            case FieldType.BILLING_COUNTRY: {
                return (
                    <Select
                        {...inputProps}
                        autoComplete="country-name"
                        name="Billing_Country"
                        value={values.Billing_Country || ""}
                        error={
                            touched.Billing_Country &&
                            errors.Billing_Country &&
                            errors.Billing_Country.length > 0
                        }
                        errorText={errors.Billing_Country}
                    >
                        {countries.map(({ countryName, countryShortCode }) => (
                            <option
                                key={countryShortCode}
                                value={countryShortCode}
                            >
                                {countryName}
                            </option>
                        ))}
                    </Select>
                );
            }
            case FieldType.STATE: {
                const { Country } = values;
                const {
                    display_text = "State",
                    display_text_int = "Province",
                } = field;
                if (Country === "US") {
                    inputProps.label = display_text;
                } else {
                    inputProps.label = display_text_int;
                }
                return (
                    <Select
                        {...inputProps}
                        autoComplete="address-level1"
                        name="State"
                        value={values.State || ""}
                        error={
                            touched.State &&
                            errors.State &&
                            errors.State.length > 0
                        }
                        errorText={errors.State}
                    >
                        {regions &&
                            regions.length > 0 &&
                            regions.map(({ stateName, stateCode }) => (
                                <option key={stateCode} value={stateName}>
                                    {stateName}
                                </option>
                            ))}
                    </Select>
                );
            }
            case FieldType.BILLING_STATE: {
                const { Billing_Country } = values;
                const {
                    display_text = "State",
                    display_text_int = "Province",
                } = field;
                if (Billing_Country === "US") {
                    inputProps.label = display_text;
                } else {
                    inputProps.label = display_text_int;
                }
                return (
                    <Select
                        {...inputProps}
                        autoComplete="address-level1"
                        name="Billing_State"
                        value={values.Billing_State || ""}
                        error={
                            touched.Billing_State &&
                            errors.Billing_State &&
                            errors.Billing_State.length > 0
                        }
                        errorText={errors.Billing_State}
                    >
                        {billingRegions &&
                            billingRegions.length > 0 &&
                            billingRegions.map(({ stateName, stateCode }) => (
                                <option key={stateCode} value={stateName}>
                                    {stateName}
                                </option>
                            ))}
                    </Select>
                );
            }
            case FieldType.DROPDOWN: {
                const { description = [], fieldOptions } = field;
                const oldFieldOptions =
                    description.length > 0 ? description.split("\n") : [];
                const _fieldOptions = fieldOptions || oldFieldOptions;
                return (
                    <Select {...inputProps}>
                        {_fieldOptions.map((option, i) => (
                            // ⬇️ Grandfathered in from before Airbnb rules
                            // eslint-disable-next-line react/no-array-index-key
                            <option key={i} value={option}>
                                {decodeHTMLEntities(option)}
                            </option>
                        ))}
                    </Select>
                );
            }
            case FieldType.SALUTATION: {
                const { display_text = "Title", fieldOptions } = field;
                const _fieldOptions = fieldOptions || [];
                return (
                    <Select
                        {...inputProps}
                        autoComplete="honorific-prefix"
                        name="Title"
                        label={display_text}
                        value={values.Title}
                        error={
                            touched.Title &&
                            errors.Title &&
                            errors.Title.length > 0
                        }
                        errorText={errors.Title}
                    >
                        {_fieldOptions.map((option, i) => (
                            // ⬇️ Grandfathered in from before Airbnb rules
                            // eslint-disable-next-line react/no-array-index-key
                            <option key={i} value={option}>
                                {decodeHTMLEntities(option)}
                            </option>
                        ))}
                    </Select>
                );
            }
            case FieldType.SUFFIX: {
                const { display_text } = field;
                return (
                    <TextField
                        {...inputProps}
                        type="text"
                        autoComplete="honorific-suffix"
                        name="Suffix"
                        label={display_text}
                        value={values.Suffix}
                        error={
                            touched.Suffix &&
                            errors.Suffix &&
                            errors.Suffix.length > 0
                        }
                        errorText={errors.Suffix}
                    />
                );
            }
            case FieldType.MULTIPLE_SELECTION: {
                const { fieldOptions } = field;
                const listLabel = inputProps.label;
                const error =
                    // multiple selection checkboxes are store like this:
                    // { touched: { [${id}-${i}]: true } };
                    // where id is this field's id, and i is the index of the
                    // option within the multiple selection options array.
                    Object.keys(touched).filter((key) => key.includes(fieldId))
                        .length > 0 &&
                    errors[fieldId] &&
                    errors[fieldId].length > 0;
                return (
                    <CheckboxGroup
                        ariaRequired={ariaRequired}
                        error={error}
                        errorText={errors[fieldId]}
                        fieldType={fieldType}
                        listLabel={listLabel}
                        helpText={inputProps.helpText}
                    >
                        {fieldOptions.map((option, i) => {
                            const checked = values[fieldId].includes(option);
                            const handleChangeCheckbox = () => {
                                let newValue;
                                if (checked) {
                                    newValue = [
                                        ...values[fieldId].filter(
                                            (storedValue) =>
                                                storedValue !== option,
                                        ),
                                    ];
                                } else {
                                    newValue = [...values[fieldId], option];
                                }
                                setFieldValue(fieldId, newValue);
                            };
                            return (
                                <Checkbox
                                    fieldType={fieldType}
                                    name={`${fieldId}-${i}`}
                                    id={`${fieldId}-${i}`}
                                    // ⬇️ Grandfathered in from before Airbnb rules
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={i}
                                    checked={checked}
                                    handleBlur={inputProps.handleBlur}
                                    handleChange={handleChangeCheckbox}
                                >
                                    {decodeHTMLEntities(option)}
                                </Checkbox>
                            );
                        })}
                    </CheckboxGroup>
                );
            }
            case FieldType.RADIO_BUTTON: {
                const { fieldOptions } = field;
                const listLabel = inputProps.label;
                return (
                    <RadioGroup
                        ariaRequired={ariaRequired}
                        error={inputProps.error}
                        errorText={inputProps.errorText}
                        helpText={inputProps.helpText}
                        listLabel={listLabel}
                        fieldType={fieldType}
                    >
                        {fieldOptions.map((option, i) => {
                            const checked = values[fieldId] === option;
                            const handleChangeRadio = () => {
                                setFieldValue(fieldId, option);
                            };
                            return (
                                <Radio
                                    name={fieldId}
                                    // ⬇️ Grandfathered in from before Airbnb rules
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={i}
                                    checked={checked}
                                    value={option}
                                    handleBlur={inputProps.handleBlur}
                                    handleChange={handleChangeRadio}
                                >
                                    {decodeHTMLEntities(option)}
                                </Radio>
                            );
                        })}
                    </RadioGroup>
                );
            }
            // -------------------------------------------------------------------------
            // NOTE: Unless otherwise specified, we don't need to include
            //       a hidden field in the DOM. The value for this field
            //       is accounted for in initialValues for formik.
            // -------------------------------------------------------------------------
            case FieldType.HIDDEN_FIELD:
            default:
                return null;
        }
    };
    return getFieldComponent();
};

QFieldInput.propTypes = {
    errors: PropTypes.shape().isRequired,
    field: PropTypes.shape().isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired,
    // flag for whether required takes into account
    // formik value of Donation_Amount
    useFormikRequired: PropTypes.bool,
    preventDescText: PropTypes.bool,
    donationAmountFromRedux: PropTypes.number,
};

export default QFieldInput;
