import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import parse from "html-react-parser";
import {
    getGoogleAnalyticsUAScriptContents,
    getGoogleAnalyticsGA4ScriptContents,
    getGoogleTagManagerScriptContents,
    getFacebookPixelScriptContents,
} from "./helpers";

const FormInjectionHead = ({
    activeInjectionsInHead: {
        meta = [],
        script = [],
        googleAnalyticsUA = {},
        googleAnalyticsGA4 = {},
        googleTagManager = {},
        facebookPixel = {},
    },
}) => {
    // Make the data that was stored in the database semantics before injecting
    // it into the inline scripts
    const googleAnalyticsUAId = googleAnalyticsUA && googleAnalyticsUA.content;
    const googleAnalyticsGA4Id = googleAnalyticsGA4.content;
    const googleTagManagerId = googleTagManager.content;
    const facebookPixelId = facebookPixel.content;

    // Get the code that will be rendered for each of the tracking scripts
    const googleAnalyticsUAInlineScript =
        getGoogleAnalyticsUAScriptContents(googleAnalyticsUA);
    const googleAnalyticsGA4InlineScript =
        getGoogleAnalyticsGA4ScriptContents(googleAnalyticsGA4);
    const googleTagManagerInlineScript =
        getGoogleTagManagerScriptContents(googleTagManager);
    const facebookPixelInlineScript =
        getFacebookPixelScriptContents(facebookPixel);

    // Basic HTML elements that can be injected into the document head
    // Extract the content of the meta injections, concatenate it into a single
    // string without any spaces and use the html-react-parser package to
    // crate React elements from that string. Even though you can have multiple
    // meta tags, we do not run parse() inside of the map() function as the
    // entire contents of the <meta/> tag is stored in the database. This means
    // that in order for us to pass a key to the elements being returned by
    // map() we would have to wrap each <meta/> instance in a containing
    // HTML element. And this creates an issue as the containing element needs
    // to be a valid <head/> element otherwise React Helmet will throw an
    // error because it does not recognize the element.
    const metaInjectionContent = meta.map(
        (metaInjection) => metaInjection.content,
    );
    const metaInjectionContentString = metaInjectionContent.join("");
    const renderedMetaElements = parse(metaInjectionContentString);
    // Take the inline scripts that were stored in the database as strings
    // and inject them into <script/> elements so that they are rendered and
    // run. A "SyntaxError: Unexpected identifier" error will be thrown if the
    // JS code that the user provided had a syntax error.
    const renderedScripts = script.map((scriptInjection) => (
        <script key={`active-script-${scriptInjection.id}`}>
            {scriptInjection.content}
        </script>
    ));
    // Tags for tracking that are injected into the document head.
    // Elements that are conditionally rendered cannot be grouped as React
    // Helmet throws errors when passed nested markup. In addition to this the
    // <noscript/> tags that were part of the analytics scripts were being
    // rendered as strings instead of markup because of an issue with React and
    // they did not appear to be affecting the tracking IDs anyway.
    // https://github.com/facebook/react/issues/15238
    const externalGoogleAnalyticsUAScript = googleAnalyticsUAId && (
        <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsUAId}`}
        />
    );
    const externalGoogleAnalyticsGA4Script = googleAnalyticsGA4Id && (
        <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsGA4Id}`}
        />
    );
    const inlineGoogleAnalyticsUAScript = googleAnalyticsUAId && (
        <script>{googleAnalyticsUAInlineScript}</script>
    );
    const inlineGoogleAnalyticsGA4Script = googleAnalyticsGA4Id && (
        <script>{googleAnalyticsGA4InlineScript}</script>
    );
    const inlineGoogleTagManagerScript = googleTagManagerId && (
        <script>{googleTagManagerInlineScript}</script>
    );
    const inlineFacebookPixelScript = facebookPixelId && (
        <script>{facebookPixelInlineScript}</script>
    );

    return (
        <Helmet>
            {/* Meta and Script Elements */}
            {renderedMetaElements}
            {renderedScripts}
            {/* Google Analytics - Universal Analytics */}
            {externalGoogleAnalyticsUAScript}
            {inlineGoogleAnalyticsUAScript}
            {/* Google Analytics - Google Analytics 4 */}
            {externalGoogleAnalyticsGA4Script}
            {inlineGoogleAnalyticsGA4Script}
            {/* Google Tag Manager */}
            {inlineGoogleTagManagerScript}
            {/* Facebook Pixel */}
            {inlineFacebookPixelScript}
        </Helmet>
    );
};

FormInjectionHead.propTypes = {
    activeInjectionsInHead: PropTypes.shape({
        meta: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        script: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        googleAnalyticsUA: PropTypes.shape({
            content: PropTypes.string,
        }).isRequired,
        googleAnalyticsGA4: PropTypes.shape({
            content: PropTypes.string,
        }).isRequired,
        googleTagManager: PropTypes.shape({
            content: PropTypes.string,
        }).isRequired,
        facebookPixel: PropTypes.shape({
            content: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default FormInjectionHead;
