import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./AcceptedPaymentMethods.scss";

const AcceptedPaymentMethods = ({
    enableCCPayments,
    enableAchPayments,
    enablePaypalPayments,
    acceptedCCs,
    shouldDisplayApplePay,
}) => (
    <div className="accepted-payment-methods">
        {enableCCPayments &&
            acceptedCCs.map((type) => (
                <Icon
                    glyph={`cc-${type}-brands`}
                    type="FontAwesome"
                    key={type}
                />
            ))}
        {enablePaypalPayments && (
            <Icon glyph="cc-paypal-brands" type="FontAwesome" />
        )}
        {enableAchPayments && (
            <Icon glyph="money-check-alt-solid" type="FontAwesome" />
        )}
        {shouldDisplayApplePay && (
            <Icon glyph="cc-apple-pay-brands" type="FontAwesome" />
        )}
    </div>
);

AcceptedPaymentMethods.propTypes = {
    enableAchPayments: PropTypes.bool.isRequired,
    enableCCPayments: PropTypes.bool.isRequired,
    enablePaypalPayments: PropTypes.bool.isRequired,
    acceptedCCs: PropTypes.arrayOf(PropTypes.string).isRequired,
    shouldDisplayApplePay: PropTypes.bool.isRequired,
};

export default AcceptedPaymentMethods;
