import { constants, isValidDate } from "@qgiv/core-js";
import {
    getComparisonFieldPath,
    getCurrentFieldPathOptions,
} from "../utility/eventHelpers";
import moment from "moment";

/**
 * @public
 * @function isVisible
 * @param {object} conditions Conditional data object
 * @param {object} values Field values object
 * @param {Array} allFieldsAndGroups allFieldsAndGroups All of the fields and groups that are
 *                                   associated with the form
 * @param {object} options Optional data for event custom fields, which have a unique way of storing
 *                         their values on the "values" formik object. Information for how this
 *                         options object is created can be found with the helper function
 *                         "getFieldPathOptions"
 * @description Helper func that compares conditional data against values object
 *              and determines whether something should be visible.
 * @returns {boolean} true/false depending on condition/values comparison
 */
export const isVisible = (
    conditions,
    values = {},
    allFieldsAndGroups,
    options = {}, // optional parameter which brings in overriding settings
) => {
    const { DonationType, EntityType, FieldType, Operator } = constants.ENUMS;
    const { comparisonEntityId, comparisonEntityType, operator, value } =
        conditions;

    // Variables to check if settings exists to override original conditional logic
    const usesFieldPathName = !!options?.conditionalFieldPath;
    const usesOptionalDonationAmount = !!options?.optionalDonationAmount;
    const isPackageOrTicketLevelEventField =
        usesFieldPathName && options.conditionalFieldPath.includes("-");

    // Event custom fields use a field name path to specify which field
    // is being entered because more than one of a field can exist on a form.
    // This is why we need to replace the comparisonEntityId with a value
    // we can use for both events and form
    const getComparisonEntityFieldPath = () =>
        usesFieldPathName ? options.conditionalFieldPath : comparisonEntityId;

    const comparisonEntityFieldPath = getComparisonEntityFieldPath();
    let isFieldOrGroupVisible;

    /**
     * @private
     * @function getCurrentValue
     * @param {string|number} key The key of the value you want to retrieve
     *                            from the values object.
     * @returns {string|boolean} The target value
     */
    const getCurrentValue = (key) => values[key];

    /**
     * @private
     * @function getComparisonFieldOrGroup
     * @param {Array} allFieldsAndGroups All of the fields and groups that are
     *                                   associated with the form
     * @returns {object} The object that represents the field or
     *                             group that the current field or group is
     *                             being compared to.
     */
    const getComparisonFieldOrGroup = (allFieldsAndGroups = []) => {
        // There will be no matching field or group if:
        // 1. The comparison field is not going to be displayed in the form
        // 2. comparisonEntityId === "0" because the conditional logic is
        // dependent on Donation Amount or Donation Type instead of a condition
        // that is associated with a Custom Field.
        let fieldIdToFindComparisonField = comparisonEntityFieldPath;
        if (isPackageOrTicketLevelEventField) {
            // Need to grab the field id out comparisonEntityFieldPath to
            // correctly find comparison field in allFieldsAndGroups
            const comparisonFieldPathOptions = getCurrentFieldPathOptions(
                comparisonEntityFieldPath,
            );
            const { currentFieldId } = comparisonFieldPathOptions;

            fieldIdToFindComparisonField = currentFieldId;
        }

        const comparisonFieldOrGroup = allFieldsAndGroups.find(
            (field) => field.id === fieldIdToFindComparisonField,
        );

        if (comparisonFieldOrGroup) {
            return comparisonFieldOrGroup;
        } else {
            return {};
        }
    };

    /**
     * @private
     * @function getHasConditionalLogic
     * @param {object} comparisonFieldOrGroup The object that represents the
     *                                        field or group that the current
     *                                        field or group is being compared
     *                                        to.
     * @returns {boolean} Whether or not that field or group has any
     *                    conditional logic
     */
    const getHasConditionalLogic = (comparisonFieldOrGroup = {}) => {
        if (comparisonFieldOrGroup.conditions) {
            return Object.keys(comparisonFieldOrGroup.conditions).length > 0;
        }
        return false;
    };

    /**
     * @private
     * @function valueExists
     * @param {string|number} key The key of the value you want check for
     * @returns {boolean} Flag indicating key exists in values object
     */
    const valueExists = (key) => Object.keys(values).includes(key);

    /**
     * @private
     * @function compareNumberValues
     * @param {number} currentValue The current value number
     * @param {number} comparisonValue The comparison value number
     * @param {string|number} operator The comparison enum
     * @description Compares numbers given an operator enum
     * @returns {boolean} true/false
     */
    const compareNumberValues = (currentValue, comparisonValue, operator) => {
        switch (Number(operator)) {
            case Operator.EQUALTO: {
                return currentValue === comparisonValue;
            }
            case Operator.GREATERTHAN: {
                return currentValue > comparisonValue;
            }
            case Operator.GREATERTHANOREQUALTO: {
                return currentValue >= comparisonValue;
            }
            case Operator.LESSTHAN: {
                return currentValue < comparisonValue;
            }
            case Operator.LESSTHANOREQUALTO: {
                return currentValue <= comparisonValue;
            }
            default: {
                return false;
            }
        }
    };

    switch (Number(comparisonEntityType)) {
        // -------------------------------------------------------------------------
        // NOTE: All checks against an entity that is a field must first check
        //       that the field exists in the values object.
        // -------------------------------------------------------------------------
        case FieldType.SHORT_TEXT:
        case FieldType.PARAGRAPH_TEXT:
        case FieldType.PHONE_NUMBER:
        case FieldType.EMAIL_ADDRESS:
        case FieldType.WEBSITE:
        case FieldType.HIDDEN_FIELD: {
            // Check if current value is not empty
            isFieldOrGroupVisible = valueExists(comparisonEntityFieldPath)
                ? getCurrentValue(comparisonEntityFieldPath).length !== 0
                : false;
            break;
        }
        case FieldType.CHECKBOX: {
            // Check if current value is 'true'
            isFieldOrGroupVisible = valueExists(comparisonEntityFieldPath)
                ? getCurrentValue(comparisonEntityFieldPath) === true
                : false;
            break;
        }
        case FieldType.DROPDOWN:
        case FieldType.RADIO_BUTTON: {
            // Check if current value is equal to condition value
            isFieldOrGroupVisible = valueExists(comparisonEntityFieldPath)
                ? getCurrentValue(comparisonEntityFieldPath) === value
                : false;
            break;
        }
        case FieldType.MULTIPLE_SELECTION: {
            // Check if current value is equal to condition value
            isFieldOrGroupVisible = valueExists(comparisonEntityFieldPath)
                ? getCurrentValue(comparisonEntityFieldPath).indexOf(value) > -1
                : false;
            break;
        }
        case FieldType.DATE: {
            const exists = valueExists(comparisonEntityFieldPath);
            if (exists) {
                const currentValue = getCurrentValue(comparisonEntityFieldPath);
                const isValid = isValidDate(currentValue);
                if (!isValid) {
                    isFieldOrGroupVisible = false;
                }
                const currentDateValue = moment(currentValue);
                const conditionDateValue = moment(value);
                switch (Number(operator)) {
                    case Operator.EQUALTO: {
                        // Check if current date value is equal to condition date value
                        isFieldOrGroupVisible =
                            currentDateValue.isSame(conditionDateValue);
                        break;
                    }
                    case Operator.GREATERTHAN: {
                        // Check if current date value is after condition date value
                        isFieldOrGroupVisible =
                            currentDateValue.isAfter(conditionDateValue);
                        break;
                    }
                    case Operator.LESSTHAN: {
                        // Check if current date value is before condition date value
                        isFieldOrGroupVisible =
                            currentDateValue.isBefore(conditionDateValue);
                        break;
                    }
                    default: {
                        isFieldOrGroupVisible = true;
                    }
                }
            } else {
                isFieldOrGroupVisible = false;
            }
            break;
        }
        case FieldType.NUMBER: {
            const currentNumberValue = Number(
                getCurrentValue(comparisonEntityFieldPath),
            );
            const conditionNumberValue = Number(value);
            isFieldOrGroupVisible = valueExists(comparisonEntityFieldPath)
                ? compareNumberValues(
                      currentNumberValue,
                      conditionNumberValue,
                      operator,
                  )
                : false;
            break;
        }
        case EntityType.DONATION_AMOUNT: {
            const currentDonationAmount = usesOptionalDonationAmount
                ? options.optionalDonationAmount
                : Number(getCurrentValue("Donation_Amount"));
            const conditionDonationAmount = Number(value);
            isFieldOrGroupVisible = compareNumberValues(
                currentDonationAmount,
                conditionDonationAmount,
                operator,
            );
            break;
        }
        case EntityType.DONATION_TYPE: {
            const isRecurring = getCurrentValue("Recurring_Donation");
            const currentDonationType = isRecurring
                ? DonationType.RECURRING
                : DonationType.ONE_TIME;
            const conditionDonationType = Number(value);
            // Check if current donation type matches condition donation type
            isFieldOrGroupVisible =
                currentDonationType === conditionDonationType;
            break;
        }
        default: {
            isFieldOrGroupVisible = true;
        }
    }

    // -------------------------------------------------------------------------
    // NOTE: Get the field or group that was used for the the comparison and
    //       determine whether or not it has conditional logic. If the field is
    //       visible evaluate whether the field that the field was being
    //       compared to is also visible by extracting the condition out into
    //       its own variable and recursively running the same logic
    // -------------------------------------------------------------------------
    const comparisonFieldOrGroup =
        getComparisonFieldOrGroup(allFieldsAndGroups);
    const hasConditionalLogic = getHasConditionalLogic(comparisonFieldOrGroup);
    if (isFieldOrGroupVisible && hasConditionalLogic) {
        const {
            conditions: [comparisonFieldCondition = {}],
        } = comparisonFieldOrGroup;

        // We need to pass the donation amount value if it was being passed
        // to this function as a part of options so it can be compared with the
        // new comparison field.

        const _options = {};

        if (usesOptionalDonationAmount) {
            _options.optionalDonationAmount = options.optionalDonationAmount;
        }

        // Need to grab from the condition the id of the comparison field
        // and then run it through that generate fieldPath function, we can reference
        // comparisonEntityFieldPath to generate the new path.
        if (isPackageOrTicketLevelEventField) {
            const newComparisonId = comparisonFieldCondition.comparisonEntityId;
            const comparisonField = allFieldsAndGroups.find(
                (field) => field.id === newComparisonId,
            );
            const newComparisonFieldPath = getComparisonFieldPath(
                comparisonEntityFieldPath,
                comparisonField,
            );
            _options.conditionalFieldPath = newComparisonFieldPath;
        }

        return isVisible(
            comparisonFieldCondition,
            values,
            allFieldsAndGroups,
            _options,
        );
    }

    return isFieldOrGroupVisible;
};
