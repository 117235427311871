import { getMultiRestrictionFieldName } from "../../multiRestrictionHelpers";
import { getActiveDisplayableRestrictions } from "../../standardRestrictionsHelpers";
import { getInitialSubRestrictionValue } from "../../subRestrictionHelpers";

// -------------------------------------------------------------------------
// TODO: Tests need to be added for the functions in this file once the
// multi-restriction project is closer to wrapping up.
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// NOTE: I have left the commented logs in here as they will be useful to
// me as I continue to work through the multi-restriction feature.
// -------------------------------------------------------------------------
/**
 * @public
 * @function getMultiRestrictionFieldValues
 * @param {object} settings settings
 * @param {number} settings.currentDisplay current display enum from initial state
 * @param {object[]} settings.restrictions restrictions array from initial state
 * @returns {object} object with data for the different multi-restriction fields
 */
export const getMultiRestrictionFieldValues = ({
    currentDisplay,
    restrictions,
}) => {
    const multiRestrictionFieldValues = {};

    const addMultiRestrictionFieldValue = (restriction) => {
        const { id = 0 } = restriction;
        const fieldName = getMultiRestrictionFieldName(id);
        const initialSubRestrictionValue = getInitialSubRestrictionValue(
            restrictions,
            id,
        );

        multiRestrictionFieldValues[fieldName] = {
            Checked: false,
            Other_One_Time_Amount: "",
            Other_Recurring_Amount: "",
            Selected_One_Time_Id: "",
            Selected_Recurring_Id: "",
            Sub_Restriction: initialSubRestrictionValue,
        };
    };

    // The BE hands us all active restrictions regardless of whether or not
    // they should be shown on this device
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );

    activeDisplayableRestrictions.forEach(addMultiRestrictionFieldValue);

    return multiRestrictionFieldValues;
};
