import { useEffect, useCallback } from "react";

/**
 * @param {object} props
 * @param {boolean} props.enableAbandonedGifts
 * @param {boolean} props.hasSubmittedForm
 * @param {boolean} props.shouldAllowAbandonedGiftCookieSetting
 * @param {string} props.token
 */
const useSetAbandonedGiftCookie = ({
    enableAbandonedGifts,
    hasSubmittedForm,
    token,
    shouldAllowAbandonedGiftCookieSetting,
}) => {
    const cookieDuration = 172800; // 48 hours
    const createTokenCookie = useCallback(() => {
        const newCookie = `qgiv_abandoned_gift=${token}; SameSite=none; max-age=${cookieDuration}; Secure`;
        document.cookie = newCookie;
    }, [token]);

    const removeTokenCookie = useCallback(() => {
        // This cookie getting function was taken from the Mozilla docs
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
        const cookie = document.cookie
            .split("; ")
            .find((row) => row.startsWith("qgiv_abandoned_gift"))
            ?.split("=")[1];
        const cookieExists = !!cookie;
        if (cookieExists) {
            const expiredCookie = `qgiv_abandoned_gift=${token}; SameSite=none; max-age=0; Secure`;
            document.cookie = expiredCookie;
        }
    }, [token]);
    // add qgiv_abandoned_gift cookie to form iframe
    useEffect(() => {
        if (enableAbandonedGifts && shouldAllowAbandonedGiftCookieSetting) {
            createTokenCookie();
        }
    }, [
        shouldAllowAbandonedGiftCookieSetting,
        createTokenCookie,
        enableAbandonedGifts,
    ]);

    useEffect(() => {
        if (hasSubmittedForm) {
            removeTokenCookie();
        }
    });
};

export default useSetAbandonedGiftCookie;
