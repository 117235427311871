import React from "react";

/**
 * @param {object} props component props
 * @param {string} props.formattedRecurringUpgradeAdditionalText text from cms control options
 * @returns {React.ReactElement} RecurringUpgradeAlternateText
 */
const RecurringUpgradeAdditionalText = ({
    formattedRecurringUpgradeAdditionalText = "Your steady support helps us plan ahead and you can cancel anytime.",
}) => (
    <p className="-text--center">{formattedRecurringUpgradeAdditionalText}</p>
);

export default RecurringUpgradeAdditionalText;
