import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import TextButton from "../Button/TextButton";

const HeaderLogin = ({ id, signInText, sendLoginMessage }) => {
    const [signInArray, setSignInArray] = useState([]);

    useEffect(() => {
        if (signInText) {
            setSignInArray(signInText.split("%SIGNIN%"));
        }
    }, [signInText, setSignInArray]);

    const createSecondLabel = (wordArr) => {
        if (wordArr.length > 0) {
            return wordArr.length > 1 ? ` ${wordArr[1]}` : null;
        }
        return " to speed things up";
    };

    // Values are not loaded into the form here because the logic in Login.js
    // adds an event listener that will load the values into the form after a
    // login message is sent regardless of where that message comes from
    const handleSignInClick = () => sendLoginMessage(id);

    return (
        <p className="qg-vendor-header__login -margin-top--0">
            {signInArray.length > 0
                ? `${signInArray[0]} `
                : "Have an account? "}
            <TextButton
                handleClick={handleSignInClick}
                styles="-type--normal -color--theme-primary ignore-auto-focus"
            >
                <strong>Sign in</strong>
            </TextButton>
            {createSecondLabel(signInArray)}
        </p>
    );
};

HeaderLogin.propTypes = {
    // id is strictly typed in some apps but not others
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    signInText: PropTypes.string.isRequired,
    sendLoginMessage: PropTypes.func.isRequired,
};

export default HeaderLogin;
