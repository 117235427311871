import React from "react";
import { useSelector } from "react-redux";

import DonationFormSinglePageSwitch from "./DonationFormSinglePageSwitch";
import { selectCurrentPage } from "../../redux/slices/appSettingsSlice";

const ConnectedDonationFormSinglePageSwitch = () => {
    // Note: currentPage should either be 1 or 5 (1 for donation amounts and 5 for confirmation)
    // This may change once I have BE data
    const currentDonationFormPage = useSelector(selectCurrentPage);
    const singlePageProps = { currentDonationFormPage };
    return <DonationFormSinglePageSwitch {...singlePageProps} />;
};

export default ConnectedDonationFormSinglePageSwitch;
