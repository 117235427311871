import React, { useCallback, useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import {
    useOnClickOutside,
    Icon,
    useBrowserBackAction,
} from "@qgiv/core-react";
import { handleModalKeyDown } from "../../utility";

import "./Modal2.scss";

const Modal2 = ({
    children,
    classes = "",
    dark,
    fullWidth = false,
    standardFormWidth,
    containsForm = false,
    modalContainerID = "portal-root",
    onClose,
    scrollOverflow = false,
    closeOnClickOutside = true,
    usePrimaryBackground = false,
    displayCloseButton = true,
    hardModalFullHeight,
    allowScrollIntoViewOnChildrenUpdate = true,
    renderFooter = () => {
        return null;
    },
}) => {
    const ref = useRef();
    const modalClasses = cx(
        "modal2",
        dark && `modal2--dark`,
        scrollOverflow && `modal2--overflow-auto`,
        containsForm && `modal2--contains-form`,
        classes,
    );
    const getModalContainer = () => {
        // if the modal container does not exist, create it
        if (document.getElementById(modalContainerID) === null) {
            const container = document.createElement("div");
            container.id = modalContainerID;
            document.getElementById("app").after(container);
        }
        // return the element to be used by ReactDOM.createPortal
        return document.getElementById(modalContainerID);
    };
    let optionalEventHandlersForButton = {};

    // Adds optional close button event handler
    if (onClose) {
        const handleButtonClick = () => {
            onClose();
        };

        optionalEventHandlersForButton = {
            onClick: handleButtonClick,
        };
    }

    // If a user has the Modal open and uses the Browser back button desktop or mobile then we
    // want to hijack this action. Instead of moving to the previous page, we want to remain in
    // current and just close the modal. We pass in the condition that should be met and the
    // modal close handler.
    useBrowserBackAction(true, onClose);

    // Adds optional outside the modal event handler
    if (onClose && closeOnClickOutside) {
        const handleOutsideClick = () => {
            onClose();
        };

        useOnClickOutside(ref, handleOutsideClick);
    }

    useEffect(() => {
        // Make modal visible on screen when it renders
        // this will run every time children updates, which sometimes is not desireable.
        // Pass the allowScrollIntoViewOnChildrenUpdate as false to prevent this behavior.
        // The will also pass down to the child components the runScrollIntoView function
        // which you can call when needed.
        if (allowScrollIntoViewOnChildrenUpdate) {
            ref.current.scrollIntoView({ behavior: "auto" });
        }
    }, [children]);

    // Function that scrolls modal content wrapper ref into view
    // this is used on command as opposed to within the useEffect above.
    // This is wrapped in a useCallback so it can be used within
    // children's useEffects without causing lots of re-renders.
    // Setting behavior to "auto" temporarily.  Safari on mobile
    // is having difficulty with the "smooth" option. During this task
    // https://qgiv.atlassian.net/browse/QG-34042 it will be conditionally
    // set back to "smooth".
    const runScrollIntoView = useCallback(() => {
        ref.current.scrollIntoView({ behavior: "auto" });
    }, [ref]);

    const topOverrideStyle = hardModalFullHeight
        ? {
              top: 0,
              height: "100vh",
              minHeight: "100vh",
              maxHeight: "100vh",
              overflow: "hidden",
          }
        : {};

    // We need to be able to pass runScrollIntoView to Modal2's
    // children components. I don't think there is any issue with always
    // passing this function down to Modal2's children, but the render is
    // currently scoped to the allowScrollIntoViewOnChildrenUpdate flag
    const renderChildrenWithProps = () =>
        React.Children.map(children, (child) =>
            React.cloneElement(child, {
                runScrollIntoView,
            }),
        );

    const ModalElement = (
        // Modal
        <div
            className={modalClasses}
            onKeyDown={(e) => handleModalKeyDown(e, ref)}
        >
            {/* Modal Window */}
            <div
                className={cx(
                    "modal2__window",
                    containsForm && "modal2__window--contains-form",
                )}
            >
                {/* Modal Background */}
                <div
                    className={cx(
                        "modal2__bg",
                        usePrimaryBackground && "modal2__bg--primary-color",
                        containsForm && "modal2__bg--contains-form",
                    )}
                />
                {/* Modal Content Wrapper */}
                <div
                    className={cx(
                        "modal2__content-wrapper",
                        containsForm &&
                            "modal2__content-wrapper--contains-form",
                        fullWidth && "modal2__content-wrapper--full-width",
                        standardFormWidth &&
                            "modal2__content-wrapper--standard-form-width",
                    )}
                    ref={ref}
                    style={topOverrideStyle}
                >
                    {displayCloseButton && (
                        <button
                            className="modal2__close -cursor--pointer"
                            aria-label="Close modal"
                            autoFocus={true}
                            {...optionalEventHandlersForButton}
                        >
                            <Icon
                                glyph="cross"
                                type="Icomoon"
                                title="Close"
                                label="Close"
                            />
                        </button>
                    )}
                    {/* Modal Content */}
                    <div
                        className={cx(
                            "modal2__content",
                            containsForm && "modal2__content--contains-form",
                        )}
                    >
                        {allowScrollIntoViewOnChildrenUpdate
                            ? children
                            : // children with runScrollIntoView being passed through props
                              renderChildrenWithProps()}
                    </div>
                    {renderFooter()}
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(ModalElement, getModalContainer());
};

Modal2.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    classes: PropTypes.string,
    /**
     * Allows modal to be dismissed when clicking outside of it's content.
     */
    closeOnClickOutside: PropTypes.bool,
    dark: PropTypes.bool,
    fullWidth: PropTypes.bool,
    containsForm: PropTypes.bool,
    // Allows for auto scroll at content wrapper level
    scrollOverflow: PropTypes.bool,
    glyph: PropTypes.shape({
        glyph: PropTypes.string,
        type: PropTypes.string,
    }),
    modalContainerID: PropTypes.string,
    onClose: PropTypes.func,
    usePrimaryBackground: PropTypes.bool,
    displayCloseButton: PropTypes.bool,
    allowScrollIntoViewOnChildrenUpdate: PropTypes.bool,
};

export default Modal2;
