import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @returns {React.ReactElement} RecurringUpgradeIcon
 */
const RecurringUpgradeIcon = () => (
    <Icon
        type="Custom"
        glyph="qgiv-impact"
        classNames={["recurring-upgrade__icon"]}
    />
);

export default RecurringUpgradeIcon;
