import { getInitialTouched as getGiftInitialTouched } from "../../Pages/GiftPage/formik";
import { getInitialTouched as getDetailsInitialTouched } from "../../Pages/DetailsPage/formik";
import { getInitialTouched as getAdditionalInitialTouched } from "../../Pages/AdditionalDetailsPage/formik";
import { getInitialTouched as getPaymentInitialTouched } from "../../Pages/PaymentPage/formik";

const getInitialTouched = (hasActiveFulfillment, settings) => {
    const {
        giftPageInitialTouchedSettings = {},
        donorDetailPageInitialTouchedSettings = {},
        additionalDetailPageInitialTouchedSettings = {},
        paymentPageInitialTouchedSettings = {},
    } = settings;
    const initialTouchedFromGiftContent = getGiftInitialTouched(
        giftPageInitialTouchedSettings,
    );
    const initialTouchedFromDetailsContent = getDetailsInitialTouched(
        donorDetailPageInitialTouchedSettings,
    );
    const initialTouchedFromAdditionalContent = getAdditionalInitialTouched(
        additionalDetailPageInitialTouchedSettings,
    );
    const initialTouchedFromPaymentContent = getPaymentInitialTouched(
        paymentPageInitialTouchedSettings,
    );

    const singlePageInitialTouched = {
        ...initialTouchedFromGiftContent,
        ...initialTouchedFromDetailsContent,
        ...(!hasActiveFulfillment && {
            ...initialTouchedFromAdditionalContent,
        }),
        ...initialTouchedFromPaymentContent,
    };

    return singlePageInitialTouched;
};

export default getInitialTouched;
