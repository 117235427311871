/**
 * @public
 * @function getSessionStorageKey
 * @description function that will return key for session data if given id of the form it is used with
 * @param {string|number} id id of the form/event that is storing data in session storage
 * @returns {string} key to be used to store session data
 */
const getSessionStorageKey = (id) => `q_data_form_${id}`;

export default getSessionStorageKey;
