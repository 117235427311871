import { currencyString } from "@qgiv/core-js";
import { Yup, getYupQAmount, getYupQDate } from "@qgiv/core-validation";
import moment from "moment";
import {
    getHasValueInOtherAmount,
    getHasSelectedOtherAmount,
    getIsDateRequiredForRecurringDonations,
    getIsOtherAmount,
    getSelectedAmount,
} from "../amountHelpers";
import {
    getMultiRestrictionFieldName,
    getShouldRenderMultiRestriction,
} from "../multiRestrictionHelpers";
import { getActiveDisplayableRestrictions } from "../standardRestrictionsHelpers";

/**
 * @public
 * @function getOtherAmountValidation
 * @param {object} settings All settings needed to determine min/max validation
 * @returns {object} Object with Formik field and error
 */
export const getOtherAmountValidation = (settings) => {
    const {
        donationSettings: { activeAmounts, minAmt, maxAmt },
        formSettings: { currency },
        hasSelectedRecurringDonation,
        storedFormikGiftDetails,
    } = settings;

    const fieldName = hasSelectedRecurringDonation
        ? "Other_Recurring_Amount"
        : "Other_One_Time_Amount";

    const hasSelectedOtherAmount = getHasSelectedOtherAmount({
        activeAmounts,
        values: storedFormikGiftDetails,
        fieldName: hasSelectedRecurringDonation
            ? "Selected_Recurring_Id"
            : "Selected_One_Time_Id",
    });

    // Exit early if the user has not selected the Other Amount
    if (!hasSelectedOtherAmount) {
        return {};
    }

    let amountValidation = null;
    const baseValidation = getYupQAmount(fieldName);

    const formattedMinAmt = Math.max(parseFloat(minAmt).toFixed(2), 0.01);

    // Min/Max Donation Amounts
    if (parseFloat(maxAmt) > 0) {
        amountValidation = baseValidation
            .min(
                `${formattedMinAmt}`,
                `Your donation must be at least ${currencyString(
                    formattedMinAmt,
                    currency,
                )}`,
            )
            .max(
                `${maxAmt}`,
                `Your donation must be less than ${currencyString(
                    maxAmt,
                    currency,
                )}.`,
            );
    } else {
        amountValidation = baseValidation.min(
            `${formattedMinAmt}`,
            `Your donation must be at least ${currencyString(
                formattedMinAmt,
                currency,
            )}`,
        );
    }

    return { [fieldName]: amountValidation };
};

/**
 * @public
 * @function getStartDateValidation
 * @param {object} settings All settings needed to determine min/max validation
 * @param {string} today todays date as string
 * @param {boolean} isDateRequiredForRecurringDonations required vs optional CP setting
 * @returns {object} Object with Formik field and error
 */
export const getStartDateValidation = (
    settings,
    today,
    isDateRequiredForRecurringDonations,
) => {
    const {
        donationSettings: { enableStartDate },
    } = settings;

    const fieldName = "Start Date";
    const requireStartDateOfTodayOrLater = getYupQDate(fieldName).min(
        today,
        `Please enter a valid ${fieldName}.`,
    );

    if (enableStartDate) {
        return {
            Start_Date: isDateRequiredForRecurringDonations
                ? requireStartDateOfTodayOrLater.required(
                      `${fieldName} is required.`,
                  )
                : requireStartDateOfTodayOrLater,
        };
    }
    // Start date should never be set before today's date
    return requireStartDateOfTodayOrLater;
};

/**
 * @public
 * @function getEndDateValidation
 * @param {object} settings All settings needed to determine min/max validation
 * @param {string} today todays date as string
 * @param {boolean} isDateRequiredForRecurringDonations required vs optional CP setting
 * @returns {object} Object with Formik field and error
 */
export const getEndDateValidation = (
    settings,
    today,
    isDateRequiredForRecurringDonations,
) => {
    const {
        donationSettings: { enableStartDate, enableEndDate },
    } = settings;

    const fieldName = "End Date";
    const requireEndDateOfTodayOrLater = getYupQDate(fieldName).min(
        today,
        `Please enter a valid ${fieldName}.`,
    );

    const requireEndDateOfStartDateOrLater = getYupQDate(fieldName).min(
        Yup.ref("Start_Date"),
        `${fieldName} must be after start date.`,
    );

    if (enableEndDate && enableStartDate) {
        // If the user can specify a start date and an end date
        // the end date cannot be before the start date
        return {
            End_Date: isDateRequiredForRecurringDonations
                ? requireEndDateOfStartDateOrLater.required(
                      `${fieldName} is required.`,
                  )
                : requireEndDateOfStartDateOrLater,
        };
    }
    if (/*! isPromiseTransaction && */ enableEndDate && !enableStartDate) {
        // If the user can only specify an end date, the end date
        // only needs to be after today's date
        return {
            End_Date: isDateRequiredForRecurringDonations
                ? requireEndDateOfTodayOrLater.required(
                      `${fieldName} is required.`,
                  )
                : requireEndDateOfTodayOrLater,
        };
    }

    // End date should never be set before today's date
    return requireEndDateOfTodayOrLater;
};

/**
 * @public
 * @function getStartEndDateValidation
 * @param {object} settings All settings needed to determine min/max validation
 * @returns {object} Object with Formik field and error
 */
export const getStartEndDateValidation = (settings) => {
    const {
        donationSettings: {
            enableEndDate,
            enableStartDate,
            recurringPlanEndDateRequired,
        },
        formSettings: { enableRecur },
        hasSelectedRecurringDonation,
        // isPromiseTransaction
    } = settings;
    const today = moment().format("MM/DD/YYYY");

    let validations = {};

    const isDateRequiredForRecurringDonations =
        getIsDateRequiredForRecurringDonations({
            enableEndDate,
            enableRecur,
            enableStartDate,
            recurringPlanEndDateRequired,
        });

    // Date Validations
    if (hasSelectedRecurringDonation /* && !isPromiseTransaction */) {
        if (enableStartDate) {
            // Start dates should only be validated if not a promise transaction
            validations = {
                ...validations,
                ...getStartDateValidation(
                    settings,
                    today,
                    isDateRequiredForRecurringDonations,
                ),
            };
        }

        if (enableEndDate) {
            validations = {
                ...validations,
                ...getEndDateValidation(
                    settings,
                    today,
                    isDateRequiredForRecurringDonations,
                ),
            };
        }
    }

    return {
        ...validations,
    };
};

/**
 *
 * @param {object} settings
 * @param {object[]} settings.activeAmounts
 * @param {object} settings.currentMultiRestrictionValue
 * @param {boolean} settings.hasSelectedRecurringDonation
 * @param {string} settings.value
 * @returns {boolean}
 */
const getMultiRestrictionOneTimeDonationValidation = ({
    activeAmounts,
    currentMultiRestrictionValue,
    hasSelectedRecurringDonation,
    value,
}) => {
    const { Checked = false, Other_One_Time_Amount = "" } =
        currentMultiRestrictionValue;
    const hasSelectedAmountForOneTimeDonation = !!(
        !hasSelectedRecurringDonation && value
    );
    const amountId = Number(value);
    const selectedAmount = getSelectedAmount(activeAmounts, amountId);
    const hasSelectedOtherAmount = getIsOtherAmount(selectedAmount);
    const hasValueInOtherAmount = getHasValueInOtherAmount(
        Other_One_Time_Amount,
    );
    const hasSelectedStandardAmount = !hasSelectedOtherAmount;

    if (
        Checked &&
        hasSelectedAmountForOneTimeDonation &&
        hasSelectedOtherAmount &&
        hasValueInOtherAmount
    ) {
        return true;
    }

    if (
        Checked &&
        hasSelectedAmountForOneTimeDonation &&
        hasSelectedStandardAmount
    ) {
        return true;
    }

    return false;
};

/**
 *
 * @param {object} settings
 * @param {object[]} settings.activeAmounts
 * @param {object} settings.currentMultiRestrictionValue
 * @param {boolean} settings.hasSelectedRecurringDonation
 * @param {string} settings.value
 * @returns {boolean}
 */
const getMultiRestrictionRecurringDonationValidation = ({
    activeAmounts,
    currentMultiRestrictionValue,
    hasSelectedRecurringDonation,
    value,
}) => {
    const { Checked = false, Other_Recurring_Amount = "" } =
        currentMultiRestrictionValue;
    const hasSelectedAmountForRecurringDonation = !!(
        hasSelectedRecurringDonation && value
    );
    const amountId = Number(value);
    const selectedAmount = getSelectedAmount(activeAmounts, amountId);
    const hasSelectedOtherAmount = getIsOtherAmount(selectedAmount);
    const hasValueInOtherAmount = getHasValueInOtherAmount(
        Other_Recurring_Amount,
    );
    const hasSelectedStandardAmount = !hasSelectedOtherAmount;

    if (
        Checked &&
        hasSelectedAmountForRecurringDonation &&
        hasSelectedOtherAmount &&
        hasValueInOtherAmount
    ) {
        return true;
    }

    if (
        Checked &&
        hasSelectedAmountForRecurringDonation &&
        hasSelectedStandardAmount
    ) {
        return true;
    }

    return false;
};

/**
 *
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {object} settings.donationSettings
 * @param {boolean} settings.hasSelectedRecurringDonation
 * @param {object[]} settings.restrictions
 * @param {object} settings.smsData
 * @returns {object}
 */
export const getMultiRestrictionsValidation = ({
    currentDisplay,
    donationSettings,
    hasSelectedRecurringDonation,
    restrictions,
    smsData,
}) => {
    const { restrictionGivingType } = donationSettings;
    const { activeAmounts = [] } = donationSettings;
    const multiRestrictionFieldValues = {};

    // The BE hands us all active restrictions regardless of whether or not
    // they should be shown on this device
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    const shouldBuildMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    // Construct validation for each of the multi-restriction fields
    const addValidationForMultiRestrictionFieldValue = (restriction) => {
        const { id = 0 } = restriction;
        const fieldName = getMultiRestrictionFieldName(id);

        // value is undefined until you actually select an amount
        // This is definitely not the most legible way to extract the current
        // multi-restriction value but our hands are tied here and this is how
        // we did it in the original implementation of the MR feature
        const validateSelectedOneTimeId = (value, context) => {
            // @ts-ignore
            const currentMultiRestrictionValue = context?.from[0].value;

            if (
                !currentMultiRestrictionValue.Checked ||
                hasSelectedRecurringDonation
            ) {
                return true;
            }

            const hasValidOneTimeDonation =
                getMultiRestrictionOneTimeDonationValidation({
                    activeAmounts,
                    currentMultiRestrictionValue,
                    hasSelectedRecurringDonation,
                    value,
                });

            if (hasValidOneTimeDonation) {
                return true;
            }

            return false;
        };

        const validateSelectedRecurringId = (value, context) => {
            // @ts-ignore
            const currentMultiRestrictionValue = context?.from[0].value;
            const { Checked = false } = currentMultiRestrictionValue;

            if (!Checked || !hasSelectedRecurringDonation) {
                return true;
            }

            const hasValidRecurringDonation =
                getMultiRestrictionRecurringDonationValidation({
                    activeAmounts,
                    currentMultiRestrictionValue,
                    hasSelectedRecurringDonation,
                    value,
                });

            if (hasValidRecurringDonation) {
                return true;
            }

            return false;
        };

        // The validation messages used here are placeholders as it was easier
        // to customize the validation message in the connected component
        multiRestrictionFieldValues[fieldName] = Yup.object().shape({
            Checked: Yup.bool(),
            Other_One_Time_Amount: Yup.string(),
            Other_Recurring_Amount: Yup.string(),
            Selected_One_Time_Id: Yup.string().test({
                message: "Enter a valid one time amount",
                test: validateSelectedOneTimeId,
            }),
            Selected_Recurring_Id: Yup.string().test({
                message: "Enter a valid recurring amount",
                test: validateSelectedRecurringId,
            }),
            Sub_Restriction: Yup.string(),
        });
    };

    if (
        activeDisplayableRestrictions.length > 0 &&
        shouldBuildMultiRestriction
    ) {
        activeDisplayableRestrictions.forEach(
            addValidationForMultiRestrictionFieldValue,
        );
    }

    return multiRestrictionFieldValues;
};
