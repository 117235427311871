import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import DonationFormReceiptBase from "./DonationFormReceiptBase";

const ConnectedDonationFormReceiptBase = ({ children }) => {
    const formSettings = useSelector(selectAllFormSettings);
    const { enableDonorLogins = true } = formSettings;

    const eventReceiptBaseProps = {
        enableDonorLogins,
    };

    return (
        <DonationFormReceiptBase {...eventReceiptBaseProps}>
            {children}
        </DonationFormReceiptBase>
    );
};

ConnectedDonationFormReceiptBase.propTypes = {
    // Accommodate accordion headers that are just a string of text, a single
    // that contains complex markup or multiple elements that contain complex
    // markup
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
};

export default ConnectedDonationFormReceiptBase;
