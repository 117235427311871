import React from "react";
// import DonationFormClosedBase from "../templates/DonationFormClosedBase/DonationFormClosedBase";

import ConnectedFormStatus from "./ConnectedFormStatus";
import "./DonationFormClosedPage.scss";

const DonationFormClosedPage = () => (
    // <DonationFormClosedBase>
    <div className="donation-closed-page">
        <div className="donation-closed-page__content">
            <ConnectedFormStatus />
        </div>
    </div>
    //  </DonationFormClosedBase>
);

export default DonationFormClosedPage;
