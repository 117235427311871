import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { getPaymentTypeInitialValue } from "@qgiv/core-donor";
import { selectPaymentData } from "../../../../redux/slices/formSettingsSlice";
import {
    selectHasStoredPaymentMethods,
    selectIsLoggedIn,
} from "../../../../redux/slices/donorAccountSlice";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";
import PaymentMethods from "./PaymentMethods";

const ConnectedPaymentMethods = ({ controlId }) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const displayStoredPaymentMethodsOnLoad = useSelector(
        selectHasStoredPaymentMethods,
    );
    const isCms = useSelector(selectIsCms);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const [displayStoredPaymentMethods, setDisplayStoredPaymentMethods] =
        useState(displayStoredPaymentMethodsOnLoad && !isCms);
    const [hasToggledPaymentMethodView, setHasToggledPaymentMethodView] =
        useState(false);

    const { enableAchPayments, enableCCPayments, enablePaypalPayments } =
        useSelector(selectPaymentData);
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        // -------------------------------------------------------------------------
        // NOTE: If donor logs out while on payment page, Store Payment View then we
        // need to update the UI to show New Payment UI
        // -------------------------------------------------------------------------
        if (!isLoggedIn && displayStoredPaymentMethods) {
            setDisplayStoredPaymentMethods(false);
        }
    }, [isLoggedIn, displayStoredPaymentMethods]);

    const initialPaymentTypeValueIfNotLoggedIn = getPaymentTypeInitialValue({
        enableAchPayments,
        enableCCPayments,
        enablePaypalPayments,
    });

    const handleTogglePaymentMethodsButtonClick = () => {
        if (displayStoredPaymentMethods) {
            setFieldValue("Stored_Payment", false);
            setFieldValue("Payment_Type", initialPaymentTypeValueIfNotLoggedIn);
            setDisplayStoredPaymentMethods(false);
        } else {
            setFieldValue("Stored_Payment", true);
            // Bypass the validations that are based on payment types
            setFieldValue("Payment_Type", PaymentType.STORED);
            setDisplayStoredPaymentMethods(true);
        }
        // Sets a boolean that is used as a flag that indicates that we should
        // focus on the saved payment method heading text after the use a saved
        // payment method button was clicked. This ensures that the user can
        // seamlessly tab through the saved payment methods
        setHasToggledPaymentMethodView(true);
    };

    return (
        <PaymentMethods
            controlId={controlId}
            displayStoredPaymentMethods={displayStoredPaymentMethods}
            hasToggledPaymentMethodView={hasToggledPaymentMethodView}
            handleTogglePaymentMethodsButtonClick={
                handleTogglePaymentMethodsButtonClick
            }
        />
    );
};

ConnectedPaymentMethods.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedPaymentMethods;
