import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { MatchingGift } from "@qgiv/core-donor";
import {
    selectConfig,
    selectIsCms,
} from "../../../redux/slices/configSettingsSlice";
import {
    selectGiftDetails,
    selectDonorDetails,
} from "../../../redux/slices/donationDetailsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectMatchingGiftSettings } from "../../../redux/slices/donationSettingsSlice";
import { selectCmsControlById } from "../../../redux/slices/cmsSettingsSlice";
import { selectAllSystemFields } from "../../../redux/slices/fieldsSlice";

const ConnectedMatchingGift = ({ controlId }) => {
    const {
        ENUMS: {
            EntityType: { SYSTEM_FIELD_GROUP_MATCHING_GIFTS },
            FieldType,
            GiftMatchingService,
            MatchingType,
        },
    } = constants;

    const isCms = useSelector(selectIsCms);

    const { subtotal } = useSelector(selectGiftDetails);

    const { currency } = useSelector(selectAllFormSettings);
    const { path } = useSelector(selectConfig);

    const { thirdPartyMatching, enableMatching, matchingType } = useSelector(
        selectMatchingGiftSettings,
    );

    const { options } = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    const donorDetails = useSelector(selectDonorDetails);
    const { Company_Donation } = donorDetails;

    const {
        sectionHeading = "Your gift could be worth more!",
        description = "Find out if you’re eligible for a matching gift from your employer.",
        matchingSearchButtonLabel = "Check If I’m Eligible",
        matchingSearchDescription = "Is your employer one of these companies?",
        matchingSearchCancelLink = "Cancel",
        manualMatchingLabel = "This gift can be matched",
        manualMatchingHelpText = "",
        manualMatchingCompanyLabel = "Matching Company",
        manualMatchingAmountLabel = "Matching Amount",
    } = options;

    const allFieldsAndGroups = useSelector(selectAllSystemFields);

    const matchingGroupData = allFieldsAndGroups.find(
        (field) => Number(field.type) === SYSTEM_FIELD_GROUP_MATCHING_GIFTS,
    );
    const matchingGroupFields = matchingGroupData.fields;
    const workEmailFieldData = matchingGroupFields.find(
        (field) => Number(field.type) === FieldType.WORK_EMAIL,
    );

    const matchingWorkEmail = workEmailFieldData.display_text;
    const matchingWorkEmailHelpText = workEmailFieldData.helpText;

    const employerFieldData = matchingGroupFields.find(
        (field) => Number(field.type) === FieldType.EMPLOYER,
    );

    const matchingEmployerLabel = employerFieldData.display_text;
    const matchingEmployerHelpText = employerFieldData.helpText;

    const { values, setValues } = useFormikContext();
    const {
        Match_Gift,
        Matching_Company_Name,
        Matching_Donor_Verified,
        Employer,
        Work_Email,
    } = values;

    // bool comparators for service provider logic
    const isDtD =
        parseInt(thirdPartyMatching, 10) ===
        GiftMatchingService.DOUBLE_THE_DONATION;
    const isHEP =
        parseInt(thirdPartyMatching, 10) === GiftMatchingService.HEP_DATA;
    const isCG =
        parseInt(thirdPartyMatching, 10) === GiftMatchingService.CYBER_GRANTS ||
        parseInt(thirdPartyMatching, 10) === 0;

    const searchEnabled = !!(
        (Employer && Employer.length > 3) ||
        (Work_Email && Work_Email.length > 3)
    );

    // determine which provider logo
    let providerLogoSrc = "";
    let providerLabel = "";
    switch (parseInt(thirdPartyMatching, 10)) {
        case GiftMatchingService.HEP_DATA:
            providerLogoSrc = `/resources/core/images/logo-hepdata.png`;
            providerLabel = "HEPData";
            break;
        case GiftMatchingService.DOUBLE_THE_DONATION:
            providerLogoSrc = `/resources/core/images/logo-doubledonation.png`;
            providerLabel = "Double the Donation";
            break;
        case GiftMatchingService.QGIV:
            providerLogoSrc = `/resources/core/images/logo-qgiv.png`;
            providerLabel = "Qgiv";
            break;
        case GiftMatchingService.CYBER_GRANTS:
        default:
            providerLogoSrc = `/resources/core/images/logo-cybergrants.png`;
            providerLabel = "CyberGrants";
            break;
    }

    const selectionStored =
        (Matching_Company_Name && Matching_Company_Name.length > 0) ||
        Matching_Donor_Verified;

    let viewType = null;

    const isManualMatch = matchingType === MatchingType.MANUAL;

    if (isManualMatch) {
        viewType = "ManualMatching";
    }
    if (matchingType === MatchingType.AUTOMATIC) {
        viewType = "SearchView";
    }

    let isVisible;
    switch (viewType) {
        case "SearchView":
        case "SelectionView":
        case "ResultsView":
            isVisible = true;

            break;
        case "ManualMatching":
            if (isCms) {
                isVisible = true;
            } else {
                isVisible = !!Match_Gift && subtotal > 0;
            }
            break;
        default:
            isVisible = false;
    }

    const employerField = Employer;
    const workEmailField = Work_Email;

    const displayMatchingGift = enableMatching && !Company_Donation;

    // Donation total without Gift Assist
    const donationTotal = subtotal;

    const matchingGiftProps = {
        currency,
        displayMatchingGift,
        donationTotal,
        employerField,
        isCG,
        isDtD,
        isHEP,
        isManualMatch,
        isStandardDonationForm: true,
        isVisible,
        sectionHeading,
        description,
        manualMatchingAmountLabel,
        manualMatchingCompanyLabel,
        manualMatchingHelpText,
        manualMatchingLabel,
        matchingSearchButtonLabel,
        matchingSearchDescription,
        matchingSearchCancelLink,
        matchingEmployerLabel,
        matchingEmployerHelpText,
        matchingWorkEmail,
        matchingWorkEmailHelpText,
        noLeftGutter: true,
        path,
        providerLogoSrc,
        providerLabel,
        searchEnabled,
        selectionStored,
        teaserLabel: "",
        values,
        viewType,
        setValues,
        workEmailField,
    };

    return <MatchingGift {...matchingGiftProps} />;
};

ConnectedMatchingGift.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedMatchingGift;
