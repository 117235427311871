import React from "react";
import PropTypes from "prop-types";
import { Message } from "@qgiv/core-react";

const MessageDisplay = ({ messages = [], handleClose = () => {} }) =>
    messages.length > 0 &&
    Array.isArray(messages) && (
        <div className="grid">
            <div className="col col--12">
                <Message
                    closeButton
                    onClose={handleClose}
                    scrollToMessage
                    type="success"
                >
                    <p>
                        {messages.map((message) => (
                            <span key={message}>{`${message} `}</span>
                        ))}
                    </p>
                </Message>
            </div>
        </div>
    );

MessageDisplay.propTypes = {
    handleClose: PropTypes.func.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string),
};

export default MessageDisplay;
