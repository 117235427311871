import React from "react";
import PropTypes from "prop-types";
import PrivacyOptionsControl from "./PrivacyOptionsControl";

const ConnectedPrivacyOptionsControl = ({ controlId }) => (
    <PrivacyOptionsControl controlId={controlId} />
);

ConnectedPrivacyOptionsControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedPrivacyOptionsControl;
