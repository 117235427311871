import React from "react";
import { Select } from "@qgiv/core-donor";

/**
 * @param {object} props
 * @param {number[]} props.availableInstallmentOptions
 * @param {Function} props.handleChange
 * @param {Function} props.handleBlur
 * @param {number} props.Installment_Count
 * @param {string} props.label
 * @param {string} props.placeholderText - The placeholder text to display before selection of a plan.
 * @returns {React.ReactElement} The rendered NumberOfInstallmentsSelect component.
 */
const NumberOfInstallmentsSelect = ({
    availableInstallmentOptions = [],
    handleChange = () => {},
    handleBlur = () => {},
    Installment_Count = 0,
    label,
    placeholderText,
}) => (
    <div className="-margin-bottom--15">
        {availableInstallmentOptions.length > 1 ? (
            <Select
                disableBlankOption
                disableInlineLabel
                id="Installment-Count"
                name="Installment_Count"
                handleBlur={handleBlur}
                handleChange={handleChange}
                label={label}
                value={String(Installment_Count)}
                ariaLabel="Installment Count"
            >
                {availableInstallmentOptions.map((frequency) => (
                    <option
                        key={frequency}
                        value={frequency}
                        className="number-of-installments"
                    >
                        {frequency}
                    </option>
                ))}
            </Select>
        ) : (
            <>
                <span className="qg-vendor-field-label field-label">
                    {label}:
                </span>
                <p>
                    <strong>
                        {availableInstallmentOptions[0]
                            ? availableInstallmentOptions[0]
                            : placeholderText}
                    </strong>
                </p>
            </>
        )}
    </div>
);

export default NumberOfInstallmentsSelect;
