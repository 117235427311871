import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import Modal from "../Modal";

import "./MoreInfoModal.scss";

const MoreInfoModal = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        children,
        position,
        renderContent = (content) => {
            return (
                <div className="col col--10 col--sm-8 -margin-bottom--10 -margin-top--20">
                    {content}
                </div>
            );
        },
    } = props;

    const dismissButton = {
        size: "xlarge",
        onClick: () => {
            setIsModalOpen(false);
        },
        text: "Dismiss",
    };

    const modalOpenHandler = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
    };

    return (
        <>
            <span className="-cursor--pointer" onClick={modalOpenHandler}>
                <Icon glyph="question-circle-regular" type="FontAwesome" />
            </span>

            {isModalOpen && (
                <Modal
                    buttons={{ dismissButton }}
                    classes={`qg-vendor-more-info-modal more-info-modal ${
                        position === "center" ? "screen-centered" : ""
                    }`}
                    dark
                    isModalOpen
                    glyph={{
                        glyph: "question-circle-solid",
                        type: "FontAwesome",
                    }}
                    onClose={() => setIsModalOpen(false)}
                    closeOnClickOutside={false}
                >
                    {renderContent(children)}
                </Modal>
            )}
        </>
    );
};

MoreInfoModal.propTypes = {
    children: PropTypes.any,
    renderContent: PropTypes.func,
    position: PropTypes.string,
};

export default MoreInfoModal;
