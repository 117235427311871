import { Yup, getDedicationValidations } from "@qgiv/core-validation";
import {
    getMultiRestrictionsValidation,
    getOtherAmountValidation,
    getStartEndDateValidation,
} from "@qgiv/donation-form";

// -------------------------------------------------------------------------
// MARK: Form level validation schema
// -------------------------------------------------------------------------
export const getGiftPageValidations = (settings) => {
    const {
        currentDisplay,
        dedicationSettings,
        Has_Dedication,
        hasDisplayableDedicationOnThisPage,
    } = settings;
    const giftSchema = {
        ...getMultiRestrictionsValidation(settings),
        ...getOtherAmountValidation(settings),
        ...getStartEndDateValidation(settings),
        ...(hasDisplayableDedicationOnThisPage && {
            ...getDedicationValidations({
                currentDisplay,
                dedicationSettings,
                Has_Dedication,
            }),
        }),
    };

    return giftSchema;
};

const getValidationSchema = (settings) => {
    const validationShape = {
        ...getGiftPageValidations(settings),
    };
    const schema = Yup.object().shape(validationShape);

    return schema;
};

export default getValidationSchema;
