import { useEffect } from "react";

// -------------------------------------------------------------------------
// NOTE: We want to purposefully ignore certain focusable elements when
// loading the modal form / navigating to a new page in the modal form.
// The goal is that the element that gets focused on is the first element
// that makes up a form input / user interaction. This means we want to ignore
// things like the donor/sign in actions, navigation bar, etc. To achieve this
// in a maintainable way, the class "ignore-auto-focus" is added to elements we
// want to be ignored by this hook. It might be necessary to add this class to new elements
// if they are added above the first element that makes up a form input / user interaction.
// Currently, there is not a need for this functionality on non-modal views of forms,
// but it should work correctly in those views just as well and can be used if needed.
// -------------------------------------------------------------------------

// This list comes from the handleModalKeydown function
// with the extended .ignore-auto-focus classes added
const focusableElements = [
    "button:not(.ignore-auto-focus, [disabled])",
    "input:not(.ignore-auto-focus, [disabled])",
    "select:not(.ignore-auto-focus, [disabled])",
    "textarea:not(.ignore-auto-focus, [disabled])",
    'a[href]:not([tabindex="-1"]):not(.ignore-auto-focus, [disabled])',
    '[tabindex]:not([tabindex="-1"]):not(.ignore-auto-focus, [disabled])',
];

const focusableElementsString = focusableElements.join(", ");
const timeoutDuration = 600;

/**
 * @public
 * @function useContentAutoFocus
 * @description Hook for focusing on the first "acceptable" element when loading the modal form.
 * Will also focus on the first "acceptable" element when navigating through pages.
 * @param {object} settings object used to set up useContentAutoFocus
 * @param {number} settings.currentPage value of the current page being viewed. Updating this value re-runs the
 * logic in the useEffect, but does not have any impact on the actual logic.
 * @param {Node} settings.ref element to look within for focusable elements, return of useRef hook
 */
const useContentAutoFocus = ({ currentPage = 0, ref = { current: false } }) => {
    useEffect(() => {
        if (ref.current) {
            setTimeout(() => {
                const modalElement = ref.current;
                // querySelector will grab first element that matches query
                const firstFocusableElement = modalElement.querySelector(
                    focusableElementsString,
                );
                firstFocusableElement?.focus();
            }, timeoutDuration);
        }
    }, [ref, currentPage]);
};

export default useContentAutoFocus;
