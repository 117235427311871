import { constants } from "@qgiv/core-js";
import { getMappedRecipient } from "../../recipientHelpers";
import { cmsDefaultRecipient } from "../../defaultTestData";

const getSelectedRecipientInitialValues = ({
    donationRecipientCmsData,
    eventSettings,
    formId,
    isCms,
    preselectedRecipient,
}) => {
    const {
        donationRecipientControlExistsInCms = false,
        hideDonationRecipientOnEventPages = false,
    } = donationRecipientCmsData;

    if (!donationRecipientControlExistsInCms) {
        return {
            donationRecipientControlExistsInCms,
        };
    }

    const {
        ENUMS: {
            EntityType: { FORM, REGISTRATION, TEAM, CLASSIFICATION },
        },
    } = constants;

    const {
        enableClassificationForDonation,
        enableClassifications,
        enableDonationToParticipant,
        enableDonationToTeam,
    } = eventSettings;

    const defaultSelectedRecipient = {
        selectedRecipient: {},
        presetRecipient: false,
        donationRecipientControlExistsInCms,
    };

    const defaultEventRecipient = {
        selectedRecipient: {
            entity: formId,
            entityType: FORM,
        },
        // this flag is for showing/hiding selected recipient actions
        // which will not display for event only donation forms because
        // the donation recipient control will not display.
        presetRecipient: true,
        donationRecipientControlExistsInCms,
    };

    const classificationsAreEnabledAndAbleToDonate =
        enableClassificationForDonation && enableClassifications;

    const donateOnlyToEventEnabled =
        !classificationsAreEnabledAndAbleToDonate &&
        !enableDonationToParticipant &&
        !enableDonationToTeam;

    if (donateOnlyToEventEnabled) {
        return defaultEventRecipient;
    }

    if (isCms && hideDonationRecipientOnEventPages) {
        const defaultCmsRecipient = getMappedRecipient(cmsDefaultRecipient);

        return {
            selectedRecipient: defaultCmsRecipient,
            presetRecipient: false,
            donationRecipientControlExistsInCms,
        };
    }

    if (Object.keys(preselectedRecipient).length > 0) {
        const preselectedRecipientWithEntityData = {
            ...preselectedRecipient,
            entity: preselectedRecipient.id,
            entityType: preselectedRecipient.preselectedRecipientType,
        };
        let shouldAllowPreselectedRecipient = false;
        switch (preselectedRecipient.preselectedRecipientType) {
            case REGISTRATION:
                shouldAllowPreselectedRecipient = enableDonationToParticipant;
                break;
            case TEAM:
                shouldAllowPreselectedRecipient = enableDonationToTeam;
                break;
            case CLASSIFICATION:
                shouldAllowPreselectedRecipient =
                    enableClassificationForDonation;
                break;
            default:
                shouldAllowPreselectedRecipient = false;
        }
        if (shouldAllowPreselectedRecipient) {
            return {
                selectedRecipient: getMappedRecipient(
                    preselectedRecipientWithEntityData,
                ),
                presetRecipient: true,
                donationRecipientControlExistsInCms,
            };
        }
    }
    return defaultSelectedRecipient;
};

export default getSelectedRecipientInitialValues;
