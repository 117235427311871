import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import {
    SystemFieldGroupName,
    SystemFieldGroupBillingAddress,
    SystemFieldGroupMailingAddress,
    SystemFieldGroupPrivacyOptions,
} from "./System";
import CustomFieldGroup from "./Custom";

const QFieldGroup = (props) => {
    const { EntityType } = constants.ENUMS;
    const { field } = props;
    const getFieldGroupComponents = () => {
        switch (parseInt(field.type)) {
            case EntityType.SYSTEM_FIELD_GROUP_NAME:
                return <SystemFieldGroupName {...props} />;
            case EntityType.SYSTEM_FIELD_GROUP_MAILING_ADDRESS:
                return <SystemFieldGroupMailingAddress {...props} />;
            case EntityType.SYSTEM_FIELD_GROUP_BILLING_ADDRESS:
                return <SystemFieldGroupBillingAddress {...props} />;
            case EntityType.SYSTEM_FIELD_GROUP_PRIVACY_OPTIONS:
                return <SystemFieldGroupPrivacyOptions {...props} />;
            case EntityType.SYSTEM_FIELD_GROUP_MATCHING_GIFTS:
            case EntityType.FIELD_GROUP: {
                return <CustomFieldGroup {...props} />;
            }
        }
    };
    return getFieldGroupComponents();
};

QFieldGroup.propTypes = {
    field: PropTypes.shape({
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fields: PropTypes.array,
    }).isRequired,
};
export default QFieldGroup;
