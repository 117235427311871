import React from "react";
import PropTypes from "prop-types";

const AccordionBody = ({ children, ...props }) => {
    return <div {...props}>{children}</div>;
};

AccordionBody.propTypes = {
    children: PropTypes.object,
    props: PropTypes.object,
};

export default AccordionBody;
