import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { currencyString } from "@qgiv/core-js";
import { useDispatch, useSelector } from "react-redux";
import { selectAllFormSettings } from "../../../../../../../redux/slices/formSettingsSlice";
import {
    selectAllDonationSettings,
    selectRecurringFrequencyByValue,
} from "../../../../../../../redux/slices/donationSettingsSlice";
import {
    selectGiftDetails,
    updateGiftDetails,
} from "../../../../../../../redux/slices/donationDetailsSlice";
import InstallmentSummary from "./InstallmentSummary";
import {
    getFormattedEndDate,
    getCalculatedInstallmentEndDate,
    getFinalEndDate,
} from "../installmentHelpers";

/**
 * @returns {React.ReactElement} The rendered InstallmentSummary component.
 */
const ConnectedInstallmentSummary = () => {
    // Formik
    const { values, setFieldValue } = useFormikContext();
    const { Recurring_Frequency, Selected_Plan } = values;
    // Redux
    const dispatch = useDispatch();
    const { subtotal } = useSelector(selectGiftDetails);
    const { currency } = useSelector(selectAllFormSettings);
    const { activePlans } = useSelector(selectAllDonationSettings);

    const selectedFrequency = useSelector((state) =>
        selectRecurringFrequencyByValue(state, Recurring_Frequency),
    );

    const selectedPlan =
        activePlans.find((plan) => plan.id === Selected_Plan) || {};

    const formattedInstallmentSubtotal = currencyString(subtotal, currency);

    const shouldDisplayInstallmentSummary = Selected_Plan && subtotal > 0;

    const calculatedInstallmentEndDate = getCalculatedInstallmentEndDate(
        selectedPlan,
        values,
    );

    const finalEndDate = getFinalEndDate(calculatedInstallmentEndDate);

    const formattedEndDate = getFormattedEndDate(finalEndDate);

    // update formik and Redux End_Date upon change
    useEffect(() => {
        setFieldValue("End_Date", formattedEndDate);
        dispatch(updateGiftDetails({ End_Date: formattedEndDate }));
    }, [dispatch, formattedEndDate, setFieldValue]);

    const formattedInstallmentSummary = `${formattedInstallmentSubtotal} ${selectedFrequency.label} until ${formattedEndDate}`;

    return (
        shouldDisplayInstallmentSummary && (
            <InstallmentSummary
                formattedInstallmentSummary={formattedInstallmentSummary}
            />
        )
    );
};

export default ConnectedInstallmentSummary;
