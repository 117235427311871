import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getDisplayableActiveDedications } from "@qgiv/donation-form";
import DedicationControl from "./DedicationControl";
import { selectAllActiveDedications } from "../../../redux/slices/dedicationSettingsSlice";
import { selectCurrentDisplay } from "../../../redux/slices/configSettingsSlice";

const ConnectedDedicationControl = ({ controlId }) => {
    const dedications = useSelector(selectAllActiveDedications);
    const currentDisplay = useSelector(selectCurrentDisplay);

    const activeDisplayableDedications = getDisplayableActiveDedications({
        dedications,
        currentDisplay,
    });

    const isDedicationEnabled = activeDisplayableDedications.length > 0;

    return isDedicationEnabled ? (
        <DedicationControl controlId={controlId} />
    ) : null;
};

ConnectedDedicationControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDedicationControl;
