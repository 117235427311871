import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectConfig } from "../../../../redux/slices/configSettingsSlice";
import {
    selectContentToDisplay,
    selectCurrentPage,
    selectIsSinglePageForm,
} from "../../../../redux/slices/appSettingsSlice";
import { ModalContentReplacerEnums } from "../../../common/ModalContentReplacer";
import DonationFormBase from "./DonationFormBase";

const ConnectedDonationFormBase = ({ children }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { QGIV_EVENTS },
        },
    } = constants;
    const formSettings = useSelector(selectAllFormSettings);
    const currentPage = useSelector(selectCurrentPage);
    const config = useSelector(selectConfig);
    const { isQgivEventEmbed = false } = config;

    const isEventsPage = Number(currentPage) === QGIV_EVENTS;
    const isSinglePageForm = useSelector(selectIsSinglePageForm);

    const selectedContentToDisplay = useSelector(selectContentToDisplay);
    const contentSupportsBaseElementsDisplay =
        selectedContentToDisplay === ModalContentReplacerEnums.DEFAULT_CONTENT;

    // hide footer if we're rendering the Qgiv event list embedded on event landing page
    const displayFooter =
        !isQgivEventEmbed && contentSupportsBaseElementsDisplay;

    const { enableDonorLogins = true } = formSettings;
    const displayDonorLogin =
        enableDonorLogins &&
        contentSupportsBaseElementsDisplay &&
        !isEventsPage;

    const donationFormBaseProps = {
        displayDonorLogin,
        displayFooter,
        isSinglePageForm,
    };

    return (
        <DonationFormBase {...donationFormBaseProps}>
            {children}
        </DonationFormBase>
    );
};

ConnectedDonationFormBase.propTypes = {
    // Accommodate accordion headers that are just a string of text, a single
    // that contains complex markup or multiple elements that contain complex
    // markup
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
};

export default ConnectedDonationFormBase;
