import {
    getDedicationFieldsInitialValues,
    getDisplayableActiveDedications,
    getDisplayableDedicationFields,
} from "../../dedicationHelpers";

// eslint-disable-next-line import/prefer-default-export
export const getDedicationDetailsInitialValues = ({
    currentDisplay,
    currentPage,
    dedicationSettings,
}) => {
    const { dedications, dedicationLocation, enableDedNotif, fields } =
        dedicationSettings;

    const displayableActiveDedications = getDisplayableActiveDedications({
        dedications,
        currentDisplay,
    });

    const shouldDedicationBuildValues =
        currentPage === dedicationLocation &&
        displayableActiveDedications.length > 0;

    const notificationValues = enableDedNotif
        ? { Dedication_Email: "", Dedication_Message: "" }
        : {};

    const displayableDedicationFieldsObj = getDisplayableDedicationFields({
        fields,
        currentDisplay,
    });
    const displayableDedicationFields = Object.values(
        displayableDedicationFieldsObj,
    );
    const fieldValues =
        displayableDedicationFields.length > 0
            ? getDedicationFieldsInitialValues(displayableDedicationFields)
            : {};

    const values = shouldDedicationBuildValues
        ? {
              //   Dedication Details need to be pruned by settings
              Has_Dedication: false,
              Dedication_Name: "",
              Dedication_Type: "",
              ...notificationValues,
              ...fieldValues,
          }
        : {};

    return values;
};
