import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getHasDisplayableDedicationOnThisPage } from "@qgiv/donation-form";
import { constants } from "@qgiv/core-js";
import {
    selectAllAdditionalDetailsPageCustomFields,
    selectAllFieldsAndGroups,
    selectHasDisplayableMatchingGiftOnPage,
} from "../../redux/slices/fieldsSlice";
import { selectSpecificFormSetting } from "../../redux/slices/formSettingsSlice";
import {
    selectCustomFieldValues,
    selectDedicationsValues,
    selectGiftDetails,
    selectMatchingValues,
} from "../../redux/slices/donationDetailsSlice";
import { selectMatchingGiftSettings } from "../../redux/slices/donationSettingsSlice";
import { selectDedicationSettings } from "../../redux/slices/dedicationSettingsSlice";
import { selectCurrentDisplay } from "../../redux/slices/configSettingsSlice";
import { buildAdditionalDetailsPageFormikSettings } from "./pageDataHelper";

const useAdditionalDetailsPageData = () => {
    const {
        ENUMS: { CmsPageStandardizer },
    } = constants;
    const additionalDetailsCustomFields = useSelector(
        selectAllAdditionalDetailsPageCustomFields,
    );
    const displayCaptcha = useSelector((state) =>
        selectSpecificFormSetting(state, "captcha"),
    );

    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);
    // NOTE: savedCustomFieldValues will include custom field values from details page
    // which makes additional details page conditional fields based on
    // details page custom fields possible.
    const savedCustomFieldValues = useSelector(selectCustomFieldValues);
    const savedMatchingGiftValues = useSelector(selectMatchingValues);
    const savedDedicationValues = useSelector(selectDedicationsValues);
    const savedValues = useMemo(
        () => ({
            ...savedCustomFieldValues,
            ...savedMatchingGiftValues,
            ...savedDedicationValues,
        }),
        [
            savedCustomFieldValues,
            savedDedicationValues,
            savedMatchingGiftValues,
        ],
    );

    const { Has_Dedication = false } = savedDedicationValues;

    const { total, subtotal, hasSelectedRecurringDonation } =
        useSelector(selectGiftDetails);

    // -------------------------------------------------------------------------
    // NOTE: Current Page becomes unreliable when form is Single Page. Since we
    // are using this data only on the Additional Details page, we can hardcode.
    // -------------------------------------------------------------------------
    const currentPage = CmsPageStandardizer.ADDITIONAL_DETAILS;

    const hasDisplayableMatchingGiftOnThisPage = useSelector((state) =>
        selectHasDisplayableMatchingGiftOnPage(state, currentPage),
    );
    const { enableMatching, matchingRatio, matchingType } = useSelector(
        selectMatchingGiftSettings,
    );

    const dedicationSettings = useSelector(selectDedicationSettings);
    const currentDisplay = useSelector(selectCurrentDisplay);
    const hasDisplayableDedicationOnThisPage =
        getHasDisplayableDedicationOnThisPage({
            currentDisplay,
            currentPage,
            dedicationSettings,
        });

    const shouldCaptchaBeRequired = displayCaptcha && total === 0;
    const donationAmountFromRedux = subtotal;

    const conditionalLogicOptions = useMemo(
        () => ({
            donationAmountFromRedux,
            hasSelectedRecurringDonation,
            usesReduxDonationAmountValue: true,
            usesReduxRecurringValue: true,
        }),
        [donationAmountFromRedux, hasSelectedRecurringDonation],
    );
    const additionalDetailsPageData = useMemo(() => {
        const formikSettings = buildAdditionalDetailsPageFormikSettings({
            additionalDetailsCustomFields,
            allFieldsAndGroups,
            conditionalLogicOptions,
            currentDisplay,
            currentPage,
            dedicationSettings,
            enableMatching,
            Has_Dedication,
            hasDisplayableDedicationOnThisPage,
            hasDisplayableMatchingGiftOnThisPage,
            matchingRatio,
            matchingType,
            savedValues,
            shouldCaptchaBeRequired,
            subtotal,
            total,
        });

        return {
            displayCaptcha: false,
            documentTitle: "Details",
            formikSettings,
            hasDisplayableDedicationOnThisPage,
            hasDisplayableMatchingGiftOnThisPage,
            savedValues,
        };
    }, [
        Has_Dedication,
        additionalDetailsCustomFields,
        allFieldsAndGroups,
        conditionalLogicOptions,
        currentDisplay,
        currentPage,
        dedicationSettings,
        enableMatching,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        matchingRatio,
        matchingType,
        savedValues,
        shouldCaptchaBeRequired,
        subtotal,
        total,
    ]);

    return additionalDetailsPageData;
};

export default useAdditionalDetailsPageData;
