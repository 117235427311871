import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useBreakpoints, Tooltip } from "@qgiv/core-react";
import { Button, ProcessingButton, BackButtonButton } from "@qgiv/core-donor";
import ConnectedAcceptedPaymentMethods from "./ConnectedAcceptedPaymentMethods";

const Submit = ({
    addTopPaddingAndGutter = true,
    backButtonLabel = "Back",
    disabled = false,
    displayAcceptedPaymentMethods = false,
    displayBackButton = false,
    displaySubmit = true,
    handleBackButtonClick = () => {},
    isPreview = false,
    isSubmitting = false,
    nextButtonLabel = "Next",
    scopeToIframe = false,
    submitTooltipMessage = "",
    showBackground = false,
}) => {
    const { isExtraLargeScreen = true } = useBreakpoints();

    let buttonComponent;
    if (isPreview && !isExtraLargeScreen) {
        buttonComponent = (
            <Tooltip content={submitTooltipMessage}>
                <Button
                    classes={cx(disabled && "-disabled")}
                    disabled={disabled}
                    type="submit"
                >
                    {nextButtonLabel}
                </Button>
            </Tooltip>
        );
    } else {
        buttonComponent = (
            <>
                {isPreview && isExtraLargeScreen && submitTooltipMessage && (
                    <small className="-text--center -margin-top--10">
                        {submitTooltipMessage}
                    </small>
                )}
                <Button
                    classes={cx(disabled && "-disabled", "-font-weight--light")}
                    disabled={disabled}
                    type="submit"
                >
                    {nextButtonLabel}
                </Button>
            </>
        );
    }

    const renderBackButton = () =>
        displayBackButton ? (
            <div
                className={cx(
                    "col col--12 col--xl-6",
                    isExtraLargeScreen && "-text--center -padding-top--30",
                )}
            >
                <BackButtonButton
                    handleClick={handleBackButtonClick}
                    ariaLabel={backButtonLabel}
                    backLinkLabel={backButtonLabel}
                    ariaHidden={false}
                />
            </div>
        ) : null;

    const renderNextButton = () => (
        <div
            className={cx(
                "col col--12",
                displayBackButton && "col--xl-6",
                !addTopPaddingAndGutter && "col--no-top-gutter",
            )}
        >
            {isSubmitting ? (
                <ProcessingButton
                    showBackground={showBackground}
                    scopeToModal
                    scopeToIframe={scopeToIframe}
                />
            ) : (
                buttonComponent
            )}
            {displayAcceptedPaymentMethods && (
                <div className="col col--12 col--no-top-gutter">
                    <ConnectedAcceptedPaymentMethods />
                </div>
            )}
        </div>
    );

    // for accessibility, we need to change the physical order of these
    // buttons at the set breakpoint instead of using css style for
    // the tab order to be correct
    return displaySubmit ? (
        <div
            className={cx(
                "qg-vendor-form-footer grid grid--align-items-center",
                addTopPaddingAndGutter && "-padding-top--15",
            )}
        >
            {isExtraLargeScreen ? (
                <>
                    {renderNextButton()} {renderBackButton()}
                </>
            ) : (
                <>
                    {renderBackButton()}
                    {renderNextButton()}
                </>
            )}
        </div>
    ) : null;
};

Submit.propTypes = {
    addTopPaddingAndGutter: PropTypes.bool,
    backButtonLabel: PropTypes.string,
    disabled: PropTypes.bool,
    displayAcceptedPaymentMethods: PropTypes.bool,
    displayBackButton: PropTypes.bool,
    displaySubmit: PropTypes.bool,
    handleBackButtonClick: PropTypes.func,
    isPreview: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    // Give page needs to be able to load DOM element for icon
    nextButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    submitTooltipMessage: PropTypes.string,
    showBackground: PropTypes.bool.isRequired,
};

export default Submit;
