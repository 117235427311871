/**
 * @public
 * @function getValueWithoutSeparator
 * @description function that removes separators from a currency string using regex
 * @param {string} value currency string that may include separators
 * @param {string} separator character that separates currency strings
 * @returns {string} string value with separators removed
 */
export const getValueWithoutSeparator = (value, separator) => {
    if (value === undefined) return "";
    const separatorRegExp = new RegExp(separator, "g");
    const valueWithoutSeparators = value.replace(separatorRegExp, "");
    return valueWithoutSeparators;
};

/**
 * @public
 * @function addSeparatorToReverseArray
 * @param {Array} array of whole number digits in reverse order of placement in input
 * @param {string|object} separatorItem item to be placed in array at correct intervals
 * @returns {Array} array of whole number digits with separators in reverse order if placement in input
 */
export const addSeparatorToReverseArray = (array, separatorItem) => {
    const mutableArray = [...array];
    // need to add separators incrementally
    // thousands separator
    if (mutableArray.length > 3) {
        mutableArray.splice(3, 0, separatorItem);
    }
    // millions separator
    if (mutableArray.length > 7) {
        mutableArray.splice(7, 0, separatorItem);
    }
    // billions separator
    if (mutableArray.length > 11) {
        mutableArray.splice(11, 0, separatorItem);
    }
    // if we need support for a trillion dollars,
    // add a new separator check here
    return mutableArray;
};

/**
 * @public
 * @function getDisplayValue
 * @description function that takes in a string that needs to be formatted correctly for currency display.
 * @param {object} settings object that contains settings for getting the display value
 * @param {string} settings.value string that can be incorrectly formatted for currency
 * @param {string} settings.radix string that denotes a fractional number, ie. "."
 * @param {string} settings.separator string that is used to separate currency magnitudes, ie. ","
 * @returns {string} formatted currency string for display in input
 */
export const getDisplayValue = ({ value, radix, separator }) => {
    const withoutSep = getValueWithoutSeparator(value, separator);
    const valueSplit = withoutSep.split("");
    const radixIndex = valueSplit.indexOf(radix);
    const wholeNumberValue = withoutSep.split(radix)[0];
    if (wholeNumberValue.length > 3) {
        const wholeNumberSplit = wholeNumberValue.split("");
        const wholeNumberSplitReverse = wholeNumberSplit.reverse();
        const wholeNumberReverseWithSeparators = addSeparatorToReverseArray(
            wholeNumberSplitReverse,
            separator,
        );
        const wholeNumberCorrectOrder =
            wholeNumberReverseWithSeparators.reverse();
        const dollars = wholeNumberCorrectOrder.join("");
        if (radixIndex === -1) {
            return dollars;
        }
        const cents = withoutSep.split(radix)[1];
        return `${dollars}${radix}${cents}`;
    }
    return value;
};

/**
 * @public
 * @function getIndexOfNewChar
 * @description function that will return the correct index of the newly added character after it has gone through currency formatting
 * @param {object} settings object that contains the settings needed to get index of new char
 * @param {Array.<{value: string, new: boolean}>} settings.currentArray array that contains items that represent characters in the input. Item in array has two properties:
 * First property is the value of the character, Second property is if this character was the one added with the latest action.
 * @param {string} settings.radix string that denotes a fractional number, ie. "."
 * @param {string} settings.separator string that is used to separate currency magnitudes, ie. ","
 * @returns {number} index of the new character in the displayable array
 */
export const getIndexOfNewChar = ({ currentArray, radix, separator }) => {
    // remove separators
    const withoutSep = currentArray.filter((char) => char.value !== separator);
    const separatorItem = { value: separator, new: false };
    const radixIndex = withoutSep.map((char) => char.value).indexOf(radix);
    if (radixIndex === -1) {
        // this is a whole number value
        const wholeNumberSplitReverse = withoutSep.reverse();
        const wholeNumberReverseWithSeparators = addSeparatorToReverseArray(
            wholeNumberSplitReverse,
            separatorItem,
        );
        const wholeNumberCorrectOrder =
            wholeNumberReverseWithSeparators.reverse();
        const indexOfNewChar = wholeNumberCorrectOrder
            .map((item) => item.new)
            .indexOf(true);
        return indexOfNewChar;
    }
    // this value includes fractional digits
    // slice off cents, add the separators to the whole numbers, and then combine
    const cents = withoutSep.slice(radixIndex, withoutSep.length);
    const wholeNumbers = withoutSep.slice(0, radixIndex);
    const wholeNumbersReverse = wholeNumbers.reverse();
    const wholeNumberReverseWithSeparators = addSeparatorToReverseArray(
        wholeNumbersReverse,
        separatorItem,
    );
    const wholeNumberCorrectOrder = wholeNumberReverseWithSeparators.reverse();
    const fullValueArray = [...wholeNumberCorrectOrder, ...cents];
    const indexOfNewChar = fullValueArray.map((item) => item.new).indexOf(true);
    return indexOfNewChar;
};

/**
 * @public
 * @function removeLeadingZeros
 * @description function that uses Number() and String() to return an accurate currency value
 * @param {string} stringAmount string that is the current unformattedCurrencyValue
 * @param {string} radix string that represents the radix character in currency (.)
 * @returns {string} valueInString string that is an accurate currency value without leading zeros
 */
export const removeLeadingZeros = (stringAmount, radix) => {
    const stringIncludesRadix = stringAmount.includes(radix);
    if (!stringIncludesRadix) {
        const numberValue = Number(stringAmount);
        const valueInString = String(numberValue);
        return valueInString;
    }
    const stringAmountSplitRadix = stringAmount.split(radix);
    // eslint made me do it
    const [dollars, cents] = stringAmountSplitRadix;
    const dollarValue = Number(dollars); // this still includes radix and cents!
    const valueInString = `${dollarValue}${
        stringIncludesRadix && cents.length > 0 ? radix + cents : ""
    }`;
    return valueInString;
};

/**
 * @public
 * @function getCleanUnformattedFrequencyValue
 * @description function that cleans up unformattedCurrencyValue by accounting for leading zeros, radix, and cents data
 * @param {string} unformattedCurrencyValue string that is the current unformattedCurrencyValue
 * @param {string} radix string that represents the radix character in currency (.)
 * @returns {string} smoothed out unformatted value that has an accurate currency value and radix/cents data
 */
export const getCleanUnformattedFrequencyValue = (
    unformattedCurrencyValue,
    radix,
) => {
    const valueWithoutLeadingZeros = removeLeadingZeros(
        unformattedCurrencyValue,
        radix,
    );
    const hasRadix = valueWithoutLeadingZeros.includes(radix);
    if (!hasRadix) {
        return valueWithoutLeadingZeros;
    }
    const valueSplitRadix = valueWithoutLeadingZeros.split(radix);
    const dollars = valueSplitRadix[0];
    const cents = valueSplitRadix[1];
    if (cents.length === 1) {
        return `${dollars}${radix}${cents}0`;
    }
    return valueWithoutLeadingZeros;
};
