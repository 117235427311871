import { constants } from "@qgiv/core-js";
import { getFieldIsVisibleBasedOnConditionals } from "@qgiv/core-donor";

const prepareValuesForConfirmationFieldsSubmit = ({
    allFieldsAndGroups,
    conditionalLogicOptions,
    displayableCustomFields,
    formId = "",
    values = {},
    transaction = {},
}) => {
    const {
        ENUMS: { EntityType },
    } = constants;
    // Extract a list of all the fields other than the one whose values are
    // there for the purposes of running conditional logic
    const fieldNames = Object.keys(values);
    const systemFieldsValuesForSubmit = {};
    const customFieldsValuesForSubmit = { Custom_Fields: {} };

    // Copy system field and custom field values into the shapes
    // required for submission. Custom Fields must be placed into
    // an object with the Custom_Fields key
    fieldNames.forEach((fieldName) => {
        if (fieldName === "Anonymity") {
            systemFieldsValuesForSubmit.Anonymity = !!values[fieldName];
        } else if (fieldName === "Do_Not_Show_Name") {
            systemFieldsValuesForSubmit.Show_Name = !values[fieldName];
        } else if (fieldName === "Do_Not_Show_Amount") {
            systemFieldsValuesForSubmit.Show_Amount = !values[fieldName];
        } else if (
            fieldName === "Employer" ||
            fieldName === "Opt_In" ||
            fieldName === "Phone"
        ) {
            systemFieldsValuesForSubmit[fieldName] = values[fieldName];
        } else {
            // Any remaining fieldName that reaches this code block
            // belongs to a custom field.
            const currentCustomField =
                displayableCustomFields.find(
                    (customField) => `${customField.id}` === fieldName,
                ) || {};

            // early return if custom field is not found;
            if (Object.keys(currentCustomField).length === 0) {
                return;
            }
            const { conditions = [] } = currentCustomField;
            const condition = conditions?.[0] || {};
            const hasCondition = Object.keys(condition).length > 0;

            let visible = true;
            if (hasCondition) {
                visible = getFieldIsVisibleBasedOnConditionals({
                    condition,
                    allFieldsAndGroups,
                    options: conditionalLogicOptions,
                    values,
                });
            }
            if (visible) {
                customFieldsValuesForSubmit.Custom_Fields[fieldName] =
                    values[fieldName];
            }
        }
    });

    const valuesForConfirmationFieldsSubmit = {
        Entity: formId,
        Entity_Type: EntityType.FORM,
        Transaction_ID: transaction.id,
        ...systemFieldsValuesForSubmit,
        ...customFieldsValuesForSubmit,
    };

    return valuesForConfirmationFieldsSubmit;
};

export default prepareValuesForConfirmationFieldsSubmit;
