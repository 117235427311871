import { createSlice } from "@reduxjs/toolkit";
import { receipts as initialState } from "../initialState";
import {
    selectReceiptFieldsSelector,
    selectReceiptSelector,
} from "../selectors/receiptSelectors";

const getReceiptsInitialState = () => initialState;
export const receiptsInitialState = getReceiptsInitialState();

export const slice = createSlice({
    name: "receipts",
    initialState: receiptsInitialState,
    reducers: {
        setReceipts: (state, action) => action.payload,
    },
});

// actions
export const { setReceipts } = slice.actions;

// selectors
export const selectReceipt = (state) => selectReceiptSelector(state);

export const selectReceiptId = (state) => state.currentReceipt;

export const selectReceiptTransaction = (state) => {
    const { currentReceipt = "" } = state;
    return state.receipts[currentReceipt].transaction;
};

export const selectCurrentReceiptData = (state) => {
    const { currentReceipt = "" } = state;
    return state.receipts[currentReceipt];
};

export const selectReceiptDonationRecipientName = (state) => {
    const { currentReceipt = "" } = state;
    return (
        state.receipts[currentReceipt].transaction?.donations?.[0]
            ?.entityName || ""
    );
};

export const selectReceiptDonationMessage = (state) => {
    const { currentReceipt = "" } = state;
    return (
        state.receipts[currentReceipt].transaction?.donations?.[0]?.message ||
        ""
    );
};

export const selectReceiptTotal = (state) => {
    const { currentReceipt = "" } = state;
    return state.receipts[currentReceipt].total;
};

export const selectReceiptFields = (state) =>
    selectReceiptFieldsSelector(state);

export default slice.reducer;
