import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import {
    getHasDisplayableDedicationOnThisPage,
    prepareValuesForSubmit,
} from "@qgiv/donation-form";
import { getSessionStorageKey } from "@qgiv/core-donor";
import {
    selectCurrentPage,
    selectIsSinglePageForm,
    selectIsSubmittingUsingExpressCheckout,
    selectStateForSessionStorage,
    setCurrentPage,
    setErrors,
    setHasSubmittedForm,
} from "../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { setReceipts } from "../../../redux/slices/receiptsSlice";
import { setCurrentReceipt } from "../../../redux/slices/currentReceipt";
import {
    selectAllDonationDetails,
    updateSubmitDonation,
} from "../../../redux/slices/donationDetailsSlice";
import { setDonorAccount } from "../../../redux/slices/donorAccountSlice";
import {
    selectAllFieldsAndGroups,
    selectAllDisplayableCustomFields,
    selectHasDisplayableMatchingGiftOnPage,
} from "../../../redux/slices/fieldsSlice";
import { selectDedicationSettings } from "../../../redux/slices/dedicationSettingsSlice";
import {
    selectCurrentDisplay,
    selectSubmissionType,
} from "../../../redux/slices/configSettingsSlice";
import { selectAllDonationSettings } from "../../../redux/slices/donationSettingsSlice";
import { selectAssocInfoUrlShortcuts } from "../../../redux/slices/urlShortcutsSlice";
import { selectRestrictions } from "../../../redux/slices/restrictionSettingsSlice";
import { selectSmsData } from "../../../redux/slices/smsDataSlice";
import { selectHasActiveFulfillment } from "../../../redux/slices/promiseTransactionSlice";
import { selectAbandonedGiftToken } from "../../../redux/slices/abandonedGiftSlice";
import SubmitDonation from "./SubmitDonation";

const ConnectedSubmitDonation = () => {
    const {
        ENUMS: { CmsPageStandardizer },
    } = constants;
    const dispatch = useDispatch();
    const setSubmitDonationFalse = () => {
        dispatch(updateSubmitDonation(false));
    };

    const setReceiptData = (receiptId, receipts) => {
        dispatch(setCurrentReceipt(receiptId));
        dispatch(setReceipts(receipts));
    };

    const setDonorAccountData = (donorAccount) => {
        dispatch(setDonorAccount(donorAccount));
    };

    const setCurrentPageValue = () => {
        dispatch(setCurrentPage(CmsPageStandardizer.CONFIRMATION));
    };

    const setErrorsFromAPICall = (errors) => {
        dispatch(setErrors(errors));
    };

    const setHasSubmittedFormValue = () => {
        dispatch(setHasSubmittedForm(true));
    };

    const isSinglePageForm = useSelector(selectIsSinglePageForm);

    const formSettings = useSelector(selectAllFormSettings);
    const { id: formId } = formSettings;
    const currentPage = useSelector(selectCurrentPage);
    const donationDetailsFromSelector = useSelector(selectAllDonationDetails);
    const customFields = useSelector(selectAllDisplayableCustomFields);
    const donationSettings = useSelector(selectAllDonationSettings);
    const { activeAmounts = [], useDynamicAmounts = false } = donationSettings;
    const dedicationSettings = useSelector(selectDedicationSettings);
    const currentDisplay = useSelector(selectCurrentDisplay);
    const submissionType = useSelector(selectSubmissionType);
    const restrictions = useSelector(selectRestrictions);
    const hasDisplayableMatchingGiftOnThisPage = useSelector((state) =>
        selectHasDisplayableMatchingGiftOnPage(state, currentPage),
    );
    const assocInfo = useSelector(selectAssocInfoUrlShortcuts);

    const isSubmittingUsingExpressCheckout = useSelector(
        selectIsSubmittingUsingExpressCheckout,
    );
    const hasDisplayableDedicationOnThisPage =
        getHasDisplayableDedicationOnThisPage({
            currentDisplay,
            currentPage,
            dedicationSettings,
        });
    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    const { setSubmitting, values } = useFormikContext();

    // To avoid collision, remap customFields in donationDetails to customFieldValues
    const donationDetails = {
        ...donationDetailsFromSelector,
        customFieldValues: { ...donationDetailsFromSelector.customFields },
    };

    const donationAmountFromRedux =
        donationDetailsFromSelector?.giftDetails?.subtotal;
    const hasSelectedRecurringDonation =
        donationDetailsFromSelector?.giftDetails?.hasSelectedRecurringDonation;

    const smsData = useSelector(selectSmsData);

    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);

    const conditionalLogicOptions = useMemo(
        () => ({
            donationAmountFromRedux,
            hasSelectedRecurringDonation,
            usesReduxDonationAmountValue: true,
            usesReduxRecurringValue: true,
        }),
        [donationAmountFromRedux, hasSelectedRecurringDonation],
    );

    const abandonedGiftToken = useSelector(selectAbandonedGiftToken);

    const sessionStorageData = useSelector(selectStateForSessionStorage);
    const sessionStorageKey = getSessionStorageKey(formId);

    const preparedValues = prepareValuesForSubmit({
        abandonedGiftToken,
        activeAmounts,
        allFieldsAndGroups,
        assocInfo,
        conditionalLogicOptions,
        currentDisplay,
        currentPage,
        customFields,
        donationDetails,
        formSettings,
        hasActiveFulfillment,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        isUsingSmartAmounts: useDynamicAmounts,
        restrictions,
        submissionType,
        smsData,
        values,
        isSubmittingUsingExpressCheckout,
        donationSettings,
        isSinglePageForm,
    });

    return (
        <SubmitDonation
            preparedValues={preparedValues}
            setSubmitDonationFalse={setSubmitDonationFalse}
            setReceiptData={setReceiptData}
            setCurrentPageValue={setCurrentPageValue}
            setDonorAccountData={setDonorAccountData}
            setErrorsFromAPICall={setErrorsFromAPICall}
            setHasSubmittedFormValue={setHasSubmittedFormValue}
            setSubmitting={setSubmitting}
            sessionStorageData={sessionStorageData}
            sessionStorageKey={sessionStorageKey}
        />
    );
};

export default ConnectedSubmitDonation;
