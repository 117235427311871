import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { constants, isDisplayable } from "@qgiv/core-js";
import { selectMatchingGiftSettings } from "../../../redux/slices/donationSettingsSlice";
import {
    selectConfig,
    selectIsCms,
} from "../../../redux/slices/configSettingsSlice";
import { selectAllSystemFields } from "../../../redux/slices/fieldsSlice";
import { selectHasActiveFulfillment } from "../../../redux/slices/promiseTransactionSlice";
import MatchingGiftControl from "./MatchingGiftControl";

const ConnectedMatchingGiftControl = ({ controlId }) => {
    const {
        ENUMS: {
            EntityType: { SYSTEM_FIELD_GROUP_MATCHING_GIFTS },
        },
    } = constants;
    const { enableMatching } = useSelector(selectMatchingGiftSettings);

    const { currentDisplay } = useSelector(selectConfig);
    const isCms = useSelector(selectIsCms);

    const allFieldsAndGroups = useSelector(selectAllSystemFields);

    const matchingGiftFieldGroup = allFieldsAndGroups.find(
        (field) => Number(field.type) === SYSTEM_FIELD_GROUP_MATCHING_GIFTS,
    );
    const displayOptions = matchingGiftFieldGroup
        ? matchingGiftFieldGroup.options
        : {};
    const displayable = isDisplayable(displayOptions, currentDisplay);

    const isMatchingGiftEnabled = enableMatching;

    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    return isMatchingGiftEnabled &&
        (displayable || isCms) &&
        !hasActiveFulfillment ? (
        <MatchingGiftControl controlId={controlId} />
    ) : null;
};

ConnectedMatchingGiftControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedMatchingGiftControl;
