import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useFormikContext } from "formik";
import DonorAccountUsername from "./DonorAccountUsername";
import PasswordAndConfirmation from "../PasswordAndConfirmation";
import Button from "../Button";
import "./DonorAccountForm.scss";

const DonorAccountForm = ({ controlId, saveButton = "Save" }) => {
    const {
        errors = {},
        handleBlur = () => {},
        handleChange = () => {},
        touched = {},
        values = {},
    } = useFormikContext();

    const {
        Password = "",
        Password_Confirm = "",
        Company_Donation = false,
        First_Name = "",
        Last_Name = "",
    } = values;
    const passwordAndConfirmationProps = {
        controlId,
        errors,
        handleBlur,
        handleChange,
        Password,
        Password_Confirm,
        touched,
    };
    const usernameProps = {
        handleChange,
        handleBlur,
        touched,
        errors,
        values: { First_Name, Last_Name },
    };

    const hasEnteredPassword =
        Password.length > 0 && Password_Confirm.length > 0;

    const disableSaveButton =
        !hasEnteredPassword || Object.keys(errors).length > 0;

    return (
        <div className="donor-account-form">
            {/* in case the donor is a company we need to grab first/lastName to create an account */}
            {Company_Donation && (
                // eslint-disable-next-line tailwindcss/classnames-order
                <div className="grid -padding-left--15">
                    <DonorAccountUsername {...usernameProps} />
                </div>
            )}
            <PasswordAndConfirmation {...passwordAndConfirmationProps} />
            <div className="col col--12 -padding-top--20">
                <Button
                    type="submit"
                    disabled={disableSaveButton}
                    classes={cx(disableSaveButton && "-disabled")}
                >
                    {saveButton}
                </Button>
            </div>
        </div>
    );
};

DonorAccountForm.propTypes = {
    controlId: PropTypes.string.isRequired,
    saveButton: PropTypes.string.isRequired,
};

export default DonorAccountForm;
