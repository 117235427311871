/**
 * @function validateRouting
 * @param {string} num String to be validated as bank routing number.
 * @returns {boolean} Indicates whether bank routing number is valid
 * @description Validates bank routing card
 * @see https://en.wikipedia.org/wiki/ABA_routing_transit_number
 * @see http://www.brainjar.com/js/validation/
 */
// eslint-disable-next-line import/prefer-default-export
export const validateRouting = (num) => {
    let n = 0;
    for (let i = 0; i < num.length; i += 3) {
        n +=
            parseInt(num.charAt(i), 10) * 3 +
            parseInt(num.charAt(i + 1), 10) * 7 +
            parseInt(num.charAt(i + 2), 10);
    }
    return n !== 0 && n % 10 === 0;
};
