import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { CardImage } from "@qgiv/core-donor";
import "./StandardAmountCard.scss";
import StandardAmountCardDescription from "./StandardAmountCardDescription";

const StandardAmountCard = ({
    amountAlignment,
    checked,
    descr = "",
    displayDescriptionOnNewLine,
    image,
    label,
    showImage,
    showTitle,
    title = "",
}) => (
    <div className="amount-card -cursor--pointer">
        <div className="qg-vendor-card__inner amount-card__inner">
            <div className="amount-card__inner--col">
                {showImage && (
                    <figure className="amount-card__figure">
                        <CardImage {...image} />
                    </figure>
                )}
                <div
                    className={cx(
                        "amount-card__label",
                        checked && "amount-card__label--selected",
                    )}
                >
                    <div
                        className={cx(
                            "amount-card__desc-text-container",
                            !title && !descr
                                ? "amount-card__desc-text-container--centered"
                                : `amount-card__desc-text-container--${amountAlignment}`,
                        )}
                    >
                        {(showTitle || descr) && (
                            <div
                                className={`amount-card__desc-text amount-card__desc-text--${amountAlignment}`}
                            >
                                {showTitle && (
                                    <div className="grid">
                                        <div className="col col--12 col--no-top-gutter -type--large">
                                            {title}
                                        </div>
                                    </div>
                                )}
                                {descr && !displayDescriptionOnNewLine && (
                                    <StandardAmountCardDescription
                                        amountAlignment={amountAlignment}
                                        descr={descr}
                                        displayDescriptionOnNewLine={
                                            displayDescriptionOnNewLine
                                        }
                                    />
                                )}
                            </div>
                        )}

                        <div className="grid">
                            <div className="col col--12 col--no-top-gutter">
                                <div className="amount-card__content -type--large">
                                    {label}
                                </div>
                            </div>
                        </div>
                    </div>

                    {descr && displayDescriptionOnNewLine && (
                        <StandardAmountCardDescription
                            amountAlignment={amountAlignment}
                            descr={descr}
                            displayDescriptionOnNewLine={
                                displayDescriptionOnNewLine
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    </div>
);

StandardAmountCard.propTypes = {
    checked: PropTypes.bool.isRequired,
    descr: PropTypes.string,
    image: PropTypes.shape({}).isRequired,
    displayDescriptionOnNewLine: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    showImage: PropTypes.bool.isRequired,
    showTitle: PropTypes.bool.isRequired,
    amountAlignment: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default StandardAmountCard;
