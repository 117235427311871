import { createSlice } from "@reduxjs/toolkit";
import { cmsSettings as initialState } from "../initialState";
import {
    selectAllCmsSettingsSelector,
    selectCmsPageOrderSelector,
    selectAllCmsPagesSelector,
    selectConfirmationFieldControlsSelector,
    selectCmsContainersByPageSelector,
    selectCmsControlsByPageSelector,
    selectCmsControlByIdByPageSelector,
    selectCmsControlByIdSelector,
    selectCmsControlByTypeByPageSelector,
    selectHasTopBottomContentByPageSelector,
} from "../selectors/cmsSettingsSelectors";

import {
    updateControlAction,
    updateSinglePageControlAction,
} from "../actions/cmsSettingsActions/cmsSettingsActions";

const getCmsSettingsInitialState = () => initialState;
export const cmsSettingsInitialState = getCmsSettingsInitialState();

export const slice = createSlice({
    name: "cmsSettings",
    initialState: cmsSettingsInitialState,
    reducers: {
        setCmsSettings: (state, action) => action.payload,
        updateControl: (state, action) => updateControlAction(state, action),
        updateSinglePageControl: (state, action) =>
            updateSinglePageControlAction(state, action),
    },
});

// actions
export const { setCmsSettings, updateControl, updateSinglePageControl } =
    slice.actions;

// -------------------------------------------------------------------------
// MARK: CMS Containers and Controls Selectors
// -------------------------------------------------------------------------

export const selectAllCmsSettings = (state) =>
    selectAllCmsSettingsSelector(state);

export const selectCmsPageOrder = (state) => selectCmsPageOrderSelector(state);

export const selectAllCmsPages = (state) => selectAllCmsPagesSelector(state);

export const selectConfirmationFieldControls = (state) =>
    selectConfirmationFieldControlsSelector(state);

export const selectCmsContainersByPage = (state, page) =>
    selectCmsContainersByPageSelector(state, page);

export const selectCmsControlsByPage = (state, page) =>
    selectCmsControlsByPageSelector(state, page);

export const selectCmsControlByIdByPage = (state, id, page) =>
    selectCmsControlByIdByPageSelector(state, id, page);

// This is a non-performant function that searches all pages cms data
// use selectCmsControlByIdByPage if you are able to know the page Enum
// this is mainly used for common widgets (Heading, Text, Spacing, CustomContent)
export const selectCmsControlById = (state, id) =>
    selectCmsControlByIdSelector(state, id);

// This selector will return the first control of a given type on a page
// It is used when you need data from a type of control that exists on multiple pages
// i.e. The EVENT_HEADER control
export const selectCmsControlByTypeByPage = (state, type, page) =>
    selectCmsControlByTypeByPageSelector(state, type, page);

export const selectHasTopBottomContentByPage = (
    state,
    currentPage,
    isSinglePageForm,
) =>
    selectHasTopBottomContentByPageSelector(
        state,
        currentPage,
        isSinglePageForm,
    );

export default slice.reducer;
