import React from "react";
import PropTypes from "prop-types";
import { TextButton } from "../Button";

const TogglePaymentMethodsButton = ({
    displayTogglePaymentMethodsButton,
    handleTogglePaymentMethodsButtonClick,
    text,
}) =>
    displayTogglePaymentMethodsButton ? (
        <div className="col col--12">
            <div className="grid">
                <div className="col col--12 -text--center -margin-bottom--15">
                    <p>
                        <TextButton
                            handleClick={handleTogglePaymentMethodsButtonClick}
                        >
                            <strong>{text} </strong>
                        </TextButton>
                    </p>
                </div>
            </div>
        </div>
    ) : null;

TogglePaymentMethodsButton.propTypes = {
    displayTogglePaymentMethodsButton: PropTypes.bool.isRequired,
    handleTogglePaymentMethodsButtonClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default TogglePaymentMethodsButton;
