import { event, ga, initialize, pageview, set } from "react-ga";

import { constants, env } from "@qgiv/core-js";

// -------------------------------------------------------------------------
// NOTE: We are using react-ga for loading GA and sending GA data
// @see: https://github.com/react-ga/react-ga
// -------------------------------------------------------------------------

// Flag to enable/disable analytics based on environment and host.
// Currently only enabled for production builds, and on staging/main/secure.
const isEnabled =
    env.IS_PROD() && (env.IS_STAGING_HOST() || env.IS_PROD_HOST());

// Grab our tracking ids
const { gaTrackingId, gaDevTrackingId } = constants;

/**
 * @function initAnalytics
 * @description Initialize google analytics tracking with QgivAnalytics tracker
 */
export const initAnalytics = () => {
    const trackingId = env.IS_PROD_HOST() ? gaTrackingId : gaDevTrackingId;
    const debug = env.IS_DEV();
    if (isEnabled) {
        // Initialize GA
        initialize(
            [
                {
                    trackingId,
                    gaOptions: {
                        name: "QgivAnalytics",
                    },
                },
            ],
            {
                debug,
                alwaysSendToDefaultTracker: false,
            },
        );
        // GA settings
        ga("QgivAnalytics.set", "anonymizeIp", true);
        // Load GA ecommerce
        ga("QgivAnalytics.require", "ecommerce");
    }
};

/**
 * @function trackEvent
 * @param {object} data Data to send for tracking a GA event
 */
export const trackEvent = (data) => {
    if (isEnabled && data && typeof data === "object") {
        event(
            {
                ...data,
            },
            ["QgivAnalytics"],
        );
    }
};

/**
 * @function trackPageView
 */
export const trackPageView = () => {
    if (isEnabled) {
        const page = window.location.pathname;
        set(
            {
                path: page,
            },
            ["QgivAnalytics"],
        );
        pageview(page, ["QgivAnalytics"]);
    }
};
