import React from "react";
import cx from "classnames";
import { Checkbox } from "@qgiv/core-donor";
import "./RestrictionCardLabel.scss";

/**
 * @typedef {import("@qgiv/core-donor").ComponentTypes.CheckboxProps} CheckboxProps
 * @typedef {import("react")} React
 */

/**
 *
 * @param {object} props
 * @param {CheckboxProps} props.checkboxProps
 * @param {string} props.description
 * @param {React.MouseEventHandler} props.handleRestrictionCardLabelClick
 * @param {string} props.htmlFor
 * @param {string} props.name
 * @param {boolean} props.selected
 * @param {boolean} props.showDescription
 * @param {boolean} props.showError
 * @returns {React.ReactElement}
 */
const RestrictionCardLabel = ({
    checkboxProps,
    description,
    handleRestrictionCardLabelClick,
    htmlFor,
    name,
    selected,
    showDescription,
    showError,
}) => {
    const restrictionCardLabelClasses = cx(
        "-padding-left--15",
        "-padding-right--15",
        "-cursor--pointer",
        "restriction-card-label",
        selected && "restriction-card-label--selected",
        showError && "restriction-card-label--error",
    );

    // Clicks on the label are responsible for selecting and de-selecting a restriction
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <label
            className={restrictionCardLabelClasses}
            htmlFor={htmlFor}
            onClick={handleRestrictionCardLabelClick}
        >
            {/* eslint-disable-next-line tailwindcss/classnames-order */}
            <div className="-padding-left--15 grid restriction-card-label__header">
                <div>
                    <Checkbox {...checkboxProps} />
                </div>
                <div className="-padding-bottom--15 restriction-card-label__name">
                    <span className="-type--large">{name}</span>
                </div>
            </div>
            {showDescription && (
                <div className="-padding-bottom--15 -padding-left--30">
                    <span className=" -type--small">{description}</span>
                </div>
            )}
        </label>
    );
};

export default RestrictionCardLabel;
