import React from "react";
import PropTypes from "prop-types";
import { SearchField } from "@qgiv/core-donor";

const RecipientSearchBar = ({
    handleSearchClearBtnClick = () => {},
    handleSearchChange = () => {},
    searchTerm = "",
    placeholder = "Search This Event",
}) => (
    <SearchField
        handleClearBtnClick={handleSearchClearBtnClick}
        onChange={handleSearchChange}
        value={searchTerm}
        placeholder={placeholder}
    />
);

RecipientSearchBar.propTypes = {
    handleSearchClearBtnClick: PropTypes.func,
    handleSearchChange: PropTypes.func,
    searchTerm: PropTypes.string,
    placeholder: PropTypes.string,
};

export default RecipientSearchBar;
