/**
 * @public
 * @function getAccountInitialValues
 * @param {boolean} hasSuffixField Suffix field is active
 * @param {boolean} hasTitleField titleFieldIsRequired.exists
 * @returns {object} initial value for Account in formik
 */
const getAccountInitialValues = (hasSuffixField, hasTitleField) => ({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Donor_Account_ID: "",
    ...(hasTitleField && {
        Title: "",
    }),
    ...(hasSuffixField && {
        Suffix: "",
    }),
});

export default getAccountInitialValues;
