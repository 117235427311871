import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    getHasRecurringAmountToDisplay,
    getShouldRenderMultiRestriction,
} from "@qgiv/donation-form";
import { selectAllDonationSettings } from "../../../../redux/slices/donationSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectSmsData } from "../../../../redux/slices/smsDataSlice";
import DonatedGiftTabOneTime from "./DonatedGiftTabOneTime";

const ConnectedDonatedGiftTabOneTime = ({ controlId }) => {
    const {
        activeAmounts,
        activePlans,
        pledgeActive,
        recurringCtaBefore,
        restrictionGivingType,
    } = useSelector(selectAllDonationSettings);

    const smsData = useSelector(selectSmsData);

    const { shouldDisplayTextToDonateInterface = false } = smsData;

    const { enableRecur } = useSelector(selectAllFormSettings);
    const hasRecurringDonationAmountToDisplay = getHasRecurringAmountToDisplay(
        activeAmounts,
        activePlans,
        pledgeActive,
    );
    const showUpgradeNudgeButton =
        enableRecur &&
        recurringCtaBefore &&
        hasRecurringDonationAmountToDisplay;

    const isMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    // When T2D comes in without an amount or Edit Amount is clicked,
    // standard amounts will display.
    const isStandardAmounts =
        !isMultiRestriction && !shouldDisplayTextToDonateInterface;

    const donatedGiftTabOneTimeProps = {
        controlId,
        isMultiRestriction,
        isStandardAmounts,
        shouldDisplayTextToDonateInterface,
        showUpgradeNudgeButton,
    };

    return <DonatedGiftTabOneTime {...donatedGiftTabOneTimeProps} />;
};

ConnectedDonatedGiftTabOneTime.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonatedGiftTabOneTime;
