import React from "react";
import PropTypes from "prop-types";
import { constants, log, getAcceptedCCs, getRegions } from "@qgiv/core-js";
import { validateFormAfterFormValuesHaveUpdated } from "@qgiv/core-validation";
import { authenticateMerchantValidation } from "../../api";
import ApplePaySubmit from "./ApplePaySubmit";

import "./ApplePayPaymentMethod.scss";

const ApplePayPaymentMethod = ({
    handleErrorsPriorToPaymentSheetDisplay,
    handleSubmissionAfterAuthorization,
    handleSubmissionUsingRedux,
    isApplePay,
    isCms,
    isDisabled = false,
    isSubmitting,
    onBeforeOpen,
    onBeforeSubmission,
    setFieldValue,
    setValues,
    shouldDisplayApplePay,
    validateForm,
    values,
    VisaAmexDisc,
}) => {
    const {
        ENUMS: { PaymentType },
    } = constants;

    // -------------------------------------------------------------------------
    // TODO: Consolidate the handleSubmissionUsingFormik, handleSubmissionWithRedux, and
    // the handleSubmissionAfterAuthorization props into a single handleSubmission
    // function that is passed into every instance of the ApplePayPaymentMethod.js
    // component across all of our applications.
    // -------------------------------------------------------------------------
    // default case
    const handleSubmissionUsingFormik = (
        session,
        regions,
        billingContact,
        ApplePaySession,
        payment,
    ) => {
        const applePayBillingCountryRegions = regions.data;
        const applePayBillingStateName = applePayBillingCountryRegions.find(
            (region) =>
                region.stateCode ===
                billingContact.administrativeArea.toUpperCase(),
        ).stateName;
        // Store the Apple Pay payment data
        // Uncheck the Same as Mailing Address field
        // Map the Apple Pay billing data to the keys on the values
        // object that are used to store this data in the form
        // Because setting the field value for validation does
        // not mean that the data on the values object in this
        // component has changed the Payment Type needs to set
        // to Apple Pay once again
        // This ensures that when the form is submitted it uses
        // the Apple Pay instead of the original Payment Type
        const platformAgnosticApplePayValues = {
            Apple_Pay_Token: payment,
            Payment_Type: PaymentType.APPLEPAY,
            Billing_Address_Use_Mailing: false,
            Billing_Address: billingContact.addressLines[0],
            Billing_City: billingContact.locality,
            Billing_State: applePayBillingStateName,
            Billing_Zip: billingContact.postalCode,
            Billing_Country: billingContact.countryCode,
        };

        // Form specific integration of the values returned by Apple Pay
        // into the values that are associated with the form that is going
        // to be submitted
        const platformSpecificApplePayValues = onBeforeSubmission(
            platformAgnosticApplePayValues,
            billingContact,
        );

        // Construct a new values object using the Apple Pay data
        const valuesWithApplePayData = {
            ...values,
            ...platformSpecificApplePayValues,
        };

        // Update the form's data so that the Apple Pay data can be
        // passed along during form submission
        setValues(valuesWithApplePayData);

        // Use existing submission infrastructure to handle form submission
        // and analytics tracking after an Apple Pay transaction has been
        // successfully completed
        handleSubmissionAfterAuthorization();

        // Complete the payment authorization and dismiss the Apple
        // Pay payment sheet from the UI
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
    };

    // Once there are no validation errors, configure the payment
    // request, validate the merchant, handle payment method selection
    // authorize the payment and initiate the Apple Pay session
    const configureAndRunApplePaySession = () => {
        const { ApplePaySession } = window;
        const acceptedCCs = getAcceptedCCs(VisaAmexDisc);
        // Create an Apple Pay session where the Apple Pay version
        // and the details of the payment request object has been
        // set up to match those in system.payment.js
        const applePayVersion = 2;
        const defaultApplePayPaymentRequest = {
            currencyCode: "USD",
            countryCode: "US",
            requiredBillingContactFields: ["postalAddress"],
            requiredShippingContactFields: ["name", "email"],
            supportedNetworks: acceptedCCs,
            merchantCapabilities: [
                "supportsCredit",
                "supportsDebit",
                "supports3DS",
            ],
            total: {
                label: "Total",
                amount: "0.00",
            },
        };
        // Helper functions used when constructing payment request to Apple
        const formatAsAmount = (number) => {
            return parseFloat(number).toFixed(2);
        };
        const formatAsNumber = (value) => {
            const valueAsNumber = Number(value) > 0 ? Number(value) : 0;
            return valueAsNumber;
        };
        const defaultAdditionalApplePayPaymentRequestData = {};

        // Additional data to be modified by platform specific logic and merged
        // into the final payment request
        defaultAdditionalApplePayPaymentRequestData.lineItems = [];
        defaultAdditionalApplePayPaymentRequestData.total = {
            label: "Total",
            amount: 0,
        };

        // Form specific part of the payment request setup
        const applePayPaymentRequest = onBeforeOpen(
            defaultApplePayPaymentRequest,
            defaultAdditionalApplePayPaymentRequestData,
            formatAsAmount,
            formatAsNumber,
        );

        // Create Apple Pay session
        const session = new ApplePaySession(
            applePayVersion,
            applePayPaymentRequest,
        );

        // Event handler that is called when the payment sheet is displayed
        // that is used to request and return a merchant session
        session.onvalidatemerchant = function (event) {
            authenticateMerchantValidation(event, session);
        };

        // Event handler that is run when the payment sheet is first
        // displayed and whenever a new payment method is selected
        // Adds the TOTAL line above the PAY TOTAL in the payment sheet
        session.onpaymentmethodselected = function () {
            const { total } = applePayPaymentRequest;

            session.completePaymentMethodSelection(total, [total]);
        };

        // Event handler that is run after the user authorizes an Apple Pay
        // payment using Touch ID, Face ID or a passcode
        session.onpaymentauthorized = function (event) {
            const { payment } = event;
            const { billingContact, shippingContact } = payment;
            // Use the shortcode for the billing country returned by Apple to
            // return a list of regions for that country and use this to map
            // the region shortcode returned by Apple to the region name
            // associated with data in the UI. The country code and the
            // administrative area are both converted to uppercase values to
            // ensure that differences in case does not invalidate a match
            getRegions(billingContact.countryCode).then((regions) => {
                if (handleSubmissionUsingRedux) {
                    handleSubmissionUsingRedux({
                        session,
                        regions,
                        ApplePaySession,
                        payment,
                    });
                } else {
                    handleSubmissionUsingFormik(
                        session,
                        regions,
                        billingContact,
                        ApplePaySession,
                        payment,
                    );
                }
            });
        };

        // Present the payment sheet and initiate the merchant validation process
        session.begin();
    };

    const handleApplePayButtonClick = () => {
        const handleValidationSuccess = (validationErrors) => {
            log("ATTEMPT TO VALIDATE: SUCCESS");
            log("validationErrors", validationErrors);
            const numberOfValidationErrors =
                Object.keys(validationErrors).length;

            if (numberOfValidationErrors > 0) {
                log(
                    "DISPLAY VALIDATION ERROR MESSAGE AND ABORT APPLE PAY LOGIC",
                );
                // Use existing submission infrastructure to handle and
                // display errors that are thrown because of validation
                // failing prior to the display of the Apple Pay
                // payment sheet
                handleErrorsPriorToPaymentSheetDisplay();
            } else {
                log("CONTINUE WITH APPLE PAY LOGIC");
                configureAndRunApplePaySession();
            }
        };

        const handleValidationError = (error) => {
            log("ATTEMPT TO VALIDATE: FAILURE");
            log(error);
        };

        // Do not run handler that displays payment sheet in CMS
        if (isCms || isDisabled) {
            return;
        }

        // Set the Payment_Type to ensure that the form is validated using
        // the Apple Pay requirements
        setFieldValue("Payment_Type", PaymentType.APPLEPAY);

        // Validate the form once the Payment_Type value has been updated so
        // that the appropriate errors object is generated
        validateFormAfterFormValuesHaveUpdated(
            validateForm,
            handleValidationSuccess,
            handleValidationError,
        );
    };

    return (
        <>
            {shouldDisplayApplePay && (
                <div className="-margin-bottom--15 apple-pay-payment-method">
                    <div className="apple-pay-payment-method__button-flex-container">
                        <ApplePaySubmit
                            handleApplePayButtonClick={
                                handleApplePayButtonClick
                            }
                            isApplePay={isApplePay}
                            isSubmitting={isSubmitting}
                            isDisabled={isDisabled}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

ApplePayPaymentMethod.propTypes = {
    isApplePay: PropTypes.bool.isRequired,
    isCms: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    // shouldDisplayApplePay can be undefined if
    // canMakePaymentsWithActiveCard has not resolved yet
    shouldDisplayApplePay: PropTypes.bool,
    validateForm: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    VisaAmexDisc: PropTypes.string.isRequired,
    // Form specific validation and event handlers
    handleErrorsPriorToPaymentSheetDisplay: PropTypes.func.isRequired,
    handleSubmissionAfterAuthorization: PropTypes.func.isRequired,
    handleSubmissionUsingRedux: PropTypes.func,
    onBeforeOpen: PropTypes.func.isRequired,
    onBeforeSubmission: PropTypes.func.isRequired,
};

export default ApplePayPaymentMethod;
