import React from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";

import { getQMaskConfig } from "./QMaskConfigs";

const _QMaskField = (
    {
        ariaLabel,
        ariaRequired = "false",
        ariaInvalid = "false",
        autoComplete,
        className,
        config,
        country = "US", // only used by tel QMask type
        disabled,
        id,
        name,
        onBlur,
        onChange,
        onClick,
        onFocus,
        onKeyDown,
        placeholder,
        readOnly,
        settings,
        type,
        value,
        tabIndex,
    },
    ref,
) => {
    const settingsWithFormik = {
        ...(settings && { ...settings }),
        ...(country && { values: { country } }),
    };

    const QMaskConfig = {
        ...getQMaskConfig(type, settingsWithFormik),
        ...config,
    };

    const getAriaInvalidObject = () => {
        if (ariaInvalid === "true") {
            return { "aria-invalid": ariaInvalid };
        }
        return {};
    };

    return (
        <IMaskInput
            aria-required={ariaRequired}
            aria-label={ariaLabel}
            autoComplete={autoComplete}
            id={id}
            name={name}
            className={className}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            onAccept={(val, QMask) => onChange(val, QMask)}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            type="text"
            ref={ref}
            masktype={type}
            tabIndex={tabIndex}
            {...getAriaInvalidObject()}
            {...QMaskConfig}
        />
    );
};

const QMaskField = React.forwardRef(_QMaskField);

_QMaskField.propTypes = {
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    ariaLabel: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    ariaInvalid: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    ariaRequired: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    autoComplete: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    config: PropTypes.shape(),
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    disabled: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    onClick: PropTypes.func,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    onFocus: PropTypes.func,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    onKeyDown: PropTypes.func,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    placeholder: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    readOnly: PropTypes.oneOf(["string", "bool"]),
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    settings: PropTypes.shape(),
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    tabIndex: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    country: PropTypes.string,
};

export default QMaskField;
