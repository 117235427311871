/**
 * @public
 * @function getCreditCardInitialValues
 * @description function that returns initial values for credit card fields
 * @param {boolean} enableCCPayments form setting for allowing credit cards to be used on the form
 * @returns {object} initial values for credit cards
 */
const getCreditCardInitialValues = (enableCCPayments) => {
    if (enableCCPayments) {
        return {
            Card_Number: "",
            Card_Exp_Date: "",
            Card_CVV: "",
        };
    }
    return {};
};

export default getCreditCardInitialValues;
