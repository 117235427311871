import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { getDonationMessageWithSelectedRecipient } from "@qgiv/donation-form";
import DonationMessage from "./DonationMessage";
import { selectIsCms } from "../../../../../redux/slices/configSettingsSlice";
import { selectAllSystemFields } from "../../../../../redux/slices/fieldsSlice";
import { selectSelectedRecipient } from "../../../../../redux/slices/donationDetailsSlice";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";

const ConnectedDonationMessage = () => {
    const {
        ENUMS: {
            FieldType: { DONATION_MESSAGE },
        },
    } = constants;

    const allFieldsAndGroups = useSelector(selectAllSystemFields);
    const selectedRecipient = useSelector(selectSelectedRecipient);
    const formSettings = useSelector(selectAllFormSettings);
    const eventTitle = formSettings.title;

    const donationMessageField =
        allFieldsAndGroups.find(
            (field) => Number(field.type) === DONATION_MESSAGE,
        ) || {};

    const isCms = useSelector(selectIsCms);
    const { errors, handleBlur, handleChange, touched, values } =
        useFormikContext();

    if (Object.keys(donationMessageField).length) {
        const { display_text_checkbox, display_text_paragraph, helpText } =
            donationMessageField;

        const { Donation_Message = "", Has_Donation_Message = false } = values;
        const replaceTag = "%RecipientName%";
        const replacedHelpText = getDonationMessageWithSelectedRecipient({
            helpText,
            selectedRecipient,
            eventTitle,
            replaceTag,
        });

        const donationMessageProps = {
            checkboxText: display_text_checkbox,
            Donation_Message,
            errors,
            handleBlur,
            handleChange,
            Has_Donation_Message,
            isCms,
            paragraphHelpText: replacedHelpText,
            paragraphPlaceholderText: display_text_paragraph,
            touched,
        };

        return <DonationMessage {...donationMessageProps} />;
    }
    return null;
};

export default ConnectedDonationMessage;
