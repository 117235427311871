import React from "react";
import "./RestrictionCardError.scss";

/**
 *
 * @param {object} props
 * @param {string} props.message
 * @returns {React.ReactElement}
 */
const RestrictionCardError = ({ message }) => (
    <p className="restriction-card-error -text--center -padding-bottom--15">
        {message}
    </p>
);

export default RestrictionCardError;
