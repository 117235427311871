import React from "react";
import PropTypes from "prop-types";
import "./MyAccount.scss";

const MyAccount = ({ additionalTextInnerHtml = "", headingInnerHtml = "" }) => (
    <div className="my-account -padding--30">
        <div className="grid">
            <div className="col col--12 -padding-top--0 -text--center">
                <h2
                    // Contains an optional %MYACCOUNT% suggested tag that
                    // contains a link that takes users to their giving
                    // dashboard.
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: headingInnerHtml,
                    }}
                />
            </div>
            <div className="col col--12 -padding-top--0 -text--center">
                <p
                    // Contains optional %FIRSTNAME% and %LASTNAME% tags
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: additionalTextInnerHtml,
                    }}
                />
            </div>
        </div>
    </div>
);

MyAccount.propTypes = {
    additionalTextInnerHtml: PropTypes.string.isRequired,
    headingInnerHtml: PropTypes.string.isRequired,
};

export default MyAccount;
