import React from "react";
import { Formik, Form } from "formik";
import { UpdateFieldErrorFocus, SEO } from "@qgiv/core-donor";
import ConnectedAdditionalDetailsPageContent from "./AdditionalDetailsPageContent";
import ConnectedDonationFormBase from "../templates/DonationFormBase";

/**
 * @param {object} props
 * @param {boolean} props.displayCaptcha
 * @param {string} props.documentTitle
 * @param {object} props.formikProps
 * @returns {React.ReactElement}
 */
const AdditionalDetailsPage = ({
    displayCaptcha = false,
    documentTitle = "",
    formikProps,
}) => (
    <Formik {...formikProps}>
        <ConnectedDonationFormBase>
            <SEO title={documentTitle} description="" />
            <Form>
                <ConnectedAdditionalDetailsPageContent
                    displayCaptcha={displayCaptcha}
                />
            </Form>
            {/* {submitDonationForm && <ConnectedSubmitDonationForm />} */}
            <UpdateFieldErrorFocus />
        </ConnectedDonationFormBase>
    </Formik>
);

export default AdditionalDetailsPage;
