import React from "react";
import PropTypes from "prop-types";
import { useBreakpoints, Icon, Tooltip } from "@qgiv/core-react";
import { useHover } from "../../hooks";
import CheckboxWithTooltip from "../Fields/CheckboxWithTooltip";
import Checkbox from "../Fields/Checkbox/Checkbox";

const DetailsStepAnonymity = ({
    handleBlur,
    handleChange,
    values,
    display_text,
}) => {
    const { isSmallScreen } = useBreakpoints();
    const isHoverable = useHover();

    return (
        <>
            {isSmallScreen || !isHoverable ? (
                <CheckboxWithTooltip
                    id="Anonymity"
                    name="Anonymity"
                    checked={values["Anonymity"]}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    label={display_text}
                    tooltipContent="Your name will be visible to the organization and payment processor, but will not be shared or displayed publicly."
                ></CheckboxWithTooltip>
            ) : (
                <Checkbox
                    id="Anonymity"
                    name="Anonymity"
                    checked={values["Anonymity"]}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                >
                    {display_text}
                    <span className="-padding-left--10">
                        <Tooltip
                            placement="bottom"
                            content="Your name will be visible to the organization and payment processor, but will not be shared or displayed publicly."
                            theme="dark"
                        >
                            <Icon
                                glyph="question-circle-regular"
                                type="FontAwesome"
                            ></Icon>
                        </Tooltip>
                    </span>
                </Checkbox>
            )}
        </>
    );
};

DetailsStepAnonymity.propTypes = {
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
        Anonymity: PropTypes.bool,
    }).isRequired,
    display_text: PropTypes.string.isRequired,
};

export default DetailsStepAnonymity;
