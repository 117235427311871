import { createSlice } from "@reduxjs/toolkit";
import { donationSettings as initialState } from "../initialState";
import {
    selectAllDonationSettingsSelector,
    selectEnableAbandonedGiftsSelector,
    selectMatchingGiftSettingsSelector,
    selectRecurringFrequencyByValueSelector,
} from "../selectors/donationSettingsSelectors";

const getDonationSettingsInitialState = () => initialState;
export const donationSettingsInitialState = getDonationSettingsInitialState();

export const slice = createSlice({
    name: "donationSettings",
    initialState: donationSettingsInitialState,
    reducers: {},
});

export const selectAllDonationSettings = (state) =>
    selectAllDonationSettingsSelector(state);

export const selectRecurringFrequencyByValue = (state, value) =>
    selectRecurringFrequencyByValueSelector(state, value);

export const selectMatchingGiftSettings = (state) =>
    selectMatchingGiftSettingsSelector(state);

export const selectEnableAbandonedGifts = (state) =>
    selectEnableAbandonedGiftsSelector(state);

export default slice.reducer;
