import { useEffect, useState } from "react";
import { log } from "@qgiv/core-js";
import { asyncSessionStorage } from "../../utility";

/**
 * @public
 * @function useAsyncSessionStorageData
 * @description Hook that will safely reach into sessionStorage using asyncSessionStorage.get
 *              and return the parsedFormDataObject needed to load the form after redirect.
 * @param {string} sessionStorageKey key of the item we are looking for in session storage.
 * @returns {object|null} parsedFormDataObject data needed to load the form after redirect.
 */
const useAsyncSessionStorageData = (sessionStorageKey) => {
    // empty keys on session storage return null
    const [parsedFormDataObject, setParsedDataFromObject] = useState(null);

    useEffect(() => {
        asyncSessionStorage
            .get(sessionStorageKey)
            .then((parsedFormDataObjectFromSession) => {
                setParsedDataFromObject(parsedFormDataObjectFromSession);
            })
            .catch(() => {
                // errors do not happen when the key is not found in session storage
                // they happen when we don't have access to the window's session storage
                // i.e. incognito mode while form embedded on external site.
                log("unable to load data from session storage");
            });
    }, [sessionStorageKey]);

    return parsedFormDataObject;
};

export default useAsyncSessionStorageData;
