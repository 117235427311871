import React from "react";
import PropTypes from "prop-types";
import ConnectedSubmit from "../../Pages/DetailsPage/Submit";

const DetailsPageFooterControl = ({ controlId }) => (
    <ConnectedSubmit controlId={controlId} />
);

DetailsPageFooterControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default DetailsPageFooterControl;
