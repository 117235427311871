import { createSlice } from "@reduxjs/toolkit";
import { activeInjections as initialState } from "../initialState";

const getActiveInjectionsInitialState = () => initialState;
export const activeInjectionsInitialState = getActiveInjectionsInitialState();

export const slice = createSlice({
    name: "activeInjections",
    initialState: activeInjectionsInitialState,
    reducers: {},
});

// selectors
export const selectActiveInjections = (state) => state.activeInjections;

export default slice.reducer;
