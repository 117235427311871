import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import InnerHTML from "dangerously-set-html-content";
import { TextButton } from "../Button";
import "./ExpandableTextContainer.scss";

const ExpandableTextContainer = ({
    showMoreLink = "Show more",
    showLessLink = "Show less",
    expandableText = "",
    indented = true,
}) => {
    const [showFullText, setShowFullText] = useState(false);
    const [buttonLabel, setButtonLabel] = useState(showMoreLink);

    const toggleTextLength = () => {
        if (showFullText) {
            setShowFullText(false);
            setButtonLabel(showMoreLink);
        } else {
            setShowFullText(true);
            setButtonLabel(showLessLink);
        }
    };

    const isLongText = expandableText.length > 400;

    const shortenTextContent =
        isLongText && !showFullText && "text-container-short";

    return (
        <div className="expandable-container -padding-bottom--5">
            <div
                className={cx(
                    `${shortenTextContent}`,
                    "-user-html-content",
                    indented && "-padding-left--30",
                )}
            >
                <small>
                    <InnerHTML html={expandableText} />
                </small>
            </div>
            {isLongText && (
                <div
                    className={`${
                        indented ? "-padding-left--30" : ""
                    } -padding-top--5`}
                >
                    <TextButton
                        handleClick={toggleTextLength}
                        styles="-font-weight--bold -cursor--pointer"
                    >
                        <small>{buttonLabel}</small>
                    </TextButton>
                </div>
            )}
        </div>
    );
};

ExpandableTextContainer.propTypes = {
    showLessLink: PropTypes.string.isRequired,
    showMoreLink: PropTypes.string.isRequired,
    expandableText: PropTypes.string.isRequired,
    indented: PropTypes.bool,
};

export default ExpandableTextContainer;
