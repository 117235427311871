import { constants } from "@qgiv/core-js";

/**
 * @public
 * @function getInjectionsInEmbedLocation
 * @param {object} options Object with arguments.
 * @param {object} options.activeFormInjections Active form injections.
 * @param {boolean} options.enableAnalyticsOutsideIframe Flag that indicates
 *        if the internal setting that allows analytics to be rendered outside
 *        the landing page has been turned on.
 *        rendered on an internal landing page or as a widget on an external
 *        domain.
 * @param {boolean} options.isProductThatUsesIframe Flag that denotes whether the
 *        product where this function is called is rendered within an iframe.
 * @returns {object} Dictionary of flags and arrays of ids.
 */
const getInjectionsInEmbedLocation = ({
    activeFormInjections = {},
    enableAnalyticsOutsideIframe = false,
    isProductThatUsesIframe = true,
}) => {
    const {
        meta = [],
        script = [],
        googleAnalyticsUA = {},
        googleAnalyticsGA4 = {},
        googleTagManager = {},
        facebookPixel = {},
    } = activeFormInjections;
    let injectionsInEmbedLocation = {
        facebookPixel: false,
        googleAnalyticsGA4: false,
        googleAnalyticsUA: false,
        googleTagManager: false,
        meta: [],
        script: [],
    };

    // Only consider rendering these scripts if one is present
    let hasFacebookPixelInjection = !!facebookPixel.id;
    let hasGoogleAnalyticsGA4Injection = !!googleAnalyticsGA4.id;
    let hasGoogleAnalyticsUAInjection = !!googleAnalyticsUA.id;
    let hasGoogleTagManagerInjection = !!googleTagManager.id;

    // However, if the injection is rendered in a product that is nested within
    // an iframe an the internal setting that is supposed to prevent the
    // analytics script from rendering within the iframe is turned on, none of
    // these scripts should ever be rendered.
    if (enableAnalyticsOutsideIframe && isProductThatUsesIframe) {
        hasFacebookPixelInjection = false;
        hasGoogleAnalyticsGA4Injection = false;
        hasGoogleAnalyticsUAInjection = false;
        hasGoogleTagManagerInjection = false;
    }

    // Meta injections and script injections are not affected by embed location
    const metaInjections = meta;
    const scriptInjections = script;
    const idsOfMetaInjections = metaInjections.map(
        (metaInjection) => metaInjection.id,
    );
    const idsOfScriptInjections = scriptInjections.map(
        (scriptInjection) => scriptInjection.id,
    );

    // Consolidate flags into object
    injectionsInEmbedLocation = {
        facebookPixel: hasFacebookPixelInjection,
        googleAnalyticsGA4: hasGoogleAnalyticsGA4Injection,
        googleAnalyticsUA: hasGoogleAnalyticsUAInjection,
        googleTagManager: hasGoogleTagManagerInjection,
        meta: idsOfMetaInjections,
        script: idsOfScriptInjections,
    };

    return injectionsInEmbedLocation;
};

/**
 * @public
 * @function getInjectionsInLocation
 * @param {object} activeFormInjections Active form injections.
 * @param {string} currentStep Step within form.
 * @returns {object} Object that contains a list of keys, each of which
 *          represents all of the injections that are associated with the
 *          current page/location. Valid pages/locations include main donation
 *          form, receipt page etc.
 */
const getInjectionsInLocation = (
    activeFormInjections = {},
    currentStep = "",
) => {
    const {
        meta = [],
        script = [],
        googleAnalyticsUA = {},
        googleAnalyticsGA4 = {},
        googleTagManager = {},
        facebookPixel = {},
    } = activeFormInjections;

    let injectionsInLocation = {
        facebookPixel: false,
        googleAnalyticsGA4: false,
        googleAnalyticsUA: false,
        googleTagManager: false,
        meta: [],
        script: [],
    };

    // Helper function
    const getInjectionInLocation = (injection) => {
        const { location } = injection;
        const {
            ENUMS: { FormStep, FormInjectionLocation, DefaultView },
        } = constants;
        const isReceipt =
            Number(currentStep) === FormStep.CONFIRMATION ||
            Number(currentStep) === DefaultView.QGIV_EVENT_CONFIRMATION ||
            Number(currentStep) === DefaultView.STANDARD_REGISTRATION_RECEIPT;
        // Generate flags that can be used to determine whether the script
        // should be included on a certain page of the form. If an injection
        // does not have a location it is because a user was unable to select
        // a location as it should be used everywhere.
        const isMainFormInjection =
            Number(location) === FormInjectionLocation.MAIN_FORM ||
            Number(location) === FormInjectionLocation.BOTH ||
            Number(location) === FormInjectionLocation.NONE;
        const isReceiptInjection =
            Number(location) === FormInjectionLocation.RECEIPT_CONFIRMATION ||
            Number(location) === FormInjectionLocation.BOTH ||
            Number(location) === FormInjectionLocation.NONE;
        // Use the current step to determine whether or not the script
        // should be injected
        if (
            (!isReceipt && isMainFormInjection) ||
            (isReceipt && isReceiptInjection)
        ) {
            return true;
        }

        return false;
    };

    // Analytics injections are always used in all locations
    const hasFacebookPixelInjection = !!facebookPixel.id;
    const hasGoogleAnalyticsGA4Injection = !!googleAnalyticsGA4.id;
    const hasGoogleAnalyticsUAInjection = !!googleAnalyticsUA.id;
    const hasGoogleTagManagerInjection = !!googleTagManager.id;

    // Meta injections can be rendered in the main form or on the receipt
    const metaInjections = meta.filter(getInjectionInLocation);
    const idsOfMetaInjections = metaInjections.map(
        (metaInjection) => metaInjection.id,
    );

    // Script injections can be rendered in the main form or on the receipt
    const scriptInjections = script.filter(getInjectionInLocation);
    const idsOfScriptInjections = scriptInjections.map(
        (scriptInjection) => scriptInjection.id,
    );

    // Consolidate flags into object
    injectionsInLocation = {
        facebookPixel: hasFacebookPixelInjection,
        googleAnalyticsGA4: hasGoogleAnalyticsGA4Injection,
        googleAnalyticsUA: hasGoogleAnalyticsUAInjection,
        googleTagManager: hasGoogleTagManagerInjection,
        meta: idsOfMetaInjections,
        script: idsOfScriptInjections,
    };

    return injectionsInLocation;
};

/**
 * @public
 * @function getInjectionsInPosition
 * @param {object} activeFormInjections Active form injections.
 * @param {number} currentPosition Where on the webpage the injection is going
 *                  to be rendered. Document head, document body etc.
 * @returns {object} Object with keys that represent all of the active
 *                  injections in the current position.
 */
const getInjectionsInPosition = (
    activeFormInjections = {},
    currentPosition = 1,
) => {
    // Favicons are not included as it is injected into the head of the landing
    // page instead of the head of the form.
    const {
        facebookPixel = {},
        googleAnalyticsGA4 = {},
        googleAnalyticsUA = {},
        googleTagManager = {},
        meta = [],
        script = [],
    } = activeFormInjections;
    const {
        ENUMS: { FormInjectionPosition },
    } = constants;

    let injectionsInPosition = {
        facebookPixel: false,
        googleAnalyticsGA4: false,
        googleAnalyticsUA: false,
        googleTagManager: false,
        meta: [],
        script: [],
    };

    // Helper function
    const getInjectionInPosition = (injection) => {
        const { position } = injection;
        if (currentPosition === Number(position)) {
            return true;
        }
        return false;
    };

    // Analytics injections are only ever rendered in the document head
    const hasFacebookPixelInjection =
        currentPosition === FormInjectionPosition.HEAD && !!facebookPixel.id;
    const hasGoogleAnalyticsGA4Injection =
        currentPosition === FormInjectionPosition.HEAD &&
        !!googleAnalyticsGA4.id;
    const hasGoogleAnalyticsUAInjection =
        currentPosition === FormInjectionPosition.HEAD &&
        !!googleAnalyticsUA.id;
    const hasGoogleTagManagerInjection =
        currentPosition === FormInjectionPosition.HEAD && !!googleTagManager.id;

    // Meta injections are only ever rendered in the document head
    const metaInjections =
        currentPosition === FormInjectionPosition.HEAD ? meta : [];
    const idsOfMetaInjections = metaInjections.map(
        (metaInjection) => metaInjection.id,
    );

    // Script injections can be located in the document head or body
    const scriptInjections = script.filter(getInjectionInPosition);
    const idsOfScriptInjections = scriptInjections.map(
        (scriptInjection) => scriptInjection.id,
    );

    // Consolidate flags into object
    injectionsInPosition = {
        ...injectionsInPosition,
        facebookPixel: hasFacebookPixelInjection,
        googleAnalyticsGA4: hasGoogleAnalyticsGA4Injection,
        googleAnalyticsUA: hasGoogleAnalyticsUAInjection,
        googleTagManager: hasGoogleTagManagerInjection,
        meta: idsOfMetaInjections,
        script: idsOfScriptInjections,
    };

    return injectionsInPosition;
};

/**
 *
 * @public
 * @function getInjectionsToRender
 * @param {object} options Object with arguments.
 * @param {object} options.activeFormInjections Active form injections.
 * @param {object} options.injectionsInEmbedLocation Dictionary of flags and
 *        arrays of ids.
 * @param {object} options.injectionsInPosition Dictionary of flags and arrays
 *        of ids.
 * @param {object} options.injectionsInLocation Dictionary of flags and arrays
 *        of ids.
 * @returns {object} Object with data of the injections to render in this
 *          instance of the component.
 */
const getInjectionsToRender = ({
    activeFormInjections = {},
    injectionsInEmbedLocation = {},
    injectionsInPosition = {},
    injectionsInLocation = {},
}) => {
    const injectionsToRender = {
        facebookPixel: {},
        googleAnalyticsGA4: {},
        googleAnalyticsUA: {},
        googleTagManager: {},
        meta: [],
        script: [],
    };

    // Facebook Pixel, GA4, UA and GTM injections. Because there can only ever
    // be one of these injections per form we can use the booleans that were
    // generate by the position and location functions to determine if we can
    // add the injection to the list of injections to be rendered within the
    // React app.
    if (
        injectionsInEmbedLocation.facebookPixel &&
        injectionsInPosition.facebookPixel &&
        injectionsInLocation.facebookPixel
    ) {
        injectionsToRender.facebookPixel = activeFormInjections.facebookPixel;
    }

    if (
        injectionsInEmbedLocation.googleAnalyticsGA4 &&
        injectionsInPosition.googleAnalyticsGA4 &&
        injectionsInLocation.googleAnalyticsGA4
    ) {
        injectionsToRender.googleAnalyticsGA4 =
            activeFormInjections.googleAnalyticsGA4;
    }

    if (
        injectionsInEmbedLocation.googleAnalyticsUA &&
        injectionsInPosition.googleAnalyticsUA &&
        injectionsInLocation.googleAnalyticsUA
    ) {
        injectionsToRender.googleAnalyticsUA =
            activeFormInjections.googleAnalyticsUA;
    }

    if (
        injectionsInEmbedLocation.googleTagManager &&
        injectionsInPosition.googleTagManager &&
        injectionsInLocation.googleTagManager
    ) {
        injectionsToRender.googleTagManager =
            activeFormInjections.googleTagManager;
    }

    // Meta injections. Because there can be multiple of these injections we
    // need to go through the array of ids that were returned by the position
    // and location functions and only return meta elements that met both the
    // position and location requirements.
    if (
        injectionsInEmbedLocation.meta.length > 0 &&
        injectionsInPosition.meta.length > 0 &&
        injectionsInLocation.meta.length > 0
    ) {
        const metaElementsToRender = activeFormInjections.meta.filter(
            (meta) => {
                const { id = "" } = meta;
                if (
                    injectionsInPosition.meta.includes(id) &&
                    injectionsInLocation.meta.includes(id)
                ) {
                    return true;
                }
                return false;
            },
        );

        injectionsToRender.meta = metaElementsToRender;
    }

    // Script injections. Because there can be multiple of these injections we
    // need to go through the array of ids that were returned by the position
    // and location functions and only return script elements that met both the
    // position and location requirements.
    if (
        injectionsInEmbedLocation.script.length > 0 &&
        injectionsInPosition.script.length > 0 &&
        injectionsInLocation.script.length > 0
    ) {
        const scriptInjectionsToRender = activeFormInjections.script.filter(
            (script) => {
                const { id = "" } = script;
                if (
                    injectionsInPosition.script.includes(id) &&
                    injectionsInLocation.script.includes(id)
                ) {
                    return true;
                }
                return false;
            },
        );

        injectionsToRender.script = scriptInjectionsToRender;
    }

    return injectionsToRender;
};

/**
 *
 * @public
 * @function getInjectionsToRenderInReactApp
 * @param {object} options Object with arguments.
 * @param {object} options.activeFormInjections Active form injections.
 * @param {number} options.currentPosition Position of component on webpage.
 * @param {string} options.currentStep Step within form.
 * @param {boolean} options.enableAnalyticsOutsideIframe Flag that indicates
 *        if the internal setting that allows analytics to be rendered outside
 *        the landing page has been turned on.
 *        rendered on an internal landing page or as a widget on an external
 *        domain.
 * @param {boolean} options.isProductThatUsesIframe Flag that denotes whether the
 *        product where this function is called is rendered within an iframe.
 * @returns {object} Object with data of the injections to render in this
 *          instance of the component.
 */
const getInjectionsToRenderInReactApp = ({
    activeFormInjections = {},
    currentPosition = 1,
    currentStep = "",
    enableAnalyticsOutsideIframe = false,
    isProductThatUsesIframe = true,
}) => {
    // Generate flags based off of position in the webpage, location or step in
    // the form and the embed location. The embed location generates a flag
    // that determines whether or not the analytics scripts should be rendered
    // as the location of these tracking scripts changes based on the type of
    // product that is rendering these scripts and whether or not that
    // particular instance of the form is being deployed as a widget.
    const injectionsInPosition = getInjectionsInPosition(
        activeFormInjections,
        currentPosition,
    );
    const injectionsInLocation = getInjectionsInLocation(
        activeFormInjections,
        currentStep,
    );

    const injectionsInEmbedLocation = getInjectionsInEmbedLocation({
        activeFormInjections,
        enableAnalyticsOutsideIframe,
        isProductThatUsesIframe,
    });

    // Use these flags to generate an object that contains all the data
    // associated with the injections that need to be rendered
    const injectionsToRender = getInjectionsToRender({
        activeFormInjections,
        injectionsInEmbedLocation,
        injectionsInPosition,
        injectionsInLocation,
    });

    return injectionsToRender;
};

export default getInjectionsToRenderInReactApp;
