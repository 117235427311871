import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";

const RecurringCallout = ({
    displayRecurringCallout = false,
    recurringCalloutText = "Next gift bills on",
}) =>
    displayRecurringCallout ? (
        <div className="-callout -margin-top--15">
            <p>
                <Icon
                    classNames={["-margin-right--10"]}
                    glyph="calendar-alt-regular"
                    type="FontAwesome"
                />{" "}
                {recurringCalloutText}
            </p>
        </div>
    ) : null;

RecurringCallout.propTypes = {
    displayRecurringCallout: PropTypes.bool.isRequired,
    recurringCalloutText: PropTypes.string.isRequired,
};

export default RecurringCallout;
