import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { UpgradeNudge, convertFrequencyTag } from "@qgiv/donation-form";
import { selectAllDonationSettings } from "../../../../../redux/slices/donationSettingsSlice";
import { updateGiftDetails } from "../../../../../redux/slices/donationDetailsSlice";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import {
    selectCurrentPage,
    setShouldScrollToTop,
} from "../../../../../redux/slices/appSettingsSlice";

const ConnectedUpgradeNudge = ({ controlId }) => {
    const dispatch = useDispatch();

    const { values } = useFormikContext();
    const { Recurring_Frequency } = values;

    const { activeDonationFrequencies, pledgeActive } = useSelector(
        selectAllDonationSettings,
    );

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const {
        options: {
            nudgeMessage = "Multiply your impact. Make it %FREQUENCY%!",
        },
    } = control;

    const currentFrequencyLabel = convertFrequencyTag(
        activeDonationFrequencies,
        Recurring_Frequency,
    );

    const formattedNudgeMessage = nudgeMessage.replace(
        "%FREQUENCY%",
        pledgeActive ? "Ongoing" : currentFrequencyLabel,
    );

    const handleUpgradeNudgeClick = () => {
        dispatch(setShouldScrollToTop(true));
        dispatch(updateGiftDetails({ hasSelectedRecurringDonation: true }));
    };

    const displayUpgradeNudge = true;

    const upgradeNudgeProps = {
        formattedNudgeMessage,
        handleClick: handleUpgradeNudgeClick,
    };

    return displayUpgradeNudge ? <UpgradeNudge {...upgradeNudgeProps} /> : null;
};

ConnectedUpgradeNudge.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedUpgradeNudge;
