import { isDisplayable, constants } from "@qgiv/core-js";
import { createSelector } from "@reduxjs/toolkit";

const {
    ENUMS: { EntityType, Status, CmsPageStandardizer, FieldType, ProductType },
} = constants;

export const selectAllFieldsSelector = (state) => state.fields;

// returns array of system fields and system field groups
export const selectAllSystemFieldsSelector = (state) =>
    state.fields.systemFields;

// returns array of custom fields and custom field groups
export const selectAllCustomFieldsSelector = (state) =>
    state.fields.customFields;

export const selectAllFieldsAndGroupsSelector = createSelector(
    [selectAllSystemFieldsSelector, selectAllCustomFieldsSelector],
    (allSystemFields, allCustomFields) => [
        ...allSystemFields,
        ...allCustomFields,
    ],
);

export const getIsSystemFieldGroupSelector = (field) => {
    const isFieldGroup =
        Number(field.type) === EntityType.FIELD_GROUP ||
        Number(field.type) === EntityType.SYSTEM_FIELD_GROUP_NAME ||
        Number(field.type) === EntityType.SYSTEM_FIELD_GROUP_MAILING_ADDRESS ||
        Number(field.type) === EntityType.SYSTEM_FIELD_GROUP_BILLING_ADDRESS ||
        Number(field.type) === EntityType.SYSTEM_FIELD_GROUP_MATCHING_GIFTS ||
        Number(field.type) === EntityType.SYSTEM_FIELD_GROUP_PRIVACY_OPTIONS;

    return isFieldGroup;
};

export const selectAllFieldsInSystemFieldsSelector = createSelector(
    [selectAllSystemFieldsSelector],
    (allSystemFields) => {
        const systemFieldGroups = allSystemFields.filter((field) => {
            const isSystemFieldGroup = getIsSystemFieldGroupSelector(field);
            return isSystemFieldGroup;
        });
        const systemFieldsFromSystemFieldGroups = systemFieldGroups
            .map((fieldGroup) => fieldGroup.fields)
            .reduce(
                (previousValue, currentValue) => [
                    ...previousValue,
                    ...currentValue,
                ],
                [],
            );
        const individualSystemFields = allSystemFields.filter((field) => {
            const isSystemFieldGroup = getIsSystemFieldGroupSelector(field);
            // Because of the large number of field types, check for the presence
            // of a field group as this is the only time the data here is not a
            // field
            return !isSystemFieldGroup;
        });

        const newSystemFieldsList = [
            ...systemFieldsFromSystemFieldGroups,
            ...individualSystemFields,
        ];

        return newSystemFieldsList;
    },
);

// returns array of all custom fields with correct display settings
export const selectAllFieldsInCustomFieldsSelector = createSelector(
    [(state) => selectAllCustomFieldsSelector(state)],
    (customFieldsAndGroups) => {
        const validFieldGroup = (item) =>
            Number(item?.status) === Status.ACTIVE &&
            item?.fields &&
            item?.fields.length &&
            item?.fields.length > 0;
        const allCustomFields = customFieldsAndGroups
            .filter((item) => {
                if (!item?.fields || validFieldGroup(item)) {
                    return true;
                }
                return false;
            })
            .flatMap((item) => {
                if (validFieldGroup(item)) {
                    // need to override field data with group data
                    return item.fields.map((_field) => ({
                        ..._field,
                        ...item.options,
                        conditions: item.conditions,
                    }));
                }
                return item;
            })
            .map((_field) => {
                const fieldOptions = _field?.options;
                return {
                    ..._field,
                    ...(fieldOptions && { fieldOptions }),
                };
            });

        return allCustomFields;
    },
);

export const selectAllDisplayableCustomFieldsSelector = createSelector(
    [
        (state, currentDisplay) =>
            selectAllFieldsInCustomFieldsSelector(state, currentDisplay),
        (state, currentDisplay) => currentDisplay,
    ],
    (allCustomFields, currentDisplay) => {
        const allDisplayableCustomFields = allCustomFields.filter((field) => {
            const fieldIsDisplayable = isDisplayable(field, currentDisplay);
            return fieldIsDisplayable;
        });
        return allDisplayableCustomFields;
    },
);

// returns array of custom fields that display on the details page
export const selectAllDetailsPageCustomFieldsSelector = createSelector(
    [
        (state, currentDisplay) =>
            selectAllFieldsInCustomFieldsSelector(state, currentDisplay),
    ],
    (allCustomFields) => {
        const detailsPageCustomFields = allCustomFields.filter(
            (field) => Number(field?.formStep) === CmsPageStandardizer.DETAILS,
        );
        return detailsPageCustomFields;
    },
);

// returns array of custom fields that display on the additional details page
export const selectAllAdditionalDetailsPageCustomFieldsSelector =
    createSelector(
        [
            (state, currentDisplay) =>
                selectAllDisplayableCustomFieldsSelector(state, currentDisplay),
        ],
        (allCustomFields) => {
            const additionalDetailsPageCustomFields = allCustomFields.filter(
                (field) =>
                    Number(field?.formStep) ===
                    CmsPageStandardizer.ADDITIONAL_DETAILS,
            );
            return additionalDetailsPageCustomFields;
        },
    );

export const selectCustomFieldByIdSelector = (state, id) => {
    const allCustomFields = selectAllFieldsInCustomFieldsSelector(state);
    return allCustomFields.find((_field) => _field.id === id) || {};
};

export const selectSystemFieldByIdSelector = (state, id) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    return systemFields.find((_field) => _field.id === id) || {};
};

export const selectDefaultCountrySelector = (state, groupType) => {
    // groupType could reference MailingAddress or BillingAddress field groups
    // the country field for those field group have different types we need to check
    const isMailingAddress =
        groupType === EntityType.SYSTEM_FIELD_GROUP_MAILING_ADDRESS;

    const countryFieldEnum = isMailingAddress
        ? FieldType.COUNTRY
        : FieldType.BILLING_COUNTRY;

    const systemFields = selectAllSystemFieldsSelector(state);
    const mailingFieldGroup =
        systemFields.find((field) => Number(field.type) === groupType) || {};

    if (Object.keys(mailingFieldGroup).length === 0) return "US";

    const { fields = [] } = mailingFieldGroup;

    const countryField =
        fields.find((field) => Number(field.type) === countryFieldEnum) || {};

    if (Object.keys(countryField).length === 0) return "US";

    const { defaultCountry = "US" } = countryField;
    // countryField's unsaved defaultCountry value is an empty string
    // for the state select to work, we need to set it to "US"
    return defaultCountry === "" ? "US" : defaultCountry;
};

export const selectFieldCheckedByDefaultSelector = (state, fieldType) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    const field =
        systemFields.find((_field) => Number(_field.type) === fieldType) || {};
    if (Object.keys(field).length === 0) return { exists: false, fieldType };

    const { checked_by_default } = field;

    // Boolean("0") and !!"0" both return true, need to check for that specific value type
    return {
        exists: true,
        checkedByDefault:
            checked_by_default === "0" ? false : Boolean(checked_by_default),
    };
};

export const selectOptInDefaultCheckedSelector = (state) =>
    selectFieldCheckedByDefaultSelector(state, FieldType.OPT_IN);

export const selectAnonymousFieldCheckedSelector = (state) =>
    selectFieldCheckedByDefaultSelector(state, FieldType.ANONYMOUS);

export const selectAnonymousFieldDataSelector = (state, currentDisplay) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    const field =
        systemFields.find(
            (_field) => Number(_field.type) === FieldType.ANONYMOUS,
        ) || {};
    const displayable = isDisplayable(field, currentDisplay);
    if (Object.keys(field).length === 0 || !displayable)
        return { exists: false, type: FieldType.ANONYMOUS };

    const { checked_by_default, display_text } = field;
    return {
        exists: true,
        checkedByDefault:
            checked_by_default === "0" ? false : Boolean(checked_by_default),
        display_text,
    };
};

export const selectHasLegalFieldSelector = (state, currentDisplay) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    const legalField =
        systemFields.find((field) => Number(field.type) === FieldType.LEGAL) ||
        {};
    const displayable = isDisplayable(legalField, currentDisplay);
    return Object.keys(legalField).length > 0 && displayable;
};

export const selectHasEmailFieldSelector = (state, currentDisplay) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    const emailField =
        systemFields.find(
            (field) => Number(field.type) === FieldType.SYSTEM_EMAIL,
        ) || {};
    const displayable = isDisplayable(emailField, currentDisplay);
    return Object.keys(emailField).length > 0 && displayable;
};

export const selectHasDonationMessageSelector = (state, currentDisplay) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    const donationMessageField =
        systemFields.find(
            (field) => Number(field.type) === FieldType.DONATION_MESSAGE,
        ) || {};
    const displayable = isDisplayable(donationMessageField, currentDisplay);
    const isActive = donationMessageField?.status === Status.ACTIVE;
    if (
        Object.keys(donationMessageField).length > 0 &&
        displayable &&
        isActive
    ) {
        return true;
    }
    return false;
};

export const selectHasDisplayableMatchingGiftOnPageSelector = ({
    state,
    currentDisplay,
    currentPage,
    hasSelectedCompanyDonation,
}) => {
    const {
        donationSettings: { enableMatching },
    } = state;
    // exit early if matching gifts is disabled
    if (!enableMatching || hasSelectedCompanyDonation) {
        return false;
    }

    const systemFields = selectAllSystemFieldsSelector(state);
    const matchingGiftFieldGroup =
        systemFields.find(
            (field) =>
                Number(field.type) ===
                EntityType.SYSTEM_FIELD_GROUP_MATCHING_GIFTS,
        ) || {};

    if (Object.keys(matchingGiftFieldGroup).length === 0) return false;

    const displayable =
        matchingGiftFieldGroup?.options &&
        isDisplayable(matchingGiftFieldGroup.options, currentDisplay);

    const shouldDisplayOnCurrentPage =
        Number(matchingGiftFieldGroup?.formStep) === currentPage;

    return displayable && shouldDisplayOnCurrentPage;
};

export const amtReqFieldIsRequiredDataSelector = (
    state,
    type,
    currentDisplay,
    total,
) => {
    const systemFields = selectAllFieldsInSystemFieldsSelector(state);
    const field =
        systemFields.find((_field) => Number(_field.type) === type) || {};

    const displayable = isDisplayable(field, currentDisplay);

    if (Object.keys(field).length === 0 || !displayable)
        return { exists: false, type };

    const { required, amtReq } = field;

    const isRequired = required === "1" || required === true || required === 1;

    const hasAmtRequired = isRequired && Number(amtReq) > 0;
    const totalGreaterThanAmtRequired = total > Number(amtReq);
    return {
        required: hasAmtRequired ? totalGreaterThanAmtRequired : isRequired,
        amtReq: isRequired ? Number(amtReq) : 0,
        exists: true,
    };
};

export const amtReqFieldInFieldGroupIsRequiredDataSelector = (
    state,
    groupType,
    fieldType,
    total,
) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    const fieldGroup =
        systemFields.find((_field) => Number(_field.type) === groupType) || {};

    if (Object.keys(fieldGroup).length === 0)
        return { exists: false, fieldType };

    const { fields = [] } = fieldGroup;
    const field =
        fields.find((_field) => Number(_field.type) === fieldType) || {};

    if (Object.keys(field).length === 0) return { exists: false };
    const { required, amtReq } = field;

    const isRequired = required === true;

    // For events we are not respecting the amtReq, instead if this
    // value is set in the CP then we should default to Optional
    const hasAmtRequired = isRequired && Number(amtReq) > 0;
    const totalGreaterThanAmtRequired = total > Number(amtReq);
    return {
        required: hasAmtRequired ? totalGreaterThanAmtRequired : isRequired,
        amtReq: isRequired ? Number(amtReq) : 0,
        exists: true,
    };
};

export const selectPhoneFieldIsRequiredSelector = (
    state,
    currentDisplay,
    total,
) =>
    amtReqFieldIsRequiredDataSelector(
        state,
        FieldType.SYSTEM_PHONE,
        currentDisplay,
        total,
    );

export const selectCompanyFieldIsRequiredSelector = (state, total) =>
    amtReqFieldInFieldGroupIsRequiredDataSelector(
        state,
        EntityType.SYSTEM_FIELD_GROUP_NAME,
        FieldType.COMPANY,
        total,
    );

export const selectEmployerFieldIsRequiredSelector = (
    state,
    currentDisplay,
    total,
) =>
    amtReqFieldIsRequiredDataSelector(
        state,
        FieldType.EMPLOYER,
        currentDisplay,
        total,
    );

export const selectAddressGroupIsDisplayableSelector = createSelector(
    [
        (state) => selectAllSystemFieldsSelector(state),
        (state, groupType) => groupType,
        (state, groupType, currentDisplay) => currentDisplay,
    ],
    (systemFields, groupType, currentDisplay) => {
        const fieldGroup =
            systemFields.find((_field) => Number(_field.type) === groupType) ||
            {};
        if (Object.keys(fieldGroup).length === 0) {
            return { exists: false };
        }
        // display settings live in field groups' options
        const displayable = isDisplayable(fieldGroup.options, currentDisplay);
        if (!displayable) return { exists: false };
        return { exists: true };
    },
);

export const selectPrivacyOptionsGroupIsDisplayableSelector = (
    state,
    groupType,
    currentDisplay,
) => {
    const systemFields = selectAllSystemFieldsSelector(state);
    const fieldGroup =
        systemFields.find((_field) => Number(_field.type) === groupType) || {};
    if (Object.keys(fieldGroup).length === 0) {
        return { exists: false };
    }
    const displayable = isDisplayable(fieldGroup.options, currentDisplay);
    if (!displayable) return { exists: false };
    return { exists: true };
};

export const selectTitleFieldIsRequiredSelector = (state, total) =>
    amtReqFieldInFieldGroupIsRequiredDataSelector(
        state,
        EntityType.SYSTEM_FIELD_GROUP_NAME,
        FieldType.SALUTATION,
        total,
    );

export const selectHasSuffixFieldSelector = (state, total) => {
    const suffixFieldData = amtReqFieldInFieldGroupIsRequiredDataSelector(
        state,
        EntityType.SYSTEM_FIELD_GROUP_NAME,
        FieldType.SUFFIX,
        total,
    );
    // We are going to remove the ability to make this field required
    // So we only need the exists part of field data.
    return suffixFieldData.exists;
};

export const selectHasBillingNameFieldSelector = createSelector(
    [
        (state) => selectAllSystemFieldsSelector(state),
        (state, currentDisplay) => currentDisplay,
    ],
    (systemFields, currentDisplay) => {
        const billingNameField =
            systemFields.find(
                (field) => Number(field.type) === FieldType.BILLING_NAME,
            ) || {};
        const displayable = isDisplayable(billingNameField, currentDisplay);
        if (Object.keys(billingNameField).length === 0 || !displayable) {
            return {
                exists: false,
            };
        }
        const {
            displayFrontend,
            displayMobile,
            displayTxtToDonate,
            displayVT,
            display_text,
            display_text_cc,
            display_text_echeck,
            required,
        } = billingNameField;

        const displayOptions = {
            displayFrontend,
            displayMobile,
            displayTxtToDonate,
            displayVT,
        };

        return {
            exists: true,
            display_text,
            display_text_cc,
            display_text_echeck,
            required,
            displayOptions,
        };
    },
);

export const selectCCFieldsDataSelector = createSelector(
    [selectAllSystemFieldsSelector],
    (systemFields) => {
        const creditCardFieldGroup = systemFields.find(
            (fieldOrGroup) =>
                fieldOrGroup.type === EntityType.SYSTEM_FIELD_GROUP_CREDIT_CARD,
        ) || { fields: [] };
        const creditCardFields = creditCardFieldGroup.fields;
        const creditCardFieldsData = creditCardFields.reduce(
            (cardFieldData, currentField) => {
                let key = "";
                switch (currentField.type) {
                    case FieldType.CVV:
                        key = "cvvLabel";
                        break;
                    case FieldType.EXPIRATION_DATE:
                        key = "expirationDateLabel";
                        break;
                    case FieldType.CARD_NUMBER:
                        key = "cardNumberLabel";
                        break;
                    default:
                        key = "";
                }
                if (key) {
                    return {
                        ...cardFieldData,
                        [key]: currentField.display_text,
                    };
                }
                return cardFieldData;
            },
            {},
        );
        return creditCardFieldsData;
    },
);

export const selectBankFieldsDataSelector = createSelector(
    [selectAllSystemFieldsSelector],
    (systemFields) => {
        const bankFieldsGroup = systemFields.find(
            (fieldOrGroup) =>
                fieldOrGroup.type === EntityType.SYSTEM_FIELD_GROUP_ECHECK_BANK,
        ) || { fields: [] };
        const bankFields = bankFieldsGroup.fields;
        const bankFieldsData = bankFields.reduce(
            (bankFieldData, currentField) => {
                let key = "";
                switch (currentField.type) {
                    case FieldType.ACCOUNT_NUMBER:
                        key = "accountNumberLabel";
                        break;
                    case FieldType.ROUTING_NUMBER:
                        key = "routingNumberLabel";
                        break;
                    default:
                        key = "";
                }
                if (key) {
                    return {
                        ...bankFieldData,
                        [key]: currentField.display_text,
                    };
                }
                return bankFieldData;
            },
            {},
        );
        return bankFieldsData;
    },
);

// -------------------------------------------------------------------------
// NOTE: To our future selves or anyone looking to this piece of code for
// guidance and inspiration. DON'T. Find another way. Any other way. This
// began as a simple consolidation of data, but due to the memoization console
// warnings has since become an untenable monster. Please. Learn from the
// mistakes of the past selves. We can do better...
// Also, if you find the time feel free to refactor.
// -------------------------------------------------------------------------
export const selectDetailsPageFieldsDataSelector = createSelector(
    [
        (state, total) => selectCompanyFieldIsRequiredSelector(state, total),
        (state) => selectAnonymousFieldCheckedSelector(state),
        (state) =>
            selectDefaultCountrySelector(
                state,
                EntityType.SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
            ),
        (state) => selectOptInDefaultCheckedSelector(state),
        (state, currentDisplay, total) =>
            selectEmployerFieldIsRequiredSelector(state, currentDisplay, total),
        (state, currentDisplay) =>
            selectHasLegalFieldSelector(state, currentDisplay),
        (state, currentDisplay) =>
            selectHasEmailFieldSelector(state, currentDisplay),
        (state, total) => selectHasSuffixFieldSelector(state, total),
        (state, currentDisplay, total) =>
            selectPhoneFieldIsRequiredSelector(state, currentDisplay, total),
        (state, total) => selectTitleFieldIsRequiredSelector(state, total),
        (state, currentDisplay) =>
            selectAddressGroupIsDisplayableSelector(
                state,
                EntityType.SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
                currentDisplay,
            ),
        (state, currentDisplay) =>
            selectPrivacyOptionsGroupIsDisplayableSelector(
                state,
                EntityType.SYSTEM_FIELD_GROUP_PRIVACY_OPTIONS,
                currentDisplay,
            ),
    ],
    (
        companyFieldIsRequired,
        defaultAnonymousFieldChecked,
        defaultCountry,
        defaultOptInChecked,
        employerFieldIsRequired,
        hasLegalField,
        hasEmailField,
        hasSuffixField,
        phoneFieldIsRequired,
        titleFieldIsRequired,
        mailingAddressIsDisplayable,
        privacyOptionsIsDisplayable,
    ) => ({
        companyFieldIsRequired,
        defaultAnonymousFieldChecked,
        defaultCountry,
        defaultOptInChecked,
        employerFieldIsRequired,
        hasLegalField,
        hasEmailField,
        hasSuffixField,
        phoneFieldIsRequired,
        titleFieldIsRequired,
        mailingAddressIsDisplayable,
        privacyOptionsIsDisplayable,
    }),
);

export const selectDisplayableBillingFieldsSelector = createSelector(
    [selectAllSystemFieldsSelector, (state, currentDisplay) => currentDisplay],
    (systemFields, currentDisplay) => {
        const billingFieldsGroup = systemFields.find(
            (fieldOrGroup) =>
                fieldOrGroup.type ===
                EntityType.SYSTEM_FIELD_GROUP_BILLING_ADDRESS,
        ) || { fields: [] };
        const billingFields = billingFieldsGroup.fields;
        const displayableBillingFields = billingFields.filter(
            (billingField) => {
                const fieldIsDisplayable = isDisplayable(
                    billingField,
                    currentDisplay,
                );
                return fieldIsDisplayable;
            },
        );
        return displayableBillingFields;
    },
);

export const selectIsUsingPrivacyOptionsSelector = (state) => {
    const { HOBNOB } = ProductType;
    const productType = state.formSettings.type;

    if (productType === HOBNOB) {
        return true;
    }
    return false;
};
