import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon, useWindowSize } from "@qgiv/core-react";
import "./FieldContainer.scss";
import TextBelowField from "./TextBelowField";

const FieldContainer = ({
    // html properties
    htmlForName,
    label,

    // other
    helpText = "",
    disableInlineLabel = true,
    descText = "",
    descIcon = {
        glyph: "",
        type: "",
    },
    descRef,
    active = false,
    entered = false,
    selected = false,
    multiline = false,
    cursor,
    children,
    innerBackground = false,
    innerShadow = false,
    maxChar,
    name,
    hasDatePicker,

    // custom
    selectedOnHover,

    // error
    error = false,
    errorText = "",

    value,

    // event handlers
    handleClick,
    handleMouseDown,
}) => {
    const { width: screenWidth } = useWindowSize();
    const fieldContainerRef = React.createRef();
    const [labelStyles, setLabelStyles] = useState({});
    const showLabel = !disableInlineLabel && label;
    const showDescText = descText.length > 0;
    const showDescIcon = descIcon.glyph.length > 0;
    const showDesc = showDescIcon || showDescText;
    const showHelpText = helpText.length > 0;
    const showErrorText = !!error && !!errorText.length > 0;
    const showMaxChar = !!maxChar && !!(parseInt(maxChar) > 0);
    const fieldContainerClassNames = cx(
        "qg-vendor-field-container",
        "field-container",
        active && "field-container--active",
        (entered || hasDatePicker) && "field-container--entered",
        selected && "field-container--selected",
        selectedOnHover && "field-container--selected-on-hover",
        multiline && "field-container--multiline",
        error && "field-container--error",
        cursor !== undefined && `-cursor--${cursor}`,
    );
    const fieldContainerInnerClassNames = cx(
        "qg-vendor-field-container__inner field-container__inner",
        innerBackground && "field-container__inner--background",
        innerShadow && "field-container__inner--shadow",
    );
    const fieldContainerLabelClassNames = cx(
        "field-container__label",
        multiline && "field-container__label--multiline",
    );
    const iconClassNames =
        descIcon.glyph === "calendar-alt-regular" ? ["calendar-icon"] : [];

    // Generate the styles object used to set the label width
    const calculateLabelStyles = () => {
        if (showLabel) {
            // Extract relevant data from the appropriate DOM elements
            const fieldContainerEl = fieldContainerRef.current;
            const fieldContainerFieldEl = fieldContainerEl.querySelector(
                ".field-container__field",
            );
            const fieldContainerLabelEl = fieldContainerEl.querySelector(
                ".field-container__label",
            );
            const fieldContainerFieldCss = window.getComputedStyle(
                fieldContainerFieldEl,
            );
            const fieldContainerLabelCss = window.getComputedStyle(
                fieldContainerLabelEl,
            );
            const fieldContainerWidth = parseFloat(
                fieldContainerFieldCss.getPropertyValue("width"),
            );
            const whiteSpaceToLeftOfLabel = parseFloat(
                fieldContainerLabelCss.getPropertyValue("left"),
            );
            let fieldContainerDescWidth = 0;
            const whiteSpaceToRightOfLabel = 15;

            // Extract relevant data from the description if one is present
            if (showDesc) {
                const fieldContainerDescEl = fieldContainerEl.querySelector(
                    ".field-container__desc",
                );
                const fieldContainerDescCss =
                    window.getComputedStyle(fieldContainerDescEl);
                fieldContainerDescWidth = parseFloat(
                    fieldContainerDescCss.getPropertyValue("width"),
                );
            }

            // Calculate the width of the label
            // The final width of the label in the UI is also multiplied by
            // the result of the scale() function passed to the transform
            // property in FieldContainer.scss
            const fieldContainerLabelWidth =
                fieldContainerWidth -
                whiteSpaceToLeftOfLabel -
                whiteSpaceToRightOfLabel -
                fieldContainerDescWidth;

            // Note: Running tests return an error for fieldContainerWidth and whiteSpaceToLeftOfLabel because they are NaN. I have
            // added a default of 100% if for some reason fieldContainerLabelWidth is NaN

            // Set the width of the label element
            setLabelStyles({
                maxWidth: fieldContainerLabelWidth || "100%",
            });
        }
    };

    // Set the label width when the application
    // loads and when window width has changed
    useEffect(() => {
        calculateLabelStyles();
    }, [screenWidth]);

    // Consolidate props into a single object
    const textBelowFieldProps = {
        errorTextProps: {
            errorText,
        },
        helpTextProps: {
            helpText,
        },
        maxCharProps: {
            maxChar,
            value,
        },
        showErrorText,
        showHelpText,
        showMaxChar,
    };

    return (
        <div
            className={fieldContainerClassNames}
            onMouseDown={handleMouseDown}
            onClick={handleClick}
            ref={fieldContainerRef}
        >
            <div className={fieldContainerInnerClassNames}>
                <div className="qg-vendor-field-container__field field-container__field">
                    {showLabel && (
                        <label
                            className={fieldContainerLabelClassNames}
                            htmlFor={htmlForName}
                            style={labelStyles}
                        >
                            {label}
                        </label>
                    )}
                    {children}
                </div>
                {showDesc && (
                    <label
                        className="field-container__desc"
                        htmlFor={htmlForName}
                        ref={descRef}
                    >
                        {showDescText && (
                            <div className="field-container__desc-text">
                                <span>{descText}</span>
                            </div>
                        )}
                        {showDescIcon && (
                            <div className="field-container__desc-icon">
                                <Icon
                                    classNames={iconClassNames}
                                    glyph={descIcon.glyph}
                                    type={descIcon.type}
                                />
                            </div>
                        )}
                    </label>
                )}
            </div>
            <TextBelowField {...textBelowFieldProps} />
        </div>
    );
};

FieldContainer.propTypes = {
    htmlForName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // We can pass labels as elements or strings
    label: PropTypes.node,
    helpText: PropTypes.string,
    descText: PropTypes.string,
    descIcon: PropTypes.shape({
        glyph: PropTypes.string,
        type: PropTypes.string,
    }),
    descRef: PropTypes.shape({ current: PropTypes.any }),
    disableInlineLabel: PropTypes.bool,
    active: PropTypes.bool,
    entered: PropTypes.bool,
    selected: PropTypes.bool,
    maxChar: PropTypes.string,
    multiline: PropTypes.bool,
    handleClick: PropTypes.func,
    handleMouseDown: PropTypes.func,
    cursor: PropTypes.string,
    children: PropTypes.any,
    innerBackground: PropTypes.bool,
    innerShadow: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    selectedOnHover: PropTypes.bool,
    name: PropTypes.string,
    value: (props, propName) => {
        if (
            props.maxChar &&
            props.maxChar.length > 0 &&
            props[propName] === undefined
        ) {
            return Error("Value is required when maxChar exists");
        }
        return null;
    },
};

export default FieldContainer;
