import React from "react";
import PropTypes from "prop-types";
import InstantPaymentToggle from "./InstantPaymentToggle";

const StandardPaymentMethods = ({
    instantPaymentToggleProps,
    numberOfStandardPaymentMethods,
}) =>
    numberOfStandardPaymentMethods > 0 ? (
        <InstantPaymentToggle {...instantPaymentToggleProps} />
    ) : (
        <h4 className="-text--center">
            Unfortunately, an error has occurred.
            <br />
            No Credit Card or Bank transactions are currently accepted on this
            form.
        </h4>
    );

StandardPaymentMethods.propTypes = {
    instantPaymentToggleProps: PropTypes.shape({}).isRequired,
    numberOfStandardPaymentMethods: PropTypes.number.isRequired,
};

export default StandardPaymentMethods;
