import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { createInstance } from "@datapunt/matomo-tracker-react";
import { constants, env } from "@qgiv/core-js";
import { trackEvent } from "@qgiv/core-react";

// -------------------------------------------------------------------------
// NOTE: We are using react-ga for loading GA and sending GA data
// @see: https://github.com/react-ga/react-ga
// -------------------------------------------------------------------------

// Flag to enable/disable analytics based on environment and host.
// Currently only enabled for production builds, and on staging/main/secure.
const isEnabled =
    env.IS_PROD() && (env.IS_STAGING_HOST() || env.IS_PROD_HOST());

/**
 * Enums
 */
export const EVENT_ACTION = {
    PAID: "paid",
    PURCHASE: "purchase",
    VIEW: "view",
};

export const EVENT_CATEGORY = {
    FORM: "Donation Form",
    P2P: "Peer-to-Peer",
};

export const EVENT_LABEL = {
    DONATION: "Donation",
    EMBEDDED_DONATION: "Embedded Donation",
    REGISTRATION: "Registration",
    APPLE_PAY: "Apple Pay",
    // Form Step View
    SINGLE_STEP_DONATION_PAGE: "Single Step Donation Page",
    SINGLE_STEP_CONFIRMATION_PAGE: "Single Step Confirmation Page",
    CHOOSE_GIFT_PAGE: "Choose Gift Page",
    DONOR_DETAILS_PAGE: "Donor Details Page",
    ADDITIONAL_DETAILS_PAGE: "Additional Details Page",
    PAYMENT_DETAILS_PAGE: "Payment Page",
    CONFIRMATION_PAGE: "Confirmation Page",
};

// Grab our tracking ids
const {
    uaProdTrackingId,
    uaDevTrackingId,
    gtmProdTrackingId,
    gtmDevTrackingId,
} = constants;

/**
 * @public
 * @function initGoogleAnalytics
 * @description Initialize UA and GA4 Google Analytics tracking data. UA
 *              tracking data is collected via Google Analytics while GA4
 *              tracking data is collected via Google Tag Manager.
 */
export const initGoogleAnalytics = (options) => {
    const uaTrackingId = env.IS_PROD_HOST()
        ? uaProdTrackingId
        : uaDevTrackingId;
    const gtmTrackingId = env.IS_PROD_HOST()
        ? gtmProdTrackingId
        : gtmDevTrackingId;
    const debug = env.IS_DEV();
    const tagManagerArgs = {
        gtmId: gtmTrackingId,
        dataLayer: options,
    };

    if (isEnabled) {
        // Initialize Google Analytics via the UA tracking scripts
        ReactGA.initialize(
            [
                {
                    trackingId: uaTrackingId,
                    gaOptions: {
                        name: "QgivAnalytics",
                    },
                },
            ],
            {
                debug,
                alwaysSendToDefaultTracker: false,
            },
        );
        ReactGA.ga("QgivAnalytics.set", "anonymizeIp", true);
        ReactGA.ga("QgivAnalytics.require", "ecommerce");

        // -------------------------------------------------------------------------
        // TODO: Move this out of initGoogleAnalytics() and into its own function
        // as Google Tag Manager is a separate thing from Google Analytics which
        // makes this current setup very confusing.
        // -------------------------------------------------------------------------
        // Initialize GTM script that is linked to GA4 tracking scripts
        TagManager.initialize(tagManagerArgs);
    }
};

// -------------------------------------------------------------------------
// TODO:
// 1. Confirm that this function behaves as expected when analytics tracking
// is set up on the redesigned P2P registration.
// -------------------------------------------------------------------------
/**
 * @public
 * @function trackApplePay
 * @param {boolean} formTemplate Type of form that is initiating the Apple Pay event.
 * @description Tracks Apple Pay transactions by sending a paid action with the
 *              label Apple Pay to the Qgiv Google Analytics account.
 */
export const trackApplePay = (formTemplate) => {
    const {
        ENUMS: { FormTemplate },
    } = constants;
    const getCategory = () => {
        switch (Number(formTemplate)) {
            case FormTemplate.NEW_STANDARD_FORM:
                return EVENT_CATEGORY.FORM;
            case FormTemplate.P2P_STANDARD:
                return EVENT_CATEGORY.P2P;
            default:
                return "";
        }
    };
    // Set event category based on form template
    const category = getCategory();
    const applePayEvent = {
        action: EVENT_ACTION.PAID,
        label: EVENT_LABEL.APPLE_PAY,
        category,
    };
    // Send event
    trackEvent(applePayEvent);
};

/**
 * @public
 * @function initMatomoAnalyticsInstance
 * @description Initialize Matomo Analytics tracking
 * @returns {object} Matomo analytics instance
 */
export const initMatomoAnalyticsInstance = () => {
    if (isEnabled) {
        // Urls that direct the Matomo script calls to the different Matomo
        // environments
        const matomoEnvironmentUrl = env.IS_PROD_HOST()
            ? "https://matomo.qgiv.com/"
            : "https://test.matomo.qgiv.com/";
        const matomoInstanceConfiguration = {
            urlBase: matomoEnvironmentUrl,
            siteId: 1,
        };

        return createInstance(matomoInstanceConfiguration);
    }
};

/**
 * @public
 * @function initMatomoAnalyticsTracking
 * @param {object} matomo Object that contains the different methods that can
 *         be used to track the different analytics that can be recorded by
 *         Matomo.
 * @param {object} options Object that contains the page title, form id and org id.
 * @description Runs methods that will initiate Matomo's tracking of certain events.
 */
export const initMatomoAnalyticsTracking = (matomo, options) => {
    if (isEnabled) {
        const { trackPageView } = matomo;
        const { formId = 0, orgId = 0, title = "" } = options;
        // Configure custom dimensions
        const formIdCustomDimension = {
            id: 1,
            value: formId,
        };
        const orgIdCustomDimension = {
            id: 2,
            value: orgId,
        };

        trackPageView({
            documentTitle: title,
            customDimensions: [formIdCustomDimension, orgIdCustomDimension],
        });
    }
};
