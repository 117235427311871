import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Standard Accordion Label styling, if custom style is needed
// replace this element when Accordion is implemented with styled component
const AccordionHeader = ({
    ariaExpanded,
    ariaLabel,
    children,
    size,
    ...props
}) => {
    const panelHeadingClasses = cx(
        "panel__heading",
        `panel__heading--${size}`,
        "-cursor--pointer",
    );

    return (
        <button
            type="button"
            aria-label={ariaLabel}
            aria-expanded={ariaExpanded}
            className={panelHeadingClasses}
            {...props}
        >
            {children}
        </button>
    );
};

AccordionHeader.propTypes = {
    ariaExpanded: PropTypes.bool,
    ariaLabel: PropTypes.string,
    // Allow it to accept a single child element or multiple child elements
    children: PropTypes.array.isRequired,
    props: PropTypes.object,
    size: PropTypes.string,
};

export default AccordionHeader;
