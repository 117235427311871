import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectDonorAccount,
    selectIsLoggedIn,
} from "../../../redux/slices/donorAccountSlice";
import { selectConfig } from "../../../redux/slices/configSettingsSlice";
import { sendEmbedLoginMessage } from "../../PostMessage/postMessage";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import {
    selectDirectLoginModalHasOpened,
    setDirectLoginModalHasOpened,
} from "../../../redux/slices/appSettingsSlice";
import DonorLogin from "./DonorLogin";

const ConnectedDonorLogin = () => {
    const dispatch = useDispatch();
    const directLoginModalHasOpened = useSelector(
        selectDirectLoginModalHasOpened,
    );
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const { id } = useSelector(selectAllFormSettings);
    const { userId = "" } = useSelector(selectDonorAccount);

    const { isDirectLogin = false } = useSelector(selectConfig);

    const donorAccountId = userId;

    // If the /login URL is appended to the path and the donor is not logged in
    // trigger the log in event opening the sign in modal
    useEffect(() => {
        if (
            isDirectLogin &&
            !directLoginModalHasOpened &&
            donorAccountId?.length === 0
        ) {
            sendEmbedLoginMessage(id);
            dispatch(setDirectLoginModalHasOpened(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <DonorLogin isLoggedIn={isLoggedIn} />;
};

export default ConnectedDonorLogin;
