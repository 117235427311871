import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import moment from "moment";
import {
    translateImageSize,
    translateRecurringUnit,
    currencyString,
} from "@qgiv/core-js";
import GivingPlanCard from "./GivingPlanCard";
import { selectSpecificFormSetting } from "../../../../../../redux/slices/formSettingsSlice";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Plan} Plan
 */

/**
 * @param {object} props
 * @param {Plan} props.plan - The individual giving plan object derived from activePlans
 * @param {React.ChangeEventHandler} props.handleChange
 * @param {boolean} props.isInstallment
 * @returns {React.ReactElement} The GivingPlanCard component.
 */
const ConnectedGivingPlanCard = ({ plan, handleChange, isInstallment }) => {
    const {
        amount,
        begin_date,
        description,
        frequencyEnum,
        id,
        image,
        name,
        restriction,
        subrestriction,
        times,
    } = plan;

    const { values } = useFormikContext();

    const selected = Number(values.Selected_Plan) === Number(id);

    const currency = useSelector((state) =>
        selectSpecificFormSetting(state, "currency"),
    );
    const imageSize = useSelector((state) =>
        selectSpecificFormSetting(state, "imageSize"),
    );

    const shouldDisplayRestriction = restriction?.length > 0;
    const shouldDisplayImage = image.length > 0;

    const getRestrictionDetails = () => {
        if (!restriction.length) {
            return null;
        }
        let restrictionDetails = `Supports ${restriction}`;
        if (subrestriction?.length) {
            restrictionDetails += `: ${subrestriction}`;
        }
        return restrictionDetails;
    };

    // Plan Summary Props
    const formattedSummaryAmount = !isInstallment
        ? currencyString(amount * times, currency)
        : currencyString(amount, currency);
    const formattedSummaryText = isInstallment
        ? " gifted over installments"
        : ` gifted over ${times} ${times === 1 ? "installment" : "installments"}`;

    const isUnlimitedPlan = times === -1;
    const isPredefinedPlanWithEndDate = !isInstallment && !isUnlimitedPlan;
    const shouldDisplaySummary = isInstallment || isPredefinedPlanWithEndDate;

    // Start Date props
    const shouldDisplayFutureStartDate = () => {
        const currentDate = moment().format("MM/DD/YYYY");
        const planStartDate = moment(begin_date).format("MM/DD/YYYY");
        // if plan does not have a future start date or the
        // plan start date is today we do not display a date
        if (!begin_date || currentDate === planStartDate) {
            return false;
        }

        return true;
    };

    const shouldDisplayDivider =
        shouldDisplayRestriction ||
        shouldDisplaySummary ||
        shouldDisplayFutureStartDate();

    const planStartDate = !begin_date ? moment() : begin_date;
    const formattedStartDate = moment(planStartDate).format("MM/DD/YYYY");

    // Image Props
    const imageProps = image
        ? {
              url: image,
              altText: name,
              display: translateImageSize(imageSize),
          }
        : {};

    // Amount with Frequency
    const planAmount = currencyString(amount, currency);

    let planFrequency;

    if (!isInstallment) {
        planFrequency = translateRecurringUnit(frequencyEnum).toLowerCase();
    }

    const givingPlanCardProps = {
        description,
        formattedStartDate,
        formattedSummaryAmount,
        formattedSummaryText,
        handleChange,
        id,
        imageProps,
        isInstallment,
        name,
        plan,
        planAmount,
        planFrequency,
        restrictionDetails: getRestrictionDetails(),
        selected,
        shouldDisplayFutureStartDate: shouldDisplayFutureStartDate(),
        shouldDisplayDivider,
        shouldDisplayImage,
        shouldDisplayRestriction,
        shouldDisplaySummary,
    };

    return <GivingPlanCard {...givingPlanCardProps} />;
};

export default ConnectedGivingPlanCard;
