import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ProcessingBackground from "../ProcessingBackground";
import ProcessingDots from "../ProcessingDots";

import "./ApplePaySubmit.scss";

const ApplePaySubmit = ({
    handleApplePayButtonClick,
    isApplePay,
    isSubmitting,
    isDisabled,
}) => {
    // The button type is set to "button" instead of "submit" as all of the
    // Apple Pay logic needs to be run before the form is submitted.
    // The processing background is the white overlay that is displayed during
    // form submission.
    const defaultButtonClassNames = cx(
        "apple-pay-submit__button apple-pay-button apple-pay-button-black",
        isDisabled && "apple-pay-submit__button-disabled",
    );
    return (
        <div className="apple-pay-submit">
            {isSubmitting && isApplePay ? (
                <>
                    <ProcessingBackground />
                    <button
                        className="apple-pay-submit__button apple-pay-submit__button-processing qgiv-apple-pay-button__processing"
                        type="button"
                    >
                        <ProcessingDots />
                    </button>
                </>
            ) : (
                <button
                    className={defaultButtonClassNames}
                    type="button"
                    onClick={handleApplePayButtonClick}
                ></button>
            )}
        </div>
    );
};

ApplePaySubmit.propTypes = {
    handleApplePayButtonClick: PropTypes.func.isRequired,
    isApplePay: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};

export default ApplePaySubmit;
