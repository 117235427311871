import React from "react";
import PropTypes from "prop-types";
import Field from "./Field";

import "./FieldStack.scss";
import ErrorText from "./ErrorText";

const FieldStack = ({ fields = [], setErrorBelowStack = false }) => (
    <div className="field-stack">
        {fields.map((field, i) => (
            <Field
                autoFocus={field.autoFocus}
                error={field.error}
                errorText={!setErrorBelowStack ? field.errorText : ""}
                handleBlur={field.handleBlur}
                handleChange={field.handleChange}
                id={field.id ? field.id : field.name}
                key={field.id ? field.id : field.name}
                label={field.label}
                multiline={field.multiline}
                name={field.name}
                noShadow={i !== 0}
                type={field.type}
                value={field.value}
            />
        ))}

        {/* Option to display errors at the bottom of entire stack instead of below each field */}
        {setErrorBelowStack
            ? fields.map((field) => {
                  if (field.error && field.errorText) {
                      return (
                          <ErrorText
                              key={field.id ? field.id : field.name}
                              errorText={field.errorText}
                          />
                      );
                  }
                  return null;
              })
            : null}
    </div>
);

FieldStack.propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            multiline: PropTypes.bool,
            handleBlur: PropTypes.func.isRequired,
            handleChange: PropTypes.func.isRequired,
        }),
    ).isRequired,
    setErrorBelowStack: PropTypes.bool,
};

export default FieldStack;
