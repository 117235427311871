import React from "react";
import PropTypes from "prop-types";
import { Select } from "@qgiv/core-donor";

const Restrictions = ({
    activeDisplayableRestrictions,
    ariaLabel,
    fieldId,
    fieldName,
    handleBlur,
    handleRestrictionChange,
    restrictionLabel,
    selectedRestrictionDescription,
    showRestrictionDescription,
    Standard_Restriction_Id,
}) => (
    // eslint-disable-next-line tailwindcss/classnames-order
    <div className="grid restrictions">
        <div className="col col--12 restrictions__select">
            <Select
                disableBlankOption
                disableInlineLabel
                id={fieldId}
                name={fieldName}
                handleBlur={handleBlur}
                handleChange={handleRestrictionChange}
                label={restrictionLabel}
                value={String(Standard_Restriction_Id)}
                ariaLabel={ariaLabel}
            >
                {activeDisplayableRestrictions.map((restriction) => (
                    <option
                        key={restriction.id}
                        value={restriction.id}
                        className="restrictions__select--option"
                    >
                        {restriction.name}
                    </option>
                ))}
            </Select>
        </div>
        {showRestrictionDescription && (
            <div className="col col--12 restrictions__description">
                <p>{selectedRestrictionDescription}</p>
            </div>
        )}{" "}
    </div>
);

Restrictions.propTypes = {
    activeDisplayableRestrictions: PropTypes.arrayOf(PropTypes.shape({}))
        .isRequired,
    ariaLabel: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleRestrictionChange: PropTypes.func.isRequired,
    restrictionLabel: PropTypes.string.isRequired,
    selectedRestrictionDescription: PropTypes.string.isRequired,
    showRestrictionDescription: PropTypes.bool.isRequired,
    Standard_Restriction_Id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};

export default Restrictions;
