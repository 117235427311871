import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";

import "../InputBox/InputBox.scss";

const Radio = ({
    // HTML attributes
    checked = false,
    disabled = false,
    name,
    required = false,
    value,

    handleBlur = () => {},
    // change handler
    handleChange = () => {},

    // error
    error = false,
    errorText = "",

    // other
    inset = true,
    innerPadding = true,
    children,
}) => {
    // Remove padding from the outer <div> and add it back to the <label/>
    // element to ensure that there is not a mismatch between the outline that
    // appears when using a screen reader which is tied to the width of the
    // <label/> and the actual element
    const checkboxFieldClasses = cx(
        "-padding-left--0",
        "-padding-right--0",
        "qg-vendor-input-box",
        "input-box input-box--radio",
        checked && "input-box--checked",
        disabled ? "input-box--disabled" : "-cursor--pointer",
        inset && "input-box--inset",
        error && "input-box--error",
    );
    const checkboxInnerClasses = cx(
        "-padding-left--15",
        "-padding-right--15",
        "qg-vendor-input-box__inner",
        "input-box__inner",
        innerPadding && "input-box__inner--padding",
        !disabled && "-cursor--pointer",
    );
    const id = `${name}-${value}`;

    // -------------------------------------------------------------------------
    // NOTE:
    // Do not move the <input/> inside of the <span/> that wraps the circle
    // icon. Doing this prevents clicks that are outside of the <input/> but
    // within the <label/> from registering as selections of a particular radio
    // option. Because of this all clicks on radio options that were outside of
    // the <input/> element were registering as selections of the first element
    // which is very counterintuitive to users. This was discovered while fixing
    // a bug where we discovered that approximately 99% of users had selected
    // the first radio option because of this.
    // -------------------------------------------------------------------------
    return (
        <div className={checkboxFieldClasses}>
            <label className={checkboxInnerClasses} htmlFor={id}>
                <input
                    className="input-box__input -cursor--pointer"
                    type="radio"
                    checked={checked}
                    disabled={disabled}
                    name={name}
                    required={required}
                    value={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    id={id}
                />
                {/* Icon that displays whether the radio button is checked */}
                <span className="qg-vendor-input-box__icon input-box__icon">
                    <Icon
                        classNames={["input-box__svg"]}
                        glyph="circle"
                        type="Custom"
                    />
                </span>
                <span className="input-box__label">{children}</span>
            </label>
            {error && errorText?.length > 0 && (
                <span className="input-box__error-text">{errorText}</span>
            )}
        </div>
    );
};

Radio.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    inset: PropTypes.bool,
    innerPadding: PropTypes.bool,
    children: PropTypes.string.isRequired,
};

export default Radio;
