import React from "react";
import PropTypes from "prop-types";
import ErrorText from "./ErrorText";

import { VALIDATION_ERROR_MESSAGE } from "@qgiv/core-validation";

const FieldError = (props) => {
    const { error, label, touched } = props;
    // If the error message matches the required validation error we can
    // generate a required validation error using the field label from CMS
    const message =
        error === VALIDATION_ERROR_MESSAGE.REQUIRED
            ? `${label} is required`
            : error;

    return touched && error ? <ErrorText errorText={message} /> : null;
};

FieldError.propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
};

export default FieldError;
