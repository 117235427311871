import React from "react";
import { Formik, Form } from "formik";
import { UpdateFieldErrorFocus, SEO } from "@qgiv/core-donor";
import ModalContentReplacer from "../../common/ModalContentReplacer";
import ConnectedGiftPageContent from "./GiftPageContent";
import ConnectedDonationFormBase from "../templates/DonationFormBase";
import ConnectedSubmitDonation from "../../common/SubmitDonation";

/**
 * @param {object} props
 * @param {React.ReactNode} props.alternateContent
 * @param {boolean} props.displayCaptcha
 * @param {string} props.documentTitle
 * @param {object} props.formikProps
 * @param {number} props.selectedContentToDisplay
 * @param {boolean} props.submitDonation
 * @returns {React.ReactElement}
 */
const GiftPage = ({
    alternateContent,
    displayCaptcha,
    documentTitle,
    formikProps,
    selectedContentToDisplay,
    submitDonation,
}) => (
    <>
        <SEO title={documentTitle} description="" />
        <Formik {...formikProps}>
            <ConnectedDonationFormBase>
                <ModalContentReplacer
                    selectedContentToDisplay={selectedContentToDisplay}
                    alternateContent={alternateContent}
                >
                    <Form>
                        <ConnectedGiftPageContent
                            displayCaptcha={displayCaptcha}
                        />
                    </Form>
                </ModalContentReplacer>
                {submitDonation && <ConnectedSubmitDonation />}
                <UpdateFieldErrorFocus />
            </ConnectedDonationFormBase>
        </Formik>
    </>
);

export default GiftPage;
