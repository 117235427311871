import moment from "moment";
import { currencyString } from "@qgiv/core-js";
import { calEarlyBirdPrice } from "../../../utility";

/**
 * @param {object[]} packages - An array of package objects
 * @param {string} packages[].earlyBirdDate - The early bird date for the package
 * @param {number} packages[].earlyBirdDiscountType - The type of early bird discount (e.g., percentage or fixed amount)
 * @param {number} packages[].earlyBirdDiscount - The amount of the early bird discount
 * @param {number} packages[].cost - The regular cost of the package
 * @returns {Array<number>} An array of package costs, with early bird discounts applied where applicable
 */
export const getPackageCosts = (packages) => {
    const now = moment();
    const packageCosts = packages.map((pkg) =>
        moment(pkg.earlyBirdDate) > now
            ? calEarlyBirdPrice(
                  pkg.earlyBirdDiscountType,
                  pkg.earlyBirdDiscount,
                  pkg.cost,
              )
            : pkg.cost,
    );

    return packageCosts;
};

/**
 * @param {number} minCost - The minimum cost of the event
 * @param {number} maxCost - The maximum cost of the event
 * @param {object} currency - The currency symbol to use for formatting
 * @returns {string} The formatted cost string displayed as a range if minCost
 * and maxCost are different, or as a single cost if they are the same
 */
export const getFormattedCost = (minCost, maxCost, currency) => {
    const formattedCost =
        minCost !== maxCost
            ? `${currencyString(
                  minCost,
                  currency,
              )} - ${currencyString(maxCost, currency)}`
            : currencyString(minCost, currency);
    return formattedCost;
};

/**
 * @param {string} path - The base path for the event
 * @param {boolean} isStandardEvent - Flag indicating whether the event is a standard event
 * @param {string} alias - The alias for the event
 * @param {string} eventId - The unique identifier for the event
 * @param {boolean} isUsingExternalEventPage - Flag indicating whether the event is using an external event page
 * @param {string} externalEventPageUrl - The URL of the external event page
 * @returns {string} The constructed URL for the event
 */
export const getEventUrl = (
    path,
    isStandardEvent,
    alias,
    eventId,
    isUsingExternalEventPage,
    externalEventPageUrl,
) => {
    // If the event is not using our landing page then we should send them
    // to their customized external url
    const eventInternalUrl = `/for/${path}/event/${
        isStandardEvent && alias ? alias : eventId
    }/`;
    const eventExternalUrl =
        isUsingExternalEventPage && !!externalEventPageUrl
            ? externalEventPageUrl
            : "";

    const eventUrl = eventExternalUrl || eventInternalUrl;

    return eventUrl;
};

/**
 * @param {string} e_date_begin - The start date of the event in "YYYY-MM-DD" format
 * @returns {object} An object containing the parsed event start date and the formatted event start date
 */
export const getEventStartDate = (e_date_begin) => {
    const eventStartDate = moment(e_date_begin, "YYYY-MM-DD");
    const formattedEventStartDate = eventStartDate.format("dddd, MMMM D, YYYY");

    return { eventStartDate, formattedEventStartDate };
};

/**
 * @param {Array<string>} venueArray - An array of venue strings
 * @returns {string} A formatted string of venue information, with non-empty strings joined by ", "
 */
export const getEventVenue = (venueArray) =>
    venueArray.filter((str) => str.length).join(", ");
