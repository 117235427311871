import React from "react";
import { constants } from "@qgiv/core-js";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getActiveDisplayableRestrictions,
    getMultiRestrictionFieldName,
    getNameOfAmountIdField,
    getNewFieldValueOnClick,
    getRestrictionById,
} from "@qgiv/donation-form";
import { selectConfig } from "../../../../../../../redux/slices/configSettingsSlice";
import {
    selectAllDonationDetails,
    updateGiftDetails,
} from "../../../../../../../redux/slices/donationDetailsSlice";
import { selectRestrictions } from "../../../../../../../redux/slices/restrictionSettingsSlice";
import { getCheckboxProps } from "./restrictionCardLabelHelpers";
import RestrictionCardLabel from "./RestrictionCardLabel";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 */

const { ENUMS } = constants;
const { DisplayOn } = ENUMS;

/**
 *
 * @param {object} props
 * @param {Amount[]} props.amountsToDisplayWithRestriction
 * @param {number} props.restrictionId
 * @param {boolean} props.showError
 * @returns {React.ReactElement}
 */
const ConnectedRestrictionCardLabel = ({
    amountsToDisplayWithRestriction,
    restrictionId,
    showError,
}) => {
    const config = useSelector(selectConfig);
    const dispatch = useDispatch();
    const donationDetails = useSelector(selectAllDonationDetails);
    const formikContext = useFormikContext();
    const restrictions = useSelector(selectRestrictions);
    const { currentDisplay = DisplayOn.FRONTEND, isCms = false } = config;
    const { giftDetails = {} } = donationDetails;
    const { setFieldValue = () => {}, values = {} } = formikContext;
    const { hasSelectedRecurringDonation = false } = giftDetails;

    // The BE hands us all active restrictions regardless of whether or not
    // they should be shown on this device
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    const restriction = getRestrictionById(
        activeDisplayableRestrictions,
        restrictionId,
    );
    const multiRestrictionFieldName =
        getMultiRestrictionFieldName(restrictionId);
    const existingMultiRestrictionFieldValue =
        values[multiRestrictionFieldName];
    const { description = "", name = "" } = restriction;

    /**
     *
     * @param {React.MouseEvent} e
     * @returns {undefined}
     * @description Clicks in the label can be used for selecting and
     * de-selecting a restriction. Need to prevent default & stop the
     * propagation of this event up to handleRestrictionClick() to ensure
     * that function does not revert any changes made by this handler.
     */
    const handleRestrictionCardLabelClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isCms) {
            const nameOfAmountIdField = getNameOfAmountIdField(
                hasSelectedRecurringDonation,
            );
            const newFieldValue = getNewFieldValueOnClick({
                amountsToDisplayWithRestriction,
                existingMultiRestrictionFieldValue,
                nameOfAmountIdField,
            });

            // Update Formik and then Redux
            setFieldValue(multiRestrictionFieldName, newFieldValue);
            dispatch(
                updateGiftDetails({
                    [multiRestrictionFieldName]: newFieldValue,
                }),
            );
        }
    };

    // Reassign and create variables as needed
    const checkboxProps = getCheckboxProps({ formikContext, restriction });
    // Connect the label being rendered to the input that is associated
    // with this restriction
    const htmlFor = multiRestrictionFieldName;
    const selected = existingMultiRestrictionFieldValue.Checked;
    const showDescription = !!description;

    const restrictionCardLabelProps = {
        checkboxProps,
        description,
        handleRestrictionCardLabelClick,
        htmlFor,
        name,
        selected,
        showDescription,
        showError,
    };

    return <RestrictionCardLabel {...restrictionCardLabelProps} />;
};

export default ConnectedRestrictionCardLabel;
