import React from "react";
import PropTypes from "prop-types";
import { Modal2 } from "../../Modal";
import WidgetSocialSharingEmailModalContent from "./WidgetSocialSharingEmailModalContent";

const WidgetSocialSharingEmailModal = ({
    handleRemoveSocialSharingEmailModal,
    handleSubmitSharingEmailPath,
    widgetSocialSharingEmailModalContentProps,
}) => {
    const handleEmailModalCloseButtonClick = () => {
        handleRemoveSocialSharingEmailModal(false);
    };

    return (
        <Modal2 onClose={handleEmailModalCloseButtonClick}>
            <WidgetSocialSharingEmailModalContent
                {...widgetSocialSharingEmailModalContentProps}
                handleRemoveSocialSharingEmailModal={
                    handleRemoveSocialSharingEmailModal
                }
                handleSubmitSharingEmailPath={handleSubmitSharingEmailPath}
            />
        </Modal2>
    );
};

WidgetSocialSharingEmailModal.propTypes = {
    handleSubmitSharingEmailPath: PropTypes.func.isRequired,
    handleRemoveSocialSharingEmailModal: PropTypes.func.isRequired,
    widgetSocialSharingEmailModalContentProps: PropTypes.shape({}).isRequired,
};

export default WidgetSocialSharingEmailModal;
