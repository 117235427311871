import { Yup, getAccountValidations } from "@qgiv/core-validation";

// -------------------------------------------------------------------------
// NOTE: The validation logic that is associated with this form is
//       structured as follows:
//       1. When possible reuse existing global level validation logic by
//       accessing the schema chunks have been accessed from in
//       qgiv/validation.js.
//       2. If there is no existing global level validation logic that is
//       relevant to the field that you are validating and the field that
//       you are validating is static (always visible) or relatively simple
//       (e.g. a piece of validation that is always optional) add the
//       validation logic as form level schemas in this file.
//       3. If there is no existing global level validation logic that is
//       relevant to the field that you are validating and the field that
//       you are validating is dynamic (only visible when certain conditions
//       are present) and sufficiently complex, add the logic associated
//       with validating the field as a field level function that is
//       imported from this file. The reason this is typically preferable is
//       that field level validation is only run when a field is visible.
//       This means that adding the validation logic as field validation
//       ensures that there is no need to recreate the often complex logic
//       that toggles the visibility of a field while writing the validation.
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// MARK: Donor account form level validation schema
// -------------------------------------------------------------------------
const getDonorAccountFormValidations = () =>
    // Generate combined form level validation schema
    ({
        ...getAccountValidations(),
    });

const getValidationSchema = () => {
    const validationShape = {
        ...getDonorAccountFormValidations(),
    };
    const schema = Yup.object().shape(validationShape);

    return schema;
};

export default getValidationSchema;
