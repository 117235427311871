import React from "react";
import { isDisplayable } from "@qgiv/core-js";
import { QFieldInput } from "@qgiv/core-donor";

/**
 * DedicationField Component
 * @description A React component that renders a single dedication field with validation and event handling.
 * @param {object} props - The properties passed to the component.
 * @param {number} props.currentDisplay - The current display type (e.g., Desktop, Mobile, Text-to-Donate).
 * @param {object} props.errors - An object containing validation errors for the fields.
 * @param {object} props.field - The field configuration object.
 * @param {Function} props.handleBlur - The function to handle blur events.
 * @param {Function} props.handleChange - The function to handle change events.
 * @param {Function} props.setFieldValue - The function to set the value of a field.
 * @param {object} props.touched - An object indicating which fields have been touched.
 * @param {object} props.values - An object containing the current values of the fields.
 * @returns {(React.ReactElement|null)} The rendered dedication field component or null
 */
const DedicationField = ({
    currentDisplay,
    errors,
    field,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    values,
}) => {
    const qFieldProps = {
        currentDisplay,
        errors,
        field,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        values,
    };
    const displayable = isDisplayable(field, currentDisplay);
    return displayable ? <QFieldInput {...qFieldProps} /> : null;
};

export default DedicationField;
