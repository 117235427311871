import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@qgiv/core-donor";

const SavePaymentMethod = ({
    handleBlur,
    handleChange,
    savePaymentMethodLabel,
    Save_Payment,
}) => (
    <div className="grid">
        <div className="col col--12">
            <Checkbox
                id="Save_Payment"
                name="Save_Payment"
                innerPadding={false}
                inset={false}
                handleBlur={handleBlur}
                handleChange={handleChange}
                checked={Save_Payment}
            >
                <p>{savePaymentMethodLabel}</p>
            </Checkbox>
        </div>
    </div>
);

SavePaymentMethod.propTypes = {
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    savePaymentMethodLabel: PropTypes.string.isRequired,
    Save_Payment: PropTypes.bool.isRequired,
};

export default SavePaymentMethod;
