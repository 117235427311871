import React from "react";
import Container from "./Container";

/**
 * @param {object} props - Component properties
 * @param {Array} props.containers - An array of container objects to be displayed on the events page
 * @returns {React.ReactElement} The EventsPageContainers component.
 */
const EventsPageContainers = ({ containers }) => {
    const containerProps = {
        page: "events-page",
    };

    return containers.map((container) => (
        <Container
            key={container.id}
            container={container}
            {...containerProps}
        />
    ));
};

export default EventsPageContainers;
