import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectAllEventSettings } from "../../../../redux/slices/eventSettingsSlice";
import { updateContentToDisplay } from "../../../../redux/slices/appSettingsSlice";
import {
    updateSelectedRecipient,
    selectSelectedRecipient,
    selectShouldDisplayRecipientValidationError,
    updateShouldDisplayRecipientValidationError,
} from "../../../../redux/slices/donationDetailsSlice";
import { selectSpecificFormSetting } from "../../../../redux/slices/formSettingsSlice";
import { ModalContentReplacerEnums } from "../../../common/ModalContentReplacer";
import {
    getResizeMessageToSend,
    sendPostMessage,
} from "../../../PostMessage/postMessage";
import ChooseRecipient from "./ChooseRecipient";

const ConnectedChooseRecipient = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS },
            EntityType: { FORM },
            FormTemplate: { P2P_STANDARD },
        },
    } = constants;

    const selectedRecipient = useSelector(selectSelectedRecipient);

    const storedRecipientIsEventType =
        Object.keys(selectedRecipient).length > 0 &&
        selectedRecipient?.entityType === FORM;

    const [donateToEvent, setDonateToEvent] = useState(
        storedRecipientIsEventType,
    );

    // Ensure donateToEvent is current version of storedRecipientIsEventType.
    // When selecting event from search list (empty search results state)
    // useState is not re-evaluated because to component is never removed
    // from the DOM while using the modal content replacer component
    useEffect(() => {
        setDonateToEvent(storedRecipientIsEventType);
    }, [storedRecipientIsEventType]);

    const dispatch = useDispatch();

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, DONATION_AMOUNTS),
    );

    const { options = {} } = control;

    const {
        chooseRecipientHeading = "Whose fundraising efforts would you like your gift to support?",
        chooseRecipientButtonLabel = "Choose someone",
        chooseRecipientButtonDescription = "Your gift will be applied to their fundraising goal.",
        chooseRecipientCheckboxLabel = "No one specifically, just the overall campaign.",
    } = options;

    const setModalContentToSearchUI = () => {
        dispatch(
            updateContentToDisplay(
                ModalContentReplacerEnums.RECIPIENT_SEARCH_UI,
            ),
        );
        setTimeout(() => {
            getResizeMessageToSend();
            sendPostMessage("top", { formTemplate: P2P_STANDARD });
        }, 10);
    };

    const id = useSelector((state) => selectSpecificFormSetting(state, "id"));

    const { enableDonationToEvent } = useSelector(selectAllEventSettings);

    const handleDonateToEventCheckboxChange = () => {
        setDonateToEvent((prev) => {
            const newDonateValue = !prev;
            const newRecipientData = newDonateValue
                ? {
                      entity: id, // form id
                      entityType: FORM,
                  }
                : {};
            dispatch(updateSelectedRecipient(newRecipientData));
            dispatch(updateShouldDisplayRecipientValidationError(false));
            return newDonateValue;
        });
    };

    const shouldDisplayRecipientValidationError = useSelector(
        selectShouldDisplayRecipientValidationError,
    );

    return (
        <ChooseRecipient
            chooseRecipientHeading={chooseRecipientHeading}
            chooseRecipientButtonLabel={chooseRecipientButtonLabel}
            chooseRecipientButtonDescription={chooseRecipientButtonDescription}
            chooseRecipientCheckboxLabel={chooseRecipientCheckboxLabel}
            donateToEvent={donateToEvent}
            enableDonationToEvent={enableDonationToEvent}
            handleDonateToEventCheckboxChange={
                handleDonateToEventCheckboxChange
            }
            setModalContentToSearchUI={setModalContentToSearchUI}
            shouldDisplayRecipientValidationError={
                shouldDisplayRecipientValidationError
            }
        />
    );
};

ConnectedChooseRecipient.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedChooseRecipient;
