import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    PayPalSDKScriptProvider,
    getPayPalSDKScriptProviderProps,
    getDisplayPayPalButtonsFlags,
    getPaymentTypeInitialValue,
} from "@qgiv/core-donor";
import { useFormikContext } from "formik";
import { getValuesForCreateOrderAPI } from "@qgiv/donation-form/source/utility/paypalHelpers";
import {
    selectGiftDetails,
    selectHasSelectedRecurringDonation,
} from "../../../../redux/slices/donationDetailsSlice";
import {
    incrementForceReRenderCount,
    selectForceReRenderCount,
    selectHasCompletedPayPalInitialRender,
    selectPaymentMethodResetCount,
    setHasCompletedPayPalAuthorizationFlow,
    setHasCompletedPayPalInitialRender,
    setHasInitiatedPayPalAuthorizationFlow,
    setPayPalPaymentSource,
} from "../../../../redux/slices/appSettingsSlice";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectDonorAccount } from "../../../../redux/slices/donorAccountSlice";

const ConnectedPayPalSDKScriptProvider = () => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const formikContext = useFormikContext();
    const dispatch = useDispatch();
    const formSettings = useSelector((state) => selectAllFormSettings(state));
    const giftDetails = useSelector(selectGiftDetails);
    const { total } = giftDetails;
    const { paymentData = {}, isStandardDonationForm } = formSettings;
    const {
        enableAchPayments,
        enableCCPayments,
        enablePayPalPayments,
        payPalSDK,
    } = paymentData;
    const donorAccount = useSelector(selectDonorAccount);
    const paymentTypeInitialValue = getPaymentTypeInitialValue(
        {
            enableCCPayments,
            enableAchPayments,
            enablePayPalPayments,
        },
        donorAccount,
    );
    const { setFieldValue = () => {}, submitForm = () => {} } = formikContext;
    const values = getValuesForCreateOrderAPI(giftDetails);
    const orderSource = isStandardDonationForm ? "Standard Donation Form" : "";
    const platformSpecificDataForCreateOrderAPI = {
        payPalSDK,
        values,
        orderSource,
    };
    const paymentMethodResetCount = useSelector((state) =>
        selectPaymentMethodResetCount(state),
    );
    const forceReRenderCount = useSelector(selectForceReRenderCount);
    const {
        displayPayPalSDKScriptProvider,
        shouldLoadPayPalSDKScriptProvider,
    } = getDisplayPayPalButtonsFlags(payPalSDK);
    // Check if we're on a recurring donation — if yes, hide Venmo button.
    const isRecurringDonation = useSelector(selectHasSelectedRecurringDonation);
    // Make the button appear enabled in CMS
    const isCms = useSelector(selectIsCms);
    // Only enable the PayPal button when there is a donation amount to process
    const hasAnAmountToSendToPayPal = total > 0;
    // Assemble the data needed by the update component. The
    // displayUpdateIncrementForceReRenderCount flag can be removed once this
    // component has been integrated into every instance of the PayPal SDK
    const displayUpdateIncrementForceReRenderCount = true;
    // -------------------------------------------------------------------------
    // TODO: When implementing Single-Step forms, ensure that the forceReRender
    // debounce logic prevents out-of-control re-renders on 'Other' amount changes
    // -------------------------------------------------------------------------
    const dispatchIncrementForceReRenderCount = () => {
        dispatch(incrementForceReRenderCount());
    };
    // -------------------------------------------------------------------------
    // NOTE:  Ideally, this component should also rerender based on Recurring_Frequency,
    // Start_Date, and End_Date, but it seems that for Recurring Donations, none of these
    // values force a rerender, due to the differences in how we handle one-time and
    // recurring PayPal transactions. This appears to occur on Form, as well.
    // -------------------------------------------------------------------------
    const valuesThatShouldForceAReRender = [total];
    const hasCompletedInitialRender = useSelector(
        selectHasCompletedPayPalInitialRender,
    );
    const dispatchSetHasCompletedPayPalInitialRender = () => {
        dispatch(setHasCompletedPayPalInitialRender());
    };

    // -------------------------------------------------------------------------
    // Re-render if there is any change to the overall total of the
    // donation. Such a change should NOT be possible in donationForm, but
    // we are leaving this value here in case an unforeseen bug
    // allows the donation amount to change.
    // -------------------------------------------------------------------------
    const forceReRender = [forceReRenderCount];
    const paypalSDKScriptProviderPropsOptions = {
        dispatchIncrementForceReRenderCount,
        dispatchSetHasCompletedPayPalInitialRender,
        displayPayPalSDKScriptProvider,
        displayUpdateIncrementForceReRenderCount,
        forceReRender,
        hasAnAmountToSendToPayPal,
        hasCompletedInitialRender,
        isCms,
        isRecurringDonation,
        paymentMethodResetCount,
        payPalSDK,
        platformSpecificDataForCreateOrderAPI,
        shouldLoadPayPalSDKScriptProvider,
        valuesThatShouldForceAReRender,
    };
    const paypalSDKScriptProviderProps = getPayPalSDKScriptProviderProps(
        paypalSDKScriptProviderPropsOptions,
    );

    const handleCompletePayPalAuthorizationFlow = ({
        payPalValues = {},
        // eslint-disable-next-line no-shadow
        payPalPaymentSource = "paypal",
    }) => {
        const {
            PayPal_Agreement_ID = "",
            PayPal_Is_Venmo = false,
            PayPal_Payer_ID = "",
            PayPal_Token = "",
        } = payPalValues;

        // Set the value for the relevant PayPal fields including the billing
        // agreement ID which is required for recurring donations
        setFieldValue("Payment_Type", PaymentType.PAYPAL);
        setFieldValue("PayPal_Payer_ID", PayPal_Payer_ID);
        setFieldValue("PayPal_Token", PayPal_Token);

        if (PayPal_Agreement_ID) {
            setFieldValue("PayPal_Agreement_ID", PayPal_Agreement_ID);
        }

        if (PayPal_Is_Venmo) {
            setFieldValue("PayPal_Is_Venmo", true);
        }

        // Update the relevant values in the Redux store. Incrementing the step
        // submit count matches the behavior on the continue button and enables
        // an error message to be displayed if there is one
        dispatch(setHasCompletedPayPalAuthorizationFlow(true));
        dispatch(setPayPalPaymentSource(payPalPaymentSource));
    };

    // Display the use a different payment method button
    const handleInitiatePayPalAuthorizationFlow = (
        updatedHasInitiatedPayPalAuthorizationFlow,
    ) => {
        dispatch(
            setHasInitiatedPayPalAuthorizationFlow(
                updatedHasInitiatedPayPalAuthorizationFlow,
            ),
        );
    };
    const handleHasAnAmountToSendToPayPalError = () => {
        // -------------------------------------------------------------------------
        // TODO: For Express Checkout, Single Step, and (possibly) Matching Gifts,
        // display any potential validation issues before letting the donor go through
        // the PayPal validation process.
        // -------------------------------------------------------------------------
        submitForm();
        // dispatch(incrementStepSubmitCount());
    };

    // Reset the values that were set when the donor clicked the PayPal button
    // as once an error is thrown we are no longer attempting to process a
    // PayPal transaction
    const handlePayPalAuthorizationError = (
        hasInitiatedPayPalAuthorizationFlow,
    ) => {
        // Replace with whatever the default value is
        setFieldValue("Payment_Type", paymentTypeInitialValue);
        dispatch(
            setHasInitiatedPayPalAuthorizationFlow({
                hasInitiatedPayPalAuthorizationFlow,
            }),
        );
    };

    const modifiedPayPalSDKScriptProviderProps = {
        ...paypalSDKScriptProviderProps,
        handleCompletePayPalAuthorizationFlow,
        handleHasAnAmountToSendToPayPalError,
        handleInitiatePayPalAuthorizationFlow,
        handlePayPalAuthorizationError,
    };

    return (
        <PayPalSDKScriptProvider {...modifiedPayPalSDKScriptProviderProps} />
    );
};

export default ConnectedPayPalSDKScriptProvider;
