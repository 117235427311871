import {
    getAlwaysDisplayAmountsAndSubRestrictions,
    getAmountsFromRestrictionSettings,
    getHasDisplayableSubRestriction,
    getMultiRestrictionFieldName,
    getNameOfAmountIdField,
    getNewFieldValueOnClick,
    getRestrictionById,
} from "@qgiv/donation-form";

/**
 * @typedef {import("./restrictionCardsTypes").RestrictionCardProps} RestrictionCardProps
 * @typedef {import("@qgiv/donation-form").ReduxTypes.Config.Config} Config
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 * @typedef {import("@qgiv/donation-form").ReduxTypes.CMSSettings.StandardDonationGiftContentControl} StandardDonationGiftContentControl
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.MultiRestrictionGroup} MultiRestrictionGroup
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.Restriction} Restriction
 * @typedef {import("formik").FormikProps<{}>} FormikProps
 */

/**
 *
 * @param {object} settings
 * @param {Restriction[]} settings.activeDisplayableRestrictions
 * @param {MultiRestrictionGroup[]} settings.multiRestrictionGroups
 * @param {number[]} settings.restrictionIdsForRestrictionGroup Restrictions to display in group of cards.
 * @returns {Restriction[]} Data for restrictions that should be displayed in group of cards.
 */
export const getRestrictionsForCards = ({
    activeDisplayableRestrictions,
    multiRestrictionGroups,
    restrictionIdsForRestrictionGroup,
}) => {
    const hasMultiRestrictionGroups = multiRestrictionGroups.length > 0;
    let restrictionsForCards = [];

    if (!hasMultiRestrictionGroups) {
        restrictionsForCards = activeDisplayableRestrictions;
    } else {
        restrictionsForCards = activeDisplayableRestrictions.filter(
            (restriction) => {
                if (
                    restrictionIdsForRestrictionGroup.includes(restriction.id)
                ) {
                    return true;
                }
                return false;
            },
        );
    }

    return restrictionsForCards;
};

/**
 *
 * @param {object} settings
 * @param {Amount[]} settings.amountsToDisplay
 * @param {Config} settings.config
 * @param {StandardDonationGiftContentControl} settings.control
 * @param {number} settings.controlId
 * @param {Function} settings.dispatch
 * @param {FormikProps} settings.formikContext
 * @param {boolean} settings.hasSelectedRecurringDonation
 * @param {Restriction[]} settings.restrictionsForCards
 * @param {Function} settings.updateGiftDetails Updates donationDetails.giftDetails in Redux store.
 * @returns {RestrictionCardProps[]}
 */
export const getRestrictionCards = ({
    amountsToDisplay,
    config,
    control,
    controlId,
    dispatch,
    formikContext,
    hasSelectedRecurringDonation,
    restrictionsForCards,
    updateGiftDetails,
}) => {
    const { options } = control;
    const { amountAndSubRestrictionsDisplay = "selected" } = options;

    /**
     *
     * @param {Restriction} restriction
     * @returns {RestrictionCardProps}
     */
    const getRestrictionCardProps = (restriction) => {
        const {
            errors = {},
            setFieldValue = () => {},
            touched = {},
            values = {},
        } = formikContext;
        const { id = 0, image = "" } = restriction;

        // Get the list of amounts that can potentially be displayed and then
        // filter by restriction settings. The filtered list is then passed down
        // so it can be reused everywhere
        const amountsToDisplayWithRestriction =
            getAmountsFromRestrictionSettings(amountsToDisplay, restriction);
        const multiRestrictionFieldName = getMultiRestrictionFieldName(id);
        const existingMultiRestrictionFieldValue =
            values[multiRestrictionFieldName];

        /**
         *
         * @returns {undefined}
         * @description Only handles clicks in the card that are made outside
         * of the label as the other click handler is responsible for clicks
         * within the label. Can be used for selecting restriction but not
         * de-selecting restrictions as if you  want to de-select the
         * restriction you need to click the label.
         */
        const handleRestrictionCardClick = () => {
            const { isCms = false } = config;

            if (!isCms && !existingMultiRestrictionFieldValue.Checked) {
                const nameOfAmountIdField = getNameOfAmountIdField(
                    hasSelectedRecurringDonation,
                );
                const newFieldValue = getNewFieldValueOnClick({
                    amountsToDisplayWithRestriction,
                    existingMultiRestrictionFieldValue,
                    nameOfAmountIdField,
                });

                // Update Formik and then Redux
                setFieldValue(multiRestrictionFieldName, newFieldValue);
                dispatch(
                    updateGiftDetails({
                        [multiRestrictionFieldName]: newFieldValue,
                    }),
                );
            }
        };

        // Logic to generate sub-restriction and display flag
        const selectedRestriction = getRestrictionById(
            restrictionsForCards,
            id,
        );
        const hasDisplayableSubRestriction =
            getHasDisplayableSubRestriction(selectedRestriction);
        const alwaysDisplayAmountsAndSubRestrictions =
            getAlwaysDisplayAmountsAndSubRestrictions(
                amountAndSubRestrictionsDisplay,
            );

        // Only displaying amounts any sub-restrictions if the restriction has
        // been selected or the CMS setting has been set to always
        const showAmountsAndSubRestrictions =
            existingMultiRestrictionFieldValue.Checked ||
            alwaysDisplayAmountsAndSubRestrictions;

        // Flag for error styling in card and nested components as errors
        // should only be displayed if the user attempts to go to the next step
        const hasError = !!errors[multiRestrictionFieldName];
        const hasTouched = !!touched[multiRestrictionFieldName];
        const showError = hasError && hasTouched;

        // Reassign and create variables as needed
        const key = `restriction_card_${id}`;
        const restrictionId = id;
        const restrictionCardAmountsProps = {
            amountsToDisplayWithRestriction,
            controlId,
            restrictionId,
        };
        const restrictionCardErrorProps = {
            restrictionId,
        };
        const restrictionCardImageProps = {
            restrictionId,
        };
        const restrictionCardLabelProps = {
            amountsToDisplayWithRestriction,
            restrictionId,
            showError,
        };
        const restrictionCardSubRestrictionProps = {
            restrictionId,
        };
        const selected = existingMultiRestrictionFieldValue.Checked;
        const showAmounts = showAmountsAndSubRestrictions;
        const showImage = !!image;
        const showSubRestrictions =
            showAmountsAndSubRestrictions && hasDisplayableSubRestriction;

        const restrictionCardProps = {
            handleRestrictionCardClick,
            key,
            restrictionId,
            restrictionCardAmountsProps,
            restrictionCardErrorProps,
            restrictionCardImageProps,
            restrictionCardLabelProps,
            restrictionCardSubRestrictionProps,
            selected,
            showAmounts,
            showError,
            showImage,
            showSubRestrictions,
        };

        return restrictionCardProps;
    };

    let restrictionCards = [];

    if (Array.isArray(restrictionsForCards)) {
        restrictionCards = restrictionsForCards.map(getRestrictionCardProps);
    }

    return restrictionCards;
};
