import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { isDisplayable, constants } from "@qgiv/core-js";
import { QFieldInput } from "../QField";
import { isVisible, getFieldIsVisibleBasedOnConditionals } from "../../utility";

const WidgetField = (props) => {
    const {
        control: {
            options: { id, display_text },
        },
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        settings: {
            activePromiseTransaction,
            allFieldsAndGroups,
            allowCompanyContributions,
            config: { currentDisplay },
            donationAmountFromRedux = 0,
            useNewConditionalLogicFunctions = false,
            conditionalLogicOptions = {},
        },
        touched,
        values,
    } = props;
    const {
        ENUMS: { FieldType, FieldContext },
        cms,
    } = constants;
    const field = allFieldsAndGroups.find((field) => field.id === id);
    const fieldWithUpdatedDisplayText =
        display_text && display_text.length > 0
            ? { ...field, display_text }
            : field;
    const qFieldProps = {
        errors,
        field: fieldWithUpdatedDisplayText,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        values,
        donationAmountFromRedux,
    };
    const { type } = field;
    // Grab conditions safely if data from BE is missing.
    const conditions = field?.conditions?.[0] || {};

    const displayable = isDisplayable(field, currentDisplay);
    let visible = true;
    // If conditional logic has been enabled
    if (Object.keys(conditions).length > 0) {
        // The One Form has updated conditional logic
        visible = useNewConditionalLogicFunctions
            ? getFieldIsVisibleBasedOnConditionals({
                  condition: conditions,
                  allFieldsAndGroups,
                  // will include donation amount, type
                  options: conditionalLogicOptions,
                  values,
              })
            : isVisible(conditions, values, allFieldsAndGroups);
    }
    // Do not display anything for a hidden field
    if (Number(type) === FieldType.HIDDEN_FIELD) {
        visible = false;
    }
    // Do not display anything for custom fields on fulfillment forms
    if (
        activePromiseTransaction &&
        Number(field.contextId) === FieldContext.CUSTOM_FIELD
    ) {
        visible = false;
    }
    // Do not display Employer field if Company field is being displayed
    if (
        allowCompanyContributions &&
        values.Company_Donation &&
        Number(type) === FieldType.EMPLOYER
    ) {
        visible = false;
    }
    return cms || (displayable && visible) ? (
        <div
            className={cx(
                "qg-vendor-widget-field",
                "widget-field",
                "grid",
                !(displayable && visible) && "-disabled",
            )}
        >
            <div className="col col--12">
                <QFieldInput {...qFieldProps} />
            </div>
        </div>
    ) : null;
};

WidgetField.propTypes = {
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    control: PropTypes.shape({
        options: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            display_text: PropTypes.string,
        }),
    }),
    setFieldValue: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        activePromiseTransaction: PropTypes.bool.isRequired,
        allFieldsAndGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
        allowCompanyContributions: PropTypes.bool.isRequired,
        config: PropTypes.shape({
            currentDisplay: PropTypes.number.isRequired,
        }),
        donationAmountFromRedux: PropTypes.number,
    }).isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
};

export default WidgetField;
