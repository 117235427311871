/**
 * @public
 * @function getPayPalVenmoInitialValues
 * @param {boolean} enablePaypalPayments enablePaypalPayments value from formSettings.paymentData
 * @returns {object} initial values for PayPal and Venmo in Formik
 */
const getPayPalVenmoInitialValues = (enablePaypalPayments) => {
    if (enablePaypalPayments) {
        return {
            PayPal_Token: "",
            PayPal_Payer_ID: "",
            PayPal_Agreement_ID: "",
            PayPal_Is_Venmo: false,
        };
    }
    return {};
};

export default getPayPalVenmoInitialValues;
