import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncSessionStorage } from "@qgiv/core-donor";
import { constants } from "@qgiv/core-js";
import UpdateFormDataFromSessionStorage from "./UpdateFormDataFromSessionStorage";
import { selectAllFormSettings } from "../redux/slices/formSettingsSlice";
import { setDonationDetailsFromSessionStorage } from "../redux/slices/donationDetailsSlice";
import {
    selectIsSinglePageForm,
    setCurrentPage,
} from "../redux/slices/appSettingsSlice";

const ConnectedUpdateFormDataFromSessionStorage = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DETAILS, DONATION_AMOUNTS },
        },
    } = constants;
    const dispatch = useDispatch();

    const { id, template } = useSelector(selectAllFormSettings);
    const isSinglePageForm = useSelector(selectIsSinglePageForm);

    const replaceDonationDetailsState = (
        sessionStorageKey,
        sessionStorageData,
    ) => {
        // Set Redux values from Session Storage
        dispatch(
            setDonationDetailsFromSessionStorage(
                sessionStorageData.donationDetails,
            ),
        );

        // This is a quick redirect away from the gift page in order to allow savedValues to
        // catch the sessionStorage values. I might hate this solution though.
        if (!isSinglePageForm) {
            if (sessionStorageData.currentPage === DONATION_AMOUNTS) {
                dispatch(setCurrentPage(DETAILS));
            }
            // Set the Last current page from Session Storage

            dispatch(setCurrentPage(sessionStorageData.currentPage));
        }

        // remove sessionStorage relating to this transaction
        asyncSessionStorage.remove(sessionStorageKey);
    };

    return (
        <UpdateFormDataFromSessionStorage
            id={id}
            replaceDonationDetailsState={replaceDonationDetailsState}
            template={template}
            isSinglePageForm={isSinglePageForm}
        />
    );
};

export default ConnectedUpdateFormDataFromSessionStorage;
