import { translateImageSize } from "@qgiv/core-js";

/**
 * @typedef {import("@qgiv/core-donor").ComponentTypes.CardImageProps} CardImageProps
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FormSettings.FormSettings} FormSettings
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.Restriction} Restriction
 */

/**
 *
 * @param {object} settings
 * @param {FormSettings} settings.formSettings
 * @param {Restriction} settings.restriction
 * @returns {CardImageProps}
 */
export const getCardImageProps = ({ formSettings, restriction }) => {
    const { imageSize = "" } = formSettings;
    const { image = "", name = "" } = restriction;

    // Reassign and create variables as needed
    const altText = `Image for ${name}`;
    const display = translateImageSize(imageSize);
    const url = image;

    return {
        altText,
        display,
        url,
    };
};
