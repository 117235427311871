import React from "react";
import { useSelector } from "react-redux";
import { BankFields } from "@qgiv/core-donor";
import {
    selectHasBillingNameField,
    selectBankFieldsData,
} from "../../../../redux/slices/fieldsSlice";

const ConnectedBankFields = () => {
    const bankFieldData = useSelector(selectBankFieldsData);
    const {
        accountNumberLabel = "Account Number",
        routingNumberLabel = "Routing Number",
    } = bankFieldData;

    const { exists = false, display_text_echeck = "Name on account" } =
        useSelector(selectHasBillingNameField);

    const showBillingNameField = exists;
    const billingNameLabel = display_text_echeck;

    const bankFieldProps = {
        accountNumberLabel,
        routingNumberLabel,
        showBillingNameField,
        billingNameLabel,
    };
    return <BankFields {...bankFieldProps} />;
};

export default ConnectedBankFields;
