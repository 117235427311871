import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getFormValuesFromDonorAccount,
    getMyAccountLinkUrl,
    onDonorAccountChangeSetFormik,
} from "@qgiv/donation-form";
import { accountLogout, getPaymentTypeInitialValue } from "@qgiv/core-donor";
import { useFormikContext } from "formik";
import DonorIsLoggedIn from "./DonorIsLoggedIn";
import {
    selectDonorAccount,
    setDonorAccount,
} from "../../../redux/slices/donorAccountSlice";
import {
    selectAllFormSettings,
    selectPaymentData,
} from "../../../redux/slices/formSettingsSlice";
import { selectConfig } from "../../../redux/slices/configSettingsSlice";
import {
    selectAllSystemFields,
    selectDefaultCountry,
    selectHasBillingNameField,
} from "../../../redux/slices/fieldsSlice";

const ConnectedDonorIsLoggedIn = () => {
    const dispatch = useDispatch();
    const donorAccount = useSelector(selectDonorAccount);
    const { org, id } = useSelector(selectAllFormSettings);
    const { embed = {} } = useSelector(selectConfig);
    const { values = {}, setValues = () => {} } = useFormikContext() ?? {};
    const systemFields = useSelector(selectAllSystemFields);
    const defaultCountry = useSelector(selectDefaultCountry);
    const billingNameDisplayedData = useSelector(selectHasBillingNameField);
    const isBillingNameDisplayed = billingNameDisplayedData?.exists;
    const { enableAchPayments, enableCCPayments, enablePaypalPayments } =
        useSelector(selectPaymentData);

    const getSignOutLinkClickHandler = () => {
        accountLogout().then(() => {
            const donorAccountToUnset = {};
            dispatch(setDonorAccount(donorAccountToUnset));
            const formValuesFromDonorAccount = getFormValuesFromDonorAccount({
                donorAccount: donorAccountToUnset,
                systemFields,
                countryInitial: defaultCountry,
                isBillingNameDisplayed,
            });
            const paymentTypeAfterSignOut = getPaymentTypeInitialValue(
                {
                    enableAchPayments,
                    enableCCPayments,
                    enablePaypalPayments,
                },
                donorAccountToUnset,
            );

            if (values) {
                onDonorAccountChangeSetFormik(
                    formValuesFromDonorAccount,
                    setValues,
                    values,
                    paymentTypeAfterSignOut,
                );
            }
        });
    };
    // -------------------------------------------------------------------------
    // NOTE: User is currently Logged in so we will be displaying the Sign out
    // and My Account Links.
    // -------------------------------------------------------------------------
    const donorIsLoggedInProps = {
        donorActionLinkLabel: "Sign Out",
        handleLinkClick: getSignOutLinkClickHandler,
    };

    const myAccountLinkUrl = getMyAccountLinkUrl({
        donorAccount,
        orgId: org,
        formId: id,
        embed,
    });

    const iconData = {
        glyph: "user-circle-regular",
        type: "FontAwesome",
        size: "regular",
    };

    const myAccountProps = {
        linkUrl: myAccountLinkUrl,
        donorActionLinkLabel: "My Account",
        iconData,
        isLink: true,
    };

    const loggedOutProps = {
        donorIsLoggedInProps,
        myAccountProps,
    };

    return <DonorIsLoggedIn {...loggedOutProps} />;
};

export default ConnectedDonorIsLoggedIn;
