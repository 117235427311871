import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import QFieldInput from "../QFieldInput";

const SystemFieldGroupMailingAddress = (props) => {
    const { ENUMS } = constants;
    const { FieldType } = ENUMS;
    const {
        field: { fields },
    } = props;
    return (
        <div className="grid">
            {fields.map((field, i) => {
                switch (parseInt(field.type)) {
                    case FieldType.ADDRESS:
                        return (
                            <div key={i} className="col col--12">
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    case FieldType.ADDRESS_2:
                        return (
                            <div key={i} className="col col--12">
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    case FieldType.CITY:
                        return (
                            <div key={i} className="col col--12 col--sm-4">
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    case FieldType.STATE:
                        return (
                            <div key={i} className="col col--12 col--sm-4">
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    case FieldType.ZIP:
                        return (
                            <div key={i} className="col col--12 col--sm-4">
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    case FieldType.COUNTRY:
                        return (
                            <div key={i} className="col col--12">
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
};

SystemFieldGroupMailingAddress.propTypes = {
    field: PropTypes.shape({
        fields: PropTypes.array,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
        Address: PropTypes.string,
        Address_2: PropTypes.string,
        City: PropTypes.string,
        State: PropTypes.string,
        Zip: PropTypes.string,
        Country: PropTypes.string,
    }).isRequired,
};

export default SystemFieldGroupMailingAddress;
