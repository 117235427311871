import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import "./DonationFormProgressBar.scss";

const DonationFormProgressBar = ({
    // All steps that should be displayed for DonationForm.  Get names from the page containers
    allDisplayedPageSteps = [],
    // This value should be the currentPage stored in AppSettings slice
    currentStep = 1,
    isCms = false,
    // Array of Page names for the icon labels
    labels = [],
    handleStepClick = () => {},
    renderIconProps = () => ({}),
}) => {
    const getLabel = (i) => labels[i];

    const getStep = (stepIdentifier, i) => {
        const stepIndex = i;
        const isComplete = currentStep > stepIdentifier;
        const isActive = currentStep === stepIdentifier;
        const isFilled = isActive || isComplete;

        // Pass step that is clicked to the handleStepClick
        // the clicked step will become the currentPage in redux
        // allowing the page to change accurately
        const handleClick = () => {
            handleStepClick(stepIdentifier);
        };
        return (
            <button
                type="button"
                key={stepIndex}
                onClick={isComplete ? handleClick : undefined}
                className="donation-form-progress-bar__step ignore-auto-focus"
                aria-label={getLabel(i)}
                disabled={(!isComplete || isCms) && true}
            >
                {stepIndex > 0 && (
                    <div
                        className={cx(
                            "donation-form-progress-bar__line",
                            isFilled &&
                                "donation-form-progress-bar__line--filled",
                        )}
                    />
                )}
                <div
                    className={cx(
                        "donation-form-progress-bar__indicator",
                        isFilled &&
                            "donation-form-progress-bar__indicator--filled",
                    )}
                >
                    <div className="donation-form-progress-bar__icon">
                        <Icon
                            classNames={[
                                "donation-form-progress-bar__svg",
                                "donation-form-progress-bar__svg--circle",
                                `${
                                    isActive
                                        ? "donation-form-progress-bar__svg--visible"
                                        : ""
                                }`,
                            ]}
                            label={getLabel(i)}
                            glyph="circle"
                            type="Custom"
                            {...renderIconProps(stepIndex)}
                        />
                        <Icon
                            classNames={[
                                "donation-form-progress-bar__svg",
                                `${
                                    isComplete
                                        ? "donation-form-progress-bar__svg--visible"
                                        : ""
                                }`,
                            ]}
                            label={getLabel(i)}
                            glyph="check-solid"
                            type="FontAwesome"
                            {...renderIconProps(stepIndex)}
                        />
                    </div>
                </div>
            </button>
        );
    };
    return (
        <div className="qg-vendor-progress-bar donation-form-progress-bar">
            {allDisplayedPageSteps.map((stepIdentifier, i) =>
                getStep(stepIdentifier, i),
            )}
        </div>
    );
};

DonationFormProgressBar.propTypes = {
    allDisplayedPageSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
    isCms: PropTypes.bool.isRequired,
    currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleStepClick: PropTypes.func,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderIconProps: PropTypes.func,
};

export default DonationFormProgressBar;
