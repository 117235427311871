import { constants, breakpoints } from "@qgiv/core-js";
import { debounce } from "lodash";
import store from "../../redux";

const debounceDelay = 100;

/**
 * @private
 * @function sendPostMessage
 * @param {string} event Type of event to send to parent window
 * @param {object|number} data The data expected by embed.js for the event type
 * @description Used by embedded form to "talk" to parent window
 *              Received and handled by embed.js
 */
export const sendPostMessage = (event, data) => {
    const state = store.getState();
    const {
        config: { embed },
    } = state;
    if (embed && embed?.id && embed?.url) {
        window.parent.postMessage(
            JSON.stringify({ event, data, embed: embed.id }),
            embed.url ? embed.url : "*",
        );
        return;
    }

    window.parent.postMessage(JSON.stringify({ event, data }));
};

// -------------------------------------------------------------------------
// NOTE: Modal Specific Functions
// -------------------------------------------------------------------------
/**
 * @private
 * @function sendModalResizeMessage
 * @param {number} height Optional height to force the resize to apply to
 * @param {number} lastOffset Optional previously computed height
 * @description Trigger resize event, updating the height of the form iframe
 */
export const sendModalResizeMessage = (height = null, lastOffset = null) => {
    const windowWidth = window.innerWidth;
    const { sm } = breakpoints;
    const smallBreakPointValue = Number(sm.replace("px", ""));
    const extraHeightDesktop = windowWidth > smallBreakPointValue ? 180 : 0;
    let messageHeight;

    if (height) {
        messageHeight = height;
    } else {
        // height is not passed when observer resizes due to loading media.
        // for event modal, need to grab tallest container of the modal
        // which varies from absolute positioned elements
        const modalContainer = document.querySelector(".modal2");
        const contentContainer = document.querySelector(".modal2__content");
        const contentHeight = contentContainer?.getBoundingClientRect()?.height;
        const modalHeight = modalContainer?.getBoundingClientRect()?.height;
        messageHeight =
            modalHeight > contentHeight ? modalHeight : contentHeight;
    }

    if (messageHeight) {
        sendPostMessage(
            "resizeFullScreenModal",
            messageHeight + lastOffset + extraHeightDesktop,
        );
    }
};

// -------------------------------------------------------------------------
// NOTE: Embed Specific Functions
// -------------------------------------------------------------------------
/**
 * @private
 * @function sendEmbedResizeMessage
 * @param {number} height Optional height to force the resize to apply to
 * @param {number} lastOffset Optional previously computed height
 * @description Trigger resize event, updating the height of the form iframe
 */
export const sendEmbedResizeMessage = (height = null, lastOffset = null) => {
    // add buffer to element height so that the bottom border is not cut off
    const heightBuffer = 16;
    sendPostMessage(
        "resize",
        height
            ? height + heightBuffer
            : document.getElementById("app-container").getBoundingClientRect()
                  .height +
                  lastOffset +
                  heightBuffer,
    );
};

/**
 * @private
 * @function scrollTop
 * @description Scrolls the app and the parent window to top of the iframe where
 *              the form is mounted. Post message used instead of calling the
 *              scroll method from inside of the iframe does not scroll the webpage
 *              on a range of supported browsers. The list of browsers that were
 *              not scrolling as expected includes Safari on desktop or Safari
 *              Chrome, Firefox or Edge on mobile.
 */
export const scrollTop = () => {
    const {
        ENUMS: { FormTemplate },
    } = constants;
    sendPostMessage("top", {
        formTemplate: FormTemplate.NEW_STANDARD_FORM,
    });
};

// -------------------------------------------------------------------------
// NOTE: Shared Functions
// -------------------------------------------------------------------------

/**
 * @function getResizeMessageToSend
 * @param {number} height - The height to be included in the resize message.
 * @param {number} lastOffset - The last offset to be included in the resize message.
 * @returns {Function} The function to be used for resize based on isDisplayTypeModal.
 */
export const getResizeMessageToSend = (height, lastOffset) => {
    const state = store.getState();
    const {
        config: { isDisplayTypeModal },
    } = state;

    if (isDisplayTypeModal) {
        return sendModalResizeMessage(height, lastOffset);
    }
    return sendEmbedResizeMessage(height, lastOffset);
};

/**
 * @private
 * @function debounceSendResizeMessage
 * @description Debounced version of the resize message func
 *              Use this to avoid calling the resize message too many times
 *              in a row. (e.g., when many events fire off the resize message,
 *              only the last resize will actually get called.)
 */
export const debounceSendResizeMessage = debounce(
    getResizeMessageToSend,
    debounceDelay,
);

/**
 * @private
 * @function sendEmbedLoginMessage
 * @param {string} formId The ID of the form to log in to
 * @description Trigger login event, opening the sign in modal. Clicking submit button
 * opens communication the legacy js. Here we are hitting a different endpoint to complete
 * the logging in (/api/v1/donor/login/)
 */
export const sendEmbedLoginMessage = (formId) => {
    const state = store.getState();
    const {
        config: { HTTP_HOST },
    } = state;
    let signInPath = `${HTTP_HOST}/account/login/embed/`;
    if (formId) {
        signInPath += `?formId=${formId}`;
    }
    sendPostMessage("login", { path: signInPath });
};

/**
 * @private
 * @function sendEmbedDataLayerMessage
 * @param {object} data Payload that is being sent to the parent webpage.
 * @description Function that emits a dataLayer event that is received by
 *              a handlers in embed.development.js and used to update the data
 *              layer of the parent webpage.
 */
export const sendEmbedDataLayerMessage = (data = {}) => {
    sendPostMessage("createDataLayerEvent", data);
};
