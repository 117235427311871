import React from "react";
import { Divider } from "@qgiv/core-donor";
import ConnectedRestrictionCards from "../RestrictionCards";

/**
 * @typedef {import("../types/restrictionGroupsTypes").RestrictionGroupProps} RestrictionGroupProps
 */

/**
 *
 * @param {RestrictionGroupProps} props
 * @returns {React.ReactElement}
 */
const RestrictionGroup = ({
    controlId,
    description,
    name,
    restrictionCardsProps,
    showDescription,
    showDivider,
    showGroupDetails,
}) => (
    <>
        {showGroupDetails && (
            <div className="-padding-bottom--15">
                <h2>{name}</h2>
                {showDescription && <p>{description}</p>}
            </div>
        )}
        <ConnectedRestrictionCards
            controlId={controlId}
            {...restrictionCardsProps}
        />
        {showDivider && (
            <div className="-padding-bottom--15">
                <Divider />
            </div>
        )}
    </>
);

export default RestrictionGroup;
