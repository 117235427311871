import { debounce } from "lodash";

// VENDOR_EVENTS is meant to encompass all vendor events we'll be creating from now on

// *** vendor events: naming convention ***
// QGIV-output-<eventName> => events fired inside the app on which vendors can react to writing their own logic
// QGIV-input-<eventName> => events listened by the application. Vendor fires them and the app should react somehow
const VENDOR_EVENTS = {
    amountChange: "qg-output-amountChange",
    domChange: "qg-output-domChange",
    formStepChange: "qg-output-formStepChange",
    toggleUpgradeModal: "qg-input-toggleUpgradeModal",
};

/**
 * @public
 * @function triggerVendorEvent
 * @param {string} vendorEvent String representing the type of event to be triggered
 * @param {object|null} data Payload data attached to the event
 * @description Fires an event to the document so listeners can react to
 */
function triggerVendorEvent(vendorEvent, data) {
    const event = new CustomEvent(vendorEvent, { detail: data });
    document.dispatchEvent(event);
}

/**
 * @private
 * @function sendAmountChangeEvent
 * @param {object} values Object containing data related to the currently selected amount
 * @param {string} values.Total Donation total amount
 * @param {boolean} values.Recurring_Donation is it a recurring donation ?
 * @param {boolean} values.Company_Donation is it a company donation ?
 * @description Used by embedded form to "talk" to parent window.
 *              Fires qg-output-amountChange event so the outer document can listen
 */
const sendAmountChangeEvent = (values) => {
    const {
        Total = 0,
        Recurring_Donation = false,
        Company_Donation = false,
    } = values;

    triggerVendorEvent(VENDOR_EVENTS.amountChange, {
        Total: Number(Total),
        Recurring_Donation,
        Company_Donation,
    });
};

/**
 * @private
 * @function sendDomChangeEvent
 * @param {object} changesInfo Object containing information about changes in DOM nodes and the total height of the app
 * @param {MutationRecord[]} changesInfo.mutationsList List of nodes that have been changed
 * @param {number} changesInfo.appHeight Embedded form total height
 * @description Used by embedded form to "talk" to parent window.
 *              Fires qg-output-domChange event so the outer document can listen
 */
const sendDomChangeEvent = (changesInfo) => {
    const { mutationsList, appHeight } = changesInfo;
    triggerVendorEvent(VENDOR_EVENTS.domChange, { mutationsList, appHeight });
};
/**
 * @private
 * @function debounceDomChangedEvent
 * @description Debounced version of sendDomChangeEvent
 *              Use this to avoid firing the event too many times
 */
const debounceDomChangeEvent = debounce(sendDomChangeEvent, 300);

/**
 * @private
 * @function sendStepChangeEvent
 * @param {number} currentStep current form step
 * @description Used by embedded form to "talk" to parent window.
 *              Fires qg-output-formStepChange event so the outer document can listen
 */
const sendStepChangeEvent = (currentStep) => {
    const step = Number(currentStep);
    triggerVendorEvent(VENDOR_EVENTS.formStepChange, { step });
};

export {
    VENDOR_EVENTS,
    sendDomChangeEvent,
    sendAmountChangeEvent,
    sendStepChangeEvent,
    triggerVendorEvent,
    debounceDomChangeEvent,
};
