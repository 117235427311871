import React, { useState } from "react";
import cx from "classnames";
import { LoadingDots } from "@qgiv/core-react";
import "./WidgetThermometer.scss";

/**
 * @param {object} props component props
 * @param {object} props.isThermometerWidgetDisplayable evaluation on whether this Thermometer is displayable
 * @param {Function} props.debounceSendResizeMessage function to send resize message to window
 * @param {Function} props.ResizeObserver function to watch for resize
 * @param {string} props.widgetUrl url for Thermometer specific to product type
 * @returns {React.ReactElement} WidgetThermometer
 */
const WidgetThermometer = ({
    debounceSendResizeMessage,
    isThermometerWidgetDisplayable,
    ResizeObserver,
    widgetUrl,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [padding, setPadding] = useState(0);

    // Exit early if not visible or widgetUrl is empty string
    if (!isThermometerWidgetDisplayable) return null;

    const thermometerClasses = cx(
        "qg-vendor-widget-thermometer",
        "widget-thermometer",
        !loaded && "widget-thermometer--loading",
    );

    const handleResize = (entries) => {
        const rect = entries[0]?.contentRect;
        setPadding((rect.height / rect.width) * 100);
        debounceSendResizeMessage();
    };
    const observer = new ResizeObserver(handleResize);

    const handleLoad = (loadEvent) => {
        const iframeElement = loadEvent.target.contentDocument.documentElement;
        observer.observe(iframeElement);
        setLoaded(true);
    };

    return (
        <div className="grid">
            <div className="col">
                <div className={thermometerClasses}>
                    <div
                        className="widget-thermometer__iframe"
                        style={{
                            paddingTop: `calc(${padding}% - 30px)`,
                        }}
                    >
                        <iframe
                            className="qgiv-embed"
                            id="qgiv-embed-116472"
                            title="qgiv-embed-116472"
                            src={widgetUrl}
                            onLoad={handleLoad}
                            scrolling="no"
                        />
                        {!loaded && <LoadingDots center />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WidgetThermometer;
