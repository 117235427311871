import {
    Yup,
    getCustomFieldBaseAndRequiredValidation,
} from "@qgiv/core-validation";
import { getFieldIsVisibleBasedOnConditionals } from "./conditionalCustomFieldsVisibility";

export const getRequiredConditionalCustomFieldSchema = ({
    allFieldsAndGroups,
    baseValidation,
    condition,
    options,
    requiredValidation,
    savedValues,
}) => {
    const validation = Yup.lazy((_values, { context }) => {
        const currentValues = context;
        const valuesToUse = {
            ...savedValues,
            ...currentValues,
        };
        const fieldIsVisibleBasedOnConditionals =
            getFieldIsVisibleBasedOnConditionals({
                allFieldsAndGroups,
                condition,
                options,
                values: valuesToUse,
            });

        return fieldIsVisibleBasedOnConditionals
            ? requiredValidation
            : baseValidation;
    });
    return validation;
};

export const getFieldValidationSchema = ({
    allFieldsAndGroups,
    currentField,
    options,
    savedValues,
    total,
}) => {
    const { required, amtReq } = currentField;

    const { baseValidation, requiredValidation } =
        getCustomFieldBaseAndRequiredValidation(currentField);

    const isRequired =
        (required && amtReq === 0) || (required && total > amtReq);

    const { conditions = [] } = currentField;
    const condition = conditions?.[0] || {};
    const isConditionalField = Object.keys(condition).length > 0;

    if (isRequired && isConditionalField) {
        return getRequiredConditionalCustomFieldSchema({
            allFieldsAndGroups,
            baseValidation,
            condition,
            options,
            requiredValidation,
            savedValues,
        });
    }

    return isRequired ? requiredValidation : baseValidation;
};

export const getAllCustomFieldValidation = ({
    allFieldsAndGroups,
    allCustomFields,
    options,
    savedValues,
    total,
}) => {
    const validationObject = allCustomFields.reduce(
        (totalValidationObject, currentField) => ({
            ...totalValidationObject,
            [currentField.id]: getFieldValidationSchema({
                allFieldsAndGroups,
                currentField,
                options,
                savedValues,
                total,
            }),
        }),
        {},
    );
    return validationObject;
};
