import { qfetch } from "@qgiv/core-js";

/**
 * @public
 * @function getSubmitConfirmationFieldsUrl
 * @description helper function to create the submit api url for confirmation fields
 * @param {string} formId id of the form being submitted
 * @returns {string} submitConfirmationFields api url
 */
const getSubmitConfirmationFieldsUrl = (formId) =>
    `/api/v1/${formId}/submit_confirmation_fields`;

/**
 * @public
 * @function getSubmitUrl
 * @description helper function to create the submit api url for events
 * @returns {string} submit api url
 */
export const getSubmitUrl = () => "/api/v1/submit";

/**
 * @public
 * @function submitDonationAPI
 * @description API call for submitting a donation
 * @param {object} data return of prepareValuesForEventSubmit
 * @returns {Promise} axios promise by way of qfetch
 */
export const submitDonationAPI = (data = {}) =>
    qfetch.post(getSubmitUrl(), data);

/*
    $this->searchCategory = $request['searchCategory'];
    $this->formId = (int)$request['formId'];
    $this->searchTerm = $request['searchTerm'] ?? '';
    $this->globalSearch = $request['globalSearch'] ?? 0;
    $this->source = $request['source'] ?? '';
    $this->parentOnly = $request['parentOnly'] ?? 0;
    $this->recentParticipants = $request['recentParticipants'] ?? 0;
*/
export const getRecipientList = (params = {}) =>
    qfetch.get("/api/v1/donationform/donationrecipient/search", { params });

export const submitConfirmationFields = (formId, data) =>
    qfetch.post(getSubmitConfirmationFieldsUrl(formId), data);
