import { constants } from "@qgiv/core-js";

const { ENUMS } = constants;

/**
 *
 * @param {object} control CMS Control for STANDARD_DONATION_GIFT_CONTENT content.
 * @returns {"alternate"|"standard"} If standard or alternate button style should be used.
 */
export const getButtonStyleFromDonatedGiftControl = (control) => {
    const { options = {} } = control;
    const {
        donationAmountButtonStyle = ENUMS.StandardFormAmountsButtonStyle
            .STANDARD,
    } = options;

    const isButtonStyleAlternate =
        donationAmountButtonStyle ===
        ENUMS.StandardFormAmountsButtonStyle.ALTERNATE;
    const buttonStyle = isButtonStyleAlternate ? "alternate" : "standard";

    return buttonStyle;
};
