import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    selectPromiseTransaction,
    selectHasCompletedFulfillment,
    selectHasActiveFulfillment,
} from "../../../../../redux/slices/promiseTransactionSlice";
import { updateGiftDetails } from "../../../../../redux/slices/donationDetailsSlice";
import DonatedGiftInvoiceTabs from "./DonatedGiftInvoiceTabs";
import { selectConfig } from "../../../../../redux/slices/configSettingsSlice";

/**
 * @function ConnectedDonatedGiftInvoiceTabs
 * @param {object} props - Component properties
 * @param {number} props.controlId - The control ID
 * @returns {React.ReactElement} The DonatedGiftInvoiceTabs component.
 */
const ConnectedDonatedGiftInvoiceTabs = ({ controlId }) => {
    const { InvoiceFulfillmentType } = constants.ENUMS;
    const dispatch = useDispatch();
    const { handleChange, values, setFieldValue, setValues } =
        useFormikContext();

    const promiseTransaction = useSelector(selectPromiseTransaction);
    const { receiptId } = promiseTransaction;

    const config = useSelector(selectConfig);
    const { HTTP_HOST, path } = config;

    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    const [displaySendingToReceipt, setDisplaySendingToReceipt] =
        useState(false);

    const updateInvoiceCompleteFulfillment = (value) => {
        let newDonationAmount;
        switch (Number(value)) {
            case InvoiceFulfillmentType.ONETIME:
                newDonationAmount = promiseTransaction.amount;
                break;
            case InvoiceFulfillmentType.OUTSTANDING:
                newDonationAmount = Number(
                    promiseTransaction.invoiceOutstandingAmount,
                ).toFixed(2);
                break;
            case InvoiceFulfillmentType.FULL:
                newDonationAmount = Number(
                    promiseTransaction.invoiceAmountRemaining,
                ).toFixed(2);
                break;
            default:
                break;
        }

        const formikUpdateValues = {
            Invoice_Amount_Paid: newDonationAmount,
            Invoice_Complete_Fulfillment:
                Number(value) === InvoiceFulfillmentType.FULL,
            Invoice_Fulfill_Outstanding:
                Number(value) === InvoiceFulfillmentType.OUTSTANDING,
        };

        setValues({
            ...values,
            ...formikUpdateValues,
        });
        dispatch(updateGiftDetails({ ...formikUpdateValues }));
    };

    const hasRecurringInvoice = promiseTransaction.recurring !== "0";
    const hasOutstandingInvoices =
        hasRecurringInvoice && promiseTransaction.invoiceOutstandingCount >= 2;
    const hasFullInvoiceBalance =
        hasRecurringInvoice &&
        promiseTransaction.finalBill !== "0000-00-00" &&
        promiseTransaction.amtPledged !== "0";

    const handleSubtotalChange = (e) => {
        const { value } = e.target;
        setFieldValue("subtotal", promiseTransaction.amount);
        setFieldValue("total", promiseTransaction.amount);
        handleChange(e);
        updateInvoiceCompleteFulfillment(value);
    };

    // returns false when it is not an active fulfillment form
    const hasCompletedFulfillment = useSelector(selectHasCompletedFulfillment);
    const receiptUrl = hasActiveFulfillment
        ? `${HTTP_HOST}/for/${path}/receipt/${receiptId}/success`
        : "";

    // Send to receipt if this promise has already been fulfilled
    useEffect(() => {
        if (receiptId && hasCompletedFulfillment) {
            setDisplaySendingToReceipt(true);
            window.location.href = `${receiptUrl}?invoice`;
        }
    }, [hasCompletedFulfillment, receiptId, receiptUrl]);

    return (
        <DonatedGiftInvoiceTabs
            controlId={controlId}
            handleSubtotalChange={handleSubtotalChange}
            hasOutstandingInvoices={hasOutstandingInvoices}
            hasFullInvoiceBalance={hasFullInvoiceBalance}
            displaySendingToReceipt={displaySendingToReceipt}
        />
    );
};

ConnectedDonatedGiftInvoiceTabs.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonatedGiftInvoiceTabs;
