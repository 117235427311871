import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { setCurrentPagePreviousPage } from "../../../../redux/slices/appSettingsSlice";
import { selectCmsControlById } from "../../../../redux/slices/cmsSettingsSlice";
import { selectIsDisplayTypeModal } from "../../../../redux/slices/configSettingsSlice";
import Submit from "../../../common/Submit";

const ConnectedSubmit = ({ controlId }) => {
    const { isSubmitting } = useFormikContext();
    const isDisplayTypeModal = useSelector(selectIsDisplayTypeModal);
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    const {
        options: { continueButtonLabel = "Continue" },
    } = control;

    const dispatch = useDispatch();
    const handleBackButtonClick = () => {
        dispatch(setCurrentPagePreviousPage());
    };

    const submitProps = {
        backButtonLabel: "Back",
        displayBackButton: true,
        handleBackButtonClick,
        isSubmitting,
        nextButtonLabel: continueButtonLabel,
        total: 0,
        isPreview: false,
        disabled: false,
        submitTooltipMessage: "",
        showBackground: !!isDisplayTypeModal,
    };

    return <Submit {...submitProps} />;
};

ConnectedSubmit.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedSubmit;
