import React from "react";

/**
 * @param {object} props component props
 * @param {string} props.formattedRecurringUpgradeHeading text from cms control option combined with First_Name value
 * @returns {React.ReactElement} RecurringUpgradeHeading
 */
const RecurringUpgradeHeading = ({ formattedRecurringUpgradeHeading }) => (
    <h2 className="-text--center -margin-top--15">
        {formattedRecurringUpgradeHeading}
    </h2>
);

export default RecurringUpgradeHeading;
