import React from "react";
import { useFormikContext } from "formik";
import { Captcha } from "@qgiv/core-donor";

const ConnectedCaptcha = () => {
    const { errors, values, touched, setValues } = useFormikContext();
    const useErrorBorder = !!(
        touched.G_Recaptcha_Response && errors.G_Recaptcha_Response
    );
    const captchaProps = { errors, values, touched, setValues, useErrorBorder };
    return <Captcha {...captchaProps} />;
};

export default ConnectedCaptcha;
