/**
 * @function formikOptionConverter
 * @description converts tags inside CMS options to reflect formik values
 * @param {object} options - cms options for a given control
 * @param {object} values - form values
 * @returns {object} identical to options except all tags have been replaced
 */
const formikOptionConverter = (options, values = {}) => {
    // Return options if there are not any formik values
    // to replace these tags with
    if (Object.keys(values).length === 0) {
        return options;
    }
    const tagConversions = {
        "%TOTAL%": values.Total,
        "%AMOUNT%": values.Total,
        "%FIRSTNAME%": values.First_Name,
        "%LASTNAME%": values.Last_Name,
        "%NAME%": values.Company_Donation ? values.Company : values.First_Name,
    };
    const formatSpecialCases = (option) => {
        const shouldFormat = option.includes(", %NAME%");
        if (
            (shouldFormat &&
                values.Company_Donation &&
                "Company" in values &&
                values.Company.length === 0) ||
            ("First_Name" in values &&
                !values.Company_Donation &&
                values.First_Name.length === 0)
        ) {
            return option.replace(", %NAME%", "");
        }
        return option;
    };

    const optionKeys = Object.keys(options);
    const optionsWithReplacedTags = optionKeys.reduce((result, option) => {
        const optionValue = options[option];
        // prevent string replacement on non-string type values
        const shouldNotConvertOption = typeof optionValue !== "string";
        if (shouldNotConvertOption) {
            return {
                ...result,
                [option]: optionValue,
            };
        }
        // since option value is string, we can look for tags
        let convertedOption = formatSpecialCases(optionValue);
        Object.keys(tagConversions).forEach((tag) => {
            // only replace option tag if tag is one of the tagConversion keys
            // and value for tag conversion exists
            if (convertedOption.includes(tag) && tagConversions[tag]) {
                convertedOption = convertedOption.replace(
                    tag,
                    tagConversions[tag],
                );
            }
        });
        return {
            ...result,
            [option]: convertedOption,
        };
    }, {});

    return {
        ...optionsWithReplacedTags,
    };
};

// need to export like this so we can export correctly from index.js
export default formikOptionConverter;
