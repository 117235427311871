import React, { useCallback } from "react";
import { filterDonationDetailsValuesByType } from "@qgiv/donation-form";
import { useDispatch } from "react-redux";
import { useGiftPageData } from "../../PageDataHooks";
import {
    getInitialTouched,
    getInitialValues,
    getValidationSchema,
} from "./formik";
import ConnectedRecipientSearch from "../../common/RecipientSearch";
import { ModalContentReplacerEnums } from "../../common/ModalContentReplacer";
import {
    updateDonationDedication,
    updateDonationMessage,
    updateGiftDetails,
    updateShouldDisplayRecipientValidationError,
} from "../../../redux/slices/donationDetailsSlice";
import { setCurrentPageNextPage } from "../../../redux/slices/appSettingsSlice";
import GiftPage from "./GiftPage";

const ConnectedGiftPage = () => {
    // Get all the data needed for the gift page. This hook will gather all the data needed for the gift page
    // using Redux selectors and other hooks.
    const giftPageData = useGiftPageData();

    const dispatch = useDispatch();
    // Only destructure the data we need to use in this component
    const {
        displayCaptcha,
        documentTitle,
        donationRecipientExistsInCms,
        formikSettings,
        handleUpdateSubmitDonation,
        hasDisplayableDedicationOnThisPage,
        isP2PStandard,
        isSubmittingUsingExpressCheckout,
        selectedContentToDisplay,
        shouldThrowRecipientValidationError,
        storedInitialValuesFromRedux,
        submitDonation,
    } = giftPageData;

    // Destructure Formik settings objects which are build via the useGiftPageData hook
    const {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    } = formikSettings;

    const getAlternateContentForModal = useCallback(() => {
        const alternateContentForModal = [];
        if (donationRecipientExistsInCms) {
            alternateContentForModal.push({
                component: <ConnectedRecipientSearch />,
                type: ModalContentReplacerEnums.RECIPIENT_SEARCH_UI,
            });
        }
        return alternateContentForModal;
    }, [donationRecipientExistsInCms]);

    const alternateContent = getAlternateContentForModal();

    const onSubmit = (values, actions) => {
        setTimeout(() => {
            // -------------------------------------------------------------------------
            // NOTE: Selected Recipient Validation
            //       Since we do not want to have the submission button disabled
            //       when a selected recipient is empty, but do want to display a
            //       validation message and prevent "page submission", the logic for checking
            //       that set of data and displaying the validation error is going to live here.
            // -------------------------------------------------------------------------
            if (shouldThrowRecipientValidationError && isP2PStandard) {
                actions.setSubmitting(false);
                dispatch(updateShouldDisplayRecipientValidationError(true));
                return;
            }
            // -------------------------------------------------------------------------
            // NOTE: Redux is being updated via Amounts/Frequency onChange
            //
            // Currently we will always be moving to DETAILS page next, once we introduce
            // the ability to change page order we will need to get the page order from
            // the next page in the pageOrder array
            // -------------------------------------------------------------------------

            // If donor has completed PayPal/Venmo/Apple Pay Express Checkout validation,
            // then submit the donation directly.
            if (isSubmittingUsingExpressCheckout) {
                handleUpdateSubmitDonation();
                return;
            }

            // Submit page values
            dispatch(updateGiftDetails(values));
            const donorDetailsWithoutDedications =
                filterDonationDetailsValuesByType("systemFields", values);
            // Submit page values without dedications
            dispatch(updateGiftDetails(donorDetailsWithoutDedications));

            if (isP2PStandard) {
                const donationMessageDetails =
                    filterDonationDetailsValuesByType(
                        "donationMessage",
                        values,
                    );
                dispatch(updateDonationMessage(donationMessageDetails));
            }

            if (hasDisplayableDedicationOnThisPage) {
                dispatch(updateDonationDedication(values));
            }
            // wait to make sure redux is updated
            setTimeout(() => {
                actions.setSubmitting(false);
                dispatch(setCurrentPageNextPage());
            }, 250);
        }, 350);
    };

    const formikProps = {
        initialTouched: getInitialTouched(
            initialTouchedSettings,
            storedInitialValuesFromRedux,
        ),
        initialValues: getInitialValues({
            ...initialValuesSettings,
            storedInitialValuesFromRedux,
        }),
        validationSchema: getValidationSchema(validationSettings),
        onSubmit,
    };

    const giftPageProps = {
        alternateContent,
        displayCaptcha,
        documentTitle,
        formikProps,
        selectedContentToDisplay,
        submitDonation,
    };
    return <GiftPage {...giftPageProps} />;
};

export default ConnectedGiftPage;
