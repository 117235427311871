// -------------------------------------------------------------------------
// NOTE: There are going to be a lot of changes to the data and logic for
//       whether or not we display these fields, but the rules will stay the same.
//       The rules will be listed below, but for now we'll always return these
//       default values.
// -------------------------------------------------------------------------

/*
    Rules for displaying Billing Address Fields:
    1. Mailing Address fields are displayable
    2. Billing Address fields are displayable
    3. Mailing Address fields' status is ACTIVE
    4. Billing Address fields' status is ACTIVE
*/

const getBillingAddressInitialValues = (
    billingDefaultCountry = "US",
    // mailing data will come back exists false when disabled or
    // display settings do not match currentDisplay
    mailingAddressFieldsData = { exists: false },
) => ({
    Billing_Address: "",
    Billing_Address_2: "",
    Billing_City: "",
    Billing_State: "",
    Billing_Zip: "",
    Billing_Country: billingDefaultCountry,
    Billing_Address_Use_Mailing: mailingAddressFieldsData?.exists,
});

export default getBillingAddressInitialValues;
