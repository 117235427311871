import React from "react";
import PropTypes from "prop-types";
import ConnectedConfirmationFieldsHeader from "../../Pages/ReceiptPage/ConfirmationFields/ConnectedConfirmationFieldsHeader";

const ConfirmationFieldsHeaderControl = ({ controlId }) => (
    <ConnectedConfirmationFieldsHeader controlId={controlId} />
);

ConfirmationFieldsHeaderControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConfirmationFieldsHeaderControl;
