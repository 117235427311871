import React, { useCallback } from "react";
import Lottie from "lottie-react";
import { confetti, wiggleHeart } from "@qgiv/animations";

const Animation = ({
    id = "",
    style = {},
    handleComplete = () => {},
    loop = false,
    glyph = "",
    ...rest
}) => {
    const generateUniqueId = useCallback(() => {
        const ranNum = Math.floor(Math.random() * 100000);
        return `${glyph}-${ranNum}`;
    }, [glyph]);

    // -------------------------------------------------------------------------
    // NOTE: Currently animation package is a way to consolidate where the json
    // for animations live. If it looks like we may be adding a lot more animations
    // and this switch is getting unruly then we might want to look into using
    // https://v4.webpack.js.org/loaders/json-loader/ similar to how we load icons
    // with svg-sprite-loader/plugin. I did not think 2 animations were worth doing
    // this now but it may make sense to add later.
    // -------------------------------------------------------------------------
    const getAnimationDataJson = (_glyph) => {
        switch (_glyph) {
            case "confetti":
                return confetti;

            case "wiggle-heart":
                return wiggleHeart;

            default:
                break;
        }
        return {};
    };

    const animationDataJson = getAnimationDataJson(glyph);

    const styles = {
        position: "absolute",
        left: "0",
        width: "auto",
        height: "auto",
        // overwrite any above or add new styles
        ...style,
    };

    return (
        <Lottie
            {...rest}
            id={id || generateUniqueId()}
            animationData={animationDataJson}
            loop={loop || false}
            onComplete={handleComplete}
            style={styles}
        />
    );
};

export default Animation;
