import { useState, useEffect } from "react";

/**
 * @public
 * @function useShouldDisplayApplePay
 * @param {object} window The global window object.
 * @param {object} applePayOptions An object with an applePayEnabled,
 *                 isCreditCardMerchantGatewayLitle and enableCCPayments values that
 *                 have been passed from props.
 * @returns {boolean} Indicates whether Apple Pay is supported.
 * @description A custom hook that uses the presence of the Apple Pay Session
 *              object the applePayEnabled, isCreditCardMerchantGatewayLitle and
 *              enableCCPayments values that have been passed from props to
 *              determine whether Apple Pay is supported.
 */
export default function useShouldDisplayApplePay(window, applePayOptions) {
    const {
        applePayEnabled,
        isCreditCardMerchantGatewayLitle,
        enableCCPayments,
    } = applePayOptions;

    // Indicates whether the device supports Apple Pay and whether the user has
    // an active card in Wallet
    // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778000-canmakepaymentswithactivecard
    let [hasActiveCardInWallet, setHasActiveCardInWallet] = useState(false);

    useEffect(() => {
        const { ApplePaySession, location } = window;
        // Keeps track of whether the component is mounted when the
        // asynchronous request to Apple's servers completes
        let isComponentMounted = true;

        if (ApplePaySession) {
            // Set the merchant ID based on the type of environment based
            // on whether the form is being deployed in a dev or a prod environment
            const merchantIdentifier =
                location.hostname.indexOf("dev.qgiv.com") !== -1
                    ? "merchant.dev.qgiv.com"
                    : "merchant.secure.qgiv.com";
            let canMakePaymentsWithActiveCard =
                ApplePaySession.canMakePaymentsWithActiveCard(
                    merchantIdentifier,
                );

            canMakePaymentsWithActiveCard
                .then(function (canMakePayments) {
                    if (isComponentMounted) {
                        if (canMakePayments) {
                            console.log("QGIV: Apple Pay is supported.");
                            setHasActiveCardInWallet(true);
                        } else {
                            console.log("QGIV: Apple Pay is not supported.");
                            setHasActiveCardInWallet(false);
                        }
                    }
                })
                .catch(function (error) {
                    if (isComponentMounted) {
                        // Handle the Promise Rejection
                        // Apple Pay does not currently support iframes with different
                        // domains and custom domains require their own Apple pay certs
                        // which we do not support yet
                        // Because of this, trying to start an Apple Pay session from a
                        // document with an different security origin than its
                        // top-level frame is an expected error if the form is being
                        // deployed on a custom domain
                        const expectedErrorMessage =
                            "Trying to start an Apple Pay session from a document with an different security origin than its top-level frame.";
                        const expectedErrorName = "InvalidAccessError";
                        const { message, name } = error;

                        // If the error message is not expected we should log it
                        if (
                            message !== expectedErrorMessage ||
                            name !== expectedErrorName
                        ) {
                            console.log(error);
                        }

                        console.log("QGIV: Apple Pay is not supported.");
                        setHasActiveCardInWallet(false);
                    }
                });
        } else {
            console.log("QGIV: Apple Pay is not supported.");
            setHasActiveCardInWallet(false);
        }

        // Cleanup function that is run when the component dismounts thus
        // preventing a memory leak by ensuring that the component state will
        // not update if the component has been dismounted by the time the
        // async request completes
        return () => {
            isComponentMounted = false;
        };
    }, [hasActiveCardInWallet]);

    const shouldDisplayApplePay =
        !!isCreditCardMerchantGatewayLitle &&
        !!applePayEnabled &&
        !!enableCCPayments &&
        !!hasActiveCardInWallet;

    return shouldDisplayApplePay;
}
