import React from "react";
import Container from "./Container";

/**
 * SinglePageFooterContainers
 * @param {object} props
 * @param {Array} props.containers
 * @returns {React.ReactElement}
 */
const SinglePageFooterContainers = ({ containers }) => {
    const containerProps = {
        page: "single-page-footer",
    };

    return containers.map((container) => (
        <Container
            key={container.id}
            container={container}
            {...containerProps}
        />
    ));
};

export default SinglePageFooterContainers;
