import { getMultiRestrictionFieldName } from "@qgiv/donation-form";

/**
 * @typedef {import("@qgiv/core-donor").ComponentTypes.CheckboxProps} CheckboxProps
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.Restriction} Restriction
 * @typedef {import("formik").FormikProps<{}>} FormikProps
 */

/**
 *
 * @param {object} settings
 * @param {FormikProps} settings.formikContext
 * @param {Restriction} settings.restriction
 * @returns {CheckboxProps}
 */
export const getCheckboxProps = ({ formikContext, restriction }) => {
    const { values = {} } = formikContext;
    const { id: restrictionId = 0 } = restriction;

    const multiRestrictionFieldName =
        getMultiRestrictionFieldName(restrictionId);
    const currentValueOfRestrictionBeingEdited =
        values[multiRestrictionFieldName] || {};

    // These functions are passed down in order to stop the throwing of a
    // PropType error as the logic that selects and de-selects a restriction is
    // handled elsewhere
    const handleBlur = () => {};
    const handleChange = () => {};

    // Reassign and create variables as needed
    const checked = currentValueOfRestrictionBeingEdited.Checked;
    const id = multiRestrictionFieldName;
    const name = multiRestrictionFieldName;
    const value = currentValueOfRestrictionBeingEdited.Checked;

    const checkboxProps = {
        checked,
        handleBlur,
        handleChange,
        id,
        name,
        value,
    };

    return checkboxProps;
};
