import React from "react";
import PropTypes from "prop-types";
import { log } from "@qgiv/core-js";
import { validateFormAfterFormValuesHaveUpdated } from "@qgiv/core-validation";
import { getPaymentTypeInitialValue } from "../../utility";
import { TextButton } from "../Button";

const UseADifferentPaymentMethodButton = ({
    formSettings,
    handleUseADifferentPaymentMethodClick,
    setValues,
    text,
    values,
    validateForm,
}) => {
    // -------------------------------------------------------------------------
    // NOTE:
    // The form values that are associated with the PayPal transaction are not
    // reset to their original state here as that logic is handled by the
    // UpdateShouldReauthorizePayPal.js component.
    // -------------------------------------------------------------------------
    // Used to reset the payment UI to its initial state allowing users to pay
    // using a payment method after they clicked the Apple Pay, New PayPal,
    // Old PayPal or Venmo buttons.
    const handleClick = () => {
        const initialPaymentTypeValue =
            getPaymentTypeInitialValue(formSettings);
        const initialApplePayTokenValue = "";
        const initialApplePayValues = {
            Payment_Type: initialPaymentTypeValue,
            Apple_Pay_Token: initialApplePayTokenValue,
        };
        const valuesWithoutApplePayData = {
            ...values,
            ...initialApplePayValues,
        };

        // Reset the field values associated with the Apple Pay transaction
        // to their original state
        setValues(valuesWithoutApplePayData);

        // Handle all of the platform specific UI updates that affect
        // application state
        handleUseADifferentPaymentMethodClick();

        // Define success and error handlers for validation logic
        const handleValidationSuccess = () => {
            log("ATTEMPT TO VALIDATE: SUCCESS");
        };

        const handleValidationError = (error) => {
            log("ATTEMPT TO VALIDATE: FAILURE");
            log(error);
        };

        // Validate the form once the Payment_Type value has been updated so
        // that the appropriate errors object is generated
        validateFormAfterFormValuesHaveUpdated(
            validateForm,
            handleValidationSuccess,
            handleValidationError,
        );
    };

    return (
        <div className="-text--center">
            <TextButton handleClick={handleClick}>
                <strong>{text}</strong>
            </TextButton>
        </div>
    );
};

UseADifferentPaymentMethodButton.propTypes = {
    formSettings: PropTypes.shape({
        enableAchPayments: PropTypes.bool.isRequired,
        enableCCPayments: PropTypes.bool.isRequired,
        enablePaypalPayments: PropTypes.bool.isRequired,
    }).isRequired,
    handleUseADifferentPaymentMethodClick: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    values: PropTypes.shape({}).isRequired,
    validateForm: PropTypes.func.isRequired,
};

export default UseADifferentPaymentMethodButton;
