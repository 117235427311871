import React from "react";
import { useSelector } from "react-redux";
import InstallmentDetailsHeader from "./InstallmentDetailsHeader";
import { selectCurrentPage } from "../../../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../../../redux/slices/cmsSettingsSlice";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement} The rendered InstallmentDetailsHeader component.
 */
const ConnectedInstallmentDetailsHeader = ({ controlId }) => {
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const { installmentScheduleHeading = "Set your installment schedule." } =
        options;
    return <InstallmentDetailsHeader header={installmentScheduleHeading} />;
};

export default ConnectedInstallmentDetailsHeader;
