import { createSlice } from "@reduxjs/toolkit";
import { dedicationSettings as initialState } from "../initialState";

const getDedicationInitialState = () => {
    const { fields } = initialState;
    const fieldsWithFieldNamePaths = fields.map((field) => ({
        ...field,
        fieldNamePath: `Dedication_Field_${field.id}`,
    }));
    return { ...initialState, fields: fieldsWithFieldNamePaths };
};
export const dedicationInitialState = getDedicationInitialState();

const slice = createSlice({
    name: "dedicationSettings",
    initialState: dedicationInitialState,
    reducers: {},
});

// selectors
export const selectDedicationSettings = (state) => state.dedicationSettings;

export const selectAllActiveDedications = (state) =>
    state.dedicationSettings.dedications;

export const selectActiveDedicationFields = (state) =>
    state.dedicationSettings.fields;

export const selectDedicationLocation = (state) =>
    state.dedicationSettings.dedicationLocation;

export default slice.reducer;
