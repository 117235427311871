import { createSlice } from "@reduxjs/toolkit";
import { eventSettings as initialState } from "../initialState";
import {
    selectAllEventSettingsSelector,
    selectDonationRecipientSettingsAllowControlDisplaySelector,
    selectDonationRecipientSettingsSelector,
} from "../selectors/eventSettingsSelectors";

const getEventSettingsInitialState = () => initialState;
export const eventSettingsInitialState = getEventSettingsInitialState();

export const slice = createSlice({
    name: "eventSettings",
    initialState: eventSettingsInitialState,
    reducers: {
        setEventSettings: (state, action) => action.payload,
    },
});

// actions
export const { setEventSettings } = slice.actions;

// selectors
export const selectAllEventSettings = (state) =>
    selectAllEventSettingsSelector(state);

// Good for when you only need one key
export const selectSpecificEventSetting = (state, settingName) => {
    const settingToFind = settingName;

    return state.eventSettings[settingToFind];
};

// eventClosedMessaging for p2p and empty string for year round
export const selectEventClosedMessaging = (state) =>
    selectSpecificEventSetting(state, "eventClosed") || "";

// This pulls out the relevant settings for evaluating donation recipient options.
// Classifications are a bit strange because the setting for enableClassificationForDonation
// is locked behind enableClassifications. This means we can not trust
// enableClassificationForDonation by itself.
export const selectDonationRecipientSettings = (state) =>
    selectDonationRecipientSettingsSelector(state);

export const selectDonationRecipientSettingsAllowControlDisplay = (state) =>
    selectDonationRecipientSettingsAllowControlDisplaySelector(state);

export default slice.reducer;
