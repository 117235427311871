import { createSelector } from "@reduxjs/toolkit";

export const selectAllEventSettingsSelector = (state) => state.eventSettings;

export const selectDonationRecipientSettingsSelector = createSelector(
    [(state) => selectAllEventSettingsSelector(state)],
    (eventSettings) => {
        const {
            enableClassificationForDonation,
            enableClassifications,
            enableDonationToEvent,
            enableDonationToParticipant,
            enableDonationToTeam,
        } = eventSettings;

        return {
            enableClassificationForDonation:
                enableClassificationForDonation && enableClassifications,
            enableDonationToEvent,
            enableDonationToParticipant,
            enableDonationToTeam,
        };
    },
);

export const selectDonationRecipientSettingsAllowControlDisplaySelector =
    createSelector(
        [
            (state) => selectAllEventSettingsSelector(state),
            (state) => selectDonationRecipientSettingsSelector(state),
        ],
        (eventSettings, donationRecipientSettings) => {
            if (Object.keys(eventSettings).length <= 0) {
                return false;
            }

            const {
                enableClassificationForDonation,
                enableDonationToParticipant,
                enableDonationToTeam,
            } = donationRecipientSettings;
            const donationRecipientSettingsAllowControlDisplay =
                !!enableClassificationForDonation ||
                !!enableDonationToParticipant ||
                !!enableDonationToTeam;

            return donationRecipientSettingsAllowControlDisplay;
        },
    );
