import { constants, currencyString, translateImageSize } from "@qgiv/core-js";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 */

/**
 *
 * @param {object[]} amounts
 * @returns {number[]}
 */
export const getAmountIds = (amounts) => amounts.map((amount) => amount.id);

/**
 * @public
 * @function getIsDonationAmountWithinRange
 * @param {object} props contains all props
 * @param {object} props.amount current donation amount object.
 * @param {number} props.minAmt Minimum donation allowed
 * @param {number} props.maxAmt Maximum donation allowed
 * @returns {boolean} Helper logic that is used to generate the amountsToDisplay value.
 */
export const getIsDonationAmountWithinRange = ({
    amount = {},
    minAmt = 0,
    maxAmt = 0,
}) => {
    const {
        ENUMS: { ItemType },
    } = constants;
    // Convert all of these amounts to numbers so that they can be used for
    // comparisons
    const minAmtAsNumber = parseFloat(minAmt);
    const maxAmtAsNumber = parseFloat(maxAmt);
    const amountAsNumber = parseFloat(amount.amount);
    const isAmountTypeOther =
        parseInt(amount.amountType, 10) === ItemType.OTHER;

    // Other amount is always in range as it has its own min/max error message
    if (isAmountTypeOther) {
        return true;
    }

    // No need to validate if the minimum amount and maximum amount are not set
    if (!minAmtAsNumber && !maxAmtAsNumber) {
        return true;
    }

    // Fail if amount is less than the minimum
    if (minAmtAsNumber && amountAsNumber < minAmtAsNumber) {
        return false;
    }

    // Fail if the amount is greater than the maximum
    if (maxAmtAsNumber && amountAsNumber > maxAmtAsNumber) {
        return false;
    }

    // If we get here the amount is valid
    return true;
};

/**
 * @public
 * @function getAmountsToDisplay
 * @param {object} props contains all the props
 * @param {Array} props.activeAmounts Array of active donation amounts.
 * @param {boolean} props.donationActive Boolean of if One Time is enabled
 * @param {boolean} props.enableRecur Boolean of if Recurring is enabled
 * @param {boolean} props.hasSelectedRecurringDonation is the Recurring tab selected
 * @param {number} props.maxAmt Maximum donation allowed
 * @param {number} props.minAmt Minimum donation allowed
 * @returns {Array} Filtered array of only the activeAmounts that can
 *          be displayed for this donation tab
 */
export const getAmountsToDisplay = ({
    activeAmounts = [],
    donationActive = true,
    enableRecur = false,
    hasSelectedRecurringDonation = false,
    maxAmt = 0,
    minAmt = 0,
}) => {
    const hasEnabledOneTimeAndRecurringDonations =
        !!donationActive && !!enableRecur;

    // Use the min/max donation settings, which tab is active and the
    // one-time/ongoing amount settings to generate a list of amounts to
    // display
    const amountsToDisplay = activeAmounts.filter((amount) => {
        const isDonationAmountWithinRange = getIsDonationAmountWithinRange({
            amount,
            minAmt,
            maxAmt,
        });

        // All other amounts pass this test as other amounts have their own set
        // of min/max error messaging
        if (!isDonationAmountWithinRange) {
            return false;
        }

        if (
            hasEnabledOneTimeAndRecurringDonations &&
            hasSelectedRecurringDonation
        ) {
            return amount.displayForRecurring;
        }

        if (
            hasEnabledOneTimeAndRecurringDonations &&
            !hasSelectedRecurringDonation
        ) {
            return amount.displayForOneTime;
        }

        // If only one tab is enabled, display all donation amounts
        return true;
    });

    return amountsToDisplay;
};

/**
 * @public
 * @function getStandardAmounts
 * @param {object} props container for all props
 * @param {boolean} props.shouldDisplayAmountsAsCards Flag to indicate whether cards should be displayed
 * @param {Array} props.amountsToDisplay array of displayable amounts objects
 * @param {string} props.buttonStyle string to represent "standard" or "alternative"
 * @param {object} props.currency currency object from form settings
 * @param {string} props.donationAmountAlignment cms setting for which side the amount should
 *  be in card view, default "right"
 * @param {number} props.idOfSelectedAmount id of selected amount
 * @param {number} props.imageSize Enum representing Standard or Wide size
 * @param {boolean} props.isDisplayedAsPartOfCYOP boolean
 * @param {string} props.fieldName string with the Formik field name
 * @param {Array} props.standardAndOtherAmountRefs All amounts refs
 * @returns {Array} Functions that are used to generate props that are passed into
 *          component and HTML elements.
 */
export const getStandardAmounts = ({
    shouldDisplayAmountsAsCards = false,
    amountsToDisplay = [],
    buttonStyle = "",
    currency = {},
    donationAmountAlignment = "",
    idOfSelectedAmount = "",
    imageSize = "",
    isDisplayedAsPartOfCYOP = false,
    fieldName = "",
    standardAndOtherAmountRefs = [],
}) => {
    const {
        ENUMS: { ItemType },
    } = constants;

    // The other amount will be rendered by the custom amount component
    const standardAmountsToDisplay = amountsToDisplay.filter(
        (amount) => Number(amount.amountType) !== ItemType.OTHER,
    );

    // Return early if there are no active standard amounts
    if (!standardAmountsToDisplay) return {};

    // Generate an array of amounts to display with the list of props that we
    // want to be associated with each standard amount
    const standardAmounts = standardAmountsToDisplay.map(
        (standardAmountToDisplay) => {
            // Redeclare the name and image values as the name that is passed
            // down to StandardAmounts is really the name of the field while
            // the image value is really a url
            const {
                descr = "",
                id = "",
                name: title = "",
                image: url = "",
            } = standardAmountToDisplay;
            // Rename data so that it matches the prop where this is passed
            // into the component
            const name = fieldName;
            const hasTitleDescriptionOrImage = !!title || !!descr || !!url;

            const displayAsCard =
                shouldDisplayAmountsAsCards &&
                !isDisplayedAsPartOfCYOP &&
                hasTitleDescriptionOrImage;

            const displayFullWidth =
                shouldDisplayAmountsAsCards && !isDisplayedAsPartOfCYOP;

            const label = currencyString(
                standardAmountToDisplay.amount,
                currency,
            );
            const checked = parseInt(idOfSelectedAmount, 10) === id;

            // Generate props for image. Cannot pass an empty image object down as
            // CardRadio will throw a prop type error if that happens
            const altText = title || `Image for donation of ${label}`;
            const display = translateImageSize(imageSize);
            let image = {
                altText: "",
                display: "",
                url: "",
            };
            let containerClasses = " col--6 col--xsm-4 col--sm-3";

            // If a single amount is displayed as a card we need to display all
            // of the amounts full width
            if (displayFullWidth) {
                containerClasses = " col--12";
            }

            if (url) {
                image = {
                    altText,
                    display,
                    url,
                };
            }

            // Consolidate all of the data into an object
            const standardAmount = {
                buttonStyle,
                checked,
                containerClasses,
                displayAsCard,
                donationAmountAlignment,
                id,
                image,
                label,
                descr,
                name,
                title,
                standardAndOtherAmountRefs,
            };

            return standardAmount;
        },
    );

    return standardAmounts;
};

/**
 * @public
 * @function determineCorrespondingEmptyValueToUpdate
 * @description Function to determine which select should be mirrored on the
 * corresponding tab. If there are both one time and Recurring and they both have no
 * value setting one should set the other if that amount is displayable on both. Once
 * a selection is made either by user or urlShortcuts or preselect an empty
 * object will be returned and no more updates will be made to the corresponding
 * amount.
 * @param {object} props object passed containing all props
 * @param {Array} props.activeAmounts Array of active donation amounts.
 * @param {string} props.name field name being selected
 * @param {string} props.value value (id) of selected amount
 * @param {object} props.values object that contains the one time or recurring values being modified
 * @param {boolean} [props.isOtherInput] passed in to indicate we need the input amount
 * @param {number} [props.otherInputAmountId] selected other id to update amount for
 * @returns {object} Object of corresponding amounts to update
 */
export const determineCorrespondingEmptyValueToUpdate = ({
    activeAmounts,
    name,
    value,
    values,
    isOtherInput,
    otherInputAmountId,
}) => {
    const selectedIdToEvaluate = isOtherInput ? otherInputAmountId : value;

    const currentSelectedAmount = activeAmounts.find(
        (selectedAmount) =>
            Number(selectedAmount.id) === Number(selectedIdToEvaluate),
    );

    const recurringName = isOtherInput
        ? "Other_Recurring_Amount"
        : "Selected_Recurring_Id";

    const oneTimeName = isOtherInput
        ? "Other_One_Time_Amount"
        : "Selected_One_Time_Id";

    const currentSelectedAmountIsBothOneTimeAndRecurring =
        !!currentSelectedAmount?.displayForOneTime &&
        !!currentSelectedAmount?.displayForRecurring;

    const isRecurringValueEmpty = !isOtherInput
        ? values[recurringName] === ""
        : true;

    const isOneTimeValueEmpty = !isOtherInput
        ? values[oneTimeName] === ""
        : true;

    if (currentSelectedAmountIsBothOneTimeAndRecurring) {
        if (
            name === oneTimeName &&
            recurringName in values &&
            isRecurringValueEmpty
        ) {
            return {
                name: recurringName,
                value,
            };
        }

        if (
            name === recurringName &&
            oneTimeName in values &&
            isOneTimeValueEmpty
        ) {
            return {
                name: oneTimeName,
                value,
            };
        }
    }

    // Return empty object if no corresponding value should be set
    return {};
};

/**
 * @public
 * @function getMinMaxMessageProps
 * @param {object} props container for props
 * @param {string} props.maximumGiftMessage Unformatted message for max gift
 * @param {string} props.minimumGiftMessage Unformatted message for min gift
 * @param {object} props.currency currency object
 * @param {object} props.donationSettings Minimum Maximum display and dollar amount settings
 * @param {boolean} props.shouldConsiderDisplayOfMinMaxMessage Flag to indicate if there is an
 *        Other Amount input on the Form
 * @returns {object} Helper logic to return all props needed for min/max message ui
 */
export const getMinMaxMessageProps = ({
    maximumGiftMessage = "",
    minimumGiftMessage = "",
    currency,
    donationSettings = {},
    shouldConsiderDisplayOfMinMaxMessage,
}) => {
    const {
        showMaximumDonation = "",
        showMinimumDonation = "",
        minAmt = 0,
        maxAmt = 0,
    } = donationSettings;
    const displayMaxMessage = !!showMaximumDonation;
    const displayMinMessage = !!showMinimumDonation;

    const formattedMaxAmt = currencyString(maxAmt, currency);
    const formattedMinAmt = currencyString(minAmt, currency);

    const minMaxMessageProps = {
        displayMaxMessage,
        displayMinMessage,
        maximumGiftMessage: maximumGiftMessage.replace(
            "%MAXIMUM%",
            formattedMaxAmt,
        ),
        minimumGiftMessage: minimumGiftMessage.replace(
            "%MINIMUM%",
            formattedMinAmt,
        ),
        shouldConsiderDisplayOfMinMaxMessage,
    };

    return minMaxMessageProps;
};

/**
 *
 * @param {object[]} amounts List of amounts from Redux
 * @param {number} amountId Id of amount
 * @returns {object} Data for individual amount
 */
export const getSelectedAmount = (amounts, amountId) =>
    amounts.find(
        (selectedAmount = {}) => selectedAmount.id === Number(amountId),
    ) || {};

/**
 *
 * @param {object} amounts Amount data from Redux.
 * @returns {object[]} Data for all the standard amounts.
 * @description Standard amounts have an amountType of ItemType.CUSTOM and the
 * other amount has an amountType of ItemType.OTHER
 */
export const getStandardAmountsData = (amounts) => {
    const { ENUMS } = constants;

    const standardAmounts = amounts.filter(
        (amount) => amount.amountType === ENUMS.ItemType.CUSTOM,
    );

    return standardAmounts;
};

/**
 *
 * @param {boolean} hasSelectedRecurringDonation If donation is one time or ongoing.
 * @returns {"Selected_Recurring_Id"|"Selected_One_Time_Id"} Formik
 */
export const getNameOfAmountIdField = (hasSelectedRecurringDonation) => {
    if (hasSelectedRecurringDonation) {
        return "Selected_Recurring_Id";
    }

    return "Selected_One_Time_Id";
};

/**
 *
 * @param {object} amount
 * @returns {boolean}
 */
export const getIsOtherAmount = (amount) => {
    const {
        ENUMS: { ItemType },
    } = constants;
    const isOtherAmount = amount && amount.amountType === ItemType.OTHER;

    return isOtherAmount;
};

/**
 *
 * @param {undefined|string} value
 * @returns {boolean}
 */
export const getHasValueInOtherAmount = (value) => {
    // Need to account for case where value is "0.00"
    const hasValueInOtherAmount = !!Number(value);

    return hasValueInOtherAmount;
};

/**
 *
 * @param {Amount[]} amounts
 * @returns {boolean}
 */
export const getHasOngoingAmountToDisplay = (amounts) => {
    const hasOngoingAmountToDisplay = amounts.some(
        (amount) => amount.displayForRecurring,
    );
    return hasOngoingAmountToDisplay;
};
