import { createSlice } from "@reduxjs/toolkit";
import { cookieSettings as initialState } from "../initialState";

const getCookieSettingsInitialState = () => initialState;
export const cookieSettingsInitialState = getCookieSettingsInitialState();

export const slice = createSlice({
    name: "cookieSettings",
    initialState: cookieSettingsInitialState,
    reducers: {},
});

// selectors
export const selectCookieSettings = (state) => state.cookieSettings;

export default slice.reducer;
