import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    parseCustomFieldValuesReceiptDisplay,
    ReceiptAdditionalDetails,
} from "@qgiv/core-donor";
import { selectReceiptFields } from "../../../../redux/slices/receiptsSlice";

const ConnectedReceiptAdditionalDetails = ({ control }) => {
    const { options = {} } = control;
    const { additionalDetailsLabel, showCustomFields } = options;
    // currently all of the fields are coming back without a type
    const fields = useSelector(selectReceiptFields);

    // when viewing confirmation page directly, showCustomFields option
    // can come in as a number. This will probably change in the near future
    // to always be a bool
    const showCustomFieldsBoolean =
        showCustomFields === 1 || showCustomFields === true;

    const shouldDisplayReceiptAdditionalDetails =
        fields?.length > 0 && showCustomFieldsBoolean;

    const parsedCustomFields = fields.map((field) => ({
        id: field.id,
        fieldLabel: field.display_text,
        value: parseCustomFieldValuesReceiptDisplay(field.type, field.data),
    }));
    return shouldDisplayReceiptAdditionalDetails ? (
        <ReceiptAdditionalDetails
            parsedCustomFields={parsedCustomFields}
            additionalDetailsLabel={additionalDetailsLabel}
        />
    ) : null;
};

ConnectedReceiptAdditionalDetails.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            additionalDetailsLabel: PropTypes.string,
            showCustomFields: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.bool,
            ]),
        }),
    }).isRequired,
};

export default ConnectedReceiptAdditionalDetails;
