import {
    Yup,
    getCaptchaFieldValidation,
    getDedicationValidations,
    getMatchingGiftValidations,
} from "@qgiv/core-validation";
import { getAllCustomFieldValidation } from "@qgiv/core-donor";

export const getAdditionalDetailsPageValidations = (settings) => {
    const {
        additionalDetailsCustomFields,
        allFieldsAndGroups,
        conditionalLogicOptions,
        currentDisplay,
        dedicationSettings,
        Has_Dedication,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        matchingRatio,
        savedValues,
        shouldCaptchaBeRequired,
        subtotal,
        total,
    } = settings;
    const additionalDetailsSchema = {
        ...getAllCustomFieldValidation({
            allCustomFields: additionalDetailsCustomFields,
            allFieldsAndGroups,
            options: conditionalLogicOptions,
            savedValues,
            total,
        }),
        ...getCaptchaFieldValidation(shouldCaptchaBeRequired),
        ...(hasDisplayableMatchingGiftOnThisPage && {
            ...getMatchingGiftValidations(matchingRatio, subtotal),
        }),
        ...(hasDisplayableDedicationOnThisPage && {
            ...getDedicationValidations({
                currentDisplay,
                dedicationSettings,
                Has_Dedication,
            }),
        }),
    };

    return additionalDetailsSchema;
};

const getValidationSchema = (settings) => {
    const validationShape = {
        ...getAdditionalDetailsPageValidations(settings),
    };
    const schema = Yup.object().shape(validationShape);

    return schema;
};

export default getValidationSchema;
