import { constants } from "@qgiv/core-js";

const { ENUMS } = constants;
const { ItemType } = ENUMS;

/**
 * @public
 * @function getHasSelectedOtherAmount
 * @param {object} props contains all props
 * @param {Array} props.activeAmounts Array of active donation amounts.
 * @param {object} props.values Object of all the current formik values
 * @param {string} props.fieldName Formik value we are looking for
 * @returns {boolean} Boolean that represents whether the selected
 *          amount is a Standard amount or Other Amount
 */
export const getHasSelectedOtherAmount = ({
    activeAmounts,
    values,
    fieldName,
}) => {
    const selectedId = values[fieldName];
    const selectAmountData = activeAmounts.find(
        (amount) => parseInt(amount.id, 10) === parseInt(selectedId, 10),
    );
    const hasSelectedOtherAmount =
        selectAmountData?.amountType === ItemType.OTHER;

    return hasSelectedOtherAmount;
};

/**
 * @public
 * @function getOtherInputProps
 * @param {object} settings setting parameters of the function
 * @param {string} settings.fieldName string with the Formik field name for selected button id
 * @param {boolean} settings.hasSelectedOtherAmount The other input has been selected by user
 * @param {boolean} settings.hasSelectedRecurringDonation string with the Formik field name for amount input
 * @param {Array} settings.amountsToDisplay array of displayable amounts objects
 * @returns {object} Props object returned
 */
export const getOtherInputProps = ({
    fieldName = "",
    hasSelectedOtherAmount = false,
    hasSelectedRecurringDonation = false,
    amountsToDisplay,
}) => {
    const checked = hasSelectedOtherAmount;
    // Setting the name to the field name will allow you to use the keyboard
    // to navigate from the standard amounts to the custom amount
    const buttonSelectedFieldName = fieldName;

    const otherAmountInputFieldName = hasSelectedRecurringDonation
        ? "Other_Recurring_Amount"
        : "Other_One_Time_Amount";

    const otherFieldData = amountsToDisplay.find(
        (amountItem) => amountItem.amountType === ItemType.OTHER,
    );

    // Return early if there is no active Other Amount
    if (!otherFieldData) return {};

    const idOfOtherAmount = otherFieldData?.id;
    const otherLabel =
        amountsToDisplay.length > 1 ? otherFieldData?.name : "$0";

    const indexOfOtherAmount = amountsToDisplay.findIndex(
        (amount) => amount.amountType === ItemType.OTHER,
    );

    let containerClasses = "";
    // If name of other amount is more than 7 characters
    // ensure that its text is always visible by moving it
    // to its own line
    if (otherLabel && otherLabel.length > 7) {
        containerClasses = "col col--12";
    }

    const otherInputProps = {
        checked,
        buttonSelectedFieldName,
        otherAmountInputFieldName,
        idOfOtherAmount,
        indexOfOtherAmount,
        containerClasses,
        otherLabel,
    };

    return otherInputProps;
};

/**
 * @public
 * @function getOtherAmountErrorProps
 * @param {object} props contains all props
 * @param {string} props.otherAmountErrorMessage Array of active donation amounts.
 * @param {boolean} props.hasSelectedOtherAmount Flag for whether button selected is Other
 * @returns {object} Returns props need for OtherAmountError component
 */
export const getOtherAmountErrorProps = ({
    otherAmountErrorMessage,
    hasSelectedOtherAmount,
}) => {
    const displayOtherAmountError =
        hasSelectedOtherAmount &&
        !!otherAmountErrorMessage &&
        otherAmountErrorMessage.length > 0;
    return {
        displayOtherAmountError,
        message: otherAmountErrorMessage,
    };
};

/**
 *
 * @param {object[]} amounts Amounts data from Redux.
 * @returns {object} Data for the other amount.
 */
export const getOtherAmountData = (amounts) => {
    const otherAmount =
        amounts.find((amount) => amount.amountType === ItemType.OTHER) || {};

    return otherAmount;
};

/**
 *
 * @param {object[]} amounts Amounts data from Redux.
 * @returns {number} Index of the other amount. -1 is returned if it is not
 * found.
 */
export const getIndexOfOtherAmount = (amounts) => {
    const indexOfOtherAmount = amounts.findIndex(
        (amount) => amount.amountType === ItemType.OTHER,
    );

    return indexOfOtherAmount;
};

/**
 *
 * @param {object} otherAmount Data for the other amount.
 * @param {object[]} amountsToDisplay List of amounts to display
 * @returns {string} Other amount label. Returns "$0" if there is no label or
 * the other amount is the only amount that is going to be displayed.
 */
export const getOtherAmountLabel = (otherAmount, amountsToDisplay) => {
    if (otherAmount && otherAmount.name && amountsToDisplay.length > 1) {
        return otherAmount.name;
    }

    return "$0";
};

/**
 *
 * @param {string} otherAmountLabel Other amount label.
 * @returns {string} String that ensures the other amount on its own line if
 * the label contains > 7 characters.
 */
export const getOtherAmountContainerClasses = (otherAmountLabel) => {
    if (otherAmountLabel && otherAmountLabel.length > 7) {
        return "col col--12";
    }

    return "";
};

/**
 *
 * @param {boolean} hasSelectedRecurringDonation If donation is recurring.
 * @returns {string} String that represents the name of the Formik field that
 * is associated the other amount for the one time or ongoing donations.
 */
export const getNameOfOtherAmountField = (hasSelectedRecurringDonation) => {
    if (hasSelectedRecurringDonation) {
        return "Other_Recurring_Amount";
    }

    return "Other_One_Time_Amount";
};
