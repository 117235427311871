import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import { Checkbox, Button } from "@qgiv/core-donor";
import ChooseRecipientErrorMessage from "./ChooseRecipientErrorMessage";
import "./ChooseRecipient.scss";

const ChooseRecipient = ({
    chooseRecipientButtonDescription = "Your gift will be applied to their fundraising goal.",
    chooseRecipientButtonLabel = "Choose someone",
    chooseRecipientCheckboxLabel = "No one specifically, just the overall campaign.",
    chooseRecipientHeading = "Whose fundraising efforts would you like to support?",
    donateToEvent,
    enableDonationToEvent = true,
    handleDonateToEventCheckboxChange,
    setModalContentToSearchUI = () => {},
    shouldDisplayRecipientValidationError,
}) => (
    <div className="choose-recipient -callout">
        {shouldDisplayRecipientValidationError ? (
            <ChooseRecipientErrorMessage errorText="Please make a selection." />
        ) : null}
        <h3 className="-text--center">{chooseRecipientHeading}</h3>
        {!donateToEvent ? (
            <Button
                type="button"
                btnStyle="secondary"
                classes="choose-recipient__button -margin-top--15 -margin-bottom--15"
                onClick={setModalContentToSearchUI}
            >
                <div className="choose-recipient__button-content">
                    <Icon
                        type="FontAwesome"
                        glyph="qgiv-fundraiser-duotone"
                        classNames={["choose-recipient__button-main-icon"]}
                        ariaHidden
                    />
                    <div className="choose-recipient__button-content-details">
                        <p className="choose-recipient__button-action-text ignore-typography ignore-apply-spacing">
                            <span>{chooseRecipientButtonLabel}</span>
                            <Icon
                                type="FontAwesome"
                                glyph="chevron-right-solid"
                                classNames={[
                                    "choose-recipient__button-details-icon",
                                ]}
                                ariaHidden
                            />
                        </p>
                        <p className="ignore-apply-spacing">
                            <small>{chooseRecipientButtonDescription}</small>
                        </p>
                    </div>
                </div>
            </Button>
        ) : null}
        {enableDonationToEvent ? (
            <div className="donate-to-event-container">
                <Checkbox
                    name="Donate_To_Event"
                    id="Donate_To_Event"
                    handleBlur={() => {}}
                    value={donateToEvent}
                    checked={donateToEvent}
                    handleChange={handleDonateToEventCheckboxChange}
                >
                    <span>{chooseRecipientCheckboxLabel}</span>
                </Checkbox>
            </div>
        ) : null}
    </div>
);

ChooseRecipient.propTypes = {
    chooseRecipientHeading: PropTypes.string,
    chooseRecipientButtonLabel: PropTypes.string,
    chooseRecipientButtonDescription: PropTypes.string,
    chooseRecipientCheckboxLabel: PropTypes.string,
    donateToEvent: PropTypes.bool.isRequired,
    enableDonationToEvent: PropTypes.bool,
    handleDonateToEventCheckboxChange: PropTypes.func.isRequired,
    setModalContentToSearchUI: PropTypes.func,
    shouldDisplayRecipientValidationError: PropTypes.bool.isRequired,
};

export default ChooseRecipient;
