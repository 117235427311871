import React from "react";
import { useSelector } from "react-redux";
import InstallmentHeader from "./InstallmentHeader";
import { selectCurrentPage } from "../../../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../../../redux/slices/cmsSettingsSlice";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement} The InstallmentHeader component.
 */
const ConnectedInstallmentHeader = ({ controlId }) => {
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const {
        flexiblePlanHeading = "Select a Plan",
        flexiblePlanAdditionalText = "The total will be split into installments",
    } = options;

    return (
        <InstallmentHeader
            header={flexiblePlanHeading}
            additionalText={flexiblePlanAdditionalText}
        />
    );
};

export default ConnectedInstallmentHeader;
