import { constants } from "@qgiv/core-js";

// eslint-disable-next-line import/prefer-default-export
export const getMatchingGiftsInitialValues = (
    hasDisplayableMatchingGiftOnThisPage,
    enableMatching,
    matchingType,
) => {
    const {
        ENUMS: { MatchingType },
    } = constants;

    // Matching Gifts may be displayed on either Donor Details or Additional Pages
    if (enableMatching && hasDisplayableMatchingGiftOnThisPage) {
        const manualMatchingValues = {
            Match_Type: matchingType,
            Match_Gift: false,
            Employer: "",
            Matching_Gift_Amount: "",
        };

        const automaticMatchingValues = {
            // Automatic Matching Gifts
            Match_Type: matchingType,
            Employer: "",
            Work_Email: "",
            // Double the Donation
            Matching_Company_Name: "",
            Matching_Company_ID: "",
            Matching_Parent_Raw_ID: "",
            Matching_Contact_Address_Line_1: "",
            Matching_Contact_Address_Line_2: "",
            Matching_Contact_City: "",
            Matching_Contact_State: "",
            Matching_Contact_Zip: "",
            Matching_Contact_Phone: "",
            Matching_Contact_Email: "",
            // Cyber grants only
            Matching_Donor_Found: "",
            Matching_Donor_Verified: false,
        };

        const matchingInitialValues =
            matchingType === MatchingType.MANUAL
                ? manualMatchingValues
                : automaticMatchingValues;

        return matchingInitialValues;
    }

    // Return empty object if Matching is disabled or not displayed on this page
    return {};
};
