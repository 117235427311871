import React from "react";
import PropTypes from "prop-types";
import RefundCallout from "./RefundCallout";

const RefundCallouts = ({ hasRefunds = false, formattedRefunds = [] }) => {
    // Render refund callouts if there are refunds to display
    const renderRefund = (formattedRefund) => (
        <RefundCallout
            key={formattedRefund.id}
            displayRefund={formattedRefund.displayRefund}
            formattedAmount={formattedRefund.formattedAmount}
            formattedDate={formattedRefund.formattedDate}
        />
    );

    return hasRefunds ? formattedRefunds.map(renderRefund) : null;
};

RefundCallouts.propTypes = {
    hasRefunds: PropTypes.bool.isRequired,
    formattedRefunds: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            displayRefund: PropTypes.bool.isRequired,
            formattedAmount: PropTypes.string.isRequired,
            formattedDate: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default RefundCallouts;
