import { constants } from "@qgiv/core-js";

const {
    ENUMS: { ProductType },
} = constants;

// eslint-disable-next-line import/prefer-default-export
export const defaultFormSettings = {
    VisaAmexDisc: "yyy",
    appearance: 2,
    captcha: false,
    currency: {},
    donationActive: true,
    enableDonorLogins: true,
    enableGiftAssistByDefault: false,
    enableInvoice: false,
    enableRecur: true,
    feeCoverage: 1,
    feeCoverageFlat: 0,
    feeCoveragePercentage: 3,
    feeCoverageText: "Add a little extra to your total to help with fees %Fee%",
    id: 1234,
    imageSize: 2,
    isStandardDonationForm: true,
    org: 21204,
    orgName: "Test Org",
    paymentData: {},
    status: 9,
    template: 10,
    theme: {},
    title: "Test Form",
    type: ProductType.QGIV,
};
