import React from "react";
import PropTypes from "prop-types";
import ConnectedWidgetSocialSharing from "./ConnectedWidgetSocialSharing";

const SocialControl = ({ controlId }) => (
    <ConnectedWidgetSocialSharing controlId={controlId} />
);

SocialControl.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default SocialControl;
