import React from "react";
import PropTypes from "prop-types";
import { LoadingDots } from "@qgiv/core-react";

import "./ProcessingDots.scss";

const ProcessingDots = ({ containerStyle = {}, spinnerStyle = "" }) => (
    <div className="processing-dots" style={containerStyle}>
        <LoadingDots color={spinnerStyle === "dark" ? "" : "#ffffff"} center />
    </div>
);

ProcessingDots.propTypes = {
    // You can pass any key that can be associated with an inline CSS rule here
    containerStyle: PropTypes.shape({}),
    spinnerStyle: PropTypes.string,
};

export default ProcessingDots;
