/**
 * @public
 * @function getCalculatedInstallmentEndDate
 * @description calculates the installment end date based on the currently selected plans
 * configuration
 * @param {object} plan single activePlan object
 * @param {Array} values formik values
 * @returns {object} calculated end date object
 */
export const getCalculatedInstallmentEndDate = (plan, values) => {
    const { Installment_Count, Recurring_Frequency, Start_Date } = values;

    // if formik start date is greater than plan.begin_date this is
    // a custom plan with custom start_date and we should use the formik value
    // and not the plan.begin_date as that is todays date
    const shouldUseFormikStartDate =
        new Date(Start_Date).getTime() > new Date(plan.begin_date).getTime();

    const startDate = shouldUseFormikStartDate ? Start_Date : plan.begin_date;
    const todaysDate = new Date();
    const planStartDate =
        new Date(startDate).getTime() > todaysDate.getTime()
            ? new Date(startDate)
            : todaysDate;

    let calculatedEndDate = new Date();
    // Subtracts 1 from Installment_Count as to account for the first payment
    switch (Recurring_Frequency) {
        case "d":
            for (let i = 0; i < Installment_Count - 1; i++) {
                calculatedEndDate = planStartDate.setDate(
                    planStartDate.getDate() + 1,
                );
                calculatedEndDate = new Date(calculatedEndDate);
            }
            break;
        case "w":
            for (let i = 0; i < Installment_Count - 1; i++) {
                calculatedEndDate = planStartDate.setDate(
                    planStartDate.getDate() + 7,
                );
                calculatedEndDate = new Date(calculatedEndDate);
            }
            break;
        case "b":
            for (let i = 0; i < Installment_Count - 1; i++) {
                calculatedEndDate = planStartDate.setDate(
                    planStartDate.getDate() + 14,
                );
                calculatedEndDate = new Date(calculatedEndDate);
            }
            break;
        case "m":
            for (let i = 0; i < Installment_Count - 1; i++) {
                calculatedEndDate = planStartDate.setMonth(
                    planStartDate.getMonth() + 1,
                );
                calculatedEndDate = new Date(calculatedEndDate);
            }
            break;
        case "q":
            for (let i = 0; i < Installment_Count - 1; i++) {
                calculatedEndDate = planStartDate.setMonth(
                    planStartDate.getMonth() + 3,
                );

                calculatedEndDate = new Date(calculatedEndDate);
            }
            break;
        case "s":
            for (let i = 0; i < Installment_Count - 1; i++) {
                calculatedEndDate = planStartDate.setMonth(
                    planStartDate.getMonth() + 6,
                );

                calculatedEndDate = new Date(calculatedEndDate);
            }
            break;
        case "a":
            for (let i = 0; i < Installment_Count - 1; i++) {
                calculatedEndDate = planStartDate.setFullYear(
                    planStartDate.getFullYear() + 1,
                );
                calculatedEndDate = new Date(calculatedEndDate);
            }
            break;
        default:
            break;
    }
    return calculatedEndDate;
};

/**
 * @public
 * @function getFinalEndDate
 * @description for parity with BE, when payment end dates are on the 27th or greater we default to
 * the last day of the month to account for leap years
 * @param {object} calculatedEndDate the calculated end date not considering leap years
 * @returns {object} final end date accounting for leap years
 */
export const getFinalEndDate = (calculatedEndDate) => {
    if (calculatedEndDate.getDate() > 27) {
        return new Date(
            calculatedEndDate.getUTCFullYear(),
            calculatedEndDate.getUTCMonth() + 1,
            0,
        );
    }
    return calculatedEndDate;
};

/**
 * @public
 * @function getFormattedEndDate
 * @description formats the end date to the desired m/d/Y format and prefixes 0's
 * if the day or month is a single digit
 * @param {object} installmentEndDate currently selected installment end date
 * @returns {string} calculated end date object
 */
export const getFormattedEndDate = (installmentEndDate) => {
    // add 1 because month is 0 indexed
    const formattedMonth = installmentEndDate.getMonth() + 1;
    const month = formattedMonth < 10 ? `0${formattedMonth}` : formattedMonth;
    const dayOfMonth =
        installmentEndDate.getDate() < 10
            ? `0${installmentEndDate.getDate()}`
            : installmentEndDate.getDate();
    const year = installmentEndDate.getFullYear();

    return `${month}/${dayOfMonth}/${year}`;
};
