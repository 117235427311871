import { isDisplayable } from "@qgiv/core-js";
import {
    getHasEnabledActiveStandardRestrictions,
    getRestrictionName,
} from "../../standardRestrictionsHelpers";
import {
    getHasDisplayableSubRestrictions,
    getInitialSubRestrictionValue,
} from "../../subRestrictionHelpers";

/**
 * @public
 * @function getRestrictionInitialValues
 * @param {object} settings Form settings object
 * @description Determines initial values for Restriction
 * @returns {object} Spread this returned object into the initial values object.
 */

// eslint-disable-next-line import/prefer-default-export
export const getStandardRestrictionInitialValue = (settings) => {
    const {
        restrictionSettings,
        currentDisplay,
        donationSettings: { restrictionGivingType },
    } = settings;
    const { restrictions = [] } = restrictionSettings;

    const hasEnabledActiveStandardRestrictions =
        getHasEnabledActiveStandardRestrictions({
            restrictions,
            hasActiveFulfillment: false,
            currentDisplay,
            restrictionGivingType,
        });

    // Early exit if Standard Restrictions are not enabled
    if (!hasEnabledActiveStandardRestrictions) return {};

    // Only grab visible restrictions
    const visibleRestrictions = restrictions.filter((restriction) =>
        isDisplayable(restriction, currentDisplay),
    );

    const hasDisplayableSubRestrictions = getHasDisplayableSubRestrictions(
        restrictions,
        currentDisplay,
    );
    const initialRestrictionId = visibleRestrictions[0]?.id || "";
    const initialRestrictionName = getRestrictionName(
        visibleRestrictions,
        initialRestrictionId,
    );
    const initialSubRestrictionValue = getInitialSubRestrictionValue(
        restrictions,
        initialRestrictionId,
    );

    const values = {
        Standard_Restriction_Id: initialRestrictionId,
        Standard_Restriction_Name: initialRestrictionName,
        ...(hasDisplayableSubRestrictions && {
            Standard_Restriction_Sub_Restriction: initialSubRestrictionValue,
        }),
    };

    return values;
};
