import { constants } from "@qgiv/core-js";

const { RecurringFrequency } = constants.ENUMS;

// -------------------------------------------------------------------------
// TODO: When we add multi-restriction, multi-restriction keys will need to begin with
// 'Multi_' and end in the ID (ex. Multi_1001). Multi-restriction values should follow
// the same shape used by Standard Restrictions (Array, with 'Donation_Amount' key and
// the amount as the value).
// -------------------------------------------------------------------------

/**
 * @public
 * @function getRecurringFrequencyData
 * @description Returns the recurring frequency data needed for the call to PayPal's create order API
 * @param {boolean} hasSelectedRecurringDonation Indicates if donor has selected recurring donation
 * @param {string} Recurring_Frequency recurring frequency of donation
 * @returns {string} recurring frequency enum
 */
export const getRecurringFrequencyData = (
    hasSelectedRecurringDonation,
    Recurring_Frequency,
) =>
    // NOTE: Redux will store the recurring frequency as the default (usually "m"), even when the
    // currently selected tab is "one-time". The below conversion allows us to send the correct enum
    // when on one-time donations ("n").
    hasSelectedRecurringDonation
        ? Recurring_Frequency
        : RecurringFrequency.ONCE;

/**
 * @public
 * @function getGiftAssistData
 * @description Returns the gift assist data needed for the call to PayPal's create order API
 * @param {(boolean|undefined)} Gift_Assist if gift assist is checked. Undefined if GA is disabled in form settings
 * @param {number} giftAssistAmount The amount of gift assist to be included on top of donation amount
 * @returns {object} gift assist data
 */
export const getGiftAssistData = (Gift_Assist, giftAssistAmount) => {
    // NOTE: Below value will ONLY be true when gift assist
    // is both enabled by org AND checked by donor.
    const isGiftAssistEnabledAndChecked = !!Gift_Assist;

    const giftAssistData = {
        Checked: isGiftAssistEnabledAndChecked,
        Amount: giftAssistAmount,
    };

    return giftAssistData;
};

/**
 * @public
 * @function getRestrictionData
 * @description Returns the restriction data needed for the call to PayPal's create order API
 * @param {number} donationAmountBeforeGiftAssist the amount of the donation, before gift assist is applied
 * @param {number} Standard_Restriction_Id the id of the standard restriction, if applicable
 * @returns {object} restriction data
 */
export const getRestrictionData = (
    donationAmountBeforeGiftAssist,
    Standard_Restriction_Id,
) => {
    const restrictionData = {};
    if (Standard_Restriction_Id) {
        restrictionData[Standard_Restriction_Id] = {
            Donation_Amount: donationAmountBeforeGiftAssist,
        };
    }

    return restrictionData;
};

/**
 * @public
 * @function getValuesForCreateOrderAPI
 * @description Returns the data needed for the call to PayPal's create order API
 * @param {object} giftDetails object containing the giftDetails portion of the donationDetails slice
 * @returns {object} values object to be sent to create order API
 */
export const getValuesForCreateOrderAPI = (giftDetails) => {
    const {
        Gift_Assist,
        giftAssistAmount,
        hasSelectedRecurringDonation,
        Recurring_Frequency,
        Standard_Restriction_Id,
        subtotal,
    } = giftDetails;

    const donationAmountBeforeGiftAssist = subtotal;
    const giftAssistData = getGiftAssistData(Gift_Assist, giftAssistAmount);
    const recurringFrequencyData = getRecurringFrequencyData(
        hasSelectedRecurringDonation,
        Recurring_Frequency,
    );

    const values = {
        Donation_Amount: donationAmountBeforeGiftAssist,
        Recurring_Frequency: recurringFrequencyData,
        Gift_Assist: giftAssistData,
        ...getRestrictionData(
            donationAmountBeforeGiftAssist,
            Standard_Restriction_Id,
        ),
    };

    return values;
};
