import React from "react";
import cx from "classnames";

import ImageLoader from "../ImageLoader";

/**
 * @param {object} props Component props
 * @param {string} [props.className] Class names to add to the error component
 * @returns {React.ReactElement} ErrorComponent
 */
const ErrorComponent = ({ className = "" }) => (
    <div
        className={cx(
            "-text--center -type--regular -font-weight--semibold -color--system-medium-dark",
            className,
        )}
    >
        <ImageLoader
            alt="Error"
            className="-width--120px"
            src="https://secure.qgiv.com/resources/admin/images/error.svg"
        />
        <div>Uh oh! Something went wrong.</div>
        <div>
            <button
                className="-link"
                onClick={() => window.location.reload()}
                type="button"
            >
                Click here
            </button>{" "}
            to refresh.
        </div>
    </div>
);

export default ErrorComponent;
