import { getInitialValues as getDetailsInitialValues } from "../../Pages/DetailsPage/formik";
import { getInitialValues as getAdditionalInitialValues } from "../../Pages/AdditionalDetailsPage/formik";
import { getInitialValues as getPaymentInitialValues } from "../../Pages/PaymentPage/formik";
import { getInitialValues as getGiftInitialValues } from "../../Pages/GiftPage/formik";

const getInitialValues = (
    hasActiveFulfillment,
    settings,
    storedInitialValuesFromRedux,
) => {
    const {
        giftPageInitialValuesSettings = {},
        donorDetailPageInitialValuesSettings = {},
        additionalDetailPageInitialValuesSettings = {},
        paymentPageInitialValuesSettings = {},
    } = settings;

    const initialValuesFromGiftContent = getGiftInitialValues({
        ...giftPageInitialValuesSettings,
        storedInitialValuesFromRedux,
    });
    const initialValuesFromDetailsContent = getDetailsInitialValues(
        donorDetailPageInitialValuesSettings,
    );
    const initialValuesFromAdditionalContent = getAdditionalInitialValues(
        additionalDetailPageInitialValuesSettings,
    );
    const initialValuesFromPaymentContent = getPaymentInitialValues(
        paymentPageInitialValuesSettings,
    );

    const singlePageInitialValues = {
        ...initialValuesFromGiftContent,
        ...initialValuesFromDetailsContent,
        ...(!hasActiveFulfillment && {
            ...initialValuesFromAdditionalContent,
        }),
        ...initialValuesFromPaymentContent,
    };

    const initialValuesWithSavedValues = {
        ...singlePageInitialValues,
    };

    return initialValuesWithSavedValues;
};

export default getInitialValues;
