import React from "react";
import PropTypes from "prop-types";
import { TextButton } from "../Button";

// -------------------------------------------------------------------------
// TODO:
// 1. Add tests to confirm that this button is never displayed when the I
// do not want to use Apple Pay button is displayed.
// -------------------------------------------------------------------------
const InvoiceButton = ({ handleClick = () => {}, text = "" }) => (
    <div className="col col--12 -text--center">
        <TextButton handleClick={handleClick}>
            <strong>{text}</strong>
        </TextButton>
    </div>
);

InvoiceButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default InvoiceButton;
