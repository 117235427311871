import { calcGiftAssistFee } from "@qgiv/core-donor";
import { constants } from "@qgiv/core-js";

const {
    ENUMS: { GivingPlanSystemType },
} = constants;

/**
 * @public
 * @function getIsPreselectDisplayable
 * @param {object} settings all passed props
 * @param {object} settings.preselectData active amount data derived from preselectAmount id
 * @param {string} settings.donationType "onetime" or "recurring"
 * @param {object} settings.donationSettings all donation settings
 * @description Determines if the preselected amount is displayed and selectable
 * @returns {boolean} isPreselectDisplayable
 */
export const getIsPreselectDisplayable = ({
    preselectData,
    donationType,
    donationSettings,
}) => {
    // Exit early if no preselect data was found
    if (!preselectData || Object.keys(preselectData).length === 0) {
        return false;
    }

    const { minAmt = 0, maxAmt = 0 } = donationSettings;

    const isPreselectWithinMinMaxRange =
        !!(!minAmt || preselectData.amount >= minAmt) &&
        !!(!maxAmt || preselectData.amount <= maxAmt);

    const isOneTimeAmountDisplayable =
        isPreselectWithinMinMaxRange &&
        preselectData?.displayForOneTime &&
        donationType === "onetime";

    const isRecurringAmountDisplayable =
        isPreselectWithinMinMaxRange &&
        preselectData?.displayForRecurring &&
        donationType === "recurring";

    if (isOneTimeAmountDisplayable || isRecurringAmountDisplayable) {
        return true;
    }

    return false;
};

export const getIsPreselectGivingPlanDisplayable = (
    preselectPledge,
    activePlans,
) => {
    // check if the preselect plan exists in activePlans
    const activePreselectedGivingPlan =
        activePlans.find((plan) => plan?.id === Number(preselectPledge)) || {};

    if (Object.keys(activePreselectedGivingPlan).length === 0) {
        return false;
    }

    return true;
};

/**
 * @public
 * @function totalsWithPreselect
 * @param {object} settings all settings needed for initial values
 * @param {boolean} isOneTimeAmountDisplayable determines if preselected amount is displayable for onetime
 * @param {boolean} isRecurringAmountDisplayable determines if preselected amount is displayable for recurring
 * @description Determines starting values of totals based on preselected amounts
 * @returns {string} initial values
 */
export const totalsWithPreselect = (
    settings,
    isOneTimeAmountDisplayable,
    isRecurringAmountDisplayable,
) => {
    const {
        donationSettings,
        formSettings: {
            feeCoverage,
            feeCoverageFlat,
            feeCoveragePercentage,
            enableGiftAssistByDefault,
        },
        hasSelectedRecurringDonation,
    } = settings;
    const {
        activePlans,
        preselectAmounts = {},
        activeAmounts = [],
        enablePreselectAmount = false,
        pledgeActive,
        givingPlanSystemType,
    } = donationSettings;

    // We only want to set the total for the preselected amount if that amount is actually displayed. Min/Max or Amounts made inactive
    // after the preselected amount is set, can create a situation where we are trying to preselect an amount that is no longer displayed.
    const selectedPreselectAmountData =
        activeAmounts.find((selectedAmount) => {
            if (
                hasSelectedRecurringDonation &&
                isRecurringAmountDisplayable &&
                !pledgeActive
            ) {
                return (
                    selectedAmount.id ===
                    Number(preselectAmounts.preselectRecurringAmount)
                );
            }

            if (!hasSelectedRecurringDonation && isOneTimeAmountDisplayable) {
                return (
                    selectedAmount.id ===
                    Number(preselectAmounts.preselectOneTimeAmount)
                );
            }
            return false;
        }) || {};

    const selectedGivingPlanData =
        (pledgeActive &&
            activePlans.find(
                (selectedPlan) =>
                    selectedPlan.id ===
                    Number(preselectAmounts.preselectPledge),
            )) ||
        {};

    let subtotal = 0.0;
    let giftAssistAmount = 0.0;

    const shouldUpdateTotalWithPreselect =
        enablePreselectAmount &&
        selectedPreselectAmountData &&
        Object.keys(selectedPreselectAmountData).length > 0 &&
        (isOneTimeAmountDisplayable || isRecurringAmountDisplayable);

    const shouldUpdateTotalWithGivingPlanPreselect =
        enablePreselectAmount &&
        selectedGivingPlanData &&
        (hasSelectedRecurringDonation || !isOneTimeAmountDisplayable) &&
        pledgeActive &&
        givingPlanSystemType === GivingPlanSystemType.PREDEFINED &&
        Object.keys(selectedGivingPlanData).length > 0;

    const shouldUpdateTotalWithFlexiblePlanPreselect =
        enablePreselectAmount &&
        selectedGivingPlanData &&
        (hasSelectedRecurringDonation || !isOneTimeAmountDisplayable) &&
        pledgeActive &&
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE &&
        Object.keys(selectedGivingPlanData).length > 0;

    if (shouldUpdateTotalWithPreselect) {
        subtotal = selectedPreselectAmountData?.amount;
    }

    if (shouldUpdateTotalWithGivingPlanPreselect) {
        subtotal = selectedGivingPlanData?.amount;
    }

    if (shouldUpdateTotalWithFlexiblePlanPreselect) {
        subtotal =
            selectedGivingPlanData.amount /
            selectedGivingPlanData.defaultNumberOfInstallments;
    }

    if (subtotal > 0 && enableGiftAssistByDefault && Number(feeCoverage) > 0) {
        giftAssistAmount = Number(
            calcGiftAssistFee(subtotal, {
                feeCoverage,
                feeCoverageFlat,
                feeCoveragePercentage,
            }),
        );
    }

    return shouldUpdateTotalWithPreselect ||
        shouldUpdateTotalWithGivingPlanPreselect
        ? {
              subtotal,
              giftAssistAmount,
              total: subtotal + giftAssistAmount,
          }
        : {};
};

const getPreselectedPlanFrequency = (
    preselectPledge,
    activePlans,
    givingPlanSystemType,
) => {
    const preSelectedPlan =
        activePlans.find((plan) => plan.id === Number(preselectPledge)) || {};

    let preSelectedPlanFrequency;
    if (givingPlanSystemType === GivingPlanSystemType.PREDEFINED) {
        preSelectedPlanFrequency = preSelectedPlan.frequencyEnum;
    }

    if (givingPlanSystemType === GivingPlanSystemType.FLEXIBLE) {
        preSelectedPlanFrequency = preSelectedPlan.defaultFrequencyEnum;
    }

    return preSelectedPlanFrequency;
};

const getOneTimePreselectData = (activeAmounts, preselectOneTimeAmount) => {
    const oneTimePreselectData =
        activeAmounts.find(
            (amount) => amount.id === Number(preselectOneTimeAmount),
        ) || {};
    return oneTimePreselectData;
};

const getRecurringPreselectData = (activeAmounts, preselectRecurringAmount) => {
    const recurringPreselectData =
        activeAmounts.find(
            (amount) => amount.id === Number(preselectRecurringAmount),
        ) || {};
    return recurringPreselectData;
};

const getGivingPlanPreselectData = (activePlans, preselectPledge) => {
    const givingPlanPreselectData =
        activePlans.find((plan) => plan.id === Number(preselectPledge)) || {};
    return givingPlanPreselectData;
};

const getPreselectedGivingPlanRestrictionData = (givingPlanPreselectData) => {
    const initialGivingPlanRestrictionValues = {};

    if (!givingPlanPreselectData.restriction) {
        return {};
    }

    initialGivingPlanRestrictionValues.Standard_Restriction_Name =
        givingPlanPreselectData.restriction;
    initialGivingPlanRestrictionValues.Standard_Restriction_Id =
        givingPlanPreselectData.restrictionId;

    if (givingPlanPreselectData.subrestriction) {
        initialGivingPlanRestrictionValues.Standard_Restriction_Sub_Restriction =
            givingPlanPreselectData.subrestriction;
    }

    return initialGivingPlanRestrictionValues;
};

const buildGivingPlanInitialValues = (settings) => {
    const {
        preselectPledge,
        activePlans,
        givingPlanPreselectData,
        givingPlanSystemType,
    } = settings;

    const shouldBuildFlexiblePreselectData =
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    return {
        Selected_Plan: preselectPledge,
        Recurring_Frequency: getPreselectedPlanFrequency(
            preselectPledge,
            activePlans,
            givingPlanSystemType,
        ),
        Start_Date: givingPlanPreselectData.begin_date,
        End_Date: givingPlanPreselectData?.end_date
            ? givingPlanPreselectData.end_date
            : "",
        ...(shouldBuildFlexiblePreselectData && {
            Installment_Count:
                givingPlanPreselectData.defaultNumberOfInstallments,
        }),
        ...getPreselectedGivingPlanRestrictionData(givingPlanPreselectData),
    };
};

const getFilteredPreselectedInitialValues = (settings) => {
    const {
        shouldBuildOneTimePreselectInitialValues,
        shouldBuildRecurringPreselectInitialValues,
        shouldBuildGivingPlanPreselectInitialValues,
        preselectOneTimeAmount,
        preselectRecurringAmount,
        preselectPledge,
        activePlans,
        givingPlanPreselectData,
        givingPlanSystemType,
    } = settings;
    let preselectedInitialValues = {};
    if (shouldBuildOneTimePreselectInitialValues) {
        preselectedInitialValues.Selected_One_Time_Id = preselectOneTimeAmount;
    }
    if (shouldBuildRecurringPreselectInitialValues) {
        preselectedInitialValues.Selected_Recurring_Id =
            preselectRecurringAmount;
    }
    if (shouldBuildGivingPlanPreselectInitialValues) {
        const givingPlanInitialValues = buildGivingPlanInitialValues({
            preselectPledge,
            activePlans,
            givingPlanPreselectData,
            givingPlanSystemType,
        });
        preselectedInitialValues = {
            ...preselectedInitialValues,
            ...givingPlanInitialValues,
        };
    }
    return preselectedInitialValues;
};

/**
 * @public
 * @function getPreselectedInitialValues
 * @param {object} settings all settings needed for initial values
 * @description Determines starting values of amounts based on preselected amounts
 * @returns {string} initial values when preselect is enabled
 */
export const getPreselectedInitialValues = (settings) => {
    const { donationSettings } = settings;

    const {
        preselectAmounts = {},
        enablePreselectAmount = false,
        activeAmounts = [],
        activePlans,
        pledgeActive,
        givingPlanSystemType,
    } = donationSettings;

    if (!enablePreselectAmount) return {};

    const {
        preselectOneTimeAmount = 0,
        preselectRecurringAmount = 0,
        preselectPledge = 0,
    } = preselectAmounts;

    const oneTimePreselectData = getOneTimePreselectData(
        activeAmounts,
        preselectOneTimeAmount,
    );

    const isOneTimeAmountDisplayable = getIsPreselectDisplayable({
        preselectData: oneTimePreselectData,
        donationType: "onetime",
        donationSettings,
    });

    const recurringPreselectData = getRecurringPreselectData(
        activeAmounts,
        preselectRecurringAmount,
    );

    // access the preselected plan object to extract start and end dates / restrictions
    // currently restriction is preselected when plan does not have a restriction
    const givingPlanPreselectData = getGivingPlanPreselectData(
        activePlans,
        preselectPledge,
    );

    const isRecurringAmountDisplayable = getIsPreselectDisplayable({
        preselectData: recurringPreselectData,
        donationType: "recurring",
        donationSettings,
    });

    const isGivingPlanDisplayable = getIsPreselectGivingPlanDisplayable(
        preselectPledge,
        activePlans,
    );

    const shouldBuildOneTimePreselectInitialValues =
        isOneTimeAmountDisplayable && !!preselectOneTimeAmount;
    const shouldBuildRecurringPreselectInitialValues =
        isRecurringAmountDisplayable &&
        !!preselectRecurringAmount &&
        !pledgeActive;
    const shouldBuildGivingPlanPreselectInitialValues =
        isGivingPlanDisplayable &&
        !!preselectPledge &&
        pledgeActive &&
        activePlans?.length > 0;

    const filteredPreselectSettings = {
        shouldBuildOneTimePreselectInitialValues,
        shouldBuildRecurringPreselectInitialValues,
        shouldBuildGivingPlanPreselectInitialValues,
        preselectOneTimeAmount,
        preselectRecurringAmount,
        preselectPledge,
        activePlans,
        givingPlanPreselectData,
        givingPlanSystemType,
    };

    const preselectedInitialValues = getFilteredPreselectedInitialValues(
        filteredPreselectSettings,
    );

    const values = {
        ...preselectedInitialValues,
        ...totalsWithPreselect(
            settings,
            isOneTimeAmountDisplayable,
            isRecurringAmountDisplayable,
        ),
    };

    return values;
};
