import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { getHasEnabledActiveStandardRestrictions } from "@qgiv/donation-form";
import { selectAllDonationSettings } from "../../../../../../redux/slices/donationSettingsSlice";
import { selectRestrictions } from "../../../../../../redux/slices/restrictionSettingsSlice";
import { selectCurrentDisplay } from "../../../../../../redux/slices/configSettingsSlice";
import { selectCurrentPage } from "../../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../../redux/slices/cmsSettingsSlice";
import CreateYourOwnPlan from "./CreateYourOwnPlan";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @param {Function} props.handleChange
 * @returns {React.ReactElement} The rendered CreateYourOwnPlan component.
 */
const ConnectedCreateYourOwnPlan = ({ controlId, handleChange }) => {
    // Formik
    const { values } = useFormikContext();
    // Selectors
    const {
        restrictionGivingType,
        activePlans,
        givingPlanSystemType = null,
    } = useSelector(selectAllDonationSettings);

    const restrictions = useSelector(selectRestrictions);
    const currentDisplay = useSelector(selectCurrentDisplay);
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options } = control;
    const { cyopCustomizeLabel } = options;
    const {
        ENUMS: { ItemType, GivingPlanSystemType },
    } = constants;
    const isInstallment =
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    // Restrictions
    const hasActiveFulfillment = false;
    const hasEnabledActiveStandardRestrictions =
        getHasEnabledActiveStandardRestrictions({
            restrictions,
            hasActiveFulfillment,
            currentDisplay,
            restrictionGivingType,
        });

    const shouldDisplayRestrictions = hasEnabledActiveStandardRestrictions;

    const getCreateYourOwnPlan = useCallback(() => {
        const createYourOwnPlan =
            activePlans.find(
                (plan) =>
                    parseInt(plan.pledgeType, 10) === ItemType.CREATE_YOUR_OWN,
            ) || {};
        const hasCreateYourOwnPlan = Object.keys(createYourOwnPlan).length > 0;
        const createYourOwnPlanId = createYourOwnPlan?.id;
        const createYourOwnPlanName = createYourOwnPlan?.name;
        return {
            createYourOwnPlanId,
            hasCreateYourOwnPlan,
            createYourOwnPlanName,
        };
    }, [activePlans, ItemType.CREATE_YOUR_OWN]);

    const { createYourOwnPlanId, hasCreateYourOwnPlan, createYourOwnPlanName } =
        getCreateYourOwnPlan();

    const createYourOwnPlanSelected =
        Number(values.Selected_Plan) === Number(createYourOwnPlanId);

    return (
        hasCreateYourOwnPlan && (
            <CreateYourOwnPlan
                controlId={controlId}
                createYourOwnPlanName={createYourOwnPlanName}
                cyopCustomizeLabel={cyopCustomizeLabel}
                handleChange={handleChange}
                id={createYourOwnPlanId}
                selected={createYourOwnPlanSelected}
                shouldDisplayRestrictions={shouldDisplayRestrictions}
                isInstallment={isInstallment}
            />
        )
    );
};

export default ConnectedCreateYourOwnPlan;
