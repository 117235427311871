import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { WidgetFieldGroup } from "@qgiv/core-donor";
import { selectCmsControlById } from "../../redux/slices/cmsSettingsSlice";
import { selectAllFieldsAndGroups } from "../../redux/slices/fieldsSlice";
import { selectConfig } from "../../redux/slices/configSettingsSlice";
import { selectAllFormSettings } from "../../redux/slices/formSettingsSlice";
import { selectAllDonationSettings } from "../../redux/slices/donationSettingsSlice";
import { selectGiftDetails } from "../../redux/slices/donationDetailsSlice";

const ConnectedWidgetFieldGroupControl = ({ controlId }) => {
    // gets cms control data from redux
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);

    const config = useSelector(selectConfig);
    const formSettingsWithoutDonationSettings = useSelector(
        selectAllFormSettings,
    );

    const giftDetails = useSelector(selectGiftDetails);
    const { hasSelectedRecurringDonation, subtotal } = giftDetails;
    const donationAmountFromRedux = subtotal;

    // this is an example of things that should be handled on the specific field group level
    // to keep in mind when we get to reworking fields/field groups
    const donationSettings = useSelector(selectAllDonationSettings);
    const {
        allow_company_contributions = 1,
        requireNameForCompanyDonation,
        companyTabLabel,
        individualTabLabel,
    } = donationSettings;
    // Settings inferred for convenience
    const allowIndividualContributions =
        parseInt(allow_company_contributions, 10) < 2;
    const allowCompanyContributions =
        parseInt(allow_company_contributions, 10) > 0;

    // need to place certain settings within formSettings for consumption by SystemFieldGroupName
    // wrapped in useMemo to avoid causing settings to cause endless re-renders
    const settingsToInjectToFormSettings = useMemo(
        () => ({
            requireNameForCompanyDonation,
            companyTabLabel,
            individualTabLabel,
        }),
        [requireNameForCompanyDonation, companyTabLabel, individualTabLabel],
    );

    // These are settings that need to be passed to the new conditional logic functions
    const conditionalLogicOptions = useMemo(
        () => ({
            donationAmountFromRedux,
            hasSelectedRecurringDonation,
            usesReduxDonationAmountValue: true,
            usesReduxRecurringValue: true,
        }),
        [donationAmountFromRedux, hasSelectedRecurringDonation],
    );

    const settings = useMemo(
        () => ({
            // TODO: when invoices are connected to donationForm
            activePromiseTransaction: false,
            allFieldsAndGroups,
            allowCompanyContributions,
            allowIndividualContributions,
            conditionalLogicOptions,
            config,
            formSettings: {
                ...formSettingsWithoutDonationSettings,
                ...settingsToInjectToFormSettings,
            },
            requireNameForCompanyDonation,
            useNewConditionalLogicFunctions: true,
        }),
        [
            allFieldsAndGroups,
            allowCompanyContributions,
            allowIndividualContributions,
            conditionalLogicOptions,
            config,
            formSettingsWithoutDonationSettings,
            requireNameForCompanyDonation,
            settingsToInjectToFormSettings,
        ],
    );

    const {
        errors,
        values,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        setValues,
    } = useFormikContext();

    const componentProps = useMemo(
        () => ({
            control,
            settings,
            errors,
            setFieldValue,
            setValues,
            handleChange,
            handleBlur,
            touched,
            values,
        }),
        [
            control,
            settings,
            errors,
            setFieldValue,
            setValues,
            handleChange,
            handleBlur,
            touched,
            values,
        ],
    );

    return <WidgetFieldGroup {...componentProps} />;
};

ConnectedWidgetFieldGroupControl.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default ConnectedWidgetFieldGroupControl;
