import { constants } from "@qgiv/core-js";
import { createSelector } from "@reduxjs/toolkit";

const {
    ENUMS: {
        CmsPageStandardizer: {
            DONATION_AMOUNTS,
            DETAILS,
            ADDITIONAL_DETAILS,
            PAYMENT_DETAILS,
            CONFIRMATION,
            QGIV_EVENTS,
            SINGLE_STEP_FOOTER,
            SINGLE_STEP_FORM,
        },
        DefaultView: {
            CHOOSE_GIFT_STEP,
            DONOR_DETAILS_STEP,
            ADDITIONAL_DETAILS_STEP,
            PAYMENT_DETAILS_STEP,
            FORM_CONFIRMATION,
            SINGLE_STEP_FORM: SINGLE_STEP_FORM_VIEW,
            P2P_DONATION_AMOUNTS_STEP,
            P2P_DONATION_DONOR_DETAILS_STEP,
            P2P_DONATION_ADDITIONAL_DETAILS_STEP,
            P2P_DONATION_PAYMENT_DETAILS_STEP,
            P2P_DONATION_FORM_CONFIRMATION,
            QGIV_EVENTS: QGIV_EVENTS_VIEW,
        },
        CMS_Control,
    },
} = constants;

// as products are added to use this app,
// we will need to extend this map to include those pages
export const pageEnumMap = {
    // Standard Year Round Form
    [CHOOSE_GIFT_STEP]: DONATION_AMOUNTS,
    [DONOR_DETAILS_STEP]: DETAILS,
    [ADDITIONAL_DETAILS_STEP]: ADDITIONAL_DETAILS,
    [PAYMENT_DETAILS_STEP]: PAYMENT_DETAILS,
    [FORM_CONFIRMATION]: CONFIRMATION,
    [QGIV_EVENTS_VIEW]: QGIV_EVENTS,
    // P2P Donation Form
    [P2P_DONATION_AMOUNTS_STEP]: DONATION_AMOUNTS,
    [P2P_DONATION_DONOR_DETAILS_STEP]: DETAILS,
    [P2P_DONATION_ADDITIONAL_DETAILS_STEP]: ADDITIONAL_DETAILS,
    [P2P_DONATION_PAYMENT_DETAILS_STEP]: PAYMENT_DETAILS,
    [P2P_DONATION_FORM_CONFIRMATION]: CONFIRMATION,
    // Single Step Form
    [SINGLE_STEP_FORM_VIEW]: SINGLE_STEP_FORM,
};

export const selectAllCmsSettingsSelector = (state) => {
    const { cmsSettings } = state;

    return cmsSettings;
};

export const selectCmsPageOrderSelector = (state) => {
    const cmsSettings = selectAllCmsSettingsSelector(state);
    const { pageOrder } = cmsSettings;
    return pageOrder;
};

export const selectAllCmsPagesSelector = (state) => {
    const cmsSettings = selectAllCmsSettingsSelector(state);
    const { pages } = cmsSettings;
    return pages;
};

export const selectConfirmationFieldControlsSelector = (state) => {
    const cmsSettings = selectAllCmsSettingsSelector(state);
    const { pages } = cmsSettings;
    const { confirmationFieldControls } = pages;
    return confirmationFieldControls;
};

export const selectCmsContainersByPageSelector = (state, page) => {
    const pages = selectAllCmsPagesSelector(state);

    const containers = pages[page];
    return containers;
};

export const selectCmsControlsByPageSelector = (state, page) => {
    let containers = [];

    // -------------------------------------------------------------------------
    // NOTE: This is a special case for the single page form when viewing in CMS.
    // We need to combine all the controls from the different pages into one.
    // -------------------------------------------------------------------------
    // -------------------------------------------------------------------------
    // TODO: Add Recurring Summary once it is displayed on the new template
    // -------------------------------------------------------------------------
    const isPageToSkipOnSingleStepContainerSetup =
        page === QGIV_EVENTS || page === CONFIRMATION;

    if (
        state.config?.isCms &&
        state.appSettings?.isSinglePageForm &&
        !isPageToSkipOnSingleStepContainerSetup
    ) {
        const { pages } = state.cmsSettings;
        // -------------------------------------------------------------------------
        // NOTE: If the only control in the additional details page is the header,
        // we don't want to load the additional details page controls.
        // -------------------------------------------------------------------------
        const shouldLoadAdditionalDetailsControls =
            pages[ADDITIONAL_DETAILS][0]?.controls?.length > 1;

        // Combine all the controls for the single page form
        containers = [
            ...selectCmsContainersByPageSelector(state, DONATION_AMOUNTS),
            ...selectCmsContainersByPageSelector(state, DETAILS),
            ...selectCmsContainersByPageSelector(state, PAYMENT_DETAILS),
            ...selectCmsContainersByPageSelector(state, SINGLE_STEP_FOOTER),
        ];

        if (shouldLoadAdditionalDetailsControls) {
            containers.push(
                ...selectCmsContainersByPageSelector(state, ADDITIONAL_DETAILS),
            );
        }
    } else {
        // -------------------------------------------------------------------------
        // NOTE: Containers will be selected normally for public facing forms and
        // multi-page cms.
        // -------------------------------------------------------------------------
        containers = selectCmsContainersByPageSelector(state, page);
    }

    const controls =
        containers?.flatMap((container) => container.controls) || [];
    return controls;
};

export const selectCmsControlByIdByPageSelector = (state, id, page) => {
    const controls = selectCmsControlsByPageSelector(state, page);
    const control = controls.find((_control) => _control.id === id);
    return control;
};

// This is a non-performant function that searches all pages cms data
// use selectCmsControlByIdByPage if you are able to know the page Enum
// this is mainly used for common widgets (Heading, Text, Spacing, CustomContent)
export const selectCmsControlByIdSelector = (state, id) => {
    const pages = selectAllCmsPagesSelector(state);
    const pageKeys = Object.keys(pages);
    const containers = [];
    pageKeys.forEach((pageKey) => {
        const pageContainers = pages[pageKey];
        if (pageContainers.length > 0) {
            pageContainers.forEach((container) => {
                containers.push(container);
            });
        }
    });
    const controls = containers.flatMap((container) => container.controls);
    return controls.find((control) => control.id === id) || {};
};

// This selector will return the first control of a given type on a page
// It is used when you need data from a type of control that exists on multiple pages
// i.e. The EVENT_HEADER control
export const selectCmsControlByTypeByPageSelector = (state, type, page) => {
    const controls = selectCmsControlsByPageSelector(state, page);
    return controls.find((control) => Number(control.type) === type) || {};
};

// -------------------------------------------------------------------------
// Note: selectCmsOptionByTypeMultiplePages also has a very specific use case
// which only happens currently for the Donation Form Progress Bar.
// The selector grabs all of the same options for the same type of control
// on the pages it needs that data from.
//
// This selector no longer has a corresponding slice to avoid rerender/memoization
// warnings. It will instead act as a normal helpers.
//
// i.e. pages [1, 2, 4]; type: HEADING; option : 'title'
// returns ["section heading for 1", "section heading for 2", "section heading for 4"]
// -------------------------------------------------------------------------
// prettier-ignore
export const selectCmsOptionByTypeMultiplePagesSelector = ( type, option, state, pages ) => {
    const totalControls = [];
    pages.forEach((page) => {
        const controls = selectCmsControlsByPageSelector(state, page);
        controls.forEach((pageControl) => totalControls.push(pageControl));
    });

    const typeControls = totalControls
        .filter(
            (control) =>
                control && control.type && Number(control.type) === type,
        )
        .filter((control) => Object.keys(control.options).includes(option));
    const allOptionVersions = typeControls.map(
        (control) => control?.options?.[option],
    );
    return allOptionVersions;
};

// I want an easy way to get the hideDonationRecipientOnEventPages option from
// the Standard Donation Recipient Control for loading initial values in redux correctly.
export const selectHideDonationRecipientOnEventPagesSelector = (state) => {
    const DonationRecipientControlType =
        CMS_Control.STANDARD_DONATION_RECIPIENT;
    const control = selectCmsControlByTypeByPageSelector(
        state,
        DonationRecipientControlType,
        DONATION_AMOUNTS,
    );
    const donationRecipientControlExistsInCms = Object.keys(control).length > 0;
    if (!donationRecipientControlExistsInCms) {
        return { donationRecipientControlExistsInCms };
    }
    const { options = {} } = control;
    const { hideDonationRecipientOnEventPages = false } = options;
    return {
        donationRecipientControlExistsInCms,
        hideDonationRecipientOnEventPages,
    };
};

export const selectHasTopBottomContentByPageSelector = createSelector(
    [
        (state) => state.cmsSettings,
        (state, currentPage) => currentPage,
        (state, currentPage, isSinglePageForm) => isSinglePageForm,
    ],

    (cmsSettings, currentPage, isSinglePageForm) => {
        const topAndBottomContent = cmsSettings?.topAndBottomContent;

        const useSinglePageContent =
            isSinglePageForm &&
            currentPage !== CONFIRMATION &&
            currentPage !== QGIV_EVENTS;

        const currentPageToCheck = useSinglePageContent
            ? SINGLE_STEP_FORM
            : currentPage;

        if (topAndBottomContent) {
            const topContent = topAndBottomContent[currentPageToCheck]?.header;
            const bottomContent =
                topAndBottomContent[currentPageToCheck]?.footer;

            return {
                hasTopContent: !!topContent,
                hasBottomContent: !!bottomContent,
                useSinglePageContent,
            };
        }

        return { hasTopContent: false, hasBottomContent: false };
    },
);
