import { parse, isValid } from "date-fns";
import { log } from "@qgiv/core-js";

export const ensureValidDate = (dateString) => {
    const isValidDate = isValid(dateString);
    return isValidDate ? dateString : "";
};

// -------------------------------------------------------------------------
// NOTE: Parse from date-fns will throw crashing error if given incorrect data
//       Wrapped in a try/catch to prevent from crashing
// -------------------------------------------------------------------------
export const getDateInstanceFromString = ({ dateString }) => {
    try {
        const parsedDate = parse(dateString, "MM/dd/yyyy", new Date());
        return ensureValidDate(parsedDate);
    } catch (error) {
        log(error);
        const dateObj = dateString
            .split("/")
            .reduce((storedDateObj, currentItem, index) => {
                let key = "";
                let adjustedItem = 0;
                switch (index) {
                    // adjusted for 0 starting index in months
                    case 0:
                        key = "month";
                        adjustedItem = Number(currentItem) - 1;
                        break;
                    case 1:
                        key = "day";
                        adjustedItem = Number(currentItem);

                        break;
                    case 2:
                        key = "year";
                        adjustedItem = Number(currentItem);
                        break;
                    default:
                        break;
                }
                return {
                    ...storedDateObj,
                    [key]: adjustedItem,
                };
            }, {});
        const valueDate = new Date();
        // set month, day, year
        valueDate.setMonth(dateObj.month);
        valueDate.setDate(dateObj.day);
        valueDate.setFullYear(dateObj.year);
        return ensureValidDate(valueDate);
    }
};
