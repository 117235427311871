import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./ReceiptHeader.scss";

const ReceiptHeader = ({
    displayCancelledMessage = false,
    displayDemoMessage,
    displayInvoicedMessage,
    displayStartBillingDate,
    glyph,
    invoicedMessageText,
    receiptHeadingText,
    renderSubHeading,
    startBillingDateText,
}) => (
    <div className="receipt-header -padding-bottom--30">
        <div className="col col--12 -text--center">
            <Icon
                classNames={[
                    "receipt-header__confirmation-icon",
                    "-color--theme-primary",
                ]}
                glyph={glyph}
                type="Custom"
            />
        </div>
        <div className="col col--12 -text--center -padding-top--20">
            <h1>{receiptHeadingText}</h1>
        </div>
        <div className="col col--12 -text--center -padding-top--5">
            <span className="-type--xlarge">{renderSubHeading}</span>
        </div>
        {displayInvoicedMessage && (
            <div className="col col--12 -text--center -callout -margin-top--10">
                <p>
                    <Icon
                        classNames={[""]}
                        glyph="receipt-regular"
                        type="FontAwesome"
                    />{" "}
                    {invoicedMessageText}
                </p>
            </div>
        )}
        {displayStartBillingDate && (
            <div className="col col--12 -text--center -callout -margin-top--10">
                <p>
                    <Icon glyph="hourglass-start" type="FontAwesome" />{" "}
                    {startBillingDateText}
                </p>
            </div>
        )}
        {displayDemoMessage && (
            <div className="col col--12 -text--center -callout -margin-top--10">
                <p>
                    This is a demo transaction. Your card has not been charged.
                </p>
            </div>
        )}
        {displayCancelledMessage && (
            <div className="col col--12 -text--center -callout -margin-top--10 text-bold">
                <p>
                    <Icon glyph="ban-solid" type="FontAwesome" /> This ongoing
                    gift has been cancelled.
                </p>
            </div>
        )}
    </div>
);

ReceiptHeader.propTypes = {
    displayDemoMessage: PropTypes.bool.isRequired,
    displayInvoicedMessage: PropTypes.bool.isRequired,
    displayStartBillingDate: PropTypes.bool.isRequired,
    glyph: PropTypes.string.isRequired,
    invoicedMessageText: PropTypes.string.isRequired,
    displayCancelledMessage: PropTypes.bool,
    receiptHeadingText: PropTypes.string.isRequired,
    renderSubHeading: PropTypes.node.isRequired,
    startBillingDateText: PropTypes.string.isRequired,
};

export default ReceiptHeader;
