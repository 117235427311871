import { useEffect } from "react";

// click outside hook
/**
 * @function useOnClickOutside
 * @param {object} ref Instance of React.useRef()
 * @param {Function} handler Handler to call
 */
export default function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) return;
            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
