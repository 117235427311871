import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { WidgetSpacing } from "@qgiv/core-donor";
import { selectCmsControlById } from "../../redux/slices/cmsSettingsSlice";

const ConnectedWidgetSpacingControl = ({ controlId }) => {
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    return <WidgetSpacing control={control} />;
};

ConnectedWidgetSpacingControl.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default ConnectedWidgetSpacingControl;
