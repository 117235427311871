import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getAttributesForSubRestrictionOptionFromList,
    getRestrictionById,
    getSelectedSubRestrictionFromList,
    getSubRestrictionAriaLabel,
    getSubRestrictionDescription,
    getSubRestrictionLabel,
    getSubRestrictionsList,
    getSubRestrictionShowDescription,
    getSubRestrictionShowList,
    SubRestrictions,
} from "@qgiv/donation-form";
import { selectRestrictions } from "../../../../../redux/slices/restrictionSettingsSlice";

/**
 *
 * @returns {React.ReactElement}
 */
const ConnectedSubRestrictions = () => {
    const { handleBlur, handleChange, values } = useFormikContext();
    const restrictions = useSelector(selectRestrictions);

    // Gather data
    const restriction = getRestrictionById(
        restrictions,
        values.Standard_Restriction_Id,
    );
    const subRestrictionsList = getSubRestrictionsList(
        restrictions,
        values.Standard_Restriction_Id,
    );
    const selectedSubRestrictionFromList = getSelectedSubRestrictionFromList(
        subRestrictionsList,
        values.Standard_Restriction_Sub_Restriction,
    );
    const subRestrictionLabel = getSubRestrictionLabel(
        restrictions,
        values.Standard_Restriction_Id,
    );
    const fieldNameInFormik = "Standard_Restriction_Sub_Restriction";

    // Assemble data into props
    const ariaLabel = getSubRestrictionAriaLabel(subRestrictionLabel);
    const description = getSubRestrictionDescription(
        selectedSubRestrictionFromList,
    );
    const name = fieldNameInFormik;
    const id = fieldNameInFormik;
    const label = subRestrictionLabel;
    const showDescription = getSubRestrictionShowDescription(
        selectedSubRestrictionFromList,
    );
    const showList = getSubRestrictionShowList(restriction);
    const subRestrictionListOptions = showList
        ? subRestrictionsList.map(getAttributesForSubRestrictionOptionFromList)
        : [];
    const value = values.Standard_Restriction_Sub_Restriction;

    // Consolidate props into objects that can be associated with the
    // components that are rendered by the SubRestrictions component. The only
    // thing that we need to update the data right now is Formik's built in
    // handleChange() function.
    const subRestrictionsListProps = {
        ariaLabel,
        handleBlur,
        handleChange,
        id,
        label,
        name,
        subRestrictionListOptions,
        value,
    };

    const writeTheirOwnSubRestrictionsProps = {
        ariaLabel,
        handleBlur,
        handleChange,
        name,
        label,
        value,
    };

    const subRestrictionsProps = {
        description,
        showDescription,
        showList,
        subRestrictionsListProps,
        writeTheirOwnSubRestrictionsProps,
    };

    return <SubRestrictions {...subRestrictionsProps} />;
};

export default ConnectedSubRestrictions;
