import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    selectAppLoading,
    selectCurrentPage,
    selectIsSinglePageForm,
} from "../../redux/slices/appSettingsSlice";
import {
    selectAllCmsSettings,
    selectHasTopBottomContentByPage,
} from "../../redux/slices/cmsSettingsSlice";
import { selectConfig } from "../../redux/slices/configSettingsSlice";
import TopBottomContent from "./TopBottomContent";

/**
 *
 * @param {object} props Props
 * @param {string} props.selector Location of content, "header" or "footer"
 * @returns {React.ReactElement} TopBottomContent
 */
const ConnectedTopBottomContent = ({ selector = "" }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { SINGLE_STEP_FORM },
        },
    } = constants;
    const appLoading = useSelector(selectAppLoading);
    const { isDisplayTypeModal = false } = useSelector(selectConfig);

    const currentPage = useSelector(selectCurrentPage);
    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const cms = useSelector(selectAllCmsSettings);
    const {
        hasTopContent = false,
        hasBottomContent = false,
        useSinglePageContent = false,
    } = useSelector((state) =>
        selectHasTopBottomContentByPage(state, currentPage, isSinglePageForm),
    );

    const shouldRenderTopBottom =
        !isDisplayTypeModal &&
        cms?.topAndBottomContent &&
        (hasBottomContent || hasTopContent);

    const topBottomContentPage = useSinglePageContent
        ? SINGLE_STEP_FORM
        : currentPage;

    const currentPageContent = cms?.topAndBottomContent?.[topBottomContentPage];

    const content = currentPageContent?.[selector] || "";

    return !appLoading && shouldRenderTopBottom ? (
        <TopBottomContent content={content} />
    ) : null;
};

export default ConnectedTopBottomContent;
