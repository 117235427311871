import React from "react";
import PropTypes from "prop-types";
import ConnectedAmounts from "./Amounts";
import ConnectedDonationFrequenciesSelect from "./DonationFrequenciesSelect";
import ConnectedRecurringImpactAndDates from "./RecurringImpactAndDates";
import ConnectedDonatedGiftTextToDonate from "./ConnectedDonatedGiftTextToDonate";
import ConnectedMultiRestrictions from "./MultiRestrictions";

const DonatedGiftTabRecurring = ({
    controlId,
    isMultiRestriction,
    isStandardRecurringAmounts,
    shouldDisplayTextToDonateInterface,
    showRecurringDatesOrImpactUI,
}) => (
    <>
        {/* Donation amounts section */}
        <div className="col col--12 col--no-top-gutter">
            <ConnectedDonationFrequenciesSelect controlId={controlId} />
            {shouldDisplayTextToDonateInterface && (
                <ConnectedDonatedGiftTextToDonate controlId={controlId} />
            )}
            {isMultiRestriction && (
                <ConnectedMultiRestrictions controlId={controlId} />
            )}
            {isStandardRecurringAmounts && (
                <ConnectedAmounts controlId={controlId} isRecurringDisplay />
            )}
            {showRecurringDatesOrImpactUI && (
                <div className="grid">
                    <div className="col col--12">
                        <ConnectedRecurringImpactAndDates
                            controlId={controlId}
                        />
                    </div>
                </div>
            )}
        </div>
    </>
);

DonatedGiftTabRecurring.propTypes = {
    controlId: PropTypes.number.isRequired,
    isMultiRestriction: PropTypes.bool.isRequired,
    isStandardRecurringAmounts: PropTypes.bool.isRequired,
    shouldDisplayTextToDonateInterface: PropTypes.bool.isRequired,
    showRecurringDatesOrImpactUI: PropTypes.bool.isRequired,
};

export default DonatedGiftTabRecurring;
