import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getMultiRestrictionFieldName,
    getNameOfAmountIdField,
} from "@qgiv/donation-form";
import { selectAllDonationDetails } from "../../../../../../../redux/slices/donationDetailsSlice";
import RestrictionCardError from "./RestrictionCardError";

/**
 *
 * @param {object} props
 * @param {number} props.restrictionId
 * @returns {React.ReactElement}
 */
const ConnectedRestrictionCardError = ({ restrictionId }) => {
    const formikContext = useFormikContext();
    const donationDetails = useSelector(selectAllDonationDetails);
    const { giftDetails = {} } = donationDetails;
    const { values = {} } = formikContext;
    const { hasSelectedRecurringDonation = false } = giftDetails;

    // Get the id stored in the field that is throwing the error
    const multiRestrictionFieldName =
        getMultiRestrictionFieldName(restrictionId);
    const existingMultiRestrictionFieldValue =
        values[multiRestrictionFieldName];
    const nameOfAmountIdField = getNameOfAmountIdField(
        hasSelectedRecurringDonation,
    );
    const valueInFieldThrowingError =
        existingMultiRestrictionFieldValue[nameOfAmountIdField];
    const hasSelectedAnAmount = !!valueInFieldThrowingError;

    // If the user has selected an amount then the only reason an error could
    // be thrown is if they did not enter a value into the other amount. This
    // error text is generated here because it is easier to determine whether
    // or not an other amount has been selected here than it is to do this in
    // the validation logic
    const message = hasSelectedAnAmount
        ? "Please enter an amount "
        : "Please select an amount";

    const restrictionCardErrorProps = {
        message,
    };

    return <RestrictionCardError {...restrictionCardErrorProps} />;
};

export default ConnectedRestrictionCardError;
