import React from "react";
import PropTypes from "prop-types";
import "./DonatedGiftTextToDonate.scss";

const DonatedGiftTextToDonate = ({
    formattedAmountTextWithFrequency,
    handleEditAmountClick = () => {},
    textToDonateMessage = "Thanks for texting! Your gift is:",
}) => (
    // padding was removed from col for text to donate
    // added back in what padding would be for this iteration of the amounts
    // which is 15px
    <div className="qg-vendor-text-to-donate text-to-donate -padding-top--15">
        <p>{textToDonateMessage}</p>
        <div className="qg-vendor-text-to-donate__amount text-to-donate__amount">
            {formattedAmountTextWithFrequency}
        </div>
        <button
            className="text-to-donate__button -type--small -color--theme-primary"
            type="button"
            onClick={handleEditAmountClick}
        >
            <strong>Edit Amount</strong>
        </button>
    </div>
);

DonatedGiftTextToDonate.propTypes = {
    formattedAmountTextWithFrequency: PropTypes.string.isRequired,

    handleEditAmountClick: PropTypes.func,
    textToDonateMessage: PropTypes.string,
};

export default DonatedGiftTextToDonate;
