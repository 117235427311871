import { constants } from "@qgiv/core-js";
import {
    giftDataExtractor,
    paymentTypeExtractor,
    billingDataExtractor,
    donorAccountActionsDataExtractor,
    giftAssistExtractor,
    donorDetailsDataExtractor,
    customFieldsDataExtractor,
    standardRestrictionExtractor,
    matchingDataExtractor,
    multiRestrictionsDataExtractor,
    dedicationDataExtractor,
    donationMessageDataExtractor,
    privacyOptionsDataExtractor,
    givingPlanGiftDataExtractor,
    textToDonateDataExtractor,
    invoiceFulfillmentGiftDataExtractor,
} from "./dataExtractors";
import { sortSystemAndCustomFieldsFromValues } from "../fieldHelpers";
import { getShouldRenderMultiRestriction } from "../multiRestrictionHelpers";

const {
    ENUMS: { GivingPlanSystemType },
} = constants;

export const shapeReturnWrapper = (key, values) => ({
    [key]: { ...values },
});

// $request->post['Products'] ??= [];
// $request->post['Registrations'] ??= [];
// $request->post['Source'] ??= [];

// I don't think we care about values here since redux
// gets updated with the current state of formik when
// there is a change in the Donation Amounts Page
// but we'll keep it here for now...
export const shapeStandardDonationGiftDetails = ({
    giftDetails,
    values,
    currentDisplay,
    currentPage,
    DONATION_AMOUNTS,
    isUsingSmartAmounts,
    activeAmounts,
    hasActiveFulfillment,
    restrictionGivingType,
    restrictions,
    smsData,
    isSinglePageForm,
}) => {
    // -------------------------------------------------------------------------
    // NOTE: Need to only take values we need from selections made
    // -------------------------------------------------------------------------
    // $request->post['Donations'] ??= [
    //     [
    //         'Amount' => 50,
    //         'Donation_Type' => 'event_donation',
    //         'Entity' => 7725,
    //         'Entity_Type' => 4,
    //     ]
    // ];
    const useFormikValues = currentPage === DONATION_AMOUNTS;
    // -------------------------------------------------------------------------
    // NOTE: Submission needs access to Redux values when submitting.
    // When submitting from the Donation Amounts page, we need to include Redux
    // values with the most recent Formik values. The main reason here is that there
    // are values stored in Redux on change (Amount, hasSelectedRecurringDonation,
    // Recurring Frequency), and there are values stored on submission (dedications,
    // donation message).
    // -------------------------------------------------------------------------
    const valuesToUseOnDonationPage = { ...giftDetails, ...values };
    const valuesToUse =
        !isSinglePageForm && useFormikValues
            ? valuesToUseOnDonationPage
            : giftDetails;

    const hasMultiRestrictionValuesToSubmit = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );
    const standardValuesToSubmit = giftDataExtractor(
        valuesToUse,
        isUsingSmartAmounts,
        activeAmounts,
        hasActiveFulfillment,
    );
    const hasStandardValuesToSubmit =
        !hasMultiRestrictionValuesToSubmit &&
        Object.keys(standardValuesToSubmit).length > 0;

    // A new donation is created for every selected restriction
    if (hasMultiRestrictionValuesToSubmit) {
        const multiRestrictionValuesToSubmit = multiRestrictionsDataExtractor({
            currentDisplay,
            restrictions,
            valuesToUse,
        });

        return { Donations: [...multiRestrictionValuesToSubmit] };
    }

    // When submitting giving plans, valuesToSubmit is empty and we no longer
    // send the Donations array.
    if (hasStandardValuesToSubmit) {
        return { Donations: [{ ...standardValuesToSubmit }] };
    }

    return {};
};

export const shapeGivingPlanGiftDetails = ({
    customFlexibleGivingPlanTotal,
    giftDetails,
    givingPlanSystemType,
    hasSelectedRecurringDonation,
    Other_Recurring_Amount,
    pledgeActive,
    subtotal,
}) => {
    const valuesToUse = giftDetails;
    const { hasAcceptedRecurringUpgrade = false, Selected_Recurring_Id = "" } =
        valuesToUse;
    if (hasAcceptedRecurringUpgrade) {
        return {};
    }

    const valuesToSubmit = givingPlanGiftDataExtractor(valuesToUse);

    // pass redux subtotal as it is required by BE for flexible plans
    if (
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE &&
        pledgeActive &&
        hasSelectedRecurringDonation
    ) {
        valuesToSubmit.installmentAmount = subtotal;
        valuesToSubmit.customFlexibleGivingPlanTotal =
            Other_Recurring_Amount || customFlexibleGivingPlanTotal;
    }

    // checks for the existence of a Selected_Recurring_Id which indicates that
    // they have selected a custom amount for a Create Your Own Plan.  These
    // values are not submitted as part of the Pledge Array and are handled
    // by shapeStandardDonationGiftDetails and treated as a regular recurring donation
    const isPredefinedGivingPlan =
        Object.keys(valuesToSubmit).length > 0 &&
        givingPlanSystemType === GivingPlanSystemType.PREDEFINED &&
        !Selected_Recurring_Id;

    const isFlexibleGivingPlan =
        Object.keys(valuesToSubmit).length > 0 &&
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    let plansSubmissionObject = {};
    if (isPredefinedGivingPlan) {
        plansSubmissionObject = { Pledge: valuesToSubmit };
    }

    if (isFlexibleGivingPlan) {
        plansSubmissionObject = { Donations: [{ ...valuesToSubmit }] };
    }
    return plansSubmissionObject;
};

/**
 * @function
 * @param {object} params - The parameters object.
 * @param {object} params.giftDetails - The gift details object containing invoice fulfillment information.
 * @param {boolean} params.hasActiveFulfillment - Flag indicating if there is an active fulfillment.
 * @returns {object} The shaped invoice fulfillment gift details for submission.
 */
export const shapeInvoiceFulfillmentGiftDetails = ({
    giftDetails,
    hasActiveFulfillment,
}) => {
    if (!hasActiveFulfillment) return {};

    const valuesToSubmit = invoiceFulfillmentGiftDataExtractor(giftDetails);
    return shapeReturnWrapper("Invoice", valuesToSubmit);
};

export const shapeDonorDetails = ({
    donorDetails,
    values,
    currentPage,
    DETAILS,
    isSinglePageForm,
}) => {
    // $request->post['Personal'] ??= [
    //     'organization' => 'organization',
    //     'firstName' => 'firstName',
    //     'lastName' => 'lastName',
    //     'address1' => 'address1',
    //     'city' => 'city',
    //     'state' => 'state',
    //     'zip' => 'zip',
    //     'country' => 'country',
    //     'email' => 'testemail@q-give.com',
    //     'phone' => '1234567890',
    //     'salutation' => 'salutation',
    //     'employer' => 'employer',
    // ];
    const useFormikValues = isSinglePageForm || currentPage === DETAILS;
    const valuesToUse = useFormikValues ? values : donorDetails;

    const valuesToSubmit = donorDetailsDataExtractor(valuesToUse);
    return shapeReturnWrapper("Personal", valuesToSubmit);
};

export const shapePrivacyOptions = ({
    currentPage,
    DETAILS,
    donorDetails,
    type,
    values,
    isSinglePageForm,
}) => {
    const useFormikValues = isSinglePageForm || currentPage === DETAILS;
    const valuesToUse = useFormikValues ? values : donorDetails;
    const valuesToSubmit = privacyOptionsDataExtractor(type, valuesToUse);
    return shapeReturnWrapper("Privacy", valuesToSubmit);
};

// Currently, the values object will represent formik values from the Payment Page
// but that won't always be true once we allow the order of the pages to change.
// With that in mind, still going to check the current page, although I believe there
// will be other data to reference down the road. (Pre-sent payment data?)
export const shapePaymentDetails = ({
    payment,
    values,
    currentPage,
    PAYMENT_DETAILS,
    PaymentType,
    isSinglePageForm,
}) => {
    // $request->post['Payment'] ??= [
    //     'type' => 'card',
    //     'csc' => 123,
    //     'account' => 4111111111111111,
    //     'expiry' => [
    //         'month' => 12,
    //         'year' => 2025
    //     ],
    // ];
    const useFormikValues = isSinglePageForm || currentPage === PAYMENT_DETAILS;
    const valuesToUse = useFormikValues ? values : payment;
    const valuesToSubmit = paymentTypeExtractor(valuesToUse, PaymentType);
    return shapeReturnWrapper("Payment", valuesToSubmit);
};

export const shapeBillingDetails = ({
    billing,
    values,
    currentPage,
    PAYMENT_DETAILS,
    isSinglePageForm,
}) => {
    // $request->post['Billing'] ??= [
    //     'billingUseMailing' => true,
    // ];
    const useFormikValues = isSinglePageForm || currentPage === PAYMENT_DETAILS;
    const valuesToUse = useFormikValues ? values : billing;
    const valuesToSubmit = billingDataExtractor(valuesToUse);
    return shapeReturnWrapper("Billing", valuesToSubmit);
};

export const shapeDonorAccountActionsDetails = ({
    donorAccountActions,
    values,
    currentPage,
    PAYMENT_DETAILS,
    isSinglePageForm,
}) => {
    // $request->post['Donor'] ??= [
    //     'Donor_Account_Id' => 12344,
    //     'Password' => "",
    //     'Password_Confirm' => ""
    // ];
    const useFormikValues = isSinglePageForm || currentPage === PAYMENT_DETAILS;
    const valuesToUse = useFormikValues ? values : donorAccountActions;
    const valuesToSubmit = donorAccountActionsDataExtractor(valuesToUse);
    return shapeReturnWrapper("Donor", valuesToSubmit);
};

export const shapeMatchingGifts = ({
    hasDisplayableMatchingGiftOnThisPage,
    matching,
    values,
    isSinglePageForm,
}) => {
    // $request->post['Matching'] ??= [];

    const valuesToUse =
        isSinglePageForm || hasDisplayableMatchingGiftOnThisPage
            ? values
            : matching;
    const valuesToSubmit = matchingDataExtractor(valuesToUse);

    return shapeReturnWrapper("Matching", valuesToSubmit);
};

export const shapeDedication = ({
    hasDisplayableDedicationOnThisPage,
    dedication,
    values,
    isSinglePageForm,
}) => {
    // $request->post['Dedication'] ??= [
    //      'Has_Dedication' => true,
    //      'Dedication_Type' => 57273,
    //      'Dedication_Name' => "",
    //      'Dedication_Email' => "",
    //      'Dedication_Message' => "",
    // ];

    const valuesToUse =
        isSinglePageForm || hasDisplayableDedicationOnThisPage
            ? values
            : dedication;
    const valuesToSubmit = dedicationDataExtractor(valuesToUse);

    return shapeReturnWrapper("Dedication", valuesToSubmit);
};

export const shapeGiftAssist = ({
    values,
    currentPage,
    giftDetails,
    DONATION_AMOUNTS,
    isSinglePageForm,
}) => {
    const useFormikValues =
        isSinglePageForm || currentPage === DONATION_AMOUNTS;
    const valuesToUse = useFormikValues ? values : giftDetails;
    const giftAssistFee = giftDetails.giftAssistAmount;

    const valuesToSubmit = giftAssistExtractor(valuesToUse, giftAssistFee);

    return shapeReturnWrapper("GiftAssist", valuesToSubmit);
};

export const shapeCustomFields = ({
    allFieldsAndGroups,
    conditionalLogicOptions,
    customFields,
    customFieldValues,
    values,
    currentPage,
    ADDITIONAL_DETAILS,
    DETAILS,
    isSinglePageForm,
}) => {
    // This shape function has a unique challenge because custom fields values
    // can exist on both the Details Page and Additional Details Page
    // customFieldsValues will contain values for fields on both pages
    // where values may or may not contain custom fields values
    const valuesContainCustomFieldData =
        isSinglePageForm ||
        currentPage === ADDITIONAL_DETAILS ||
        currentPage === DETAILS;
    let valuesToUse = customFieldValues;
    // add formik values to custom field values if currentPage makes sense
    if (valuesContainCustomFieldData) {
        // details page contains non-custom donor fields
        // additional details page contains non-custom matching gift fields
        // so remove non-custom fields from dataset
        const sortedFieldValues = sortSystemAndCustomFieldsFromValues(values);
        const formikValuesToBePassedWithCustomFields =
            sortedFieldValues?.customFieldValues;
        valuesToUse = {
            ...customFieldValues,
            ...formikValuesToBePassedWithCustomFields,
        };
    }
    const valuesToSubmit = customFieldsDataExtractor({
        allFieldsAndGroups,
        conditionalLogicOptions,
        customFields,
        values: valuesToUse,
    });
    return shapeReturnWrapper("Fields", valuesToSubmit);
};

export const shapeStandardRestriction = ({
    values,
    currentPage,
    giftDetails,
    DONATION_AMOUNTS,
    isSinglePageForm,
}) => {
    // $request->post['Restriction'] ??= [
    //     'restrictionId' => 'restriction id',
    //     'restriction' => 'restriction name',
    //     'subRestriction' => 'sub restriction name',
    // ];

    const useFormikValues =
        isSinglePageForm || currentPage === DONATION_AMOUNTS;
    const valuesToUse = useFormikValues ? values : giftDetails;

    const valuesToSubmit = standardRestrictionExtractor(valuesToUse);

    return shapeReturnWrapper("Restriction", valuesToSubmit);
};

export const shapeDonationMessage = ({
    currentPage,
    DONATION_AMOUNTS,
    donationMessage,
    values,
    isSinglePageForm,
}) => {
    const useFormikValues =
        isSinglePageForm || currentPage === DONATION_AMOUNTS;
    const valuesToUse = useFormikValues ? values : donationMessage;
    const valuesToSubmit = donationMessageDataExtractor(valuesToUse);

    return valuesToSubmit;
};

export const shapeDonationRecipient = ({ selectedRecipient }) => {
    const { entity, entityType } = selectedRecipient;
    const valuesToSubmit = {
        entity,
        entityType,
    };
    return shapeReturnWrapper("Recipient", valuesToSubmit);
};

export const shapeAssociatedInfo = ({ assocInfo }) => {
    if (!assocInfo) return {};

    // $request->post['Additional'] ??= [
    //     'assocInfo' => 'string passed in via url ?info=string,
    // ];

    const valuesToSubmit = {
        assocInfo,
    };

    return shapeReturnWrapper("Additional", valuesToSubmit);
};

export const shapeTextToDonate = ({ smsData, subtotal }) => {
    const valuesToSubmit = textToDonateDataExtractor({ smsData, subtotal });
    const hasTextToDonateValues = Object.keys(valuesToSubmit).length > 0;
    return hasTextToDonateValues
        ? shapeReturnWrapper("Text_To_Donate", valuesToSubmit)
        : {};
};

export const shapeAbandonGift = (abandonedGiftToken) => {
    if (abandonedGiftToken) {
        const valuesToSubmit = { qgiv_abandoned_gift: abandonedGiftToken };
        return shapeReturnWrapper("AbandonedGift", valuesToSubmit);
    }
    return {};
};

export const prepareValuesForSubmit = ({
    abandonedGiftToken,
    activeAmounts,
    allFieldsAndGroups,
    assocInfo,
    conditionalLogicOptions,
    currentDisplay,
    currentPage,
    customFields,
    donationDetails, // stored redux data
    donationSettings,
    formSettings,
    hasActiveFulfillment,
    hasDisplayableDedicationOnThisPage,
    hasDisplayableMatchingGiftOnThisPage,
    isUsingSmartAmounts,
    restrictions,
    submissionType,
    smsData,
    values, // current formik values from current page
    isSubmittingUsingExpressCheckout,
    isSinglePageForm,
}) => {
    const {
        ENUMS: {
            CmsPageStandardizer: {
                DONATION_AMOUNTS,
                DETAILS,
                ADDITIONAL_DETAILS,
                PAYMENT_DETAILS,
            },
            PaymentType,
        },
    } = constants;

    const { givingPlanSystemType, pledgeActive, restrictionGivingType } =
        donationSettings;
    const { G_Recaptcha_Response } = values;
    const {
        dedication = {},
        donorAccountActions = {},
        donorDetails = {},
        donationMessage = {},
        customFieldValues = {},
        giftDetails = {},
        payment = {},
        billing = {},
        matching = {},
        selectedRecipient = {},
    } = donationDetails;
    const { id, type } = formSettings;
    const {
        subtotal = 0,
        hasSelectedRecurringDonation,
        customFlexibleGivingPlanTotal,
        Other_Recurring_Amount,
    } = giftDetails;

    const formId = id;
    const isMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    const form = formId;
    const productType = type;

    const valuesToSubmit = {
        form,
        productType,
        submissionType,
        Is_Multi_Restriction: isMultiRestriction,
        Express_Checkout: isSubmittingUsingExpressCheckout, // Might need to write in a conditional return if Express_Checkout: false is an issue
        ...shapeStandardDonationGiftDetails({
            giftDetails,
            values,
            currentDisplay,
            currentPage,
            DONATION_AMOUNTS,
            isUsingSmartAmounts,
            activeAmounts,
            hasActiveFulfillment,
            restrictionGivingType,
            restrictions,
            smsData,
            isSinglePageForm,
        }),
        ...shapeGivingPlanGiftDetails({
            currentPage,
            customFlexibleGivingPlanTotal,
            DONATION_AMOUNTS,
            giftDetails,
            givingPlanSystemType,
            hasSelectedRecurringDonation,
            isSinglePageForm,
            isSubmittingUsingExpressCheckout,
            Other_Recurring_Amount,
            pledgeActive,
            subtotal,
            values,
        }),
        ...shapeInvoiceFulfillmentGiftDetails({
            giftDetails,
            hasActiveFulfillment,
            isSinglePageForm,
        }),
        ...shapeDonorDetails({
            donorDetails,
            values,
            currentPage,
            DETAILS,
            hasActiveFulfillment,
            isSinglePageForm,
        }),
        ...shapeCustomFields({
            allFieldsAndGroups,
            conditionalLogicOptions,
            customFields,
            customFieldValues,
            values,
            currentPage,
            ADDITIONAL_DETAILS,
            DETAILS,
            isSinglePageForm,
        }),
        ...shapePaymentDetails({
            payment,
            values,
            currentPage,
            PAYMENT_DETAILS,
            PaymentType,
            isSinglePageForm,
        }),
        ...shapeBillingDetails({
            billing,
            values,
            currentPage,
            PAYMENT_DETAILS,
            isSinglePageForm,
        }),
        ...shapeDonorAccountActionsDetails({
            donorAccountActions,
            values,
            currentPage,
            PAYMENT_DETAILS,
            isSinglePageForm,
        }),
        ...shapeGiftAssist({
            values,
            currentPage,
            giftDetails,
            DONATION_AMOUNTS,
            isSinglePageForm,
        }),
        ...shapeStandardRestriction({
            values,
            currentPage,
            giftDetails,
            DONATION_AMOUNTS,
            isSinglePageForm,
        }),
        ...shapeMatchingGifts({
            hasDisplayableMatchingGiftOnThisPage,
            matching,
            values,
            isSinglePageForm,
        }),
        ...(G_Recaptcha_Response && { G_Recaptcha_Response }),
        ...shapeDedication({
            hasDisplayableDedicationOnThisPage,
            dedication,
            values,
            isSinglePageForm,
        }),
        ...shapeDonationMessage({
            values,
            currentPage,
            donationMessage,
            DONATION_AMOUNTS,
            isSinglePageForm,
        }),
        ...shapeDonationRecipient({ selectedRecipient }),
        ...shapeAssociatedInfo({ assocInfo }),
        ...shapePrivacyOptions({
            currentPage,
            DETAILS,
            donorDetails,
            type,
            values,
            isSinglePageForm,
        }),
        ...shapeTextToDonate({ smsData, subtotal }),
        ...shapeAbandonGift(abandonedGiftToken),
    };

    return valuesToSubmit;
};
