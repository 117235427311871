import React from "react";
import { InlineSelect } from "@qgiv/core-donor";

/**
 * @param {object} props
 * @param {Function} props.handleBlur
 * @param {Function} props.handleChange
 * @param {object[]} props.activeDonationFrequencies
 * @param {string} props.recurringDropdownLabel
 * @param {string} props.Recurring_Frequency
 * @returns {React.ReactElement} The rendered DonationFrequencySelect component.
 */
const DonationFrequencySelect = ({
    handleBlur,
    handleChange,
    activeDonationFrequencies,
    recurringDropdownLabel,
    Recurring_Frequency,
}) => (
    // override the line height: 1.5em style of -type--large
    // display: block needed for padding to work correctly
    <span
        className="-type--large -padding-top--15"
        style={{ lineHeight: "1em", display: "block" }}
    >
        <label htmlFor="Recurring-Frequency">{recurringDropdownLabel}</label>{" "}
        {activeDonationFrequencies.length > 1 ? (
            // If more than one frequency option is available, show the dropdown
            <InlineSelect
                id="Recurring-Frequency"
                name="Recurring_Frequency"
                value={Recurring_Frequency}
                handleBlur={handleBlur}
                handleChange={handleChange}
            >
                {activeDonationFrequencies.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </InlineSelect>
        ) : (
            // Otherwise indicate gift frequency. E.g. "Give Monthly"
            <label className="frequency" htmlFor="Recurring-Frequency">
                {activeDonationFrequencies[0].label}
            </label>
        )}
    </span>
);

export default DonationFrequencySelect;
