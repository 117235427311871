export const giftPageFormikData = {
    hasSelectedRecurringDonation: false,
    Recurring_Frequency: "m",
    Selected_Recurring_Id: 103690,
    Other_Recurring_Amount: "100",
    Selected_One_Time_Id: 103690,
    Other_One_Time_Amount: "200",
    Entity: 12345,
    Entity_Type: 4,
    subtotal: 250,
    total: 250,
};

export const giftPageReduxData = {
    hasSelectedRecurringDonation: true,
    Recurring_Frequency: "m",
    Selected_Recurring_Id: 103690,
    Other_Recurring_Amount: "100",
    Selected_One_Time_Id: 103690,
    Other_One_Time_Amount: "200",
    Entity: 12345,
    Entity_Type: 4,
    subtotal: 250,
    total: 250,
};

export const giftPageGivingPlansReduxData = {
    hasSelectedRecurringDonation: true,
    Selected_One_Time_Id: "",
    Selected_Recurring_Id: "",
    Other_One_Time_Amount: "200",
    Other_Recurring_Amount: "",
    Selected_Plan: "12345",
    Start_Date: "06/23/2024",
    End_Date: "09/24/2027",
    Recurring_Frequency: "m",
    subtotal: 250,
    total: 250,
};

export const paymentFormikData = {
    Account_Number: "account number",
    Routing_Number: "routing number",
    Card_Number: "card number",
    Card_Exp_Date: "card exp date",
    Card_CVV: "card cvv",
    Apple_Pay_Token: "apple pay token",
    PayPal_Token: "paypal token",
    PayPal_Payer_ID: "paypal payer id",
    PayPal_Agreement_ID: "paypal agreement id",
    PayPal_Is_Venmo: "paypal is venmo",
    Payment_Type: 1,
};

export const paymentReduxData = {
    ...paymentFormikData,
    Payment_Type: 2,
};

export const billingFormikData = {
    Billing_Address: "billing address",
    Billing_Address_2: "billing address 2",
    Billing_City: "billing city",
    Billing_State: "billing state",
    Billing_Zip: "billing zip",
    Billing_Country: "billing country",
    Billing_Address_Use_Mailing: false,
};

export const billingReduxData = {
    Billing_Address: "billing address redux",
    Billing_Address_2: "billing address 2 redux",
    Billing_City: "billing city redux",
    Billing_State: "billing state redux",
    Billing_Zip: "billing zip redux",
    Billing_Country: "billing country redux",
    Billing_Address_Use_Mailing: false,
};

export const exampleDonationsDataSet = {
    hasAcceptedRecurringUpgrade: false,
    hasSelectedRecurringDonation: false,
    Recurring_Frequency: "m",
    Selected_Recurring_Id: 103690,
    Other_Recurring_Amount: "100",
    Selected_One_Time_Id: 103690,
    Other_One_Time_Amount: "200",
    subtotal: 250,
    total: 250,
    Entity: 12345,
    Entity_Type: 4,
};

// formik values coming form payment page will include both payment and billing data
export const examplePaymentPageDataSet = {
    Account_Number: "account number",
    Routing_Number: "routing number",
    Card_Number: "card number",
    Card_Exp_Date: "card exp date",
    Card_CVV: "card cvv",
    Apple_Pay_Token: "apple pay token",
    PayPal_Token: "paypal token",
    PayPal_Payer_ID: "paypal payer id",
    PayPal_Agreement_ID: "paypal agreement id",
    PayPal_Is_Venmo: "paypal is venmo",
    Payment_Type: 1,
    Billing_Address: "billing address",
    Billing_Address_2: "billing address 2",
    Billing_City: "billing city",
    Billing_State: "billing state",
    Billing_Zip: "billing zip",
    Billing_Country: "billing country",
    Billing_Address_Use_Mailing: false,
    Stored_Payment_ID: "stored payment id",
};

export const exampleGiftAssistDataSet = {
    ...exampleDonationsDataSet,
    Gift_Assist: true,
    giftAssistAmount: 4.5,
};

export const exampleDetailsPageDataSet = {
    First_Name: "John",
    Last_Name: "Doe",
    Email: "john.doe@q-give.com",
    Address: "207 Bartow rd.",
    Address_2: "",
    City: "Lakeland",
    State: "FL",
    Country: "US",
    Zip: "33801",
    Phone: "4075555555",
};

export const expectedDetailsPageDataResult = {
    First_Name: "John",
    Last_Name: "Doe",
    Email: "john.doe@q-give.com",
    Address: "207 Bartow rd.",
    Address_2: "",
    City: "Lakeland",
    State: "FL",
    Country: "US",
    Zip: "33801",
    Phone: "4075555555",
};

export const exampleAutomaticMatchingGiftDataSet = {
    Match_Type: 2,
    Work_Email: "work@qgiv.com",
    Employer: "Qgiv",
    Matching_Company_Name: "Qgiv, Inc.",
    Matching_Company_ID: 51,
    Matching_Parent_Raw_ID: "",
    Matching_Contact_Address_Line_1: "",
    Matching_Contact_Address_Line_2: "",
    Matching_Contact_City: "",
    Matching_Contact_State: "",
    Matching_Contact_Zip: "",
    Matching_Contact_Phone: "(855) 237-7875",
    Matching_Contact_Email: "",
};

export const exampleManualMatchingGiftDataSet = {
    Employer: "Qgiv",
    Match_Type: 1,
    Match_Gift: true,
    Matching_Amount: "35",
};

export const expectedAutomaticMatchingGiftDataResult = {
    Match_Type: 2,
    Work_Email: "work@qgiv.com",
    Employer: "Qgiv",
    Matching_Company_Name: "Qgiv, Inc.",
    Matching_Company_ID: 51,
    Matching_Parent_Raw_ID: "",
    Matching_Contact_Address_Line_1: "",
    Matching_Contact_Address_Line_2: "",
    Matching_Contact_City: "",
    Matching_Contact_State: "",
    Matching_Contact_Zip: "",
    Matching_Contact_Phone: "(855) 237-7875",
    Matching_Contact_Email: "",
};

export const expectedManualMatchingGiftDataResult = {
    Employer: "Qgiv",
    Match_Type: 1,
    Match_Gift: true,
    Matching_Amount: "35",
};

export const exampleDedicationDataSet = {
    Has_Dedication: true,
    Dedication_Type: 57273,
    Dedication_Name: "Mr. Qgiv",
    Dedication_Email: "mqgiv@q-give.com",
    Dedication_Message: "Message of dedication",
    Fields: {},
};

export const expectedDedicationDataResult = {
    Has_Dedication: true,
    Dedication_Type: 57273,
    Dedication_Name: "Mr. Qgiv",
    Dedication_Email: "mqgiv@q-give.com",
    Dedication_Message: "Message of dedication",
    Fields: {},
};
