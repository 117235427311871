/* eslint-disable react/prop-types */
import React from "react";
import { Icon } from "@qgiv/core-react";
import Button from "../../Button";

/**
 * @param {object} props - Component properties
 * @param {string} props.btnStyle - The style to apply to the button
 * @param {Function} props.handleEventButtonClick - Function to handle button click events
 * @param {boolean} props.shouldDisplayButtonIcon - Flag indicating whether to display an icon in the button
 * @param {string} props.buttonLabel - The label to display on the button
 * @returns {React.ReactElement} The WidgetEventsListItemButton component.
 */
const WidgetEventsListItemButton = ({
    btnStyle,
    handleEventButtonClick,
    shouldDisplayButtonIcon,
    buttonLabel,
}) => (
    <div className="col col--12 col--sm-4">
        <Button
            btnStyle={btnStyle}
            size="regular"
            type="button"
            onClick={handleEventButtonClick}
        >
            {shouldDisplayButtonIcon && (
                <Icon
                    glyph="tickets-solid"
                    type="Custom"
                    classNames={["-margin-right--5"]}
                />
            )}

            {buttonLabel}
        </Button>
    </div>
);

export default WidgetEventsListItemButton;
