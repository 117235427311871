import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { WidgetCustomContent } from "@qgiv/core-donor";
import { selectCmsControlById } from "../../redux/slices/cmsSettingsSlice";

const ConnectedWidgetCustomContentControl = ({ controlId }) => {
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    return <WidgetCustomContent control={control} />;
};

ConnectedWidgetCustomContentControl.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default ConnectedWidgetCustomContentControl;
