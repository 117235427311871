const getApplePayTokenInitialValue = (applePayEnabled) => {
    if (applePayEnabled) {
        return {
            Apple_Pay_Token: "",
        };
    }
    return {};
};

export default getApplePayTokenInitialValue;
