/* eslint-disable react/prop-types */
import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props - Component properties
 * @param {object} props.iconProps - Properties to pass to the Icon component
 * @param {string} props.costString - The cost of the event to display
 * @param {boolean} props.shouldDisplayCost - Flag indicating whether to display the cost
 * @returns {React.ReactElement|null} The WidgetEventsListItemCost component or null if the cost should not be displayed.
 */
const WidgetEventsListItemCost = ({
    iconProps,
    costString,
    shouldDisplayCost,
}) =>
    shouldDisplayCost ? (
        <p className="widget-events__detail">
            <Icon {...iconProps} glyph="usd-circle" />
            {costString}
        </p>
    ) : null;

export default WidgetEventsListItemCost;
