import { constants } from "@qgiv/core-js";

/**
 * @public
 * @function getSelectedOneTimeId
 * @param {object} settings all settings
 * @param {object} settings.donationSettings donation settings from initial state
 * @param {object} settings.formSettings form object from initial state
 * @param {object}  settings.urlShortcuts params passed via url to be preselected
 * @description Determines starting value of Selected_One_Time_Id
 * @returns {string} Selected One Time amount initial value
 */

export const getSelectedOneTimeId = () => {
    const defaultValue = "";

    return defaultValue;
};

/**
 * @public
 * @function getOtherOneTimeAmountInitialValue
 * @description Determines starting value of Other_One_Time_Amount
 * @returns {string} other amount initial value
 */
export const getOtherOneTimeAmountInitialValue = () => {
    const otherAmount = "";

    return otherAmount;
};

const getStandardOneTimeInitialValues = (settings) => {
    const {
        ENUMS: { ItemType },
    } = constants;

    const {
        donationSettings: { activeAmounts },
        formSettings: { donationActive },
    } = settings;

    // Filter out the Other Amount then check if there are any displayed for one Time
    const hasActiveOneTimeAmounts = activeAmounts
        ?.filter(
            (standardAmount) => standardAmount.amountType === ItemType.CUSTOM,
        )
        ?.some((oneTimeAmount) => oneTimeAmount.displayForOneTime);

    const hasActiveOneTimeOtherAmount = activeAmounts?.find(
        (otherAmount) => otherAmount.amountType === ItemType.OTHER,
    )?.displayForOneTime;

    const shouldRenderStandardOneTimeDonationInputs =
        donationActive &&
        activeAmounts?.length > 0 &&
        // add not invoice condition
        // add not multi restriction condition
        (hasActiveOneTimeAmounts || hasActiveOneTimeOtherAmount);

    const values = shouldRenderStandardOneTimeDonationInputs
        ? {
              // Donation Values
              ...(hasActiveOneTimeAmounts && {
                  Selected_One_Time_Id: getSelectedOneTimeId(settings),
              }),

              ...(hasActiveOneTimeOtherAmount && {
                  Other_One_Time_Amount:
                      getOtherOneTimeAmountInitialValue(settings),
              }),
          }
        : {};

    return values;
};

export default getStandardOneTimeInitialValues;
