import React from "react";
import PropTypes from "prop-types";
import ConnectedMatchingGift from "../../common/MatchingGift/ConnectedMatchingGift";

const MatchingGiftControl = ({ controlId }) => (
    <ConnectedMatchingGift controlId={controlId} />
);

MatchingGiftControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default MatchingGiftControl;
