import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";

const OptInCallout = ({
    displayOptInCallout = false,
    optInCallOut = "You opted-in for news and updates.",
}) =>
    displayOptInCallout ? (
        <div className="col col--12">
            <div className="-callout">
                <p>
                    <Icon
                        classNames={["-margin-right--10"]}
                        glyph="bell-solid"
                        type="FontAwesome"
                    />{" "}
                    {optInCallOut}
                </p>
            </div>
        </div>
    ) : null;

OptInCallout.propTypes = {
    displayOptInCallout: PropTypes.bool.isRequired,
    optInCallOut: PropTypes.string.isRequired,
};

export default OptInCallout;
