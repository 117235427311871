import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    FormInjectionHead,
    getInjectionsToRenderInReactApp,
} from "@qgiv/core-donor";
import { selectActiveInjections } from "../../../redux/slices/activeInjectionsSlice";
import { selectCurrentPage } from "../../../redux/slices/appSettingsSlice";
import { selectEnableAnalyticsOutsideIframe } from "../../../redux/slices/internalSettingsSlice";

const ConnectedFormInjectionHead = () => {
    const {
        ENUMS: { FormInjectionPosition },
    } = constants;
    const activeInjections = useSelector(selectActiveInjections);
    const currentPage = useSelector(selectCurrentPage);
    const enableAnalyticsOutsideIframe = useSelector(
        selectEnableAnalyticsOutsideIframe,
    );

    // Organize data so that it can be passed into the helper function
    const activeFormInjections = activeInjections;
    const currentPosition = FormInjectionPosition.HEAD;
    const currentStep = currentPage;
    const isProductThatUsesIframe = true;

    // Script, Meta, Google Analytics, Google Tag Manager and Facebook Pixel
    // injections can all be made into the document head. The favicon injection
    // is not included as it is injected into the head of the landing page
    // instead of the head of the form.
    const activeInjectionsInHead = getInjectionsToRenderInReactApp({
        activeFormInjections,
        currentPosition,
        currentStep,
        enableAnalyticsOutsideIframe,
        isProductThatUsesIframe,
    });

    const formInjectionHeadProps = {
        activeInjectionsInHead,
    };

    return <FormInjectionHead {...formInjectionHeadProps} />;
};

export default ConnectedFormInjectionHead;
