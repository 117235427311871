import constants from "../constants";
import log from "../log";
import { qfetch } from "../qfetch";

// -------------------------------------------------------------------------
// MARK: Misc.
// -------------------------------------------------------------------------
/**
 * @function getJson
 * @description Get contents of *.json file from resources/ directory
 * @param {string} path Path to *.json file (`/resources/${path}`)
 * @returns {Promise} JSON object
 */
export const getJson = (path) =>
    qfetch.get(`/resources/${path}`).catch((error) => log("error", error));

// Using window.location.host since this api is used on projects with different redux stores
// and access to state.config.HTTP_HOST would require importing those stores
export const getRegions = (country) => {
    if (country && country.length > 0) {
        const url = `https://${window.location.host}/common/countries/iso3166-2/`;
        const headers = { "Content-Type": "application/json" };
        return qfetch.get(`${url}${country.toLowerCase()}.json`, headers);
    }
    // If we do not have any country data, then that country will have no
    // region data. This is useful when the Country field is not included in
    // the form which is a valid use case for T2D forms. Mocking the Promise is
    // necessary to trigger the .then() method that contains any of the
    // relevant callbacks that need to be run.
    const responseWithNoRegionData = new Promise((resolve) => {
        resolve({ data: [] });
    });
    return responseWithNoRegionData;
};

/**
 * @function storeConstant
 * @description Adds key/value pair to constants object
 * @param {string} key Key string used to store item on constants object
 * @param {any} value Item to store on constants object
 */
export const storeConstant = (key, value) => {
    // store JSON in constants
    constants[key] = value;
    log(`Dev Log: ${key}:`, constants[key]);
};
