// -------------------------------------------------------------------------
// NOTE:
// These functions originally lived in the event app's redux helpers.js file.
// They needed to be moved to core-donor so they can be used within conditional.js
// for finding and setting information about package/ticket level fields
// or nested conditional fields.
// -------------------------------------------------------------------------

/**
 * @public
 * @function getCurrentFieldPathOptions
 * @description function to get the different options for a comparison field's fieldNamePath
 * @param {string} controlId - id for the control of the field in cms settings
 * @returns {object} comparisonFieldPath options
 */
export const getCurrentFieldPathOptions = (controlId = "") => {
    const fieldNamePathArray = controlId.replace("mockId-", "").split("-");
    return {
        packageId: fieldNamePathArray[0],
        packageIteration: fieldNamePathArray[1],
        currentFieldId: fieldNamePathArray[2],
        ...(fieldNamePathArray.length === 4 && {
            ticketIteration: fieldNamePathArray[3],
        }),
    };
};

/**
 * @public
 * @function getComparisonFieldPath
 * @description function to get the "id" of the comparison field that a conditional field
 *              is conditional on. This will be the key on formik values that we need to
 *              check against in conditional logic.
 * @param {string} controlId - id for the control of the field in cms settings
 * @param {object} comparisonField - field data for the field the conditional field is compared against
 * @returns {string} comparisonFieldPath
 */
export const getComparisonFieldPath = (controlId, comparisonField) => {
    const { eventWide, ticketWide, id } = comparisonField;
    const isTransactionLevelField = eventWide === "1";
    const isTicketLevelField = ticketWide === "1";
    // transaction level fields can just return the id of the field
    let comparisonFieldPath = id;
    if (!isTransactionLevelField) {
        // get the sections that indicate which field we are looking for compared to the
        // current field from the control

        const {
            packageId = "",
            packageIteration = "",
            ticketIteration = "",
        } = getCurrentFieldPathOptions(controlId);
        // package level fields and ticket level fields have the same
        // base that makes up their fieldNamePath
        comparisonFieldPath = `${packageId}-${packageIteration}-${id}`;
        if (isTicketLevelField) {
            comparisonFieldPath += `-${ticketIteration}`;
        }
    }

    return comparisonFieldPath;
};
