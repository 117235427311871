import React from "react";
import { PoweredByQgiv } from "@qgiv/core-react";
import {
    AcceptUpgradeButtonSectionData,
    CancelUpgradeButtonSectionData,
} from "@qgiv/donation-form";
import AcceptRecurringUpgradeButton from "./AcceptRecurringUpgradeButton";
import CancelRecurringUpgradeButton from "./CancelRecurringUpgradeButton";
import RecurringUpgradeIcon from "./RecurringUpgradeIcon";
import RecurringUpgradeHeading from "./RecurringUpgradeHeading";
import RecurringUpgradeAdditionalText from "./RecurringUpgradeAdditionalText";
import "./RecurringUpgrade.scss";

/**
 * @param {object} props component props
 * @param {CancelUpgradeButtonSectionData} props.cancelUpgradeButtonLabelSections label sections for CancelRecurringUpgradeButton
 * @param {string} props.formattedRecurringUpgradeAdditionalText text for RecurringUpgradeAdditionalText
 * @param {string} props.formattedRecurringUpgradeHeading text for RecurringUpgradeHeading
 * @param {Function} props.handleAcceptUpgradeButtonClick function to run onClick for
 * AcceptRecurringUpgradeButton components
 * @param {Function} props.handleCancelUpgradeButtonClick function to run onClick for
 * CancelRecurringUpgradeButton
 * @param {AcceptUpgradeButtonSectionData[]} props.uniqueAcceptUpgradeButtonsSectionDataArray array of label sections for
 * AcceptRecurringUpgradeButton components
 * @returns {React.ReactElement} RecurringUpgrade
 */
const RecurringUpgrade = ({
    cancelUpgradeButtonLabelSections = {},
    formattedRecurringUpgradeAdditionalText = "",
    formattedRecurringUpgradeHeading = "",
    handleAcceptUpgradeButtonClick = () => {},
    handleCancelUpgradeButtonClick = () => {},
    uniqueAcceptUpgradeButtonsSectionDataArray = [],
}) => (
    <div className="recurring-upgrade qg-vendor-recurring-upgrade">
        <RecurringUpgradeIcon />
        <RecurringUpgradeHeading
            formattedRecurringUpgradeHeading={formattedRecurringUpgradeHeading}
        />
        {formattedRecurringUpgradeAdditionalText ? (
            <RecurringUpgradeAdditionalText
                formattedRecurringUpgradeAdditionalText={
                    formattedRecurringUpgradeAdditionalText
                }
            />
        ) : null}
        {/* eslint-disable-next-line tailwindcss/classnames-order */}
        <div className="grid -margin-top--15">
            {/* map through upgrade "fields" */}
            {uniqueAcceptUpgradeButtonsSectionDataArray.map(
                (acceptUpgradeButtonSectionData) => (
                    <div
                        className="col col--12"
                        key={acceptUpgradeButtonSectionData.id}
                    >
                        <AcceptRecurringUpgradeButton
                            handleAcceptUpgradeButtonClick={
                                handleAcceptUpgradeButtonClick
                            }
                            acceptUpgradeButtonSectionData={
                                acceptUpgradeButtonSectionData
                            }
                        />
                    </div>
                ),
            )}
            <div className="col col--12">
                <CancelRecurringUpgradeButton
                    cancelUpgradeButtonLabelSections={
                        cancelUpgradeButtonLabelSections
                    }
                    handleCancelUpgradeButtonClick={
                        handleCancelUpgradeButtonClick
                    }
                />
            </div>
            <div className="col col--12">
                <PoweredByQgiv />
            </div>
        </div>
    </div>
);

export default RecurringUpgrade;
