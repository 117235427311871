import React from "react";
import PropTypes from "prop-types";
import { PaymentPageContainers } from "../../../Containers";

const PaymentPageContent = ({ containers = [] }) => {
    const paymentPageContentProps = {
        containers,
    };

    return <PaymentPageContainers {...paymentPageContentProps} />;
};

PaymentPageContent.propTypes = {
    containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PaymentPageContent;
