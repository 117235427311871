import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectMessages,
    setMessages,
} from "../../../redux/slices/appSettingsSlice";
import MessageDisplay from "./MessageDisplay";

const ConnectedMessageDisplay = () => {
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setMessages([]));
    };
    const messages = useSelector(selectMessages);

    const messageProps = {
        handleClose,
        messages,
    };

    return <MessageDisplay {...messageProps} />;
};

export default ConnectedMessageDisplay;
