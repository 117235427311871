import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ImageSliderContent from "./ImageSliderContent";
import ImageThumbnail from "./ImageThumbnail";
import ImageSliderContext from "./ImageSliderContext";
import "./ImageSlider.scss";

const ImageSlider = ({ activeImageKey, onToggle, children, ...otherProps }) => {
    const context = useMemo(
        () => ({
            activeImageKey,
            onToggle,
        }),
        [activeImageKey, onToggle],
    );

    return (
        <ImageSliderContext.Provider value={context}>
            <div className="image-slider" {...otherProps}>
                {children}
            </div>
        </ImageSliderContext.Provider>
    );
};

ImageSlider.default = {
    onToggle: () => {},
    activeImageKey: 0,
};

ImageSlider.Content = ImageSliderContent;
ImageSlider.Thumbnails = ImageThumbnail;

ImageSlider.propTypes = {
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    children: PropTypes.array,
    // keys of the image which is active
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    activeImageKey: PropTypes.number,
    // onToggle callback.
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    onToggle: PropTypes.func,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    otherProps: PropTypes.object,
};

export default ImageSlider;
