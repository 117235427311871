import { createSlice } from "@reduxjs/toolkit";
import { constants } from "@qgiv/core-js";
import { smsData as initialState } from "../initialState";
import { configInitialState as config } from "./configSettingsSlice";

const getSmsDataInitialState = () => {
    const {
        ENUMS: {
            DisplayOn: { TEXT_TO_DONATE },
        },
    } = constants;
    const { currentDisplay = 0 } = config;
    const isTextToDonate = currentDisplay === TEXT_TO_DONATE;

    // need flag for if smsData amount is 0
    // or user has clicked edit amount
    // which should display the regular amounts system

    const shouldDisplayTextToDonateInterface =
        isTextToDonate && initialState?.amount > 0;

    return {
        ...initialState,
        shouldDisplayTextToDonateInterface,
        isTextToDonate,
    };
};

export const smsDataInitialState = getSmsDataInitialState();

export const slice = createSlice({
    name: "smsData",
    initialState: smsDataInitialState,
    reducers: {
        setSmsData: (state, action) => action.payload,
        updateShouldDisplayTextToDonateInterface: (state, action) => {
            state.shouldDisplayTextToDonateInterface = action.payload;
        },
    },
});

// actions
export const { setSmsData, updateShouldDisplayTextToDonateInterface } =
    slice.actions;

// selectors
export const selectSmsData = (state) => state.smsData;

export const selectIsTextToDonate = (state) => state.smsData.isTextToDonate;

export const selectShouldDisplayTextToDonateInterface = (state) =>
    state.smsData.shouldDisplayTextToDonateInterface;

export default slice.reducer;
