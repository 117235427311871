export const defaultNullPreselect = {
    activePlans: [],
    enablePreselectAmount: false,
    preselectAmounts: null,
};

export const defaultEmptyPreselect = {
    activePlans: [],
    enablePreselectAmount: false,
    preselectAmounts: {},
};

export const defaultDisabledWithData = {
    ...defaultEmptyPreselect,
    activePlans: [],
    preselectAmounts: {
        preselectOneTimeAmount: 1234,
        preselectRecurringAmount: 1236,
    },
};

export const defaultEnabledWithData = {
    ...defaultDisabledWithData,
    activePlans: [],
    enablePreselectAmount: true,
};

export const defaultEnabledWithGivingPlanData = {
    preselectAmounts: {
        preselectPledge: 12345,
        preselectOneTimeAmount: "",
        preselectRecurringAmount: "",
    },
    activePlans: [
        {
            amount: 100,
            begin_date: "2024-07-25",
            frequencyEnum: "m",
            frequencyUnit: "month",
            id: 12345,
            name: "Preselected Test Plan",
            restriction: "",
            restrictionId: 0,
            subrestriction: "",
        },
        {
            amount: 50,
            begin_date: "2024-07-25",
            frequencyEnum: "m",
            frequencyUnit: "month",
            id: 54321,
            name: "Not Selected Test Plan",
            restriction: "",
            restrictionId: 0,
            subrestriction: "",
        },
    ],
};

export const preselectedPlanHasBeenDisabledData = {
    preselectAmounts: {
        preselectPledge: 12345,
        preselectOneTimeAmount: "",
        preselectRecurringAmount: "",
    },
    activePlans: [
        {
            amount: 100,
            begin_date: "2024-07-25",
            frequencyEnum: "m",
            frequencyUnit: "month",
            id: 55555,
            name: "Preselected Test Plan",
            restriction: "",
            restrictionId: 0,
            subrestriction: "",
        },
        {
            amount: 50,
            begin_date: "2024-07-25",
            frequencyEnum: "m",
            frequencyUnit: "month",
            id: 54321,
            name: "Not Selected Test Plan",
            restriction: "",
            restrictionId: 0,
            subrestriction: "",
        },
    ],
};

export const preselectOneTimeAndGivingPlan = {
    preselectAmounts: {
        preselectPledge: 12345,
        preselectOneTimeAmount: 1234,
        preselectRecurringAmount: 4321,
    },
    activePlans: [
        {
            amount: 100,
            begin_date: "2024-07-25",
            frequencyEnum: "m",
            frequencyUnit: "month",
            id: 55555,
            name: "Preselected Test Plan",
            restriction: "",
            restrictionId: 0,
            subrestriction: "",
        },
        {
            amount: 50,
            begin_date: "2024-07-25",
            frequencyEnum: "m",
            frequencyUnit: "month",
            id: 54321,
            name: "Not Selected Test Plan",
            restriction: "",
            restrictionId: 0,
            subrestriction: "",
        },
    ],
};

export const oneTimeAndGivingPlanPreselectSettings = {
    formSettings: {
        enableGiftAssistByDefault: true,
        feeCoverage: 2,
        feeCoverageFlat: 1,
        feeCoveragePercentage: 0,
    },
    donationSettings: {
        givingPlanSystemType: 1,
        activeAmounts: [
            {
                id: 1234,
                amount: 50,
                amountType: 1,
                displayForOneTime: true,
                displayForRecurring: true,
            },
        ],
        enablePreselectAmount: true,
        pledgeActive: true,
        preselectAmounts: {
            preselectPledge: 12345,
            preselectOneTimeAmount: 1234,
            preselectRecurringAmount: 9111,
        },
        activePlans: [
            {
                amount: 100,
                begin_date: "2024-07-25",
                frequencyEnum: "m",
                frequencyUnit: "month",
                id: 55555,
                name: "Not Selected Test Plan",
                restriction: "",
                restrictionId: 0,
                subrestriction: "",
            },
            {
                amount: 150,
                begin_date: "2024-07-25",
                frequencyEnum: "m",
                frequencyUnit: "month",
                id: 12345,
                name: "Selected Test Plan",
                restriction: "",
                restrictionId: 0,
                subrestriction: "",
                end_date: "2024-12-25",
            },
        ],
    },
    hasSelectedRecurringDonation: false,
};

// dummy data simulating the case that the pre selected one time amount has been disabled
// and there are no displayable one time amounts. In this case we default to the Recurring Tab
// and the giving plan totals should be reflected.
export const oneTimeDisplayDisabledDefaultToGivingPlanPreselectSettings = {
    formSettings: {
        enableGiftAssistByDefault: true,
        feeCoverage: 2,
        feeCoverageFlat: 1,
        feeCoveragePercentage: 0,
    },
    donationSettings: {
        givingPlanSystemType: 1,
        activeAmounts: [
            {
                id: 1234,
                amount: 50,
                amountType: 1,
                displayForOneTime: false,
                displayForRecurring: true,
            },
        ],
        enablePreselectAmount: true,
        pledgeActive: true,
        preselectAmounts: {
            preselectPledge: 12345,
            preselectOneTimeAmount: 1234,
            preselectRecurringAmount: 9111,
        },
        activePlans: [
            {
                amount: 100,
                begin_date: "2024-07-25",
                frequencyEnum: "m",
                frequencyUnit: "month",
                id: 55555,
                name: "Not Selected Test Plan",
                restriction: "",
                restrictionId: 0,
                subrestriction: "",
            },
            {
                amount: 150,
                begin_date: "2024-07-25",
                frequencyEnum: "m",
                frequencyUnit: "month",
                id: 12345,
                name: "Selected Test Plan",
                restriction: "",
                restrictionId: 0,
                subrestriction: "",
                end_date: "2024-12-25",
            },
        ],
    },
    hasSelectedRecurringDonation: false,
};

export const oneTimeAndGivingPlanPreSelectedExpectedValues = {
    End_Date: "2024-12-25",
    Recurring_Frequency: "m",
    Selected_One_Time_Id: 1234,
    Selected_Plan: 12345,
    Start_Date: "2024-07-25",
    giftAssistAmount: 1,
    subtotal: 50,
    total: 51,
};

export const oneTimeDisplayDisabledDefaultToGivingPlanPreSelectedExpectedValues =
    {
        End_Date: "2024-12-25",
        Recurring_Frequency: "m",
        Selected_Plan: 12345,
        Start_Date: "2024-07-25",
        giftAssistAmount: 1,
        subtotal: 150,
        total: 151,
    };
