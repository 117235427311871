import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectFormStatusType } from "../../../redux/slices/appSettingsSlice";
import { selectConfig } from "../../../redux/slices/configSettingsSlice";
import FormStatus from "./FormStatus";
import { selectEventClosedMessaging } from "../../../redux/slices/eventSettingsSlice";

const ConnectedFormStatus = () => {
    const {
        ENUMS: { DonationStatusType },
    } = constants;
    const currentFormStatusType = useSelector(selectFormStatusType);
    const closedMessage = useSelector(selectEventClosedMessaging);

    const config = useSelector(selectConfig);

    const { HTTP_HOST } = config;

    let iconProps = {};
    let message = "";
    let hasSignInText = false;
    let hasBackToFundraiserLink = false;

    const getFormattedClosedMessage = () => {
        const hasPTag = closedMessage.includes("<p>");

        if (!hasPTag) return closedMessage;

        const formattedMessage = closedMessage.replaceAll(
            "<p",
            '<p class="ignore-typography"',
        );

        return formattedMessage;
    };

    const formattedClosedMessage = getFormattedClosedMessage();

    const defaultYearRoundClosedMessage = (
        <>
            This form has been closed.
            <br />
            Check back later!
        </>
    );

    // Assign values to above variables based on formStatusType
    switch (currentFormStatusType) {
        case DonationStatusType.FORM_CLOSED:
        case DonationStatusType.DONATIONS_UNAVAILABLE:
            hasSignInText = false;
            hasBackToFundraiserLink = false;
            iconProps = { type: "FontAwesome", glyph: "box-heart-solid" };
            break;
        case DonationStatusType.EVENT_CLOSED:
            // We are currently matching P2P registration which displays the same closed message whether it is
            // form status or event status closing the form.  This may change once this form is used for the
            // Year round form and other products
            hasSignInText = false;
            hasBackToFundraiserLink = true;
            iconProps = { type: "FontAwesome", glyph: "box-heart-solid" };
            break;
        default:
            iconProps = {
                type: "FontAwesome",
                glyph: "exclamation-circle-solid",
            };
            hasSignInText = false;
            break;
    }

    // Render message based on Form Status Type
    const renderMessage = () => {
        switch (currentFormStatusType) {
            case DonationStatusType.EVENT_CLOSED:
                if (closedMessage) {
                    message = (
                        <span
                            className="-user-html-content"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: formattedClosedMessage,
                            }}
                        />
                    );
                } else {
                    // defaulting here.
                    message = <>This campaign is currently closed.</>;
                }
                break;
            case DonationStatusType.FORM_CLOSED:
            case DonationStatusType.DONATIONS_UNAVAILABLE:
                message = defaultYearRoundClosedMessage;
                break;
            default:
                message = <>This campaign is currently closed.</>;
                break;
        }

        return message;
    };

    const formStatusProps = {
        documentTitle: "Title",
        hasSignInText,
        hasBackToFundraiserLink,
        HTTP_HOST,
        iconProps,
        renderMessage,
    };
    return <FormStatus {...formStatusProps} />;
};

export default ConnectedFormStatus;
