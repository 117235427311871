import React, { cloneElement, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import useFocusTrap from "../../hooks/useFocusTrap";

const PopperPopOut = ({
    children,
    className,
    closePopOut,
    role = "menu",
    ...rest
}) => {
    const ref = useRef();

    useFocusTrap(
        ref,
        ["ArrowDown", "ArrowLeft", "ArrowRight", "ArrowUp", "Tab"],
        false,
    );

    return (
        <div
            className={cx("popper__pop-out", className)}
            {...rest}
            {...{ ref, role }}
        >
            {typeof children.type === "function"
                ? // if child is a React component, pass closePopOut as a prop
                  cloneElement(children, { closePopOut })
                : // otherwise just use the child element
                  children}
        </div>
    );
};

PopperPopOut.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    closePopOut: PropTypes.func,
    role: PropTypes.string,
};

export default PopperPopOut;
