import React from "react";
import PropTypes from "prop-types";
import ConnectedStandardPaymentMethods from "./ConnectedStandardPaymentMethods";
import ConnectedBillingAddress from "./BillingAddress";
import ConnectedPaymentDonorAccountContent from "../PaymentDonorAccountContent";
import ConnectedDigitalWalletPaymentOptions from "./ConnectedDigitalWalletPaymentOptions";

// Digital Wallet
// Standard Payment Methods
// Billing Address
const NewPaymentMethods = ({
    controlId,
    displayAddressAndPassword,
    displayDigitalWalletPaymentOptions,
    displayStandardPaymentMethods,
    enableDonorLogins,
    hasToggledPaymentMethodView,
}) => (
    <div>
        {/* Apple Pay, PayPal and Venmo */}
        {displayDigitalWalletPaymentOptions && (
            <div className="col col--12 -padding-left--0">
                <ConnectedDigitalWalletPaymentOptions controlId={controlId} />
            </div>
        )}
        {/* Credit card, bank and invoice */}
        {displayStandardPaymentMethods && (
            <ConnectedStandardPaymentMethods
                controlId={controlId}
                hasToggledPaymentMethodView={hasToggledPaymentMethodView}
            />
        )}
        {displayAddressAndPassword && (
            <>
                {/*
                    There are going to need to be quite a few changes which
                    are going to take place in the Fields Epic (field data, isDisplayable, etc.)
                    before billing address fields are able to be used here
                */}
                <div className="col col--12 -padding-left--0 -padding-top--30">
                    <ConnectedBillingAddress controlId={controlId} />
                </div>

                {enableDonorLogins && (
                    <ConnectedPaymentDonorAccountContent
                        controlId={controlId}
                    />
                )}
            </>
        )}
    </div>
);

NewPaymentMethods.propTypes = {
    controlId: PropTypes.number.isRequired,
    displayAddressAndPassword: PropTypes.bool.isRequired,
    displayDigitalWalletPaymentOptions: PropTypes.bool.isRequired,
    displayStandardPaymentMethods: PropTypes.bool.isRequired,
    enableDonorLogins: PropTypes.bool.isRequired,
    hasToggledPaymentMethodView: PropTypes.bool.isRequired,
};

export default NewPaymentMethods;
