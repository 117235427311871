import ReactGA from "react-ga";
import { constants, env, log } from "@qgiv/core-js";
import { trackEvent } from "@qgiv/core-react";
import { EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL } from "@qgiv/core-donor";

// -------------------------------------------------------------------------
// NOTE: We are using react-ga for loading GA and sending GA data
// @see: https://github.com/react-ga/react-ga
// -------------------------------------------------------------------------

// Flag to enable/disable analytics based on environment and host.
// Currently only enabled for production builds, and on staging/main/secure.
const isEnabled =
    env.IS_PROD() && (env.IS_STAGING_HOST() || env.IS_PROD_HOST());

/**
 * @public
 * @function trackTransaction
 * @param {object} transaction Transaction data object
 */
export const trackTransaction = (transaction) => {
    if (isEnabled) {
        const QGIV = window.QGIV || {};
        const config =
            QGIV.initialState && QGIV.initialState.config
                ? QGIV.initialState.config
                : null;
        // Helper func to check if form is embedded
        const isEmbedded = () => {
            return (
                config && config.embed && config.embed.id && config.embed.url
            );
        };
        // Eventually, we will need to check for form type.
        // For now we will always assume we're dealing with a Donation Form
        const transactionEvent = {
            action: EVENT_ACTION.PURCHASE,
            category: EVENT_CATEGORY.FORM,
            label: isEmbedded()
                ? EVENT_LABEL.EMBEDDED_DONATION
                : EVENT_LABEL.DONATION,
        };
        // Here we simply pass along transaction data formatted for GA ecommerce
        const ecommerceTransaction = {
            affiliation: transaction.form,
            id: transaction.id,
            revenue: transaction.total,
        };
        // Send event
        trackEvent(transactionEvent);
        // Create ecommerce transaction
        ReactGA.ga(
            "QgivAnalytics.ecommerce:addTransaction",
            ecommerceTransaction,
        );
        // Send ecommerce transaction
        ReactGA.ga("QgivAnalytics.ecommerce:send");
    }
};

/**
 * @public
 * @function trackFormStepView
 * @param {string|number} formStep A form step enum
 * @param {boolean} isSingleStep Flag to assume single step form version of form step.
 * @description Given a formStep enum, track a view event for that step.
 */
export const trackFormStepView = (formStep, isSingleStep = false) => {
    // Helper func to determine what label we want based on the formStep
    const getLabel = () => {
        const {
            ENUMS: { FormStep },
        } = constants;
        // If enum is zero or doesn't exist, that means it's the single step page.
        if (formStep.length === 0 || Number(formStep) < 1) {
            return EVENT_LABEL.SINGLE_STEP_DONATION_PAGE;
        } else {
            switch (Number(formStep)) {
                case FormStep.CHOOSE_GIFT:
                    return EVENT_LABEL.CHOOSE_GIFT_PAGE;
                case FormStep.YOUR_DETAILS:
                    return EVENT_LABEL.DONOR_DETAILS_PAGE;
                case FormStep.ADDITIONAL_DETAILS:
                    return EVENT_LABEL.ADDITIONAL_DETAILS_PAGE;
                case FormStep.PAYMENT_DETAILS:
                    return EVENT_LABEL.PAYMENT_DETAILS_PAGE;
                case FormStep.CONFIRMATION: {
                    // Check for flag to differentiate single step
                    // vs. multi step form as transaction funnel.
                    if (isSingleStep) {
                        return EVENT_LABEL.SINGLE_STEP_CONFIRMATION_PAGE;
                    }
                    return EVENT_LABEL.CONFIRMATION_PAGE;
                }
                default:
                    return "";
            }
        }
    };
    const label = getLabel();
    if (label.length === 0) {
        log("error", "Could not determine event label");
    } else {
        // Determine the event label
        // Send event
        trackEvent({
            category: EVENT_CATEGORY.FORM,
            action: EVENT_ACTION.VIEW,
            label,
        });
    }
};
