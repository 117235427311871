import React from "react";
import PropTypes from "prop-types";
import { useHover, useTooltip } from "../../hooks";
import { Checkbox } from "../Fields/Checkbox";
import CheckboxWithTooltip from "../Fields/CheckboxWithTooltip";

const PrivacyOptionsCheckbox = ({
    display_text,
    inputProps,
    privacyCheckboxProps,
}) => {
    const { tooltipMessage, inputId, inputName, inputValue } =
        privacyCheckboxProps;

    const deviceHasHover = useHover();

    const getTooltip = useTooltip({
        message: tooltipMessage,
        placement: "bottom",
    });

    return deviceHasHover ? (
        <Checkbox
            {...inputProps}
            id={inputId}
            name={inputName}
            checked={inputValue}
            innerPadding={false}
        >
            <span className="-padding-right--10">{display_text}</span>
            {getTooltip}
        </Checkbox>
    ) : (
        <CheckboxWithTooltip
            {...inputProps}
            id={inputId}
            name={inputName}
            label={display_text}
            checked={inputValue}
            innerPadding={false}
            tooltipContent={tooltipMessage}
            moreInfoPosition="center"
        />
    );
};

PrivacyOptionsCheckbox.propTypes = {
    display_text: PropTypes.string.isRequired,
    inputProps: PropTypes.shape({}).isRequired,
    privacyCheckboxProps: PropTypes.shape({
        tooltipMessage: PropTypes.string,
        inputId: PropTypes.string,
        inputName: PropTypes.string,
        inputValue: PropTypes.bool,
    }).isRequired,
};

export default PrivacyOptionsCheckbox;
