import { useContext } from "react";
import AccordionContext from "../components/Accordion2/AccordionContext";

// Hook
/**
 * @public
 * @function useAccordionContext
 * @description React hook for passing the activeEventKey through the component tree without
 *              having to pass props manually at every level.  Both the toggle and collapse will
 *              need to track this value
 * @returns {number} Current activeEventKey
 */
export default function useAccordionContext() {
    const context = useContext(AccordionContext);
    if (!context) {
        throw new Error(
            "Accordion components are compound components. Must be used inside Accordion.",
        );
    }
    return context;
}
