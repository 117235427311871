import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import {
    constants,
    currencyString,
    filterOutDuplicateItemsInArrayBasedOnKey,
} from "@qgiv/core-js";
import {
    getCancelUpgradeButtonLabelSections,
    getFirstNameValue,
    getFormattedRecurringUpgradeTextWithFirstName,
    getAcceptUpgradeButtonLabelSections,
    getMultiRestrictionFieldValuesOnUpgrade,
    getShouldRenderMultiRestriction,
} from "@qgiv/donation-form";
import { selectCmsControlByTypeByPage } from "../../../../redux/slices/cmsSettingsSlice";
import {
    selectDonorDetails,
    selectGiftDetails,
    updateGiftDetailsWithoutTotalCalc,
    updateSubmitDonation,
} from "../../../../redux/slices/donationDetailsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectAllDonationSettings } from "../../../../redux/slices/donationSettingsSlice";
import { selectCurrentDisplay } from "../../../../redux/slices/configSettingsSlice";
import { selectRestrictions } from "../../../../redux/slices/restrictionSettingsSlice";
import { selectSmsData } from "../../../../redux/slices/smsDataSlice";
import { updateContentToDisplay } from "../../../../redux/slices/appSettingsSlice";
import { ModalContentReplacerEnums } from "../../../common/ModalContentReplacer";
import RecurringUpgrade from "./RecurringUpgrade";

const ConnectedRecurringUpgrade = () => {
    const { ENUMS } = constants;
    const { CMS_Control, CmsPageStandardizer, FormAppearance } = ENUMS;

    const dispatch = useDispatch();

    // Single step forms need to look at formik values for First_Name
    const { values = {} } = useFormikContext();
    const formSettings = useSelector(selectAllFormSettings);
    const {
        appearance = FormAppearance.MULTIPLE,
        currency,
        feeCoverage,
        feeCoveragePercentage,
        feeCoverageFlat,
    } = formSettings;
    const currentDisplay = useSelector(selectCurrentDisplay);
    const donorDetails = useSelector(selectDonorDetails);
    const giftDetails = useSelector(selectGiftDetails);
    const restrictions = useSelector(selectRestrictions);
    const smsData = useSelector(selectSmsData);
    const { Gift_Assist, subtotal, total } = giftDetails;
    const First_Name = getFirstNameValue({ appearance, donorDetails, values });
    const feeCoverageSettings = useMemo(
        () => ({
            feeCoverage,
            feeCoverageFlat,
            feeCoveragePercentage,
        }),
        [feeCoverage, feeCoveragePercentage, feeCoverageFlat],
    );

    const donationSettings = useSelector(selectAllDonationSettings);
    const {
        activeAmounts,
        activeDonationFrequencies,
        minAmt,
        recurringUpgradeModalFields,
        restrictionGivingType,
    } = donationSettings;

    // -------------------------------------------------------------------------
    // TODO: Multi-Restrictions
    // In the existing version of PaymentStepUpgradeModal.js and UpdateRecurringAmounts
    // there are a couple of differences in how Multi-Restrictions upgrade to recurring:
    // 1. The calculation for deriving the new percentage of amounts does not Math.floor the value.
    // 2. The setting of the formik values uses a different function when accepting the upgrade.
    // This means that when we get to Multi-Restriction dev, that system is going to need
    // to be updated to work in the One Form. Since the shape of the data for Multi-Restrictions
    // is still being developed, I am just going to point out with comments where I think
    // some of those changes might live.
    // -------------------------------------------------------------------------
    const isMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    const control = useSelector((state) =>
        selectCmsControlByTypeByPage(
            state,
            CMS_Control.STANDARD_DONATION_PAYMENT_CONTENT,
            CmsPageStandardizer.PAYMENT_DETAILS,
        ),
    );
    const { options = {} } = control;
    const {
        cancelButtonLabel = "No. Keep my %AMOUNT% one-time gift.",
        upgradeButtonLabel = "Yes! Give %AMOUNTWITHFREQUENCY% instead!",
        recurringUpgradeHeading = "%NAME%, before we process your gift would you consider making it ongoing?",
        recurringUpgradeAdditionalText = "Your steady support helps us plan ahead and you can cancel anytime.",
    } = options;

    // need to format the replace tags for each label
    const formattedRecurringUpgradeHeading =
        getFormattedRecurringUpgradeTextWithFirstName({
            First_Name,
            cmsOptionText: recurringUpgradeHeading,
        });

    const formattedRecurringUpgradeAdditionalText =
        getFormattedRecurringUpgradeTextWithFirstName({
            First_Name,
            cmsOptionText: recurringUpgradeAdditionalText,
        });

    const formattedTotalAmount = currencyString(total, currency);

    const cancelUpgradeButtonLabelSections =
        getCancelUpgradeButtonLabelSections({
            cancelButtonLabel,
            formattedTotalAmount,
        });

    // TODO: Multi-restriction
    // The getUpgradeButtonLabelSections function creates the necessary pieces
    // for the AcceptRecurringUpgradeButton component for both display and
    // what data is needed to pass back via the onClick. The function is composed of
    // smaller helper functions that generate each part. I believe it makes sense to
    // create a new function to handle Multi-Restrictions, which can probably make use
    // of many of the existing smaller helper functions that can have the same functionality.
    const acceptUpgradeButtonsSectionData = recurringUpgradeModalFields.map(
        (field) =>
            getAcceptUpgradeButtonLabelSections({
                activeDonationFrequencies,
                currency,
                field,
                feeCoverageSettings,
                Gift_Assist,
                minAmt,
                subtotal,
                upgradeButtonLabel,
            }),
    );

    // remove possible duplicate options form displaying
    const uniqueAcceptUpgradeButtonsSectionDataArray =
        filterOutDuplicateItemsInArrayBasedOnKey(
            acceptUpgradeButtonsSectionData,
            "uniqueUpgradeIdentifier",
        );

    const continueToFormSubmission = () => {
        // display form
        dispatch(
            updateContentToDisplay(ModalContentReplacerEnums.DEFAULT_CONTENT),
        );
        // upgrade submit donation to true
        dispatch(updateSubmitDonation(true));
    };

    const handleCancelUpgradeButtonClick = () => {
        continueToFormSubmission();
    };

    // We need to bypass the built-in total calculation in updateGiftDetails.
    // This is because we may not have access to the Other amount's id, which
    // means that the data found in the redux store will not compute the correct
    // values for giftAssistAmount and total. We have to compute those values
    // anyway for displaying that info to the donor, so we just set those values
    // manually with a new action.
    const handleAcceptUpgradeButtonClick = ({
        newGiftAssistAmount,
        newSubtotalAmount,
        newRecurringFrequency,
        newTotalAmount,
    }) => {
        let giftDetailsToUpdate = {
            giftAssistAmount: newGiftAssistAmount,
            hasAcceptedRecurringUpgrade: true,
            hasSelectedRecurringDonation: true,
            Recurring_Frequency: newRecurringFrequency,
            subtotal: newSubtotalAmount,
            total: newTotalAmount,
        };

        // Only modifies Other Amount in order to ensure we don't run into any
        // issues if the form does not have an other amount
        if (isMultiRestriction) {
            const multiRestrictionFieldValuesOnUpgrade =
                getMultiRestrictionFieldValuesOnUpgrade({
                    activeAmounts,
                    currentDisplay,
                    giftDetails,
                    newSubtotalAmount,
                    restrictions,
                });
            giftDetailsToUpdate = {
                ...giftDetailsToUpdate,
                ...multiRestrictionFieldValuesOnUpgrade,
            };
        } else {
            giftDetailsToUpdate = {
                ...giftDetailsToUpdate,
                Other_Recurring_Amount: newSubtotalAmount,
            };
        }

        dispatch(updateGiftDetailsWithoutTotalCalc(giftDetailsToUpdate));

        setTimeout(() => {
            continueToFormSubmission();
        }, 10);
    };

    return (
        <RecurringUpgrade
            cancelUpgradeButtonLabelSections={cancelUpgradeButtonLabelSections}
            formattedRecurringUpgradeHeading={formattedRecurringUpgradeHeading}
            formattedRecurringUpgradeAdditionalText={
                formattedRecurringUpgradeAdditionalText
            }
            handleAcceptUpgradeButtonClick={handleAcceptUpgradeButtonClick}
            handleCancelUpgradeButtonClick={handleCancelUpgradeButtonClick}
            uniqueAcceptUpgradeButtonsSectionDataArray={
                uniqueAcceptUpgradeButtonsSectionDataArray
            }
        />
    );
};

export default ConnectedRecurringUpgrade;
