import React, { useState } from "react";
import { constants } from "@qgiv/core-js";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import {
    convertStartDateTag,
    getFormattedRecurringImpactStatement,
    getRecurringDatesProps,
} from "@qgiv/donation-form";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectAllDonationSettings } from "../../../../../redux/slices/donationSettingsSlice";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";
import {
    selectGiftDetails,
    updateGiftDetails,
} from "../../../../../redux/slices/donationDetailsSlice";
import RecurringImpactAndDates from "./RecurringImpactAndDates";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement|null} The rendered component or null.
 */
const ConnectedRecurringImpactAndDates = ({ controlId }) => {
    const {
        ENUMS: { GivingPlanSystemType },
    } = constants;
    const dispatch = useDispatch();
    const { errors, handleBlur, handleChange, touched, values } =
        useFormikContext();
    const { Recurring_Frequency, End_Date, Start_Date } = values;

    const { enableRecur } = useSelector(selectAllFormSettings);
    const {
        activeDonationFrequencies,
        enableEndDate,
        enableStartDate,
        recurringPlanEndDateRequired,
        givingPlanSystemType,
    } = useSelector(selectAllDonationSettings);

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );

    const isOnFlexibleGivingPlans =
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    const { hasSelectedRecurringDonation } = useSelector(selectGiftDetails);

    const {
        options: {
            displayRecurringImpactStatement,
            finePrint = "Your gift starts %STARTDATE%. Cancel anytime.",
            flexibleFinePrint = "Your gift starts %STARTDATE%.",
            recurringImpactStatement,
            recurringImpactStatementHeading,
            changeDatesLabel,
            flexibleChangeDatesLabel,
            endDateLabel,
            startDateLabel,
        },
    } = control;

    // -------------------------------------------------------------------------
    // NOTE: Recurring Impact Statement
    // -------------------------------------------------------------------------
    const displayRecurringImpactComponent =
        !!displayRecurringImpactStatement &&
        !!(
            recurringImpactStatement.length > 0 ||
            recurringImpactStatementHeading.length > 0
        );

    const formattedRecurringImpactStatement =
        getFormattedRecurringImpactStatement({
            activeDonationFrequencies,
            Recurring_Frequency,
            recurringImpactStatement,
        });

    const recurringImpactProps = {
        formattedRecurringImpactStatement,
        recurringImpactStatementHeading,
    };

    // Flexible Giving Plans uses a different fine print than Sustaining Gifts / Predefined Plans
    const currentSystemFinePrint = isOnFlexibleGivingPlans
        ? flexibleFinePrint
        : finePrint;
    // -------------------------------------------------------------------------
    // NOTE: Recurring Fine Print
    // -------------------------------------------------------------------------
    const formattedFinePrint = currentSystemFinePrint.replace(
        "%STARTDATE%",
        convertStartDateTag(Start_Date),
    );

    // -------------------------------------------------------------------------
    // NOTE: Recurring Dates
    // -------------------------------------------------------------------------
    const {
        collapsedAccordionEventKeys,
        dateFieldColumnClasses,
        displayRecurringDatesComponent,
        showToggleDateFieldsButton,
        yourDetailsEventKey,
    } = getRecurringDatesProps({
        enableEndDate,
        enableRecur,
        enableStartDate,
        hasSelectedRecurringDonation,
        recurringPlanEndDateRequired,
    });

    const [activeEventKeys, setActiveEventKeys] = useState(
        collapsedAccordionEventKeys,
    );

    // // If there is an element in the activeEventKeys array it means that the
    // // accordion has been expanded and the date fields should be displayed
    const showDateFields = activeEventKeys && activeEventKeys.length > 0;

    const currentSystemChangeDatesLabel = isOnFlexibleGivingPlans
        ? flexibleChangeDatesLabel
        : changeDatesLabel;

    const handleDateBlur = (e) => {
        const { name, value } = e.target;
        const formikUpdateValues = {
            [name]: value,
        };
        dispatch(updateGiftDetails({ ...formikUpdateValues }));
        handleBlur(e);
    };

    const recurringDatesProps = {
        // CP Settings
        enableEndDate,
        enableStartDate,
        // CMS settings
        changeDatesLabel: currentSystemChangeDatesLabel,
        endDateLabel,
        startDateLabel,
        // Formik
        End_Date,
        errors,
        handleBlur: handleDateBlur,
        handleChange,
        Start_Date,
        touched,

        // Accordion/UI
        activeEventKeys,
        dateFieldColumnClasses,
        setActiveEventKeys,
        showDateFields,
        showToggleDateFieldsButton,
        yourDetailsEventKey,
    };

    // -------------------------------------------------------------------------
    // NOTE: Shared Props
    // -------------------------------------------------------------------------
    const displayRecurringImpactAndDates =
        displayRecurringImpactComponent ||
        displayRecurringDatesComponent ||
        finePrint.length > 0;

    const displayDivider =
        displayRecurringImpactComponent && finePrint.length > 0;

    const recurringImpactAndDatesProps = {
        displayDivider,
        displayRecurringDatesComponent: true,
        displayRecurringImpactComponent,
        formattedFinePrint,
        recurringDatesProps,
        recurringImpactProps,
    };

    return displayRecurringImpactAndDates ? (
        <RecurringImpactAndDates {...recurringImpactAndDatesProps} />
    ) : null;
};

export default ConnectedRecurringImpactAndDates;
