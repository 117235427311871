import React from "react";
import PropTypes from "prop-types";

// -------------------------------------------------------------------------
// NOTE: OptionConverter was removed from this component
//
// Because optionConverter brought in form's state, we needed to pull that out of this
// high level common component. To use this component correctly, you will need to
// add a connected component that makes use of optionConverter (form) or
// formikOptionConverter (event and p2p) (lives in core-donor utility)
// -------------------------------------------------------------------------

const WidgetHeading = ({ text }) => (
    // eslint-disable-next-line tailwindcss/classnames-order
    <div className="widget-heading grid -margin-top--15">
        <div className="col col--12">
            <h3 className="qg-vendor-widget-heading">{text}</h3>
        </div>
    </div>
);

WidgetHeading.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default WidgetHeading;
