import React, { useState } from "react";
import PropTypes from "prop-types";
import AccordionPanel from "./AccordionPanel";
import "./Accordion.scss";

const Accordion = (props) => {
    const { size = "normal", children } = props;
    const _children = React.Children.toArray(children);
    const initialActiveChild =
        _children[_children.findIndex((child) => child.props.active)];
    const [activePanel, setActivePanel] = useState(
        typeof initialActiveChild !== "undefined"
            ? initialActiveChild.props.label
            : null,
    );
    const handlePanelClick = (panel) => {
        activePanel === panel ? setActivePanel(null) : setActivePanel(panel);

        if (typeof props.handlePanelClick === "function") {
            props.handlePanelClick(panel);
        }
    };
    return (
        <div className="qg-vendor-accordion accordion">
            {_children.map((child) => {
                const { label, ...restProps } = child.props;
                const accordionPanelProps = {
                    activePanel,
                    label,
                    handlePanelClick,
                    size,
                };
                if (restProps.renderLabelContent) {
                    accordionPanelProps.renderLabelContent =
                        restProps.renderLabelContent;
                }
                return (
                    <AccordionPanel key={label} {...accordionPanelProps}>
                        {_children.map((child) => {
                            if (child.props.label !== activePanel)
                                return undefined;
                            return child.props.children;
                        })}
                    </AccordionPanel>
                );
            })}
        </div>
    );
};

Accordion.propTypes = {
    size: PropTypes.string,
    children: PropTypes.any.isRequired,
    handlePanelClick: PropTypes.func,
};

export default Accordion;
