import React from "react";
import { useSelector } from "react-redux";
import {
    getActiveDisplayableRestrictions,
    getRestrictionById,
} from "@qgiv/donation-form";
import { selectAllFormSettings } from "../../../../../../../redux/slices/formSettingsSlice";
import { selectCurrentDisplay } from "../../../../../../../redux/slices/configSettingsSlice";
import { selectRestrictions } from "../../../../../../../redux/slices/restrictionSettingsSlice";
import { getCardImageProps } from "./restrictionCardImageHelpers";
import RestrictionCardImage from "./RestrictionCardImage";

/**
 *
 * @param {object} props
 * @param {number} props.restrictionId
 * @returns {React.ReactElement}
 */
const ConnectedRestrictionCardImage = ({ restrictionId }) => {
    const currentDisplay = useSelector(selectCurrentDisplay);
    const formSettings = useSelector(selectAllFormSettings);
    const restrictions = useSelector(selectRestrictions);

    // The BE hands us all active restrictions regardless of whether or not
    // they should be shown on this device
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    const restriction = getRestrictionById(
        activeDisplayableRestrictions,
        restrictionId,
    );
    const cardImageProps = getCardImageProps({ formSettings, restriction });

    const restrictionCardImageProps = {
        cardImageProps,
    };

    return <RestrictionCardImage {...restrictionCardImageProps} />;
};

export default ConnectedRestrictionCardImage;
