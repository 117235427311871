export const defaultDedication = [
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: true,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 11111,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication 1",
        weight: 0,
    },
];

export const defaultMultipleDedications = [
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: false,
        displayKiosk: false,
        displayMobile: true,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22222,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Mobile 1",
        weight: 0,
    },
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: false,
        displayKiosk: false,
        displayMobile: true,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22223,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Mobile 2",
        weight: 1,
    },
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: false,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22224,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Desktop 1",
        weight: 0,
    },
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: false,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22225,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Desktop 2",
        weight: 1,
    },
];

export const defaultMobileOnlyDedications = [
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: false,
        displayKiosk: false,
        displayMobile: true,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22222,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Mobile 1",
        weight: 0,
    },
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: false,
        displayKiosk: false,
        displayMobile: true,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22223,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Mobile 2",
        weight: 1,
    },
];

export const defaultDesktopOnlyDedications = [
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: false,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22224,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Desktop 1",
        weight: 0,
    },
    {
        created: "2024-01-16 11:29:21",
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: false,
        displayTxtToDonate: false,
        displayVT: false,
        form: 12345,
        id: 22225,
        lastAction: "2024-01-16 11:29:21",
        org: 21213,
        status: 1,
        text: "Dedication Desktop 2",
        weight: 1,
    },
];

export const basicDedicationComponentProps = {
    dedicationFieldsProps: {},
    dedicationLabel: "Dedicate this gift",
    dedicationNotificationProps: {
        Dedication_Email: "",
        Dedication_Message: "",
        errors: {},
        handleBlur: () => {},
        handleChange: () => {},
        notificationEmailLabel: "Email",
        notificationMessageLabel: "Message",
        notificationLabel: "Send a message?",
        touched: {},
    },
    dedicationTypeProps: {
        dedicationTypeLabel: "What type of dedication?",
        dedicationNameLabel: "Who do you want to recognize?",
        displayableActiveDedications: defaultMultipleDedications,
        handleBlur: () => {},
        handleChange: () => {},
        Dedication_Type: "",
        Dedication_Name: "",
        errors: {},
        touched: {},
    },
    errors: {},
    handleBlur: () => {},
    handleChange: () => {},
    Has_Dedication: true,
    hasDedicationFields: false,
    hasDedicationNotificationEnabled: false,
    touched: {},
};

export const basicSingleDedicationComponentProps = {
    dedicationFieldsProps: {},
    dedicationLabel: "Dedicate this gift",
    dedicationNotificationProps: {
        Dedication_Email: "",
        Dedication_Message: "",
        errors: {},
        handleBlur: () => {},
        handleChange: () => {},
        notificationEmailLabel: "Email",
        notificationMessageLabel: "Message",
        notificationLabel: "Send a message?",
        touched: {},
    },
    dedicationTypeProps: {
        dedicationTypeLabel: "What type of dedication?",
        dedicationNameLabel: "Who do you want to recognize?",
        displayableActiveDedications: defaultDedication,
        handleBlur: () => {},
        handleChange: () => {},
        Dedication_Type: "",
        Dedication_Name: "",
        errors: {},
        touched: {},
    },
    errors: {},
    handleBlur: () => {},
    handleChange: () => {},
    Has_Dedication: true,
    hasDedicationFields: false,
    hasDedicationNotificationEnabled: false,
    touched: {},
};

export const defaultEmptyDedicationSettings = {
    dedicationLocation: 1,
    dedications: [],
    enableDedNotif: false,
    fields: [],
};

export const defaultDedicationSettingsWithADedication = {
    ...defaultEmptyDedicationSettings,
    dedications: [
        {
            ...defaultDedication,
        },
    ],
};
