import React from "react";
import {
    getButtonStyleFromDonatedGiftControl,
    getMultiRestrictionAmountIdFieldName,
    getMultiRestrictionFieldName,
    getNameOfAmountIdField,
} from "@qgiv/donation-form";

/**
 * @typedef {import("./restrictionCardsAmountsTypes").PropsToShareAmongAmountsComponents} PropsToShareAmongAmountsComponents
 * @typedef {import("@qgiv/donation-form").ReduxTypes.CMSSettings.StandardDonationGiftContentControl} StandardDonationGiftContentControl
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationDetails.DonationDetails} DonationDetails
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 * @typedef {import("formik").FormikProps<{}>} FormikProps
 */

/**
 *
 * @param {object} settings
 * @param {Amount[]} settings.amountsToDisplayWithRestriction
 * @param {StandardDonationGiftContentControl} settings.control
 * @param {FormikProps} settings.formikContext
 * @param {DonationDetails} settings.donationDetails
 * @param {number} settings.restrictionId Id of restriction being rendered.
 * @returns {PropsToShareAmongAmountsComponents} Props to pass down to both the StandardAmountsRadioOptions
 * and OtherAmountRadioOption component.
 */
export const getPropsToShareAmongAmountsComponents = ({
    amountsToDisplayWithRestriction,
    control,
    formikContext,
    donationDetails,
    restrictionId,
}) => {
    const { giftDetails = {} } = donationDetails;
    const { values = {} } = formikContext;
    const { hasSelectedRecurringDonation = false } = giftDetails;

    const multiRestrictionFieldName =
        getMultiRestrictionFieldName(restrictionId);

    // Reassign and create variables as needed
    const buttonStyle = getButtonStyleFromDonatedGiftControl(control);
    const existingMultiRestrictionFieldValue =
        values[multiRestrictionFieldName];
    const multiRestrictionAmountIdFieldName =
        getMultiRestrictionAmountIdFieldName(
            hasSelectedRecurringDonation,
            multiRestrictionFieldName,
        );
    const nameOfAmountIdField = getNameOfAmountIdField(
        hasSelectedRecurringDonation,
    );
    const standardAndOtherAmountRefs = amountsToDisplayWithRestriction.map(() =>
        React.createRef(),
    );

    const propsToShareAmongAmountsComponents = {
        amountsToDisplayWithRestriction,
        buttonStyle,
        existingMultiRestrictionFieldValue,
        multiRestrictionAmountIdFieldName,
        multiRestrictionFieldName,
        nameOfAmountIdField,
        standardAndOtherAmountRefs,
    };

    return propsToShareAmongAmountsComponents;
};
