import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "@qgiv/core-js";
import { getDisplayableActiveDedications } from "@qgiv/donation-form";
import { selectDedicationSettings } from "../../redux/slices/dedicationSettingsSlice";
import {
    selectCurrentPage,
    updateSkipAdditionalDetailsPage,
} from "../../redux/slices/appSettingsSlice";
import {
    selectAllAdditionalDetailsPageCustomFields,
    selectHasDisplayableMatchingGiftOnPage,
} from "../../redux/slices/fieldsSlice";
import { selectCurrentDisplay } from "../../redux/slices/configSettingsSlice";
import { selectHasActiveFulfillment } from "../../redux/slices/promiseTransactionSlice";
import DonationFormPageSwitch from "./DonationFormPageSwitch";

/**
 * @function ConnectedDonationFormPageSwitch
 * @returns {React.ReactElement} The DonationFormPageSwitch component.
 */
const ConnectedDonationFormPageSwitch = () => {
    const dispatch = useDispatch();
    const dedicationSettings = useSelector(selectDedicationSettings);
    const {
        ENUMS: {
            CmsPageStandardizer: { ADDITIONAL_DETAILS },
            FieldType: { HIDDEN_FIELD },
        },
    } = constants;
    const allDisplayableCustomFieldsAdditionalDetailsPage = useSelector(
        selectAllAdditionalDetailsPageCustomFields,
    );
    const currentDisplay = useSelector(selectCurrentDisplay);
    const hasDisplayableMatchingGiftOnAdditionalDetailsPage = useSelector(
        (state) =>
            selectHasDisplayableMatchingGiftOnPage(state, ADDITIONAL_DETAILS),
    );
    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);
    const currentDonationFormPage = useSelector(selectCurrentPage);

    useEffect(() => {
        const { dedications = [], dedicationLocation = 1 } = dedicationSettings;
        const allDisplayDedications = getDisplayableActiveDedications({
            dedications,
            currentDisplay,
        });
        const dedicationDisplayOnAdditionalDetails =
            dedicationLocation === ADDITIONAL_DETAILS;
        const hasActiveDedications = allDisplayDedications.length > 0;

        const hasDedicationOnAdditionalDetails =
            hasActiveDedications && dedicationDisplayOnAdditionalDetails;

        const nonHiddenTypeDisplayableCustomFields =
            allDisplayableCustomFieldsAdditionalDetailsPage.filter(
                (field) => field.type !== HIDDEN_FIELD,
            );

        const hasCustomFieldsOnAdditionalDetailsPage =
            nonHiddenTypeDisplayableCustomFields.length > 0;

        const skipAdditionalDetailsOnLoad =
            hasActiveFulfillment ||
            (!hasDedicationOnAdditionalDetails &&
                !hasDisplayableMatchingGiftOnAdditionalDetailsPage &&
                !hasCustomFieldsOnAdditionalDetailsPage);

        if (skipAdditionalDetailsOnLoad) {
            dispatch(
                updateSkipAdditionalDetailsPage(skipAdditionalDetailsOnLoad),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DonationFormPageSwitch
            currentDonationFormPage={currentDonationFormPage}
        />
    );
};

export default ConnectedDonationFormPageSwitch;
