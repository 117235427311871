import React from "react";

/**
 * @param {object} props
 * @param {string} props.header
 * @param {string} props.additionalText
 * @returns {React.ReactElement} - The rendered InstallmentHeader component.
 */
const InstallmentHeader = ({ header, additionalText }) => (
    <div className="col col--12">
        <h3 className="margin-top--15 margin-bottom--15">{header}</h3>
        <p>{additionalText}</p>
    </div>
);

export default InstallmentHeader;
