import React from "react";
import ConnectedSinglePageFooterContent from "../SinglePageFooterContent";

/**
 * @param {object} props
 * @param {boolean} props.captcha
 * @param {Function} props.renderSinglePageContent
 * @param {boolean} props.hasCompletedFulfillment
 * @returns {React.ReactElement}
 */
const SinglePageContent = ({
    captcha,
    renderSinglePageContent,
    hasCompletedFulfillment,
}) => (
    <>
        {renderSinglePageContent()}
        {!hasCompletedFulfillment && (
            <ConnectedSinglePageFooterContent displayCaptcha={captcha} />
        )}
    </>
);

export default SinglePageContent;
