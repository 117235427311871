import React from "react";
import ConnectedMinMaxMessage from "../Amounts/ConnectedMinMaxMessage";
import ConnectedRestrictionCards from "./RestrictionCards";
import ConnectedRestrictionGroups from "./RestrictionGroups";

/**
 *
 * @param {object} props
 * @param {number} props.controlId
 * @param {boolean} props.hasMultiRestrictionGroups
 * @returns {React.ReactElement}
 */
const MultiRestrictions = ({ controlId, hasMultiRestrictionGroups }) => {
    // There is no restriction group if the cards are rendered directly
    const restrictionIdsForRestrictionGroup = [];

    // Add spacing to visually separate restriction groups from tabs above
    // In addition to this the MinMaxMessage is use differently in the
    // multi-restriction UI. This is because it is always displayed once a min
    // or a max amount is set. This is different from the normal UI where it is
    // only displayed when a donor interacts with the other amount.
    return (
        <div className="-padding-top--20">
            <fieldset name="Multi_Restrictions">
                {hasMultiRestrictionGroups ? (
                    <ConnectedRestrictionGroups controlId={controlId} />
                ) : (
                    <ConnectedRestrictionCards
                        controlId={controlId}
                        restrictionIdsForRestrictionGroup={
                            restrictionIdsForRestrictionGroup
                        }
                    />
                )}
            </fieldset>
            <ConnectedMinMaxMessage
                controlId={controlId}
                shouldConsiderDisplayOfMinMaxMessage
            />
        </div>
    );
};

export default MultiRestrictions;
