import React from "react";
import PropTypes from "prop-types";
import ReceiptTransactionSummaryRecipient from "./ReceiptTransactionSummaryRecipient";

const ReceiptTransactionSummary = ({
    dateLabel = "Registered on %TransactionDate%",
    displayTransactionId = true,
    displayRecipient = false,
    donationRecipientLabel = "",
    displayDonationMessage = false,
    donationMessageLabel = "",
    donationMessage = "",
    entityName,
    isRecurringDonation,
    recurringId = "",
    recurringIdLabel = "Recurring ID %RecurringID%",
    transactionDate,
    transactionId,
    transactionIDLabel = "Transaction %TransactionID%",
    transactionSummaryLabel = "Summary",
    transactionTmStamp,
}) => {
    return (
        <div className="col col--12">
            <h3>{transactionSummaryLabel}</h3>
            <p>
                {displayTransactionId && (
                    <>
                        {transactionIDLabel.replace(
                            "%TransactionID%",
                            `#${transactionId}`,
                        )}
                        <br />
                    </>
                )}
                {isRecurringDonation && (
                    <>
                        {recurringIdLabel.replace(
                            "%RecurringID%",
                            `#${recurringId}`,
                        )}
                        <br />
                    </>
                )}
                {dateLabel.replace(
                    "%TransactionDate%",
                    `${transactionDate} at ${transactionTmStamp}`,
                )}
                {displayRecipient && (
                    <>
                        <ReceiptTransactionSummaryRecipient
                            donationRecipientLabel={donationRecipientLabel}
                            entityName={entityName}
                        />
                    </>
                )}
                {displayDonationMessage && (
                    <>
                        <br />
                        {`${donationMessageLabel}: ${donationMessage}`}
                    </>
                )}
            </p>
        </div>
    );
};

ReceiptTransactionSummary.propTypes = {
    isRecurringDonation: PropTypes.bool,
    dateLabel: PropTypes.string,
    displayTransactionId: PropTypes.bool,
    displayRecipient: PropTypes.bool,
    donationRecipientLabel: PropTypes.string,
    displayDonationMessage: PropTypes.bool,
    donationMessageLabel: PropTypes.string,
    donationMessage: PropTypes.string,
    recurringIdLabel: PropTypes.string,
    recurringId: PropTypes.string,
    transactionDate: PropTypes.string,
    transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    transactionIDLabel: PropTypes.string,
    transactionSummaryLabel: PropTypes.string,
    transactionTmStamp: PropTypes.string,
};

export default ReceiptTransactionSummary;
