import { constants } from "@qgiv/core-js";

export const getDisplayApplePayPaymentMethod = ({ shouldDisplayApplePay }) => {
    const displayApplePayPaymentMethod = shouldDisplayApplePay;

    return displayApplePayPaymentMethod;
};

export const getDisplayPalSDKScriptProvider = ({
    enablePaypalPayments,
    payPalSDK,
}) => {
    const { paypalClient = "", paypalMerchant = "" } = payPalSDK;
    const displayPayPalSDKScriptProvider =
        enablePaypalPayments && !!paypalClient && !!paypalMerchant;

    return displayPayPalSDKScriptProvider;
};

export const getDisplayExpressCheckout = ({
    currentStep,
    settings,
    shouldDisplayApplePay,
}) => {
    const {
        ENUMS: { FieldType, FormAppearance, FormStep, Status },
    } = constants;
    const { allFieldsAndGroups = [], formSettings = {} } = settings;
    const {
        appearance = "",
        enablePaypalPayments = false,
        expressCheckout = "",
        payPalSDK = {},
    } = formSettings;

    // -------------------------------------------------------------------------
    // NOTE:
    // The expressCheckout flag that is passed from the BE contains data about
    // whether or not an org has the Express Checkout permission.
    // -------------------------------------------------------------------------
    // Whether the Express Donate setting has been toggled on or off
    const hasEnabledExpressCheckout = expressCheckout === "1";

    // Get the legal field from the list of fields and field groups
    const legalField = allFieldsAndGroups.find(
        (field) => Number(field.type) === FieldType.LEGAL,
    );

    // Generate flags that are used to determine whether or not the payment
    // methods that are supported by Express Donate should be displayed
    const displayApplePayPaymentMethod = getDisplayApplePayPaymentMethod({
        shouldDisplayApplePay,
    });
    const displayPayPalSDKScriptProvider = getDisplayPalSDKScriptProvider({
        enablePaypalPayments,
        payPalSDK,
    });

    // Generate the individual flags that will be used to create the flag that
    // will be passed to the UI
    const isChooseGiftStep = Number(currentStep) === FormStep.CHOOSE_GIFT;
    const isMultiStepForm = Number(appearance) === FormAppearance.MULTIPLE;
    const isLegalFieldActive = Number(legalField.status) === Status.ACTIVE;
    const hasPaymentMethodThatSupportsExpressCheckout =
        displayPayPalSDKScriptProvider || displayApplePayPaymentMethod;

    // Generate the flag using the data that was collected so far
    const displayExpressCheckout =
        hasEnabledExpressCheckout &&
        isChooseGiftStep &&
        isMultiStepForm &&
        !isLegalFieldActive &&
        hasPaymentMethodThatSupportsExpressCheckout;

    return displayExpressCheckout;
};
