import { Tabs } from "@qgiv/core-donor";
import React from "react";
import PropTypes from "prop-types";

const DonatedGiftFrequencyTabs = ({
    handleClick,
    getLeftElementAttributes,
    getRightElementAttributes,
}) => (
    <Tabs handleClick={handleClick} size="large">
        <section {...getLeftElementAttributes()} />
        <section {...getRightElementAttributes()} />
    </Tabs>
);

DonatedGiftFrequencyTabs.propTypes = {
    handleClick: PropTypes.func.isRequired,
    getLeftElementAttributes: PropTypes.func.isRequired,
    getRightElementAttributes: PropTypes.func.isRequired,
};

export default DonatedGiftFrequencyTabs;
