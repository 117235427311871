import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { constants } from "@qgiv/core-js";
import { useContentAutoFocus } from "@qgiv/core-donor";
import {
    selectCurrentPage,
    selectFormStatusType,
    selectShouldScrollToTop,
    setShouldScrollToTop,
} from "../../../redux/slices/appSettingsSlice";
import { getResizeMessageToSend } from "../../PostMessage/postMessage";
import DonationFormClosedPage from "../../Pages/DonationFormClosedPage/DonationFormClosedPage";
import ConnectedDonationFormPageSwitch from "../../DonationFormSharedContent/ConnectedDonationFormPageSwitch";
import ConnectedUpdateFormDataFromSessionStorage from "../../ConnectedUpdateFormDataFromSessionStorage";
import ConnectedAnalyticsEvents from "../../DonationFormSharedContent/AnalyticsEvents";
import ConnectedUpdateShouldDisplayApplePay from "../../DonationFormSharedContent/ConnectedUpdateShouldDisplayApplePay";
import "./DonationFormModalContent.scss";

// runScrollIntoView is coming in from Modal2
// to purposefully run as opposed to running from within
// a useEffect. The embedded version of DonationForm will
// not have this prop, but the default provided below will
// prevent any issues there.
const DonationFormModalContent = ({ runScrollIntoView = () => {} }) => {
    const {
        ENUMS: { DonationStatusType },
    } = constants;
    const ref = useRef();
    const dispatch = useDispatch();
    const currentPage = useSelector(selectCurrentPage);
    const formStatusType = useSelector(selectFormStatusType);
    const shouldScrollToTop = useSelector(selectShouldScrollToTop);

    useContentAutoFocus({ currentPage, ref });

    useEffect(() => {
        const stats = ref?.current?.getBoundingClientRect();
        if (stats !== undefined) {
            const { height, top } = stats;
            const total = height + top;
            getResizeMessageToSend(total);
            runScrollIntoView();
        }
    }, [currentPage, runScrollIntoView]);

    // shouldScrollToTop in appSettings can be used to trigger a scroll to top
    // from anywhere in the app
    useEffect(() => {
        if (shouldScrollToTop) {
            runScrollIntoView();
            dispatch(setShouldScrollToTop(false));
        }
    }, [runScrollIntoView, dispatch, shouldScrollToTop]);

    // we need to run an initial resize call in case mutation observer
    // does not see any changes when it is initialized.
    useEffect(() => {
        setTimeout(() => {
            const modalContainer = document.querySelector(".modal2__content");
            const modalElementStats = modalContainer.getBoundingClientRect();
            if (modalElementStats !== undefined) {
                const { height, top } = modalElementStats;
                const total = height + top;
                getResizeMessageToSend(total);
            }
        }, 500);
    }, []);

    const isDonationFormClosed =
        formStatusType !== DonationStatusType.DONATIONS_AVAILABLE;

    return (
        <main
            className={cx(
                "qg-vendor-page__content donation-form-content__modal-view",
            )}
            ref={ref}
        >
            {/* if Form State is Closed load Closed Component else load below */}
            {isDonationFormClosed ? (
                <DonationFormClosedPage />
            ) : (
                <>
                    <ConnectedDonationFormPageSwitch />
                    {/* <ConnectedUpdateCmsSettings /> */}

                    {/* Used for DataDome rerender */}
                    <ConnectedUpdateFormDataFromSessionStorage />
                    <ConnectedAnalyticsEvents />
                    <ConnectedUpdateShouldDisplayApplePay />
                </>
            )}
        </main>
    );
};

DonationFormModalContent.propTypes = {
    runScrollIntoView: PropTypes.func,
};

export default DonationFormModalContent;
