import React from "react";
import { Icon } from "@qgiv/core-react";
import { Button } from "@qgiv/core-donor";
import { AcceptUpgradeButtonSectionData } from "@qgiv/donation-form";
import "./AcceptRecurringUpgradeButton.scss";

/**
 * AcceptRecurringUpgradeButton component renders a button with an icon and text
 * to accept a recurring upgrade.
 * @param {object} props - The properties object.
 * @param {Function} props.handleAcceptUpgradeButtonClick - The function to call when the button is clicked.
 * @param {AcceptUpgradeButtonSectionData} props.acceptUpgradeButtonSectionData - The data for the upgrade button section.
 * @returns {React.ReactElement} AcceptRecurringUpgradeButton
 */
const AcceptRecurringUpgradeButton = ({
    handleAcceptUpgradeButtonClick = () => {},
    acceptUpgradeButtonSectionData = {
        afterTagTextFromSplit: "",
        appendedFrequencyLabel: "",
        beforeTagTextFromSplit: "",
        formattedNewTotalAmount: "",
        newGiftAssistAmount: 0,
        newRecurringFrequency: "",
        newSubtotalAmount: 0,
        newTotalAmount: 0,
    },
}) => {
    const {
        afterTagTextFromSplit,
        appendedFrequencyLabel,
        beforeTagTextFromSplit,
        formattedNewTotalAmount,
        newGiftAssistAmount,
        newRecurringFrequency,
        newSubtotalAmount,
        newTotalAmount,
    } = acceptUpgradeButtonSectionData;
    return (
        <div className="accept-recurring-button__icon">
            <Button
                onClick={() =>
                    handleAcceptUpgradeButtonClick({
                        newGiftAssistAmount,
                        newSubtotalAmount,
                        newRecurringFrequency,
                        newTotalAmount,
                    })
                }
            >
                <Icon
                    classNames={[
                        "-margin-right--10 accept-recurring-button__icon",
                    ]}
                    glyph="qgiv-recurring"
                    type="Custom"
                />
                {beforeTagTextFromSplit ? (
                    <span>{beforeTagTextFromSplit}</span>
                ) : null}
                <strong>{formattedNewTotalAmount}</strong>
                {appendedFrequencyLabel ? (
                    <span>{appendedFrequencyLabel}</span>
                ) : null}
                {afterTagTextFromSplit ? (
                    <span>{afterTagTextFromSplit}</span>
                ) : null}
            </Button>
        </div>
    );
};

export default AcceptRecurringUpgradeButton;
