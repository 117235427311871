export const defaultWeeklyFrequency = {
    label: "Weekly",
    labelPerAmount: "week",
    value: "w",
};

export const defaultBiWeeklyFrequency = {
    label: "Every Other Week",
    labelPerAmount: "two weeks",
    value: "b",
};

export const defaultMonthlyFrequency = {
    label: "Monthly",
    labelPerAmount: "month",
    value: "m",
};

export const defaultQuarterlyFrequency = {
    label: "Quarterly",
    labelPerAmount: "three months",
    value: "q",
};

export const defaultSemiAnnualFrequency = {
    label: "Semiannually",
    labelPerAmount: "six months",
    value: "s",
};

export const defaultAnnualFrequency = {
    label: "Annually",
    labelPerAmount: "year",
    value: "y",
};

export const defaultActiveDonationFrequencies = [
    defaultWeeklyFrequency,
    defaultBiWeeklyFrequency,
    defaultMonthlyFrequency,
    defaultQuarterlyFrequency,
    defaultSemiAnnualFrequency,
    defaultAnnualFrequency,
];

export const defaultActivePlans = [];

export const defaultDonationSettings = {
    activeDonationFrequencies: defaultActiveDonationFrequencies,
    activePlans: defaultActivePlans,
    restrictionGivingType: "amounts",
};

// As confusing as it is this is the value that is passed to the FE when MR
// giving is turned on
export const defaultDonationSettingsForMultiRestrictionGiving = {
    ...defaultDonationSettings,
    restrictionGivingType: "simple",
};
