import React from "react";
import PropTypes from "prop-types";
import "./ProcessingBackground.scss";

const ProcessingBackground = ({
    scopeToModal = false,
    scopeToIframe = false,
}) => (
    <div
        className={`processing-background${
            scopeToModal ? " processing-background--modal-scope" : ""
        }${scopeToIframe ? " processing-background--embedded-donation-form" : ""}`}
    />
);

ProcessingBackground.propTypes = {
    scopeToModal: PropTypes.bool,
};

export default ProcessingBackground;
