import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectCmsControlById } from "../../../../redux/slices/cmsSettingsSlice";
import ConfirmationFieldsHeader from "./ConfirmationFieldsHeader";

const ConnectedConfirmationFieldsHeader = ({ controlId }) => {
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );
    const { options = {} } = control;
    const { heading = "Just a few questions before you go:" } = options;

    const confirmationFieldsHeaderProps = { heading };

    return <ConfirmationFieldsHeader {...confirmationFieldsHeaderProps} />;
};

ConnectedConfirmationFieldsHeader.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedConfirmationFieldsHeader;
