import React from "react";
import PropTypes from "prop-types";
import { Select, TextField } from "@qgiv/core-donor";

const DedicationTypesSelect = ({
    Dedication_Name,
    Dedication_Type,
    dedicationNameLabel,
    dedicationTypeLabel,
    displayableActiveDedications,
    errors,
    handleBlur,
    handleChange,
    touched,
}) => (
    // eslint-disable-next-line tailwindcss/classnames-order
    <div className="grid dedications__content--type">
        <div className="col col--12 col--no-top-gutter">
            {displayableActiveDedications.length === 1 ? (
                <p>
                    <strong>{displayableActiveDedications[0].text}</strong>
                </p>
            ) : (
                <Select
                    id="Dedication_Type"
                    name="Dedication_Type"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    label={dedicationTypeLabel}
                    value={Dedication_Type}
                    aria-label="Dedication Type"
                    error={
                        touched?.Dedication_Type &&
                        errors?.Dedication_Type?.length > 0
                    }
                    errorText={errors?.Dedication_Type}
                >
                    {displayableActiveDedications.map((dedication) => (
                        <option key={dedication.id} value={dedication.id}>
                            {dedication.text}
                        </option>
                    ))}
                </Select>
            )}
        </div>
        <div className="col col--12">
            <TextField
                name="Dedication_Name"
                label={dedicationNameLabel}
                value={Dedication_Name}
                handleBlur={handleBlur}
                handleChange={handleChange}
                error={
                    touched.Dedication_Name &&
                    errors.Dedication_Name &&
                    errors.Dedication_Name.length > 0
                }
                errorText={errors.Dedication_Name}
            />
        </div>
    </div>
);

DedicationTypesSelect.propTypes = {
    Dedication_Name: PropTypes.string.isRequired,
    Dedication_Type: PropTypes.string.isRequired,
    dedicationNameLabel: PropTypes.string.isRequired,
    dedicationTypeLabel: PropTypes.string.isRequired,
    displayableActiveDedications: PropTypes.arrayOf(
        PropTypes.shape({ text: PropTypes.string }),
    ).isRequired,
    errors: PropTypes.shape({
        Dedication_Name: PropTypes.string,
        Dedication_Type: PropTypes.string,
    }).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        Dedication_Name: PropTypes.bool,
        Dedication_Type: PropTypes.bool,
    }).isRequired,
};

export default DedicationTypesSelect;
