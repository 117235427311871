import React from "react";
import { Icon } from "@qgiv/core-react";
import "./EventsBanner.scss";

/**
 * @param {object} props - The component props.
 * @param {Function} props.handleClick - The click event handler for the button.
 * @param {object} props.bannerConfig - The configuration object containing props for the banner.
 * @param {string} props.bannerConfig.glyph - The glyph for the icon.
 * @param {string} props.bannerConfig.message - The message to display in the banner.
 * @param {string} props.bannerConfig.linkText - The text for the link button.
 * @returns {React.ReactElement} The rendered EventsBanner component.
 */
const EventsBanner = ({
    handleClick,
    bannerConfig: { glyph = "", message = "", linkText = "" },
}) => (
    <div className="qg-vendor-events-banner events-banner">
        <Icon
            glyph={glyph}
            type="FontAwesome"
            classNames={["events-banner__icon"]}
        />{" "}
        <small>
            {message}
            <span className="events-banner__vertical-divider">|</span>
            <button
                type="button"
                className="events-banner__link -color--theme-primary"
                onClick={handleClick}
            >
                <strong>{linkText}</strong>
            </button>
        </small>
    </div>
);

export default EventsBanner;
