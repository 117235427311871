import React from "react";

import InputBoxList from "../InputBox";

const CheckboxGroup = (props) => {
    return <InputBoxList {...props}>{props.children}</InputBoxList>;
};

CheckboxGroup.propTypes = InputBoxList.propTypes;

export default CheckboxGroup;
