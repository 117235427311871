import { createSlice } from "@reduxjs/toolkit";
import { abandonedGift as initialState } from "../initialState";

const getAbandonedGiftInitialState = () => ({
    ...initialState,
    acceptedReminder: false,
    reminderData: {},
    otherAmountData: {},
});

const slice = createSlice({
    name: "abandonedGift",
    initialState: getAbandonedGiftInitialState(),
    reducers: {
        setAbandonedGiftReminderData: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        setAbandonedGiftOtherAmountData: (state, action) => ({
            ...state,
            otherAmountData: {
                ...action.payload,
            },
        }),
    },
});

export const { setAbandonedGiftReminderData, setAbandonedGiftOtherAmountData } =
    slice.actions;

export const selectAbandonedGiftData = (state) => state.abandonedGift;

export const selectAbandonedGiftOtherAmountData = (state) =>
    state.abandonedGift.otherAmountData;

export const selectAbandonedGiftToken = (state) =>
    state.abandonedGift.qgiv_abandoned_gift;

export default slice.reducer;
