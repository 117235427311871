import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useBreakpoints } from "@qgiv/core-react";
import {
    selectAppModalOpen,
    selectContentToDisplay,
    updateContentToDisplay,
} from "../../../redux/slices/appSettingsSlice";
import { initMutationObserver } from "../../MutationObservers/initMutationObservers";
import { sendPostMessage } from "../../PostMessage/postMessage";
import { ModalContentReplacerEnums } from "../../common/ModalContentReplacer";
import { selectPreventRefreshOnClose } from "../../../redux/slices/urlShortcutsSlice";
import DonationFormModal from "./DonationFormModal";

const ConnectedDonationFromModal = () => {
    const appModalOpen = useSelector(selectAppModalOpen);
    const selectedContentToDisplay = useSelector(selectContentToDisplay);
    const displayCloseButton =
        selectedContentToDisplay !==
        ModalContentReplacerEnums.UPGRADE_RECURRING_UI;
    const dispatch = useDispatch();

    // Currently, we are performing a refresh when closing the donation form modal.
    // This is to handle session in the legacy landing page that may change if
    // a donor logs in while on the form in the modal. This is a workaround
    // until we figure out how to listen to a postmessage on the legacy page
    // to update session. There are certain routes where we do not want to allow
    // this behavior, such as /donate routes, which launch the modal on page load.
    // If we are a route like that, the BE is sending us a flag in urlShortcuts to
    // prevent refreshing the parent window on modal close, which is handled in
    // embed.js
    const preventRefreshOnClose = useSelector(selectPreventRefreshOnClose);

    // This flag is for when the close button should not close the modal, but return
    // the donor to the default content in the modal. This is currently only being used
    // by the recipient search UI.
    const contentSupportsClosingModalOnCloseClick =
        selectedContentToDisplay === ModalContentReplacerEnums.DEFAULT_CONTENT;
    const handleCloseDonationFormModal = () => {
        if (contentSupportsClosingModalOnCloseClick) {
            sendPostMessage("closeFullScreenModal", preventRefreshOnClose);
            return;
        }
        dispatch(
            updateContentToDisplay(ModalContentReplacerEnums.DEFAULT_CONTENT),
        );
    };
    const { isMediumScreen } = useBreakpoints();
    // flag for setting the embed container's height to the parent window's height
    // which is used in the embed logic. Also overrides styles for the modal2__content-wrapper element.
    const hardModalFullHeight =
        isMediumScreen &&
        selectedContentToDisplay ===
            ModalContentReplacerEnums.RECIPIENT_SEARCH_UI;

    useEffect(() => {
        const observer = initMutationObserver();
        return observer?.disconnect;
    }, []);

    useEffect(() => {
        sendPostMessage("setHardFullHeightOnModal", {
            hardModalFullHeight,
        });
    }, [hardModalFullHeight]);

    const donationFormModalProps = {
        appModalOpen,
        displayCloseButton,
        handleCloseDonationFormModal,
        hardModalFullHeight,
    };
    return <DonationFormModal {...donationFormModalProps} />;
};

export default ConnectedDonationFromModal;
