import React from "react";
import PropTypes from "prop-types";
import { TextField, Checkbox } from "@qgiv/core-donor";

const DonationMessage = ({
    Donation_Message,
    Has_Donation_Message,
    checkboxText = "",
    errors,
    handleBlur,
    handleChange,
    isCms = false,
    paragraphHelpText = "",
    paragraphPlaceholderText = "",
    touched,
}) => (
    <div className="donation-message-container col col--12 col--no-left-gutter">
        <div className="donation-message__content">
            <div className="checkbox-container">
                <Checkbox
                    name="Has_Donation_Message"
                    id="Has-Donation-Message"
                    label="has-donation-message"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    checked={Has_Donation_Message || isCms}
                    ignoreBottomPadding
                >
                    {checkboxText}
                </Checkbox>
                {Has_Donation_Message ? (
                    <div className="-padding-top--15">
                        <TextField
                            name="Donation_Message"
                            label={paragraphPlaceholderText}
                            value={Donation_Message}
                            type="text"
                            multiline
                            maxChar="300"
                            descText="Optional"
                            helpText={paragraphHelpText}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            error={
                                touched?.Donation_Message &&
                                errors?.Donation_Message &&
                                errors?.Donation_Message.length > 0
                            }
                            errorText={errors?.Donation_Message}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    </div>
);

DonationMessage.propTypes = {
    checkboxText: PropTypes.string,
    Donation_Message: PropTypes.string.isRequired,
    errors: PropTypes.shape({
        Has_Donation_Message: PropTypes.string,
        Donation_Message: PropTypes.string,
    }).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    Has_Donation_Message: PropTypes.bool.isRequired,
    isCms: PropTypes.bool,
    paragraphHelpText: PropTypes.string,
    paragraphPlaceholderText: PropTypes.string,
    touched: PropTypes.shape({
        Has_Donation_Message: PropTypes.bool,
        Donation_Message: PropTypes.bool,
    }).isRequired,
};

export default DonationMessage;
