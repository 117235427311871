import React from "react";
import PropTypes from "prop-types";
import { Accordion2 as Accordion } from "@qgiv/core-donor";
import ConnectedReceiptTransactionSummary from "./ConnectedReceiptTransactionSummary";
import ConnectedReceiptRecurringCallout from "./ConnectedReceiptRecurringCallout";
import ConnectedReceiptPersonalDetails from "./ConnectedReceiptPersonalDetails";
import ConnectedReceiptPaymentDetails from "./ConnectedReceiptPaymentDetails";
import ConnectedAnonymityCallout from "./ConnectedAnonymityCallout";
import ConnectedOptInCallout from "./ConnectedOptInCallout";
import ConnectedReceiptAdditionalDetails from "./ConnectedReceiptAdditionalDetails";
import ReceiptDetails from "./ReceiptDetails";

const ReceiptSummary = ({
    accordionLabel,
    accordionAdditionalText = "",
    activeEventKeys,
    control,
    displayPaymentDetails,
    printLinkLabel,
    setActiveEventKeys,
    yourDetailsEventKey,
}) => (
    <div id="receipt-summary">
        <Accordion
            activeEventKeys={activeEventKeys}
            onToggle={setActiveEventKeys}
        >
            <div className="panel accordion__panel">
                <Accordion.Toggle
                    eventKey={yourDetailsEventKey}
                    element={Accordion.Header}
                >
                    <Accordion.HeaderReceiptContent
                        activeEventKeys={activeEventKeys}
                        eventKey={yourDetailsEventKey}
                        label={accordionLabel}
                        labelSubText={accordionAdditionalText}
                        printLinkLabel={printLinkLabel}
                    />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={yourDetailsEventKey}>
                    <div className="-padding-bottom--20">
                        <div className="grid">
                            <ConnectedReceiptTransactionSummary
                                control={control}
                            />
                        </div>
                        <div className="grid">
                            <ReceiptDetails control={control} />
                        </div>
                        <ConnectedReceiptRecurringCallout control={control} />
                        <div className="grid">
                            <ConnectedReceiptPersonalDetails
                                control={control}
                            />
                            {displayPaymentDetails && (
                                <ConnectedReceiptPaymentDetails
                                    control={control}
                                />
                            )}
                            <ConnectedReceiptAdditionalDetails
                                control={control}
                            />
                            <ConnectedAnonymityCallout control={control} />
                            <ConnectedOptInCallout control={control} />
                        </div>
                    </div>
                </Accordion.Collapse>
            </div>
        </Accordion>
    </div>
);

ReceiptSummary.propTypes = {
    accordionLabel: PropTypes.string.isRequired,
    accordionAdditionalText: PropTypes.string,
    activeEventKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
    control: PropTypes.shape({}).isRequired,
    displayPaymentDetails: PropTypes.bool.isRequired,
    printLinkLabel: PropTypes.string.isRequired,
    setActiveEventKeys: PropTypes.func.isRequired,
    yourDetailsEventKey: PropTypes.number.isRequired,
};

export default ReceiptSummary;
