import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import moment from "moment";
import { currencyString, constants } from "@qgiv/core-js";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";
import { selectPromiseTransaction } from "../../../../../redux/slices/promiseTransactionSlice";
import InvoiceFulfillmentOption from "./InvoiceFulfillmentOption";

/**
 * @param {object} props - Component properties
 * @param {number} props.controlId - The control ID for the component
 * @param {Function} props.handleSubtotalChange - Function to handle subtotal changes upon
 * selection of a invoice payment option
 * @returns {React.ReactElement} The PayThisInvoice component.
 */
const ConnectedPayThisInvoice = ({ controlId, handleSubtotalChange }) => {
    const promiseTransaction = useSelector(selectPromiseTransaction);
    const formSettings = useSelector(selectAllFormSettings);
    const { currency } = formSettings;
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const { payThisInvoiceLabel = "Pay This Invoice" } = options;

    const { handleBlur, values } = useFormikContext();
    const { Invoice_Complete_Fulfillment, Invoice_Fulfill_Outstanding } =
        values;
    const { InvoiceFulfillmentType } = constants.ENUMS;

    const hasRecurringInvoice = promiseTransaction.recurring !== "0";

    const oneTimeInvoiceAdditionalText = `<span class="-type--small">Invoice from ${moment(
        promiseTransaction.nextBill,
    ).format("MM/DD/YYYY")}</span>`;

    const oneTimeInvoiceProps = {
        cardRadioProps: {
            id: "one-time-invoice",
            name: "Invoice_Complete_Fulfillment",
            title: payThisInvoiceLabel,
            extraTitle: `${currencyString(promiseTransaction.amount, currency)}`,
            value: InvoiceFulfillmentType.ONETIME,
            handleBlur,
            handleChange: handleSubtotalChange,
            checked:
                !Invoice_Complete_Fulfillment && !Invoice_Fulfill_Outstanding,
        },
        additionalText: oneTimeInvoiceAdditionalText,
        hasRecurringInvoice,
    };

    return <InvoiceFulfillmentOption {...oneTimeInvoiceProps} />;
};

ConnectedPayThisInvoice.propTypes = {
    controlId: PropTypes.number.isRequired,
    handleSubtotalChange: PropTypes.func.isRequired,
};

export default ConnectedPayThisInvoice;
