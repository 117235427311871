import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import "./SearchField.scss";

const SearchField = ({
    handleBlur = () => {},
    handleClearBtnClick = () => {},
    handleFocus = () => {},
    ...props
}) => {
    const ref = useRef();
    const [active, setActive] = useState(false);
    // Helper that focuses the input DOM node
    const focusInputRef = () => {
        // if ref.current is an input element focus it, otherwise the element should be the input element to focus
        const element = ref.current.nodeType
            ? ref.current
            : ref.current.element;
        if (!active) {
            setActive(true);
        }
        element.focus();
    };
    const onBlur = (e) => {
        setActive(false);
        handleBlur(e);
    };
    const onFocus = (e) => {
        focusInputRef();
        handleFocus(e);
    };
    const _handleClearBtnClick = (e) => {
        handleClearBtnClick(e);
        focusInputRef();
    };
    const { value = "" } = props;
    const displayClearBtn = useMemo(() => {
        return value.length > 0;
    }, [value]);
    return (
        <div className="search-field">
            <div
                className={cx(
                    "search-field__inner",
                    active && "search-field__inner--active",
                )}
            >
                <div className="search-field__icon-container">
                    <div className="search-field__icon-space">
                        <div className="search-field__icon">
                            <Icon type="FontAwesome" glyph="search-solid" />
                        </div>
                    </div>
                </div>
                <input
                    className="search-field__input"
                    aria-label="team search field"
                    type="text"
                    ref={ref}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    {...props}
                />
                <div className="search-field__icon-container search-field__icon-container--align-right">
                    {displayClearBtn && (
                        <button
                            aria-label="Clear search"
                            className="search-field__icon-space search-field__clear-search-btn -color--theme-primary -cursor--pointer"
                            type="button"
                            onClick={_handleClearBtnClick}
                        >
                            <div className="search-field__icon">
                                <Icon
                                    type="FontAwesome"
                                    glyph="times-circle-solid"
                                />
                            </div>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

SearchField.propTypes = {
    handleBlur: PropTypes.func,
    handleClearBtnClick: PropTypes.func,
    handleFocus: PropTypes.func,
    value: PropTypes.string,
};

export default SearchField;
