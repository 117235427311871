import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./ChooseRecipientErrorMessage.scss";

const ChooseRecipientErrorMessage = ({ errorText }) => (
    <div className="choose-recipient-error-message">
        <Icon
            classNames={[
                "-color--system-error",
                "error-icon",
                "-margin-right--10",
            ]}
            type="FontAwesome"
            glyph="exclamation-circle-solid"
        />
        <p className="ignore-typography">
            <small className="-color--system-error -font-weight--bold">
                {errorText}
            </small>
        </p>
    </div>
);

ChooseRecipientErrorMessage.propTypes = {
    errorText: PropTypes.string.isRequired,
};

export default ChooseRecipientErrorMessage;
