import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectCmsContainersByPage } from "../../../../redux/slices/cmsSettingsSlice";
import ReceiptPageContent from "./ReceiptPageContent";

const ConnectedReceiptPageContent = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { CONFIRMATION },
        },
    } = constants;

    const containers = useSelector((state) =>
        selectCmsContainersByPage(state, CONFIRMATION),
    );

    return <ReceiptPageContent containers={containers} />;
};

export default ConnectedReceiptPageContent;
