import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./RefundCallout.scss";

const RefundCallout = ({
    displayRefund = false,
    formattedAmount = "",
    formattedDate = "",
}) => {
    const boldText = <strong>refund of {formattedAmount}</strong>;

    return displayRefund ? (
        <div className="refund-callouts -callout col col--12 -margin-top--15">
            <p className="-padding-left--15">
                <Icon
                    glyph="arrow-rotate-left-solid"
                    type="FontAwesome"
                    classNames={[
                        "-type--regular -font-weight--bold -margin-right--10",
                    ]}
                />
                You were issued a {boldText} on {formattedDate}
            </p>
        </div>
    ) : null;
};

RefundCallout.propTypes = {
    displayRefund: PropTypes.bool.isRequired,
    formattedAmount: PropTypes.string.isRequired,
    formattedDate: PropTypes.string.isRequired,
};

export default RefundCallout;
