import React, { useCallback } from "react";
import PropTypes from "prop-types";

import TextField from "../Fields/TextField";

const PasswordAndConfirmation = (props) => {
    const {
        handleChange,
        Password,
        Password_Confirm,
        handleBlur,
        touched,
        errors,
        noPadding
    } = props;

    const getDescText = useCallback(() => {
        return !Password && !Password_Confirm ? "Optional" : "";
    }, [Password, Password_Confirm])

    // autoComplete="new-password" prevents Browser autofill. this is essential to 
    // prevent autofill-related bugs like annoying field focusing and 
    // setting password values in the background when user is already logged in.
    return (
        <>
            <div className={`col col--12 ${noPadding ? "-padding-left--0" : "" }`}>
                <TextField
                    id="Password"
                    name="Password"
                    type="password"
                    label="Create Password"
                    autoComplete="new-password"
                    descText={getDescText()}
                    value={Password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    error={
                        Boolean(touched.Password && errors.Password)
                    }
                    errorText={errors.Password}
                />
            </div>
            <div className={`col col--12 ${noPadding ? "-padding-left--0" : "" }`}>
                <TextField
                    id="Password_Confirm"
                    name="Password_Confirm"
                    type="password"
                    label="Re-enter Password"
                    autoComplete="new-password-confirm"
                    descText={getDescText()}
                    value={Password_Confirm}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    error={
                        Boolean(touched.Password_Confirm && errors.Password_Confirm)
                    }
                    errorText={errors.Password_Confirm}
                />
            </div>
        </>
    );
};

PasswordAndConfirmation.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    Password: PropTypes.string.isRequired,
    Password_Confirm: PropTypes.string.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    noPadding: PropTypes.bool,
};

export default PasswordAndConfirmation;
