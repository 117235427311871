/**
 * @public
 * @function getMailingAddressInitialValues
 * @param {string} defaultCountry Field setting value for default country
 * @returns {object} initial value for Mailing Address in formik
 */
const getMailingAddressInitialValues = (defaultCountry) => ({
    Address: "",
    Address_2: "",
    City: "",
    State: "",
    Zip: "",
    Country: defaultCountry,
});

export default getMailingAddressInitialValues;
