import React from "react";
import PropTypes from "prop-types";
import InnerHTML from "dangerously-set-html-content";

const FormInjectionBody = ({ activeInjectionsInBody: { script = [] } }) => {
    // A "SyntaxError: Unexpected identifier" error will be thrown if the JS
    // code that the user provided had a syntax error. Dangerously Set HTML
    // Content is used to inject this content here because it creates the
    // <script/> tag and runs the script while ReactHTMLParser does not appear
    // to be able to even create a <script/> tag when passed a string
    const scriptsWrappedInTags = script.map(function (scriptInjection) {
        return `<script>${scriptInjection.content}</script>`;
    });
    // Concatenate all of the data to be injected into a single string so that
    // it can be injected into the body
    const html = scriptsWrappedInTags.join("");
    return html.length > 0 ? <InnerHTML html={html} /> : null;
};

FormInjectionBody.propTypes = {
    activeInjectionsInBody: PropTypes.shape({
        script: PropTypes.array.isRequired,
    }).isRequired,
};

export default FormInjectionBody;
