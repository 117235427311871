import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { constants, currencyString } from "@qgiv/core-js";
import {
    selectSmsData,
    updateShouldDisplayTextToDonateInterface,
} from "../../../../redux/slices/smsDataSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectGiftDetails } from "../../../../redux/slices/donationDetailsSlice";
import { selectAllDonationSettings } from "../../../../redux/slices/donationSettingsSlice";
import DonatedGiftTextToDonate from "./DonatedGiftTextToDonate";

const ConnectedDonatedGiftTextToDonate = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS },
        },
    } = constants;
    const dispatch = useDispatch();
    const smsData = useSelector(selectSmsData);
    const { amount = 0 } = smsData;
    const formSettings = useSelector(selectAllFormSettings);
    const { currency } = formSettings;

    const formattedAmount = currencyString(amount, currency);
    const giftDetails = useSelector(selectGiftDetails);
    const { hasSelectedRecurringDonation = false } = giftDetails;

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, DONATION_AMOUNTS),
    );
    const { options = {} } = control;
    const { textToDonateMessage = "Thanks for texting! Your gift is:" } =
        options;

    const donationSettings = useSelector(selectAllDonationSettings);
    const { activeDonationFrequencies = [], defaultRecurringFrequency = {} } =
        donationSettings;

    const defaultFrequencyWithActiveData =
        activeDonationFrequencies.find(
            (activeFrequency) =>
                activeFrequency.value === defaultRecurringFrequency.value,
        ) || {};

    const { labelPerAmount = "" } = defaultFrequencyWithActiveData;

    const handleEditAmountClick = () => {
        dispatch(updateShouldDisplayTextToDonateInterface(false));
    };

    const formattedAmountTextWithFrequency = `${formattedAmount}${hasSelectedRecurringDonation ? `/${labelPerAmount}` : ""}`;

    return (
        <DonatedGiftTextToDonate
            formattedAmountTextWithFrequency={formattedAmountTextWithFrequency}
            textToDonateMessage={textToDonateMessage}
            handleEditAmountClick={handleEditAmountClick}
        />
    );
};

ConnectedDonatedGiftTextToDonate.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonatedGiftTextToDonate;
