import { createSlice } from "@reduxjs/toolkit";
import {
    systemFields as initialSystemFields,
    customFields as initialCustomFields,
    config,
} from "../initialState";

import {
    amtReqFieldInFieldGroupIsRequiredDataSelector,
    amtReqFieldIsRequiredDataSelector,
    getIsSystemFieldGroupSelector,
    selectAddressGroupIsDisplayableSelector,
    selectAllAdditionalDetailsPageCustomFieldsSelector,
    selectAllCustomFieldsSelector,
    selectAllDetailsPageCustomFieldsSelector,
    selectAllDisplayableCustomFieldsSelector,
    selectAllFieldsAndGroupsSelector,
    selectAllFieldsInCustomFieldsSelector,
    selectAllFieldsInSystemFieldsSelector,
    selectAllFieldsSelector,
    selectAllSystemFieldsSelector,
    selectAnonymousFieldCheckedSelector,
    selectAnonymousFieldDataSelector,
    selectBankFieldsDataSelector,
    selectCCFieldsDataSelector,
    selectCompanyFieldIsRequiredSelector,
    selectCustomFieldByIdSelector,
    selectDefaultCountrySelector,
    selectDetailsPageFieldsDataSelector,
    selectEmployerFieldIsRequiredSelector,
    selectFieldCheckedByDefaultSelector,
    selectHasBillingNameFieldSelector,
    selectHasLegalFieldSelector,
    selectHasSuffixFieldSelector,
    selectOptInDefaultCheckedSelector,
    selectPhoneFieldIsRequiredSelector,
    selectSystemFieldByIdSelector,
    selectTitleFieldIsRequiredSelector,
    selectDisplayableBillingFieldsSelector,
    selectHasDisplayableMatchingGiftOnPageSelector,
    selectHasDonationMessageSelector,
    selectHasEmailFieldSelector,
    selectIsUsingPrivacyOptionsSelector,
} from "../selectors/fieldSelectors";

const getFieldSettingsInitialState = () => ({
    systemFields: initialSystemFields,
    customFields: initialCustomFields,
});

export const fieldSettingsInitialState = getFieldSettingsInitialState();
// Used to determine if field is displayable based on display options
const { currentDisplay } = config;

export const slice = createSlice({
    name: "fields",
    initialState: fieldSettingsInitialState,
    // If we aren't going to be changing state, slice does not need reducers or actions
    reducers: {},
});

// selectors

export const selectAllFields = (state) => selectAllFieldsSelector(state);

// returns array of system fields and system field groups
export const selectAllSystemFields = (state) =>
    selectAllSystemFieldsSelector(state);

// returns array of custom fields and custom field groups
export const selectAllCustomFields = (state) =>
    selectAllCustomFieldsSelector(state);

export const selectAllFieldsAndGroups = (state) =>
    selectAllFieldsAndGroupsSelector(state);

export const getIsSystemFieldGroup = (field) =>
    getIsSystemFieldGroupSelector(field);

export const selectAllFieldsInSystemFields = (state) =>
    selectAllFieldsInSystemFieldsSelector(state);
// returns array of all custom fields with correct display settings
export const selectAllFieldsInCustomFields = (state) =>
    selectAllFieldsInCustomFieldsSelector(state);

export const selectAllDisplayableCustomFields = (state) =>
    selectAllDisplayableCustomFieldsSelector(state, currentDisplay);

// returns array of custom fields that display on the details page
export const selectAllDetailsPageCustomFields = (state) =>
    selectAllDetailsPageCustomFieldsSelector(state, currentDisplay);

// returns array of custom fields that display on the additional details page
export const selectAllAdditionalDetailsPageCustomFields = (state) =>
    selectAllAdditionalDetailsPageCustomFieldsSelector(state, currentDisplay);

export const selectCustomFieldById = (state, id) =>
    selectCustomFieldByIdSelector(state, id);

export const selectSystemFieldById = (state, id) =>
    selectSystemFieldByIdSelector(state, id);

export const selectDefaultCountry = (state, groupType) =>
    selectDefaultCountrySelector(state, groupType);

export const selectFieldCheckedByDefault = (state, fieldType) =>
    selectFieldCheckedByDefaultSelector(state, fieldType);

export const selectOptInDefaultChecked = (state) =>
    selectOptInDefaultCheckedSelector(state);

export const selectAnonymousFieldChecked = (state) =>
    selectAnonymousFieldCheckedSelector(state);

export const selectAnonymousFieldData = (state) =>
    selectAnonymousFieldDataSelector(state, currentDisplay);

export const selectHasLegalField = (state) =>
    selectHasLegalFieldSelector(state, currentDisplay);

export const amtReqFieldIsRequiredData = (state, type) =>
    amtReqFieldIsRequiredDataSelector(state, type, currentDisplay);

export const amtReqFieldInFieldGroupIsRequiredData = (
    state,
    groupType,
    fieldType,
    total,
) =>
    amtReqFieldInFieldGroupIsRequiredDataSelector(
        state,
        groupType,
        fieldType,
        total,
    );

export const selectPhoneFieldIsRequired = (state, total) =>
    selectPhoneFieldIsRequiredSelector(state, total);

export const selectCompanyFieldIsRequired = (state, total) =>
    selectCompanyFieldIsRequiredSelector(state, total);

export const selectEmployerFieldIsRequired = (state, total) =>
    selectEmployerFieldIsRequiredSelector(state, total);

export const selectAddressGroupIsDisplayable = (state, groupType) =>
    selectAddressGroupIsDisplayableSelector(state, groupType, currentDisplay);

export const selectTitleFieldIsRequired = (state, total) =>
    selectTitleFieldIsRequiredSelector(state, total);

export const selectHasSuffixField = (state) =>
    selectHasSuffixFieldSelector(state);

export const selectHasBillingNameField = (state) =>
    selectHasBillingNameFieldSelector(state, currentDisplay);

export const selectHasEmailField = (state) =>
    selectHasEmailFieldSelector(state, currentDisplay);

export const selectCCFieldsData = (state) => selectCCFieldsDataSelector(state);

export const selectBankFieldsData = (state) =>
    selectBankFieldsDataSelector(state);

export const selectDetailsPageFieldsData = (state, total) =>
    selectDetailsPageFieldsDataSelector(state, currentDisplay, total);

export const selectHasDisplayableMatchingGiftOnPage = (
    state,
    currentPage,
    hasSelectedCompanyDonation = false,
) =>
    selectHasDisplayableMatchingGiftOnPageSelector({
        state,
        currentPage,
        currentDisplay,
        hasSelectedCompanyDonation,
    });

export const selectDisplayableBillingFields = (state) =>
    selectDisplayableBillingFieldsSelector(state, currentDisplay);

export const selectHasDonationMessage = (state) =>
    selectHasDonationMessageSelector(state, currentDisplay);

export const selectIsUsingPrivacyOptions = (state) =>
    selectIsUsingPrivacyOptionsSelector(state);

export default slice.reducer;
