// import { debounceDomChangeEvent } from "@qgiv/form";
import { debounceSendResizeMessage } from "../PostMessage/postMessage";

/**
 * @description Local vars
 */
let lastAppHeight = 0;
let lastOffset = 0;
let offsetNodes = [];

/**
 * @private
 * @function initMutationObserverEmbed
 * @returns {object} MutationObserver object
 * @description Set up MutationObserver to watch DOM changes and trigger iframe resize.
 * This is specifically for the embedded view.
 */
export const initMutationObserverEmbed = () => {
    const appContainer = document.getElementById("app-container");
    const config = {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
    };
    const callback = (mutationsList) => {
        let offset = 0;
        let formVisibilityTimerId;

        // Monitor the height of the app container until it can be inferred
        // that the tab is visible at which point a resize can be triggered and
        // the timer dismounted
        const verifyThatFormVisibilityHasChanged = () => {
            const currentAppHeight =
                appContainer.getBoundingClientRect().height;
            if (currentAppHeight > 0) {
                debounceSendResizeMessage(currentAppHeight + lastOffset);
                clearInterval(formVisibilityTimerId);
            }
        };

        const childAddedOrRemoved = mutationsList.some(
            (mutation) => mutation.type === "childList",
        );

        const charactersChanged = mutationsList.some(
            (mutation) => mutation.type === "characterData",
        );

        // Grab nodes that are partially off vertical axis of screen
        const offsetHeightNodes = mutationsList.reduce(
            (nodes, mutationRecord) => {
                const { addedNodes } = mutationRecord;
                const matches = [...addedNodes]
                    .filter((node) => node.nodeType === 1)
                    .filter((node) => node.matches(".js-resize-offset-height"));
                return [...nodes, ...matches];
            },
            [],
        );

        // Refresh the stored nodes array only with those that still exist
        // and the new offset nodes.
        offsetNodes = [
            ...offsetNodes.filter((node) => document.body.contains(node)),
            ...offsetHeightNodes,
        ];

        // All values in object returned by .getBoundingClientRect() will be 0
        // if form is nested in a tab that is not visible on page load
        const appHeight = appContainer.getBoundingClientRect().height;
        const appIsHidden = appHeight <= 0;
        const appHeightChanged = appHeight !== lastAppHeight;

        // Aggregate all off screen node heights
        if (offsetNodes.length > 0) {
            offset = offsetNodes.reduce((_offset, node) => {
                const nodeHeight = node.getBoundingClientRect().height;
                const nodeOffsetTop = node.offsetTop;
                // detect if node is offscreen via node offset and app height
                const isOffscreen = appHeight - nodeOffsetTop < nodeHeight;
                if (isOffscreen) {
                    // Round up to prevent visually cutting off the offset node
                    const nodeOffset = Math.ceil(
                        nodeOffsetTop + nodeHeight - appHeight,
                    );
                    return _offset + nodeOffset;
                }
                return _offset;
            }, 0);
        }

        const offsetChanged = offset !== lastOffset;
        // Boolean that determines whether or not the app resizes. If future
        // issues are encountered where the app is not resizing as expected
        // then the conditions associated with this boolean will need to be
        // modified
        const resize =
            (charactersChanged && appHeightChanged) ||
            (childAddedOrRemoved && appHeightChanged) ||
            appIsHidden ||
            offsetChanged;
        // Repeatedly check to see if the form visibility has changed as if the
        // element that has hidden the form is on a client site cross origin
        // errors prevent us from directly observing the style change
        if (appIsHidden) {
            formVisibilityTimerId = setInterval(
                verifyThatFormVisibilityHasChanged,
                1000,
            );
        }

        if (resize) {
            // Give the form a default height so that all forms are at least
            // somewhat visible before the resize from the timer is triggered
            if (appIsHidden) {
                debounceSendResizeMessage(1200 + offset, null);
            } else {
                debounceSendResizeMessage(appHeight + offset, null);
            }
        }

        // Media loads can change height
        // This looks for new media, and triggers a resize when the media loads.
        const listenForMediaLoad = (child) =>
            child.addEventListener(
                "load",
                () => {
                    debounceSendResizeMessage(null, lastOffset);
                },
                false,
            );

        const media = document.querySelectorAll("img, iframe, video, canvas");

        if (media && media.length > 0) {
            Array.from(media).forEach(listenForMediaLoad);
        }

        // Store last values for next round in calculation loop
        lastAppHeight = appHeight;
        lastOffset = offset;

        // This logic has to do with the VENDOR_EVENTS.js file which
        // is not included in the One Form yet. Not sure if this will
        // need to be added.
        // debounceDomChangeEvent({ mutationsList, appHeight });
    };

    const observer = new MutationObserver(callback);

    observer.observe(appContainer, config);

    return observer;
};
