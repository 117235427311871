/* eslint-disable react/prop-types */
import React from "react";
import cx from "classnames";
import WidgetEventListUnavailable from "./WidgetEventListUnavailable";
import WidgetEventsList from "./WidgetEventsList";

/**
 * @param {object} props - Component properties
 * @param {string} props.title - The title to display at the top of the widget
 * @param {string} props.additionalText - Additional text to display below the title
 * @param {boolean} props.hasNoBorders - Flag indicating whether the widget should have no top margin
 * @param {Array} props.activeEvents - An array of active event objects to be displayed
 * @param {boolean} props.displayEventList - Flag indicating whether to display the event list
 * @param {string} props.status - The status of the events
 * @param {object} props.currency - The currency settings for the events
 * @param {Array} props.eventListOptions - Options for displaying the event list
 * @param {string} props.path - The path for the event links
 * @returns {React.ReactElement} The WidgetEvents component.
 */
const WidgetEvents = ({
    title,
    additionalText,
    hasNoBorders,
    activeEvents,
    displayEventList,
    status,
    currency,
    eventListOptions,
    path,
}) => (
    <div className={cx("widget-events", hasNoBorders && "-margin-top--0")}>
        <div className="grid">
            {title && (
                <div className="col col--12 col--no-top-gutter -text--center -margin-bottom--15">
                    <h2>{title}</h2>
                </div>
            )}
            {additionalText && (
                <div
                    className={`col col--12${
                        title ? "" : " col--no-top-gutter"
                    }`}
                >
                    <p className="-text--center">{additionalText}</p>
                </div>
            )}
            <div className="col col--12 col--no-top-gutter">
                {displayEventList ? (
                    <WidgetEventsList
                        activeEvents={activeEvents}
                        currency={currency}
                        eventListOptions={eventListOptions}
                        path={path}
                    />
                ) : (
                    <WidgetEventListUnavailable
                        status={status}
                        eventListOptions={eventListOptions}
                    />
                )}
            </div>
        </div>
    </div>
);

export default WidgetEvents;
