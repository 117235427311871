import React from "react";
import { WidgetCustomContent } from "@qgiv/core-donor";
import "./TopBottomContent.scss";

/**
 *
 * @param {object} props Props
 * @param {string} props.content Content to be rendered at the top or bottom of the page
 * @returns {React.ReactElement} TopBottomContent
 */
const TopBottomContent = ({ content = "" }) =>
    content ? (
        <div className="content">
            <WidgetCustomContent
                control={{ options: { content } }}
                allowRerender
            />
        </div>
    ) : null;

export default TopBottomContent;
