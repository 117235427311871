import React from "react";
import PropTypes from "prop-types";

const BillingAddressHeading = ({
    isAnyBillingFieldDisplayable = false,
    billingAddressSectionHeading = "",
    billingAddressAdditionalText = "",
}) =>
    isAnyBillingFieldDisplayable ? (
        <>
            <h3>{billingAddressSectionHeading}</h3>
            {billingAddressAdditionalText && (
                <p>{billingAddressAdditionalText}</p>
            )}
        </>
    ) : null;

BillingAddressHeading.propTypes = {
    isAnyBillingFieldDisplayable: PropTypes.bool.isRequired,
    billingAddressSectionHeading: PropTypes.string.isRequired,
    billingAddressAdditionalText: PropTypes.string,
};

export default BillingAddressHeading;
