import React from "react";
import { useSelector } from "react-redux";

import { ReceiptMatchingWidget } from "@qgiv/core-donor";
import { selectReceiptTransaction } from "../../../redux/slices/receiptsSlice";
import { selectMatchingGiftSettings } from "../../../redux/slices/donationSettingsSlice";

const ConnectedReceiptMatchingWidget = () => {
    let matchingProps = {};

    const {
        enableMatching,
        matchingTokens = {},
        thirdPartyMatching,
    } = useSelector(selectMatchingGiftSettings);

    const {
        email,
        company,
        matchingData: { companyId, companyName, matchId },
    } = useSelector(selectReceiptTransaction);

    matchingProps = {
        company,
        companyId,
        companyName,
        email,
        enableMatching,
        matchId,
        matchingTokens,
        noLeftGutter: true,
        thirdPartyMatching,
    };

    return <ReceiptMatchingWidget {...matchingProps} />;
};

export default ConnectedReceiptMatchingWidget;
