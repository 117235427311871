import React from "react";
import { constants } from "@qgiv/core-js";
import { useSelector } from "react-redux";
import ReceiptPage from "./ReceiptPage";
import { selectMatchingGiftSettings } from "../../../redux/slices/donationSettingsSlice";

const ConnectedReceiptPage = () => {
    const {
        ENUMS: { MatchingType, ThirdPartyMatchingType, Service },
    } = constants;
    const documentTitle = "Confirmation";
    // Matching is enabled, is automatic and DtD is chosen, there is an apikey
    const { enableMatching, thirdPartyMatching, matchingTokens, matchingType } =
        useSelector(selectMatchingGiftSettings);

    const isDtD =
        matchingType === MatchingType.AUTOMATIC &&
        thirdPartyMatching === ThirdPartyMatchingType.DOUBLEDONATION;

    const hasDtdApiKey = !!matchingTokens?.[Service.DOUBLEDONATION]?.api_key;

    const showMatchingGifts = enableMatching && isDtD && hasDtdApiKey;
    return (
        <ReceiptPage
            documentTitle={documentTitle}
            showMatchingGifts={showMatchingGifts}
        />
    );
};

export default ConnectedReceiptPage;
