import { constants } from "@qgiv/core-js";

const {
    ENUMS: { PaymentTypes, TransactionChannel },
} = constants;

// All of the potential payment methods that the event
// registration can accept. Channel is necessary as it used to
// determine whether a transaction was completed using PayPal or
// Venmo
/* eslint-disable import/prefer-default-export */
export const ccTypeToPaymentMethodList = [
    {
        ccType: PaymentTypes.AMERICAN_EXPRESS,
        channel: TransactionChannel.STANDARD,
        paymentMethod: "American Express",
    },
    {
        ccType: PaymentTypes.DISCOVER,
        channel: TransactionChannel.STANDARD,
        paymentMethod: "Discover",
    },
    {
        ccType: PaymentTypes.MASTERCARD,
        channel: TransactionChannel.STANDARD,
        paymentMethod: "MasterCard",
    },
    {
        ccType: PaymentTypes.VISA,
        channel: TransactionChannel.STANDARD,
        paymentMethod: "Visa",
    },
    {
        ccType: PaymentTypes.ECHECK,
        channel: TransactionChannel.STANDARD,
        paymentMethod: "E-Check",
    },
    {
        ccType: PaymentTypes.PAYPAL,
        channel: TransactionChannel.STANDARD,
        paymentMethod: "PayPal",
    },
    {
        ccType: PaymentTypes.PAYPAL,
        channel: TransactionChannel.VENMO,
        paymentMethod: "Venmo",
    },
];
