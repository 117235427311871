import React from "react";
import PropTypes from "prop-types";
import { constants, log } from "@qgiv/core-js";
import {
    ConnectedWidgetCustomContentControl,
    ConnectedWidgetHeadingControl,
    ConnectedWidgetFieldControl,
    ConnectedWidgetFieldGroupControl,
    ConnectedWidgetSpacingControl,
    ConnectedWidgetTextControl,
    ConnectedDonatedGiftControl,
    ConnectedDonationRecipientControl,
    ConnectedHeaderControl,
    ConnectedPaymentContentControl,
    DonorAccountControl,
    FooterControl,
    ReceiptDonationDetailsControl,
    ReceiptHeaderControl,
    SocialControl,
    ConnectedMatchingGiftControl,
    ConnectedGiftAssistControl,
    ConnectedDedicationControl,
    ConnectedDonationMessageControl,
    ConnectedPrivacyOptionsControl,
    ConfirmationFieldsHeaderControl,
    ConfirmationFieldsSubmitControl,
    ConfirmationFieldsControl,
} from "../Controls";
import ConnectedWidgetEvents from "../common/EventsWidget/ConnectedWidgetEvents";
import ConnectedWidgetThermometerContentControl from "../Controls/ConnectedWidgetThermometerContentControl";

const DonationFormControl = ({ control: { type }, ...props }) => {
    const {
        ENUMS: { CMS_Control },
    } = constants;
    switch (Number(type)) {
        case CMS_Control.WIDGET_HEADING:
            return <ConnectedWidgetHeadingControl {...props} />;
        case CMS_Control.WIDGET_CUSTOM_CONTENT:
            return <ConnectedWidgetCustomContentControl {...props} />;
        case CMS_Control.WIDGET_TEXT:
            return <ConnectedWidgetTextControl {...props} />;
        case CMS_Control.WIDGET_SPACING:
            return <ConnectedWidgetSpacingControl {...props} />;
        case CMS_Control.THERMOMETER:
            return <ConnectedWidgetThermometerContentControl {...props} />;
        case CMS_Control.HEADING:
            return <ConnectedHeaderControl {...props} />;
        case CMS_Control.STANDARD_DONATION_GIFT_CONTENT:
            return <ConnectedDonatedGiftControl {...props} />;
        case CMS_Control.GIFT_ASSIST:
            return <ConnectedGiftAssistControl {...props} />;
        case CMS_Control.DEDICATION_CONTENT:
            return <ConnectedDedicationControl {...props} />;
        case CMS_Control.STANDARD_DONATION_MESSAGE:
            return <ConnectedDonationMessageControl {...props} />;
        case CMS_Control.FOOTER:
            return <FooterControl {...props} />;
        case CMS_Control.STANDARD_DONATION_PAYMENT_CONTENT:
            return <ConnectedPaymentContentControl {...props} />;
        case CMS_Control.RECEIPT_HEADER:
            return <ReceiptHeaderControl {...props} />;
        case CMS_Control.RECEIPT_DONATION_DETAILS:
            return <ReceiptDonationDetailsControl {...props} />;
        case CMS_Control.DONOR_ACCOUNT:
            return <DonorAccountControl {...props} />;
        case CMS_Control.SOCIAL:
            return <SocialControl {...props} />;
        case CMS_Control.FIELD:
            return <ConnectedWidgetFieldControl {...props} />;
        case CMS_Control.FIELD_GROUP:
            return <ConnectedWidgetFieldGroupControl {...props} />;
        case CMS_Control.MATCHING_GIFTS_FIELD_GROUP:
        case CMS_Control.STANDARD_DONATION_MATCHING_GIFT:
            return <ConnectedMatchingGiftControl {...props} />;
        case CMS_Control.STANDARD_DONATION_RECIPIENT:
            return <ConnectedDonationRecipientControl {...props} />;
        case CMS_Control.STANDARD_DONATION_PRIVACY_OPTIONS:
            return <ConnectedPrivacyOptionsControl {...props} />;
        case CMS_Control.CONFIRMATION_FIELDS_HEADER:
            return <ConfirmationFieldsHeaderControl {...props} />;
        case CMS_Control.CONFIRMATION_FIELDS_SUBMIT:
            return <ConfirmationFieldsSubmitControl {...props} />;
        case CMS_Control.CONFIRMATION_FIELDS:
            return <ConfirmationFieldsControl {...props} />;
        case CMS_Control.WIDGET_EVENTS:
            return <ConnectedWidgetEvents {...props} />;
        default:
            log([`Missing Control Type: ${type}`, { props }]);
            return <h1>No Control Exists</h1>;
    }
};

DonationFormControl.propTypes = {
    control: PropTypes.shape({
        type: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
    }).isRequired,
};

export default DonationFormControl;
