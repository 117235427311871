import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { CurrencyInput } from "@qgiv/core-donor";
import "./SharedButtonStyles.scss";
import "./OtherAmountRadioOption.scss";

const OtherAmountRadioOption = ({
    buttonSelectedFieldName,
    buttonStyle,
    checked,
    containerClasses,
    handleChange,
    handleOtherInputButtonBlur,
    handleOtherInputButtonFocus,
    handleOtherInputButtonKeydown,
    idOfOtherAmount,
    index,
    injectedUnformattedCurrencyValue,
    multiRestrictionCurrencyInputProps,
    otherAmountInputFieldName,
    otherLabel,
    shouldInputBeFocused,
    standardAndOtherAmountRefs,
}) => {
    // Ref for Selected Amount Id button
    const ref = standardAndOtherAmountRefs[index];

    // Ref for Currency Input
    const inputRef = useRef(null);

    // Extract the HTML element that contains a reference to the Other Amount,
    // whether one time or recurring
    const triggerCustomAmountInputFocus = () => {
        const { current = {} } = inputRef;

        // Trigger the focus event on the Other_[One_Time/Recurring]_Amount input which should
        // update the placeholder value
        current.focus();
    };

    useEffect(() => {
        if (shouldInputBeFocused) {
            triggerCustomAmountInputFocus();
        }
    }, [shouldInputBeFocused, inputRef]);

    const [active, setActive] = useState(false);

    const handleFocus = (e) => {
        setActive(true);
        handleOtherInputButtonFocus(e);
    };

    const handleBlur = (e) => {
        setActive(false);
        handleOtherInputButtonBlur(e);
    };

    const handleKeyDown = (e) => {
        handleOtherInputButtonKeydown(e);
    };

    // Hard coded the id value as the name prop is what is actually used by the
    // <input/> element rendered by the CurrencyInput component and the id prop
    // is never actually used anywhere inside of it
    const currencyInputProps = {
        alwaysDisplayPlaceholder: false,
        centeredDisplay: true,
        onBlur: (e) => {
            handleBlur(e);
        },
        onFocus: (e) => {
            handleFocus(e);
        },
        id: "",
        injectedUnformattedCurrencyValue,
        isDonationForm: true,
        className: "other-amount -text--center",
        "aria-label": "Enter Other Amount",
        name: otherAmountInputFieldName,
        onChange: (e) => {
            handleChange(e);
        },
        onKeyDown: (e) => {
            handleKeyDown(e);
        },
        selectable: "true",
        tabIndex: "-1",
        type: "currency",
        placeholder: otherLabel,
        ...multiRestrictionCurrencyInputProps,
    };

    return (
        <div className={`col ${containerClasses}`}>
            <label
                htmlFor={`${buttonSelectedFieldName}_${idOfOtherAmount}`}
                className="input-button"
            >
                <div
                    className={cx(
                        `qg-vendor-button__input--${buttonStyle}`,
                        "amount-button",
                        `amount-button__input--${buttonStyle}`,
                        active && "amount-button__active--selectable",
                        checked && `amount-button__selected--${buttonStyle}`,
                    )}
                >
                    <input
                        type="radio"
                        className="-visually-hidden"
                        name={buttonSelectedFieldName}
                        value={idOfOtherAmount}
                        id={`${buttonSelectedFieldName}_${idOfOtherAmount}`}
                        checked={checked}
                        ref={ref}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                    <CurrencyInput {...currencyInputProps} ref={inputRef} />
                </div>
            </label>
        </div>
    );
};

OtherAmountRadioOption.propTypes = {
    buttonSelectedFieldName: PropTypes.string.isRequired,
    buttonStyle: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    containerClasses: PropTypes.string.isRequired,
    handleOtherInputButtonBlur: PropTypes.func.isRequired,
    handleOtherInputButtonFocus: PropTypes.func.isRequired,
    handleOtherInputButtonKeydown: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    idOfOtherAmount: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    injectedUnformattedCurrencyValue: PropTypes.string,
    multiRestrictionCurrencyInputProps: PropTypes.shape({
        isMultiRestrictionCustomAmount: PropTypes.bool.isRequired,
        relatedCheckboxName: PropTypes.string.isRequired,
    }),
    otherAmountInputFieldName: PropTypes.string.isRequired,
    otherLabel: PropTypes.string.isRequired,
    standardAndOtherAmountRefs: PropTypes.arrayOf(PropTypes.shape({}))
        .isRequired,
    shouldInputBeFocused: PropTypes.bool.isRequired,
};

export default OtherAmountRadioOption;
