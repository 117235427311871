import React from "react";
import ConnectedSubmit from "../../SinglePageForm/Submit";

/**
 * @param {object} props - The component props.
 * @param {string} props.controlId - The ID of the control element.
 * @returns {React.ReactElement} The rendered footer control for the single-page donation form.
 */
const SinglePageFooterControl = ({ controlId }) => (
    <ConnectedSubmit controlId={controlId} />
);

export default SinglePageFooterControl;
