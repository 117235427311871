import React from "react";
import PropTypes from "prop-types";

const InvoiceText = ({
    email = "",
    invoiceHeading = "Request an Invoice",
    invoiceNotificationMessage = "Once you submit your gift, an invoice will be sent to the following email to be paid later:",
}) => (
    <div className="qg-vendor-invoice-text invoice-text">
        <div className="grid">
            <div className="col col--12">
                <div className="-callout -text--center">
                    <h3>{invoiceHeading}</h3>
                    <p>{invoiceNotificationMessage}</p>
                    <p>
                        <strong>{email}</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
);

InvoiceText.propTypes = {
    email: PropTypes.string,
    invoiceHeading: PropTypes.string,
    invoiceNotificationMessage: PropTypes.string,
};

export default InvoiceText;
