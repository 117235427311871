import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getHasDisplayableSystemFieldsInConfirmationFields } from "@qgiv/donation-form";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectConfig } from "../../../redux/slices/configSettingsSlice";
import { selectDonorAccount } from "../../../redux/slices/donorAccountSlice";
import { selectCurrentReceiptData } from "../../../redux/slices/receiptsSlice";
import {
    selectShouldDisplayConfirmationFieldsBasedOnUserInteraction,
    setErrors,
    setMessages,
} from "../../../redux/slices/appSettingsSlice";
import {
    selectAllDisplayableCustomFields,
    selectAllFieldsAndGroups,
    selectAllFieldsInSystemFields,
    selectDefaultCountry,
    selectDetailsPageFieldsData,
    selectHasBillingNameField,
} from "../../../redux/slices/fieldsSlice";
import ConfirmationFieldsContainer from "./ConfirmationFieldsContainer";

const ConnectedConfirmationFieldsContainer = ({ container }) => {
    const config = useSelector(selectConfig);
    const displayableCustomFields = useSelector(
        selectAllDisplayableCustomFields,
    );
    const detailsPageFieldsData = useSelector(selectDetailsPageFieldsData);
    const systemFields = useSelector(selectAllFieldsInSystemFields);
    const defaultCountry = useSelector(selectDefaultCountry);
    const billingNameFieldData = useSelector(selectHasBillingNameField);
    const isBillingNameDisplayed = billingNameFieldData?.exists;
    const hasDisplayableSystemFields =
        getHasDisplayableSystemFieldsInConfirmationFields(
            detailsPageFieldsData,
        );

    // need to reference receipt data in order to find valid gift details
    const currentReceiptData = useSelector(selectCurrentReceiptData);
    const { total, feeCoverage = null, transaction = {} } = currentReceiptData;
    const { recurring = null } = transaction;
    const hasSelectedRecurringDonation =
        recurring !== null && Object.keys(recurring).length > 0;
    const subtotal = feeCoverage === null ? total : total - Number(feeCoverage);
    const donationAmountFromRedux = subtotal;

    const conditionalLogicOptions = useMemo(
        () => ({
            donationAmountFromRedux,
            hasSelectedRecurringDonation,
            usesReduxDonationAmountValue: true,
            usesReduxRecurringValue: true,
        }),
        [donationAmountFromRedux, hasSelectedRecurringDonation],
    );
    const donorAccount = useSelector(selectDonorAccount);
    const formSettings = useSelector(selectAllFormSettings);
    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);
    const { id } = formSettings;
    const { path = "" } = config;
    const {
        // NOTE: hasSubmittedConfirmationFieldsForm reflects if ON PAGE LOAD a form has had
        // confirmation fields submitted for it. It does not reflect the state immediately after
        // the submit button has been pressed.
        hasSubmittedConfirmationFieldsForm = "0",
        isExpressCheckout = "0",
    } = transaction;
    const shouldDisplayConfirmationFieldsBasedOnUserInteraction = useSelector(
        selectShouldDisplayConfirmationFieldsBasedOnUserInteraction,
    );
    // Whether there is actually a field that needs to be rendered
    // -------------------------------------------------------------------------
    // TODO: Conditional logic will have to be added here, when we're ready for it.
    // -------------------------------------------------------------------------
    const hasConfirmationFieldToDisplay =
        displayableCustomFields.length || hasDisplayableSystemFields;
    const formId = id;
    const shouldDisplayConfirmationFieldsBasedOnTransactionData =
        isExpressCheckout === "1" && hasSubmittedConfirmationFieldsForm === "0";
    const displayConfirmationFieldsContainer =
        hasConfirmationFieldToDisplay &&
        shouldDisplayConfirmationFieldsBasedOnTransactionData &&
        shouldDisplayConfirmationFieldsBasedOnUserInteraction;

    // Arguments that need to be passed to getInitialValues()
    const initialValuesOptions = {
        config,
        defaultCountry,
        detailsPageFieldsData,
        displayableCustomFields,
        donorAccount,
        formSettings,
        isBillingNameDisplayed,
        systemFields,
        transaction,
    };

    // Arguments that need to be passed to getValidationSchema()
    const validationSchemaOptions = {
        detailsPageFieldsData,
        displayableCustomFields,
        transaction,
        allFieldsAndGroups,
        conditionalLogicOptions,
        subtotal,
    };

    // Arguments that need to be passed to container component
    const confirmationFieldsContainerProps = {
        allFieldsAndGroups,
        conditionalLogicOptions,
        container,
        displayableCustomFields,
        displayConfirmationFieldsContainer,
        formId,
        initialValuesOptions,
        path,
        setErrors,
        setMessages,
        transaction,
        validationSchemaOptions,
    };

    return (
        <ConfirmationFieldsContainer {...confirmationFieldsContainerProps} />
    );
};

ConnectedConfirmationFieldsContainer.propTypes = {
    container: PropTypes.shape({}).isRequired,
};

export default ConnectedConfirmationFieldsContainer;
