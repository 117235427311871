import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Tab.scss";

const Tab = ({ id, activeTab, label, handleTabClick, size = "regular" }) => {
    const handleClick = () => {
        handleTabClick(id);
    };
    const isSelectedTab = id === activeTab;
    const ariaSelectedText = isSelectedTab ? "true" : "false";
    const tabNavBtnClasses = cx(
        "qg-vendor-tab__button",
        "tab__button",
        `tab__button--${size}`,
        isSelectedTab && "tab__button--active",
        "-cursor--pointer",
    );

    return (
        <li
            className="qg-vendor-tab tab"
            key={id}
            role="tab"
            aria-selected={ariaSelectedText}
        >
            <button
                id={id}
                type="button"
                className={tabNavBtnClasses}
                onClick={handleClick}
                data-tab={id}
            >
                {label}
            </button>
        </li>
    );
};

Tab.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    // Allow for tab buttons to contain markup such as icons
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    handleTabClick: PropTypes.func.isRequired,
    size: PropTypes.string,
};

export default Tab;
