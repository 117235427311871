import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { currencyString } from "@qgiv/core-js";
import { ReceiptTotal, getRecurringValuesTextOptions } from "@qgiv/core-donor";
import { selectSpecificFormSetting } from "../../../../redux/slices/formSettingsSlice";
import { selectReceipt } from "../../../../redux/slices/receiptsSlice";

const ConnectedReceiptTotal = ({ control }) => {
    const { currency = {} } = useSelector((state) =>
        selectSpecificFormSetting(state, "currency"),
    );
    const { total = 0, transaction = {} } = useSelector(selectReceipt);
    const {
        hasCompletedPaymentForInvoice = false,
        recurring = null,
        status = "A",
    } = transaction;
    const { options = {} } = control;
    const {
        invoicedStatus = "Invoiced",
        pendingStatus = "Pending",
        totalGiftLabel = "Total Gift",
    } = options;
    let recurringTotalText = "";

    // Total dollar value of the transaction
    const formattedTotal = currencyString(total, currency);

    // Promo code keys can be hard coded as promo codes are not a part of the
    // donation form so they should never be displayed.
    const promoCodes = {
        formattedOriginalPrice: "",
        hasPromo: false,
        promoCodeAppliedLabel: "",
        promoCodes: [],
    };

    const hasRecurringDonation =
        recurring &&
        recurring.frequency_description &&
        !hasCompletedPaymentForInvoice;

    const {
        hasFinalBillingDay,
        formattedFinalBillingDate,
        formattedFirstBillingDate,
        isFirstBillingDayAfterToday,
    } = getRecurringValuesTextOptions(hasRecurringDonation, recurring);
    // Because recurring is set to null if the transaction contains a one time
    // donation we need to hide this logic behind a check for the recurring
    // object otherwise all of the keys that we need to check here will throw a
    // fatal error
    if (hasRecurringDonation) {
        // Final billing date is set to "0000-00-00" when it is not set
        // Set value of recurring total text
        if (isFirstBillingDayAfterToday && hasFinalBillingDay) {
            recurringTotalText = `${recurring.frequency_description} starting ${formattedFirstBillingDate} until ${formattedFinalBillingDate}`;
        } else if (isFirstBillingDayAfterToday && !hasFinalBillingDay) {
            recurringTotalText = `${recurring.frequency_description} starting ${formattedFirstBillingDate}`;
        } else if (!isFirstBillingDayAfterToday && hasFinalBillingDay) {
            recurringTotalText = `${recurring.frequency_description} until ${formattedFinalBillingDate}`;
        } else {
            recurringTotalText = `${recurring.frequency_description}`;
        }
    }

    // Generate flags used to display different parts of the UI
    const displayHourGlassIcon =
        status !== "PR" &&
        !!hasRecurringDonation &&
        !!isFirstBillingDayAfterToday;
    const displayInvoiceIcon = status === "PR";
    const displayInvoiceStatus = status === "PR";
    const displayOriginalPrice = !!promoCodes.hasPromo;
    const displayPendingStatus =
        status !== "PR" &&
        !!hasRecurringDonation &&
        !!isFirstBillingDayAfterToday;
    const displayPromoCodes = !!promoCodes.hasPromo;
    const displayRecurringText = !!hasRecurringDonation;

    // Consolidate props into object while passing reassigned variables to the
    // appropriate key when needed.
    const receiptTotalProps = {
        displayHourGlassIcon,
        displayInvoiceIcon,
        displayInvoiceStatus,
        displayOriginalPrice,
        displayPendingStatus,
        displayPromoCodes,
        displayRecurringText,
        invoicedStatus,
        pendingStatus,
        recurringTotalText,
        total: formattedTotal,
        totalLabel: totalGiftLabel,
        ...promoCodes,
    };
    return <ReceiptTotal {...receiptTotalProps} />;
};

ConnectedReceiptTotal.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            invoicedStatus: PropTypes.string,
            pendingStatus: PropTypes.string,
            totalGiftLabel: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedReceiptTotal;
