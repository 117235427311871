import { getPaymentTypeInitialValue } from "@qgiv/core-donor";
import getBankFieldsInitialValues from "./bankFieldsInitialValues";
import getCreditCardInitialValues from "./creditCardInitialValues";
import getBillingAddressInitialValues from "./billingAddressInitialValues";
import getCaptchaInitialValue from "./captchaInitialValues";
import getApplePayTokenInitialValue from "./applePayTokenInitialValue";
import getDonorAccountActionsInitialValues from "./donorAccountActionsInitialValues";
import getPayPalVenmoInitialValues from "./paypalVenmoInitialValues";

/**
 * @typedef DonorAccountPaymentInfoItems
 * @property {number} id
 * @property {number} last
 * @property {string} expiry
 * @property {string} ccType
 * @property {string} title
 */

/**
 * @typedef BillingDetailsSettings
 * @property {boolean} captcha
 * @property {object} [donorAccount]
 * @property {DonorAccountPaymentInfoItems[]} donorAccount.paymentInfo
 * @property {boolean} hasSelectedRecurringDonation
 * @property {boolean} isLoggedIn
 * @property {boolean} enableAchPayments
 * @property {boolean} enableCCPayments
 * @property {boolean} enableDonorLogins
 * @property {boolean} enablePaypalPayments
 */

/**
 * @public
 * @function getBillingDetailsInitialValues
 * @param {BillingDetailsSettings} settings settings from state to determine initial values
 * @returns {object} initial values for formik for payment page
 */
const getBillingDetailsInitialValues = ({
    billingDefaultCountry,
    captcha,
    donorAccount,
    enableDonorLogins,
    hasSelectedRecurringDonation,
    isLoggedIn,
    applePayEnabled,
    enableAchPayments,
    enableCCPayments,
    enablePaypalPayments,
    mailingAddressFieldsData,
}) => {
    const paymentTypeSettings = {
        enableAchPayments,
        enableCCPayments,
        enablePaypalPayments,
    };

    // -------------------------------------------------------------------------
    // NOTE: PDR is not allowing Stored Payments to display if donor accounts
    // are disabled.  The other produces work differently. So in order to use
    // the same initialValue helper we will just pass an empty object when donor
    // accounts are disabled
    // -------------------------------------------------------------------------
    const donorAccountIfEnabled = enableDonorLogins ? donorAccount : {};

    const values = {
        ...getBankFieldsInitialValues(enableAchPayments),
        ...getCreditCardInitialValues(enableCCPayments),
        ...getBillingAddressInitialValues(
            billingDefaultCountry,
            mailingAddressFieldsData,
        ),
        ...getCaptchaInitialValue(captcha),
        ...getPayPalVenmoInitialValues(enablePaypalPayments),
        ...getApplePayTokenInitialValue(applePayEnabled),
        ...getDonorAccountActionsInitialValues({
            enableDonorLogins,
            donorAccount,
            hasSelectedRecurringDonation,
            isLoggedIn,
        }),
        Payment_Type: getPaymentTypeInitialValue(
            paymentTypeSettings,
            donorAccountIfEnabled,
        ),
    };

    return values;
};

export default getBillingDetailsInitialValues;
