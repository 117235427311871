import React from "react";
import DedicationField from "./DedicationField";

/**
 * DedicationFields Component
 * @description A React component that renders a list of dedication fields with various properties and handlers.
 * @param {object} props - The properties passed to the component.
 * @param {number} props.currentDisplay - The current display type (e.g., Desktop, Mobile, Text-to-Donate).
 * @param {object} props.errors - An object containing validation errors for the fields.
 * @param {Array} props.fields - An array of field configuration objects.
 * @param {Function} props.handleBlur - The function to handle blur events.
 * @param {Function} props.handleChange - The function to handle change events.
 * @param {Function} props.setFieldValue - The function to set the value of a field.
 * @param {object} props.touched - An object indicating which fields have been touched.
 * @param {object} props.values - An object containing the current values of the fields.
 * @returns {React.ReactElement[]} An array of rendered dedication field components.
 */
const DedicationFields = ({
    currentDisplay,
    errors,
    fields,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    values,
}) => {
    const dedicationFieldProps = {
        currentDisplay,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        values,
    };
    return fields.map((field) => (
        <div key={field.id} className="-padding-top--15">
            <DedicationField {...dedicationFieldProps} field={field} />
        </div>
    ));
};

export default DedicationFields;
