/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./AccordionHeaderReceiptContent.scss";

const AccordionHeaderReceiptContent = ({
    activeEventKeys,
    displayPrintLink = true,
    eventKey,
    label,
    labelSubText,
    printLinkLabel = "Print",
}) => {
    const isActive = activeEventKeys.includes(eventKey);
    // Prevent clicks from bubbling up and closing the opened accordion but
    // don't trigger it if event was propagated by the suspendedPrintClick function
    const handlePrintClick = (e) => {
        if (e && e.dispatchConfig && e.stopPropagation) {
            e.stopPropagation();
        }
        if (e.type === "keydown" && e.key === "Tab") {
            return;
        }
        const content = document.getElementById("receipt-summary");
        window.print(content);
    };

    // Waits for the accordion to open so that the receipt html is visible
    // before triggering the print function
    const suspendedPrintClick = (e) => {
        // event is lost because react re-renders
        // so we need to call persist to maintain
        // event data for handle function
        // https://reactjs.org/docs/legacy-event-pooling.html
        e.persist();
        setTimeout(() => handlePrintClick(e), 1000);
    };

    return (
        <div className="accordion-header-receipt-content">
            <div className="grid">
                <div
                    className={`col col--${
                        displayPrintLink ? "8" : "12"
                    } col--no-top-gutter`}
                >
                    <strong className="-type--regular -color--theme-primary">
                        {label}
                    </strong>
                    <br />
                    <small className="-color--system-medium-dark -margin-top--5">
                        {labelSubText}
                    </small>
                </div>
                {displayPrintLink && (
                    <div className="col col--4 col--no-top-gutter -text--right">
                        <p>
                            <a
                                className="print-link ignore-typography"
                                tabIndex="0"
                                role="link"
                                onClick={
                                    isActive
                                        ? handlePrintClick
                                        : suspendedPrintClick
                                }
                                onKeyDown={
                                    isActive
                                        ? handlePrintClick
                                        : suspendedPrintClick
                                }
                            >
                                <Icon
                                    type="FontAwesome"
                                    glyph="print-regular"
                                    classNames={[
                                        "-type--regular -margin-right--10",
                                    ]}
                                />
                                <strong>{printLinkLabel}</strong>
                            </a>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

AccordionHeaderReceiptContent.propTypes = {
    activeEventKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
    eventKey: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    labelSubText: PropTypes.string.isRequired,
    printLinkLabel: PropTypes.string,
    displayPrintLink: PropTypes.bool,
};

export default AccordionHeaderReceiptContent;
