import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import QFieldInput from "../QFieldInput";
import { DetailsStepCompanyTabs } from "../../DetailsStep";

const SystemFieldGroupName = (props) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    const {
        field: { fields },
        settings: {
            allowCompanyContributions,
            allowIndividualContributions,
            formSettings: {
                individualTabLabel,
                companyTabLabel,
                requireNameForCompanyDonation,
            },
        },
        setFieldValue,
        values: { Company_Donation },
    } = props;

    const handleTabClick = (tab) => {
        setFieldValue("Company_Donation", tab === "company-tab");
    };

    const showCompanyField =
        allowCompanyContributions && allowIndividualContributions
            ? Company_Donation
            : allowCompanyContributions
              ? true
              : false;

    // Returns boolean indicating whether first/last names are included with company donations
    const includeFirstLastNameWithCompany =
        String(requireNameForCompanyDonation) === "1";

    return (
        <div className="grid">
            {/* tabs interface */}
            {allowCompanyContributions && allowIndividualContributions && (
                <div className="col col--12">
                    <DetailsStepCompanyTabs
                        isCompanyDonation={Company_Donation}
                        handleTabClick={handleTabClick}
                        individualTabLabel={individualTabLabel || undefined}
                        companyTabLabel={companyTabLabel || undefined}
                    />
                </div>
            )}
            {/* company fields */}
            {showCompanyField &&
                fields
                    .filter((field) => {
                        // Check if setting to include first/last names with company donations is enabled
                        return includeFirstLastNameWithCompany
                            ? // If true, don't exclude first/last from filter
                              Number(field.type) !== FieldType.SALUTATION &&
                                  Number(field.type) !== FieldType.SUFFIX
                            : // If false, we exclude first/last names
                              Number(field.type) !== FieldType.SALUTATION &&
                                  Number(field.type) !== FieldType.SUFFIX &&
                                  Number(field.type) !== FieldType.FIRST_NAME &&
                                  Number(field.type) !== FieldType.LAST_NAME;
                    })
                    .map((field, i) => {
                        const fieldType = Number(field.type);
                        const sixCol =
                            fieldType === FieldType.FIRST_NAME ||
                            fieldType === FieldType.LAST_NAME
                                ? " col--sm-6"
                                : "";
                        return (
                            <div
                                key={field.id}
                                className={`col col--12${sixCol}`}
                            >
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    })}
            {allowIndividualContributions &&
                !Company_Donation &&
                fields
                    // -------------------------------------------------------------------------
                    // NOTE: Filter out company field on purpose
                    // -------------------------------------------------------------------------
                    .filter((field) => Number(field.type) !== FieldType.COMPANY)
                    .map((field, i) => {
                        const fieldType = Number(field.type);
                        const sixCol =
                            fieldType === FieldType.FIRST_NAME ||
                            fieldType === FieldType.LAST_NAME
                                ? " col--sm-6"
                                : "";
                        return (
                            <div
                                key={field.id}
                                className={`col col--12${sixCol}`}
                            >
                                <QFieldInput {...props} field={field} />
                            </div>
                        );
                    })}
        </div>
    );
};

SystemFieldGroupName.propTypes = {
    field: PropTypes.shape({
        fields: PropTypes.array,
    }),
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        allFieldsAndGroups: PropTypes.array.isRequired,
        allowCompanyContributions: PropTypes.bool,
        allowIndividualContributions: PropTypes.bool,
        formSettings: PropTypes.shape({
            individualTabLabel: PropTypes.string,
            companyTabLabel: PropTypes.string,
            requireNameForCompanyDonation: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        }),
    }),
    values: PropTypes.shape({
        Company_Donation: PropTypes.bool,
    }),
};

export default SystemFieldGroupName;
