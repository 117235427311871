import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@qgiv/core-donor";
import { Icon } from "@qgiv/core-react";

const ReceiptDetailsGifts = ({
    displayDedication = false,
    displayGiftAssist = false,
    formattedDedicationLabel = "",
    formattedFeeCoverage = "",
    giftAssistAdditionalText = "Thanks for giving a little extra!",
    giftAssistLabel = "Processing Fee",
    gifts = [],
}) => (
    <div className="col col--12 col--no-top-gutter">
        {/* Itemized list of gifts */}
        {gifts.map((gift) => {
            const {
                displayRestriction = false,
                displaySubRestriction = false,
                formattedAmount = "",
                giftDetailLabel = "Gift",
                id = "",
                restriction = "",
                subRestriction = "",
            } = gift;

            return (
                <div key={id}>
                    <div className="grid">
                        <div className="col col--6 -padding-top--10">
                            <p>{giftDetailLabel}</p>
                            {displayRestriction && <small>{restriction}</small>}
                            {displaySubRestriction && (
                                <small>{` - ${subRestriction}`}</small>
                            )}
                        </div>
                        <div className="col col--6 -text--right -padding-top--10">
                            <p>{formattedAmount}</p>
                        </div>
                    </div>
                    <Divider dashed />
                </div>
            );
        })}
        {/* Gift Assist for the entire transaction */}
        {displayGiftAssist && (
            <div>
                <div className="grid">
                    <div className="col col--6 -padding-top--10">
                        <p>
                            {giftAssistLabel}
                            <br />
                            <small>{giftAssistAdditionalText}</small>
                        </p>
                    </div>
                    <div className="col col--6 -text--right -padding-top--10">
                        <p>{formattedFeeCoverage}</p>
                    </div>
                </div>
                <Divider dashed />
            </div>
        )}
        {/* Dedications for the entire transaction */}
        {displayDedication && (
            <div>
                <div className="grid">
                    <div className="col col--12 -padding-top--10">
                        <p>
                            <Icon glyph="ribbon-solid" type="FontAwesome" />{" "}
                            {formattedDedicationLabel}
                        </p>
                    </div>
                </div>
                <Divider dashed />
            </div>
        )}
    </div>
);

ReceiptDetailsGifts.propTypes = {
    displayDedication: PropTypes.bool.isRequired,
    displayGiftAssist: PropTypes.bool.isRequired,
    formattedDedicationLabel: PropTypes.string.isRequired,
    formattedFeeCoverage: PropTypes.string.isRequired,
    giftAssistAdditionalText: PropTypes.string.isRequired,
    giftAssistLabel: PropTypes.string.isRequired,
    gifts: PropTypes.arrayOf(
        PropTypes.shape({
            formattedAmount: PropTypes.string.isRequired,
            displayRestriction: PropTypes.bool.isRequired,
            displaySubRestriction: PropTypes.bool.isRequired,
            giftDetailLabel: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            restriction: PropTypes.string.isRequired,
            subRestriction: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default ReceiptDetailsGifts;
