import React from "react";
import PropTypes from "prop-types";
import { hexToRgb } from "@qgiv/core-js";

import "./LoadingDots.scss";

const LoadingDots = ({ center, children, color, height = 25, inlineBlock }) => {
    const _color =
        color && color.includes("#") && color.length === 7
            ? hexToRgb(color)
            : null;
    const _height = parseInt(height, 10);
    const centerClass = center ? " loading-dots--center" : "";
    const inlineBlockClass =
        inlineBlock && !center // center CSS overrides inlineBlock CSS, so this prevents adding the class if centered is true
            ? " loading-dots--inline-block"
            : "";

    return (
        <div
            className={`loading-dots${centerClass}${inlineBlockClass}`}
            style={{
                padding: `${_height / 2 - 8}px`,
            }}
        >
            <div
                className="loading-dots__dots"
                height={height}
                style={{
                    "--dot-color": _color
                        ? `${_color.r}, ${_color.g}, ${_color.b}`
                        : "",
                    transform: `scale(${_height / 100})`,
                }}
            />
            {children && (
                <div style={{ position: "relative", top: `${_height / 2}px` }}>
                    {children}
                </div>
            )}
        </div>
    );
};

LoadingDots.propTypes = {
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    center: PropTypes.bool,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    children: PropTypes.any,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    color: PropTypes.string,
    height: PropTypes.number,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    inlineBlock: PropTypes.bool,
};

export default LoadingDots;
