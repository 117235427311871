import React from "react";
import { useSelector } from "react-redux";
import { getDisplaySubmit } from "@qgiv/core-donor";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { selectCmsControlByIdByPage } from "../../../redux/slices/cmsSettingsSlice";
import { selectIsTextToDonate } from "../../../redux/slices/smsDataSlice";
import { selectGiftDetails } from "../../../redux/slices/donationDetailsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectRecurringFrequencyByValue } from "../../../redux/slices/donationSettingsSlice";
import {
    selectIsCms,
    selectIsDisplayTypeModal,
} from "../../../redux/slices/configSettingsSlice";
import SubmitButtonLabelWithContent from "../../common/Submit/SubmitButtonLabelWithContent";
import Submit from "../../common/Submit";

/**
 * ConnectedSubmit
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement} Submit
 */
const ConnectedSubmit = ({ controlId }) => {
    const {
        ENUMS: { CmsPageStandardizer },
    } = constants;
    const giftDetails = useSelector(selectGiftDetails);
    const {
        hasSelectedRecurringDonation,
        Recurring_Frequency,
        subtotal,
        total,
    } = giftDetails;

    const isCms = useSelector(selectIsCms);
    const { currency } = useSelector(selectAllFormSettings);

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(
            state,
            controlId,
            CmsPageStandardizer.SINGLE_STEP_FOOTER,
        ),
    );
    const { options = {} } = control;

    const {
        submitButtonLabel = "Give %AMOUNTWITHFREQUENCY%",
        textToDonateSubmitLabel = "Give %AMOUNTWITHFREQUENCY%",
    } = options;

    const isDisplayTypeModal = useSelector(selectIsDisplayTypeModal);
    const isTextToDonate = useSelector(selectIsTextToDonate);

    const selectedFrequency = useSelector((state) =>
        selectRecurringFrequencyByValue(state, Recurring_Frequency),
    );

    const getButtonLabel = () => {
        if (isTextToDonate) {
            return textToDonateSubmitLabel;
        }
        return submitButtonLabel;
    };

    const buttonLabel = getButtonLabel();

    const frequencyUnitLabel = selectedFrequency?.labelPerAmount;
    const submitButtonLabelWithContentProps = {
        buttonLabel,
        currency,
        frequencyUnitLabel,
        hasSelectedRecurringDonation,
        total,
    };

    // -------------------------------------------------------------------------
    // NOTE: We are looking at subtotal here instead of total. This will prevent
    // a static gift assist to progress without a donation amount selected.
    // -------------------------------------------------------------------------
    const disabled = subtotal === 0;

    // this might change when we make payment page movable in page order.
    const nextButtonLabel = (
        <SubmitButtonLabelWithContent {...submitButtonLabelWithContentProps} />
    );

    const { values, isSubmitting } = useFormikContext();

    // Display the submit button if the user is in CMS or the payment methods
    // other than Apple Pay are visible
    const displaySubmit = getDisplaySubmit({
        isCms,
        values,
    });

    const submitProps = {
        controlId,
        backButtonLabel: "",
        disabled,
        displayAcceptedPaymentMethods: false,
        displayBackButton: false,
        displaySubmit,
        handleBackButtonClick: () => {},
        isPreview: false,
        isSubmitting,
        nextButtonLabel,
        scopeToIframe: !isDisplayTypeModal,
        submitTooltipMessage: "",
        showBackground: !!isSubmitting,
    };

    return <Submit {...submitProps} />;
};

export default ConnectedSubmit;
