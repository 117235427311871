import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { submitConfirmationFields } from "@qgiv/donation-form";
import prepareValuesForConfirmationFieldsSubmit from "../../../utility/submit/confirmationFieldsSubmit";
import DonationFormControl from "../../DonationFormControl";
import { setShouldDisplayConfirmationFieldsBasedOnUserInteraction } from "../../../redux/slices/appSettingsSlice";
import {
    getInitialTouched,
    getInitialValues,
    getValidationSchema,
} from "./formik";

const ConfirmationFieldsContainer = ({
    allFieldsAndGroups = [],
    conditionalLogicOptions,
    container = {},
    displayableCustomFields = [],
    displayConfirmationFieldsContainer = false,
    formId = "",
    initialValuesOptions = {},
    path = "",
    setErrors = () => {},
    setMessages = () => {},
    transaction = {},
    validationSchemaOptions = {},
}) => {
    const { controls = [] } = container;
    const dispatch = useDispatch();

    // Handler that runs on form submission once all of the traditional Formik
    // validation has run
    const onSubmit = (values = {}, actions = {}) => {
        const { setSubmitting = () => {} } = actions;

        // Handle a successful API request, a failed API request and special
        // FE single field validation error logic
        const handleSubmitSuccess = (response) => {
            const { data } = response;
            setSubmitting(false);

            if (data.errors) {
                const message = JSON.parse(data.errors);
                dispatch(setErrors(message));
            } else if (data.success) {
                const success = ["Got it! Thanks for the additional info."];
                dispatch(
                    setShouldDisplayConfirmationFieldsBasedOnUserInteraction(
                        false,
                    ),
                );
                dispatch(setMessages(success));
            }
        };

        const handleSubmitError = ({ message = [] }) => {
            setSubmitting(false);
            dispatch(setErrors([message]));
        };

        // -------------------------------------------------------------------------
        // NOTE: With the inclusion of Privacy Options (two checkboxes that can never be disabled),
        // ANY submission of P2P donationForm confirmation fields constitutes a valid response.
        // This will NOT be the case for TheOneForm™ at launch, when we'll need to add
        // logic below to calculate if at least one field in Confirmation Fields has a valid
        // field value.
        // -------------------------------------------------------------------------
        const hasValidFieldValue = true;

        // If there is an error that needs to be thrown, remove any existing
        // error message from the UI before cleaning the values and submitting
        // the form.
        if (hasValidFieldValue) {
            dispatch(setMessages([]));
            const cleanValues = prepareValuesForConfirmationFieldsSubmit({
                allFieldsAndGroups,
                conditionalLogicOptions,
                displayableCustomFields,
                formId,
                values,
                transaction,
            });
            submitConfirmationFields(path, cleanValues)
                .then(handleSubmitSuccess)
                .catch(handleSubmitError);
        }
    };

    const formikProps = {
        onSubmit,
        initialTouched: getInitialTouched(),
        initialValues: getInitialValues(initialValuesOptions),
        validationSchema: getValidationSchema(validationSchemaOptions),
    };

    // The infrastructure for Formik needs to be set up at the container level
    // here as it needs to encapsulate the fields and the submit button, all of
    // which are separate controls
    return displayConfirmationFieldsContainer ? (
        <div className="qg-vendor-controls-container controls-container -margin-top--15">
            <div className="-callout -padding--30">
                <Formik {...formikProps}>
                    <Form>
                        {controls.map((control) => {
                            const { id = "" } = control;
                            return (
                                <DonationFormControl
                                    control={control}
                                    controlId={id}
                                    key={id}
                                />
                            );
                        })}
                    </Form>
                </Formik>
            </div>
        </div>
    ) : null;
};

ConfirmationFieldsContainer.propTypes = {
    allFieldsAndGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    conditionalLogicOptions: PropTypes.shape({
        donationAmountFromRedux: PropTypes.number,
        hasSelectedRecurringDonation: PropTypes.bool,
        usesReduxDonationAmountValue: PropTypes.bool,
        usesReduxRecurringValue: PropTypes.bool,
    }).isRequired,
    container: PropTypes.shape({
        controls: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    displayableCustomFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    displayConfirmationFieldsContainer: PropTypes.bool.isRequired,
    formId: PropTypes.number.isRequired,
    initialValuesOptions: PropTypes.shape({}).isRequired,
    path: PropTypes.string.isRequired,
    setErrors: PropTypes.func.isRequired,
    setMessages: PropTypes.func.isRequired,
    transaction: PropTypes.shape({}).isRequired,
    validationSchemaOptions: PropTypes.shape({}).isRequired,
};

export default ConfirmationFieldsContainer;
