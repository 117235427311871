import { createSelector } from "@reduxjs/toolkit";

export const selectTotalGiftSelector = createSelector(
    [(state) => state.donationDetails.giftDetails],
    (giftDetails) => giftDetails.total,
);

export const selectSubtotalGiftSelector = createSelector(
    [(state) => state.donationDetails.giftDetails],
    (giftDetails) => giftDetails.subtotal,
);

export const selectStoredFormikGiftDetailsSelector = createSelector(
    [(state) => state.donationDetails.giftDetails],
    (giftDetails) => {
        const newObject = {
            ...giftDetails,
        };
        if (newObject?.giftAssistAmount) {
            delete newObject.giftAssistAmount;
        }
        delete newObject.total;
        delete newObject.subtotal;
        delete newObject.hasSelectedRecurringDonation;

        return newObject;
    },
);
