import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import {
    selectAbandonedGiftData,
    setAbandonedGiftOtherAmountData,
} from "../../../../redux/slices/abandonedGiftSlice";
import { updateGiftDetails } from "../../../../redux/slices/donationDetailsSlice";
import { selectAllDonationSettings } from "../../../../redux/slices/donationSettingsSlice";

// -------------------------------------------------------------------------
// NOTE: This kind of action got us in trouble with React 18
// It is important to use the same action to set both the redux and formik values
// that need to be updated directly.
// -------------------------------------------------------------------------

const useAcceptReminder = () => {
    const {
        ENUMS: { ItemType },
    } = constants;
    const dispatch = useDispatch();
    const { setFieldValue } = useFormikContext();

    const abandonedGiftData = useSelector(selectAbandonedGiftData);
    const donationSettings = useSelector(selectAllDonationSettings);
    const { activeAmounts = [], activeDonationFrequencies = [] } =
        donationSettings;

    const { reminderData = {}, acceptedReminder = false } = abandonedGiftData;

    useEffect(() => {
        if (acceptedReminder) {
            const {
                acceptedGiftAssist,
                amount = 0,
                ongoing = false,
                recurringFrequency = "m",
            } = reminderData;

            // TODO: move flag generation to helper function(s)
            const selectedAmount =
                activeAmounts.find(
                    (activeAmount) => activeAmount.amount === amount,
                ) || {};
            const hasSelectedAmount = Object.keys(selectedAmount).length > 0;
            const selectedAmountIsOtherAmount =
                selectedAmount?.amountType === ItemType.OTHER;

            const otherAmount =
                activeAmounts.find(
                    (activeAmount) =>
                        activeAmount.amountType === ItemType.OTHER,
                ) || {};
            const hasOtherAmount = Object.keys(otherAmount).length > 0;

            const { displayForOneTime = false, displayForRecurring = false } =
                selectedAmount;

            const displayOtherForOneTime =
                otherAmount?.displayForOneTime || false;
            const displayOtherForRecurring =
                otherAmount?.displayForRecurring || false;

            const selectedAmountIsAvailableForRecurringType = ongoing
                ? displayForRecurring
                : displayForOneTime;

            const otherAmountIsAvailableForRecurringType = ongoing
                ? displayOtherForRecurring
                : displayOtherForOneTime;

            const selectedAmountExistsWithWrongRecurringType =
                hasSelectedAmount && !selectedAmountIsAvailableForRecurringType;

            const selectedRecurringFrequency =
                activeDonationFrequencies.find(
                    (donationFrequency) =>
                        donationFrequency.value === recurringFrequency,
                ) || {};

            const hasSelectedRecurringFrequency =
                Object.keys(selectedRecurringFrequency).length > 0;

            const shouldTryToUseOtherAmount =
                !hasSelectedAmount ||
                selectedAmountExistsWithWrongRecurringType ||
                selectedAmountIsOtherAmount;
            // end of flag generation

            if (
                hasSelectedAmount &&
                !selectedAmountIsOtherAmount &&
                selectedAmountIsAvailableForRecurringType
            ) {
                const updatedAmountIdFieldKey = ongoing
                    ? "Selected_Recurring_Id"
                    : "Selected_One_Time_Id";
                const updatedGiftData = {
                    [updatedAmountIdFieldKey]: selectedAmount.id,
                    hasSelectedRecurringDonation: ongoing,
                };
                if (hasSelectedRecurringFrequency) {
                    updatedGiftData.Recurring_Frequency =
                        selectedRecurringFrequency.value;
                }
                if (acceptedGiftAssist) {
                    updatedGiftData.Gift_Assist = true;
                }
                // redux setting
                dispatch(updateGiftDetails(updatedGiftData));
                // formik setting
                setFieldValue(updatedAmountIdFieldKey, selectedAmount.id);
                if (hasSelectedRecurringFrequency && ongoing) {
                    setFieldValue(
                        "Recurring_Frequency",
                        selectedRecurringFrequency.value,
                    );
                }
                if (acceptedGiftAssist) {
                    setFieldValue("Gift_Assist", true);
                }
                // other amount is being used
            } else if (
                shouldTryToUseOtherAmount &&
                hasOtherAmount &&
                otherAmountIsAvailableForRecurringType
            ) {
                const updatedAmountIdFieldKey = ongoing
                    ? "Selected_Recurring_Id"
                    : "Selected_One_Time_Id";
                const updatedAmountValueKey = ongoing
                    ? "Other_Recurring_Amount"
                    : "Other_One_Time_Amount";
                const updatedGiftData = {
                    [updatedAmountIdFieldKey]: otherAmount.id,
                    [updatedAmountValueKey]: amount,
                    hasSelectedRecurringDonation: ongoing,
                };
                if (hasSelectedRecurringFrequency && ongoing) {
                    updatedGiftData.Recurring_Frequency =
                        selectedRecurringFrequency.value;
                }
                if (acceptedGiftAssist) {
                    updatedGiftData.Gift_Assist = true;
                }
                // redux setting
                dispatch(updateGiftDetails(updatedGiftData));
                dispatch(
                    setAbandonedGiftOtherAmountData({
                        injectedUnformattedCurrencyValue: `${amount}`,
                    }),
                );
                // formik setting
                setFieldValue(updatedAmountIdFieldKey, otherAmount.id);
                setFieldValue(updatedAmountValueKey, `${amount}`);
                if (hasSelectedRecurringFrequency && ongoing) {
                    setFieldValue(
                        "Recurring_Frequency",
                        selectedRecurringFrequency.value,
                    );
                }
                if (acceptedGiftAssist) {
                    setFieldValue("Gift_Assist", true);
                }
            }
        }
    }, [
        ItemType.OTHER,
        acceptedReminder,
        activeAmounts,
        activeDonationFrequencies,
        dispatch,
        reminderData,
        setFieldValue,
    ]);
};

export default useAcceptReminder;
