import React from "react";
import PropTypes from "prop-types";
import ConnectedDonorLogin from "../../../common/Login/ConnectedDonorLogin";
import "./DonationFormReceiptBase.scss";

// -------------------------------------------------------------------------
// NOTE:
// Do not remove the .receipt class from this element without adding
// it back somewhere else on the receipt page as this class is used to
// trigger a purchase event in Tag Manager that is important for client
// analytics.
// HelpDesk Article that references the .receipt class as an important
// element when adding a trigger for visiting the receipt page:
// https://support.qgiv.com/hc/en-us/articles/115006117667-Goal-Conversion-Tracking-in-Google-Analytics
// -------------------------------------------------------------------------
const DonationFormReceiptBase = ({ children, enableDonorLogins }) => (
    <section className="page">
        {/* Sets the width of the login component, controls and the message component */}
        {/* Do not remove receipt class. See above */}
        <div className="page__content receipt">
            {enableDonorLogins && (
                <div className="donation-form__header--login -margin-bottom--30 -text--right">
                    <ConnectedDonorLogin />
                </div>
            )}
            {children}
        </div>
    </section>
);

DonationFormReceiptBase.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    enableDonorLogins: PropTypes.bool.isRequired,
};

export default DonationFormReceiptBase;
