import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getDisplayAddressPasswordAndNickname,
    getDisplayDigitalWalletPaymentOptions,
    getDisplayStandardPaymentMethods,
} from "@qgiv/core-donor";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";
import NewPaymentMethods from "./NewPaymentMethods";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectShouldDisplayApplePay } from "../../../../redux/slices/appSettingsSlice";

const ConnectedNewPaymentMethods = ({
    controlId,
    hasToggledPaymentMethodView,
}) => {
    const formikContext = useFormikContext();
    const { values } = formikContext;

    const isCms = useSelector(selectIsCms);
    const { enableDonorLogins } = useSelector(selectAllFormSettings);

    // PDR no longer has a nickname input
    const displayAddressAndPassword = getDisplayAddressPasswordAndNickname({
        isCms,
        values,
    });

    const shouldDisplayApplePay = useSelector(selectShouldDisplayApplePay);
    const formSettings = useSelector(selectAllFormSettings);
    const { paymentData = {} } = formSettings;
    const {
        enableAchPayments = false,
        enableCCPayments = false,
        enablePaypalPayments = false,
    } = paymentData;

    // Only render the component if Apple Pay or PayPal is enabled
    const displayDigitalWalletPaymentOptions =
        getDisplayDigitalWalletPaymentOptions({
            enablePaypalPayments,
            shouldDisplayApplePay,
        });

    // If the user is not in CMS and they select Apple Pay or PayPal hide all
    // of the payment methods that are not Apple Pay or PayPal
    const displayStandardPaymentMethods = getDisplayStandardPaymentMethods({
        enableAchPayments,
        enableCCPayments,
        isCms,
        values,
    });

    return (
        <NewPaymentMethods
            controlId={controlId}
            displayAddressAndPassword={displayAddressAndPassword}
            displayDigitalWalletPaymentOptions={
                displayDigitalWalletPaymentOptions
            }
            displayStandardPaymentMethods={displayStandardPaymentMethods}
            enableDonorLogins={enableDonorLogins}
            hasToggledPaymentMethodView={hasToggledPaymentMethodView}
        />
    );
};

ConnectedNewPaymentMethods.propTypes = {
    controlId: PropTypes.number.isRequired,
    hasToggledPaymentMethodView: PropTypes.bool.isRequired,
};

export default ConnectedNewPaymentMethods;
