import { createSlice } from "@reduxjs/toolkit";
import { promiseTransaction as initialState } from "../initialState";

const getPromiseTransactionInitialState = () => ({
    ...initialState,
    hasActiveFulfillment: Object.keys(initialState).length > 0,
});

export const promiseTransactionInitialState =
    getPromiseTransactionInitialState();

export const slice = createSlice({
    name: "promiseTransaction",
    initialState: promiseTransactionInitialState,
    reducers: {
        setPromiseTransaction: (state, action) => action.payload,
    },
});

// actions
export const { setPromiseTransaction } = slice.actions;

// selectors
export const selectPromiseTransaction = (state) => state.promiseTransaction;

export const selectHasActiveFulfillment = (state) =>
    state.promiseTransaction.hasActiveFulfillment;

export const selectInvoiceReceiptId = (state) =>
    state.promiseTransaction.invoiceReceiptId;

export const selectHasCompletedFulfillment = (state) => {
    const { receiptId } = state.promiseTransaction;
    if (receiptId?.length > 0) {
        return true;
    }
    return false;
};

export default slice.reducer;
