import { createSlice } from "@reduxjs/toolkit";

import { eventListSettings as initialState } from "../initialState";

const getFormSettingsInitialState = () => initialState;
export const formSettingsInitialState = getFormSettingsInitialState();

export const slice = createSlice({
    name: "formSettings",
    initialState: formSettingsInitialState,
    reducers: {
        setFormSettings: (state, action) => action.payload,
    },
});

// selectors
export const selectEventListSettings = (state) =>
    state?.eventListSettings || {};

export default slice.reducer;
