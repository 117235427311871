import { constants } from "@qgiv/core-js";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getHasDisplayableDedicationOnThisPage } from "@qgiv/donation-form";
import {
    selectCustomFieldValues,
    selectDedicationsValues,
    selectDonorDetails,
    selectGiftDetails,
    selectMatchingValues,
    selectTotalGift,
} from "../../redux/slices/donationDetailsSlice";
import {
    selectAllAdditionalDetailsPageCustomFields,
    selectAllDetailsPageCustomFields,
    selectAllFieldsAndGroups,
    selectAllFieldsInSystemFields,
    selectDetailsPageFieldsData,
    selectHasDisplayableMatchingGiftOnPage,
} from "../../redux/slices/fieldsSlice";
import { buildDonorDetailsPageFormikSettings } from "./pageDataHelper";
import { selectAllFormSettings } from "../../redux/slices/formSettingsSlice";
import {
    selectAllDonationSettings,
    selectMatchingGiftSettings,
} from "../../redux/slices/donationSettingsSlice";
import { selectDedicationSettings } from "../../redux/slices/dedicationSettingsSlice";
import { selectCurrentDisplay } from "../../redux/slices/configSettingsSlice";
import { selectDonorAccount } from "../../redux/slices/donorAccountSlice";
import {
    selectHasActiveFulfillment,
    selectPromiseTransaction,
} from "../../redux/slices/promiseTransactionSlice";
import { selectUrlShortcuts } from "../../redux/slices/urlShortcutsSlice";

const useDonorDetailsPageData = () => {
    const {
        ENUMS: { CmsPageStandardizer },
    } = constants;

    const formSettings = useSelector(selectAllFormSettings);
    const donationSettings = useSelector(selectAllDonationSettings);
    const dedicationSettings = useSelector(selectDedicationSettings);
    const currentDisplay = useSelector(selectCurrentDisplay);
    // -------------------------------------------------------------------------
    // NOTE: Current Page becomes unreliable when form is Single Page. Since we
    // are using this data only on the Details page, we can hardcode.
    // -------------------------------------------------------------------------
    const currentPage = CmsPageStandardizer.DETAILS;
    const matchingSettings = useSelector(selectMatchingGiftSettings);
    const donorAccount = useSelector(selectDonorAccount);
    const donorDetails = useSelector(selectDonorDetails);
    const systemFields = useSelector(selectAllFieldsInSystemFields);
    const customFieldValues = useSelector(selectCustomFieldValues);
    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);
    const additionalDetailsPageCustomFields = useSelector(
        selectAllAdditionalDetailsPageCustomFields,
    );
    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);
    const promiseTransaction = useSelector(selectPromiseTransaction);
    const giftDetails = useSelector(selectGiftDetails);
    const total = useSelector(selectTotalGift);
    const storedDedicationValues = useSelector(selectDedicationsValues);
    const storedMatchingGiftValues = useSelector(selectMatchingValues);
    const urlShortcuts = useSelector(selectUrlShortcuts);

    const { Has_Dedication = false } = storedDedicationValues;

    const detailsPageCustomFields = useSelector(
        selectAllDetailsPageCustomFields,
    );
    const { allow_company_contributions } = donationSettings;
    const allowOnlyCompanyContributions = allow_company_contributions === 2;
    const { Company_Donation = false } = donorDetails;

    const detailsPageFieldsData = useSelector((state) =>
        selectDetailsPageFieldsData(state, total),
    );

    const { captcha = false } = formSettings;

    const displayCaptcha = captcha;
    const allFieldsInSystemFields = systemFields;

    // need to bring in saved values from donor details, details page custom fields
    const detailsPageCustomFieldIds = detailsPageCustomFields.map(
        (field) => field.id,
    );
    const detailsPageCustomFieldValues = detailsPageCustomFieldIds.reduce(
        (valuesObj, fieldId) => {
            const fieldValue = customFieldValues[fieldId];
            if (fieldValue) {
                return {
                    ...valuesObj,
                    [fieldId]: fieldValue,
                };
            }
            return valuesObj;
        },
        {},
    );

    // TODO: validation settings to be flushed out later:
    const skipAdditionalDetailsPage = false;
    const hideZeroDollarMailingAddress = false;
    const requireNameForCompanyDonation = false;

    const shouldCaptchaBeRequired =
        displayCaptcha && total === 0 && skipAdditionalDetailsPage;

    const hasDisplayableMatchingGiftOnThisPage = useSelector((state) =>
        selectHasDisplayableMatchingGiftOnPage(
            state,
            currentPage,
            Company_Donation,
        ),
    );
    const { matchingRatio, enableMatching, matchingType } = matchingSettings;

    const hasDisplayableDedicationOnThisPage =
        getHasDisplayableDedicationOnThisPage({
            currentDisplay,
            currentPage,
            dedicationSettings,
        });

    const hasDisplayableDedicationOnAdditionalDetailsPage =
        getHasDisplayableDedicationOnThisPage({
            currentDisplay,
            currentPage: CmsPageStandardizer.ADDITIONAL_DETAILS,
            dedicationSettings,
        });

    const hasDisplayableMatchingGiftOnAdditionalDetailsPage = useSelector(
        (state) =>
            selectHasDisplayableMatchingGiftOnPage(
                state,
                CmsPageStandardizer.ADDITIONAL_DETAILS,
                Company_Donation,
            ),
    );

    const { subtotal, hasSelectedRecurringDonation } = giftDetails;
    const donationAmountFromRedux = subtotal;

    const conditionalLogicOptions = useMemo(
        () => ({
            donationAmountFromRedux,
            hasSelectedRecurringDonation,
            usesReduxDonationAmountValue: true,
            usesReduxRecurringValue: true,
        }),
        [donationAmountFromRedux, hasSelectedRecurringDonation],
    );

    const donorDetailsPageData = useMemo(() => {
        const savedValues = {
            ...donorDetails,
            ...detailsPageCustomFieldValues,
            ...storedMatchingGiftValues,
            ...storedDedicationValues,
        };

        const formikSettings = buildDonorDetailsPageFormikSettings({
            allFieldsAndGroups,
            allFieldsInSystemFields,
            allowOnlyCompanyContributions,
            conditionalLogicOptions,
            currentDisplay,
            currentPage,
            dedicationSettings,
            detailsPageCustomFields,
            detailsPageFieldsData,
            donorAccount,
            enableMatching,
            formSettings,
            hasActiveFulfillment,
            Has_Dedication,
            hasDisplayableDedicationOnThisPage,
            hasDisplayableMatchingGiftOnThisPage,
            hideZeroDollarMailingAddress,
            matchingRatio,
            matchingType,
            promiseTransaction,
            requireNameForCompanyDonation,
            savedValues,
            shouldCaptchaBeRequired,
            subtotal,
            systemFields,
            total,
            urlShortcuts,
        });

        return {
            additionalDetailsPageCustomFields,
            allFieldsAndGroups,
            conditionalLogicOptions,
            displayCaptcha: false,
            documentTitle: "Details",
            formikSettings,
            hasActiveFulfillment,
            hasDisplayableDedicationOnAdditionalDetailsPage,
            hasDisplayableDedicationOnThisPage,
            hasDisplayableMatchingGiftOnAdditionalDetailsPage,
            hasDisplayableMatchingGiftOnThisPage,
        };
    }, [
        Has_Dedication,
        additionalDetailsPageCustomFields,
        allFieldsAndGroups,
        allFieldsInSystemFields,
        allowOnlyCompanyContributions,
        conditionalLogicOptions,
        currentDisplay,
        currentPage,
        dedicationSettings,
        detailsPageCustomFieldValues,
        detailsPageCustomFields,
        detailsPageFieldsData,
        donorAccount,
        donorDetails,
        enableMatching,
        formSettings,
        hasActiveFulfillment,
        hasDisplayableDedicationOnAdditionalDetailsPage,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnAdditionalDetailsPage,
        hasDisplayableMatchingGiftOnThisPage,
        hideZeroDollarMailingAddress,
        matchingRatio,
        matchingType,
        promiseTransaction,
        requireNameForCompanyDonation,
        shouldCaptchaBeRequired,
        storedDedicationValues,
        storedMatchingGiftValues,
        subtotal,
        systemFields,
        total,
        urlShortcuts,
    ]);

    return donorDetailsPageData;
};

export default useDonorDetailsPageData;
