import { current } from "@reduxjs/toolkit";
import { constants } from "@qgiv/core-js";
import getStronglyTypedIncomingOptions from "./optionsToStronglyType";

const {
    ENUMS: { CmsPageStandardizer },
} = constants;

const ActiveEditPage = {
    // standard year round form
    choosegift: CmsPageStandardizer.DONATION_AMOUNTS,
    donordetails: CmsPageStandardizer.DETAILS,
    additionaldetails: CmsPageStandardizer.ADDITIONAL_DETAILS,
    paymentdetails: CmsPageStandardizer.PAYMENT_DETAILS,
    formconfirmation: CmsPageStandardizer.CONFIRMATION,
    qgivevents: CmsPageStandardizer.QGIV_EVENTS,
    // p2p donation form
    p2pdonationamounts: CmsPageStandardizer.DONATION_AMOUNTS,
    p2pdonationdonordetails: CmsPageStandardizer.DETAILS,
    p2padditionaldetails: CmsPageStandardizer.ADDITIONAL_DETAILS,
    p2ppaymentdetails: CmsPageStandardizer.PAYMENT_DETAILS,
    p2pconfirmation: CmsPageStandardizer.CONFIRMATION,
};
// -------------------------------------------------------------------------
// MARK: Shared logic (Single Page and Multi Page)
// -------------------------------------------------------------------------
// We have a problem with ids now that we are strongly typing cms settings
// Existing controls will have a number id
// New Controls will have a string id
// Control Data from system.cms.js will have string ids for both
export const getNewControls = (updatedContainer, controlData) => {
    const { id, parent, type, options } = controlData;

    const numberId = Number(id); // could be NaN

    const updatedControls = [...updatedContainer.controls]; // mutable copy
    const existingControlIds = updatedControls.map((control) => control.id);
    // existing control ids will either be numbers or "new-#" strings
    const updatedControlCurrentlyExists =
        existingControlIds.includes(id) ||
        existingControlIds.includes(numberId);

    // if updating existing control, find control and replace its options
    if (updatedControlCurrentlyExists) {
        const newControls = updatedControls.map((control) => {
            if (control.id === id || control.id === numberId) {
                // We have to strongly type some boolean values for PDR
                // when they come from CMS update. More details in optionsToStronglyType.js

                const optionsWithStrongTyping =
                    getStronglyTypedIncomingOptions(options);

                // We HAVE NOT implemented nested control options in PDR (🎉)
                // so we don't have to worry about looking for those options here
                const replacedOptions = {
                    ...control.options,
                    ...optionsWithStrongTyping,
                };
                return {
                    ...control,
                    options: replacedOptions,
                };
            }
            return control;
        });
        return newControls;
    }
    // creating new control, just push to updated controls array
    const newControl = { id, container: parent, type, options };
    const newControls = [...updatedControls, newControl];
    return newControls;
};

// -------------------------------------------------------------------------
// MARK: Multi Page Specific Logic
// -------------------------------------------------------------------------
export const getUpdatedContainerData = (pageEnum, cmsSettings, parent) => {
    const { pages } = cmsSettings;
    const containers = pages[pageEnum];
    let updatedContainerIndex = 0;
    let updatedContainer = {};

    containers.forEach((container, i) => {
        if (Number(parent) === container.id) {
            updatedContainer = { ...container };
            updatedContainerIndex = i;
        }
    });
    return { updatedContainerIndex, updatedContainer };
};

export const getUpdatedPageData = (cmsSettings, action) => {
    const {
        properties: { id, parent, options, type, activeEdit },
    } = action.payload;

    const controlData = { id, parent, options, type };

    const pageEnum = ActiveEditPage[activeEdit];

    const { updatedContainerIndex, updatedContainer } = getUpdatedContainerData(
        pageEnum,
        cmsSettings,
        parent,
    );

    updatedContainer.controls = getNewControls(updatedContainer, controlData);

    // data for setting new container state
    return { pageEnum, updatedContainerIndex, updatedContainer };
};

export const updateControlAction = (state, action) => {
    const cmsSettings = current(state);
    const { pageEnum, updatedContainerIndex, updatedContainer } =
        getUpdatedPageData(cmsSettings, action);

    // set new container state
    // eslint-disable-next-line no-param-reassign
    state.pages[pageEnum][updatedContainerIndex] = updatedContainer;
};

// -------------------------------------------------------------------------
// MARK: Single Page Specific Logic
// -------------------------------------------------------------------------
export const getUpdatedSinglePageContainerData = (cmsSettings, parent) => {
    const { pages } = cmsSettings;

    const containers = [
        ...pages[CmsPageStandardizer.DONATION_AMOUNTS],
        ...pages[CmsPageStandardizer.DETAILS],
        ...pages[CmsPageStandardizer.ADDITIONAL_DETAILS],
        ...pages[CmsPageStandardizer.PAYMENT_DETAILS],
        ...pages[CmsPageStandardizer.SINGLE_STEP_FOOTER],
    ];

    const updatedContainerIndex = 0;
    let updatedContainer = {};
    let pageToUpdateEnum = 0;

    containers.forEach((container) => {
        if (Number(parent) === container.id) {
            pageToUpdateEnum = container?.options?.step;
            updatedContainer = { ...container };
        }
    });

    return { updatedContainerIndex, updatedContainer, pageToUpdateEnum };
};

export const getUpdatedSinglePageData = (cmsSettings, action) => {
    const {
        properties: { id, parent, options, type },
    } = action.payload;

    const controlData = { id, parent, options, type };

    const { pageToUpdateEnum, updatedContainerIndex, updatedContainer } =
        getUpdatedSinglePageContainerData(cmsSettings, parent);

    updatedContainer.controls = getNewControls(updatedContainer, controlData);

    // data for setting new container state
    return { pageToUpdateEnum, updatedContainerIndex, updatedContainer };
};

export const updateSinglePageControlAction = (state, action) => {
    const cmsSettings = current(state);
    const { pageToUpdateEnum, updatedContainerIndex, updatedContainer } =
        getUpdatedSinglePageData(cmsSettings, action);

    // set new container state
    // eslint-disable-next-line no-param-reassign
    state.pages[pageToUpdateEnum][updatedContainerIndex] = updatedContainer;
};
