import env from "../env";

/** @enum {string} */
const LogLevel = {
    Debug: "debug",
    Log: "log",
    Info: "info",
    Warn: "warn",
    Error: "error",
    Trace: "trace",
};

/**
 * @function log
 * @param {Array} args to pass to console.log
 * @description Normalized app console logger. If you want to use another
 *      console method like `info` or `warn` you can pass it as the first
 *      argument to the `log` method here. The default method that will be
 *      assumed is `console.log`.
 *
 *      Examples:
 *      log( "info", ...args )
 *      log( "warn", ...args )
 *      log( "trace", ...args )
 *      log( "debug", ...args )
 */
const log = (...args) => {
    if (env.IS_PROD() || env.IS_TEST() || !("console" in window)) {
        return;
    }

    let method = "log";
    let logArgs = [...args];

    // eslint-disable-next-line no-console
    if (typeof console[args[0]] === "function") {
        // eslint-disable-next-line prefer-destructuring
        method = logArgs[0];
        logArgs = logArgs.slice(1, logArgs.length);
    }

    window.console[method](...logArgs);
};

/**
 * Export
 */
export default log;

/**
 * @typedef LogInstanceMethods
 * @property {Function} log console log method
 * @property {Function} debug console debug method
 * @property {Function} error console error method
 * @property {Function} info console info method
 * @property {Function} warn console warn method
 * @property {Function} trace console trace method
 */
/**
 * @description for creating an instance of the log with an identifier
 * @param {string} identifier identifier that is prefixed to each message
 * @returns {LogInstanceMethods} log methods
 */
export function LogInstance(identifier) {
    const messageStart = `${identifier}:`;

    /**
     * @description for formatting the message using the message start
     * @param {Array} messages messages array
     * @returns {Array|string} formatted message array
     */
    const createMessage = (messages) => {
        const firstMessageItem = messages[0];
        // if the first item not a string, prepend the message start to the array
        if (typeof firstMessageItem !== "string") {
            messages.unshift(messageStart);
            return messages;
        }
        // the first item is a string, so we'll create a new formatted copy prepending the message start
        const formattedFirstItem = `${messageStart} ${firstMessageItem}`;
        // if we only have one, return a string
        if (messages.length === 1) {
            return formattedFirstItem;
        }
        // replace the first item with the formatted string
        messages.shift();
        messages.unshift(formattedFirstItem);
        return messages;
    };

    /**
     * @description wrapper for log that passes the level and parameters
     * @param {LogLevel} level logging level
     * @param {...any} params logging parametes
     */
    const logWrapper = (level, ...params) => {
        const messages = createMessage([...params]);
        // eslint-disable-next-line no-console
        log(level, messages);
    };

    // functions for each log level
    const methods = {};
    Object.values(LogLevel).forEach((level) => {
        methods[level] = (...params) => {
            logWrapper(level, ...params);
        };
    });

    return methods;
}
