export const defaultField = {
    amtReq: 0,
    conditions: [],
    context: 0,
    contextId: 1,
    description: "",
    displayFrontend: true,
    displayFrontendNoEdit: true,
    displayKiosk: true,
    displayMobile: true,
    displayMobileNoEdit: true,
    displayTxtToDonate: true,
    displayVT: true,
    displayVTNoEdit: true,
    display_text: "Lorem ipsum",
    enableDisplayToDonors: false,
    enableDisplayToRegistrants: false,
    entityGroupId: 12345,
    entityTypeText: "Lorem ipsum",
    form: 12345,
    formStep: 2,
    helpText: "Lorem ipsum",
    id: 12345,
    lockRequired: true,
    lockStatus: true,
    maxChar: "",
    onOwnLine: true,
    ref: "Lorem ipsum",
    required: true,
    status: 1,
    type: 1,
    weight: 1,
};

export const defaultShortTextCustomField = {
    ...defaultField,
    display_text: "Short Text Field",
    entityTypeText: "Paragraph Text",
    formStep: 3,
    ref: "Short_Text_Field",
    type: 11,
};

// selectDetailsPageFieldsData
export const defaultDefaultOptInChecked = {
    checkedByDefault: false,
    exists: true,
};

export const defaultDetailsPageFieldsData = {
    companyFieldIsRequired: {},
    defaultAnonymousFieldChecked: {},
    defaultCountry: "US",
    defaultOptInChecked: {
        ...defaultDefaultOptInChecked,
    },
    employerFieldIsRequired: {},
    hasLegalField: false,
    hasSuffixField: false,
    mailingAddressIsDisplayable: {},
    phoneFieldIsRequired: {},
    privacyOptionsIsDisplayable: {},
    titleFieldIsRequired: {},
};

export const defaultEmptyCustomFields = [];
