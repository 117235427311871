import React from "react";
import PropTypes from "prop-types";
import ConnectedPaymentMethods from "../../Pages/PaymentPage/PaymentMethods";
import ConnectedTotalGift from "../../Pages/PaymentPage/ConnectedTotalGift";
import ConnectedCaptcha from "../../Pages/PaymentPage/PaymentPageContent/ConnectedCaptcha";
import ConnectedUpdateShouldRevertPaymentType from "../../Pages/PaymentPage/ConnectedUpdateShouldRevertPaymentType";

const PaymentContentControl = ({
    controlId,
    displayCaptcha,
    displayTotalGift,
}) => (
    <div className="grid">
        {displayTotalGift && (
            <div className="col col--12 -padding-top--30">
                <ConnectedTotalGift controlId={controlId} />
            </div>
        )}

        <ConnectedUpdateShouldRevertPaymentType />
        <div className="col col--12">
            <ConnectedPaymentMethods controlId={controlId} />
        </div>
        {displayCaptcha && (
            <div className="col col--12">
                <ConnectedCaptcha />
            </div>
        )}
    </div>
);

PaymentContentControl.propTypes = {
    controlId: PropTypes.number.isRequired,
    displayCaptcha: PropTypes.bool.isRequired,
    displayTotalGift: PropTypes.bool.isRequired,
};

export default PaymentContentControl;
