import React from "react";
import PropTypes from "prop-types";
import ConnectedNewPaymentMethods from "./ConnectedNewPaymentMethods";
import ConnectedTogglePaymentMethodsButton from "./ConnectedTogglePaymentMethodsButton";
import ConnectedStoredPaymentMethods from "./ConnectedStoredPaymentMethods";

const PaymentMethods = ({
    controlId,
    displayStoredPaymentMethods,
    hasToggledPaymentMethodView,
    handleTogglePaymentMethodsButtonClick,
}) => (
    <>
        {/* Do not need to be wrapped in col classes as each component has a col class within it */}
        {displayStoredPaymentMethods ? (
            <ConnectedStoredPaymentMethods
                controlId={controlId}
                hasToggledPaymentMethodView={hasToggledPaymentMethodView}
            />
        ) : (
            <ConnectedNewPaymentMethods
                controlId={controlId}
                hasToggledPaymentMethodView={hasToggledPaymentMethodView}
            />
        )}
        <ConnectedTogglePaymentMethodsButton
            controlId={controlId}
            displayStoredPaymentMethods={displayStoredPaymentMethods}
            handleTogglePaymentMethodsButtonClick={
                handleTogglePaymentMethodsButtonClick
            }
        />
    </>
);

PaymentMethods.propTypes = {
    controlId: PropTypes.number.isRequired,
    displayStoredPaymentMethods: PropTypes.bool.isRequired,
    hasToggledPaymentMethodView: PropTypes.bool.isRequired,
    handleTogglePaymentMethodsButtonClick: PropTypes.func.isRequired,
};

export default PaymentMethods;
