import React from "react";
import PropTypes from "prop-types";
import InnerHTML from "dangerously-set-html-content";

const WidgetCustomContent = ({
    allowRerender = undefined,
    control: {
        options: { content = "" },
    },
}) => {
    const html = content;
    return html.length > 0 ? (
        <div className="qg-vendor-widget-custom-content widget-custom-content -user-html-content">
            <InnerHTML html={html} allowRerender={allowRerender} />
        </div>
    ) : null;
};

WidgetCustomContent.propTypes = {
    allowRerender: PropTypes.bool,
    control: PropTypes.shape({
        options: PropTypes.shape({
            content: PropTypes.string,
        }).isRequired,
    }),
};

export default WidgetCustomContent;
