import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateShouldDisplayApplePay } from "@qgiv/core-donor";
import { selectAllFormSettings } from "../../redux/slices/formSettingsSlice";
import { setShouldDisplayApplePay } from "../../redux/slices/appSettingsSlice";

const ConnectedUpdateShouldDisplayApplePay = () => {
    const dispatch = useDispatch();
    const { paymentData = {} } = useSelector(selectAllFormSettings);
    const {
        applePayEnabled = true,
        enableCCPayments = true,
        isCreditCardMerchantGatewayLitle = true,
    } = paymentData;

    // Consolidate all of the form settings required to detect whether Apple
    // Pay is supported into a single object
    const applePayOptions = {
        applePayEnabled,
        enableCCPayments,
        isCreditCardMerchantGatewayLitle,
    };

    const dispatchSetShouldDisplayApplePay = (updatedShouldDisplayApplePay) => {
        dispatch(setShouldDisplayApplePay(updatedShouldDisplayApplePay));
    };

    const updateShouldDisplayApplePayProps = {
        applePayOptions,
        dispatchSetShouldDisplayApplePay,
    };

    return (
        <UpdateShouldDisplayApplePay {...updateShouldDisplayApplePayProps} />
    );
};

export default ConnectedUpdateShouldDisplayApplePay;
