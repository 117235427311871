import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import DonationFormBaseContent from "./DonationFormBaseContent/DonationFormBaseContent";
import ConnectedDonationFormFooter from "../DonationFooterBase";
import ConnectedDonorLogin from "../../../common/Login/ConnectedDonorLogin";
import ConnectedErrorDisplay from "../../../common/ErrorDisplay/ConnectedErrorDisplay";

const DonationFormBase = ({
    displayDonorLogin,
    displayFooter,
    children,
    isSinglePageForm,
}) => (
    <section className="donation-form">
        <div className="donation-form__inner">
            <div className={cx("donation-form__content")}>
                <div className="donation-form__header">
                    {displayDonorLogin && (
                        <div className="donation-form__header--login -text--right">
                            <ConnectedDonorLogin />
                        </div>
                    )}
                </div>
                {isSinglePageForm && <ConnectedErrorDisplay />}

                <DonationFormBaseContent>{children}</DonationFormBaseContent>
            </div>
        </div>
        <div className="donation-form__footer">
            {displayFooter ? <ConnectedDonationFormFooter /> : null}
        </div>
    </section>
);

DonationFormBase.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    displayDonorLogin: PropTypes.bool.isRequired,
    displayFooter: PropTypes.bool.isRequired,
};

export default DonationFormBase;
