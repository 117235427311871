import React from "react";
import RestrictionCard from "./RestrictionCard";

/**
 * @typedef {import("./restrictionCardsTypes").RestrictionCardProps} RestrictionCardProps
 */

/**
 *
 * @param {object} props
 * @param {RestrictionCardProps[]} props.restrictionCards
 * @returns {React.ReactElement}
 */
const RestrictionCards = ({ restrictionCards }) => (
    <>
        {restrictionCards.map((restrictionCardProps) => (
            <RestrictionCard {...restrictionCardProps} />
        ))}
    </>
);

export default RestrictionCards;
