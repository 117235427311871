import React from "react";
import PropTypes from "prop-types";
import AdditionalDetailsPageFooterControl from "./AdditionalDetailsPageFooterControl";
import DetailsPageFooterControl from "./DetailsPageFooterControl";
import ConnectedDonationAmountsPageFooterControl from "./ConnectedDonationAmountsPageFooterControl";
import PaymentDetailsPageFooterControl from "./PaymentDetailsPageFooterControl";
import SinglePageFooterControl from "./SinglePageFooterControl";

const FooterControl = ({ controlId, page }) => {
    switch (page) {
        case "gift-page":
            return (
                <ConnectedDonationAmountsPageFooterControl
                    controlId={controlId}
                />
            );
        case "details-page":
            return <DetailsPageFooterControl controlId={controlId} />;
        case "additional-details-page":
            return <AdditionalDetailsPageFooterControl controlId={controlId} />;
        case "payment-page":
            return <PaymentDetailsPageFooterControl controlId={controlId} />;
        case "single-page-footer":
            return <SinglePageFooterControl controlId={controlId} />;
        default:
            return <h1>No Page Exists</h1>;
    }
};

FooterControl.propTypes = {
    controlId: PropTypes.number.isRequired,
    page: PropTypes.string.isRequired,
};

export default FooterControl;
