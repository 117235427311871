import React from "react";
import PropTypes from "prop-types";
import SubRestrictionsList from "./SubRestrictionsList";
import WriteTheirOwnSubRestriction from "./WriteTheirOwnSubRestriction";

const SubRestrictions = ({
    description,
    showDescription,
    showList,
    subRestrictionsListProps,
    writeTheirOwnSubRestrictionsProps,
}) => (
    // eslint-disable-next-line tailwindcss/classnames-order
    <div className="grid -callout">
        <div className="col col--12 col--no-top-gutter col--no-left-gutter">
            {showList ? (
                <SubRestrictionsList {...subRestrictionsListProps} />
            ) : (
                <WriteTheirOwnSubRestriction
                    {...writeTheirOwnSubRestrictionsProps}
                />
            )}
        </div>
        {showDescription && <p className="-margin-top--15">{description}</p>}
    </div>
);

SubRestrictions.propTypes = {
    description: PropTypes.string.isRequired,
    showDescription: PropTypes.bool.isRequired,
    showList: PropTypes.bool.isRequired,
    subRestrictionsListProps: PropTypes.shape({
        ariaLabel: PropTypes.string.isRequired,
        handleBlur: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        subRestrictionListOptions: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ).isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
    writeTheirOwnSubRestrictionsProps: PropTypes.shape({
        ariaLabel: PropTypes.string.isRequired,
        handleBlur: PropTypes.func.isRequired,
        handleChange: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
};

export default SubRestrictions;
