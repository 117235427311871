import React from "react";
import PropTypes from "prop-types";
import ProcessingBackground from "../ProcessingBackground";
import ProcessingDots from "../ProcessingDots";
import Button from "../Button";

import "./ProcessingButton.scss";

const ProcessingButton = ({
    btnStyle = "primary",
    showBackground = true,
    spinnerStyle = "",
    scopeToModal = false,
    scopeToIframe = false,
}) => {
    let background;

    // The translucent white background that is displayed over the entire UI
    // Because the processing animation is most commonly displayed when
    // submitting a form the translucent background is displayed by default
    if (showBackground) {
        background = (
            <ProcessingBackground
                scopeToModal={scopeToModal}
                scopeToIframe={scopeToIframe}
            />
        );
    }

    // Prevent resubmission once processing begins
    const handleProcessingButtonClick = (e) => {
        e.preventDefault();
    };

    return (
        <div className="processing">
            {background}
            <Button
                btnStyle={btnStyle}
                classes="processing__button"
                type="submit"
                onClick={handleProcessingButtonClick}
            >
                <ProcessingDots spinnerStyle={spinnerStyle} />
            </Button>
        </div>
    );
};

ProcessingButton.propTypes = {
    btnStyle: PropTypes.string,
    showBackground: PropTypes.bool,
    spinnerStyle: PropTypes.string,
    scopeToModal: PropTypes.bool,
    scopeToIframe: PropTypes.bool,
};

export default ProcessingButton;
