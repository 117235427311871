/**
 * @function detectCCTypes
 * @param {string} num Credit card number
 * @returns {Array} Matched types will be returned in an array ("visa",
 *      "mastercard", "amex", or "discover"). If no match, returns an empty
 *      array
 * @description Detects credit card type using as few digits as possible
 * @see https://baymard.com/checkout-usability/credit-card-patterns
 * @see https://www.freeformatter.com/credit-card-number-generator-validator.html
 */
export const detectCCTypes = (num) => {
    let ccTypes = [];
    if (num) {
        const _num = num.split(/\D/).join("");
        const acceptedCCtypes = {
            // Visa starts with 4, up to 19 digits
            visa: [(n) => /^4[0-9]{0,18}$/.test(n)],

            // MasterCard starts with 51-55 or 222100-272099, up to 16 digits
            mastercard: [
                // starts with 51-55
                (n) => /^5$/.test(n),
                (n) => /^5[1-5][0-9]{0,14}$/.test(n),

                // starts between 222100 and 272099
                (n) =>
                    n.length > 0 &&
                    n >= "2221000000000000".substring(0, n.length) &&
                    n <= "2720999999999999".substring(0, n.length),
            ],

            // American Express starts with 34 or 37, up to 17 digits
            amex: [
                (n) => /^3$/.test(n), // starts with 3
                (n) => /^3[47][0-9]{0,15}$/.test(n), // starts with 34 or 37
            ],

            // Discover Card starts with 6011, 622126 to 622925, 644-649, or 65,
            // up to 19 digits
            discover: [
                // starts with 6011
                (n) => n.length > 0 && n === "601".substring(0, n.length),
                (n) => /^6011[0-9]{0,15}$/.test(n),

                // starts between 622126 and 622925
                (n) =>
                    n.length > 0 &&
                    n >= "6221260000000000000".substring(0, n.length) &&
                    n <= "6229259999999999999".substring(0, n.length),

                // starts with 644-659
                (n) => /^64$/.test(n),
                (n) => /^64[4-9][0-9]{0,16}$/.test(n),
                (n) => /^65[0-9]{0,17}$/.test(n),
            ],
        };

        ccTypes = Object.keys(acceptedCCtypes).filter((type) =>
            acceptedCCtypes[type].some((ccTest) => ccTest(_num)),
        );
    }
    return ccTypes;
};

/**
 * @function validateLuhn
 * @param {string} num String to be validated as credit card number.
 * @returns {boolean} Indicates whether card number is valid.
 * @description Validates credit card using the Luhn algorithm
 * @see https://simplycalc.com/luhn-source.php
 */
export const validateLuhn = (num) => {
    let isLuhnValid = false;
    if (num) {
        const digits = num.split(/\D/).join("");
        const len = digits.length;
        const parity = len % 2;
        let sum = 0;
        for (let i = len - 1; i >= 0; i--) {
            let d = parseInt(digits.charAt(i), 10);
            // ⬇️ Grandfathered in from before Airbnb rules
            // eslint-disable-next-line eqeqeq
            if (i % 2 == parity) {
                d *= 2;
            }
            if (d > 9) {
                d -= 9;
            }
            sum += d;
        }
        isLuhnValid = sum % 10 === 0;
    }
    return isLuhnValid;
};

/**
 * @function validateCCNumLength
 * @param {string} num String to be validated as credit card number.
 * @param {Array} types Array of credit card types to be validated.
 * @returns {boolean} Indicates whether card number length is valid.
 * @description Validates credit card number length
 */
export const validateCCNumLength = (num, types) => {
    let isCCLengthValid = false;
    const ccLengths = {
        // Visa either 13, 16, or 19 digits
        visa: [13, 16, 19],
        // MasterCard 16 digits
        mastercard: [16],
        // American Express either 15 or 17 digits
        amex: [15, 17],
        // Discover Card between 16 and 19 digits
        discover: [16, 17, 18, 19],
    };

    if (num) {
        // number's length is valid for at least one type
        isCCLengthValid = types.some((type) =>
            ccLengths[type].some((len) => num.length === len),
        );
    }
    return isCCLengthValid;
};

/**
 * @function validateCCNum
 * @param {string} num String to be validated as credit card.
 * @returns {(string|boolean)} Indicates whether card number is valid
 * @description Validates credit card
 */
export const validateCCNum = (num) => {
    let isCCNumValid = false;
    if (num) {
        const _num = num.split(/\D/).join("");
        const types = detectCCTypes(_num);
        const typeIsValid = types.length > 0;
        const numIsValid = validateLuhn(_num);
        const lenIsValid = validateCCNumLength(_num, types);

        isCCNumValid = typeIsValid && numIsValid && lenIsValid;
    }
    return isCCNumValid;
};

/**
 * @function getAcceptedCCs
 * @param {string} VisaAmexDisc Three letter string of `y` or `n` indicating
 *      accepted card types. (e.g yyy or yny or nyy or nnn, etc.)
 * @returns {Array} An array of accepted card types (e.g. ["visa", "amex"] for
 *      `yyn`).
 */
export const getAcceptedCCs = (VisaAmexDisc) => {
    let acceptedCCs = [];
    if (VisaAmexDisc) {
        const filteredCCs = ["visa", "amex", "discover"].filter(
            (cardType, i) => VisaAmexDisc[i] === "y",
        );
        if (filteredCCs.includes("visa")) {
            filteredCCs.splice(1, 0, "mastercard");
        }
        acceptedCCs = filteredCCs;
    }
    return acceptedCCs;
};

/**
 * @function ccTypeAccepted
 * @param {string} VisaAmexDisc Three letter string of `y` or `n` indicating
 * @param {string} value Card number value to validate
 * @description Given a VisaAmexDisc string, determine if a cc number value
 *              is indicative of an accepted type of credit card.
 * @returns {boolean} Flag determining if the cc type is accepted
 */
export const ccTypeAccepted = (VisaAmexDisc, value) => {
    let isCCTypeAccepted = false;
    if (VisaAmexDisc && value) {
        const acceptedCCs = getAcceptedCCs(VisaAmexDisc);
        const ccTypes = detectCCTypes(value);
        isCCTypeAccepted = acceptedCCs.includes(ccTypes[0]);
    }
    return isCCTypeAccepted;
};

/**
 * @function cvvLength
 * @param {string} value Value from a card number input
 * @description Given a card number value, determine the required length of it's
 *      corresponding CVV input value
 * @returns {number} Number determining the length of the CVV
 */
export const cvvLength = (value) => {
    let length = 3;
    if (value) {
        const ccTypes = detectCCTypes(value);
        length = ccTypes.includes("amex") ? 4 : 3;
    }
    return length;
};
