import cx from "classnames";
import { convertFrequencyTag } from "../optionTagConverters.js/optionTagConverters";

/**
 * @public
 * @function getIsDateRequiredForRecurringDonations
 * @param {object} settings Settings object.
 * @param {boolean} settings.enableEndDate End date Donation Setting
 * @param {boolean} settings.enableRecur Event Level is Recurring enabled
 * @param {boolean} settings.enableStartDate Start date Donation Setting
 * @param {boolean} settings.recurringPlanEndDateRequired is end date required for Plans
 * @returns {boolean} Boolean that represents whether or not the Start_Date
 *                    and End_Date values are required for a recurring donation.
 */
export const getIsDateRequiredForRecurringDonations = ({
    enableEndDate,
    enableRecur,
    enableStartDate,
    recurringPlanEndDateRequired,
}) => {
    // Extract the flags that denote whether the org has required an end date
    // for recurring donations
    const enableRecurringDonations = enableRecur;
    const allowDonorToSpecifyEndDate = enableEndDate;
    const allowDonorToSpecifyStartDate = enableStartDate;

    const isDateRequiredSettingToggledOn = recurringPlanEndDateRequired;
    // Return a flag that denotes that the giving plan end date is required
    const isEndDateRequiredForRecurringDonations =
        enableRecurringDonations &&
        (allowDonorToSpecifyEndDate || allowDonorToSpecifyStartDate) &&
        isDateRequiredSettingToggledOn;

    return isEndDateRequiredForRecurringDonations;
};

/**
 * @public
 * @function getFormattedRecurringImpactStatement
 * @param {object} settings Settings object.
 * @param {boolean} settings.activeDonationFrequencies All available frequencies and their labels
 * @param {boolean} settings.Recurring_Frequency Formik value of select Recurring Frequency (ex "m")
 * @param {boolean} settings.recurringImpactStatement Cms set statement which may contain %FREQUENCY%
 * @returns {string} Formatted Impact Statement
 */
export const getFormattedRecurringImpactStatement = ({
    activeDonationFrequencies,
    Recurring_Frequency,
    recurringImpactStatement,
}) => {
    const currentFrequencyLabel = convertFrequencyTag(
        activeDonationFrequencies,
        Recurring_Frequency,
    );

    const formattedRecurringImpactStatement = recurringImpactStatement.replace(
        "%FREQUENCY%",
        currentFrequencyLabel,
    );

    return formattedRecurringImpactStatement;
};

/**
 * @public
 * @function getRecurringDatesProps
 * @param {object} settings Settings object.
 * @param {boolean} settings.enableEndDate End date Donation Setting
 * @param {boolean} settings.enableRecur Event Level is Recurring enabled
 * @param {boolean} settings.enableStartDate Start date Donation Setting
 * @param {boolean} settings.hasSelectedRecurringDonation Recurring donation type has been selected
 * @param {boolean} settings.recurringPlanEndDateRequired is end date required for Plans
 * @returns {string} All props for recurring Dates
 */
export const getRecurringDatesProps = ({
    enableEndDate,
    enableRecur,
    enableStartDate,
    hasSelectedRecurringDonation,
    recurringPlanEndDateRequired,
}) => {
    const shouldDatesShowAtAll =
        enableRecur && (enableStartDate || enableEndDate);

    const displayRecurringDatesComponent = shouldDatesShowAtAll;

    const showBothDateFields = enableRecur && enableEndDate && enableStartDate;

    const isDateRequiredForRecurringDonations =
        getIsDateRequiredForRecurringDonations({
            enableEndDate,
            enableRecur,
            enableStartDate,
            recurringPlanEndDateRequired,
        });

    const showToggleDateFieldsButton =
        hasSelectedRecurringDonation &&
        shouldDatesShowAtAll &&
        !isDateRequiredForRecurringDonations;

    const dateFieldColumnClasses = cx(
        "col",
        showBothDateFields && "col--12 col--sm-6",
    );

    const yourDetailsEventKey = 0;
    // // Setting the list of activeEventKeys to an empty array ensures that the
    // // accordion is collapsed on page load
    const collapsedAccordionEventKeys = [];

    // // Display the date fields on load if they are required
    if (isDateRequiredForRecurringDonations) {
        collapsedAccordionEventKeys.push(yourDetailsEventKey);
    }

    const recurringDatesProps = {
        collapsedAccordionEventKeys,
        dateFieldColumnClasses,
        displayRecurringDatesComponent,
        showToggleDateFieldsButton,
        yourDetailsEventKey,
    };
    return recurringDatesProps;
};
