import React from "react";
import PropTypes from "prop-types";
import { Checkbox, PasswordAndConfirmation } from "@qgiv/core-donor";

const DonorAccountCreation = ({
    checkboxLabel,
    Create_Account_Password,
    handleBlur,
    handleChange,
    isContentExpanded,
    passwordAndConfirmationProps,
    sectionHeading,
}) => (
    <div>
        <h3>{sectionHeading}</h3>
        <Checkbox
            id="Create_Account_Password"
            name="Create_Account_Password"
            handleBlur={handleBlur}
            handleChange={handleChange}
            checked={Create_Account_Password}
        >
            <p>{checkboxLabel}</p>
        </Checkbox>
        {isContentExpanded && (
            <div className="-padding-bottom--15">
                <PasswordAndConfirmation {...passwordAndConfirmationProps} />
            </div>
        )}
    </div>
);

DonorAccountCreation.propTypes = {
    checkboxLabel: PropTypes.string.isRequired,
    Create_Account_Password: PropTypes.bool.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    isContentExpanded: PropTypes.bool.isRequired,
    passwordAndConfirmationProps: PropTypes.shape({}).isRequired,
    sectionHeading: PropTypes.string.isRequired,
};

export default DonorAccountCreation;
