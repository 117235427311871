import { constants } from "@qgiv/core-js";

/**
 * @public
 * @function composeMultiplePaths
 * @description helper function which takes in one or more paths that load
 * a React element and transforms it into the shape needed for createBrowserRouter from react-router-dom
 * @param {object} settings contains items needed to create multiple routes rending same element
 * @param {Array} settings.paths array of strings that represent paths to load element
 * @param {object} settings.element React element that will be loaded on the path
 * @param {Array} settings.children Optional array for child routes
 * @returns {Array} array of route objects used in createBrowserRouter
 */
export const composeMultiplePaths = ({ paths = [], element, children }) =>
    paths.length > 0
        ? paths.map((path) => ({
              path,
              ...(element && { element }),
              ...(children && { children }),
          }))
        : [];

/**
 * @public
 * @function getAllRoutesByFormType
 * @description helper function which takes in the type of app that is loading the donation
 * form and returns all of the different routes used
 * @param {string} formType type of product that is rendering the donation form
 * @returns {object} object of arrays that contain the paths needed for Routes.js
 */

export const getAllRoutesByFormType = (formType) => {
    const {
        ENUMS: { ProductType },
    } = constants;
    const allPathsDefault = {
        donationFormPage: [],
        eventsPage: [],
        embedPage: [],
        receiptPage: [],
    };
    let formSpecificPaths = {};

    switch (formType) {
        // We are going to try and force all url data collection to be handled by the BE.
        // This means that the FE should load the form on all routes, and handle whatever
        // view/information needs to be loaded through data coming from the BE in initial state.
        // This is the approach taken in event redesign, and considering the react-router issues
        // we have had to over come, we should try and stick with it. We will leave the switch here
        // in case we need to add more routes in the future.
        case ProductType.HOBNOB:
        case ProductType.QGIV:
            formSpecificPaths = {
                donationFormPage: ["*"],
            };
            break;
        default:
            break;
    }
    return { ...allPathsDefault, ...formSpecificPaths };
};
