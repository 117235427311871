import React from "react";
import PropTypes from "prop-types";
import ConnectedRestrictions from "./ConnectedRestrictions";
import ConnectedSubRestrictions from "./ConnectedSubRestrictions";

const StandardRestrictionsContainer = ({ controlId, showSubRestrictions }) => (
    <div className="col col--12 standard-restrictions-container">
        <ConnectedRestrictions controlId={controlId} />
        {showSubRestrictions && (
            // eslint-disable-next-line tailwindcss/classnames-order
            <div className="grid -padding-left--15">
                <div className="col col--12">
                    <ConnectedSubRestrictions />
                </div>
            </div>
        )}
    </div>
);

StandardRestrictionsContainer.propTypes = {
    controlId: PropTypes.number.isRequired,
    showSubRestrictions: PropTypes.bool.isRequired,
};

export default StandardRestrictionsContainer;
