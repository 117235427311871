// -------------------------------------------------------------------------
// MARK:
// Logic that is shared across the instance of the PayPal components that
// are rendered on the payment step of our redesigned frontends. In this
// instance data is passed into a version of <PaymentPayPal/> which then
// passes down data to the following components. Each number represents
// a different level of nesting.
// 1. <PayPalButtonDisplay/>
// 2. <PayPalSDKScriptProvider/> and <LegacyPayPalButton/>
// 3. <PayPalScriptProvider/> and <PayPalButtons/>
// -------------------------------------------------------------------------
// Generate flags that determines whether the component that contains the
// PayPal JS SDK should be rendered and another flag that determines whether
// or not we have all of the information required to actually load the JS SDK.
// The data that is generated here is passed down to
// getPayPalButtonDisplayProps() and used to generate the data that is
// ultimately passed down to the React component.
export const getDisplayPayPalButtonsFlags = (payPalSDK = {}) => {
    const { paypalMerchant = "", paypalClient } = payPalSDK;
    // Boolean that determines which version of PayPal to display
    const displayPayPalSDKScriptProvider = !!paypalMerchant;
    const shouldLoadPayPalSDKScriptProvider = !!paypalClient;
    const payPalDisplayButtonsFlags = {
        displayPayPalSDKScriptProvider,
        shouldLoadPayPalSDKScriptProvider,
    };

    return payPalDisplayButtonsFlags;
};

// Generates all of the props that we intend to pass down to the
// <PayPalSDKScriptProvider/> component from the Redux store
export const getPayPalSDKScriptProviderProps = (
    paypalSDKScriptProviderPropsOptions = {},
) => {
    const {
        dispatchIncrementForceReRenderCount = () => {},
        dispatchSetHasCompletedPayPalInitialRender = () => {},
        displayUpdateIncrementForceReRenderCount = false,
        forceReRender = [],
        hasAnAmountToSendToPayPal = false,
        hasCompletedInitialRender = false,
        isCms = false,
        isRecurringDonation = false,
        paymentMethodResetCount = 0,
        payPalSDK = {},
        platformSpecificDataForCreateOrderAPI = {},
        shouldLoadPayPalSDKScriptProvider = false,
        valuesThatShouldForceAReRender = [],
    } = paypalSDKScriptProviderPropsOptions;
    // Grab the client id & the merchant id from the PayPal SDK
    const {
        paypalClient = "",
        paypalMerchant = "",
        paypalPartnerAttributionId = "",
        paypalPartnerId = "",
    } = payPalSDK;
    // -------------------------------------------------------------------------
    // NOTE:
    // The the merchant-id needs to contain valid data otherwise the PayPal SDK
    // script will not load as expected. disable-funding is used to prevent the
    // rendering of any button that is not the PayPal or Venmo buttons. The
    // "card" and "credit" options remove the "Pay Later" and the "Debit or
    // Credit Card" buttons. While all of the options were passed in
    // just to ensure that no other buttons appeared in the vertical view
    // enable-funding is used to display the Venmo button which was not visible
    // by default. The data-partner-attribution-id is an attribute that is specific
    // to our use case where we are processing transactions on behalf of other
    // merchants and is something that was requested as part of the Integration
    // Questionnaire the PayPal sent us prior to certification.
    // https://developer.paypal.com/docs/regional/th/checkout/reference/customize-sdk
    // -------------------------------------------------------------------------
    const payPalScriptProviderOptions = {
        "client-id": paypalClient,
        "merchant-id": paypalMerchant,
        "enable-funding": "venmo",
        "disable-funding":
            "card,credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort",
        "data-partner-attribution-id": paypalPartnerAttributionId,
        intent: "capture",
    };
    // Grab all of the data that the BE requires in order to create a PayPal order
    const getDataForCreateOrder = () => platformSpecificDataForCreateOrderAPI;
    // Grab all of the data that the BE requires in order to create a PayPal order
    // PayPal asks for the url of the page even though from what we can tell it
    // does not actually use the url of the page for anything.
    const getDataForCreateBillingAgreement = () => {
        const url = window.location.href;
        const dataForCreateBillingAgreement = {
            payPalSDK,
            url,
        };
        return dataForCreateBillingAgreement;
    };

    // If the user has selected a recurring donation then we need to use the
    // createBillingAgreement() function instead of the createOrder() function
    // which requires us to load in the JS SDK with an intent of tokenize and
    // a vault value of true. In addition to this we also need to change the
    // merchant id to our PayPal Partner ID. Changing the merchant id is not
    // something that was specified in the docs but is something that we need
    // to do that we confirmed with our PayPal integration engineer.
    // https://developer.paypal.com/sdk/js/configuration/
    // https://github.com/ppnsanders/PayPal-Commerce-Platform/tree/master/Billing-Agreements/Connected_Path_Partner_BA_Orders_Refund#checkout
    if (isRecurringDonation) {
        payPalScriptProviderOptions.intent = "tokenize";
        payPalScriptProviderOptions.vault = true;
        payPalScriptProviderOptions["merchant-id"] = paypalPartnerId;
    }

    // Consolidate props into single object. forceReRender contains the list of
    // values that should trigger a re-render of the PayPal button when changed
    const paypalSDKScriptProviderProps = {
        dispatchIncrementForceReRenderCount,
        dispatchSetHasCompletedPayPalInitialRender,
        displayUpdateIncrementForceReRenderCount,
        forceReRender,
        getDataForCreateBillingAgreement,
        getDataForCreateOrder,
        hasAnAmountToSendToPayPal,
        hasCompletedInitialRender,
        isCms,
        isRecurringDonation,
        paymentMethodResetCount,
        payPalScriptProviderOptions,
        shouldLoadPayPalSDKScriptProvider,
        valuesThatShouldForceAReRender,
    };

    return paypalSDKScriptProviderProps;
};

// Generates all of the props that we intend to pass down to the
// <PayPalButtonDisplay/> component from the Redux store
export const getPayPalButtonDisplayProps = (
    payPalButtonDisplayPropsOptions = {},
) => {
    const {
        dispatchIncrementForceReRenderCount = () => {},
        dispatchSetHasCompletedPayPalInitialRender = () => {},
        displayPayPalSDKScriptProvider = false,
        displayUpdateIncrementForceReRenderCount = false,
        forceReRender = [],
        hasAnAmountToSendToPayPal = false,
        hasCompletedInitialRender = false,
        isCms = false,
        isRecurringDonation = false,
        paymentMethodResetCount = 0,
        payPalSDK = {},
        platformSpecificDataForCreateOrderAPI = {},
        shouldLoadPayPalSDKScriptProvider = false,
        valuesThatShouldForceAReRender = [],
    } = payPalButtonDisplayPropsOptions;

    const paypalSDKScriptProviderPropsOptions = {
        dispatchIncrementForceReRenderCount,
        dispatchSetHasCompletedPayPalInitialRender,
        displayUpdateIncrementForceReRenderCount,
        forceReRender,
        hasAnAmountToSendToPayPal,
        hasCompletedInitialRender,
        isCms,
        isRecurringDonation,
        paymentMethodResetCount,
        payPalSDK,
        platformSpecificDataForCreateOrderAPI,
        shouldLoadPayPalSDKScriptProvider,
        valuesThatShouldForceAReRender,
    };

    const paypalSDKScriptProviderProps = getPayPalSDKScriptProviderProps(
        paypalSDKScriptProviderPropsOptions,
    );

    // Consolidate props into single object.
    const payPalButtonDisplayProps = {
        isRecurringDonation,
        displayPayPalSDKScriptProvider,
        payPalSDKScriptProviderProps: paypalSDKScriptProviderProps,
    };

    return payPalButtonDisplayProps;
};

// Takes the original set of data that generated by
// getPayPalButtonDisplayProps() and combines it with data that has been
// extracted from local component state in order to generate the final version
// of the data that will be passed down to the <PayPalButtonDisplay/>
// component.
export const getModifiedPayPalButtonDisplayProps = (
    modifiedPayPalButtonDisplayOptions = {},
) => {
    const {
        handleCompletePayPalAuthorizationFlow = () => {},
        handleHasAnAmountToSendToPayPalError = () => {},
        handleInitiatePayPalAuthorizationFlow = () => {},
        defaultMessageText = "",
        handleButtonClick = () => {},
        payPalButtonDisplayProps = {},
        payPalSDKScriptProviderProps = {},
    } = modifiedPayPalButtonDisplayOptions;
    // Props associated with the legacy PayPal button
    const legacyPayPalButtonProps = {
        handleButtonClick,
    };
    // Data from local state that is going to be passed down to the
    // PayPalSDKScriptProvider component
    const newPayPalSDKScriptProviderProps = {
        handleCompletePayPalAuthorizationFlow,
        handleHasAnAmountToSendToPayPalError,
        handleInitiatePayPalAuthorizationFlow,
    };
    // The object that contains the PayPalSDKScriptProvider data that has been
    // extracted from the Redux store and local component state
    const modifiedPayPalSDKScriptProviderProps = {
        ...payPalSDKScriptProviderProps,
        ...newPayPalSDKScriptProviderProps,
    };
    // Data from local state that is going to be passed down to the
    // PayPalButtonDisplay component
    const newPayPalButtonDisplayProps = {
        defaultMessageText,
        legacyPayPalButtonProps,
        payPalSDKScriptProviderProps: modifiedPayPalSDKScriptProviderProps,
    };
    // The object that contains the PayPalButtonDisplay data that has been
    // extracted from the Redux store and local component state
    const modifiedPayPalButtonDisplayProps = {
        ...payPalButtonDisplayProps,
        ...newPayPalButtonDisplayProps,
    };

    return modifiedPayPalButtonDisplayProps;
};

// -------------------------------------------------------------------------
// MARK:
// Logic that used to render the instance of the <PayPalSDKScript/> provider
// component on the Choose Gift Step of the donation form. Because this
// component is rendered directly, instead of being nested within a number
// of components, the logic from up above was very difficult to reuse & I
// created a series of helper functions that are meant to be smaller and
// more versatile to increase the legibility and reusability of this logic.
// -------------------------------------------------------------------------
// The only data that needs to be sent to the create billing agreement API is
// the payPalSDK data and the url of the webpage
export const getDataForCreateBillingAgreementAPI = ({ payPalSDK = {} }) => {
    const url = window.location.href;
    const dataForCreateBillingAgreementAPI = {
        payPalSDK,
        url,
    };

    return dataForCreateBillingAgreementAPI;
};

// The the merchant-id needs to contain valid data otherwise the PayPal SDK
// script will not load as expected. disable-funding is used to prevent the
// rendering of any button that is not the PayPal or Venmo buttons. The
// "card" and "credit" options remove the "Pay Later" and the "Debit or
// Credit Card" buttons. While all of the options were passed in
// just to ensure that no other buttons appeared in the vertical view
// enable-funding is used to display the Venmo button which was not visible
// by default. The data-partner-attribution-id is an attribute that is specific
// to our use case where we are processing transactions on behalf of other
// merchants and is something that was requested as part of the Integration
// Questionnaire the PayPal sent us prior to certification.
// https://developer.paypal.com/docs/regional/th/checkout/reference/customize-sdk
export const getPayPalScriptProviderOptions = ({
    isRecurringDonation = false,
    payPalSDK = {},
}) => {
    const payPalScriptProviderOptions = {
        "client-id": payPalSDK.paypalClient,
        "merchant-id": payPalSDK.paypalMerchant,
        "enable-funding": "venmo",
        "disable-funding":
            "card,credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort",
        "data-partner-attribution-id": payPalSDK.paypalPartnerAttributionId,
        intent: "capture",
    };

    // If the user has selected a recurring donation then we need to use the
    // createBillingAgreement() function instead of the createOrder() function
    // which requires us to load in the JS SDK with an intent of tokenize and
    // a vault value of true. In addition to this we also need to change the
    // merchant id to our PayPal Partner ID. Changing the merchant id is not
    // something that was specified in the docs but is something that we need
    // to do that we confirmed with our PayPal integration engineer.
    // https://developer.paypal.com/sdk/js/configuration/
    // https://github.com/ppnsanders/PayPal-Commerce-Platform/tree/master/Billing-Agreements/Connected_Path_Partner_BA_Orders_Refund#checkout
    if (isRecurringDonation) {
        payPalScriptProviderOptions.intent = "tokenize";
        payPalScriptProviderOptions.vault = true;
        payPalScriptProviderOptions["merchant-id"] = payPalSDK.paypalPartnerId;
    }

    return payPalScriptProviderOptions;
};

// Boolean that determines which version of PayPal to display
export const getShouldLoadPayPalSDKScriptProvider = ({ payPalSDK = {} }) => {
    const shouldLoadPayPalSDKScriptProvider = !!payPalSDK.paypalClient;

    return shouldLoadPayPalSDKScriptProvider;
};
