import { getBillingDetailsInitialValues } from "@qgiv/donation-form";

const getInitialValues = (settings, savedValues = {}) => {
    const initialValuesFromSettings = getBillingDetailsInitialValues(settings);

    const initialValuesWithSavedValues = {
        ...initialValuesFromSettings,
        ...savedValues,
    };

    return initialValuesWithSavedValues;
};

export default getInitialValues;
