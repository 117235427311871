import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "@qgiv/core-js";
import { useHandshake } from "@qgiv/core-donor";
import Routes from "./Routes";
import {
    selectAllFormSettings,
    selectFormThemeColor,
} from "../redux/slices/formSettingsSlice";
import {
    setAppLoading,
    setReceivedHandshake,
} from "../redux/slices/appSettingsSlice";
import { selectCmsPageOrder } from "../redux/slices/cmsSettingsSlice";
import { sendPostMessage } from "./PostMessage/postMessage";
import { selectConfig, selectIsCms } from "../redux/slices/configSettingsSlice";
import { selectEnableAbandonedGifts } from "../redux/slices/donationSettingsSlice";
import { selectCookieSettings } from "../redux/slices/cookieSettingsSlice";

const ConnectedRoutes = () => {
    const {
        ENUMS: { EntityType, CmsPageStandardizer },
    } = constants;
    const formSettings = useSelector(selectAllFormSettings);
    const config = useSelector(selectConfig);
    const { org = "", title = "", type = "", id } = formSettings;
    const { HTTP_HOST = "", embed = {} } = config;

    const embedId = embed?.id || 0;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setAppLoading(false));
    }, [dispatch]);

    const entity = id;
    const entityType = EntityType.FORM;
    // color is being added for future use by cookie banner
    const color = useSelector(selectFormThemeColor);
    const enableAbandonedGifts = useSelector(selectEnableAbandonedGifts);
    const cookieSettings = useSelector(selectCookieSettings);

    // prevent handshake being passed to elements embed logic if not actual form
    const isCms = useSelector(selectIsCms);
    const pageOrder = useSelector(selectCmsPageOrder);
    const firstPageInPageOrder = pageOrder[0];
    const isEventListWidget =
        firstPageInPageOrder === CmsPageStandardizer.QGIV_EVENTS;

    const allowHandshakeToReachElements = useMemo(() => {
        let shouldAllowHandshakeToReachElements = true;
        if (isCms || isEventListWidget) {
            shouldAllowHandshakeToReachElements = false;
        }
        return shouldAllowHandshakeToReachElements;
    }, [isCms, isEventListWidget]);

    const receivedHandshake = useHandshake({
        allowHandshakeToReachElements,
        appSpecificPostMessage: sendPostMessage,
        color,
        cookieSettings,
        enableAbandonedGifts,
        entity,
        embedId,
        entityType,
        HTTP_HOST,
    });

    useEffect(() => {
        if (receivedHandshake) {
            dispatch(setReceivedHandshake(receivedHandshake));
        }
    });

    // Rename variables so that they are more legible and convert them to
    // numbers so that their data type is consistent with the other data
    // being sent to Matomo analytics
    const formId = Number(id);
    const formType = Number(type);
    const orgId = Number(org);

    const routesProps = {
        formId,
        formType,
        orgId,
        title,
    };

    return <Routes {...routesProps} />;
};

export default ConnectedRoutes;
