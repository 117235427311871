import moment from "moment";
import { constants, formatPhoneNumber } from "@qgiv/core-js";

/**
 * @public
 * @function getRecurringValuesTextOptions
 * @description provided with recurring data from receipt, will return variables to help decide on text values
 * @param {boolean} shouldReturnRecurringValues boolean for whether we want to return default empty values
 * @param {object} recurring recurring object from receipt data
 * @returns {object} helpful object for setting text on receipt components
 */
export const getRecurringValuesTextOptions = (
    shouldReturnRecurringValues,
    recurring,
) => {
    // Provide default values for all of these variables as the require the
    // recurring object to be present & recurring is null for one time
    // donations
    if (!shouldReturnRecurringValues) {
        return {
            hasFinalBillingDay: false,
            firstBillingDay: {},
            formattedFirstBillingDate: "",
            formattedFinalBillingDate: "",
            formattedNextBillingDate: "",
            isFirstBillingDayAfterToday: false,
        };
    }
    const today = new Date();
    const hasFinalBillingDay =
        recurring.finalBill && recurring.finalBill !== "0000-00-00";
    const firstBillingDay = new Date(recurring.firstBill);
    const isFirstBillingDayAfterToday = today < firstBillingDay;
    // Format billing date values
    const formattedFirstBillingDate =
        recurring.firstBill && moment(recurring.firstBill).format("MM/DD/YYYY");
    const formattedFinalBillingDate =
        hasFinalBillingDay && moment(recurring.finalBill).format("MM/DD/YYYY");

    const formattedNextBillingDate =
        recurring.nextBill && moment(recurring.nextBill).format("MM/DD/YYYY");

    return {
        hasFinalBillingDay,
        firstBillingDay,
        formattedFinalBillingDate,
        formattedFirstBillingDate,
        isFirstBillingDayAfterToday,
        formattedNextBillingDate,
    };
};

// FieldType.SHORT_TEXT
// Pulling this value from constants: ENUMS outside
// the scope of the function causes this page to crash the form.
const SHORT_TEXT_FIELD_TYPE_ENUM = 11;
export const parseCustomFieldValuesReceiptDisplay = (
    type = `${SHORT_TEXT_FIELD_TYPE_ENUM}`,
    data = "",
) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    let formattedData = data;
    switch (Number(type)) {
        case FieldType.CHECKBOX:
            formattedData = data === "true" || data === "yes" ? "Yes" : "No";
            break;
        case FieldType.EVENT_PHONE:
        case FieldType.PHONE_NUMBER: {
            formattedData = formatPhoneNumber(`${data}`);
            break;
        }
        case FieldType.MULTIPLE_SELECTION:
            formattedData = `${data}`.split(",").join("; ");
            break;
        default:
            break;
    }

    return formattedData;
};
