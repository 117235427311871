import {
    Yup,
    getCCValidations,
    getBankFieldValidations,
    getBillingSameAsMailingValidations,
    getPaymentTypeValidations,
    getPaypalValidations,
    getBillingAddressValidations,
    getBillingNameValidation,
    getCaptchaFieldValidation,
} from "@qgiv/core-validation";

// -------------------------------------------------------------------------
// MARK: Form level validation schema
// -------------------------------------------------------------------------
const getPaymentPageValidations = (settings) => {
    const {
        VisaAmexDisc,
        displayableBillingFields,
        displayNameFieldData,
        shouldCaptchaBeRequired,
    } = settings;

    const paymentSchema = {
        ...getCCValidations(VisaAmexDisc),
        ...getBankFieldValidations(),
        ...getPaypalValidations(),
        ...getPaymentTypeValidations(),
        ...getBillingAddressValidations(displayableBillingFields),
        ...getBillingSameAsMailingValidations(),
        ...getBillingNameValidation(displayNameFieldData),
        ...getCaptchaFieldValidation(shouldCaptchaBeRequired),
    };

    return paymentSchema;
};

// Password Validations
export const getPasswordValidations = () => ({
    Password: Yup.string().when("Create_Account_Password", {
        is: (Create_Account_Password) => Create_Account_Password,
        then: Yup.string().validatePasswordString(),
        otherwise: Yup.string().notRequired(),
    }),
});

// Password_Confirm Validations
export const getConfirmPasswordValidations = () => ({
    Password_Confirm: Yup.string().when("Password", {
        is: (Password) => Password,
        then: Yup.string().validateConfirmPasswordString(),
        otherwise: Yup.string().notRequired(),
    }),
});

const getCreateDonorAccountValidations = () =>
    // Generate combined form level validation schema
    ({
        ...getPasswordValidations(),
        ...getConfirmPasswordValidations(),
    });

export const getAllPaymentPageValidations = (settings) => ({
    ...getPaymentPageValidations(settings),
    ...getCreateDonorAccountValidations(),
});

const getValidationSchema = (settings) => {
    const validationShape = {
        ...getPaymentPageValidations(settings),
        ...getCreateDonorAccountValidations(),
    };
    const schema = Yup.object().shape(validationShape);

    return schema;
};

export default getValidationSchema;
