import { getDedicationDetailsInitialValues } from "@qgiv/donation-form";

const getInitialValues = ({
    currentDisplay,
    currentPage,
    dedicationSettings,
    storedInitialValuesFromRedux,
}) => {
    const dedicationInitialValues = getDedicationDetailsInitialValues({
        dedicationSettings,
        currentDisplay,
        currentPage,
    });

    return {
        ...dedicationInitialValues,
        ...storedInitialValuesFromRedux,
    };
};

export default getInitialValues;
