import React, { useMemo } from "react";
import PropTypes from "prop-types";
import AccordionContext from "./AccordionContext";
import AccordionCollapse from "./AccordionCollapse";
import AccordionToggle from "./AccordionToggle";
import AccordionHeader from "./AccordionHeader";
import AccordionHeaderReceiptContent from "./AccordionHeaderReceiptContent";
import AccordionBody from "./AccordionBody";
import "./Accordion.scss";

const Accordion = ({
    activeEventKeys = [],
    onToggle = () => {},
    children,
    ...otherProps
}) => {
    const context = useMemo(() => {
        return {
            activeEventKeys,
            onToggle,
        };
    }, [activeEventKeys, onToggle]);
    return (
        <AccordionContext.Provider value={context}>
            <div className="accordion" {...otherProps}>
                {children}
            </div>
        </AccordionContext.Provider>
    );
};

Accordion.propTypes = {
    // children can be a single element or multiple elements
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    // keys of the accordion/section which is active/open
    activeEventKeys: PropTypes.array,
    // onToggle callback.
    onToggle: PropTypes.func,
    otherProps: PropTypes.object,
};

// Compound Component allows you to only have to import the Accordion component instead of all the pieces that you will need
Accordion.Collapse = AccordionCollapse;
Accordion.Toggle = AccordionToggle;
Accordion.Header = AccordionHeader;
Accordion.HeaderReceiptContent = AccordionHeaderReceiptContent;
Accordion.Body = AccordionBody;

export default Accordion;
