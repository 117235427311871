import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "./Captcha.scss";
import ErrorText from "../Fields/Field/ErrorText";

const Captcha = ({
    errors,
    setValues,
    touched,
    values,
    useErrorBorder = false,
}) => {
    window.updateGrecaptchaValue = () =>
        setValues({
            ...values,
            G_Recaptcha_Response: window.grecaptcha.getResponse(),
        });

    useEffect(() => {
        const recaptchaScript = document.createElement("script");
        const captchaLayoutBreakpoint = 599;
        let intervalID;

        // Add reCAPTCHA v2 ("I'm not a robot" Checkbox)
        recaptchaScript.src = "https://www.google.com/recaptcha/api.js";
        recaptchaScript.async = true;
        recaptchaScript.defer = true;
        document.body.appendChild(recaptchaScript);

        // Relocate the iframe with the captcha challenge to the bottom of the
        // form so that donors can actually see it on mobile devices
        const relocateCaptchaChallengeToBottomOfForm = () => {
            const iframeWithCaptchaChallenge = document.querySelector(
                "iframe[title*='recaptcha']",
            );

            if (iframeWithCaptchaChallenge) {
                const divThatSetsIframePosition =
                    iframeWithCaptchaChallenge.parentNode;
                divThatSetsIframePosition.style.position = "relative";
                clearInterval(intervalID);
            }
        };

        // setInterval() is being used here as the captcha element does not have
        // any built in attributes that support the running of the function
        // after the iframe has loaded. Because we do not have a built in hook
        // for this, we need to repeatedly check for the presence of this
        // iframe until it has been loaded. If not, then the captcha challenge
        // will be left on the top of the screen on instances of the form with
        // a slow network connection, something that we have to consider when
        // interacting with slow mobile devices.
        intervalID = setInterval(() => {
            if (window.innerWidth < captchaLayoutBreakpoint) {
                relocateCaptchaChallengeToBottomOfForm();
            }
        }, 1000);
    }, []);

    return (
        <div className="-display--flex -justify-content--center -flex-direction--column -align-items--center">
            <div
                className={`g-recaptcha -display--inline-block ${
                    useErrorBorder && "g-recaptcha__error"
                }`}
                data-sitekey="6LcQvQgTAAAAAPwrrAtA-EsboJs23Bedpw_AR00r"
                data-callback="updateGrecaptchaValue"
            />
            {/* validation error */}
            {touched.G_Recaptcha_Response && errors.G_Recaptcha_Response && (
                <ErrorText errorText={errors.G_Recaptcha_Response} />
            )}
        </div>
    );
};

Captcha.propTypes = {
    errors: PropTypes.shape({
        G_Recaptcha_Response: PropTypes.string,
    }).isRequired,
    setValues: PropTypes.func.isRequired,
    touched: PropTypes.shape({
        G_Recaptcha_Response: PropTypes.bool,
    }).isRequired,
    values: PropTypes.shape({
        G_Recaptcha_Response: PropTypes.string,
    }).isRequired,
    useErrorBorder: PropTypes.bool,
};

export default Captcha;
