import React from "react";
import PropTypes from "prop-types";
import { RecurringDates, RecurringImpactStatement } from "@qgiv/donation-form";
import { Divider } from "@qgiv/core-donor";

const RecurringImpactAndDates = ({
    displayDivider,
    displayRecurringDatesComponent,
    displayRecurringImpactComponent,
    formattedFinePrint,
    recurringDatesProps,
    recurringImpactProps,
}) => (
    <div className="-text--center -callout">
        {displayRecurringImpactComponent && (
            <RecurringImpactStatement {...recurringImpactProps} />
        )}
        {displayDivider && (
            <div className="-margin-bottom--10">
                <Divider />{" "}
            </div>
        )}
        {/* Fine Print is always displayed when recurring is enabled */}
        <p>{formattedFinePrint}</p>

        {displayRecurringDatesComponent && (
            <RecurringDates {...recurringDatesProps} />
        )}
    </div>
);

RecurringImpactAndDates.propTypes = {
    displayDivider: PropTypes.bool.isRequired,
    displayRecurringDatesComponent: PropTypes.bool.isRequired,
    displayRecurringImpactComponent: PropTypes.bool.isRequired,
    formattedFinePrint: PropTypes.string.isRequired,
    recurringImpactProps: PropTypes.shape({}).isRequired,
    recurringDatesProps: PropTypes.shape({}).isRequired,
};

export default RecurringImpactAndDates;
