import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FieldContainer from "./FieldContainer";
import FieldInput from "./FieldInput";
import { validQMaskTypes } from "../QMaskField";
import "./Field.scss";

const Field = (props) => {
    const {
        cursor = "text",
        disableInlineLabel = false,
        name,
        noShadow = false,
    } = props;
    const detectEntered = () =>
        props?.value?.length > 0 || props.autoFocus ? true : false;
    const [active, setActive] = useState(props.autoFocus);
    const [entered, setEntered] = useState(detectEntered());
    const [inputOffset, setInputOffset] = useState(0);

    const hasDatePicker = props.type === "dateMDY";

    // Helper bool that checks if Field has descText or descIcon
    const hasDesc =
        (props.descText && props.descText.length) ||
        (props.descIcon && Object.keys(props.descIcon).length);
    const descRef = React.createRef(); // Reference to the desc DOM node
    const inputRef = React.createRef(); // Reference to the input DOM node

    /**
     * @private
     * @function shouldIncludeInnerShaddow
     * @description helper function to determine whether to include inner shadow
     * @returns {boolean} Flag determining if field needs inner shadow
     */
    const shouldIncludeInnerShadow = () =>
        noShadow
            ? false
            : props.type === "text" ||
              props.type === "select" ||
              (validQMaskTypes && validQMaskTypes.includes(props.type));

    // Helper that focuses the input DOM node
    const focusInputRef = () => {
        if (!inputRef.current) {
            return;
        }
        // if inputRef.current is an input element focus it, otherwise the element should be the input element to focus
        const element = inputRef?.current?.nodeType
            ? inputRef.current
            : inputRef.current.element;
        if (!active) {
            setActive(true);
            setEntered(true);
        }
        element.focus();
    };

    const handleFocus = (e) => {
        e.preventDefault();
        focusInputRef();
        if (props.handleFocus) {
            props.handleFocus(e);
        }
    };

    const handleBlur = (e) => {
        setActive(false);
        setEntered(props.value.length !== 0);

        if (props.handleBlur) {
            props.handleBlur(e);
        }
    };

    const handleFieldContainerClick = (e) => {
        focusInputRef();
        if (props.handleClick) {
            props.handleClick(e);
        }
    };

    useEffect(() => {
        if (hasDesc) {
            // Set the offset equal to the width of the field description
            setInputOffset(descRef.current.clientWidth);
        }
    }, [inputOffset]);

    useEffect(() => {
        setEntered(detectEntered());
    }, [props.value]);

    useEffect(() => {
        if (props.autoFocus) {
            focusInputRef();
        }
    }, [inputRef.current]);

    // scrollAndFocus does the exact same thing
    // as the autoFocus prop with the exception that
    // we want the useEffect wrapping it to run if that
    // flag changes overtime so it is added to the dependency array.
    useEffect(() => {
        if (props.scrollAndFocus) {
            focusInputRef();
        }
    }, [props.scrollAndFocus, inputRef.current]);

    return (
        <div className="field">
            {/*
            This is a temporary band-aid for displaying fields with
            really long labels.
            */}
            {disableInlineLabel && props.label && (
                <label className="qg-vendor-field-label field-label">{`${props.label}:`}</label>
            )}
            <FieldContainer
                disableInlineLabel={disableInlineLabel}
                htmlForName={props.id}
                label={props.label}
                helpText={props.helpText}
                active={active}
                entered={entered}
                handleClick={handleFieldContainerClick}
                descText={props.descText}
                descIcon={props.descIcon}
                descRef={descRef}
                maxChar={props.maxChar}
                multiline={props.multiline}
                cursor={cursor}
                innerBackground={props.innerBackground}
                innerShadow={shouldIncludeInnerShadow()}
                error={props.error}
                errorText={props.errorText}
                value={props.value}
                name={name}
                hasDatePicker={hasDatePicker}
            >
                <FieldInput
                    {...props}
                    id={props.id}
                    name={name}
                    type={props.type}
                    multiline={props.multiline}
                    value={props.value}
                    handleBlur={handleBlur}
                    handleChange={props.handleChange}
                    handleFocus={handleFocus}
                    inputRef={inputRef}
                    inputOffset={inputOffset}
                    error={props.error}
                    errorText={props.errorText}
                >
                    {props.children}
                </FieldInput>
            </FieldContainer>
        </div>
    );
};

Field.propTypes = {
    autoFocus: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    // We can pass labels as elements or strings
    label: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    descText: PropTypes.string,
    descIcon: PropTypes.shape({
        glyph: PropTypes.string,
        type: PropTypes.string,
    }),
    disableInlineLabel: PropTypes.bool,
    selectedStartDate: PropTypes.string,
    maxChar: PropTypes.string,
    multiline: PropTypes.bool,
    cursor: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    innerBackground: PropTypes.bool,
    // Event Handlers
    handleChange: PropTypes.func.isRequired,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,
    handleClick: PropTypes.func,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    config: PropTypes.object,
    noShadow: PropTypes.bool,
};

export default Field;
