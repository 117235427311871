/*
    We are going to be running into an issue with UpdateControl data
    now that we are expecting strongly typed values to come from the BE.
    The values coming in from CMS updates will not be strongly typed, and this
    is strictly FE issue. With CMS redesign on the horizon, we are just
    going to keep a list of values to strongly type. Right now this is only
    needed for converting string numbers "1" and "0" to boolean true/false.
    If that changes, we'll have to expand the functionality of this idea and
    probably create a helper function here to return a strongly typed object
    for the incoming options.
*/

const optionsToStronglyType = [
    "hideCelebrationAnimationsWhenGoalReached",
    "hideDonationRecipientOnEventPages",
    "displayHeading",
];

const getStronglyTypedIncomingOptions = (incomingOptions) => {
    const newOptionsKeys = Object.keys(incomingOptions);
    const optionsWithStrongTyping = newOptionsKeys.reduce(
        (totalOptions, currentOption) => {
            // right now we are only strongly typing boolean values
            if (optionsToStronglyType.includes(currentOption)) {
                return {
                    ...totalOptions,
                    [currentOption]: incomingOptions[currentOption] === "1",
                };
            }
            return {
                ...totalOptions,
                [currentOption]: incomingOptions[currentOption],
            };
        },
        {},
    );
    return optionsWithStrongTyping;
};

export default getStronglyTypedIncomingOptions;
