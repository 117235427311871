import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ReceiptPersonalDetails, translateTransaction } from "@qgiv/core-donor";
import { selectReceiptTransaction } from "../../../../redux/slices/receiptsSlice";

const ConnectedReceiptPersonalDetails = ({ control }) => {
    const { options = {} } = control;
    const { personalDetailsLabel = "Personal Details" } = options;

    const transaction = useSelector(selectReceiptTransaction);
    // Convert any of the data that is on the transaction object that is a
    // number to a string before it is displayed on the receipt page
    const transactionWithDataAsStrings = translateTransaction(transaction);
    // Rename data on state key to avoid clash of names with state. Suffix does
    // not appear to be delivered to the FE in the receipt page for the
    // redesigned donation form or the redesigned event registration.
    const {
        address = "",
        city = "",
        company = "",
        country = "",
        email = "",
        fname = "",
        lname = "",
        phone = "",
        salutation = "",
        state: addressState = "",
        suffix = "",
        zip = "",
    } = transactionWithDataAsStrings;

    // Consolidate props into object while passing reassigned variable to the
    // appropriate key.
    const receiptPersonalDetailsProps = {
        address,
        city,
        company,
        country,
        email,
        fname,
        lname,
        personalDetailsLabel,
        phone,
        salutation,
        state: addressState,
        suffix,
        zip,
    };
    return <ReceiptPersonalDetails {...receiptPersonalDetailsProps} />;
};
ConnectedReceiptPersonalDetails.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            personalDetailsLabel: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedReceiptPersonalDetails;
