import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "@qgiv/core-js";
import { cmsDefaultRecipient } from "@qgiv/donation-form";
import { ModalContentReplacerEnums } from "../../common/ModalContentReplacer";
import {
    selectSelectedRecipient,
    selectSelectedRecipientIsPreset,
    updateSelectedRecipient,
} from "../../../redux/slices/donationDetailsSlice";
import { selectIsCms } from "../../../redux/slices/configSettingsSlice";
import { selectDonationRecipientSettingsAllowControlDisplay } from "../../../redux/slices/eventSettingsSlice";
import { selectSpecificFormSetting } from "../../../redux/slices/formSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../redux/slices/cmsSettingsSlice";
import { selectContentToDisplay } from "../../../redux/slices/appSettingsSlice";
import DonationRecipientControl from "./DonationRecipientControl";

const ConnectedDonationRecipientControl = ({ controlId }) => {
    const {
        ENUMS: {
            EntityType: { FORM },
            CmsPageStandardizer: { DONATION_AMOUNTS },
        },
    } = constants;

    const dispatch = useDispatch();

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, DONATION_AMOUNTS),
    );

    const { options = {} } = control;

    const { hideDonationRecipientOnEventPages = false } = options;

    const selectedRecipient = useSelector(selectSelectedRecipient);
    const recipientIsPreset = useSelector(selectSelectedRecipientIsPreset);
    const hasSelectedRecipient =
        Object.keys(selectedRecipient).length > 0 &&
        selectedRecipient?.entityType !== FORM;

    const isCms = useSelector(selectIsCms);

    const id = useSelector((state) => selectSpecificFormSetting(state, "id"));

    const donationRecipientSettingsAllowControlDisplay = useSelector(
        selectDonationRecipientSettingsAllowControlDisplay,
    );

    /*
        What are we doing with these settings?

        - if donationRecipientSettingsAllowControlDisplay is false
            HIDE Control

        - if donationRecipientSettingsAllowControlDisplay is true

            - if hideDonationRecipientOnEventPages is true and recipientIsPreset is false
                HIDE Control

            - if hideDonationRecipientOnEventPages is true and recipientIsPreset is true
                SHOW Control

            - if hideDonationRecipientOnEventPages is false and recipientIsPreset is false
                SHOW Control

            - if hideDonationRecipientOnEventPages is false and recipientIsPreset is true
                SHOW Control


        What does hide / show really mean?

        This means that we want the control to actually load, but it can still load more than one variant
        1. Choose Recipient
        2. Selected Recipient
        3. NEW: Selected Recipient CMS only version
            This is for when hideDonationRecipientOnEventPages is true and we are viewing cms

        So we talking bout more than one setting to load

        When Show Control:
            allow current control functionality to take place

        When Hide Control && donationRecipientSettingsAllowControlDisplay is true && hideDonationRecipientOnEventPages is true and isCms is true
            show cms only version of SelectedRecipient
    */

    let showDonationRecipientControl = true;
    if (!donationRecipientSettingsAllowControlDisplay) {
        showDonationRecipientControl = false;
    } else if (
        donationRecipientSettingsAllowControlDisplay &&
        hideDonationRecipientOnEventPages &&
        !recipientIsPreset
    ) {
        showDonationRecipientControl = false;
        // need to set selected recipient to be event
        if (selectedRecipient.entityType !== FORM && !isCms) {
            dispatch(
                updateSelectedRecipient({
                    entity: id, // form id
                    entityType: FORM,
                }),
            );
        }
    }

    const showCmsVersionOfSelectedRecipient =
        !showDonationRecipientControl &&
        donationRecipientSettingsAllowControlDisplay &&
        hideDonationRecipientOnEventPages &&
        isCms;

    // When someone is coming back to the default content from the search UI
    // we want to scroll down to the donation recipient control. This has
    // to delay running so that the change back to the default content can complete.
    const controlRef = useRef();
    // track what contentToDisplay used to be to evaluate where donor is coming from
    const [localContentToDisplay, setLocalContentToDisplay] = useState(
        ModalContentReplacerEnums.DEFAULT_CONTENT,
    );
    const contentToDisplay = useSelector(selectContentToDisplay);

    useEffect(() => {
        const { current } = controlRef;
        if (current && localContentToDisplay !== contentToDisplay) {
            const runScrollToRecipient =
                localContentToDisplay ===
                    ModalContentReplacerEnums.RECIPIENT_SEARCH_UI &&
                contentToDisplay === ModalContentReplacerEnums.DEFAULT_CONTENT;
            if (runScrollToRecipient) {
                setTimeout(() => {
                    current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }, 200);
            }
            // keep local up to date with contentToDisplay
            setLocalContentToDisplay(contentToDisplay);
        }
    }, [controlRef, localContentToDisplay, contentToDisplay]);

    useEffect(() => {
        if (isCms) {
            const hasSelectedAnyRecipient =
                Object.keys(selectedRecipient).length > 0;
            if (hideDonationRecipientOnEventPages && !hasSelectedAnyRecipient) {
                dispatch(updateSelectedRecipient(cmsDefaultRecipient));
            }
            if (!hideDonationRecipientOnEventPages && hasSelectedAnyRecipient) {
                dispatch(updateSelectedRecipient({}));
            }
        }
    }, [isCms, hideDonationRecipientOnEventPages, selectedRecipient, dispatch]);

    return (
        <DonationRecipientControl
            controlId={controlId}
            hasSelectedRecipient={hasSelectedRecipient}
            showCmsVersionOfSelectedRecipient={
                showCmsVersionOfSelectedRecipient
            }
            showDonationRecipientControl={showDonationRecipientControl}
            controlRef={controlRef}
        />
    );
};

ConnectedDonationRecipientControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonationRecipientControl;
