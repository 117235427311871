import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import TextButton from "../Button/TextButton";
import "./DonorAccountActionLink.scss";

const DonorAccountActionLink = ({
    colorClass = "-color--theme-primary",
    donorActionLinkLabel,
    handleLinkClick = () => {},
    iconData = {},
    isLink = false,
    linkUrl = "",
    sizeClass = "-type--small",
}) =>
    // My Account link needs to be an a tag so we have given this component some versatility
    isLink ? (
        <a
            className="-type--small -color--theme-primary login__account-link ignore-typography ignore-auto-focus"
            href={linkUrl}
            target="_blank"
            rel="noreferrer"
        >
            <strong>
                {iconData && Object.keys(iconData).length !== 0 && (
                    <Icon
                        ariaHidden
                        glyph={iconData.glyph}
                        type={iconData.type}
                        classNames={[
                            `-type--${
                                iconData?.size || "regular"
                            } -margin-right--10`,
                        ]}
                    />
                )}
                {donorActionLinkLabel}
            </strong>
        </a>
    ) : (
        <TextButton
            handleClick={handleLinkClick}
            styles={`${sizeClass} ${colorClass} ignore-auto-focus`}
        >
            <strong>
                {iconData && Object.keys(iconData).length !== 0 && (
                    <Icon
                        ariaHidden
                        glyph={iconData.glyph}
                        type={iconData.type}
                        classNames={[
                            `-type--${
                                iconData?.size || "regular"
                            } -margin-right--10`,
                        ]}
                    />
                )}
                {donorActionLinkLabel}
            </strong>
        </TextButton>
    );

DonorAccountActionLink.propTypes = {
    colorClass: PropTypes.string,
    donorActionLinkLabel: PropTypes.string.isRequired,
    handleLinkClick: PropTypes.func,
    iconData: PropTypes.shape({
        type: PropTypes.string,
        glyph: PropTypes.string,
        size: PropTypes.string,
    }),
    isLink: PropTypes.bool,
    linkUrl: PropTypes.string,
    sizeClass: PropTypes.string,
};

export default DonorAccountActionLink;
