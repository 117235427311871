import React from "react";
import PropTypes from "prop-types";
import StandardAmountRadioOption from "./StandardAmountRadioOption";

const StandardAmountsRadioOptions = ({
    standardAmounts,
    handleStandardAmountChange,
}) =>
    // Map over the instances standardAmount and render
    // StandardAmountRadioOption component for each
    standardAmounts.map((standardAmountProps, index) => (
        <div
            key={standardAmountProps.id}
            className={`col${standardAmountProps.containerClasses}`}
        >
            <StandardAmountRadioOption
                key={standardAmountProps.id}
                index={index}
                handleChange={handleStandardAmountChange}
                {...standardAmountProps}
            />
        </div>
    ));
StandardAmountsRadioOptions.propTypes = {
    standardAmounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleStandardAmountChange: PropTypes.func.isRequired,
};

export default StandardAmountsRadioOptions;
