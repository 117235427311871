import React from "react";
import PropTypes from "prop-types";
import { TextWithDividerLine } from "@qgiv/core-donor";
import ConnectedPayPalSDKScriptProvider from "./ConnectedPayPalSDKScriptProvider";
import ConnectedApplePayPaymentMethod from "./ConnectedApplePayPaymentMethod";

const ExpressCheckout = ({
    displayApplePayPaymentMethod,
    displayPayPalSDKScriptProvider,
}) => (
    <div className="-margin-top--30">
        {displayApplePayPaymentMethod && <ConnectedApplePayPaymentMethod />}
        {displayPayPalSDKScriptProvider && <ConnectedPayPalSDKScriptProvider />}
        <TextWithDividerLine text="or" />
    </div>
);

ExpressCheckout.propTypes = {
    displayApplePayPaymentMethod: PropTypes.bool.isRequired,
    displayPayPalSDKScriptProvider: PropTypes.bool.isRequired,
};

export default ExpressCheckout;
