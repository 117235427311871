/* eslint-disable import/prefer-default-export */
import { getFieldValue } from "@qgiv/core-donor";
import { getMatchingGiftsInitialValues } from "../matchingGiftInitialValuesGenerators";
import { getDedicationDetailsInitialValues } from "../dedicationsInitialValuesGenerators";

/**
 * @typedef AdditionalDetailsSettings
 * @param {Array} additionalDetailsCustomFields Array of visible custom fields
 * @param {boolean} enableMatching bool for Matching Gift setting
 * @param {number} matchingType Type of matching gift MANUAL = 1, AUTOMATIC = 2
 * @param {object} savedValues Previously stored values
 * @param {boolean} hasDisplayableMatchingGiftOnThisPage Matching Gift group is visible on this page
 */

/**
 * @public
 * @function getAdditionalDetailsInitialValues
 * @param {AdditionalDetailsSettings} settings settings from state to determine initial values
 * @returns {object} initial values for formik for payment page
 */
export const getAdditionalDetailsInitialValues = ({
    additionalDetailsCustomFields,
    currentDisplay,
    currentPage,
    dedicationSettings,
    enableMatching,
    hasDisplayableMatchingGiftOnThisPage,
    matchingType,
    savedValues = {},
}) => {
    const getAdditionalDetailsCustomFieldsInitialValue = () => {
        const customFieldsInitialValues = additionalDetailsCustomFields
            // return values object with key of custom field id
            .reduce((valuesObj, currentField) => {
                const fieldId = currentField.id;
                const value = getFieldValue(currentField);
                return {
                    ...valuesObj,
                    [fieldId]: value,
                };
            }, {});
        return customFieldsInitialValues;
    };

    const initialValuesFromSettings = {
        // Set initial Name/Account values
        ...getAdditionalDetailsCustomFieldsInitialValue(),
        ...getMatchingGiftsInitialValues(
            hasDisplayableMatchingGiftOnThisPage,
            enableMatching,
            matchingType,
        ),
        // Dedications
        ...getDedicationDetailsInitialValues({
            currentDisplay,
            currentPage,
            dedicationSettings,
        }),
    };

    // If the user had previously completed the details step, override any
    // initial or donor account data with the data that was entered & stored
    // when they previously completed the details step
    const initialValuesWithSavedValues = {
        ...initialValuesFromSettings,
        ...savedValues,
    };

    return initialValuesWithSavedValues;
};
