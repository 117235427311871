import React from "react";
import { Select } from "@qgiv/core-donor";

/**
 * @param {object} props
 * @param {string} props.Recurring_Frequency
 * @param {object[]} props.activeDonationFrequencies
 * @param {Function} props.handleBlur
 * @param {Function} props.handleChange
 * @param {string} props.label
 * @returns {React.ReactElement} The rendered InstallmentFrequencySelect component.
 */
const InstallmentFrequencySelect = ({
    Recurring_Frequency,
    activeDonationFrequencies = [],
    handleBlur,
    handleChange,
    label,
}) => (
    <div className="-margin-bottom--15">
        {activeDonationFrequencies && activeDonationFrequencies.length > 1 ? (
            <Select
                disableBlankOption
                disableInlineLabel
                id="Recurring-Frequency"
                name="Recurring_Frequency"
                handleBlur={handleBlur}
                handleChange={handleChange}
                label={label}
                value={Recurring_Frequency}
                ariaLabel="Pay Installments"
            >
                {activeDonationFrequencies.map((frequency) => (
                    <option
                        key={frequency.value}
                        value={frequency.value}
                        className="restrictions__select--option"
                    >
                        {frequency.label}
                    </option>
                ))}
            </Select>
        ) : (
            <>
                <span className="qg-vendor-field-label field-label">
                    {label}:
                </span>
                <p>
                    <strong>{activeDonationFrequencies[0].label}</strong>
                </p>
            </>
        )}
    </div>
);

export default InstallmentFrequencySelect;
