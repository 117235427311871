import React from "react";
import PropTypes from "prop-types";
import ConnectedDonorAccount from "../../Pages/ReceiptPage/DonorAccount/ConnectedDonorAccount";

const DonorAccountControl = ({ controlId }) => (
    <ConnectedDonorAccount controlId={controlId} />
);

DonorAccountControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default DonorAccountControl;
