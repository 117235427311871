import { calcGiftAssistFee } from "@qgiv/core-donor";
import { constants } from "@qgiv/core-js";

const {
    ENUMS: {
        ItemType: { OTHER },
    },
} = constants;

export const getExistingAmountThatMatchesSmsDataAmount = ({
    activeAmounts,
    amount,
}) => {
    const existingAmountThatMatchesSmsDataAmount =
        activeAmounts?.find((activeAmount) => activeAmount.amount === amount) ||
        {};
    return existingAmountThatMatchesSmsDataAmount;
};

export const getExistingAmountIsAvailableOneTime = (
    activeAmount,
    donationActive,
) => {
    if (Object.keys(activeAmount).length === 0) return false;
    return !!activeAmount?.displayForOneTime && donationActive;
};

export const getExistingAmountIsAvailableRecurring = (
    activeAmount,
    enableRecur,
) => {
    if (Object.keys(activeAmount).length === 0) return false;
    return !!activeAmount?.displayForRecurring && enableRecur;
};

export const getOtherAmountData = (activeAmounts) => {
    const otherAmount =
        activeAmounts.find(
            (activeAmount) => activeAmount.amountType === OTHER,
        ) || {};
    const hasExistingOtherAmount = Object.keys(otherAmount).length > 0;
    return { otherAmount, hasExistingOtherAmount };
};

export const getGiftAssistInitialValue = (giftAssistSettings, amount) => {
    const {
        enableGiftAssistByDefault,
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
    } = giftAssistSettings;
    if (
        !enableGiftAssistByDefault ||
        // TextToDonate sent w/o amount has 0 sent as amount in smsData
        amount <= 0 ||
        // feeCoverage of 0 means it is disabled
        Number(feeCoverage) === 0
    ) {
        return 0;
    }
    return Number(
        calcGiftAssistFee(amount, {
            feeCoverage,
            feeCoverageFlat,
            feeCoveragePercentage,
        }),
    );
};

const getTextToDonateInitialValues = (settings = {}) => {
    const { donationSettings = {}, formSettings = {}, smsData = {} } = settings;
    const { activeAmounts = [] } = donationSettings;

    const {
        enableGiftAssistByDefault = false,
        enableRecur = true,
        donationActive = true,
        feeCoverage = 0,
        feeCoverageFlat = 0,
        feeCoveragePercentage = 0,
    } = formSettings;

    const { amount = 0 } = smsData;

    if (amount === 0) {
        return {};
    }

    const textToDonateAmountInitialValues = {
        subtotal: amount,
    };

    // The BE will be adding the other amount as enabled if form is TextToDonate
    // even if org has set other amount as disabled
    const { otherAmount, hasExistingOtherAmount } =
        getOtherAmountData(activeAmounts);

    const existingAmountThatMatchesSmsDataAmount =
        getExistingAmountThatMatchesSmsDataAmount({ activeAmounts, amount });

    const existingAmountIsAvailableForOneTime =
        getExistingAmountIsAvailableOneTime(
            existingAmountThatMatchesSmsDataAmount,
            donationActive,
        );

    const existingAmountIsAvailableForRecurring =
        getExistingAmountIsAvailableRecurring(
            existingAmountThatMatchesSmsDataAmount,
            enableRecur,
        );

    // -------------------------------------------------------------------------
    // NOTE: Because of the nature of TextToDonate and sending an amount with
    //       the text, we are going to be ignoring the Other Amount's displayFor status
    //       and pre-filling what needs to be prefilled as if it is always enabled
    // -------------------------------------------------------------------------
    if (
        donationActive &&
        hasExistingOtherAmount &&
        !existingAmountIsAvailableForOneTime
    ) {
        textToDonateAmountInitialValues.Other_One_Time_Amount =
            amount.toFixed(2);
    }

    if (
        enableRecur &&
        hasExistingOtherAmount &&
        !existingAmountIsAvailableForRecurring
    ) {
        textToDonateAmountInitialValues.Other_Recurring_Amount =
            amount.toFixed(2);
    }

    if (donationActive) {
        textToDonateAmountInitialValues.Selected_One_Time_Id =
            existingAmountIsAvailableForOneTime
                ? existingAmountThatMatchesSmsDataAmount.id
                : otherAmount.id;
    }

    if (enableRecur) {
        textToDonateAmountInitialValues.Selected_Recurring_Id =
            existingAmountIsAvailableForRecurring
                ? existingAmountThatMatchesSmsDataAmount.id
                : otherAmount.id;
    }

    // Need to calculate giftAssistAmount is enabled be default
    // We only need to set that value if that is true
    const giftAssistAmount = getGiftAssistInitialValue(
        {
            enableGiftAssistByDefault,
            feeCoverage,
            feeCoverageFlat,
            feeCoveragePercentage,
        },
        amount,
    );

    if (giftAssistAmount > 0) {
        textToDonateAmountInitialValues.giftAssistAmount = giftAssistAmount;
    }

    textToDonateAmountInitialValues.total = amount + giftAssistAmount;

    return textToDonateAmountInitialValues;
};

export default getTextToDonateInitialValues;
