import moment from "moment";

/**
 * @function convertStartDateTag
 * @description converts %STARTDATE% tag from CMS into text that is rendered in the UI.
 * @param {string} Start_Date - the string value that represents the value in the Start_Date field. e.g. 01/19/2023
 * @returns {string} "today" if the donation starts today, "MM/DD/YYYY" otherwise.
 */

export const convertStartDateTag = (Start_Date = "") => {
    const today = moment().format("MM/DD/YYYY");
    const startsToday = Start_Date === today || !Start_Date;
    const startDateTag = startsToday ? "today" : Start_Date;
    return startDateTag;
};

/**
 * @function convertFrequencyTag
 * @description
 * @param {Array} activeDonationFrequencies array of enabled frequencies
 * @param {string} Recurring_Frequency letter representing the selected frequency
 * @returns {string} Frequency label
 */
export const convertFrequencyTag = (
    activeDonationFrequencies,
    Recurring_Frequency,
) => {
    const selectedFrequency = activeDonationFrequencies.find(
        (frequency) => frequency.value === Recurring_Frequency,
    );
    const frequencyTagLabel =
        selectedFrequency && Object.keys(selectedFrequency).length > 0
            ? selectedFrequency.label
            : "";

    return frequencyTagLabel;
};
