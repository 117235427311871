import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./ErrorText.scss";

const ErrorText = ({ errorText }) => (
    /* The qg-vendor-field-container__error-text class needs to be maintained as it is used for vendor styling */
    <div className="qg-vendor-field-container__error-text error-text">
        <Icon
            classNames={["-color--system-error", "error-icon"]}
            type="FontAwesome"
            glyph="exclamation-circle-solid"
        />{" "}
        {/* <p> */}
        <p className="ignore-typography">{errorText}</p>
    </div>
);

ErrorText.propTypes = {
    errorText: PropTypes.string.isRequired,
};

export default ErrorText;
