import React from "react";
import ConnectedOtherAmountRadioOption from "../../../../common/StandardAmountOption/ConnectedOtherAmountRadioOption";
import ConnectedStandardAmountsRadioOptions from "../../../../common/StandardAmountOption/ConnectedStandardAmountsRadioOptions";
import ConnectedMinMaxMessage from "./ConnectedMinMaxMessage";
import ConnectedOtherAmountError from "./ConnectedOtherAmountError";

// -------------------------------------------------------------------------
// NOTE:
// This component is rendered CURRENTLY:
// 1. As part of the standard one time donation tab.
// 2. As part of the standard ongoing donation tab.
// TODO:
// 3. Within the CYOP UI in order to allow users to select the amount that
// they want to choose.
// 4. As part of the T2D UI once the user clicks the Edit button.
// -------------------------------------------------------------------------

/**
 * @param {object} props
 * @param {object[]} props.amountsToDisplay
 * @param {string} props.buttonStyle
 * @param {number} props.controlId
 * @param {string} props.donationAmountAlignment
 * @param {string} props.fieldName
 * @param {boolean} props.hasOtherAmountToDisplay
 * @param {boolean} props.hasStandardAmountsToDisplay
 * @param {object[]} props.standardAndOtherAmountRefs
 * @param {boolean} props.shouldConsiderDisplayOfMinMaxMessage
 * @param {boolean} props.isDisplayedAsPartOfCYOP
 * @param {boolean} props.isRecurringDisplay
 * @returns {React.ReactElement} The rendered Amounts component.
 */
const Amounts = ({
    amountsToDisplay,
    buttonStyle,
    controlId,
    donationAmountAlignment,
    fieldName,
    hasOtherAmountToDisplay,
    hasStandardAmountsToDisplay,
    standardAndOtherAmountRefs,
    shouldConsiderDisplayOfMinMaxMessage,
    isDisplayedAsPartOfCYOP = false,
    isRecurringDisplay = false,
}) => (
    <>
        {/* Vendor injected content */}
        <div className="qg-vendor-donation-amount-statement" />
        <div
            role="radiogroup"
            aria-required="true"
            className="qg-vendor-gift-amounts gift-amounts"
            name={fieldName}
            tabIndex="-1"
        >
            <div className="grid">
                {hasStandardAmountsToDisplay && (
                    <ConnectedStandardAmountsRadioOptions
                        fieldName={fieldName}
                        amountsToDisplay={amountsToDisplay}
                        buttonStyle={buttonStyle}
                        donationAmountAlignment={donationAmountAlignment}
                        standardAndOtherAmountRefs={standardAndOtherAmountRefs}
                        isDisplayedAsPartOfCYOP={isDisplayedAsPartOfCYOP}
                        isRecurringDisplay={isRecurringDisplay}
                    />
                )}

                {hasOtherAmountToDisplay && (
                    <ConnectedOtherAmountRadioOption
                        controlId={controlId}
                        fieldName={fieldName}
                        amountsToDisplay={amountsToDisplay}
                        buttonStyle={buttonStyle}
                        standardAndOtherAmountRefs={standardAndOtherAmountRefs}
                    />
                )}
            </div>
            {shouldConsiderDisplayOfMinMaxMessage && (
                <>
                    <ConnectedMinMaxMessage
                        controlId={controlId}
                        shouldConsiderDisplayOfMinMaxMessage={
                            shouldConsiderDisplayOfMinMaxMessage
                        }
                    />
                    <ConnectedOtherAmountError
                        amountsToDisplay={amountsToDisplay}
                        fieldName={fieldName}
                    />
                </>
            )}
        </div>
    </>
);

export default Amounts;
