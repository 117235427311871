import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getShouldRenderMultiRestriction } from "@qgiv/donation-form";
import { selectAllDonationSettings } from "../../../../redux/slices/donationSettingsSlice";
import { selectSmsData } from "../../../../redux/slices/smsDataSlice";
import DonatedGiftTabRecurring from "./DonatedGiftTabRecurring";

const ConnectedDonatedGiftTabRecurring = ({ controlId }) => {
    const { restrictionGivingType } = useSelector(selectAllDonationSettings);

    const smsData = useSelector(selectSmsData);

    const { shouldDisplayTextToDonateInterface = false } = smsData;

    const isMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    const isStandardRecurringAmounts =
        !isMultiRestriction && !shouldDisplayTextToDonateInterface;

    const showRecurringDatesOrImpactUI = !shouldDisplayTextToDonateInterface;

    const donatedGiftTabRecurringProps = {
        isMultiRestriction,
        isStandardRecurringAmounts,
        shouldDisplayTextToDonateInterface,
        controlId,
        showRecurringDatesOrImpactUI,
    };

    return <DonatedGiftTabRecurring {...donatedGiftTabRecurringProps} />;
};

ConnectedDonatedGiftTabRecurring.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonatedGiftTabRecurring;
