import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { SystemFieldGroupBillingAddress } from "@qgiv/core-donor";
import { selectAllSystemFields } from "../../../../../redux/slices/fieldsSlice";
import { selectCurrentDisplay } from "../../../../../redux/slices/configSettingsSlice";

const ConnectedSystemFieldGroupBillingAddress = ({
    billingAddressFieldGroup,
}) => {
    const allFieldsAndGroups = useSelector(selectAllSystemFields);
    const currentDisplay = useSelector(selectCurrentDisplay);
    const { handleChange, handleBlur, values, errors, setFieldValue, touched } =
        useFormikContext();

    const systemFieldGroupBillingAddressProps = {
        field: billingAddressFieldGroup,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        values,
        settings: {
            allFieldsAndGroups,
            config: { currentDisplay },
        },
    };

    return (
        <SystemFieldGroupBillingAddress
            {...systemFieldGroupBillingAddressProps}
        />
    );
};

ConnectedSystemFieldGroupBillingAddress.propTypes = {
    billingAddressFieldGroup: PropTypes.shape({}).isRequired,
};

export default ConnectedSystemFieldGroupBillingAddress;
