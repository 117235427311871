import React from "react";
import { constants, currencyString, translateImageSize } from "@qgiv/core-js";

// ----------------------------------------------------------------------------
// NOTE:
// The helper functions in this file are used to extract useful pieces of data
// that can be passed down to the instance of the Amounts component that is
// being used in the Qgiv form. They perform a function similar to that of a
// selector function in a typical Redux project. However because form relies
// largely on prop drilling instead of Redux state these functions are located
// in this file instead.
// ----------------------------------------------------------------------------
// -------------------------------------------------------------------------
// MARK: Helper logic that is used to generate the amountsToDisplay value.
// -------------------------------------------------------------------------
export const getIsDonationAmountWithinRange = (
    amount = {},
    minAmt = "",
    maxAmt = "",
) => {
    const {
        ENUMS: { ItemType },
    } = constants;
    // Convert all of these amounts to numbers so that they can be used for
    // comparisons
    const minAmtAsNumber = parseFloat(minAmt);
    const maxAmtAsNumber = parseFloat(maxAmt);
    const amountAsNumber = parseFloat(amount.amount);
    const isAmountTypeOther =
        parseInt(amount.amountType, 10) === ItemType.OTHER;

    // Other amount is always in range as it has its own min/max error message
    if (isAmountTypeOther) {
        return true;
    }

    // No need to validate if the minimum amount and maximum amount are not set
    if (!minAmtAsNumber && !maxAmtAsNumber) {
        return true;
    }

    // Fail if amount is less than the minimum
    if (minAmtAsNumber && amountAsNumber < minAmtAsNumber) {
        return false;
    }

    // Fail if the amount is greater than the maximum
    if (maxAmtAsNumber && amountAsNumber > maxAmtAsNumber) {
        return false;
    }

    // If we get here the amount is valid
    return true;
};

export const getAmountsToDisplay = (
    activeAmounts = [],
    donationActive = "",
    enableRecur = "",
    isRecurringTabActive = false,
    maxAmt = "",
    minAmt = "",
) => {
    const hasEnabledOneTimeAndRecurringDonations =
        donationActive === "1" && enableRecur === "y";

    // Use the min/max donation settings, which tab is active and the
    // one-time/ongoing amount settings to generate a list of amounts to
    // display
    const amountsToDisplay = activeAmounts.filter((amount) => {
        const isDonationAmountWithinRange = getIsDonationAmountWithinRange(
            amount,
            minAmt,
            maxAmt,
        );

        // All other amounts pass this test as other amounts have their own set
        // of min/max error messaging
        if (!isDonationAmountWithinRange) {
            return false;
        }

        if (hasEnabledOneTimeAndRecurringDonations && isRecurringTabActive) {
            return amount.displayForRecurring === "1";
        }

        if (hasEnabledOneTimeAndRecurringDonations && !isRecurringTabActive) {
            return amount.displayForOneTime === "1";
        }

        // If only one tab is enabled, display all donation amounts
        return true;
    });

    return amountsToDisplay;
};

// -------------------------------------------------------------------------
// MARK: Functions that are used to generate props that are passed into
// component and HTML elements.
// -------------------------------------------------------------------------
export const getStandardAmounts = ({
    amountsAsCards = false,
    amountsToDisplay = [],
    buttonStyle = "",
    currency = {},
    idOfSelectedAmount = "",
    imageSize = "",
    isDisplayedAsPartOfCYOP = false,
    fieldName = "",
    standardAndCustomAmountRefs = [],
}) => {
    const {
        ENUMS: { ItemType },
    } = constants;

    // The other amount will be rendered by the custom amount component
    const standardAmountsToDisplay = amountsToDisplay.filter(
        (amount) => Number(amount.amountType) !== ItemType.OTHER,
    );

    // Generate an array of amounts to display with the list of props that we
    // want to be associated with each standard amount
    const standardAmounts = standardAmountsToDisplay.map(
        (standardAmountToDisplay) => {
            // Redeclare the name and image values as the name that is passed
            // down to StandardAmounts is really the name of the field while
            // the image value is really a url
            const {
                descr = "",
                id = "",
                name: title = "",
                image: url = "",
            } = standardAmountToDisplay;
            // Rename data so that it matches the prop where this is passed
            // into the component
            const name = fieldName;
            const hasTitleDescriptionOrImage = !!title || !!descr || !!url;
            const displayAsCard =
                amountsAsCards &&
                !isDisplayedAsPartOfCYOP &&
                hasTitleDescriptionOrImage;
            const displayFullWidth = amountsAsCards && !isDisplayedAsPartOfCYOP;
            const label = currencyString(
                standardAmountToDisplay.amount,
                currency,
            );
            const checked = idOfSelectedAmount === id;
            // Element is a child of the <CardRadio/> component
            const markUpWithAmountDescription = descr ? (
                <div>
                    <span
                        className="standard-amount__mark-up-with-description -type--small"
                        // We need to use dangerouslySetInnerHTML as donors can
                        // create descriptions with rich text in the CP
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: descr,
                        }}
                    />
                </div>
            ) : null;
            // Generate props for image. Cannot pass an empty image object down as
            // CardRadio will throw a prop type error if that happens
            const altText = title || `Image for donation of ${label}`;
            const display = translateImageSize(imageSize);
            let image = {
                altText: "",
                display: "",
                url: "",
            };
            let containerClasses = " col--6 col--xsm-4 col--sm-3";

            // If a single amount is displayed as a card we need to display all
            // of the amounts full width
            if (displayFullWidth) {
                containerClasses = " col--12";
            }

            if (url) {
                image = {
                    altText,
                    display,
                    url,
                };
            }

            // Consolidate all of the data into an object
            const standardAmount = {
                buttonStyle,
                checked,
                containerClasses,
                displayAsCard,
                id,
                image,
                label,
                markUpWithAmountDescription,
                name,
                title,
                standardAndCustomAmountRefs,
            };

            return standardAmount;
        },
    );

    return standardAmounts;
};

export const getInputProps = ({
    fieldName = "",
    hasSelectedCustomAmount = false,
}) => {
    const checked = hasSelectedCustomAmount;
    // Setting the name to the field name will allow you to use the keyboard
    // to navigate from the standard amounts to the custom amount
    const name = fieldName;
    const inputProps = {
        checked,
        name,
    };
    return inputProps;
};

export const getTextInputButtonProps = ({
    buttonStyle = "",
    customAmountValue = "",
    hasSelectedCustomAmount = false,
    placeholderInitial = "",
    settings = {},
    updateValuesAfterTextInputButtonChange = () => {},
}) => {
    // Add the blue border to the custom amount to indicate that it is selected
    const active = hasSelectedCustomAmount;
    // Update Formik values every time the input changes
    const onChange = updateValuesAfterTextInputButtonChange;
    // Do not tab into the custom amount unless the user has selected the
    // custom amount. The reason for this is that if a user tabs into the
    // amounts field we want them to be able to navigate out of the amounts
    // field with a single press of the tab button and we force the keyboard
    // to skip this element by setting its tabIndex to "-1"
    const tabIndex = !hasSelectedCustomAmount ? "-1" : "";
    // If the value of the custom amount is 0.00, make the value of the field
    // "" to prevent a flicker to "0.00" else just display the custom amount
    // value
    const value = customAmountValue === "0.00" ? "" : customAmountValue;
    // Set the value of the initial placeholder of the custom amount. The blur
    // handler calls resetPlaceholder() which resets e.target.placeholder after
    // the first blur event
    const placeholder = placeholderInitial;

    // buttonStyles sets the styling of the button ("standard" vs "alternate")
    // while the settings object needs to be passed to QMask
    const textInputButtonProps = {
        active,
        buttonStyle,
        onChange,
        placeholder,
        settings,
        tabIndex,
        value,
    };

    return textInputButtonProps;
};

export const getCustomAmountProps = ({
    amountsToDisplay = [],
    buttonStyle = "",
    currency = {},
    customAmountFocusOnLoad = false,
    customAmountValue = "",
    fieldName = "",
    hasSelectedCustomAmount = false,
    isMultiRestrictionCustomAmount = false,
    settings = {},
    standardAndCustomAmountRefs = [],
    updateCustomAmount = () => {},
    updateValuesAfterCustomAmountFocus = () => {},
    updateValuesAfterTextInputButtonArrowKeydown = () => {},
    updateValuesAfterTextInputButtonChange = () => {},
}) => {
    const {
        ENUMS: { ItemType },
    } = constants;
    const isCurrencySymbolBeforeAmount = currency.symbolLocation === "0";
    const indexOfCustomAmount = amountsToDisplay.findIndex(
        (amount) => parseInt(amount.amountType, 10) === ItemType.OTHER,
    );
    const displayCustomAmount = indexOfCustomAmount !== -1;
    const customAmount = displayCustomAmount
        ? amountsToDisplay.find(
              (amount) => Number(amount.amountType) === ItemType.OTHER,
          )
        : {};
    // Set the value of the initial placeholder of the custom amount. The blur
    // handler calls resetPlaceholder() which resets e.target.placeholder after
    // the first blur event. If the other amount is not the only amount,
    // display the name of the other amount. Otherwise display "$0" as the
    // initial placeholder value.
    const placeholderInitial =
        displayCustomAmount && amountsToDisplay.length > 1
            ? customAmount.name
            : "$0";

    // When the user clicks or focuses on the custom amount, the placeholder
    // text should be updated to included the currency symbol with some
    // adjacent whitespace that is located before or after the amount to ensure
    // that the cursor does not appear on top of the currency symbol
    const placeholderValueOnFocus = isCurrencySymbolBeforeAmount
        ? `${currency.symbol}  `
        : `     ${currency.symbol}`;
    let containerClasses = "col";

    // If name of custom amount is more than 7 characters
    // ensure that its text is always visible by moving it
    // to its own line
    if (customAmount.name && customAmount.name.length > 7) {
        containerClasses = "col col--12";
    }

    // Used to decide whether we need to focus on the other amount
    const isOnlyMultiRestrictionAmount =
        hasSelectedCustomAmount &&
        isMultiRestrictionCustomAmount &&
        amountsToDisplay.length === 1;

    // Generate the props that are going to be passed to the instance of the
    // <input/> element that is nested within CustomAmount
    const inputProps = getInputProps({
        fieldName,
        hasSelectedCustomAmount,
    });

    // Generate the props that are going to be passed to the instance of
    // TextInputButton that is nested within CustomAmount
    const textInputButtonProps = getTextInputButtonProps({
        buttonStyle,
        customAmountValue,
        hasSelectedCustomAmount,
        placeholderInitial,
        settings,
        updateValuesAfterTextInputButtonChange,
    });

    const customAmountProps = {
        containerClasses,
        customAmountFocusOnLoad,
        displayCustomAmount,
        hasSelectedCustomAmount,
        indexOfCustomAmount,
        inputProps,
        isMultiRestrictionCustomAmount,
        isOnlyMultiRestrictionAmount,
        placeholderInitial,
        placeholderValueOnFocus,
        standardAndCustomAmountRefs,
        textInputButtonProps,
        updateCustomAmount,
        updateValuesAfterCustomAmountFocus,
        updateValuesAfterTextInputButtonArrowKeydown,
    };

    return customAmountProps;
};

export const getMinMaxMessageProps = ({
    maximumGiftMessage = "",
    minimumGiftMessage = "",
    settings = {},
}) => {
    const { formSettings = {} } = settings;
    const { showMaximumDonation = "", showMinimumDonation = "" } = formSettings;
    const displayMaxMessage = showMaximumDonation === "1";
    const displayMinMessage = showMinimumDonation === "1";

    const minMaxMessageProps = {
        displayMaxMessage,
        displayMinMessage,
        maximumGiftMessage,
        minimumGiftMessage,
    };

    return minMaxMessageProps;
};

export const getCustomAmountErrorProps = ({
    customAmountErrorMessage = "",
    hasSelectedCustomAmount = false,
}) => {
    const displayCustomAmountError =
        hasSelectedCustomAmount &&
        !!customAmountErrorMessage &&
        customAmountErrorMessage.length > 0;
    const message = customAmountErrorMessage;

    const customAmountErrorProps = {
        displayCustomAmountError,
        message,
    };

    return customAmountErrorProps;
};
