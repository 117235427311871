import React from "react";
import { useSelector } from "react-redux";
import ExpressCheckout from "./ExpressCheckout";
import { selectExpressCheckoutDisplayFlags } from "../../../../redux/slices/appSettingsSlice";

const ConnectedExpressCheckout = () => {
    const expressCheckoutDisplayFlags = useSelector(
        selectExpressCheckoutDisplayFlags,
    );
    const { displayApplePayPaymentMethod, displayPayPalSDKScriptProvider } =
        expressCheckoutDisplayFlags;
    return (
        <ExpressCheckout
            displayApplePayPaymentMethod={displayApplePayPaymentMethod}
            displayPayPalSDKScriptProvider={displayPayPalSDKScriptProvider}
        />
    );
};

export default ConnectedExpressCheckout;
