import React from "react";
import { AcceptedPaymentMethods } from "@qgiv/core-donor";
import { useSelector } from "react-redux";
import { getAcceptedCCs } from "@qgiv/core-js";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectShouldDisplayApplePay } from "../../../redux/slices/appSettingsSlice";

const ConnectedAcceptedPaymentMethods = () => {
    const { paymentData, VisaAmexDisc } = useSelector(selectAllFormSettings);

    const { enableCCPayments, enableAchPayments, enablePaypalPayments } =
        paymentData;

    // Build the list of accepted credit card icons to be displayed
    const acceptedCCs = getAcceptedCCs(VisaAmexDisc);
    const shouldDisplayApplePay = useSelector(selectShouldDisplayApplePay);

    const acceptedPaymentProps = {
        enableCCPayments,
        enableAchPayments,
        enablePaypalPayments,
        acceptedCCs,
        shouldDisplayApplePay,
    };

    return <AcceptedPaymentMethods {...acceptedPaymentProps} />;
};

export default ConnectedAcceptedPaymentMethods;
