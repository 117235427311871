import React from "react";
import PropTypes from "prop-types";
import {
    translatePaymentType,
    translatePaymentTypeToFontAwesomeIcon,
} from "@qgiv/core-js";
import { CardRadio } from "../Card";

const PaymentCard = ({
    handleChange,
    payment: { ccType, expiry, id, last, title },
    values: { Stored_Payment_ID },
}) => {
    const paymentType = translatePaymentType(ccType);
    const glyph = translatePaymentTypeToFontAwesomeIcon(ccType);
    const radioProps = {
        checked: Stored_Payment_ID === `${id}`,
        handleChange,
        id: `stored-payment-id-${id}`,
        icon: {
            glyph,
            type: "FontAwesome",
        },
        title,
        name: "Stored_Payment_ID",
        value: id,
    };
    return (
        <li className="col col--12">
            <CardRadio {...radioProps}>
                <span className="-type--small">
                    {paymentType} ending in {last}
                    {expiry && `, Expires ${expiry}`}
                </span>
            </CardRadio>
        </li>
    );
};

PaymentCard.propTypes = {
    handleChange: PropTypes.func.isRequired,
    payment: PropTypes.shape({
        ccType: PropTypes.string,
        expiry: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        last: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
    }).isRequired,
    values: PropTypes.shape({
        Stored_Payment_ID: PropTypes.string,
    }).isRequired,
};

export default PaymentCard;
