import { createSlice } from "@reduxjs/toolkit";
import { restrictionSettings as initialState } from "../initialState";

const getRestrictionSettingsInitialState = () => initialState;
export const restrictionSettingsInitialState =
    getRestrictionSettingsInitialState();

const slice = createSlice({
    name: "restrictionSettings",
    initialState: restrictionSettingsInitialState,
    reducers: {
        setRestrictionSettings: (state, action) => action.payload,
    },
});

// actions
export const { setRestrictionSettings } = slice.actions;

// selectors
export const selectRestrictionSettings = (state) => state.restrictionSettings;

export const selectRestrictions = (state) =>
    state.restrictionSettings.restrictions;

export const selectMultiRestrictionGroups = (state) =>
    state.restrictionSettings.multiRestrictionGroups;

export default slice.reducer;
