import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ReceiptPaymentDetails, translateTransaction } from "@qgiv/core-donor";
import { selectReceiptTransaction } from "../../../../redux/slices/receiptsSlice";

const ConnectedReceiptPaymentDetails = ({ control }) => {
    const transaction = useSelector(selectReceiptTransaction);
    // Convert any of the data that is on the transaction object that is a
    // number to a string before it is displayed on the receipt page
    const transactionWithDataAsStrings = translateTransaction(transaction);
    const {
        bill_address = "",
        bill_city = "",
        bill_country = "",
        bill_state = "",
        bill_zip = "",
        ccLastFour = "",
        ccName = "",
        ccType = "",
        channel = "",
        email = "",
        status = "",
    } = transactionWithDataAsStrings;
    const { options = {} } = control;
    const { endingInText = "ending", paymentDetailsLabel = "Payment Details" } =
        options;

    // Consolidate props into object while passing reassigned variable to the
    // appropriate key.
    const receiptPaymentDetailsProps = {
        bill_address,
        bill_city,
        bill_country,
        bill_state,
        bill_zip,
        ccLastFour,
        ccName,
        ccType,
        channel,
        email,
        endingInText,
        paymentDetailsLabel,
        status,
    };

    return <ReceiptPaymentDetails {...receiptPaymentDetailsProps} />;
};

ConnectedReceiptPaymentDetails.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            endingInText: PropTypes.string,
            paymentDetailsLabel: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedReceiptPaymentDetails;
