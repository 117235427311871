import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import DonationFormFooter from "./DonationFormFooter";
import {
    selectCurrentPage,
    selectIsSinglePageForm,
} from "../../../../redux/slices/appSettingsSlice";

const ConnectedDonationFormFooter = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS, DONATION_AMOUNTS },
        },
    } = constants;

    const currentPage = useSelector(selectCurrentPage);
    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const isPaymentStep = Number(currentPage) === PAYMENT_DETAILS;
    const isSinglePageFormPaymentFooter =
        isSinglePageForm && Number(currentPage) === DONATION_AMOUNTS;
    const showDigiCert = isPaymentStep || isSinglePageFormPaymentFooter;

    const donationFormFooterProps = {
        showDigiCert,
    };

    return <DonationFormFooter {...donationFormFooterProps} />;
};

export default ConnectedDonationFormFooter;
