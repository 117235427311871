import React from "react";
import { EventsPageContainers } from "../../../Containers";

/**
 * @param {object} props - Component properties
 * @param {Array} props.containers - An array of container objects to be displayed on the events page
 * @returns {React.ReactElement}
 */
const EventsPageContent = ({ containers = [] }) => {
    const eventsPageContentProps = {
        containers,
    };

    return <EventsPageContainers {...eventsPageContentProps} />;
};

export default EventsPageContent;
