import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { constants } from "@qgiv/core-js";
import { useScript, useStylesheet } from "../../hooks";
import { Helmet } from "react-helmet";

const DtDWidget = ({ companyId, email, matchId }) => {
    const [scriptLoaded, scriptError] = useScript(
        "https://doublethedonation.com/api/js/ddplugin.js",
    );
    const [styleLoaded, styleError] = useStylesheet(
        "https://doublethedonation.com/api/css/ddplugin.css",
    );

    useEffect(() => {
        // DtD Container
        if (!document.getElementById("dd-container")) {
            const dtd_container = document.createElement("div");
            dtd_container.id = "dd-container";
            document
                .getElementById("receipt-matching-widget")
                .appendChild(dtd_container);
        }

        // wait for script and stylesheet to load before running init script
        if (!scriptLoaded || !styleLoaded) {
            return;
        } else if (
            window.doublethedonation &&
            document.getElementById("dd-container")
        ) {
            const domain =
                window.doublethedonation.integrations.core.strip_domain(email);

            window.doublethedonation.plugin.load_plugin();
            window.doublethedonation.plugin.set_company(companyId);
            window.doublethedonation.plugin.set_donation_identifier(matchId);
            // window.doublethedonation.plugin.set_donation_campaign();
            if (domain && domain.length > 0) {
                window.doublethedonation.plugin.email_domain(domain);
            }
        }
    }, [scriptLoaded, scriptError, styleLoaded, styleError]);

    return scriptLoaded && !scriptError && styleLoaded && !styleError
        ? true
        : false;
};
DtDWidget.propTypes = {
    companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    matchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const ReceiptMatchingWidget = ({
    company,
    companyId,
    email,
    enableMatching,
    matchId,
    matchingTokens,
    thirdPartyMatching,
    noLeftGutter = false,
}) => {
    const { Service } = constants.ENUMS;
    const dtdApiKey = matchingTokens?.[Service.DOUBLEDONATION]?.api_key || "";

    const matchingProps = {
        companyId,
        email,
        matchId,
    };

    const showReceiptMatchingWidget =
        (enableMatching === "1" || enableMatching === true) && // Checks the form setting
        !company && // Checks that it's not a company donation
        Number(thirdPartyMatching) === 2 && // Checks that the api is for DtD
        String(matchId).length > 0; // Checks it isn't a CG-verified donation

    return showReceiptMatchingWidget ? (
        <div
            className={cx("col col--12", noLeftGutter && "col--no-left-gutter")}
        >
            <div id="receipt-matching-widget">
                <Helmet>
                    {/* Orgs sometimes add their own DtD widget to the receipt. This causes an error when we are declaring DDCONF
                    since they are already declaring it. We should check for this value and only add it if not already present.  */}
                    <script>{`
                        if (!window?.DDCONF?.API_KEY) {
                            const DDCONF = {
                                API_KEY : "${dtdApiKey}",
                                COMPANY : "${companyId}"
                            };

                            window.DDCONF = DDCONF;
                        }
                    `}</script>
                </Helmet>

                <DtDWidget {...matchingProps} />
            </div>
        </div>
    ) : null;
};
ReceiptMatchingWidget.propTypes = {
    company: PropTypes.string,
    companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    enableMatching: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    email: PropTypes.string,
    matchingTokens: PropTypes.object,
    matchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    noLeftGutter: PropTypes.bool,
    thirdPartyMatching: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default ReceiptMatchingWidget;
