import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@qgiv/core-donor";
import DedicationTypesSelect from "./DedicationTypesSelect";
import DedicationFields from "./DedicationFields";
import DedicationNotification from "./DedicationNotification";

const Dedication = ({
    dedicationFieldsProps,
    dedicationLabel,
    dedicationNotificationProps,
    dedicationTypeProps,
    errors,
    handleBlur,
    handleChange,
    Has_Dedication,
    hasDedicationFields,
    hasDedicationNotificationEnabled,
    touched,
}) => (
    <div className="dedications col col--12 col--no-left-gutter">
        <Checkbox
            id="Has-Dedication"
            name="Has_Dedication"
            handleBlur={handleBlur}
            handleChange={handleChange}
            checked={Has_Dedication}
            error={
                touched?.Has_Dedication &&
                errors?.Has_Dedication &&
                errors?.Has_Dedication?.length > 0
            }
            errorText={errors?.Has_Dedication || ""}
            ignoreBottomPadding
        >
            <p>{dedicationLabel}</p>
        </Checkbox>

        {Has_Dedication && (
            <div className="dedications__content -padding-top--15">
                <DedicationTypesSelect {...dedicationTypeProps} />

                {hasDedicationFields && (
                    <DedicationFields {...dedicationFieldsProps} />
                )}

                {hasDedicationNotificationEnabled && (
                    <DedicationNotification {...dedicationNotificationProps} />
                )}
            </div>
        )}
    </div>
);

Dedication.propTypes = {
    dedicationFieldsProps: PropTypes.shape({}).isRequired,
    dedicationLabel: PropTypes.string.isRequired,
    dedicationNotificationProps: PropTypes.shape({}).isRequired,
    dedicationTypeProps: PropTypes.shape({}).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    Has_Dedication: PropTypes.bool.isRequired,
    hasDedicationFields: PropTypes.bool.isRequired,
    hasDedicationNotificationEnabled: PropTypes.bool.isRequired,
    errors: PropTypes.shape({ Has_Dedication: PropTypes.string }).isRequired,
    touched: PropTypes.shape({ Has_Dedication: PropTypes.bool }).isRequired,
};

export default Dedication;
