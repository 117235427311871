import { constants, isDisplayable } from "@qgiv/core-js";
import { getIsSystemFieldDisplayable } from "../fieldHelpers";
import { getHasOneTimeAmountToDisplay } from "../donationFormHelpers";
import { getHasOngoingAmountToDisplay } from "../amountHelpers";
import {
    getHasEnabledActiveStandardRestrictions,
    getRestrictionById,
} from "../standardRestrictionsHelpers";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.ActiveDonationFrequency} ActiveDonationFrequency
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Plan} Plan
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FieldSettings.SystemField} SystemField
 * @typedef {import("@qgiv/donation-form").ReduxTypes.UrlShortcuts.UrlShortcuts} UrlShortcuts
 */

const {
    ENUMS: { FieldType },
} = constants;

/**
 * @public
 * @function getShouldRenderStandardRestriction
 * @param {object} settings all settings needed for initial values
 * @param {number} settings.currentDisplay  form is displaying on Desktop, Mobile, Text-to-Donate
 * @param {boolean} settings.hasActiveFulfillment Invoice is active
 * @param {string} settings.restrictionGivingType Type of Restrictions that are enabled
 * @param {Array} settings.restrictions active restrictions
 * @param {object} settings.urlShortcuts object from initialState of url params
 * @description Looks at all the conditions for restrictions and determines whether
 * restrictions values should be displayed
 * @returns {boolean} Returns bool of whether the Standard Restrictions should be rendered
 */
export const getShouldRenderStandardRestriction = ({
    currentDisplay,
    hasActiveFulfillment,
    restrictionGivingType,
    restrictions,
    urlShortcuts,
}) => {
    const hasStandardRestrictionToSelect =
        restrictions.length > 0
            ? getHasEnabledActiveStandardRestrictions({
                  restrictions,
                  hasActiveFulfillment,
                  currentDisplay,
                  restrictionGivingType,
              })
            : false;

    const requestedRestrictionData = restrictions.find(
        (requestedRestriction) =>
            Number(requestedRestriction.id) ===
            Number(urlShortcuts.restriction),
    );

    const hasRestrictionRequested =
        requestedRestrictionData &&
        Object.keys(requestedRestrictionData).length > 0;

    const restrictionSelected =
        urlShortcuts.restriction &&
        hasStandardRestrictionToSelect &&
        hasRestrictionRequested
            ? getRestrictionById(restrictions, urlShortcuts.restriction)
            : {};

    const standardRestrictionCanBeSelectedFromUrlValue =
        Object.keys(restrictionSelected).length > 0 &&
        isDisplayable(restrictionSelected, currentDisplay);

    const shouldBuildStandardRestrictionInitialValues =
        hasStandardRestrictionToSelect &&
        standardRestrictionCanBeSelectedFromUrlValue;

    return shouldBuildStandardRestrictionInitialValues;
};

/**
 * @public
 * @function getShouldRenderOneTimeAmounts
 * @param {object} settings all settings needed for initial values
 * @param {object} settings.customAmountObject object containing custom amount
 * @param {boolean} settings.hasOneTimeShortcuts boolean of whether we have onetime shortcuts
 * @param {object} settings.otherAmountObject object containing other amount
 * @description evaluates whether onetime can be displayed
 * @returns {boolean} Returns bool of whether the onetime amounts should be rendered
 */
export const getShouldRenderOneTimeAmounts = ({
    customAmountObject,
    hasOneTimeShortcuts,
    otherAmountObject,
}) => {
    const isOneTimeEnabledForOtherAmount = otherAmountObject?.displayForOneTime;

    const isOneTimeEnabledForCustomValue =
        customAmountObject?.displayForOneTime;

    const shouldRenderOneTimeAmount =
        hasOneTimeShortcuts &&
        (isOneTimeEnabledForCustomValue || isOneTimeEnabledForOtherAmount);

    return shouldRenderOneTimeAmount;
};

/**
 * @public
 * @function getCustomAndOtherProps
 * @param {object} settings all settings needed for initial values
 * @param {object} settings.customAmountObject object containing custom amount
 * @param {object} settings.otherAmountObject object containing other amount
 * @description status of onetime/recurring enabling for Custom and Other amounts
 * @returns {object} Object with booleans
 */
export const getCustomAndOtherProps = ({
    customAmountObject,
    otherAmountObject,
}) => {
    const isOneTimeEnabledForOtherAmount =
        !!otherAmountObject?.displayForOneTime;

    const isOneTimeEnabledForCustomValue =
        !!customAmountObject?.displayForOneTime;

    const isRecurringEnabledForOtherAmount =
        !!otherAmountObject?.displayForRecurring;

    const isRecurringEnabledForCustomValue =
        !!customAmountObject?.displayForRecurring;

    return {
        isOneTimeEnabledForCustomValue,
        isOneTimeEnabledForOtherAmount,
        isRecurringEnabledForCustomValue,
        isRecurringEnabledForOtherAmount,
    };
};

/**
 * @public
 * @function getCustomAmountObject
 * @param {Array} activeAmounts array of active amounts from initialState
 * @param {string} urlShortcutAmount urlShortcut value for urlShortcuts.amount
 * @description either an id or amount can be passed in we need to be able to get the id from both
 * @returns {object} Object of custom amount data
 */
export const getCustomAmountObject = (activeAmounts, urlShortcutAmount) => {
    const customAmountObject =
        activeAmounts.find(
            (amount) =>
                // either the amount id or the amount value may be passed in url
                amount.id === urlShortcutAmount ||
                Number(amount.amount) === Number(urlShortcutAmount),
        ) || {};
    return customAmountObject;
};

/**
 * @public
 * @function getAllPropsToDetermineTypeOfUrlShortcutsToRender
 * @param {object} settings all settings needed for initial values
 * @param {Array} settings.activeAmounts Array of all active amounts
 * @param {object} settings.urlShortcuts object with url params from initialState
 * @description looks at urlShortcuts and determines what shortcuts are available
 * @returns {object} Object of props
 */
export const getAllPropsToDetermineTypeOfUrlShortcutsToRender = ({
    activeAmounts,
    urlShortcuts,
}) => {
    const {
        ENUMS: { ItemType },
    } = constants;
    const hasOtherAmountShortcut = !!urlShortcuts?.otherAmount;
    const hasCustomAmountShortcut = !!urlShortcuts?.amount;
    const hasFrequencyShortcut = !!urlShortcuts?.frequency;
    const hasGivingPlanShortcut = !!urlShortcuts?.pledge;
    const hasAmountValueShortcut = !!urlShortcuts?.amountValue;

    const hasRecurringShortcuts =
        (!!urlShortcuts?.recurring || hasFrequencyShortcut) &&
        (hasCustomAmountShortcut || hasOtherAmountShortcut);

    const hasOneTimeShortcuts =
        !!urlShortcuts?.onetime &&
        (hasCustomAmountShortcut || hasOtherAmountShortcut);

    const otherAmountObject = hasOtherAmountShortcut
        ? activeAmounts.find(
              (amount) => Number(amount.amountType) === ItemType.OTHER,
          )
        : {};

    const otherAmountId = hasOtherAmountShortcut ? otherAmountObject?.id : "";

    const customAmountObject = hasCustomAmountShortcut
        ? activeAmounts.find(
              (amount) =>
                  //   either the amount id or the amount value may be passed in url
                  amount.id === urlShortcuts.amount ||
                  Number(amount.amount) === Number(urlShortcuts.amount),
          ) || {}
        : {};

    const hasAmountOnly =
        !urlShortcuts.onetime &&
        !urlShortcuts.recurring &&
        (hasCustomAmountShortcut || hasOtherAmountShortcut);

    return {
        customAmountObject,
        hasAmountValueShortcut,
        hasAmountOnly,
        hasCustomAmountShortcut,
        hasFrequencyShortcut,
        hasGivingPlanShortcut,
        hasOneTimeShortcuts,
        hasOtherAmountShortcut,
        hasRecurringShortcuts,
        otherAmountId,
        otherAmountObject,
    };
};

/**
 *
 * @param {object} settings
 * @param {Amount[]} settings.activeAmounts
 * @param {boolean} settings.donationActive
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getHasValidOneTimeShortcut = ({
    activeAmounts,
    donationActive,
    urlShortcuts,
}) => {
    const hasOneTimeShortcut = !!urlShortcuts?.onetime;
    const hasEnabledOneTimeDonations = donationActive;
    const hasValidOneTimeAmountToDisplay =
        getHasOneTimeAmountToDisplay(activeAmounts);

    if (!hasOneTimeShortcut || !hasEnabledOneTimeDonations) {
        return false;
    }

    if (hasValidOneTimeAmountToDisplay) {
        return true;
    }

    return false;
};

/**
 *
 * @param {object} settings
 * @param {Amount[]} settings.activeAmounts
 * @param {Plan[]} settings.activePlans
 * @param {boolean} settings.enableRecur
 * @param {boolean} settings.pledgeActive
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getHasValidOngoingShortcut = ({
    activeAmounts,
    activePlans,
    enableRecur,
    pledgeActive,
    urlShortcuts,
}) => {
    const hasRecurringShortcut = !!urlShortcuts?.recurring;
    const hasEnabledRecurringDonations = enableRecur;
    const hasEnabledGivingPlans = pledgeActive;
    const hasValidGivingPlan = activePlans && activePlans.length > 0;
    const hasValidOngoingAmountToDisplay =
        getHasOngoingAmountToDisplay(activeAmounts);

    if (!hasRecurringShortcut || !hasEnabledRecurringDonations) {
        return false;
    }

    if (hasEnabledGivingPlans && hasValidGivingPlan) {
        return true;
    }

    if (!hasEnabledGivingPlans && hasValidOngoingAmountToDisplay) {
        return true;
    }

    return false;
};

/**
 *
 * @param {object} settings
 * @param {Amount[]} settings.activeAmounts
 * @param {Plan[]} settings.activePlans
 * @param {boolean} settings.donationActive
 * @param {boolean} settings.enableRecur
 * @param {boolean} settings.pledgeActive
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildHasSelectedRecurringDonationInitialValues = ({
    activeAmounts,
    activePlans,
    donationActive,
    enableRecur,
    pledgeActive,
    urlShortcuts,
}) => {
    const hasValidOneTimeShortcut = getHasValidOneTimeShortcut({
        activeAmounts,
        donationActive,
        urlShortcuts,
    });
    const hasValidOngoingShortcut = getHasValidOngoingShortcut({
        activeAmounts,
        activePlans,
        enableRecur,
        pledgeActive,
        urlShortcuts,
    });

    if (hasValidOneTimeShortcut || hasValidOngoingShortcut) {
        return true;
    }

    return false;
};

/**
 *
 * @param {object} settings
 * @param {ActiveDonationFrequency[]} settings.activeDonationFrequencies
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getHasUsedValidFrequencyInShortcut = ({
    activeDonationFrequencies,
    urlShortcuts,
}) => {
    const recurringFrequencyShortcut = urlShortcuts?.frequency;
    const selectedFrequencySettings =
        activeDonationFrequencies.find(
            (frequency) => frequency.value === recurringFrequencyShortcut,
        ) || {};
    const hasUsedValidFrequencyInShortcut =
        Object.keys(selectedFrequencySettings).length > 0;

    return hasUsedValidFrequencyInShortcut;
};

/**
 *
 * @param {object} settings
 * @param {Amount[]} settings.activeAmounts
 * @param {ActiveDonationFrequency[]} settings.activeDonationFrequencies
 * @param {Plan[]} settings.activePlans
 * @param {boolean} settings.enableRecur
 * @param {boolean} settings.pledgeActive
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildRecurringFrequencyInitialValues = ({
    activeAmounts,
    activeDonationFrequencies,
    activePlans,
    enableRecur,
    pledgeActive,
    urlShortcuts,
}) => {
    const hasRecurringFrequencyShortcut = !!urlShortcuts?.frequency;
    const hasValidOngoingShortcut = getHasValidOngoingShortcut({
        activeAmounts,
        activePlans,
        enableRecur,
        pledgeActive,
        urlShortcuts,
    });
    const hasUsedValidFrequencyInShortcut = getHasUsedValidFrequencyInShortcut({
        activeDonationFrequencies,
        urlShortcuts,
    });

    // If we cannot build an ongoing shortcut we should not attempt to build a
    // frequency shortcut as it will always be invalid
    const hasValidFrequencyShortcut =
        hasValidOngoingShortcut &&
        hasRecurringFrequencyShortcut &&
        hasUsedValidFrequencyInShortcut;

    if (hasValidFrequencyShortcut) {
        return true;
    }

    return false;
};

/**
 *
 * @param {object} settings
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildValuesFromPersonalInfo = ({ urlShortcuts }) => {
    const { personalInfo = {} } = urlShortcuts;
    const shouldBuildValuesFromPersonalInfo =
        Object.keys(personalInfo).length > 0;

    return shouldBuildValuesFromPersonalInfo;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildAddress = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { address1 = "" } = personalInfo;

    const hasParam = !!address1;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.ADDRESS,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildAddress2 = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { address2 = "" } = personalInfo;

    const hasParam = !!address2;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.ADDRESS_2,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildCity = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { city = "" } = personalInfo;

    const hasParam = !!city;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.CITY,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildCompany = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { company = "" } = personalInfo;

    const hasParam = !!company;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.COMPANY,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildEmail = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { email = "" } = personalInfo;

    const hasParam = !!email;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.SYSTEM_EMAIL,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildFirstName = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { first_name = "" } = personalInfo;

    const hasParam = !!first_name;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.FIRST_NAME,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildLastName = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { last_name = "" } = personalInfo;

    const hasParam = !!last_name;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.LAST_NAME,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildPhone = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { phone = 0 } = personalInfo;

    const hasParam = !!phone;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.SYSTEM_PHONE,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildState = ({
    currentDisplay,
    systemFields,
    urlShortcuts,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { state = "" } = personalInfo;

    const hasParam = !!state;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.STATE,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {boolean}
 */
export const getShouldBuildZip = ({
    currentDisplay,
    urlShortcuts,
    systemFields,
}) => {
    const { personalInfo = {} } = urlShortcuts;
    const { zip = 0 } = personalInfo;

    const hasParam = !!zip;
    const isSystemFieldDisplayable = getIsSystemFieldDisplayable({
        currentDisplay,
        fieldType: FieldType.ZIP,
        systemFields,
    });

    if (hasParam && isSystemFieldDisplayable) {
        return true;
    }

    return false;
};
