import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { currencyString, constants } from "@qgiv/core-js";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";
import { selectPromiseTransaction } from "../../../../../redux/slices/promiseTransactionSlice";
import InvoiceFulfillmentOption from "./InvoiceFulfillmentOption";

/**
 * @param {object} props - Component properties
 * @param {number} props.controlId - The control ID
 * @param {Function} props.handleSubtotalChange - Function to handle subtotal change upon
 * selection of invoice payment option
 * @returns {React.ReactElement} The PayFullInvoiceBalance component.
 */
const ConnectedPayFullInvoiceBalance = ({
    controlId,
    handleSubtotalChange,
}) => {
    const promiseTransaction = useSelector(selectPromiseTransaction);
    const formSettings = useSelector(selectAllFormSettings);
    const { currency } = formSettings;
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const { payFullInvoiceBalanceLabel = "Pay Full Balance" } = options;
    const { handleBlur, values } = useFormikContext();
    const { Invoice_Complete_Fulfillment } = values;
    const { InvoiceFulfillmentType } = constants.ENUMS;
    const hasRecurringInvoice = promiseTransaction.recurring !== "0";

    const fullInvoiceAdditionalText = `<span class="-type--small">Includes this invoice, any<br/> outstanding invoices and your<br/> remaining ${
        promiseTransaction.remainingInvoices - 1
    } invoices</span>`;

    const payFullInvoiceBalanceProps = {
        cardRadioProps: {
            id: "total-fulfillment",
            name: "Invoice_Complete_Fulfillment",
            title: payFullInvoiceBalanceLabel,
            extraTitle: `${currencyString(
                Number(promiseTransaction.invoiceAmountRemaining) || 0,
                currency,
            )}`,
            value: InvoiceFulfillmentType.FULL,
            handleBlur,
            handleChange: handleSubtotalChange,
            checked: Invoice_Complete_Fulfillment,
        },
        additionalText: fullInvoiceAdditionalText,
        hasRecurringInvoice,
    };

    return <InvoiceFulfillmentOption {...payFullInvoiceBalanceProps} />;
};

ConnectedPayFullInvoiceBalance.propTypes = {
    controlId: PropTypes.number.isRequired,
    handleSubtotalChange: PropTypes.func.isRequired,
};

export default ConnectedPayFullInvoiceBalance;
