import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getTextWidth } from "@qgiv/core-js";
import { Icon } from "@qgiv/core-react";

import "./InlineSelect.scss";

const InlineSelect = ({
    value,
    classNames,
    handleBlur,
    handleChange,
    children,
    ...props
}) => {
    // -------------------------------------------------------------------------
    // MARK: Refs
    // -------------------------------------------------------------------------
    const selectRef = React.createRef();

    // -------------------------------------------------------------------------
    // MARK: State
    // -------------------------------------------------------------------------
    const [inputWidth, setInputWidth] = useState(0);

    // -------------------------------------------------------------------------
    // MARK: Utility
    // -------------------------------------------------------------------------
    const calculateInputWidth = () => {
        const selectEl = selectRef.current;
        const optionEl = selectEl.querySelector("option:checked");
        const text = optionEl.text;

        // Grab padding-right of select field
        const selectElCss = window.getComputedStyle(selectEl, null);
        const paddingRight = parseFloat(
            selectElCss.getPropertyValue("padding-right"),
        );

        // Grab the font values of the option field
        const optionElCss = window.getComputedStyle(optionEl, null);
        const fontWeight = optionElCss.getPropertyValue("font-weight");
        const fontSize = parseFloat(optionElCss.getPropertyValue("font-size"));
        const fontFamily = optionElCss.getPropertyValue("font-family");
        const font = `${fontWeight} ${fontSize}px ${fontFamily}`;

        // Get width of text
        const textWidth = getTextWidth(text, font);

        // Get left and right padding of <option>
        const optionPadding =
            parseFloat(optionElCss.getPropertyValue("padding-left")) +
            parseFloat(optionElCss.getPropertyValue("padding-right"));

        // Check if browser is IE
        const isIE = false || !!document.documentMode;
        const IEwidth = isIE ? 4 : 0;

        // Calculate input width
        const width = textWidth + optionPadding + paddingRight + IEwidth;
        setInputWidth(width);
    };

    // -------------------------------------------------------------------------
    // MARK: React Hooks
    // -------------------------------------------------------------------------
    useEffect(() => {
        // Update input width when value changes
        calculateInputWidth();
    }, [value]);

    return (
        <label
            className={`inline-select ${
                typeof classNames !== "undefined" ? classNames : ""
            }`}
        >
            <select
                className="inline-select__select"
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
                style={{ width: `${inputWidth}px` }}
                {...props}
                ref={selectRef}
            >
                {children}
            </select>

            <div className="inline-select__icon-wrapper">
                <Icon
                    classNames={["inline-select__icon"]}
                    glyph="chevron-down-solid"
                    type="FontAwesome"
                />
            </div>
        </label>
    );
};

InlineSelect.propTypes = {
    value: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    children: PropTypes.any,
};

export default InlineSelect;
