import React from "react";
import PropTypes from "prop-types";
import { DonorAccountActionLink } from "@qgiv/core-donor";

const DonorIsLoggedOut = ({ donorIsLoggedOutProps }) => (
    <DonorAccountActionLink {...donorIsLoggedOutProps} />
);

DonorIsLoggedOut.propTypes = {
    donorIsLoggedOutProps: PropTypes.shape({}).isRequired,
};

export default DonorIsLoggedOut;
