import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import QFieldInput from "../QFieldInput";

const SystemFieldGroupAttendeeDetails = ({
    fields,
    fieldsOptions,
    enableGuestManagement,
    ticketDeliveryType,
    isCms,
    values,
    ...rest
}) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    const renderAttendeeFields = () => {
        const attendeeFields = fields.map((field) => {
            const fieldType = Number(field.type);
            const isFirstOrLast =
                fieldType === FieldType.EVENT_FIRST_NAME ||
                fieldType === FieldType.EVENT_LAST_NAME;
            const sixCol = isFirstOrLast ? " col--sm-6" : "";

            const fieldNamePath = fieldsOptions.find(
                (options) => options.fieldId === field.id,
            )?.fieldNamePath;

            const attendeeDetailsFieldProps = {
                field,
                name: isCms ? "Attendee_Details_Field" : fieldNamePath,
                enableGuestManagement,
                ticketDeliveryType,
                useFormikRequired: false,
                isConditional: false,
                values,
                ...rest,
            };
            return (
                <div key={field.id} className={`col col--12${sixCol}`}>
                    <QFieldInput {...attendeeDetailsFieldProps} field={field} />
                </div>
            );
        });
        return attendeeFields;
    };
    return <div className="grid">{renderAttendeeFields()}</div>;
};

SystemFieldGroupAttendeeDetails.propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
    ).isRequired,
    fieldsOptions: PropTypes.arrayOf(
        PropTypes.shape({
            fieldId: PropTypes.string.isRequired,
            fieldNamePath: PropTypes.string.isRequired,
        }),
    ).isRequired,
    isCms: PropTypes.bool.isRequired,
    enableGuestManagement: PropTypes.bool.isRequired,
    ticketDeliveryType: PropTypes.number.isRequired,
    values: PropTypes.shape({}).isRequired,
};

export default SystemFieldGroupAttendeeDetails;
