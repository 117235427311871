// -------------------------------------------------------------------------
// NOTE: Currently this component is being used for P2P and Events, at some
// point it would be nice to also use it for Form for consistency and
// re-useability, however Form would take a bit of untangling. This component
// needs to be wrapped in a connected component that feeds in the apps specific
// data.
// -------------------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useField } from "formik";
import { useBreakpoints, Icon, Tooltip } from "@qgiv/core-react";
import { currencyString } from "@qgiv/core-js";
import { useHover } from "../../hooks";
import { makeMatchingSearchRequest } from "../../api/matchingApi";
import { FormikField } from "../Fields/Field";
import Button, { TextButton } from "../Button";
import CheckboxWithTooltip from "../Fields/CheckboxWithTooltip";
import "./MatchingGift.scss";
import { FormikCheckbox } from "../Fields/Checkbox";
import { MoreInfoModal } from "../MoreInfoModal";

const MatchingGift = ({
    currency,
    displayMatchingGift = false,
    donationTotal,
    employerField = "",
    isCG,
    isCms = false,
    isDtD,
    isHEP,
    isManualMatch,
    isStandardDonationForm = false,
    isVisible,
    manualMatchingAmountLabel = "",
    manualMatchingCompanyLabel = "",
    manualMatchingHelpText = "",
    manualMatchingLabel = "",
    sectionHeading,
    description,
    matchingSearchButtonLabel,
    matchingSearchDescription,
    matchingSearchCancelLink,
    matchingEmployerLabel,
    matchingEmployerHelpText = "",
    matchingWorkEmail,
    matchingWorkEmailHelpText = "",
    noLeftGutter = false,
    path,
    providerLogoSrc,
    providerLabel,
    searchEnabled = false,
    selectionStored,
    teaserLabel,
    values,
    viewType,
    setValues,
    workEmailField = "",
}) => {
    // This determines whether we use Tooltip or MoreInfoModal
    const { isSmallScreen } = useBreakpoints();

    const isHoverable = useHover();

    // handles switching between the 4 views of this component:
    // ManualMatching, SearchView, SelectionView, and ResultsView
    const [view, setView] = useState(
        isManualMatch
            ? viewType
            : selectionStored
              ? "ResultsView"
              : "SearchView",
    );

    // "searching" handles _search_ Search Button state
    const [searching, setSearching] = useState(false);

    // "displayWorkEmailField" handles whether to display that field
    const [displayWorkEmailField, setDisplayWorkEmailField] = useState(isCG);
    const [selection, setSelection] = useState({});

    // These Fields do not exist on the page therefore we must set here
    useEffect(() => {
        // set our variable to true
        let isComponentMounted = true;

        if (isComponentMounted) {
            if (selection.id && selection.id !== "none") {
                setValues({
                    ...values,
                    Matching_Company_Name: selection.company_name,
                    Matching_Company_ID: selection.id,
                    Matching_Parent_Raw_ID: selection.parent_raw_id,
                    Matching_Contact_Address_Line_1:
                        selection.contact_address_line1,
                    Matching_Contact_Address_Line_2:
                        selection.contact_address_line2,
                    Matching_Contact_City: selection.contact_city,
                    Matching_Contact_State: selection.contact_state,
                    Matching_Contact_Zip: selection.contact_zip,
                    Matching_Contact_Phone: selection.contact_phone,
                    Matching_Contact_Email: selection.contact_email,
                    Matching_Donor_Found: selection.donorExists, // Cyber Grants
                    Matching_Donor_Verified: selection.donorVerified, // Cyber Grants
                });
            }
        }

        // Cleanup function that is run when the component dismounts thus
        // preventing a memory leak by ensuring that the component state will
        // not update if the component has been dismounted by the time the
        // async request completes
        return () => {
            isComponentMounted = false;
        };
    }, [selection, setSelection]);

    // stores search results
    const [results, setResults] = useState([]);

    const formikProps = {
        name: "Match_Gift",
        type: "checkbox",
    };
    const [{ onBlur, onChange, ...field }, meta] = useField(formikProps);
    const fieldProps = {
        handleBlur: onBlur,
        handleChange: onChange,
        ...field,
    };

    const handleSearch = () => {
        let postData = {
            formKey: path,
            formType: "1",
            prefix: !workEmailField ? employerField : "",
            email: workEmailField,
            csrfToken: document.getElementById("csrfToken").value, //REMOVE THIS once csrfTokens are sorted
        };
        if (searchEnabled && !searching) {
            setSearching(true);
            selection && selection.id
                ? (postData.emailConfirmation = "1")
                : null;

            makeMatchingSearchRequest(postData).then((response) => {
                setSearching(false);

                if (!response || (response && !response.data.success)) {
                    if (!displayWorkEmailField) setDisplayWorkEmailField(true);
                    setResults([]);
                }

                if (
                    response &&
                    response.data &&
                    response.data.result &&
                    response.data.result[0].donorVerified
                ) {
                    // donor email was verified
                    setSelection(response.data.result[0]);
                    setValues({
                        ...values,
                        Matching_Donor_Found:
                            response.data.result[0].donorExists,
                        Matching_Donor_Verified:
                            response.data.result[0].donorVerified,
                    });

                    setView("ResultsView");
                } else if (
                    response &&
                    response.data &&
                    response.data.result &&
                    response.data.result[0].email &&
                    !response.data.result[0].donorVerified &&
                    !selection.company_name
                ) {
                    // donor email was not verified, no company selected yet
                    setSelection(response.data.result[0]);
                    setView("ResultsView");
                } else if (
                    response &&
                    response.data &&
                    response.data.result &&
                    !response.data.result[0].email
                ) {
                    // employer search results
                    setResults(response.data.result);
                    setView("SelectionView");
                } else if (response && response.data && response.data.result) {
                    // result is "success: false", but there is still data
                    setView("ResultsView");
                } else if (response && !response.data.success && selection.id) {
                    setView("ResultsView");
                } else if (
                    (!response || !response.data.success) &&
                    displayWorkEmailField &&
                    (!selection.length || selection.id === "none")
                ) {
                    // result is "success: false", no data and no prev selection
                    setSelection({ id: "none" });
                    setView("ResultsView");
                }
            });
        }
    };

    const handleResultClick = (result) => {
        setSelection(result);
        setDisplayWorkEmailField(true);
        setView("SearchView");
    };

    const handleCancelButton = () => {
        // clear selection values
        setValues({
            ...values,
            Employer: "",
            Work_Email: "",
            Matching_Company_Name: "",
            Matching_Company_ID: "",
            Matching_Parent_Raw_ID: "",
            Matching_Contact_Address_Line_1: "",
            Matching_Contact_Address_Line_2: "",
            Matching_Contact_City: "",
            Matching_Contact_State: "",
            Matching_Contact_Zip: "",
            Matching_Contact_Phone: "",
            Matching_Contact_Email: "",
            Matching_Donor_Found: "",
            Matching_Donor_Verified: false,
        });

        // // reset state
        setSelection({});

        if (isDtD || isHEP) {
            setDisplayWorkEmailField(false);
        }

        // return to start screen
        setView("SearchView");
    };

    const handleNoneResult = () => {
        // clear selection
        setSelection({ id: "none" });

        // show Work Email search field if not already shown
        if (!displayWorkEmailField) setDisplayWorkEmailField(true);

        // go back to search view
        setView("SearchView");
    };

    const MatchingHeader = () => {
        return (
            <div className="qg-vendor-gift-matching__header gift-matching__header">
                {view === "SearchView" && (
                    <>
                        <h4 className="gift-matching__header--title">
                            {sectionHeading}
                        </h4>
                        {description.length > 0 && (
                            <p className="gift-matching__header--subtitle">
                                {description}
                            </p>
                        )}
                    </>
                )}
                {view === "SelectionView" && (
                    <>
                        {results && results.length > 0 ? (
                            <>
                                <h4 className="gift-matching__header--title">
                                    {sectionHeading}
                                </h4>
                                <p className="gift-matching__header--subtitle">
                                    {matchingSearchDescription}
                                </p>
                            </>
                        ) : (
                            <>
                                <h4 className="gift-matching__header--title">
                                    Hmm. We&apos;re not sure if your employer
                                    will match your gift.
                                </h4>
                                <p className="gift-matching__header--subtitle">
                                    You can always double-check with them
                                    directly to see if they&apos;ll match your
                                    gift.
                                </p>
                            </>
                        )}
                    </>
                )}
                {view === "ResultsView" && (
                    <>
                        {(values.Matching_Company_Name ||
                            selection.company_name) && (
                            <Icon
                                classNames={[
                                    "gift-matching__header--checkcircle",
                                ]}
                                glyph="check-circle-solid"
                                type="FontAwesome"
                            />
                        )}
                        {selection.data &&
                            selection.data.result &&
                            selection.data.result[0].donorVerified && (
                                <h4 className="gift-matching__header--title">
                                    <strong>
                                        Your donation is likely to be matched!
                                    </strong>
                                </h4>
                            )}
                        {(values.Matching_Company_Name ||
                            selection.company_name) && (
                            <h4 className="gift-matching__header--title">
                                <strong>
                                    {values.Matching_Company_Name ||
                                        selection.company_name}
                                </strong>{" "}
                                is likely to give up to an additional
                                <strong>
                                    {" "}
                                    {currencyString(donationTotal, currency)}
                                </strong>{" "}
                                thanks to you!
                            </h4>
                        )}
                        {selection.subsidiaryOf && (
                            <p className="gift-matching__header--subtitle">
                                <strong>{selection.company_name}</strong> is a
                                subsidiary of {selection.subsidiaryOf}
                            </p>
                        )}
                        {(values.Matching_Donor_Verified ||
                            (selection.email && selection.donorVerified)) && (
                            <h4 className="gift-matching__header--title">
                                Your donation is likely to be matched!
                            </h4>
                        )}
                        {selection.email && !selection.donorVerified && (
                            <h4 className="gift-matching__header--title">
                                We were unable to confirm your work email
                            </h4>
                        )}
                        {selection.company_name ||
                            (selection.email && selection.donorVerified && (
                                <p className="gift-matching__header--subtitle">
                                    After completing your gift, {providerLabel}{" "}
                                    will send a confirmation to your work email.
                                </p>
                            ))}
                        {selection.id === "none" && !selection.company_name && (
                            <>
                                <h4 className="gift-matching__header--title">
                                    Hmm. We&apos;re not sure if your employer
                                    will match your gift.
                                </h4>
                                <p className="gift-matching__header--subtitle">
                                    You can always double-check with them
                                    directly to see if they&apos;ll match your
                                    gift.
                                </p>
                            </>
                        )}
                    </>
                )}
            </div>
        );
    };

    // search results component
    const MatchingSearchResults = () => {
        if (results.length > 0) {
            return (
                <fieldset>
                    <ul className="gift-matching__results" role="radiogroup">
                        {results.map((result, index) => (
                            <li
                                onClick={() => {
                                    handleResultClick(result);
                                }}
                                className="gift-matching__result"
                                key={`${index}-${result.id}`}
                                role="radio"
                                aria-checked="false"
                                tabIndex={index === 0 ? `0` : `-1`}
                                autoFocus={index === 0}
                            >
                                <input
                                    type="radio"
                                    name="gift-matching-selection"
                                    className="gift-matching__radio"
                                    value={result.company_name}
                                    id={`gift-matching__radio-${index}`}
                                />
                                <label
                                    className="-cursor--pointer"
                                    htmlFor={`gift-matching__radio-${index}`}
                                >
                                    <Icon
                                        classNames={[
                                            "gift-matching__result-building",
                                        ]}
                                        glyph="building-regular"
                                        type="FontAwesome"
                                    />
                                    <div className="gift-matching__result-name">
                                        <span className="gift-matching__company-name">
                                            {result.company_name}
                                        </span>
                                        {result.subsidiaryOf && (
                                            <span className="gift-matching__subsidiary-of">
                                                Subsidiary of{" "}
                                                {result.subsidiaryOf}
                                            </span>
                                        )}
                                    </div>
                                    <Icon
                                        classNames={[
                                            "gift-matching__result-arrow",
                                        ]}
                                        glyph="chevron-right-solid"
                                        type="FontAwesome"
                                    />
                                </label>
                            </li>
                        ))}
                        <li
                            className="gift-matching__result"
                            role="radio"
                            aria-checked="false"
                            tabIndex="0"
                        >
                            <input
                                onClick={handleNoneResult}
                                type="radio"
                                name="gift-matching-selection"
                                className="gift-matching__radio"
                                id="gift-matching__radio-none"
                                value="none"
                            />
                            <label
                                className="-cursor--pointer"
                                htmlFor="gift-matching__radio-none"
                            >
                                <Icon
                                    classNames={[
                                        "gift-matching__result-cancel",
                                    ]}
                                    glyph="ban-regular"
                                    type="FontAwesome"
                                />
                                <div className="gift-matching__result-name">
                                    <span className="gift-matching__company-name">
                                        {results.length > 1
                                            ? `None of these are my employer`
                                            : `This is not my employer`}
                                    </span>
                                </div>
                                <Icon
                                    classNames={["gift-matching__result-arrow"]}
                                    glyph="chevron-right-solid"
                                    type="FontAwesome"
                                />
                            </label>
                        </li>
                    </ul>
                </fieldset>
            );
        } else {
            return null;
        }
    };

    // -------------------------------------------------------------------------
    // MARK: Main View Component
    // -------------------------------------------------------------------------
    return (
        <>
            {displayMatchingGift && (
                <div
                    className={cx(
                        "col col--12",
                        noLeftGutter && "-padding-left--0",
                    )}
                >
                    <div className="-callout -padding--15">
                        <div
                            className={cx(
                                "qg-vendor-gift-matching",
                                "gift-matching",
                                view === "SearchView" &&
                                    "gift-matching--search",
                                view === "SelectionView" &&
                                    "gift-matching--selection",
                                view === "ResultsView" &&
                                    "gift-matching--results",
                            )}
                        >
                            {view === "SearchView" && !isVisible && (
                                <div>
                                    <Icon
                                        glyph="gifts-duotone"
                                        type="FontAwesome"
                                        classNames={[" -type--xxlarge"]}
                                    ></Icon>
                                    <span className="-margin-left--10 -type--regular ">
                                        {teaserLabel}
                                    </span>
                                </div>
                            )}
                            {view === "SearchView" && isVisible && (
                                <>
                                    <MatchingHeader />
                                    <div className="qg-vendor-gift-matching__fields gift-matching__fields">
                                        <div className="grid">
                                            <div className="col col--12">
                                                {!isCG && (
                                                    <FormikField
                                                        id="Employer"
                                                        name="Employer"
                                                        field="EMPLOYER"
                                                        descText="Optional"
                                                        helpText={
                                                            matchingEmployerHelpText
                                                        }
                                                        label={
                                                            matchingEmployerLabel
                                                        }
                                                        type="text"
                                                    />
                                                )}
                                                {selection.company_name && (
                                                    <p className="-margin-top--15">
                                                        Please enter your work
                                                        email associated with{" "}
                                                        {selection.company_name}{" "}
                                                        in order to confirm
                                                        eligibility.
                                                    </p>
                                                )}
                                                {(isCG ||
                                                    displayWorkEmailField) && (
                                                    <div className="-margin-top--10">
                                                        <FormikField
                                                            id="Work_Email"
                                                            name="Work_Email"
                                                            descText="Optional"
                                                            helpText={
                                                                matchingWorkEmailHelpText
                                                            }
                                                            field="WORK_EMAIL"
                                                            label={
                                                                matchingWorkEmail
                                                            }
                                                            type="email"
                                                        />
                                                    </div>
                                                )}
                                                <Button
                                                    onMouseDown={handleSearch}
                                                    classes={cx(
                                                        "gift-matching__search-btn",
                                                        searchEnabled
                                                            ? "-cursor--pointer"
                                                            : "-cursor--default btn--disabled",
                                                    )}
                                                    tabIndex="0"
                                                    btnStyle="secondary"
                                                    size="normal"
                                                >
                                                    <Icon
                                                        glyph={
                                                            searching
                                                                ? "spinner"
                                                                : "building-regular"
                                                        }
                                                        type={
                                                            searching
                                                                ? "Icomoon"
                                                                : "FontAwesome"
                                                        }
                                                    />
                                                    {searching
                                                        ? "Searching..."
                                                        : matchingSearchButtonLabel}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {view === "SelectionView" && (
                                <>
                                    <MatchingHeader />
                                    <MatchingSearchResults />
                                    <TextButton
                                        handleClick={() => {
                                            handleCancelButton();
                                        }}
                                        styles="gift-matching__cancel-btn -cursor--pointer"
                                    >
                                        {matchingSearchCancelLink}
                                    </TextButton>
                                </>
                            )}
                            {view === "ResultsView" && (
                                <>
                                    <MatchingHeader />
                                    <TextButton
                                        handleClick={() => {
                                            handleCancelButton();
                                        }}
                                        styles="gift-matching__cancel-btn -cursor--pointer"
                                    >
                                        Clear Selection
                                    </TextButton>
                                    {providerLogoSrc && providerLabel && (
                                        <div className="gift-matching__poweredby">
                                            <span className="matching-poweredby-text">
                                                Powered by
                                            </span>
                                            <img
                                                className="matching-poweredby-logo"
                                                src={providerLogoSrc}
                                                alt={providerLabel}
                                            />
                                        </div>
                                    )}
                                </>
                            )}

                            {view === "ManualMatching" && isHoverable ? (
                                <div>
                                    {donationTotal > 0 || isCms ? (
                                        <>
                                            <FormikCheckbox
                                                id="Match-Gift"
                                                name="Match_Gift"
                                                innerPadding={false}
                                            >
                                                {manualMatchingLabel}
                                                {isSmallScreen ? (
                                                    <span className="-padding-left--10">
                                                        <MoreInfoModal>
                                                            Your gift could be
                                                            worth more with a
                                                            matching gift from
                                                            your employer or
                                                            participating
                                                            company!
                                                        </MoreInfoModal>
                                                    </span>
                                                ) : (
                                                    <Tooltip
                                                        placement="right"
                                                        content="Your gift could be worth more with a matching gift from your employer or participating company!"
                                                        theme="dark"
                                                    >
                                                        <Icon
                                                            glyph="question-circle-regular"
                                                            type="FontAwesome"
                                                            classNames={[
                                                                "-margin-left--5",
                                                            ]}
                                                        ></Icon>
                                                    </Tooltip>
                                                )}
                                            </FormikCheckbox>
                                            <>
                                                {manualMatchingHelpText && (
                                                    <div className="-padding-left--30 -padding-top--5">
                                                        <p className="-type--small">
                                                            {
                                                                manualMatchingHelpText
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                            </>
                                        </>
                                    ) : (
                                        <div>
                                            <Icon
                                                glyph="gifts-duotone"
                                                type="FontAwesome"
                                                classNames={[" -type--xxlarge"]}
                                            ></Icon>
                                            <span className="-margin-left--10 -type--regular ">
                                                {teaserLabel}
                                            </span>
                                        </div>
                                    )}

                                    {isVisible && (
                                        <>
                                            <div className="col col--12">
                                                <div className="grid">
                                                    <div
                                                        className={cx(
                                                            "col col--12 -padding-left--15",
                                                            !isStandardDonationForm &&
                                                                "col--md-6",
                                                        )}
                                                    >
                                                        <FormikField
                                                            id="Employer"
                                                            name="Employer"
                                                            label={
                                                                manualMatchingCompanyLabel
                                                            }
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col--12 ">
                                                <div className="grid">
                                                    <div
                                                        className={cx(
                                                            "col col--12 -padding-left--15",
                                                            !isStandardDonationForm &&
                                                                "col--md-6",
                                                        )}
                                                    >
                                                        <FormikField
                                                            id="Matching-Gift-Amount"
                                                            name="Matching_Gift_Amount"
                                                            label={
                                                                manualMatchingAmountLabel
                                                            }
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                view === "ManualMatching" && (
                                    <>
                                        <CheckboxWithTooltip
                                            {...fieldProps}
                                            label={manualMatchingLabel}
                                            name="Match_Gift"
                                            id="Match-Gift"
                                            tooltipContent="Your gift could be worth more with a matching gift from your employer or participating company!"
                                        />
                                        {manualMatchingHelpText && (
                                            <div className="-padding-left--30 -padding-top--5">
                                                <p className="-type--small">
                                                    {manualMatchingHelpText}
                                                </p>
                                            </div>
                                        )}
                                        {isVisible && (
                                            <>
                                                <div className="col col--12">
                                                    <div className="grid">
                                                        <div className="col col--12 col--md-6 -padding-left--15">
                                                            <FormikField
                                                                id="Employer"
                                                                name="Employer"
                                                                label={
                                                                    manualMatchingCompanyLabel
                                                                }
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col col--12 ">
                                                    <div className="grid">
                                                        <div className="col col--12 col--md-6  -padding-left--15">
                                                            <FormikField
                                                                id="Matching-Gift-Amount"
                                                                name="Matching_Gift_Amount"
                                                                label={
                                                                    manualMatchingAmountLabel
                                                                }
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

MatchingGift.propTypes = {
    currency: PropTypes.object,
    displayMatchingGift: PropTypes.bool,
    donationTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    employerField: PropTypes.string,
    isCG: PropTypes.bool,
    isDtD: PropTypes.bool,
    isHEP: PropTypes.bool,
    isManualMatch: PropTypes.bool,
    isStandardDonationForm: PropTypes.bool,
    isVisible: PropTypes.bool,
    manualMatchingAmountLabel: PropTypes.string,
    manualMatchingCompanyLabel: PropTypes.string,
    manualMatchingHelpText: PropTypes.string,
    manualMatchingLabel: PropTypes.string,
    noLeftGutter: PropTypes.bool,
    sectionHeading: PropTypes.string,
    description: PropTypes.string,
    matchingSearchButtonLabel: PropTypes.string,
    matchingSearchDescription: PropTypes.string,
    matchingSearchCancelLink: PropTypes.string,
    matchingEmployerLabel: PropTypes.string,
    matchingEmployerHelpText: PropTypes.string,
    matchingWorkEmail: PropTypes.string,
    matchingWorkEmailHelpText: PropTypes.string,
    path: PropTypes.string,
    providerLogoSrc: PropTypes.string,
    providerLabel: PropTypes.string,
    searchEnabled: PropTypes.bool,
    selectionStored: PropTypes.bool,
    teaserLabel: PropTypes.string,
    values: PropTypes.object,
    viewType: PropTypes.string,
    setValues: PropTypes.func.isRequired,
    workEmailField: PropTypes.string,
};

export default MatchingGift;
