/* eslint-disable react/prop-types */
import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props - Component properties
 * @param {boolean} props.shouldDisplayEventDates - Flag indicating whether to display the event dates
 * @param {object} props.iconProps - Properties to pass to the Icon component
 * @param {string} props.formattedEventStartDate - The formatted start date of the event
 * @returns {React.ReactElement|null} The WidgetEventsListItemDate component or null if dates should not be displayed.
 */
const WidgetEventsListItemDate = ({
    shouldDisplayEventDates,
    iconProps,
    formattedEventStartDate,
}) =>
    shouldDisplayEventDates ? (
        <p className="widget-events__detail">
            <Icon {...iconProps} glyph="calendar-alt-regular" />
            {formattedEventStartDate}
        </p>
    ) : null;

export default WidgetEventsListItemDate;
