import React from "react";
import PropTypes from "prop-types";
import ConnectedDedication from "../../common/Dedication/ConnectedDedication";

const DedicationControl = ({ controlId }) => (
    <ConnectedDedication controlId={controlId} />
);

DedicationControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default DedicationControl;
