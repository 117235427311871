import React from "react";
import PropTypes from "prop-types";
import ConnectedAmounts from "./Amounts";
import ConnectedUpgradeNudge from "./UpgradeNudge";
import ConnectedDonatedGiftTextToDonate from "./ConnectedDonatedGiftTextToDonate";
import ConnectedMultiRestrictions from "./MultiRestrictions";

const DonatedGiftTabOneTime = ({
    controlId,
    isMultiRestriction,
    isStandardAmounts,
    shouldDisplayTextToDonateInterface,
    showUpgradeNudgeButton,
}) => (
    <>
        {/* Donation amounts section */}
        <div className="col col--12 col--no-top-gutter">
            {shouldDisplayTextToDonateInterface && (
                <ConnectedDonatedGiftTextToDonate controlId={controlId} />
            )}
            {isMultiRestriction && (
                <ConnectedMultiRestrictions controlId={controlId} />
            )}
            {isStandardAmounts && (
                <ConnectedAmounts
                    controlId={controlId}
                    isRecurringDisplay={false}
                />
            )}
        </div>

        {/* Upgrade nudge */}
        {showUpgradeNudgeButton && (
            <ConnectedUpgradeNudge controlId={controlId} />
        )}
    </>
);

DonatedGiftTabOneTime.propTypes = {
    controlId: PropTypes.number.isRequired,
    isMultiRestriction: PropTypes.bool.isRequired,
    isStandardAmounts: PropTypes.bool.isRequired,
    shouldDisplayTextToDonateInterface: PropTypes.bool.isRequired,
    showUpgradeNudgeButton: PropTypes.bool.isRequired,
};

export default DonatedGiftTabOneTime;
