import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { HeaderLogin } from "@qgiv/core-donor";
import { sendEmbedLoginMessage } from "../../PostMessage/postMessage";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";

const ConnectedHeaderLogin = ({ control }) => {
    const {
        options: {
            signInText = "Have an account? %SIGNIN% to speed things up!",
        },
    } = control;
    const formSettings = useSelector(selectAllFormSettings);
    const { id = 0 } = formSettings;

    const handleSignInClick = () => {
        sendEmbedLoginMessage(id);
    };

    const headerLoginProps = {
        id,
        sendLoginMessage: handleSignInClick,
        signInText,
    };

    return <HeaderLogin {...headerLoginProps} />;
};

ConnectedHeaderLogin.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            signInText: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedHeaderLogin;
