import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectCmsContainersByPage } from "../../../redux/slices/cmsSettingsSlice";
import SinglePageFooterContent from "./SinglePageFooterContent";

/**
 * ConnectedSinglePageFooterContent
 * @returns {React.ReactElement} SinglePageFooterContent
 */
const ConnectedSinglePageFooterContent = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { SINGLE_STEP_FOOTER },
        },
    } = constants;

    const containers = useSelector((state) =>
        selectCmsContainersByPage(state, SINGLE_STEP_FOOTER),
    );

    return <SinglePageFooterContent containers={containers} />;
};

export default ConnectedSinglePageFooterContent;
