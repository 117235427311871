import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectAllFieldsAndGroups } from "../../../../redux/slices/fieldsSlice";
import { selectConfig } from "../../../../redux/slices/configSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectAllDonationSettings } from "../../../../redux/slices/donationSettingsSlice";
import { selectConfirmationFieldControls } from "../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentReceiptData } from "../../../../redux/slices/receiptsSlice";
import ConfirmationFields from "./ConfirmationFields";

const ConnectedConfirmationFields = () => {
    const {
        ENUMS: { EntityType, CMS_Control },
    } = constants;
    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);
    const config = useSelector(selectConfig);
    const formSettingsWithoutDonationSettings = useSelector(
        selectAllFormSettings,
    );
    const donationSettings = useSelector(selectAllDonationSettings);
    const {
        allow_company_contributions = 1,
        requireNameForCompanyDonation,
        companyTabLabel,
        individualTabLabel,
    } = donationSettings;
    const allowIndividualContributions =
        parseInt(allow_company_contributions, 10) < 2;
    const allowCompanyContributions =
        parseInt(allow_company_contributions, 10) > 0;
    const {
        errors = {},
        values = {},
        touched = {},
        setFieldValue = () => {},
        handleChange = () => {},
        handleBlur = () => {},
        setValues = () => {},
    } = useFormikContext();
    const privacyFieldGroupValue =
        allFieldsAndGroups.find(
            (field) =>
                Number(field.type) ===
                EntityType.SYSTEM_FIELD_GROUP_PRIVACY_OPTIONS,
        ) || {};
    const privacyFieldGroup = { privacyFieldGroup: privacyFieldGroupValue };

    // need to reference receipt data in order to find valid gift details
    const currentReceiptData = useSelector(selectCurrentReceiptData);
    const { total, feeCoverage = null, transaction = {} } = currentReceiptData;
    const { recurring = null } = transaction;
    const hasSelectedRecurringDonation =
        recurring !== null && Object.keys(recurring).length > 0;
    const subtotal = feeCoverage === null ? total : total - Number(feeCoverage);
    const donationAmountFromRedux = subtotal;

    const conditionalLogicOptions = useMemo(
        () => ({
            donationAmountFromRedux,
            hasSelectedRecurringDonation,
            usesReduxDonationAmountValue: true,
            usesReduxRecurringValue: true,
        }),
        [donationAmountFromRedux, hasSelectedRecurringDonation],
    );

    // Extract the special set of controls that contain all of the eligible
    // system and custom fields that we need to render as part of the
    // confirmation fields form
    const confirmationFieldControls = useSelector(
        selectConfirmationFieldControls,
    );
    // need to place certain settings within formSettings for consumption by SystemFieldGroupName
    // wrapped in useMemo to avoid causing settings to cause endless re-renders
    const settingsToInjectToFormSettings = {
        requireNameForCompanyDonation,
        companyTabLabel,
        individualTabLabel,
    };

    // -------------------------------------------------------------------------
    // NOTE: getOptionalFieldOrGroup modifies the field data so that each
    // field or field group RENDERS as optional. It does not affect the required
    // status of a field/field group for the purposes of validation.
    // -------------------------------------------------------------------------
    const getOptionalFieldOrGroup = (fieldOrGroup = {}) => {
        const isGroup =
            fieldOrGroup.fields && Array.isArray(fieldOrGroup.fields);

        if (isGroup) {
            const newFields = fieldOrGroup.fields.map((field) => {
                const newField = {
                    ...field,
                    required: false,
                };
                return newField;
            });
            const newGroup = {
                ...fieldOrGroup,
                fields: newFields,
            };

            return newGroup;
        }

        const newField = {
            ...fieldOrGroup,
            required: false,
        };

        return newField;
    };
    // Manually set the required status of all fields to "0" so that the
    // Optional text is displayed
    const optionalFieldsAndGroups = allFieldsAndGroups.map(
        getOptionalFieldOrGroup,
    );

    const useNewConditionalLogicFunctions = true;

    // Props for a list of widget field or widget group components
    const widgetFieldOrGroupProps = confirmationFieldControls.map(
        (confirmationFieldControl) => {
            // Consolidate data into control and settings objects
            const control = {
                ...confirmationFieldControl,
            };
            const settings = {
                // NOTE - To be added with invoices
                activePromiseTransaction: false,
                allFieldsAndGroups: optionalFieldsAndGroups,
                allowCompanyContributions,
                allowIndividualContributions,
                conditionalLogicOptions,
                config,
                donationAmountFromRedux,
                formSettings: {
                    ...formSettingsWithoutDonationSettings,
                    ...settingsToInjectToFormSettings,
                },
                requireNameForCompanyDonation,
                useNewConditionalLogicFunctions,
            };

            const isPrivacyFieldSystemField =
                Number(control.type) ===
                CMS_Control.STANDARD_DONATION_PRIVACY_OPTIONS;
            // Props for an individual widget field component
            const widgetFieldProps = {
                control,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                setValues,
                settings,
                touched,
                values,
                // Pass down privacyFieldGroup for Privacy Options
                ...(isPrivacyFieldSystemField && privacyFieldGroup),
            };

            return widgetFieldProps;
        },
    );

    const confirmationFieldsProps = { widgetFieldOrGroupProps };

    return <ConfirmationFields {...confirmationFieldsProps} />;
};

export default ConnectedConfirmationFields;
