/**
 * @public
 * @function getCaptchaInitialValue
 * @param {boolean} captcha captcha value from form settings
 * @returns {object} initial value for captcha in formik
 */
const getCaptchaInitialValue = (captcha) => {
    if (captcha) {
        return {
            G_Recaptcha_Response: "",
        };
    }
    return {};
};

export default getCaptchaInitialValue;
