import { useMemo } from "react";
import { useSelector } from "react-redux";
import useGiftPageData from "./useGiftPageData";
import useDonorDetailsPageData from "./useDonorDetailsPageData";
import useAdditionalDetailsPageData from "./useAdditionalDetailsPageData";
import usePaymentPageData from "./usePaymentPageData";
import {
    selectDedicationsValues,
    selectDonationMessage,
    selectStoredFormikGiftDetails,
} from "../../redux/slices/donationDetailsSlice";
import { selectContentToDisplay } from "../../redux/slices/appSettingsSlice";
import { selectHasActiveFulfillment } from "../../redux/slices/promiseTransactionSlice";

// takes in the result of all the page specific hooks and returns the data for the single page form
const useSinglePageData = () => {
    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    const storedFormikGiftDetails = useSelector(selectStoredFormikGiftDetails);
    const storedDedicationValues = useSelector(selectDedicationsValues);
    const storedDonationMessageValues = useSelector(selectDonationMessage);

    const selectedContentToDisplay = useSelector(selectContentToDisplay);

    // -------------------------------------------------------------------------
    // NOTE: Collect all the data from the page specific hooks and rename them
    // to avoid confusion.
    // -------------------------------------------------------------------------

    // Gift Page
    const giftPageData = useGiftPageData();

    const giftPageInitialValuesSettings =
        giftPageData?.formikSettings?.initialValuesSettings;

    const giftPageInitialTouchedSettings =
        giftPageData?.formikSettings?.initialTouchedSettings;

    const giftPageValidationSettings =
        giftPageData?.formikSettings?.validationSettings;

    // Donor Details Page
    const donorDetailPageData = useDonorDetailsPageData();

    const donorDetailPageInitialValuesSettings =
        donorDetailPageData?.formikSettings?.initialValuesSettings;
    const donorDetailPageInitialTouchedSettings =
        donorDetailPageData?.formikSettings?.initialTouchedSettings;
    const donorDetailPageValidationSettings =
        donorDetailPageData?.formikSettings?.validationSettings;

    // Additional Details Page
    const additionalDetailPageData = useAdditionalDetailsPageData();

    const additionalDetailPageInitialValuesSettings =
        additionalDetailPageData?.formikSettings?.initialValuesSettings;
    const additionalDetailPageInitialTouchedSettings =
        additionalDetailPageData?.formikSettings?.initialTouchedSettings;
    const additionalDetailPageValidationSettings =
        additionalDetailPageData?.formikSettings?.validationSettings;

    // Payment Page
    const paymentPageData = usePaymentPageData();

    const paymentPageInitialValuesSettings =
        paymentPageData?.formikSettings?.initialValuesSettings;
    const paymentPageInitialTouchedSettings =
        paymentPageData?.formikSettings?.initialTouchedSettings;
    const paymentPageValidationSettings =
        paymentPageData?.formikSettings?.validationSettings;

    const {
        handleUpdateSubmitDonation,
        shouldDisplayRecurringUpgrade,
        submitDonation,
    } = paymentPageData;

    const {
        conditionalLogicOptions,
        hasDisplayableDedicationOnAdditionalDetailsPage,
        hasDisplayableMatchingGiftOnAdditionalDetailsPage,
    } = donorDetailPageData;

    const singlePageData = useMemo(() => {
        const storedInitialValuesFromRedux = {
            ...storedFormikGiftDetails,
            ...storedDedicationValues,
            ...storedDonationMessageValues,
        };

        // -------------------------------------------------------------------------
        // NOTE: Divide the Formik data into the different pages
        // -------------------------------------------------------------------------
        const initialTouchedSettings = {
            giftPageInitialTouchedSettings,
            donorDetailPageInitialTouchedSettings,
            additionalDetailPageInitialTouchedSettings,
            paymentPageInitialTouchedSettings,
        };
        const initialValuesSettings = {
            giftPageInitialValuesSettings,
            donorDetailPageInitialValuesSettings,
            additionalDetailPageInitialValuesSettings,
            paymentPageInitialValuesSettings,
        };
        const validationSettings = {
            giftPageValidationSettings,
            donorDetailPageValidationSettings,
            additionalDetailPageValidationSettings,
            paymentPageValidationSettings,
        };

        const formikSettings = {
            initialTouchedSettings,
            initialValuesSettings,
            validationSettings,
        };

        return {
            conditionalLogicOptions,
            displayCaptcha: false,
            documentTitle: "",
            formikSettings,
            handleUpdateSubmitDonation,
            hasActiveFulfillment,
            hasDisplayableDedicationOnAdditionalDetailsPage,
            hasDisplayableMatchingGiftOnAdditionalDetailsPage,
            selectedContentToDisplay,
            shouldDisplayRecurringUpgrade,
            storedInitialValuesFromRedux,
            submitDonation,
        };
    }, [
        additionalDetailPageInitialTouchedSettings,
        additionalDetailPageInitialValuesSettings,
        additionalDetailPageValidationSettings,
        conditionalLogicOptions,
        donorDetailPageInitialTouchedSettings,
        donorDetailPageInitialValuesSettings,
        donorDetailPageValidationSettings,
        giftPageInitialTouchedSettings,
        giftPageInitialValuesSettings,
        giftPageValidationSettings,
        handleUpdateSubmitDonation,
        hasActiveFulfillment,
        hasDisplayableDedicationOnAdditionalDetailsPage,
        hasDisplayableMatchingGiftOnAdditionalDetailsPage,
        paymentPageInitialTouchedSettings,
        paymentPageInitialValuesSettings,
        paymentPageValidationSettings,
        selectedContentToDisplay,
        shouldDisplayRecurringUpgrade,
        storedDedicationValues,
        storedDonationMessageValues,
        storedFormikGiftDetails,
        submitDonation,
    ]);

    return singlePageData;
};

export default useSinglePageData;
