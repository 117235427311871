import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { UseADifferentPaymentMethodButton } from "@qgiv/core-donor";
import { incrementPaymentMethodResetCount } from "../../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";

const ConnectedUseADifferentPaymentMethodButton = () => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const dispatch = useDispatch();
    const { setValues, values, validateForm } = useFormikContext();
    const { paymentData = {} } = useSelector(selectAllFormSettings);
    const {
        enableAchPayments = false,
        enableCCPayments = false,
        enablePaypalPayments = false,
    } = paymentData;
    const { Payment_Type } = values;
    let text = "Use a different payment method";

    // In the case of PayPal this button can also be used to reload the PayPal
    // button if an error was thrown during the authorization process. Adding
    // this also helps the button make more sense in the case where PayPal is
    // the only available payment method.
    if (Payment_Type === PaymentType.PAYPAL) {
        text += " or try again";
    }

    // Arrange data into a formSettings object as that's what the
    // implementation of the UseADifferentPaymentMethodButton button in the
    // redesigned form and P2P expect
    const formSettings = {
        enableAchPayments,
        enableCCPayments,
        enablePaypalPayments,
    };

    // Hide the use a different payment method button, remove any error
    // messages are reload all of the payment data
    const handleUseADifferentPaymentMethodClick = () => {
        dispatch(incrementPaymentMethodResetCount());
    };

    const useADifferentPaymentMethodButtonProps = {
        formSettings,
        handleUseADifferentPaymentMethodClick,
        setValues,
        text,
        values,
        validateForm,
    };

    return (
        <UseADifferentPaymentMethodButton
            {...useADifferentPaymentMethodButtonProps}
        />
    );
};

export default ConnectedUseADifferentPaymentMethodButton;
