import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon, ScrollTo } from "@qgiv/core-react";

import "./Message.scss";

const Message = ({
    message: {
        absolutePosition = true,
        closeButton = false,
        message,
        scrollToMessage = true,
        timeout = 0,
        type = "error",
    },
    setMessage,
    submitCount = 0,
    focusOnMessageContent = false,
}) => {
    const [isActive, setIsActive] = useState(false);
    const _message = typeof message === "string" ? [message] : message;
    const ref = React.createRef();
    const messageContentRef = React.createRef();
    const iconGlyphs = {
        error: {
            glyph: "frown-open-solid",
            type: "FontAwesome",
        },
        primary: {
            glyph: "info",
            type: "Icomoon",
        },
        success: {
            glyph: "check-circle-solid",
            type: "FontAwesome",
        },
    };
    const messageClasses = cx(
        "donor-message",
        `donor-message--${type}`,
        isActive && "donor-message--active",
        ((timeout && absolutePosition) || (closeButton && absolutePosition)) &&
            "donor-message--position-absolute",
    );
    const clearMessage = () => {
        setIsActive(false);
        setTimeout(() => setMessage({}), 450);
    };

    useEffect(() => {
        setIsActive(true);
        if (timeout !== 0) {
            setTimeout(() => clearMessage(), timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If the message is being used to display a message that appears after a
    // user attempts to submit a form, redirect the focus to the message
    // content so that the screen reader can read the messages
    useEffect(() => {
        if (focusOnMessageContent) {
            messageContentRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);

    return (
        <div className={messageClasses} ref={ref}>
            {scrollToMessage && <ScrollTo ref={ref} />}
            <Icon
                glyph={iconGlyphs[type].glyph}
                type={iconGlyphs[type].type}
                classNames={["donor-message__icon"]}
            />
            <div
                className="donor-message__content"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex="0"
                ref={messageContentRef}
            >
                {_message.map((msg, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i}>{msg}</div>
                ))}
            </div>
            {closeButton && (
                // added before eslint-plugin-jsx-a11y@6.8.0
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button
                    className="donor-message__close -cursor--pointer"
                    type="button"
                    onClick={() => clearMessage()}
                >
                    <Icon glyph="cross" type="Icomoon" />
                </button>
            )}
            {timeout !== 0 && (
                <div
                    className="donor-message__timeout-timer"
                    style={{
                        transition: `width ${timeout}ms linear`,
                    }}
                />
            )}
        </div>
    );
};

Message.propTypes = {
    message: PropTypes.shape({
        absolutePosition: PropTypes.bool,
        closeButton: PropTypes.bool,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
            .isRequired,
        scrollToMessage: PropTypes.bool,
        timeout: PropTypes.number,
        type: PropTypes.string,
    }).isRequired,
    // eslint-disable-next-line react/require-default-props
    setMessage: PropTypes.func,
    // eslint-disable-next-line react/require-default-props
    submitCount: PropTypes.number,
    // eslint-disable-next-line react/require-default-props
    focusOnMessageContent: PropTypes.bool,
};

export default Message;
