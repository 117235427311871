import { log } from "@qgiv/core-js";

/**
 *  @public
 *  @function replaceAnimation
 */
export const replaceAnimation = () => {
    try {
        // Access the loading animation markup which is located in the immediate
        // parent of the window associated with the iframe where the form is embedded
        // The immediate parent should be used instead of the top most browser window
        // as in CMS the newDonationFormContainer element is nested in an iframe that
        // is nested inside of the top most window
        const parentWindow = window.parent;
        const parentDocument = parentWindow.document;
        const newDonationFormContainer = parentDocument.querySelector(
            ".donation-form-container--new-standard-form",
        );

        // If the landing page for the new donation form is loaded
        if (newDonationFormContainer) {
            const loadingFormAnimation = newDonationFormContainer.querySelector(
                ".donation-form-loading",
            );
            const embeddedFormContainer =
                newDonationFormContainer.querySelector(".qgiv-embed-container");

            // If the loading animation that is displayed on the landing page is present
            // Remove the loading animation and display the embedded form instead
            if (loadingFormAnimation) {
                loadingFormAnimation.style.display = "none";

                embeddedFormContainer.style.opacity = 0;
                embeddedFormContainer.style.display = "block";
                window.setTimeout(() => {
                    embeddedFormContainer.style.transition = "opacity 0.4s";
                    embeddedFormContainer.style.opacity = 1;
                }, 400);
            }
        }
    } catch (error) {
        // Expected to catch a cross-origin error that indicates that there is no loading
        // animation to replace when an embedded form is deployed to a non-Qgiv domain
        log("Dev Log: Loading Animation Error:", error);
    }
};
