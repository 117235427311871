// -------------------------------------------------------------------------
// NOTE: Inject tracking IDs into template literals that are associated with
// the different tags. Even though it is not mentioned in the React Helmet
// documentation, a string can be rendered and run as an inline script
// when it is made the child of a <script/> element when passed in via
// React Helmet
// -------------------------------------------------------------------------

// Because the structure of the scripts used by Universal Analytics
// (UA) and Google Analytics (GA4) are the same we actually use the same
// code snippet for both of these scripts. However, because these scripts
// can be run concurrently (something that is important during the upgrade
// process from UA to GA4) 2 separate script instances are created here so
// that 2 separate scripts can be injected into the DOM.
/**
 *
 * @param {object} googleAnalyticsUA Universal Analytics injection data.
 * @returns {string} Universal Analytics script to render.
 */
export const getGoogleAnalyticsUAScriptContents = (googleAnalyticsUA = {}) => {
    const googleAnalyticsUAId = googleAnalyticsUA && googleAnalyticsUA.content;
    const googleAnalyticsUAScriptContents = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${googleAnalyticsUAId}');
    `;

    return googleAnalyticsUAScriptContents;
};

/**
 *
 * @param {object} googleAnalyticsGA4 Google Analytics 4 injection data.
 * @returns {string} Google Analytics 4 script to render.
 */
export const getGoogleAnalyticsGA4ScriptContents = (
    googleAnalyticsGA4 = {},
) => {
    const googleAnalyticsGA4Id = googleAnalyticsGA4.content;
    const googleAnalyticsGA4ScriptContents = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${googleAnalyticsGA4Id}');
    `;

    return googleAnalyticsGA4ScriptContents;
};

/**
 *
 * @param {object} googleTagManager Google Tag Manager injection data.
 * @returns {string} Google Tag Manager script to render.
 */
export const getGoogleTagManagerScriptContents = (googleTagManager = {}) => {
    const googleTagManagerId = googleTagManager.content;
    const googleTagManagerScriptContents = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');
    `;

    return googleTagManagerScriptContents;
};

/**
 *
 * @param {object} facebookPixel Facebook Pixel injection data.
 * @returns {string} Facebook Pixel script to render.
 */
export const getFacebookPixelScriptContents = (facebookPixel = {}) => {
    const facebookPixelId = facebookPixel.content;
    const facebookPixelEventId = facebookPixel.eventId;
    const isSetUpForConversionAPI = !!facebookPixelEventId;

    // Basic Facebook Pixel Script
    const basicFacebookPixelScriptContents = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${facebookPixelId});
        fbq('track', 'PageView')
    `;

    // Facebook Pixel with the Conversions API
    const facebookPixelWithConversionAPIScriptContents = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${facebookPixelId});
        fbq('track', 'PageView', {}, {eventID: '${facebookPixelEventId}'});
    `;

    const facebookPixelScriptContents = isSetUpForConversionAPI
        ? facebookPixelWithConversionAPIScriptContents
        : basicFacebookPixelScriptContents;

    return facebookPixelScriptContents;
};
