import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { constants, currencyString } from "@qgiv/core-js";
import { TotalGift } from "@qgiv/donation-form";
import { selectCmsControlByIdByPage } from "../../../redux/slices/cmsSettingsSlice";
import {
    selectGiftDetails,
    selectSelectedRecipient,
} from "../../../redux/slices/donationDetailsSlice";
import { selectSpecificFormSetting } from "../../../redux/slices/formSettingsSlice";
import { setCurrentPage } from "../../../redux/slices/appSettingsSlice";
import { selectRecurringFrequencyByValue } from "../../../redux/slices/donationSettingsSlice";

const ConnectedTotalGift = ({ controlId }) => {
    const dispatch = useDispatch();

    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS, PAYMENT_DETAILS },
            EntityType: { FORM },
        },
    } = constants;

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );

    const {
        options: { totalGiftLabel, editGiftLabel },
    } = control;

    const selectedRecipient = useSelector(selectSelectedRecipient);
    const displayRecipientData =
        selectedRecipient !== undefined &&
        Object.keys(selectedRecipient).length > 0 &&
        selectedRecipient?.entityType !== FORM;

    const currency = useSelector((state) =>
        selectSpecificFormSetting(state, "currency"),
    );

    const { total, hasSelectedRecurringDonation, Recurring_Frequency } =
        useSelector(selectGiftDetails);

    const formattedTotal = currencyString(total, currency);
    const selectedFrequency = useSelector((state) =>
        selectRecurringFrequencyByValue(state, Recurring_Frequency),
    );
    const formattedFrequency = selectedFrequency?.labelPerAmount;

    const handleChangeClick = () => {
        dispatch(setCurrentPage(DONATION_AMOUNTS));
    };

    const displayRecurringFrequency =
        hasSelectedRecurringDonation &&
        formattedFrequency &&
        formattedFrequency.length > 0;

    return (
        <TotalGift
            totalGiftLabel={totalGiftLabel}
            editGiftLabel={editGiftLabel}
            formattedTotal={formattedTotal}
            handleChangeClick={handleChangeClick}
            displayRecurringFrequency={displayRecurringFrequency}
            formattedFrequency={formattedFrequency}
            displayRecipientData={displayRecipientData}
            selectedRecipient={selectedRecipient}
        />
    );
};

ConnectedTotalGift.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedTotalGift;
