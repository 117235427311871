import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const Icon = ({
    ariaHidden,
    glyph,
    type,
    classNames = [],
    label = glyph,
    ...props
}) => {
    const iconClasses = [
        "icon",
        ...classNames,
        // vertical-align: text-bottom has historically been the default style
        // for the `.icon` class. This allows us to overwrite this style using a
        // modifier class. If no "-vertical-align--*" modifier class is passed
        // "-vertical-align--text-bottom" will be applied.
        !classNames.find((iconClass) => iconClass.match("-vertical-align--"))
            ?.length
            ? "-vertical-align--text-bottom"
            : "",
    ].join(" ");

    // -------------------------------------------------------------------------
    // NOTE:
    // For accessibility, we need to add the 'aria-labelledby' property to the svg
    // which will point to title element within the svg. This will connect the
    // content of the title element to svg element for screen readers. Since there
    // are several can be icons of the same type (progress bar circles, tooltip question marks)
    // in the DOM at once, we need to generate a unique ID to pass to both the
    // 'aria-labelledby' svg property and the svg's title element.
    // -------------------------------------------------------------------------
    const generateUniqueTitleId = useCallback(() => {
        const ranNum = Math.floor(Math.random() * 100000);
        return `title-${ranNum}`;
    }, []);

    const titleId = generateUniqueTitleId();
    const useAriaHidden = ariaHidden || label === glyph;

    // When using the aria-hidden="true" attribute for an HTML element,
    // we need to not assign the aria-labelledby attribute since it will
    // confuse screen readers (JAWS), causing it to read out the svg.
    const svgProps = useMemo(
        () => ({
            role: "img",
            ...(useAriaHidden
                ? { "aria-hidden": "true" }
                : { "aria-labelledby": titleId }),
        }),
        [useAriaHidden, titleId],
    );

    return (
        <span className={iconClasses}>
            <svg {...svgProps}>
                <title id={titleId}>{label}</title>
                <use href={`#${type}__${glyph}`} {...props} />
            </svg>
        </span>
    );
};

Icon.propTypes = {
    ariaHidden: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    glyph: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    classNames: PropTypes.arrayOf(PropTypes.string),
};

export default Icon;
