import { createSlice } from "@reduxjs/toolkit";
import { constants } from "@qgiv/core-js";
import { config as initialState } from "../initialState";
import {
    selectDirectLinkLoginUrlSelector,
    selectDonationFormUrlSelector,
    selectWidgetSharingBaseUrlSelector,
    selectWidgetThermometerUrlSelector,
} from "../selectors/configSelectors/configSelectors";

const getConfigInitialState = () => ({
    ...initialState,
    isCms: !!constants.cms,
    ...(!!constants.cms && { currentDisplay: 0 }),
});

export const configInitialState = getConfigInitialState();

const slice = createSlice({
    name: "config",
    initialState: configInitialState,
    reducers: {},
});

// selectors
export const selectConfig = (state) => state.config;

export const selectCurrentDisplay = (state) => state.config.currentDisplay;

export const selectIsCms = (state) => state.config.isCms;

export const selectEmbed = (state) => state.config.embed;

export const selectSubmissionType = (state) => state.config.submissionType;

export const selectDonationFormUrl = (state) =>
    selectDonationFormUrlSelector(state.config, state.formSettings.template);

export const selectDirectLinkLoginUrl = (state) =>
    selectDirectLinkLoginUrlSelector(state.config, state.formSettings.template);

export const selectWidgetSharingBaseUrl = (state) =>
    selectWidgetSharingBaseUrlSelector(
        state.config,
        state.eventSettings,
        state.formSettings.template,
        state.formSettings.type === constants.ENUMS.ProductType.AUCTION,
    );

export const selectWidgetThermometerUrl = (state, control) =>
    selectWidgetThermometerUrlSelector(
        state.config,
        control,
        state.eventSettings,
        state.formSettings,
        state.formSettings.template,
    );

export const selectIsDisplayTypeModal = (state) =>
    state.config.isDisplayTypeModal;

export default slice.reducer;
