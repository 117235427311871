import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import ProcessingDots from "../ProcessingDots";

// -------------------------------------------------------------------------
// NOTE:
// I was not aware of the usePayPalScriptReducer() hook that can be used to
// reload the JS SDK script when parameters change when I originally wrote the
// logic to manually reload the script when the user changes the type of
// donation from a one time donation to a recurring donation so this is
// something that we may want to consider changing at some point in the future.
// https://paypal.github.io/react-paypal-js/?path=/story/getting-started--page
// -------------------------------------------------------------------------
// Pass all of the props that were assembled in the PayPalSDKProvider component
// down into the PayPalButtons component while also giving us an opportunity
// to use functionality from the react-paypal package such as the
// usePayPalScriptReducer() hook which can only be used  from within the
// PayPalScriptProvider component
const PayPalSDKButtons = (props) => {
    const [displayLoadingAnimation, setDisplayLoadingAnimation] =
        useState(true);
    const [{ isResolved }] = usePayPalScriptReducer();

    // Track the loading state of the JS SDK and once the script has loaded
    // wait a second before removing the loading animation from the page and
    // replacing it with the PayPal buttons. This interval was added because
    // even once the SDK loads the button is not always immediately rendered
    // to the page and when it is not rendered to the page the transition from
    // animation to button can be quite jarring. Because of that this timer
    // was added in order to ensure that the transition from button to
    // animation is consistently smooth as this was thought to be the best user
    // experience.
    useEffect(() => {
        setTimeout(() => {
            setDisplayLoadingAnimation(false);
        }, 1500);
    }, [isResolved]);

    // Match the height of the container that surrounds the processing
    // animation to the height of a typical PayPal button
    if (displayLoadingAnimation) {
        return (
            <ProcessingDots
                containerStyle={{ height: "45px" }}
                spinnerStyle="dark"
            />
        );
    }

    return <PayPalButtons {...props} />;
};

// We are intentionally not creating default args with empty functions for
// createBillingAgreement() and createOrder() because if both of these
// functions are passed into PayPalButtons at the same time the PayPalButtons
// component will throw an error
PayPalSDKButtons.propTypes = {
    // eslint-disable-next-line react/require-default-props
    createBillingAgreement: PropTypes.func,
    // eslint-disable-next-line react/require-default-props
    createOrder: PropTypes.func,
    className: PropTypes.string,
    forceReRender: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
    ).isRequired,
    onApprove: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    style: PropTypes.shape({
        layout: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

export default PayPalSDKButtons;
