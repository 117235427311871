import { getDonorDetailsValues } from "@qgiv/core-donor";

/**
 * @public
 * @function getMyAccountLinkUrl
 * @param {object} settings All passed settings
 * @param {object} settings.donorAccount donor account data from redux
 * @param {number} settings.orgId organization id
 * @param {number} settings.formId form id
 * @param {object} settings.embed embed data from Redux
 * @returns {string} Returns url for reaching My Account
 */
export const getMyAccountLinkUrl = ({
    donorAccount,
    orgId,
    formId,
    embed = {},
    // eventId,
}) => {
    let myAccountLinkUrl = `/account/${donorAccount.userId}/embed/?org=${orgId}&formId=${formId}`;

    // If the "my account" button (login link) is part of an embedded form,
    // include the embed.id in the URL of the link so that POST requests
    // triggering form resizes get directed to the URL of the parent window
    // where the form is embedded
    if (embed && embed?.id) {
        myAccountLinkUrl += `&embedId=${embed.id}`;
    }

    return myAccountLinkUrl;
};

/**
 * @public
 * @function getFormValuesFromDonorAccount
 * @description Given a donor account object, determines what donor details
 *              values need to be populated/reset. Returns a new object
 *              that you can spread into a call of `setValues`
 * @param {object} props all passed props
 * @param {object} props.donorAccount response with donor account from login api call
 * @param {object} props.systemFields all system fields
 * @param {string} props.countryInitial Field setting for country default
 * @param {boolean} props.isBillingNameDisplayed field selector for if field is displayed
 * @returns {object} _values
 */
export const getFormValuesFromDonorAccount = ({
    donorAccount,
    systemFields,
    countryInitial,
    isBillingNameDisplayed,
}) => {
    const formikValues = {
        ...getDonorDetailsValues(
            donorAccount,
            systemFields,
            countryInitial,
            isBillingNameDisplayed,
        ),
    };
    return formikValues;
};

/**
 * @public
 * @function onDonorAccountChangeSetFormik
 * @description Take in the new donor account values and set them as Formik values
 * @param {object} formValuesFromDonorAccount values for donor account that need to be updated
 * @param {Function} setValues Formik function to set values
 * @param {boolean} values current formik values to be overwritten
 * @param {number} paymentTypeAfterSignOut payment type enum value
 */
export const onDonorAccountChangeSetFormik = (
    formValuesFromDonorAccount,
    setValues,
    values,
    paymentTypeAfterSignOut,
) => {
    const newValues = {
        ...values,
        // Reset and donor detail fields based on donor account
        ...formValuesFromDonorAccount,
        ...(values.Payment_Type && { Payment_Type: paymentTypeAfterSignOut }),
        ...(values.Stored_Payment && { Stored_Payment: false }),
    };
    setValues(newValues);
};
