/* eslint-disable react/prop-types */
import React from "react";
import { constants } from "@qgiv/core-js";
import { Icon } from "@qgiv/core-react";
import "./WidgetEvents.scss";

/**
 * @param {object} props - Component properties
 * @param {string} props.status - The status of the form
 * @param {object} props.eventListOptions - Options for displaying the event list, including custom messages
 * @returns {React.ReactElement} The WidgetEventListUnavailable component.
 */
const WidgetEventListUnavailable = ({ status, eventListOptions }) => {
    const { noEventsText = "No upcoming events scheduled.\nCheck back soon." } =
        eventListOptions;

    const {
        ENUMS: { Status },
    } = constants;
    const isFormOpen =
        Number(status) !== Status.CLOSEANDBILL &&
        Number(status) !== Status.CLOSED &&
        Number(status) !== Status.DELETED;

    const parseStringNewLines = (string) => {
        const stringArray = string.split("\n");
        return stringArray.map((str, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
                {str}
                {index !== stringArray.length - 1 && <br />}
            </React.Fragment>
        ));
    };

    const hasNoEventsMessage = (
        <div className="widget-events__no-events-message">
            <Icon
                type="FontAwesome"
                glyph="sparkles-regular"
                classNames={[
                    "widget-events__no-events-icon -margin-bottom--10",
                ]}
            />
            <br />
            <span className="-type--large">
                {parseStringNewLines(noEventsText)}
            </span>
        </div>
    );

    const isFormClosedMessage = (
        // eslint-disable-next-line tailwindcss/classnames-order
        <div className="grid grid--justify-content-center">
            <div className="col col--10 col--sm-7 col--no-gutter">
                <div className="form-locked">
                    <Icon
                        type="FontAwesome"
                        glyph="box-heart-solid"
                        classNames={["form-locked__icon"]}
                    />

                    <div className="-type--xlarge">
                        This form has been closed
                        <br />
                        Check back later
                    </div>
                </div>
            </div>
        </div>
    );

    return isFormOpen ? hasNoEventsMessage : isFormClosedMessage;
};

export default WidgetEventListUnavailable;
