import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { WidgetThermometer } from "@qgiv/core-donor";
import ResizeObserver from "resize-observer-polyfill";
import { selectCmsControlById } from "../../redux/slices/cmsSettingsSlice";
import { selectWidgetThermometerUrl } from "../../redux/slices/configSettingsSlice";
import { debounceSendResizeMessage } from "../PostMessage/postMessage";

const ConnectedWidgetThermometerContentControl = ({ controlId }) => {
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );
    const {
        options: { visible = true },
    } = control;

    const widgetUrl = useSelector((state) =>
        selectWidgetThermometerUrl(state, control),
    );

    const isThermometerWidgetDisplayable = visible && !!widgetUrl;

    return (
        <WidgetThermometer
            debounceSendResizeMessage={debounceSendResizeMessage}
            isThermometerWidgetDisplayable={isThermometerWidgetDisplayable}
            ResizeObserver={ResizeObserver}
            widgetUrl={widgetUrl}
        />
    );
};

ConnectedWidgetThermometerContentControl.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default ConnectedWidgetThermometerContentControl;
