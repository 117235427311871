import React from "react";
import PropTypes from "prop-types";

import "./MailingAddressPreview.scss";

const MailingAddressPreview = (props) => {
    const addressValues = {
        Address: props.Address,
        Address_2: props.Address_2,
        City: props.City,
        State: props.State,
        Zip: props.Zip,
        Country: props.Country,
    };

    // Determine length of all address fields
    // We exclude `Country` since it is pre-selected and not user input
    const addressLength = Object.keys(addressValues)
        .filter((key) => key !== "Country")
        .reduce((length, key) => {
            return (length += addressValues[key].length);
        }, 0);

    // Here we finally destructure out values that have a length greater than 0
    // If they don't pass the length test, they will simply be `undefined`
    // and the jsx we write later will treat these as falsy values.
    const { Address, Address_2, City, State, Zip, Country } = Object.keys(
        addressValues,
    ).reduce((_values, key) => {
        const value = addressValues[key];
        if (value && value.length > 0) {
            return {
                ..._values,
                [key]: value,
            };
        } else {
            return _values;
        }
    }, {});

    // Helper func to generate address text
    const getAddressText = () => {
        return (
            <>
                {Address && (
                    <>
                        {Address}
                        <br />
                    </>
                )}
                {Address_2 && (
                    <>
                        {Address_2}
                        <br />
                    </>
                )}
                {City && `${City}, `}
                {State && `${State} `}
                {Zip && Zip}
                {(City || State || Zip) && <br />}
                {Country && Country}
            </>
        );
    };

    return (
        addressLength > 0 && (
            <div className="qg-vendor-mailing-address mailing-address">
                <div className="grid">
                    <div className="col col--12 col--no-top-gutter">
                        <div className="mailing-address__address -callout">
                            <p>{getAddressText()}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

MailingAddressPreview.propTypes = {
    Address: PropTypes.string.isRequired,
    Address_2: PropTypes.string.isRequired,
    City: PropTypes.string.isRequired,
    State: PropTypes.string.isRequired,
    Zip: PropTypes.string.isRequired,
    Country: PropTypes.string.isRequired,
};

export default MailingAddressPreview;
