import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import FieldContainer from "../Fields/Field/FieldContainer";
import CardImage from "./CardImage";
import "./Card.scss";

const CardInner = ({
    checked,
    children,
    title,
    renderTitle = (title) => <span className="-type--large">{title}</span>,
    image,
    icon,
    extraTitle,
    renderExtraTitle = (extraTitle) => (
        <div className="card__extra-title">
            <div>
                <span className="-type--large">{extraTitle}</span>
            </div>
        </div>
    ),
}) => {
    const showTitle = `${title}`.length > 0;
    const showImage = image && image.url.length > 0;
    const iconClassNames = icon && icon.classNames ? icon.classNames : [];
    return (
        <div className="card__inner">
            {icon && (
                <div className="card__inner__col">
                    <Icon
                        {...icon}
                        classNames={[...iconClassNames, "card__icon"]}
                    />
                </div>
            )}
            <div className="card__inner__col">
                {showImage > 0 && (
                    <figure className="card__figure">
                        <CardImage {...image} />
                    </figure>
                )}
                <div
                    className={cx(
                        "card__label",
                        checked && "card__label--selected",
                    )}
                >
                    <div className="card__desc-text-container">
                        <div className="card__desc-text">
                            <div className="grid">
                                {showTitle && (
                                    <div className="col col--12 col--no-top-gutter">
                                        {renderTitle(title)}
                                    </div>
                                )}
                                <div className="col col--12 col--no-top-gutter">
                                    <div className="card__content">
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {extraTitle && (
                            <div className="card__extra-title-container -text--right">
                                {renderExtraTitle(extraTitle)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

CardInner.propTypes = {
    checked: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * @function renderTitle
     * @param {string} title The passed in title prop <CardInner/>
     * @description Function that allows you to overwrite the content rendered
     *              for the <CardInner/>'s title
     * @returns {React.component}
     */
    renderTitle: PropTypes.func,
    extraTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * @function renderExtraTitle
     * @param {string} extraTitle The passed in title prop <CardInner/>
     * @description Function that allows you to overwrite the content rendered
     *              around <CardInner/>'s extra title
     * @returns {React.component}
     */
    renderExtraTitle: PropTypes.func,
    icon: PropTypes.object,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        altText: PropTypes.string.isRequired,
        display: PropTypes.string,
    }),
    children: PropTypes.any,
};
// -------------------------------------------------------------------------
// NOTE: the prop classNames for the CardInput is by default ['-visually-hidden']
//       which when the input is disabled, it will apply disabled stylings to the card
//       If you want to have a disabled input, but not have the styling for disabled,
//       you can add the class '-same--disabled' to the classNames array
// -------------------------------------------------------------------------
const CardInput = memo(
    forwardRef((props, ref) => {
        const {
            id,
            value,
            checked = false,
            disabled,
            classNames = ["-visually-hidden"],
            name,
            handleBlur = () => {},
            handleChange,
            handleClick = () => {},
            handleFocus = () => {},
            type,
        } = props;
        return (
            <FieldContainer
                selected={checked}
                selectedOnHover={disabled ? false : true}
                htmlForName={id}
            >
                <label className="card-container">
                    <input
                        id={id}
                        className={classNames.join(" ")}
                        type={type}
                        name={name}
                        value={value}
                        checked={checked}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onClick={handleClick}
                        onFocus={handleFocus}
                        disabled={disabled}
                        ref={ref}
                    />
                    <div className="card -cursor--pointer">
                        <CardInner {...props} />
                    </div>
                </label>
            </FieldContainer>
        );
    }),
);

CardInput.displayName = "CardInput";

CardInput.propTypes = {
    checked: PropTypes.bool,
    classNames: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    extraTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    icon: PropTypes.object,
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        altText: PropTypes.string.isRequired,
        display: PropTypes.string,
    }),
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleClick: PropTypes.func,
    handleFocus: PropTypes.func,
    children: PropTypes.any,
    type: PropTypes.oneOf(["radio", "checkbox"]).isRequired,
};

export default CardInput;
