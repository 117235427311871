import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { DonorAccount } from "@qgiv/core-donor";
import { getMyAccountLinkUrl } from "@qgiv/donation-form";
import {
    selectConfig,
    selectIsCms,
} from "../../../../redux/slices/configSettingsSlice";
import {
    setErrors,
    setMessages,
} from "../../../../redux/slices/appSettingsSlice";
import { selectCmsControlById } from "../../../../redux/slices/cmsSettingsSlice";
import { selectReceiptTransaction } from "../../../../redux/slices/receiptsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import {
    setDonorAccount,
    selectDonorAccount,
} from "../../../../redux/slices/donorAccountSlice";

const ConnectedDonorAccount = ({ controlId }) => {
    const isCms = useSelector(selectIsCms);
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );
    const formSettings = useSelector((state) => selectAllFormSettings(state));

    const transaction = useSelector((state) => selectReceiptTransaction(state));
    const { recurring } = transaction;
    const donorAccount = useSelector(selectDonorAccount);
    const config = useSelector(selectConfig);
    const { embed } = config;

    const dispatch = useDispatch();
    const {
        options: {
            additionalHeadingOneTimeGift = "",
            additionalHeadingRecurringGift = "",
            additionalTextMyAccount = "You're signed in as %FIRSTNAME% %LASTNAME%",
            headingMyAccount = "Go to %MYACCOUNT%",
            headingOneTimeGift = "Add a password to save your info for next time:",
            headingRecurringGift = "Add a password to easily edit your gift online:",
            saveButton = "Save",
        },
    } = control;
    const { id = 0, org = 0 } = formSettings;
    const { userId = "", contact = {} } = donorAccount;
    const { firstName = "", lastName = "" } = contact;

    const isRecurring = !!recurring;

    const heading = isRecurring ? headingRecurringGift : headingOneTimeGift;

    const additionalText = isRecurring
        ? additionalHeadingOneTimeGift
        : additionalHeadingRecurringGift;
    const displayAdditionalText = !!additionalText;
    // Both the donor account form and the my account UI should be displayed in CMS
    const displayDonorAccountForm = isCms || userId <= 0;
    const displayMyAccount = isCms || userId > 0;

    // Wrap the donor's first and last name with <strong/> tags if the
    // %FIRSTNAME% and %LASTNAME% tags are included in CMS
    const getAdditionalTextMyAccountInnerHtml = () => {
        let additionalTextMyAccountInnerHtml = additionalTextMyAccount;
        const hasFirstNameTag = additionalTextMyAccount.includes("%FIRSTNAME%");
        const hasLastNameTag = additionalTextMyAccount.includes("%LASTNAME%");
        if (hasFirstNameTag) {
            additionalTextMyAccountInnerHtml =
                additionalTextMyAccountInnerHtml.replace(
                    "%FIRSTNAME%",
                    `<strong>${firstName}</strong>`,
                );
        }
        if (hasLastNameTag) {
            additionalTextMyAccountInnerHtml =
                additionalTextMyAccountInnerHtml.replace(
                    "%LASTNAME%",
                    `<strong>${lastName}</strong>`,
                );
        }
        return additionalTextMyAccountInnerHtml;
    };
    const additionalTextMyAccountInnerHtml =
        getAdditionalTextMyAccountInnerHtml();
    // %MYACCOUNT% tag represents a link that should take the user to their
    // giving dashboard
    const myAccountLinkUrl = getMyAccountLinkUrl({
        donorAccount,
        orgId: org,
        formId: id,
        embed,
    });

    const myAccountLink = `<a href="${myAccountLinkUrl}" class="-color--theme-primary" target="_blank"><strong>my account</strong></a>`;
    const headingMyAccountInnerHtml = headingMyAccount.includes("%MYACCOUNT%")
        ? headingMyAccount.replace("%MYACCOUNT%", myAccountLink)
        : headingMyAccount;

    const handleAccountCreationSuccess = (response) => {
        // Reassign donorAccount to createdAccount as donorAccount is
        // declared in the upper scope
        const {
            data: { donorAccount: createdAccount },
        } = response;
        const success = ["Your account has been created!"];
        // Update the donor account data
        dispatch(setDonorAccount(createdAccount));
        // Display the success message
        dispatch(setMessages(success));
    };

    const handleAccountCreationError = (response) => {
        const {
            response: {
                data: { errors = [] },
            },
        } = response;
        // Display the error message
        dispatch(setErrors(errors));
    };

    // Arguments that are passed into the functions that set up the initial
    // touched, initial values and validation logic in Formik
    const initialValuesSettings = { transaction };

    const donorAccountProps = {
        additionalText,
        additionalTextMyAccountInnerHtml,
        controlId: String(controlId),
        displayAdditionalText,
        displayDonorAccountForm,
        displayMyAccount,
        formId: id,
        handleAccountCreationError,
        handleAccountCreationSuccess,
        heading,
        headingMyAccountInnerHtml,
        initialValuesSettings,
        saveButton,
        useStandardDonationAccountCreateUrl: true,
    };

    return <DonorAccount {...donorAccountProps} />;
};

ConnectedDonorAccount.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonorAccount;
