import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./MaxChar.scss";

const MaxChar = ({ maxChar = "", value = "" }) => {
    const hasReachedMaxChar = value.length === Number(maxChar);

    return (
        <div className="-padding-left--5 -text--right">
            <p className="max-char__text">
                {hasReachedMaxChar ? (
                    <>
                        <Icon
                            glyph="exclamation-circle-solid"
                            type="FontAwesome"
                        />
                        <span className="-font-weight--bold">
                            {value.length}/{maxChar}
                        </span>
                    </>
                ) : (
                    <span>
                        {value.length}/{maxChar}
                    </span>
                )}
            </p>
        </div>
    );
};

MaxChar.propTypes = {
    maxChar: PropTypes.string,
    value: PropTypes.string,
};

export default MaxChar;
