/* eslint-disable react/prop-types */
import React from "react";
import WidgetEventsListItemName from "./WidgetEventsListItemName";
import WidgetEventsListItemDate from "./WidgetEventsListItemDate";
import WidgetEventsListItemVenue from "./WidgetEventsListItemVenue";
import WidgetEventsListItemCost from "./WidgetEventsListItemCost";
import WidgetEventsListItemButton from "./WidgetEventsListItemButton";

/**
 * @param {object} props - The component props.
 * @param {string} props.eventId - The unique identifier for the event.
 * @param {object} props.widgetEventsListItemNameProps - The props for the WidgetEventsListItemName component.
 * @param {object} props.widgetEventsListItemDateProps - The props for the WidgetEventsListItemDate component.
 * @param {object} props.widgetEventsListItemVenueProps - The props for the WidgetEventsListItemVenue component.
 * @param {object} props.widgetEventsListItemCostProps - The props for the WidgetEventsListItemCost component.
 * @param {object} props.widgetEventsListItemButtonProps - The props for the WidgetEventsListItemButton component.
 * @returns {React.ReactElement} The rendered WidgetEventsListItem component.
 */
const WidgetEventsListItem = ({
    eventId,
    widgetEventsListItemNameProps,
    widgetEventsListItemDateProps,
    widgetEventsListItemVenueProps,
    widgetEventsListItemCostProps,
    widgetEventsListItemButtonProps,
}) => (
    <div className="widget-events__event" key={eventId}>
        <div className="grid">
            <div className="col col--12 col--sm-8">
                <WidgetEventsListItemName {...widgetEventsListItemNameProps} />
                <WidgetEventsListItemDate {...widgetEventsListItemDateProps} />
                <WidgetEventsListItemVenue
                    {...widgetEventsListItemVenueProps}
                />
                <WidgetEventsListItemCost {...widgetEventsListItemCostProps} />
            </div>
            <WidgetEventsListItemButton {...widgetEventsListItemButtonProps} />
        </div>
    </div>
);

export default WidgetEventsListItem;
