import React from "react";
import PropTypes from "prop-types";

import DonationMessageControl from "./DonationMessageControl";

const ConnectedDonationMessageControl = ({ controlId }) => (
    <DonationMessageControl controlId={controlId} />
);

ConnectedDonationMessageControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonationMessageControl;
