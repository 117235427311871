import React from "react";
import { useSelector } from "react-redux";
import { PayPalAuthenticatedMessage } from "@qgiv/core-donor/source/components/PayPal";
import { selectTotalGift } from "../../../../redux/slices/donationDetailsSlice";
import {
    selectPayPalPaymentSource,
    selectHasCompletedPayPalAuthorizationFlow,
} from "../../../../redux/slices/appSettingsSlice";

const ConnectedPayPalAuthenticatedMessage = () => {
    const totalGift = useSelector(selectTotalGift);

    // Ensure that the amount displayed to the user never includes more than
    // two decimal places
    const authenticatedAmountString = parseFloat(totalGift).toFixed(2);

    const paypalPaymentSource = useSelector((state) =>
        selectPayPalPaymentSource(state),
    );
    const hasCompletedPayPalAuthorizationFlow = useSelector((state) =>
        selectHasCompletedPayPalAuthorizationFlow(state),
    );
    const paypalAuthenticatedMessageProps = {
        authenticatedAmountString,
        hasCompletedPayPalAuthorizationFlow,
        paypalPaymentSource,
    };
    return <PayPalAuthenticatedMessage {...paypalAuthenticatedMessageProps} />;
};

export default ConnectedPayPalAuthenticatedMessage;
