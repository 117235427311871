import moment from "moment";

import { translateTimezone } from "../translators";

/**
 * @function validateMonth
 * @param {string|number} m Month to be validated
 * @returns {boolean} Indicates whether month is valid. Month must be
 *      between 1-12 or 01-12
 * @description Validates month number or string
 */
export const validateMonth = (m) => {
    const _m = parseInt(m, 10);
    return _m >= 1 && _m <= 12;
};

/**
 * @function validateDay
 * @param {string|number} d Day to be validated
 * @returns {boolean} Indicates whether day is valid. Day must be between
 *      1-31 or 01-31
 * @description Validates day number or string
 */
export const validateDay = (d) => {
    const _d = parseInt(d, 10);
    return _d >= 1 && _d <= 31;
};

/**
 * @function validateYear
 * @param {string|number} y Year to be validated
 * @returns {boolean} Indicates whether year is valid. Year in either
 *      2-digit (00-99) or 4-digit (1900-2100) format
 * @description Validates year number or string
 */
export const validateYear = (y) => {
    const yString = `${y}`;
    const yInt = parseInt(y, 10);
    return (
        (yString.length === 2 && yInt >= 0 && yInt < 100) ||
        (yString.length === 4 && yInt >= 1900 && yInt < 2100)
    );
};

/**
 * @function validateFutureDate
 * @param {string} dateString Date to be validated
 * @returns {boolean} Indicates whether date is future.
 * @description Validates whether date is today or later
 */
export const validateFutureDate = (dateString) => {
    const today = new Date();
    const todayY = today.getFullYear();
    const todayM = today.getMonth() + 1;
    const todayD = today.getDate();

    const dateArr = dateString.split("/");
    const m = parseInt(dateArr[0], 10);
    const d = parseInt(dateArr[1], 10);
    const y = parseInt(dateArr[2], 10) || parseInt(dateArr[1], 10);

    if (y !== todayY) return y > todayY;
    if (m !== todayM) return m > todayM;
    if (d && d !== todayD) {
        return d > todayD;
    }
    return true;
};

/**
 * @function validateMDY
 * @param {string} dateString String to be validated
 * @returns {boolean} Indicates whether date is valid. Date must include
 *      month (between 1-12 or 01-12), day (between 1-31 or 01-31), and year
 *      in either 2-digit (00-99) or 4-digit (1900-2100) format
 * @description Validates date in m/d/y format
 */
export const validateMDY = (dateString) => {
    const dateArr = dateString.split("/");
    const m = dateArr[0];
    const d = dateArr[1];
    const y = dateArr[2];

    return (
        dateArr.length === 3 &&
        validateMonth(m) &&
        validateDay(d) &&
        validateYear(y)
    );
};

/**
 * @function validateMY
 * @param {string} dateString String to be validated
 * @returns {boolean} Indicates whether date is valid. Date must include
 *      month (between 1-12 or 01-12) and year in either 2-digit (00-99) or
 *      4-digit (1900-2100) format
 * @description Validates date in m/d/y format
 */
export const validateMY = (dateString) => {
    const dateArr = dateString.split("/");
    const m = dateArr[0];
    const y = dateArr[1];

    return dateArr.length === 2 && validateMonth(m) && validateYear(y);
};

/**
 * @function formatMDY
 * @param {string} dateString String to be formatted
 * @returns {(string|boolean)} If date can be reformatted, returns the date
 *      string. If date is invalid, returns `false`.
 * @description Formats date in mm/dd/yyyy format by adding 0's to day and
 *      month and 19/20 to year as necessary
 */
export const formatMDY = (dateString) => {
    const dateArr = dateString.split("/");
    let m = dateArr[0];
    let d = dateArr[1];
    let y = dateArr[2];

    if (validateMDY(dateString) === false) return false;

    if (d.length === 1) d = `0${d}`;
    if (m.length === 1) m = `0${m}`;
    if (y.length === 2 && y < 100 && y >= 30) y = `19${y}`;
    if (y.length === 2 && y < 30) y = `20${y}`;

    if (m.length !== 2 || d.length !== 2) {
        return false;
    }

    return [m, d, y].join("/");
};

/**
 * @function formatMY
 * @param {string} dateString String to be formatted
 * @returns {(string|boolean)} If date can be reformatted, returns the date
 *      string. If date is invalid, returns `false`.
 * @description Formats date in mm/yyyy format by adding 0's to month and
 *      19/20 to year as necessary
 */
export const formatMY = (dateString) => {
    const dateArr = dateString.split("/");
    let m = dateArr[0];
    let y = dateArr[1];

    if (validateMY(dateString) === false) return false;

    if (m.length === 1) m = `0${m}`;
    if (y.length === 2 && y < 100 && y >= 30) y = `19${y}`;
    if (y.length === 2 && y < 30) y = `20${y}`;

    if (m.length !== 2) {
        return false;
    }

    return [m, y].join("/");
};

/**
 * @function isValidDate
 * @param {string} dateString String to validate
 * @returns {boolean} Flag determining if date is valid
 * @description Determines whether a given string is a valid date string
 */
export const isValidDate = (dateString) => {
    const d = dateString.split("/");
    if (d.length < 3 || d[2].length <= 1) return false;
    return moment(dateString).isValid();
};

/**
 * @function formatDateStringToArray
 * @param {string} dateString String to validate from BE date value
 * @returns {Array} If date is valid, returns array with two strings
 *      ['M/DD/YYYY', 'h:mm a'] ie ['4/13/2021', '4:22 pm'], else returns
 *      empty array
 * @description For creating a valid date array which can be parsed in
 *      setDisplayDate or for display on FE
 */
export const formatDateStringToArray = (dateString) => {
    if (!dateString) {
        return [];
    }
    const d = moment(dateString, "YYYY-MM-DD hh:mm:ss");
    return d.isValid()
        ? [
              d.format("dddd, M/DD/YYYY"),
              d.format("h:mm a").split(" ").join("").replace(":00", ""),
          ]
        : [];
};

/**
 * @function getFormattedEventDate
 * @param {object} displayDateObject contains startDate, endDate, and
 *      timezone
 * @param {string} displayDateObject.startDate BE string for event start
 *      date
 * @param {string} displayDateObject.endDate BE string for event ends date
 * @param {string} displayDateObject.timezone Timezone Enum to be parsed
 *      through translateTimezone
 * @returns {string} If dates are valid, returns string for date display for
 *      event with start and end dates
 * @description There are different displays for an event's date depending
 *      on the start and end dates, this function validates the date strings
 *      and determines which type of date to display and returns that string
 */
export const getFormattedEventDate = ({ startDate, endDate, timezone }) => {
    const startDateArray = formatDateStringToArray(startDate);
    const endDateArray = formatDateStringToArray(endDate);
    const translatedTimezone = timezone ? translateTimezone(timezone) : "";

    if (startDateArray.length < 2 || endDateArray.length < 2) {
        return "";
    }

    if (
        startDateArray.length === 2 &&
        endDateArray.length === 2 &&
        startDateArray[0] === endDateArray[0]
    ) {
        return `${startDateArray[0]} ${startDateArray[1]} ${
            timezone ? translatedTimezone : ""
        } - ${endDateArray[1]} ${timezone ? translatedTimezone : ""}`;
    }

    return `${startDateArray[0]} - ${endDateArray[0]}`;
};
