import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import DonationFormProgressBar from "../../common/DonationFormProgressBar";
import { selectIsCms } from "../../../redux/slices/configSettingsSlice";
import {
    selectCurrentPage,
    setCurrentPage,
    selectSkipAdditionalDetailsPage,
} from "../../../redux/slices/appSettingsSlice";
import {
    selectAllCmsSettings,
    selectCmsPageOrder,
} from "../../../redux/slices/cmsSettingsSlice";
import { selectCmsOptionByTypeMultiplePagesSelector } from "../../../redux/selectors/cmsSettingsSelectors";

const ConnectedDonationFormProgressBar = () => {
    const dispatch = useDispatch();
    const {
        ENUMS: {
            CmsPageStandardizer: {
                CONFIRMATION,
                ADDITIONAL_DETAILS,
                QGIV_EVENTS,
            },
            CMS_Control: { HEADING },
        },
    } = constants;
    const isCms = useSelector(selectIsCms);
    const currentStep = useSelector(selectCurrentPage);
    const cmsSettings = useSelector(selectAllCmsSettings);

    const pageOrder = useSelector(selectCmsPageOrder);

    const skipAdditionalDetailsPage = useSelector(
        selectSkipAdditionalDetailsPage,
    );
    // Should this filtering be handled on the BE?
    // All steps that should be displayed for DonationForm.
    // Filter out Additional page if no content and Confirmation Page
    // Should only include Gift, Details, Additional (if content), Payment
    // convert to numbers
    const pageFilter = (page) => {
        if (Number(page) !== CONFIRMATION && Number(page) !== QGIV_EVENTS) {
            if (Number(page) === ADDITIONAL_DETAILS) {
                return !skipAdditionalDetailsPage;
            }

            return true;
        }
        return false;
    };

    const pagesToDisplay = pageOrder
        .filter(pageFilter)
        // Convert to numbers to make component more reuseable
        .map((page) => Number(page));

    const sectionHeadings = useMemo(() => {
        const state = { cmsSettings };
        return selectCmsOptionByTypeMultiplePagesSelector(
            HEADING,
            "title",
            state,
            pagesToDisplay,
        );
    }, [HEADING, pagesToDisplay, cmsSettings]);

    const allDisplayedPageSteps = pagesToDisplay;

    const labels = sectionHeadings;
    const handleStepClick = (stepToPage) => {
        dispatch(setCurrentPage(stepToPage));
    };

    const ownProps = {
        isCms,
        // All pages that are navigable by steps (confirmation not included)
        allDisplayedPageSteps,
        currentStep,
        handleStepClick,
        labels,
    };

    return <DonationFormProgressBar {...ownProps} />;
};

export default ConnectedDonationFormProgressBar;
