import React from "react";
import PropTypes from "prop-types";

import Icon from "../Icon";

import "./PoweredByQgiv.scss";

const PoweredByQgiv = ({ classNames = [], dark = true }) => (
    <Icon
        classNames={[
            ...classNames,
            "powered-by-qgiv-logo",
            dark ? "powered-by-qgiv-logo--dark" : "",
        ]}
        glyph="logo-powered-by-qgiv"
        type="Custom"
    />
);

PoweredByQgiv.propTypes = {
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    classNames: PropTypes.arrayOf(PropTypes.string),
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    dark: PropTypes.bool,
};

export default PoweredByQgiv;
