/* eslint-disable react/prop-types */
import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props - Component properties
 * @param {object} props.iconProps - Properties to pass to the Icon component
 * @param {string} props.venue - The venue of the event to display
 * @param {boolean} props.shouldDisplayVenue - Flag indicating whether to display the venue
 * @returns {React.ReactElement|null} The WidgetEventsListItemVenue component or null if the venue should not be displayed.
 */
const WidgetEventsListItemVenue = ({ iconProps, venue, shouldDisplayVenue }) =>
    shouldDisplayVenue ? (
        <p className="widget-events__detail">
            <Icon {...iconProps} glyph="map-marker-alt" />
            {venue}
        </p>
    ) : null;

export default WidgetEventsListItemVenue;
