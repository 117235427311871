import React from "react";
import PropTypes from "prop-types";
import { Button } from "@qgiv/core-donor";

const ConfirmationFieldsSubmit = ({ submitButtonLabel }) => (
    <div className="confirmation-fields-submit grid">
        <div className="col col--12 -margin-top--15">
            <Button type="submit">{submitButtonLabel}</Button>
        </div>
    </div>
);

ConfirmationFieldsSubmit.propTypes = {
    submitButtonLabel: PropTypes.string.isRequired,
};

export default ConfirmationFieldsSubmit;
