import React from "react";
import ConnectedDonationFormBase from "../templates/DonationFormBase";
import { ConnectedEventsPageContent } from "./EventsPageContent";

/**
 * @returns {React.ReactElement} The EventsPage component.
 */
const EventsPage = () => (
    <ConnectedDonationFormBase>
        <ConnectedEventsPageContent />
    </ConnectedDonationFormBase>
);

export default EventsPage;
