import React from "react";
import RestrictionGroup from "./RestrictionGroup";

/**
 * @typedef {import("./restrictionGroupsTypes").RestrictionGroupProps} RestrictionGroupProps
 */

/**
 *
 * @param {object} props
 * @param {RestrictionGroupProps[]} props.restrictionGroups
 * @returns {React.ReactElement}
 */
const RestrictionGroups = ({ restrictionGroups }) => (
    <>
        {restrictionGroups.map((restrictionGroupProps) => (
            <RestrictionGroup {...restrictionGroupProps} />
        ))}
    </>
);

export default RestrictionGroups;
