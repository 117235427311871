import React from "react";
import { ConnectedGivingPlanCard } from "./GivingPlanCard";
import { ConnectedCreateYourOwnPlan } from "./CreateYourOwnPlan";
import {
    ConnectedNumberOfInstallmentsSelect,
    ConnectedInstallmentFrequencySelect,
    ConnectedInstallmentSummary,
    ConnectedInstallmentHeader,
    ConnectedInstallmentDetailsHeader,
} from "./Installments";

/**
 * @param {object} props
 * @param {object[]} props.activePlans
 * @param {number} props.controlId
 * @param {React.ReactEventHandler} props.handleChange
 * @param {boolean} props.isInstallment
 * @returns {React.ReactElement} The rendered GivingPlans component.
 */
const GivingPlans = ({
    activePlans,
    controlId,
    handleChange,
    isInstallment,
}) => (
    <>
        {isInstallment && <ConnectedInstallmentHeader controlId={controlId} />}
        <div className="col col--12 col--no-top-gutter">
            <div
                role="radiogroup"
                aria-required="true"
                className="giving-plans"
            >
                <div className="grid">
                    {activePlans.map((plan) => (
                        <ConnectedGivingPlanCard
                            plan={plan}
                            key={plan.id}
                            handleChange={handleChange}
                            isInstallment={isInstallment}
                        />
                    ))}
                    <ConnectedCreateYourOwnPlan
                        controlId={controlId}
                        handleChange={handleChange}
                    />
                    {isInstallment && (
                        <div className="col col--12 -margin-top--30">
                            <ConnectedInstallmentDetailsHeader
                                controlId={controlId}
                            />
                            <ConnectedInstallmentFrequencySelect
                                controlId={controlId}
                            />
                            <ConnectedNumberOfInstallmentsSelect
                                controlId={controlId}
                            />
                            <ConnectedInstallmentSummary />
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
);

export default GivingPlans;
