import React, { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import PropTypes from "prop-types";
import "./SimpleErrorDisplay.scss";

// setup default function that returns a promise
// so apps that use this component don't have to pass
// along a submit api call if they don't want to
const defaultPromise = () => {
    const fakePromise = new Promise((resolve, reject) => {
        const pass = true;
        if (pass) {
            resolve();
        } else {
            reject();
        }
    });
    return fakePromise;
};

const SimpleErrorDisplay = ({
    headingText = "We're sorry, it looks like something went wrong.",
    additionalText = "Contact the organization for support.",
    removeSessionStorage = () => {},
    submitErrorData = defaultPromise,
}) => {
    const location = window.location.href;
    const { message, stack } = useRouteError();

    useEffect(() => {
        // remove session data if exists because it may be that loading that stored data was
        // what was causing the crash and we don't want to repeat that process.
        removeSessionStorage();
        submitErrorData({
            data: { location, message, stack },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className="error-display">
            <h2 className="error-display__heading">{headingText}</h2>
            <p className="error-display__additional-text">{additionalText}</p>
        </div>
    );
};

SimpleErrorDisplay.propTypes = {
    headingText: PropTypes.string,
    additionalText: PropTypes.string,
    removeSessionStorage: PropTypes.func,
    submitErrorData: PropTypes.func,
};

export default SimpleErrorDisplay;
