import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import {
    selectHasInitiatedPayPalAuthorizationFlow,
    selectHasCompletedPayPalAuthorizationFlow,
    selectShouldDisplayApplePay,
} from "../../../../redux/slices/appSettingsSlice";
import DigitalWalletPaymentOptions from "./DigitalWalletPaymentOptions";

const ConnectedDigitalWalletPaymentOptions = ({ controlId }) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const formikContext = useFormikContext();
    const formSettings = useSelector(selectAllFormSettings);
    const shouldDisplayApplePay = useSelector(selectShouldDisplayApplePay);
    const hasInitiatedPayPalAuthorizationFlow = useSelector(
        selectHasInitiatedPayPalAuthorizationFlow,
    );
    const hasCompletedPayPalAuthorizationFlow = useSelector(
        selectHasCompletedPayPalAuthorizationFlow,
    );
    const { values = {} } = formikContext;
    const { paymentData = {} } = formSettings;
    const { Payment_Type } = values;
    const {
        enableAchPayments = false,
        enableCCPayments = false,
        enablePaypalPayments = false,
    } = paymentData;
    // The Apple Pay button is displayed if the org & form are set up for it,
    // the form is viewed on a supported device and PayPal is not selected. The
    // Apple Pay button is also hidden if the user has initiated a PayPal
    // request
    const displayApplePayPaymentMethod =
        shouldDisplayApplePay && Payment_Type !== PaymentType.PAYPAL;
    // Hide the PayPal UI once the user clicks on the Apple Pay button
    const displayPayPalSDKScriptProvider =
        enablePaypalPayments &&
        Payment_Type !== PaymentType.APPLEPAY &&
        !hasCompletedPayPalAuthorizationFlow;
    const displayPayPalAuthenticatedMessage =
        hasCompletedPayPalAuthorizationFlow && !displayPayPalSDKScriptProvider;
    // Display the or text once either the Bank or the CC payment method is
    // visible until the user clicks on the Apple Pay button
    const displayOrText =
        (enableAchPayments || enableCCPayments) &&
        Payment_Type !== PaymentType.APPLEPAY;
    // Display the user a different payment method button once the user has
    // clicked on the Apple Pay or the PayPal button
    const displayUseADifferentPaymentMethodButton =
        (hasInitiatedPayPalAuthorizationFlow &&
            Payment_Type === PaymentType.PAYPAL) ||
        Payment_Type === PaymentType.APPLEPAY;

    const digitalWalletPaymentOptionsProps = {
        controlId,
        displayUseADifferentPaymentMethodButton,
        displayOrText,
        displayPayPalSDKScriptProvider,
        displayApplePayPaymentMethod,
        displayPayPalAuthenticatedMessage,
    };

    return (
        <DigitalWalletPaymentOptions {...digitalWalletPaymentOptionsProps} />
    );
};

ConnectedDigitalWalletPaymentOptions.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default ConnectedDigitalWalletPaymentOptions;
