import React from "react";
import { useSelector } from "react-redux";
import {
    getActiveDisplayableRestrictions,
    getAmountIds,
    getAmountsToDisplay,
    getAttachedAmountsToDisplay,
    getRestrictionGroupsToDisplay,
    getRestrictionIds,
} from "@qgiv/donation-form";
import { selectAllDonationSettings } from "../../../../../../redux/slices/donationSettingsSlice";
import { selectCurrentDisplay } from "../../../../../../redux/slices/configSettingsSlice";
import { selectGiftDetails } from "../../../../../../redux/slices/donationDetailsSlice";
import { selectAllFormSettings } from "../../../../../../redux/slices/formSettingsSlice";
import { selectRestrictionSettings } from "../../../../../../redux/slices/restrictionSettingsSlice";
import { getRestrictionGroups } from "./restrictionGroupsHelpers";
import RestrictionGroups from "./RestrictionGroups";

/**
 *
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement}
 */
const ConnectedRestrictionGroups = ({ controlId }) => {
    const currentDisplay = useSelector(selectCurrentDisplay);
    const donationSettings = useSelector(selectAllDonationSettings);
    const giftDetails = useSelector(selectGiftDetails);
    const formSettings = useSelector(selectAllFormSettings);
    const restrictionSettings = useSelector(selectRestrictionSettings);
    const { activeAmounts = [], maxAmt = 0, minAmt = 0 } = donationSettings;
    const { hasSelectedRecurringDonation = false } = giftDetails;
    const { donationActive = false, enableRecur = false } = formSettings;
    const { restrictions = [], multiRestrictionGroups = [] } =
        restrictionSettings;

    // Get the list of restrictions to display based off of display settings
    const restrictionsToDisplay = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    const idsOfDisplayableRestrictions = getRestrictionIds(
        restrictionsToDisplay,
    );

    // Get the list of amounts that should be displayed based off if the
    // donation is one time or ongoing
    const amountsToDisplay = getAmountsToDisplay({
        activeAmounts,
        donationActive,
        enableRecur,
        hasSelectedRecurringDonation,
        maxAmt,
        minAmt,
    });

    // Uses the list of amounts that can possibly be displayed to refine the
    // list of restriction groups and restrictions to display. This ensures
    // that we don't display any groups or restrictions with no amounts
    const idsOfAmountsToDisplay = getAmountIds(amountsToDisplay);
    const attachedAmountsToDisplay = getAttachedAmountsToDisplay(
        idsOfAmountsToDisplay,
        restrictions,
    );

    // Uses the list of restrictions and amounts generated above to return the
    // list of restrictions that should be displayed
    const restrictionGroupsToDisplay = getRestrictionGroupsToDisplay({
        attachedAmountsToDisplay,
        idsOfDisplayableRestrictions,
        multiRestrictionGroups,
    });

    // Generate props to be passed down to the UI
    const restrictionGroups = getRestrictionGroups(
        restrictionGroupsToDisplay,
        controlId,
    );

    const restrictionGroupsProps = {
        controlId,
        restrictionGroups,
    };

    return <RestrictionGroups {...restrictionGroupsProps} />;
};

export default ConnectedRestrictionGroups;
