import React from "react";
import PropTypes from "prop-types";

const ReceiptAdditionalDetails = ({
    additionalDetailsLabel = "Additional Details",
    parsedCustomFields = [],
}) => (
    <div className="col col--12 -margin-top--15">
        <h3>{additionalDetailsLabel}</h3>
        {parsedCustomFields.map((field) => (
            <p key={field.id}>{`${field.fieldLabel}: ${field.value}`}</p>
        ))}
    </div>
);

ReceiptAdditionalDetails.propTypes = {
    additionalDetailsLabel: PropTypes.string,
    parsedCustomFields: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            fieldLabel: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
};

export default ReceiptAdditionalDetails;
