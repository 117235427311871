import React from "react";
import PropTypes from "prop-types";
import { Select } from "@qgiv/core-donor";

const SubRestrictionsList = ({
    ariaLabel,
    handleBlur,
    handleChange,
    id,
    label,
    name,
    subRestrictionListOptions,
    value,
}) => (
    <Select
        ariaLabel={ariaLabel}
        disableBlankOption
        disableInlineLabel
        handleBlur={handleBlur}
        handleChange={handleChange}
        id={id}
        label={label}
        name={name}
        value={value}
    >
        {subRestrictionListOptions.map((subRestrictionListOption) => (
            <option
                key={subRestrictionListOption.key}
                value={subRestrictionListOption.value}
            >
                {subRestrictionListOption.name}
            </option>
        ))}
    </Select>
);

SubRestrictionsList.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    subRestrictionListOptions: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    value: PropTypes.string.isRequired,
};

export default SubRestrictionsList;
