import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props
 * @param {string} props.formattedInstallmentSummary
 * @returns {React.ReactElement} - The rendered InstallmentSummary component.
 */
const InstallmentSummary = ({ formattedInstallmentSummary = "" }) => (
    <div className="-margin-bottom--15">
        <div className="-display--flex -align-items--center -flex-gap--half -callout">
            <Icon glyph="calendar-alt-regular" type="FontAwesome" />
            <span className="-type--small">{formattedInstallmentSummary}</span>
        </div>
    </div>
);

export default InstallmentSummary;
