import { createSlice } from "@reduxjs/toolkit";
import { donorAccount as initialState } from "../initialState";
import {
    selectDonorAccountSelector,
    selectIsLoggedInSelector,
    selectHasStoredPaymentMethodsSelector,
} from "../selectors/donorAccountSelectors";

const getDonorAccountInitialState = () => initialState;
export const donorAccountInitialState = getDonorAccountInitialState();

const slice = createSlice({
    name: "donorAccount",
    initialState: donorAccountInitialState,
    reducers: {
        setDonorAccount: (state, action) => {
            state = action.payload;
            const newDonorAccount = { ...action.payload };
            return {
                ...newDonorAccount,
            };
        },
    },
});

// actions
export const { setDonorAccount } = slice.actions;

// selectors
export const selectDonorAccount = (state) => selectDonorAccountSelector(state);

export const selectIsLoggedIn = (state) => selectIsLoggedInSelector(state);

export const selectHasStoredPaymentMethods = (state) =>
    selectHasStoredPaymentMethodsSelector(state);

export default slice.reducer;
