import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { selectAllDonationSettings } from "../../../../../redux/slices/donationSettingsSlice";
import { updateGiftDetails } from "../../../../../redux/slices/donationDetailsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import { selectShouldDisplayTextToDonateInterface } from "../../../../../redux/slices/smsDataSlice";
import DonationFrequencySelect from "./DonationFrequencySelect";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement|null} The DonationFrequenciesSelect component or null.
 */
const ConnectedDonationFrequenciesSelect = ({ controlId }) => {
    const dispatch = useDispatch();

    const { values, handleBlur, handleChange } = useFormikContext();
    const { Recurring_Frequency } = values;

    const { activeDonationFrequencies, activePlans, pledgeActive } =
        useSelector(selectAllDonationSettings);

    const shouldDisplayTextToDonateInterface = useSelector(
        selectShouldDisplayTextToDonateInterface,
    );

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const {
        options: { recurringDropdownLabel },
    } = control;

    const isUsingGivingPlansSystem = activePlans?.length > 0 && pledgeActive;
    const isSingleFrequencyCreateYourOwnPlan =
        activeDonationFrequencies?.length === 1 &&
        isUsingGivingPlansSystem &&
        !shouldDisplayTextToDonateInterface;

    const handleFrequencyChange = (e) => {
        const { currentTarget } = e;
        const { name, value } = currentTarget;

        // Formik's built in change handler
        handleChange(e);
        // Store value in Redux
        dispatch(updateGiftDetails({ [name]: value }));
    };

    // -------------------------------------------------------------------------
    // NOTE: activeDonationFrequencies have labels that can be customized in cms.
    // The are being added to the activeDonationFrequencies object in the BE before
    // being handed to us. No need to pull the labels from cmsSettings.
    // -------------------------------------------------------------------------
    const displayFrequencySelect =
        (activeDonationFrequencies?.length > 1 &&
            !shouldDisplayTextToDonateInterface) ||
        isSingleFrequencyCreateYourOwnPlan;

    const donationFrequenciesSelectProps = {
        // Formik's built in blur handler
        handleBlur,
        handleChange: handleFrequencyChange,
        activeDonationFrequencies,
        recurringDropdownLabel,
        Recurring_Frequency,
    };

    return displayFrequencySelect ? (
        <DonationFrequencySelect {...donationFrequenciesSelectProps} />
    ) : null;
};

export default ConnectedDonationFrequenciesSelect;
