const translateTransaction = (transaction) => {
    const translatedTransaction = Object.keys(transaction).reduce(
        (_transaction, key) => {
            if (typeof transaction[key] === "number") {
                return {
                    ..._transaction,
                    [key]: `${transaction[key]}`,
                };
            }
            return {
                ..._transaction,
                [key]: transaction[key],
            };
        },
        {},
    );

    return {
        ...translatedTransaction,
    };
};

export default translateTransaction;
