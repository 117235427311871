import React from "react";
import InnerHTML from "dangerously-set-html-content";

const DigiCert = () => {
    const _html = `
    <div id="DigiCertClickID_qLWgJHxn" data-language="en_US">
        <a href="https://www.digicert.com/ssl.htm" style="display: none;">SSL</a>
    </div>
    <script type="text/javascript">
    var __dcid = __dcid || [];__dcid.push(["DigiCertClickID_qLWgJHxn", "11", "s", "black", "qLWgJHxn"]);(function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
    </script>
    `;
    return <InnerHTML html={_html} />;
};

export default DigiCert;
