import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@qgiv/core-react";
import { RecurringHeartAnimation } from "@qgiv/core-donor";
import DonatedGiftFrequencyTabs from "./DonatedGiftFrequencyTabs";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../../../redux/slices/appSettingsSlice";
import { selectAllDonationSettings } from "../../../../redux/slices/donationSettingsSlice";
import "./DonatedGiftFrequencyTabs.scss";
import {
    selectGiftDetails,
    updateGiftDetails,
} from "../../../../redux/slices/donationDetailsSlice";

const ConnectedDonatedGiftFrequencyTabs = ({ controlId }) => {
    const [displayRecurringHeartAnimation, setDisplayRecurringHeartAnimation] =
        useState(false);
    const dispatch = useDispatch();
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { activeDonationFrequencies, pledgeActive } = useSelector(
        selectAllDonationSettings,
    );

    const {
        options: {
            defaultTab,
            oneTimeTabLabel,
            recurringMultiFreqTabLabel = "Ongoing",
            recurringSingleFreqTabLabel = "%FREQUENCY%",
            hideRecurringDonationAnimation = false,
        },
    } = control;

    const { hasSelectedRecurringDonation } = useSelector(selectGiftDetails);

    // responsible for displaying the recurring heart animation
    // when ongoing tab is clicked
    const handleDisplayRecurringHeartAnimation = (isOngoing) => {
        const isOngoingAndShowingRecurringHeartAnimation =
            isOngoing && !hideRecurringDonationAnimation;
        if (isOngoingAndShowingRecurringHeartAnimation) {
            setDisplayRecurringHeartAnimation(true);
        }
    };

    const handleClick = (tab) => {
        const isOngoing = tab === "ongoing";

        dispatch(
            updateGiftDetails({ hasSelectedRecurringDonation: isOngoing }),
        );
        handleDisplayRecurringHeartAnimation(isOngoing);
    };

    const renderOngoingLabel = () => {
        let text = recurringMultiFreqTabLabel;

        if (activeDonationFrequencies.length === 1 && !pledgeActive) {
            text = recurringSingleFreqTabLabel.replace(
                "%FREQUENCY%",
                activeDonationFrequencies[0].label,
            );
        }

        return (
            <>
                {displayRecurringHeartAnimation && (
                    <RecurringHeartAnimation
                        setDisplayRecurringHeartAnimation={
                            setDisplayRecurringHeartAnimation
                        }
                    />
                )}
                <Icon
                    classNames={[
                        "-padding-right--10 donation-frequency-tabs__recurring-icon -type--large",
                    ]}
                    glyph="qgiv-recurring"
                    type="Custom"
                />
                {text}
            </>
        );
    };

    // Id and label values for one time and ongoing tabs
    const oneTimeTabAttributes = {
        id: "one-time",
        label: oneTimeTabLabel,
    };

    const ongoingTabAttributes = {
        id: "ongoing",
        label: renderOngoingLabel(),
    };

    // Use the defaultTab value from CMS to determine whether the left tab
    // should be set to One Time or Ongoing
    const getLeftElementAttributes = () => {
        let leftElementAttributes;

        if (defaultTab === "ongoing") {
            leftElementAttributes = {
                ...ongoingTabAttributes,
            };
        } else {
            leftElementAttributes = {
                ...oneTimeTabAttributes,
            };
        }

        return leftElementAttributes;
    };

    // Use the defaultTab value from CMS to determine whether the right tab
    // should be set to One Time or Ongoing
    const getRightElementAttributes = () => {
        let rightElementAttributes;

        // active is used to forcefully make the right tab active or inactive
        // whenever the hasSelectedRecurringDonation value changes. Manually setting this
        // is how the "multiply your impact" button toggles the amount tabs
        if (defaultTab === "ongoing") {
            rightElementAttributes = {
                ...oneTimeTabAttributes,
                active: !hasSelectedRecurringDonation,
            };
        } else {
            rightElementAttributes = {
                ...ongoingTabAttributes,
                active: hasSelectedRecurringDonation,
            };
        }

        return rightElementAttributes;
    };

    const donatedGiftFrequencyTabsProps = {
        handleClick,
        getLeftElementAttributes,
        getRightElementAttributes,
    };

    return <DonatedGiftFrequencyTabs {...donatedGiftFrequencyTabsProps} />;
};

ConnectedDonatedGiftFrequencyTabs.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonatedGiftFrequencyTabs;
