import React from "react";
import { getDataLayerOptions } from "@qgiv/donation-form";
import { useDispatch, useSelector } from "react-redux";
import { StartAnalyticsEvents } from "@qgiv/core-donor";
import {
    selectCurrentPage,
    selectHasSentStartAnalyticsEvents,
    selectHasSubmittedForm,
    setHasSentStartAnalyticsEvents,
} from "../../../redux/slices/appSettingsSlice";
import { selectConfig } from "../../../redux/slices/configSettingsSlice";
import { selectDedicationSettings } from "../../../redux/slices/dedicationSettingsSlice";
import { selectAllDonationSettings } from "../../../redux/slices/donationSettingsSlice";
import {
    selectAllDonationDetails,
    selectTotalGift,
} from "../../../redux/slices/donationDetailsSlice";
import {
    selectAllDisplayableCustomFields,
    selectDetailsPageFieldsData,
} from "../../../redux/slices/fieldsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectReceipt } from "../../../redux/slices/receiptsSlice";
import { selectRestrictionSettings } from "../../../redux/slices/restrictionSettingsSlice";
import { selectSmsData } from "../../../redux/slices/smsDataSlice";
import {
    getClosedAnalyticsEvents,
    sendStartAnalyticsEvents,
} from "../../../utility";

const ConnectedStartAnalyticsEvents = () => {
    const dispatch = useDispatch();

    // Get data from state
    const allDisplayableCustomFields = useSelector(
        selectAllDisplayableCustomFields,
    );
    const config = useSelector(selectConfig);
    const currentPage = useSelector(selectCurrentPage);
    const dedicationSettings = useSelector(selectDedicationSettings);
    const donationDetails = useSelector(selectAllDonationDetails);
    const donationSettings = useSelector(selectAllDonationSettings);
    const formSettings = useSelector(selectAllFormSettings);
    const hasSentStartAnalyticsEvents = useSelector(
        selectHasSentStartAnalyticsEvents,
    );
    const hasSubmittedForm = useSelector(selectHasSubmittedForm);
    const receipt = useSelector(selectReceipt);
    const total = useSelector(selectTotalGift);
    const restrictionSettings = useSelector(selectRestrictionSettings);
    const smsData = useSelector(selectSmsData);
    const detailsPageFieldsData = useSelector((state) =>
        selectDetailsPageFieldsData(state, total),
    );
    // Convert data into keys that are needed
    const dataLayerOptions = getDataLayerOptions({
        allDisplayableCustomFields,
        config,
        dedicationSettings,
        detailsPageFieldsData,
        donationDetails,
        donationSettings,
        formSettings,
        receipt,
        restrictionSettings,
        smsData,
    });
    const shouldRunStartAnalyticsEvents = !hasSentStartAnalyticsEvents;

    // Action that is dispatched to the Redux store
    const setHasSentStartAnalyticsEventsValue = () => {
        dispatch(setHasSentStartAnalyticsEvents(true));
    };

    const props = {
        currentPage,
        dataLayerOptions,
        getClosedAnalyticsEvents,
        hasSubmittedForm,
        sendStartAnalyticsEvents,
        setHasSentStartAnalyticsEventsValue,
        shouldRunStartAnalyticsEvents,
    };

    return <StartAnalyticsEvents {...props} />;
};

export default ConnectedStartAnalyticsEvents;
