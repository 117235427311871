import React from "react";
import PropTypes from "prop-types";
import { isDisplayable, constants } from "@qgiv/core-js";
import QFieldInput from "../QFieldInput";

const SystemFieldGroupBillingAddress = (props) => {
    const {
        ENUMS: {
            EntityType: {
                SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
                SYSTEM_FIELD_GROUP_BILLING_ADDRESS,
            },
            FieldType,
        },
        cms,
    } = constants;
    const {
        field: { fields },
        values: { Billing_Address_Use_Mailing },
        settings: {
            allFieldsAndGroups,
            config: { currentDisplay },
        },
    } = props;
    const addressGroupDisplayOptions =
        allFieldsAndGroups.find(
            (field) =>
                Number(field.type) === SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
        )?.options || {};

    const isAddressGroupDisplayable = isDisplayable(
        addressGroupDisplayOptions,
        currentDisplay,
    );
    const billingFields = allFieldsAndGroups.find(
        (field) => Number(field.type) === SYSTEM_FIELD_GROUP_BILLING_ADDRESS,
    );

    const billingCountry = billingFields.fields.find(
        (field) => Number(field.type) === FieldType.BILLING_COUNTRY,
    );

    const billingCountryDefault = billingCountry.defaultCountry;

    return (
        <div className="grid">
            {(!isAddressGroupDisplayable || !Billing_Address_Use_Mailing) &&
                fields.map((field, i) => {
                    const displayable = isDisplayable(field, currentDisplay);
                    if (displayable) {
                        switch (parseInt(field.type)) {
                            case FieldType.BILLING_ADDRESS:
                                return (
                                    <div key={i} className="col col--12">
                                        <QFieldInput {...props} field={field} />
                                    </div>
                                );
                            case FieldType.BILLING_ADDRESS_2:
                                return (
                                    <div key={i} className="col col--12">
                                        <QFieldInput {...props} field={field} />
                                    </div>
                                );
                            case FieldType.BILLING_CITY:
                                return (
                                    <div
                                        key={i}
                                        className="col col--12 col--sm-4"
                                    >
                                        <QFieldInput {...props} field={field} />
                                    </div>
                                );
                            case FieldType.BILLING_STATE:
                                return (
                                    <div
                                        key={i}
                                        className="col col--12 col--sm-4"
                                    >
                                        <QFieldInput {...props} field={field} />
                                    </div>
                                );
                            case FieldType.BILLING_ZIP:
                                const propsWithBillingCountryDefault = {
                                    ...props,
                                    billingCountryDefault,
                                };
                                return (
                                    <div
                                        key={i}
                                        className="col col--12 col--sm-4"
                                    >
                                        <QFieldInput
                                            {...propsWithBillingCountryDefault}
                                            field={field}
                                        />
                                    </div>
                                );
                            case FieldType.BILLING_COUNTRY:
                                return (
                                    <div key={i} className="col col--12">
                                        <QFieldInput {...props} field={field} />
                                    </div>
                                );
                            default:
                                return null;
                        }
                    } else {
                        return null;
                    }
                })}
        </div>
    );
};

SystemFieldGroupBillingAddress.propTypes = {
    field: PropTypes.shape({
        fields: PropTypes.array,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        allFieldsAndGroups: PropTypes.array.isRequired,
        config: PropTypes.shape({
            currentDisplay: PropTypes.number.isRequired,
        }),
    }),
    values: PropTypes.shape({
        Billing_Address_Use_Mailing: PropTypes.bool,
        Billing_Address: PropTypes.string,
        Billing_Address_2: PropTypes.string,
        Billing_City: PropTypes.string,
        Billing_State: PropTypes.string,
        Billing_Zip: PropTypes.string,
        Billing_Country: PropTypes.string,
    }).isRequired,
};

export default SystemFieldGroupBillingAddress;
