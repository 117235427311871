import React from "react";
import { Modal2 } from "@qgiv/core-donor";
import DonationFormModalContent from "./DonationFormModalContent";

/**
 * DonationFormModal component renders a modal with donation form content.
 * @param {object} props - The properties object.
 * @param {boolean} props.appModalOpen - Determines if the modal is open.
 * @param {boolean} props.displayCloseButton - Determines if the close button should be displayed.
 * @param {Function} props.handleCloseDonationFormModal - Function to handle closing the modal.
 * @param {boolean} props.hardModalFullHeight - Determines if the modal should take full height.
 * @returns {React.ReactElement} The rendered modal component or null if the modal is not open.
 */
const DonationFormModal = ({
    appModalOpen,
    displayCloseButton,
    handleCloseDonationFormModal,
    hardModalFullHeight,
}) =>
    appModalOpen ? (
        <Modal2
            onClose={handleCloseDonationFormModal}
            closeOnClickOutside={false}
            classes="qg-vendor-form-container donation-form-modal"
            usePrimaryBackground={false}
            hardModalFullHeight={hardModalFullHeight}
            displayCloseButton={displayCloseButton}
            containsForm
            standardFormWidth
            // prevents scrollIntoView to run on useEffect
            // brings in runScrollIntoView as prop for
            // DonationFormContent
            allowScrollIntoViewOnChildrenUpdate={false}
        >
            {/* There is a hidden prop coming in from Modal2 here */}
            <DonationFormModalContent />
        </Modal2>
    ) : null;

export default DonationFormModal;
