import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./ImageLoader.scss";

const _loaded = {};

const ImageLoader = ({
    // Classname overrides
    className = "",
    loadedClassName = "img--loaded",
    loadingClassName = "img--loading",

    // html
    src,

    // event handling
    handleImgLoad = () => {},

    // other
    ...props
}) => {
    const [loaded, setLoaded] = useState(_loaded[src]);
    const handleLoad = (e) => {
        _loaded[src] = true;
        setLoaded(true);
        handleImgLoad(e);
    };
    // By default toggling the class added to the image from loadedClassName to
    // loadingClassName will hide the image until it is loaded at which point
    // the image will be moved upwards into position and faded into view
    const imgClassNames = cx(
        className,
        loaded ? loadedClassName : loadingClassName,
    );

    return (
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
            src={src}
            className={imgClassNames}
            onLoad={handleLoad}
            {...props}
        />
    );
};

ImageLoader.propTypes = {
    className: PropTypes.string,
    loadedClassName: PropTypes.string,
    loadingClassName: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    src: PropTypes.string,
    handleImgLoad: PropTypes.func,
};

export default ImageLoader;
