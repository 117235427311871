import { useState, useEffect } from "react";

// Hook
/**
 * @public
 * @function useHover
 * @description React hook for determining if the user device has hover capability.
 * Example: phone/tablet vs desktop/laptop
 * @returns {boolean} true or false if client device uses hover functionality.
 */
export default function useHover() {
    const [hoverable, setHoverable] = useState();

    const detectHover = () => {
        if (window.matchMedia("(hover: none)").matches) {
            setHoverable(false);
        } else {
            setHoverable(true);
        }
    };

    useEffect(() => {
        detectHover();
    }, []);

    return hoverable;
}
