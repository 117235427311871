import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import PaymentCard from "./PaymentCard";

const StoredPaymentMethods = (props) => {
    const {
        hasToggledPaymentMethodView,
        donorAccount: { paymentInfo = [] },
        savedPaymentMethodsSectionHeading,
    } = props;
    const savedPaymentMethodsTitleRef = useRef();

    // If the use a saved payment method was clicked move the heading text so
    // that the user can seamlessly tab through the saved payment methods
    useEffect(() => {
        if (paymentInfo.length > 0 && hasToggledPaymentMethodView) {
            savedPaymentMethodsTitleRef.current.focus();
        }
    }, [paymentInfo.length, hasToggledPaymentMethodView]);

    return (
        paymentInfo.length > 0 && (
            <div>
                <div className="grid">
                    <div className="col col--12">
                        <h3
                            aria-level="3"
                            // We use tabIndex to forcefully navigate the
                            // user to the heading at the top of the saved
                            // payment methods list when a user switches tabs
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            tabIndex="0"
                            ref={savedPaymentMethodsTitleRef}
                        >
                            {savedPaymentMethodsSectionHeading}
                        </h3>
                        <ul className="grid">
                            {paymentInfo.map((payment) => (
                                <PaymentCard
                                    {...props}
                                    key={payment.id}
                                    payment={payment}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    );
};

StoredPaymentMethods.propTypes = {
    hasToggledPaymentMethodView: PropTypes.bool.isRequired,
    donorAccount: PropTypes.shape({
        paymentInfo: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    savedPaymentMethodsSectionHeading: PropTypes.string.isRequired,
};

export default StoredPaymentMethods;
