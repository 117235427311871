/* eslint-disable react/prop-types */
import React from "react";
import { constants } from "@qgiv/core-js";
import { getCountryNameFromShortCode } from "../../../utility";
import {
    getFormattedCost,
    getPackageCosts,
    getEventUrl,
    getEventStartDate,
    getEventVenue,
} from "./widgetEventListHelpers";
import WidgetEventsListItem from "./WidgetEventsListItem";

/**
 * @param {object} props - The component props.
 * @param {Array} props.activeEvents - The list of active events.
 * @param {string} props.currency - The currency symbol.
 * @param {object} props.eventListOptions - The options for displaying the event list.
 * @param {string} props.path - The path of the event.
 * @returns {React.ReactElement[]} - The rendered list of events.
 */
const WidgetEventsList = ({
    activeEvents,
    currency,
    eventListOptions,
    path,
}) => {
    const iconProps = {
        type: "FontAwesome",
        classNames: ["widget-events__icon"],
    };
    const {
        ENUMS: { FormStatusType },
    } = constants;

    const {
        buttonText = "Get Tickets",
        buttonTextPastEvents = "See Details",
        displayButtonIcon = 1,
        displayEventDates = 1,
        displayEventPrice = 1,
        displayEventVenues = 1,
        priceFreeText = "Free",
    } = eventListOptions;

    return activeEvents.map(
        ({
            id: eventId,
            name: eventName,
            e_date_begin,
            location,
            city,
            state,
            country,
            packages,
            alias,
            formStatusType,
            isStandardEvent,
            isUsingExternalEventPage,
            externalEventPageUrl,
        }) => {
            const countryName = getCountryNameFromShortCode(country);
            const venue = getEventVenue([location, city, state, countryName]);

            const packageCosts = getPackageCosts(packages);

            const minCost = Math.min(...packageCosts);
            const maxCost = Math.max(...packageCosts);
            const allPackagesAreFree = packageCosts.every(
                (item) => Number(item) === 0,
            );
            const formattedCost = getFormattedCost(minCost, maxCost, currency);

            const costString = `${
                allPackagesAreFree ? priceFreeText : formattedCost
            }`;

            const { eventStartDate, formattedEventStartDate } =
                getEventStartDate(e_date_begin);

            const registrationNotAvailable =
                Number(formStatusType) !==
                FormStatusType.REGISTRATION_AVAILABLE;

            const eventUrl = getEventUrl(
                path,
                isStandardEvent,
                alias,
                eventId,
                isUsingExternalEventPage,
                externalEventPageUrl,
            );

            const btnStyle = registrationNotAvailable ? "secondary" : "primary";

            const handleEventButtonClick = () => {
                window.open(eventUrl, "_blank", "noopener", "noreferrer");
            };

            const shouldDisplayEventDates =
                displayEventDates === 1 &&
                eventStartDate &&
                eventStartDate.isValid();

            const shouldDisplayVenue =
                displayEventVenues === 1 && venue.length > 1;

            const shouldDisplayCost = displayEventPrice === 1;

            const shouldDisplayButtonIcon =
                displayButtonIcon === 1 && !registrationNotAvailable;

            const buttonLabel = registrationNotAvailable
                ? buttonTextPastEvents
                : buttonText;

            const widgetEventsListItemNameProps = { eventName, eventUrl };
            const widgetEventsListItemDateProps = {
                shouldDisplayEventDates,
                iconProps,
                formattedEventStartDate,
            };
            const widgetEventsListItemVenueProps = {
                iconProps,
                venue,
                shouldDisplayVenue,
            };
            const widgetEventsListItemCostProps = {
                iconProps,
                costString,
                shouldDisplayCost,
            };
            const widgetEventsListItemButtonProps = {
                btnStyle,
                handleEventButtonClick,
                shouldDisplayButtonIcon,
                buttonLabel,
            };
            return (
                <WidgetEventsListItem
                    key={eventId}
                    eventId={eventId}
                    widgetEventsListItemNameProps={
                        widgetEventsListItemNameProps
                    }
                    widgetEventsListItemDateProps={
                        widgetEventsListItemDateProps
                    }
                    widgetEventsListItemVenueProps={
                        widgetEventsListItemVenueProps
                    }
                    widgetEventsListItemCostProps={
                        widgetEventsListItemCostProps
                    }
                    widgetEventsListItemButtonProps={
                        widgetEventsListItemButtonProps
                    }
                />
            );
        },
    );
};

export default WidgetEventsList;
