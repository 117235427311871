import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const SEO = ({ title, description }) => {
    // -------------------------------------------------------------------------
    // TODO: Need to figure out how to get socialSharingImage
    // -------------------------------------------------------------------------
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* Open Graph Protocol */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/* <meta property="og:url" content={url} /> */}
            {/* <meta property="og:image" content={socialSharingImage} /> */}
            {/* <meta property="og:image:width" content="1500" />
            <meta property="og:image:height" content="1000" /> */}
            {/* Twitter Card Protocol */}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {/* <meta name="twitter:image" content={socialSharingImage} /> */}
            {/* <meta name="twitter:card" content="summary_large_image" /> */}
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default SEO;
