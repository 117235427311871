import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import { useFormikContext } from "formik";
import DetailsPageContent from "./DetailsPageContent";
import { selectCmsContainersByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { updateDonorDetails } from "../../../../redux/slices/donationDetailsSlice";

const ConnectedDetailsPageContent = ({ displayCaptcha }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DETAILS },
        },
    } = constants;
    const { values } = useFormikContext();
    const { Company_Donation = false } = values;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateDonorDetails({ Company_Donation }));
    }, [Company_Donation, dispatch]);

    const containers = useSelector((state) =>
        selectCmsContainersByPage(state, DETAILS),
    );

    return (
        <DetailsPageContent
            containers={containers}
            displayCaptcha={displayCaptcha}
        />
    );
};

ConnectedDetailsPageContent.propTypes = {
    displayCaptcha: PropTypes.bool.isRequired,
};

export default ConnectedDetailsPageContent;
