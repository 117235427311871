import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { SystemFieldGroupPrivacyOptions } from "@qgiv/core-donor/source/components/QField";
import { selectAllSystemFields } from "../../../../redux/slices/fieldsSlice";
import { selectCurrentDisplay } from "../../../../redux/slices/configSettingsSlice";
import { selectAllEventSettings } from "../../../../redux/slices/eventSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectSelectedRecipient } from "../../../../redux/slices/donationDetailsSlice";

const ConnectedSystemFieldGroupPrivacyOptions = ({ controlId }) => {
    const {
        ENUMS: { EntityType, CmsPageStandardizer },
    } = constants;

    const allFieldsAndGroups = useSelector(selectAllSystemFields);
    const currentDisplay = useSelector(selectCurrentDisplay);

    const selectedRecipient = useSelector(selectSelectedRecipient);

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(
            state,
            controlId,
            CmsPageStandardizer.DETAILS,
        ),
    );
    const {
        options: { displayHeading = true, heading = "Privacy Options" },
    } = control;

    const headingProps = { displayHeading, heading };
    const { displayAnonymousDonorInformation = false } = useSelector(
        selectAllEventSettings,
    );

    const privacyFieldGroup = allFieldsAndGroups.find(
        (field) =>
            Number(field.type) ===
            EntityType.SYSTEM_FIELD_GROUP_PRIVACY_OPTIONS,
    );

    const { handleChange, handleBlur, values, errors, setFieldValue, touched } =
        useFormikContext();

    const systemFieldGroupPrivacyOptionsProps = {
        privacyFieldGroup,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        values,
        settings: {
            allFieldsAndGroups,
            config: { currentDisplay },
            displayAnonymousDonorInformation,
        },
        headingProps,
        selectedRecipient,
    };

    return (
        <SystemFieldGroupPrivacyOptions
            {...systemFieldGroupPrivacyOptionsProps}
        />
    );
};

ConnectedSystemFieldGroupPrivacyOptions.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedSystemFieldGroupPrivacyOptions;
