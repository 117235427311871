import { useState, useEffect } from "react";

// Hook
let cachedStylesheets = [];

/**
 * @public
 * @function useStylesheet
 * @param {string} src The source url for a stylesheet
 * @returns {Array} Array of loaded and error stateful vars
 */
export default function useStylesheet(src) {
    // Keeping track of stylesheet loaded and error state
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });

    useEffect(() => {
        // If cachedStylesheets array already includes src that means another instance ...
        // ... of this hook already loaded this stylesheet, so no need to load again.
        if (cachedStylesheets.includes(src)) {
            setState({
                loaded: true,
                error: false,
            });
        } else {
            cachedStylesheets.push(src);

            // Create stylesheet
            let stylesheet = document.createElement("link");
            stylesheet.href = src;
            stylesheet.type = "text/css";
            stylesheet.rel = "stylesheet";

            // Stylsheet event listener callbacks for load and error
            const onStylesheetLoad = () => {
                setState({
                    loaded: true,
                    error: false,
                });
            };

            const onStylesheetError = () => {
                // Remove from cachedStylesheets we can try loading again
                const index = cachedStylesheets.indexOf(src);
                if (index >= 0) cachedStylesheets.splice(index, 1);
                stylesheet.remove();

                setState({
                    loaded: true,
                    error: true,
                });
            };

            stylesheet.addEventListener("load", onStylesheetLoad);
            stylesheet.addEventListener("error", onStylesheetError);

            // Add stylesheet to document body
            document.body.appendChild(stylesheet);

            // Remove event listeners on cleanup
            return () => {
                stylesheet.removeEventListener("load", onStylesheetLoad);
                stylesheet.removeEventListener("error", onStylesheetError);
            };
        }
    }, [src]); // Only re-run effect if stylesheet src changes

    return [state.loaded, state.error];
}
