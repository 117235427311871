import React from "react";
import PropTypes from "prop-types";

const OtherAmountError = ({ displayOtherAmountError = false, message = "" }) =>
    displayOtherAmountError ? (
        <div className="col col--12 -text--center">
            <p className="-color--system-error">
                <strong>{message}</strong>
            </p>
        </div>
    ) : null;

OtherAmountError.propTypes = {
    displayOtherAmountError: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
};

export default OtherAmountError;
