import {
    constants,
    translatePaymentStatus,
    translatePaymentType,
} from "@qgiv/core-js";
import { getHasValidCustomFieldValue } from "../fieldHelpers";
import { getShouldRenderMultiRestriction } from "../multiRestrictionHelpers";
import { getActiveDisplayableRestrictions } from "../standardRestrictionsHelpers";
import {
    getGivenAnonymously,
    getMultiRestrictionOptions,
    getStandardRestrictionOptions,
} from "./getDataLayerOptionsHelpers";

// -------------------------------------------------------------------------
// TODO:
// 1. Confirm that the getCustomFieldsOptions() function behaves as
// expected once conditional logic has been added to custom fields & update
// the function if necessary.
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// NOTE: The data that is being generated by these functions should match
// the data that is specified by the following document in Notion:
// https://www.notion.so/bloomerang/JavaScript-API-for-the-QGIV-Donation-Form-17cfcd61c0fe4f87bf5b17bb0969ac05
// Because clients who use our enterprise analytics system are relying on
// this data in order for their analytics integrations to work, any changes
// to this data needs to be communicated with other team members members so
// that this information can be passed along to clients.
// -------------------------------------------------------------------------

const {
    ENUMS: { DisplayOn },
} = constants;

/**
 * @typedef {import("@qgiv/donation-form").SelectorTypes.Fields.DetailsPageFieldsData} DetailsPageFieldsData
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationDetails.DonorDetails} DonorDetails
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FormSettings.FormSettings} FormSettings
 * @typedef {import("@qgiv/donation-form").UtilityTypes.DataLayer.Contact} Contact
 */

/**
 *
 * @param {object} options
 * @param {DetailsPageFieldsData} options.detailsPageFieldsData
 * @param {DonorDetails} options.donorDetails
 * @param {FormSettings} options.formSettings
 * @returns {Contact|{}}
 */
export const getContactOptions = ({
    detailsPageFieldsData,
    donorDetails,
    formSettings,
}) => {
    const hasDonorDetails = Object.keys(donorDetails).length > 0;
    const hasDetailsPageFieldsData =
        Object.keys(detailsPageFieldsData).length > 0;
    let contactOptions = {};

    if (!hasDonorDetails && !hasDetailsPageFieldsData) {
        return contactOptions;
    }

    // Extract data from state
    const { defaultOptInChecked } = detailsPageFieldsData;

    // Assign values to remaining keys specified by documentation
    // Need to pay attention to the default value for the opt in field as
    // is the only field here whose value can actually be checked by default.
    // Other than that the default values of all of the remaining fields cannot
    // be modified once they are active.
    const defaultOptInValue =
        !!defaultOptInChecked.exists && !!defaultOptInChecked.checkedByDefault;
    const {
        Company = "",
        Email = "",
        First_Name = "",
        Last_Name = "",
        Opt_In = defaultOptInValue,
    } = donorDetails;
    const company = Company;
    const email = Email;
    const firstName = First_Name;
    const givenAnonymously = getGivenAnonymously(donorDetails, formSettings);
    const lastName = Last_Name;
    const optedIn = Opt_In;

    contactOptions = {
        company,
        email,
        firstName,
        givenAnonymously,
        lastName,
        optedIn,
    };

    return contactOptions;
};

/**
 *
 * @param {object} customFields
 * @param {Array} allDisplayableCustomFields
 * @returns {object}
 */
export const getCustomFieldsOptions = (
    customFields = {},
    allDisplayableCustomFields = [],
) => {
    const customFieldsOptions = {};
    const hasCustomFields = Object.keys(customFields).length > 0;

    if (!hasCustomFields) {
        return customFieldsOptions;
    }

    const idsOfCustomFields = Object.keys(customFields);

    // Add a key to the payload where that contains the value of the custom
    // field if the custom field has been filled out by the donor. Includes a
    // guard case that doesn't try to access data on this object as not doing
    // this could result in a fatal error
    const addCustomFieldValue = (idOfCustomField) => {
        const value = customFields[idOfCustomField];
        const customField =
            (Array.isArray(allDisplayableCustomFields) &&
                allDisplayableCustomFields.find(
                    (displayableCustomField) =>
                        displayableCustomField.id === Number(idOfCustomField),
                )) ||
            {};

        if (customField && customField.ref) {
            const reportingLabel = customField.ref;
            const hasValue = getHasValidCustomFieldValue(customField, value);

            if (hasValue) {
                customFieldsOptions[reportingLabel] = value;
            }
        }
    };

    idsOfCustomFields.forEach(addCustomFieldValue);

    return customFieldsOptions;
};

/**
 *
 * @param {object} dedication
 * @param {object} dedicationSettings
 * @returns {object}
 */
export const getDedicationOptions = (
    dedication = {},
    dedicationSettings = {},
) => {
    let dedicationOptions = {};

    const hasDedications =
        Object.keys(dedication).length > 0 && dedication.Has_Dedication;
    const hasDedicationsSettings = Object.keys(dedicationSettings).length > 0;

    if (!hasDedications || !hasDedicationsSettings) {
        return dedicationOptions;
    }

    // Extract data from state
    const { dedications = [], fields } = dedicationSettings;
    const {
        Dedication_Email = "",
        Dedication_Name = "",
        Dedication_Type = "",
    } = dedication;
    const selectedDedication =
        Array.isArray(dedications) &&
        dedications.find(
            (individualDedicationSettings) =>
                individualDedicationSettings.id === Number(Dedication_Type),
        );

    /**
     * @description If the dedication field has a value, add the reporting
     * label and the value for the dedication field to the dedication options
     * object
     * @param {object} dedicationField
     * @returns {undefined}
     */
    const addDedicationFieldValue = (dedicationField) => {
        const reportingLabel = dedicationField.ref;
        const value = dedication[dedicationField.fieldNamePath];

        if (value) {
            dedicationOptions[reportingLabel] = value;
        }
    };

    // Assign values to remaining keys specified by documentation
    const dedicationRecipientsEmail = Dedication_Email;
    const name = Dedication_Name;
    const type = selectedDedication ? selectedDedication.text : "";

    // Dedications always have a name and a type
    dedicationOptions = {
        name,
        type,
    };

    // Conditionally add the notification email if it is present
    if (dedicationRecipientsEmail) {
        dedicationOptions = {
            ...dedicationOptions,
            dedicationRecipientsEmail,
        };
    }

    // Extract the reporting label & values for all of the dedications fields
    // and add that data to the dedication options object. The objects that contain
    // this data are the same shape as the objects that are used to store the
    // custom fields data
    if (Array.isArray(fields) && fields.length > 0) {
        fields.forEach(addDedicationFieldValue);
    }

    return dedicationOptions;
};

/**
 *
 * @param {object} receipt
 * @returns {object}
 */
export const getEcommerceOptions = (receipt = {}) => {
    const hasReceipt = Object.keys(receipt).length > 0;
    let ecommerceOptions = {};

    if (!hasReceipt) {
        return ecommerceOptions;
    }

    // Extract data from state
    const { transaction: rawTransaction = {} } = receipt;
    const { id = "", merchantAccount = {} } = rawTransaction;
    const { currency = "USD" } = merchantAccount;

    // Assign values to remaining keys specified by documentation
    // Only has a single item at this point
    const items = [
        {
            item_name: rawTransaction.formName,
            price: Number(rawTransaction.amount),
        },
    ];
    const transaction_id = id;
    const value = Number(rawTransaction.amount);

    ecommerceOptions = {
        currency,
        items,
        transaction_id,
        value,
    };

    return ecommerceOptions;
};

/**
 *
 * @param {object} formSettings
 * @returns {object}
 */
export const getFormOptions = (formSettings = {}) => {
    // Extract data from state
    const { id: idAsNumber = 0, title = "" } = formSettings;

    // Assign values to remaining keys specified by documentation
    const id = String(idAsNumber);
    const name = title;

    const formOptions = {
        id,
        name,
    };

    return formOptions;
};

/**
 *
 * @param {object} options
 * @param {object} options.config
 * @param {object} options.donationSettings
 * @param {object} options.giftDetails
 * @param {object} options.restrictionSettings
 * @param {object} options.smsData
 * @returns {object}
 */
export const getRestrictionsOptions = ({
    config = {},
    donationSettings = {},
    giftDetails = {},
    restrictionSettings = {},
    smsData = {},
}) => {
    const { currentDisplay = DisplayOn.FRONTEND } = config;
    const { restrictions = [] } = restrictionSettings;
    const hasGiftDetails = Object.keys(giftDetails).length > 0;
    const hasActiveDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    let restrictionsOptions = {};

    if (!hasGiftDetails || !hasActiveDisplayableRestrictions) {
        return restrictionsOptions;
    }

    // Extract data from state
    const { restrictionGivingType = "" } = donationSettings;
    const shouldRenderMultiRestrictionGiving = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    // Assign values to remaining keys specified by documentation
    if (!shouldRenderMultiRestrictionGiving) {
        restrictionsOptions = getStandardRestrictionOptions({
            giftDetails,
        });
    }

    if (shouldRenderMultiRestrictionGiving) {
        restrictionsOptions = getMultiRestrictionOptions({
            config,
            donationSettings,
            giftDetails,
            restrictionSettings,
        });
    }

    return restrictionsOptions;
};

/**
 *
 *  @param {object} receipt
 * @returns {object}
 */
export const getTransactionOptions = (receipt = {}) => {
    const hasReceipt = Object.keys(receipt).length > 0;
    let transactionOptions = {};

    if (!hasReceipt) {
        return transactionOptions;
    }

    // Extract data from state
    const { feeCoverage = null, transaction = {} } = receipt;
    const { id = "", merchantAccount = {} } = transaction;
    const { currency = "USD" } = merchantAccount;

    // Assign values to remaining keys specified by documentation
    const associatedInfo = transaction.assocInfo;
    const date = transaction.tm_stamp;
    const demo = transaction.demo === "y";
    // When a donation is a one time donation the recurring key is set to null
    const frequency =
        (transaction.recurring &&
            transaction.recurring.frequency_description) ||
        "One Time";
    const giftAssist = Number(feeCoverage);
    const paymentMethod = translatePaymentType(
        transaction.ccType,
        transaction.channel,
    );
    const recurring = !!transaction.recurring;
    const status = translatePaymentStatus(transaction.status);
    const timestamp = String(transaction.tm_stamp_unix);
    const total = Number(transaction.amount);
    const type = transaction.company ? "company" : "individual";

    transactionOptions = {
        associatedInfo,
        currency,
        date,
        demo,
        frequency,
        giftAssist,
        id,
        paymentMethod,
        recurring,
        status,
        timestamp,
        total,
        type,
    };

    return transactionOptions;
};

/**
 *
 * @param {object} options
 * @param {Array} options.allDisplayableCustomFields
 * @param {object} options.config
 * @param {object} options.dedicationSettings
 * @param {object} options.detailsPageFieldsData
 * @param {object} options.donationDetails
 * @param {object} options.donationSettings
 * @param {object} options.formSettings
 * @param {object} options.restrictionSettings
 * @param {object} options.receipt
 * @param {object} options.smsData
 * @returns {object}
 */
export const getDataLayerOptions = ({
    allDisplayableCustomFields = [],
    config = {},
    dedicationSettings = {},
    detailsPageFieldsData = {},
    donationDetails = {},
    donationSettings = {},
    formSettings = {},
    receipt = {},
    restrictionSettings = {},
    smsData = {},
}) => {
    const defaultOptions = {
        contact: {},
        ecommerce: {},
        form: {},
        transaction: {},
        utm: {},
    };
    let dataLayerOptions = {};
    const {
        customFields = {},
        dedication = {},
        donorDetails = {},
        giftDetails = {},
    } = donationDetails;

    // Gather data from helper functions
    const contactOptions = getContactOptions({
        detailsPageFieldsData,
        donorDetails,
        formSettings,
    });
    const customFieldsOptions = getCustomFieldsOptions(
        customFields,
        allDisplayableCustomFields,
    );
    const dedicationOptions = getDedicationOptions(
        dedication,
        dedicationSettings,
    );
    const ecommerceOptions = getEcommerceOptions(receipt);
    const formOptions = getFormOptions(formSettings);
    const restrictionsOptions = getRestrictionsOptions({
        config,
        donationSettings,
        giftDetails,
        restrictionSettings,
        smsData,
    });
    const transactionOptions = getTransactionOptions(receipt);

    // Assemble data into final payload
    dataLayerOptions = {
        ...defaultOptions,
        contact: contactOptions,
        customFields: customFieldsOptions,
        dedication: dedicationOptions,
        ecommerce: ecommerceOptions,
        form: formOptions,
        restrictions: restrictionsOptions,
        transaction: transactionOptions,
    };

    return dataLayerOptions;
};
