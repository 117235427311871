/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FormSettings.FormSettings} FormSettings
 * @typedef {import("@qgiv/donation-form").ReduxTypes.SMSData.SMSData} SMSData
 */
/**
 * @param {object} params
 * @param {string} params.currentDisplay
 * @param {object} params.dedicationSettings
 * @param {object} params.donationSettings
 * @param {FormSettings} params.formSettings
 * @param {boolean} params.Has_Dedication
 * @param {boolean} params.hasDisplayableDedicationOnThisPage
 * @param {boolean} params.hasSelectedRecurringDonation
 * @param {object} params.restrictions
 * @param {string} params.currentPage
 * @param {SMSData} params.smsData
 * @param {object} params.storedFormikGiftDetails
 * @returns {object}
 */
export const buildGiftPageFormikSettings = ({
    currentDisplay,
    dedicationSettings,
    donationSettings,
    formSettings,
    Has_Dedication,
    hasDisplayableDedicationOnThisPage,
    hasSelectedRecurringDonation,
    restrictions,
    currentPage,
    smsData,
    storedFormikGiftDetails,
}) => {
    const initialTouchedSettings = {};

    const initialValuesSettings = {
        currentDisplay,
        currentPage,
        dedicationSettings,
    };

    const validationSettings = {
        currentDisplay,
        dedicationSettings,
        donationSettings,
        formSettings,
        Has_Dedication,
        hasDisplayableDedicationOnThisPage,
        hasSelectedRecurringDonation,
        restrictions,
        smsData,
        storedFormikGiftDetails,
    };
    const formikSettings = {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    };

    return formikSettings;
};

/**
 * @param {object} params
 * @param {Array} params.allFieldsAndGroups
 * @param {Array} params.allFieldsInSystemFields
 * @param {boolean} params.allowOnlyCompanyContributions
 * @param {object} params.conditionalLogicOptions
 * @param {string} params.currentDisplay
 * @param {string} params.currentPage
 * @param {object} params.dedicationSettings
 * @param {Array} params.detailsPageCustomFields
 * @param {object} params.detailsPageFieldsData
 * @param {object} params.donorAccount
 * @param {boolean} params.enableMatching
 * @param {FormSettings} params.formSettings
 * @param {boolean} params.hasActiveFulfillment
 * @param {boolean} params.Has_Dedication
 * @param {boolean} params.hasDisplayableDedicationOnThisPage
 * @param {boolean} params.hasDisplayableMatchingGiftOnThisPage
 * @param {boolean} params.hideZeroDollarMailingAddress
 * @param {number} params.matchingRatio
 * @param {string} params.matchingType
 * @param {object} params.promiseTransaction
 * @param {boolean} params.requireNameForCompanyDonation
 * @param {object} params.savedValues
 * @param {boolean} params.shouldCaptchaBeRequired
 * @param {number} params.subtotal
 * @param {Array} params.systemFields
 * @param {number} params.total
 * @param {object} params.urlShortcuts
 * @returns {object}
 */
export const buildDonorDetailsPageFormikSettings = ({
    allFieldsAndGroups,
    allFieldsInSystemFields,
    allowOnlyCompanyContributions,
    conditionalLogicOptions,
    currentDisplay,
    currentPage,
    dedicationSettings,
    detailsPageCustomFields,
    detailsPageFieldsData,
    donorAccount,
    enableMatching,
    formSettings,
    hasActiveFulfillment,
    Has_Dedication,
    hasDisplayableDedicationOnThisPage,
    hasDisplayableMatchingGiftOnThisPage,
    hideZeroDollarMailingAddress,
    matchingRatio,
    matchingType,
    promiseTransaction,
    requireNameForCompanyDonation,
    savedValues,
    shouldCaptchaBeRequired,
    subtotal,
    systemFields,
    total,
    urlShortcuts,
}) => {
    // Arguments that are passed into the functions that set up the initial
    // touched, initial values and validation logic in Formik
    const initialTouchedSettings = {};
    const initialValuesSettings = {
        allowOnlyCompanyContributions,
        currentDisplay,
        currentPage,
        dedicationSettings,
        detailsPageCustomFields,
        detailsPageFieldsData,
        donorAccount,
        enableMatching,
        formSettings,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        matchingType,
        savedValues,
        systemFields,
        urlShortcuts,
        hasActiveFulfillment,
        promiseTransaction,
    };

    // -------------------------------------------------------------------------
    // TODO: use isVisible check here with savedValues for conditional logic on custom fields
    // -------------------------------------------------------------------------
    const visibleDetailsPageCustomFields = [...detailsPageCustomFields];

    const validationSettings = {
        allFieldsAndGroups,
        allFieldsInSystemFields,
        conditionalLogicOptions,
        currentDisplay,
        dedicationSettings,
        detailsPageFieldsData,
        enableMatching,
        Has_Dedication,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        hideZeroDollarMailingAddress,
        matchingRatio,
        requireNameForCompanyDonation,
        shouldCaptchaBeRequired,
        subtotal,
        total,
        visibleDetailsPageCustomFields,
    };
    const formikSettings = {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    };

    return formikSettings;
};

/**
 * @param {object} params
 * @param {Array} params.additionalDetailsCustomFields
 * @param {Array} params.allFieldsAndGroups
 * @param {object} params.conditionalLogicOptions
 * @param {string} params.currentDisplay
 * @param {string} params.currentPage
 * @param {object} params.dedicationSettings
 * @param {boolean} params.enableMatching
 * @param {boolean} params.Has_Dedication
 * @param {boolean} params.hasDisplayableDedicationOnThisPage
 * @param {boolean} params.hasDisplayableMatchingGiftOnThisPage
 * @param {number} params.matchingRatio
 * @param {string} params.matchingType
 * @param {object} params.savedValues
 * @param {boolean} params.shouldCaptchaBeRequired
 * @param {number} params.subtotal
 * @param {number} params.total
 * @returns {object}
 */
export const buildAdditionalDetailsPageFormikSettings = ({
    additionalDetailsCustomFields,
    allFieldsAndGroups,
    conditionalLogicOptions,
    currentDisplay,
    currentPage,
    dedicationSettings,
    enableMatching,
    Has_Dedication,
    hasDisplayableDedicationOnThisPage,
    hasDisplayableMatchingGiftOnThisPage,
    matchingRatio,
    matchingType,
    savedValues,
    shouldCaptchaBeRequired,
    subtotal,
    total,
}) => {
    // Arguments that are passed into the functions that set up the initial
    // touched, initial values and validation logic in Formik
    const initialTouchedSettings = {};
    const initialValuesSettings = {
        additionalDetailsCustomFields,
        enableMatching,
        matchingType,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        currentDisplay,
        currentPage,
        dedicationSettings,
    };

    const validationSettings = {
        additionalDetailsCustomFields,
        allFieldsAndGroups,
        conditionalLogicOptions,
        currentDisplay,
        dedicationSettings,
        Has_Dedication,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        matchingRatio,
        savedValues,
        shouldCaptchaBeRequired,
        subtotal,
        total,
    };
    const formikSettings = {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    };

    return formikSettings;
};

/**
 * @param {object} params
 * @param {string} params.billingDefaultCountry
 * @param {object} params.captcha
 * @param {Array} params.displayableBillingFields
 * @param {object} params.displayNameFieldData
 * @param {object} params.donorAccount
 * @param {boolean} params.enableDonorLogins
 * @param {boolean} params.hasSelectedRecurringDonation
 * @param {boolean} params.isLoggedIn
 * @param {object} params.mailingAddressFieldsData
 * @param {object} params.paymentData
 * @param {number} params.total
 * @param {object} params.VisaAmexDisc
 * @returns {object}
 */
export const buildPaymentPageFormikSettings = ({
    billingDefaultCountry,
    captcha,
    displayableBillingFields,
    displayNameFieldData,
    donorAccount,
    enableDonorLogins,
    hasSelectedRecurringDonation,
    isLoggedIn,
    mailingAddressFieldsData,
    paymentData,
    total,
    VisaAmexDisc,
}) => {
    // Arguments that are passed into the functions that set up the initial
    // touched, initial values and validation logic in Formik
    const initialTouchedSettings = {};
    const initialValuesSettings = {
        billingDefaultCountry,
        captcha,
        donorAccount,
        enableDonorLogins,
        hasSelectedRecurringDonation,
        isLoggedIn,
        mailingAddressFieldsData,
        ...paymentData,
    };

    const validationSettings = {
        VisaAmexDisc,
        displayNameFieldData,
        displayableBillingFields,
        mailingAddressFieldsData,
        shouldCaptchaBeRequired: !!captcha,
        total,
    };

    const formikSettings = {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    };

    return formikSettings;
};
