import { constants, currencyString } from "@qgiv/core-js";
import { calcGiftAssistFee } from "@qgiv/core-donor";
import { getSelectedAmount } from "../amountHelpers";
import {
    getMultiRestrictionFieldName,
    getOneTimeSubtotalForRestriction,
    getSelectedRestrictions,
} from "../multiRestrictionHelpers";
import {
    getActiveDisplayableRestrictions,
    getRestrictionIds,
} from "../standardRestrictionsHelpers";
import * as RecurringUpgradeTypes from "./recurringUpgradeHelperTypes";

const {
    ENUMS: { FormAppearance, PaymentType },
} = constants;

/**
 * @function getSubtotalIsLessThanLimitAmount
 * @description helper function to evaluate total vs. recurringCtaLimitAmount
 * @param {object} settings argument for function
 * @param {number} settings.recurringCtaLimitAmount value from donationSettings for cta limit
 * @param {number} settings.subtotal subtotal from donation details slice
 * @returns {boolean} totalIsLessThanLimitAmount
 */
export const getSubtotalIsLessThanLimitAmount = ({
    recurringCtaLimitAmount = 0,
    subtotal = 0,
}) => subtotal < recurringCtaLimitAmount;

/**
 * @function getShouldDisplayRecurringUpgrade
 * @description helper function for getting display value for recurring upgrade before submission
 * @param {object} settings argument for function
 * @param {boolean} settings.enableRecur value for form allowing recurring donations
 * @param {boolean} settings.enableRecurringCtaLimit value for using eval against total donation for using cta
 * @param {boolean} settings.hasActiveFulfillment value from promiseTransaction for active fulfillment
 * @param {boolean} settings.hasSelectedRecurringDonation value from donationDetails for selected recurring donation
 * @param {boolean} settings.recurringCtaAfter value from donationSettings for basic display of recurring upgrade
 * @param {number} settings.recurringCtaLimitAmount value from donationSettings for cta limit
 * @param {number} settings.subtotal subtotal from donation details slice
 * @returns {boolean} shouldDisplayRecurringUpgrade
 */
export const getShouldDisplayRecurringUpgrade = ({
    enableRecur,
    enableRecurringCtaLimit = false,
    hasActiveFulfillment = false,
    hasSelectedRecurringDonation = false,
    recurringCtaAfter = false,
    recurringCtaLimitAmount = 0,
    subtotal = 0,
}) => {
    // early returns when amount eval is not necessary or selected recurring donation already
    if (
        !recurringCtaAfter ||
        !enableRecur ||
        hasSelectedRecurringDonation ||
        hasActiveFulfillment
    )
        return false;
    if (recurringCtaAfter && !enableRecurringCtaLimit) return true;
    // amount eval
    return getSubtotalIsLessThanLimitAmount({
        recurringCtaLimitAmount,
        subtotal,
    });
};

/**
 * @function getPaymentTypeAllowsRecurringUpgrade
 * @description Determines if the payment type allows for a recurring upgrade.
 * @param {number} Payment_Type - The type of payment method used.
 * @returns {boolean} True if the payment type allows for a recurring upgrade, false otherwise.
 */
export const getPaymentTypeAllowsRecurringUpgrade = (Payment_Type = 0) =>
    Payment_Type !== PaymentType.APPLEPAY &&
    Payment_Type !== PaymentType.PAYPAL;

/**
 * @function getFormattedRecurringUpgradeTextWithFirstName
 * @description helper function that replaces recurring upgrade texts' %NAME% tag with First_Name value
 * @param {object} settings argument for function
 * @param {string} settings.First_Name First_Name field value from redux or formik
 * @param {string} settings.cmsOptionText cms control option for recurring upgrade texts
 * @returns {string} formattedRecurringUpgradeText
 */
export const getFormattedRecurringUpgradeTextWithFirstName = ({
    First_Name = "",
    cmsOptionText = "",
}) => {
    // The previous version of the upgrade modal had a similar replace use case for the additional text
    // and the solution was over-engineered to include situations where the First_Name value would not
    // exist. That is not currently an issue that we are considering in the One Form, Name fields are
    // always visible and required. If that changes, we would have to implement a similar complicated solution
    // like the one found in PaymentStepUpgradeModal for getAdditionalText.
    const formattedRecurringUpgradeText = cmsOptionText.replace(
        "%NAME%",
        First_Name,
    );

    return formattedRecurringUpgradeText;
};

/**
 * @function getFirstNameValue
 * @description helper function will return the First_Name value based on form appearance. Multi-step forms
 * will look for that value in redux, single-step forms will look for that value in formik values
 * @param {object} settings argument for function
 * @param {number} settings.appearance form settings value for appearance
 * @param {object} settings.donorDetails donor details bucket from donationDetails slice
 * @param {object} settings.values formik values from useFormikContext
 * @returns {string} First_Name
 */
export const getFirstNameValue = ({
    appearance = FormAppearance.MULTIPLE,
    donorDetails = {},
    values = {},
}) => {
    const isMultiStepForm = appearance === FormAppearance.MULTIPLE;
    if (isMultiStepForm) {
        const { First_Name = "" } = donorDetails;
        return First_Name;
    }
    const { First_Name = "" } = values;
    return First_Name;
};

/**
 * @function getFieldRecurringFrequencyLabelPerAmount
 * @description helper function that returns the label per amount for a given donation frequency value
 * @param {object} params - The parameters for the function.
 * @param {string} params.value - The value of the donation frequency to find.
 * @param {object[]} params.activeDonationFrequencies - The list of active donation frequencies.
 * @returns {string|undefined} The label per amount for the specified donation frequency value, or undefined if not found.
 */
export const getFieldRecurringFrequencyLabelPerAmount = ({
    value = "",
    activeDonationFrequencies = [],
}) => {
    const activeFrequency =
        activeDonationFrequencies.find(
            (frequency) => frequency.value === value,
        ) || {};
    const activeFrequencyLabelPerAmount = activeFrequency?.labelPerAmount;
    return activeFrequencyLabelPerAmount;
};

/**
 * @function getAppendedFrequencyLabel
 * @description helper function that gets the appended frequency label for a given donation frequency value
 * @param {object} params - The parameters for the function.
 * @param {string} params.value - The value of the donation frequency to find.
 * @param {object[]} params.activeDonationFrequencies - The list of active donation frequencies.
 * @returns {string} The appended frequency label, or an empty string if not found.
 */
export const getAppendedFrequencyLabel = ({
    value = "",
    activeDonationFrequencies = [],
}) => {
    const fieldRecurringFrequencyLabelPerAmount =
        getFieldRecurringFrequencyLabelPerAmount({
            value,
            activeDonationFrequencies,
        });
    const appendedFrequencyLabel = fieldRecurringFrequencyLabelPerAmount
        ? `/${fieldRecurringFrequencyLabelPerAmount}`
        : "";
    return appendedFrequencyLabel;
};

export const getNewGiftAssistValue = ({
    feeCoverageSettings = {},
    amountToUseForRecurringUpgrade = 0,
    Gift_Assist = false,
}) => {
    if (Gift_Assist) {
        const giftAssistFeeString = calcGiftAssistFee(
            amountToUseForRecurringUpgrade,
            feeCoverageSettings,
        );
        const giftAssistFeeNumber = Number(giftAssistFeeString);
        return giftAssistFeeNumber;
    }
    return 0;
};

// -------------------------------------------------------------------------
// NOTE: We are getting the floor value of the percentage of the subtotal
// because we want to have whole numbers in the recurring upgrade button label
// The final result of this process means the returned value will often be less
// than the percentage of the subtotal. This is intentional.
// -------------------------------------------------------------------------

/**
 * @function getPercentOfAmount
 * @description helper function that calculates the floored percentage of a given subtotal.
 * @param {object} params - The parameters for the calculation.
 * @param {number} params.percentage - The percentage to apply.
 * @param {number} params.subtotal - The subtotal amount.
 * @returns {number} The floored amount after applying the percentage to the subtotal.
 */
export const getPercentOfAmount = ({ percentage = 0, subtotal = 0 }) => {
    const percentAsPercent = percentage * 0.01;
    const percentOfSubtotal = percentAsPercent * subtotal;
    const flooredAmount = Math.floor(percentOfSubtotal);
    return flooredAmount;
};

/**
 * @function getAmountToUseForRecurringUpgrade
 * @description Determines the amount to use for a recurring upgrade based on the floored amount and a minimum amount.
 * @param {object} params - The parameters for the calculation.
 * @param {number} params.flooredAmount - The floored amount calculated from the percentage of the subtotal.
 * @param {number} params.minAmt - The minimum amount to use if it is greater than the floored amount.
 * @returns {number} The amount to use for the recurring upgrade.
 */
export const getAmountToUseForRecurringUpgrade = ({
    flooredAmount = 0,
    minAmt = 0,
}) => {
    if (minAmt > 0 && minAmt > flooredAmount) {
        return minAmt;
    }
    return flooredAmount;
};

/**
 * @function getArrayFromSplitTextOnTag
 * @description Splits the given text on the specified tag and returns the text before and after the tag.
 * @param {object} params - The parameters for the function.
 * @param {string} params.tag - The tag to split the text on.
 * @param {string} params.text - The text to be split.
 * @returns {object} An object containing the text before and after the tag.
 */
export const getArrayFromSplitTextOnTag = ({ tag = "", text = "" }) => {
    if (!tag) {
        return { beforeTagTextFromSplit: text, afterTagTextFromSplit: "" };
    }
    const arrayFromSplitTextOnTag = text.split(tag);
    const [beforeTagTextFromSplit, afterTagTextFromSplit] =
        arrayFromSplitTextOnTag;
    return { afterTagTextFromSplit, beforeTagTextFromSplit };
};

/**
 * @function getUniqueUpgradeIdentifier
 * @description Generates a unique identifier for a recurring upgrade based on the new recurring frequency and subtotal amount.
 * @param {object} params - The parameters for the function.
 * @param {string} params.newRecurringFrequency - The new recurring frequency for the upgrade.
 * @param {number} params.newSubtotalAmount - The new subtotal amount for the upgrade.
 * @returns {string} A unique identifier string combining the new subtotal amount and the new recurring frequency. This aids
 * in removing duplicate amount and frequency combinations.
 */
export const getUniqueUpgradeIdentifier = ({
    newRecurringFrequency = "",
    newSubtotalAmount = 0,
}) => `${newSubtotalAmount}-${newRecurringFrequency}`;

/**
 * @function getAcceptUpgradeButtonLabelSections
 * @description Generates the sections of the upgrade button label based on the provided parameters.
 * @param {object} params - The parameters for the function.
 * @param {Array} params.activeDonationFrequencies - List of active donation frequencies.
 * @param {object} params.currency - Currency settings.
 * @param {object} params.feeCoverageSettings - Settings for fee coverage.
 * @param {object} params.field - Field information from recurringUpgradeModalFields.
 * @param {boolean} params.Gift_Assist - Indicates if gift assist is enabled.
 * @param {number} params.minAmt minimum amount that donation can be from donationSettings
 * @param {number} params.subtotal - Subtotal amount.
 * @param {string} params.upgradeButtonLabel - Cms control option for button label
 * @returns {RecurringUpgradeTypes.AcceptUpgradeButtonSectionData} upgradeButtonSectionData
 */
export const getAcceptUpgradeButtonLabelSections = ({
    activeDonationFrequencies = [],
    currency = {
        format: 0,
        symbolLocation: 0,
        symbol: "$",
    },
    feeCoverageSettings = {
        feeCoverage: 0,
        feeCoverageFlat: 0,
        feeCoveragePercentage: 0,
    },
    field = {},
    Gift_Assist = false,
    minAmt = 0,
    subtotal = 0,
    upgradeButtonLabel = "Yes! Give %AMOUNTWITHFREQUENCY% instead!",
}) => {
    const { frequency = "", id = 0, percentage = 0 } = field;

    // should be same for multi-restrictions
    const { beforeTagTextFromSplit, afterTagTextFromSplit } =
        getArrayFromSplitTextOnTag({
            tag: "%AMOUNTWITHFREQUENCY%",
            text: upgradeButtonLabel,
        });

    // This was a departure point for Multi-restrictions in PaymentStepUpgradeModal.js
    // it did not use Math.floor to round down the percentages.
    const flooredAmount = getPercentOfAmount({
        minAmt,
        percentage,
        subtotal,
    });

    const amountToUseForRecurringUpgrade = getAmountToUseForRecurringUpgrade({
        flooredAmount,
        minAmt,
    });

    const newGiftAssistAmount = getNewGiftAssistValue({
        amountToUseForRecurringUpgrade,
        feeCoverageSettings,
        Gift_Assist,
    });

    const newRecurringFrequency = frequency;
    const newSubtotalAmount = amountToUseForRecurringUpgrade;
    const newTotalAmount = newSubtotalAmount + newGiftAssistAmount;
    const formattedNewTotalAmount = currencyString(newTotalAmount, currency);

    // should be same for multi-restrictions
    const appendedFrequencyLabel = getAppendedFrequencyLabel({
        value: frequency,
        activeDonationFrequencies,
    });

    // should be same for multi-restrictions
    const uniqueUpgradeIdentifier = getUniqueUpgradeIdentifier({
        newRecurringFrequency,
        newSubtotalAmount,
    });

    return {
        afterTagTextFromSplit,
        appendedFrequencyLabel,
        beforeTagTextFromSplit,
        formattedNewTotalAmount,
        id,
        newGiftAssistAmount,
        newRecurringFrequency,
        newSubtotalAmount,
        newTotalAmount,
        uniqueUpgradeIdentifier,
    };
};

/**
 * @function getCancelUpgradeButtonLabelSections
 * @description helper function that splits the cancel button label text on the "%AMOUNT%" tag and returns the sections
 * for rendering parts of the component along with the formatted total amount.
 * @param {object} params - The parameters object.
 * @param {string} params.cancelButtonLabel - The label text for the cancel button, which includes the "%AMOUNT%" tag.
 * @param {string} params.formattedTotalAmount - The formatted total amount to be displayed.
 * @returns {RecurringUpgradeTypes.CancelUpgradeButtonSectionData} An object containing the sections of the label text split on the "%AMOUNT%" tag and the formatted total amount.
 */
export const getCancelUpgradeButtonLabelSections = ({
    cancelButtonLabel = "",
    formattedTotalAmount = "",
}) => {
    const { beforeTagTextFromSplit, afterTagTextFromSplit } =
        getArrayFromSplitTextOnTag({
            tag: "%AMOUNT%",
            text: cancelButtonLabel,
        });

    return {
        afterTagTextFromSplit,
        beforeTagTextFromSplit,
        formattedTotalAmount,
    };
};

/**
 *
 * @param {number} upgradeAmountAsDecimal
 * @param {number} subtotal
 * @returns {number}
 */
export const getNewSubtotalFromActualUpgradeDecimal = (
    upgradeAmountAsDecimal,
    subtotal,
) => {
    const rawRestrictionSubtotal = upgradeAmountAsDecimal * subtotal;
    const restrictionSubtotal = Number(rawRestrictionSubtotal.toFixed(2));

    return restrictionSubtotal;
};

/**
 *
 * @param {object} params
 * @param {object[]} params.activeAmounts
 * @param {number} params.currentDisplay
 * @param {object} params.giftDetails
 * @param {number} params.newSubtotalAmount
 * @param {object[]} params.restrictions
 * @returns {object}
 */
export const getMultiRestrictionFieldValuesOnUpgrade = ({
    activeAmounts,
    currentDisplay,
    giftDetails,
    newSubtotalAmount,
    restrictions,
}) => {
    const { subtotal } = giftDetails;

    // Generate data needed for new restriction amount calculations. Because we
    // present, clean, rounded values to the donor in the upgrade modal we need
    // to calculate new values based off the actual percentage as opposed to
    // the percentage shown to the org in the control panel
    const upgradeAmountAsDecimal = newSubtotalAmount / subtotal;
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    const selectedRestrictions = getSelectedRestrictions(
        activeDisplayableRestrictions,
        giftDetails,
    );
    const idsOfSelectedRestrictions = getRestrictionIds(selectedRestrictions);

    // Initialize values needed for new restriction amount calculations
    const multiRestrictionFieldValuesOnUpgrade = {};
    let subtotalRemaining = newSubtotalAmount;

    /**
     *
     * @param {number} id
     * @param {number} index
     * @returns {undefined}
     * @description Only modifies Other Amount in order to ensure we don't run
     * into any issues if the form does not have an other amount. The remaining
     * amount is always used for the  last amounts to ensure the new restriction
     * values add up to exactly the subtotal
     */
    const getNewMultiRestrictionFieldValue = (id, index) => {
        const multiRestrictionFieldName = getMultiRestrictionFieldName(id);
        const existingMultiRestrictionFieldValue =
            giftDetails[multiRestrictionFieldName];
        const isLastRestriction =
            index === idsOfSelectedRestrictions.length - 1;
        let restrictionSubtotal = 0;

        if (isLastRestriction) {
            restrictionSubtotal = subtotalRemaining;
        } else {
            const idOfSelectedAmount =
                existingMultiRestrictionFieldValue.Selected_One_Time_Id;
            const selectedAmount = getSelectedAmount(
                activeAmounts,
                idOfSelectedAmount,
            );
            const existingSubtotal = getOneTimeSubtotalForRestriction(
                existingMultiRestrictionFieldValue,
                selectedAmount,
            );
            restrictionSubtotal = getNewSubtotalFromActualUpgradeDecimal(
                upgradeAmountAsDecimal,
                existingSubtotal,
            );
            subtotalRemaining -= restrictionSubtotal;
        }

        const multiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            Other_Recurring_Amount: restrictionSubtotal,
        };

        multiRestrictionFieldValuesOnUpgrade[multiRestrictionFieldName] =
            multiRestrictionFieldValue;
    };

    idsOfSelectedRestrictions.forEach(getNewMultiRestrictionFieldValue);

    return multiRestrictionFieldValuesOnUpgrade;
};
