import React from "react";
import PropTypes from "prop-types";
import { usePopperTooltip } from "react-popper-tooltip";
import cx from "classnames";

import "./Tooltip.scss";

/**
 * @see https://github.com/mohsinulhaq/react-popper-tooltip
 */

const Tooltip = ({
    children,
    className,
    content,
    placement = "top",
    tooltipClassName,
    ...rest
}) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({ delayHide: 100, placement, ...rest });

    // TODO: This needs to be refactored as it causes to hang when the tooltip
    //      is open and the component re-renders.
    // -------------------------------------------------------------------------
    // NOTE The `aria-describedby` property links the hovered ref to the tooltip
    // (when it pops up) for screen readers. It is possible to have multiple
    // tooltips on the same page, so we need to generate a unique ID to pass to
    // the `aria-describedby` property and the tooltip id.
    // -------------------------------------------------------------------------
    /*
    const generateUniqueTitleId = useCallback(() => {
        const ranNum = Math.floor(Math.random() * 100000);
        return `tooltip-${ranNum}`;
    }, []);

    const titleId = generateUniqueTitleId();
    */

    return (
        <>
            <span
                /*
                TODO: This needs to be refactored as it causes to hang when the tooltip
                      is open and the component re-renders.
                aria-describedby={titleId}
                */
                className={cx("qg-vendor-tooltip-ref tooltip__ref", className)}
                ref={setTriggerRef}
            >
                {children}
            </span>

            {visible && (
                <div
                    /*
                    TODO: This needs to be refactored as it causes to hang when the tooltip
                         is open and the component re-renders.
                    id={titleId}
                    */
                    role="tooltip"
                    {...getTooltipProps({
                        className: cx(
                            "qg-vendor-tooltip tooltip__container",
                            tooltipClassName,
                        ),
                    })}
                    ref={setTooltipRef}
                >
                    {content}
                    <div {...getArrowProps({ className: "tooltip__arrow" })} />
                </div>
            )}
        </>
    );
};

Tooltip.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    placement: PropTypes.string,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    tooltipClassName: PropTypes.string,
};

export default Tooltip;
