import React from "react";
import PropTypes from "prop-types";
import DonationFormControl from "../DonationFormControl";

const Container = (props) => {
    const {
        container: { controls },
    } = props;

    return (
        <div className="qg-vendor-controls-container controls-container">
            {controls.map((_control) => (
                <DonationFormControl
                    {...props}
                    key={_control.id}
                    controlId={_control.id}
                    control={_control}
                />
            ))}
        </div>
    );
};

Container.propTypes = {
    container: PropTypes.shape({
        controls: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default Container;
