import React, { useEffect, useState } from "react";
import Injections from "./Injections";

const ConnectedInjections = () => {
    const [hasEnumsLoaded, setHasEnumsLoaded] = useState(false);

    useEffect(() => {
        setHasEnumsLoaded(true);
    }, []);

    return hasEnumsLoaded ? <Injections /> : null;
};

export default ConnectedInjections;
