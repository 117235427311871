import React from "react";
import PropTypes from "prop-types";

const WidgetSpacing = (props) => {
    const {
        control: {
            options: { height },
        },
    } = props;
    const style = {
        height: `${height}px`,
        width: "100%",
    };
    return <div className="widget-spacing" style={style}></div>;
};

WidgetSpacing.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        }).isRequired,
    }),
};

export default WidgetSpacing;
