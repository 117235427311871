import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./DatePickerFieldCustomHeader.scss";

const DatePickerFieldCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    isStartEndDateField,
}) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    // selected date information
    const selectedMonth = date.getMonth();
    const selectedYear = date.getFullYear();

    // set select range for years based on date field type
    const referenceDate = new Date();
    const pastYearLimit = isStartEndDateField
        ? referenceDate.getFullYear()
        : referenceDate.getFullYear() - 100;
    const futureYearLimit = referenceDate.getFullYear() + 10;

    // generate years array for year select
    const years = [];
    for (let i = pastYearLimit; i < futureYearLimit; i++) {
        years.push(i);
    }

    return (
        <div className="custom-header">
            <button
                className="custom-header__month-select-button"
                type="button"
                aria-label="Move backwards to switch to previous month."
                onClick={decreaseMonth}
            >
                <Icon
                    ariaHidden={false}
                    glyph="chevron-circle-left-regular"
                    type="FontAwesome"
                />
            </button>

            <select
                className="custom-header__select custom-header__select--month"
                value={selectedMonth}
                name="month-select"
                onChange={(e) => {
                    changeMonth(e.target.value);
                }}
            >
                {months.map((month, i) => (
                    <option value={i} key={month}>
                        {month}
                    </option>
                ))}
            </select>

            <select
                className="custom-header__select custom-header__select--year"
                value={selectedYear}
                name="year-select"
                onChange={(e) => {
                    changeYear(e.target.value);
                }}
            >
                {years.map((year) => (
                    <option value={year} key={year}>
                        {year}
                    </option>
                ))}
            </select>

            <button
                className="custom-header__month-select-button"
                type="button"
                aria-label="Move forwards to switch to next month"
                onClick={increaseMonth}
            >
                <Icon
                    ariaHidden={false}
                    glyph="chevron-circle-right-regular"
                    type="FontAwesome"
                />
            </button>
        </div>
    );
};

DatePickerFieldCustomHeader.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    changeMonth: PropTypes.func.isRequired,
    changeYear: PropTypes.func.isRequired,
    decreaseMonth: PropTypes.func.isRequired,
    increaseMonth: PropTypes.func.isRequired,
    isStartEndDateField: PropTypes.bool.isRequired,
};

export default DatePickerFieldCustomHeader;
