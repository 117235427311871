import { useEffect } from "react";
import PropTypes from "prop-types";
import useShouldDisplayApplePay from "../../hooks/useShouldDisplayApplePay";

const UpdateShouldDisplayApplePay = ({
    applePayOptions,
    dispatchSetShouldDisplayApplePay,
}) => {
    // Flag that detects whether Apple Pay is a valid payment option
    const shouldDisplayApplePay = useShouldDisplayApplePay(
        window,
        applePayOptions,
    );

    useEffect(() => {
        dispatchSetShouldDisplayApplePay(shouldDisplayApplePay);
    }, [shouldDisplayApplePay]);

    return null;
};

UpdateShouldDisplayApplePay.propTypes = {
    applePayOptions: PropTypes.shape({
        applePayEnabled: PropTypes.bool.isRequired,
        enableCCPayments: PropTypes.bool.isRequired,
        // This key is not present if no there are no credit cards
        isCreditCardMerchantGatewayLitle: PropTypes.bool,
    }).isRequired,
    dispatchSetShouldDisplayApplePay: PropTypes.func.isRequired,
};

export default UpdateShouldDisplayApplePay;
