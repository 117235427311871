import React from "react";
import PropTypes from "prop-types";
import "./SelectedRecipientThermometer.scss";

const SelectedRecipientThermometer = ({
    color,
    hasReachedGoal,
    percentOfGoalToDisplayOnThermometer,
}) => (
    <div
        className={`goal-thermometer${
            hasReachedGoal ? " goal-thermometer--complete" : ""
        }`}
    >
        <div
            className={`goal-thermometer-content${
                hasReachedGoal ? " goal-thermometer-content--complete" : ""
            }`}
            style={{
                width: `${percentOfGoalToDisplayOnThermometer}%`,
                // the appended 66 to the color hex lightens the color through changing the opacity
                // we are using this to add a linear background gradient based on theme color
                background: `linear-gradient(35deg, ${color}, ${color}66)`,
            }}
        >
            <div
                className={`goal-thermometer-shine${
                    hasReachedGoal ? " goal-thermometer-shine--complete" : ""
                }`}
            />
            <div
                className={`goal-thermometer-shine${
                    hasReachedGoal
                        ? " goal-thermometer-shine--complete-second-element"
                        : ""
                }`}
            />
        </div>
    </div>
);

SelectedRecipientThermometer.propTypes = {
    color: PropTypes.string.isRequired,
    hasReachedGoal: PropTypes.bool.isRequired,
    percentOfGoalToDisplayOnThermometer: PropTypes.number.isRequired,
};

export default SelectedRecipientThermometer;
