const requireAll = (requireContext) =>
    requireContext.keys().map(requireContext);

const loadIcons = () => {
    const fontAwesomeIcons = require.context(
        "./FontAwesome",
        false,
        /.*\.svg$/,
    );
    const icomoonIcons = require.context("./Icomoon", false, /.*\.svg$/);
    const customIcons = require.context("./Custom", false, /.*\.svg$/);
    requireAll(fontAwesomeIcons);
    requireAll(icomoonIcons);
    requireAll(customIcons);
};

export default loadIcons;
