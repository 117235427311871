import axios from "axios";
import qs from "qs";

import constants from "../constants";
import log from "../log";

/**
 * Default qfetch options
 * Configure data sending in a way that backend expects
 */
const $MODULE_DEFAULTS = {
    responseType: "json",
    csrfEnabled: true,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};

/**
 * @private
 * @function isSettingEnabled
 * @param {string} setting The setting to check the `config` object for
 * @param {object} [config] The object we're checking a setting for
 * @returns {boolean} Flag determining if setting is enabled in given config
 */
const isSettingEnabled = (setting, config = {}) =>
    !(
        Object.prototype.hasOwnProperty.call(setting, config) &&
        !config[setting]
    );

/**
 * @private
 * @function handleCsrfToken
 * @param {object} config The qfetch/axios config object
 * @description Appends the csrfToken to qfetch's request data
 * @returns {object} A formatted config object
 */
const handleCsrfToken = (config) => {
    const { params = {} } = config;
    const _config = {
        ...config,
        params: {
            ...params,
            csrfToken: constants.csrfToken,
        },
    };
    return _config;
};

/**
 * @private
 * @function handleRequest
 * @param {object} config The qfetch/axios config object
 * @see https://github.com/axios/axios#request-config for config schema
 * @description Axios request interceptor function. Updates request config
 *              based on passed in config.
 * @returns {object} The payload returned by a network request
 */
const handleRequest = (config) => {
    let payload;
    if (isSettingEnabled("csrfEnabled", config)) {
        log(`qfetch: ${config.method} request made with csrfToken`);
        payload = handleCsrfToken(config);
    } else {
        payload = config;
        log(`qfetch: ${config.method} request made without csrfToken`);
    }
    // Bypass stringify-ing if Content-Type is set to 'multipart/form-data'.
    // 'multipart/form-data' is set when File object binaries are sent for file
    // uploads
    if (!config?.headers?.["Content-Type"].includes("multipart/form-data;")) {
        // Backend expects url encoded data.
        payload.data = qs.stringify(payload.data);
    }
    return payload;
};

/**
 * @private
 * @function handleResponse
 * @param {object} response The response from a network request
 * @see https://github.com/axios/axios#response-schema for response schema
 * @description Axios response interceptor function.
 * @returns {object} The response
 */
export const handleResponse = (response) => {
    const hasResponse = response.data && typeof response.data === "object";
    const responseInvalid = response.data[0] === "Invalid submission";
    const responseErrors =
        hasResponse &&
        Object.keys(response.data).includes("errors") &&
        !response.data.success;
    const getErrorArray = (errors) =>
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line no-nested-ternary
        Array.isArray(errors)
            ? // If errors is an array return it.
              errors
            : errors.charAt(0) === "[" &&
                errors.charAt(errors.length - 1) === "]"
              ? // If errors is a stringified array, parse it.
                // (e.g. '["Error 1, Error 2"]' -> ["Error 1", "Error 2"])
                // This is used to parse errors returned from processsing.
                errors
                    .substring(0, errors.length - 2)
                    .substring(2)
                    .split(`","`)
              : [errors]; // otherwise add the string to an empty array

    log(`qfetch: ${response.config.method} response received`);

    if (hasResponse && responseInvalid) {
        // Server side validation returns a string error message in data
        const message = response.data[0];
        const data = {
            message,
            response,
        };
        return Promise.reject(data);
    }

    if (hasResponse && responseErrors) {
        // Server is telling us we have outdated data on the frontend
        const responseOutdated =
            hasResponse && Object.keys(response.data).includes("outdated");
        // Server is telling us we have updated data to use on the frontend
        const responseUpdated =
            hasResponse && Object.keys(response.data).includes("updated");
        const responseErroredEntities =
            hasResponse &&
            Object.keys(response.data).includes("erroredEntities");
        // Server side validation returns a string error message in data
        const message = response.data.errors
            ? getErrorArray(response.data.errors)
            : "Unknown error occurred";
        const data = {
            message,
            response,
        };
        if (responseOutdated) {
            data.outdated = response.data.outdated;
        }
        if (responseUpdated) {
            data.updated = response.data.updated;
        }
        if (responseErroredEntities) {
            data.erroredEntities = response.data.erroredEntities;
        }
        return Promise.reject(data);
    }

    return response;
};

// Create our axios instance with default options
const qfetch = axios.create($MODULE_DEFAULTS);

// Intercept and handle all requests here:
qfetch.interceptors.request.use((_config) => handleRequest(_config));

// Intercept and handle all responses here:
qfetch.interceptors.response.use((response) => handleResponse(response));

export { qfetch };
