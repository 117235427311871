import React from "react";
import { PropTypes } from "prop-types";
import { CardRadio } from "@qgiv/core-donor";

/**
 * @param {object} props - Component properties
 * @param {boolean} props.hasRecurringInvoice - Flag to indicate if there is a recurring invoice
 * @param {string} props.additionalText - Additional text to display if there is a recurring invoice
 * @param {object} props.cardRadioProps - Props to pass to the CardRadio component
 * @returns {React.ReactElement} The InvoiceFulfillmentOption component.
 */
const InvoiceFulfillmentOption = ({
    hasRecurringInvoice,
    additionalText,
    cardRadioProps,
}) => (
    <CardRadio {...cardRadioProps}>
        {hasRecurringInvoice && (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: additionalText }} />
        )}
    </CardRadio>
);

InvoiceFulfillmentOption.propTypes = {
    hasRecurringInvoice: PropTypes.bool.isRequired,
    additionalText: PropTypes.string.isRequired,
    cardRadioProps: PropTypes.shape({}),
};

export default InvoiceFulfillmentOption;
