/**
 * @public
 * @function isMobile
 * @description Check for high-end mobile device user agents.
 * @returns {boolean} Is mobile device flag
 */
export const isMobile = () =>
    /Android|BlackBerry|iPhone|iPad|iPod|IEMobile|Opera Mini/gi.test(
        window.navigator.userAgent,
    );

/**
 * @public
 * @function isTouch
 * @description Check whether this is a touch device or not.
 * @see https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
 * @returns {boolean} Is touch enabled device flag
 */
export const isTouch = () =>
    "ontouchstart" in window || "DocumentTouch" in window;

/**
 * @public
 * @function isDevice
 * @description Must be `isMobile` and `isTouch`.
 * @returns {boolean} Is a mobile and touch enabled device flag
 */
export const isDevice = () => isTouch() && isMobile();
