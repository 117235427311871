import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import useAccordionContext from "../../hooks/useAccordionContext";

// Accessibility
const getAccordionStatus = (activeEventKeys, eventKey) => {
    // returns a bool to determine whether the accordion section
    // is expanded or collapsed based on the eventKey(current index)
    // existing in the active array
    const accordionStatusExpanded = activeEventKeys
        ? activeEventKeys.includes(eventKey)
        : false;

    return { accordionStatusExpanded };
};

const useAccordionClick = (eventKey, onClick) => {
    // Gets the current status of the accordion when the component is rendered
    // so that we can figure out whether or not we need to expand or collapse
    // the accordion on click
    const { onToggle, activeEventKeys } = useAccordionContext();
    const { accordionStatusExpanded } = getAccordionStatus(
        activeEventKeys,
        eventKey,
    );

    return (event) => {
        // Prepare the updated version of the activeEventKeys that we want to
        // create whenever this event handler is called. It should remove the
        // eventKey from the activeEventKey array if the accordion is expanded
        // and add the eventKey to the activeEventKeys array if the accordion
        // is not expanded.
        const updatedActiveEventKeys =
            activeEventKeys && accordionStatusExpanded
                ? activeEventKeys.filter((value) => value !== eventKey)
                : [...activeEventKeys, eventKey];
        onToggle(updatedActiveEventKeys);

        if (onClick) {
            onClick(event);
        }
    };
};

const AccordionToggle = ({
    ariaLabel,
    element: Component = "button",
    eventKey,
    iconCollapsed = {
        glyph: "plus-circle-regular",
        type: "FontAwesome",
    },
    iconExpanded = {
        glyph: "minus-circle-regular",
        type: "FontAwesome",
    },
    onClick,
    children,
    size = "large",
}) => {
    const { activeEventKeys } = useAccordionContext();
    const accordionClick = useAccordionClick(eventKey, onClick);

    const { accordionStatusExpanded } = getAccordionStatus(
        activeEventKeys,
        eventKey,
    );

    return (
        <Component
            type="button"
            onClick={accordionClick}
            size={size}
            ariaLabel={ariaLabel}
            ariaExpanded={accordionStatusExpanded}
        >
            {activeEventKeys && !accordionStatusExpanded && (
                <Icon
                    classNames={["panel__heading__icon"]}
                    glyph={iconCollapsed.glyph}
                    type={iconCollapsed.type}
                />
            )}
            {activeEventKeys && accordionStatusExpanded && (
                <Icon
                    classNames={["panel__heading__icon"]}
                    glyph={iconExpanded.glyph}
                    type={iconExpanded.type}
                />
            )}
            {children}
        </Component>
    );
};

AccordionToggle.propTypes = {
    ariaLabel: PropTypes.string,
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconCollapsed: PropTypes.object,
    iconExpanded: PropTypes.object,
    onClick: PropTypes.func,
    // Accommodate accordion headers that are just a string of text, a single
    // that contains complex markup or multiple elements that contain complex
    // markup
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    size: PropTypes.string,
};

export default AccordionToggle;
