/* eslint-disable import/prefer-default-export */
import { constants } from "@qgiv/core-js";
import {
    getDonorDetailsValues /* isVisible */,
    getFieldValue,
} from "@qgiv/core-donor";
import getAccountInitialValues from "./accountInitialValues";
import { getUrlShortcutsInitialValues } from "./urlShortcutsInitialValues";
import getMailingAddressInitialValues from "./mailingAddressInitialValues";
import { getMatchingGiftsInitialValues } from "../matchingGiftInitialValuesGenerators";
import { getDedicationDetailsInitialValues } from "../dedicationsInitialValuesGenerators";

const {
    ENUMS: { ProductType },
} = constants;

/**
 * @private
 * @function getFieldInitialValue
 * @param {string} field
 * @param {any} value
 * @param {object} fieldData
 * @returns {object} field-value key-value pair
 */
const getFieldInitialValue = (field, value, fieldData) => {
    if (fieldData.exists === false) return {};
    return {
        [field]: value,
    };
};

/**
 * @private
 * @function getPhoneInitialValue
 * @param {object} phoneFieldIsRequired object containing data about field
 * @returns {object} field-value key-value pair
 */
const getPhoneInitialValue = (phoneFieldIsRequired) =>
    getFieldInitialValue("Phone", "", phoneFieldIsRequired);

/**
 * @private
 * @function getEmployerInitialValue
 * @param {object} employerFieldIsRequired object containing data about field
 * @returns {object} field-value key-value pair
 */
const getEmployerInitialValue = (employerFieldIsRequired) =>
    getFieldInitialValue("Employer", "", employerFieldIsRequired);

/**
 * @private
 * @function getOptInInitialValue
 * @param {object} defaultOptInChecked object containing data about field
 * @returns {object} field-value key-value pair
 */
const getOptInInitialValue = (defaultOptInChecked) =>
    getFieldInitialValue(
        "Opt_In",
        defaultOptInChecked?.checkedByDefault,
        defaultOptInChecked,
    );

/**
 * @description Because we have not brought the privacy options field group to
 * the year round form we need to change the field that we are paying attention
 * to here based on product type.
 * @param {number} type
 * @param {object} defaultAnonymousFieldChecked
 * @returns {object}
 */
const getPrivacyOptionsInitialValue = (type, defaultAnonymousFieldChecked) => {
    // Unlike the privacy options field group the anonymous field can be turned
    // on or off and can be checked by default
    if (type === ProductType.QGIV) {
        return getFieldInitialValue(
            "Anonymity",
            defaultAnonymousFieldChecked?.checkedByDefault,
            defaultAnonymousFieldChecked,
        );
    }

    return {
        Do_Not_Show_Amount: false,
        Do_Not_Show_Name: false,
    };
};

/**
 * @typedef DonorDetailsSettings
 * @param {object} detailsPageFieldsData object containing flag to determine which initial values are needed
 * @param {object} detailsPageFieldsData
 * @property {object} [donorAccount] Object returned when
 *                              logging in/creating a donor account
 * @param {boolean} enableMatching bool for Matching Gift setting
 * @param {boolean} hasDisplayableMatchingGiftOnThisPage Matching Gift group is visible on this page
 * @param {boolean} isBillingNameDisplayed true/false representing billing_name display settings
 * @param {number} matchingType Type of matching gift MANUAL = 1, AUTOMATIC = 2
 * @param {object} savedValues Previously stored values
 * @param {Array} systemFields Array of visible system fields
 */

/**
 * @public
 * @function getDonorDetailsInitialValues
 * @param {DonorDetailsSettings} settings settings from state to determine initial values
 * @returns {object} initial values for formik for payment page
 */
export const getDonorDetailsInitialValues = ({
    allowOnlyCompanyContributions,
    currentDisplay,
    currentPage,
    dedicationSettings,
    detailsPageCustomFields,
    detailsPageFieldsData,
    donorAccount,
    enableMatching,
    formSettings,
    hasDisplayableMatchingGiftOnThisPage,
    isBillingNameDisplayed,
    matchingType,
    savedValues = {},
    systemFields,
    urlShortcuts,
    hasActiveFulfillment,
    promiseTransaction,
}) => {
    const { type } = formSettings;
    const {
        companyFieldIsRequired,
        defaultAnonymousFieldChecked,
        defaultCountry,
        defaultOptInChecked,
        employerFieldIsRequired,
        hasLegalField,
        phoneFieldIsRequired,
        titleFieldIsRequired,
        hasSuffixField,
    } = detailsPageFieldsData;

    const getDetailsPageCustomFields = () => {
        // TODO: Need to check is custom field visible - conditional logic
        const detailsPageCustomFieldsValues = detailsPageCustomFields.reduce(
            (valuesObj, currentField) => {
                const fieldId = currentField.id;
                const value = getFieldValue(currentField);
                return {
                    ...valuesObj,
                    [fieldId]: value,
                };
            },
            {},
        );
        return detailsPageCustomFieldsValues;
    };

    const getCompanyInitialValues = () => ({
        ...getFieldInitialValue("Company", "", companyFieldIsRequired),
        // we can use the same eval for Company_Donation value
        Company_Donation: allowOnlyCompanyContributions || false,
    });

    const getLegalInitialValue = () => {
        if (hasLegalField) {
            return {
                Legal: false,
            };
        }
        return {};
    };

    /**
     * @typedef {object} InvoiceFulfillmentInitialValues
     * @property {string} Address - The address line 1 from the promise transaction.
     * @property {string} Address_2 - The address line 2 (empty string).
     * @property {string} City - The city from the promise transaction.
     * @property {string} Country - The country from the promise transaction.
     * @property {string} Email - The email from the promise transaction.
     * @property {string} First_Name - The first name from the promise transaction.
     * @property {string} Last_Name - The last name from the promise transaction.
     * @property {string} Salutation - The salutation from the promise transaction.
     * @property {string} State - The state from the promise transaction.
     * @property {string} Zip - The zip code from the promise transaction.
     */

    /**
     * @function
     * @returns {InvoiceFulfillmentInitialValues} The initial values for invoice fulfillment.
     */
    const getInvoiceFulfillmentInitialValues = () => {
        const activeFulfillmentValues =
            hasActiveFulfillment && promiseTransaction
                ? {
                      Address: promiseTransaction?.address1 || "",
                      Address_2: "",
                      City: promiseTransaction?.city || "",
                      Country: promiseTransaction?.country || "",
                      Email: promiseTransaction?.email || "",
                      First_Name: promiseTransaction?.first_name || "",
                      Last_Name: promiseTransaction?.last_name || "",
                      Salutation: promiseTransaction?.salutation || "",
                      State: promiseTransaction?.state || "",
                      Zip: promiseTransaction?.zip || "",
                  }
                : {};
        return activeFulfillmentValues;
    };

    const hasTitleField = titleFieldIsRequired.exists;

    const initialValuesFromSettings = {
        ...getAccountInitialValues(hasSuffixField, hasTitleField),
        ...getMailingAddressInitialValues(defaultCountry),
        ...getPhoneInitialValue(phoneFieldIsRequired),
        ...getEmployerInitialValue(employerFieldIsRequired),
        ...getOptInInitialValue(defaultOptInChecked),
        ...getCompanyInitialValues(),
        ...getDetailsPageCustomFields(),
        ...getLegalInitialValue(),
        ...getMatchingGiftsInitialValues(
            hasDisplayableMatchingGiftOnThisPage,
            enableMatching,
            matchingType,
        ),
        ...getPrivacyOptionsInitialValue(type, defaultAnonymousFieldChecked),
        ...getDedicationDetailsInitialValues({
            currentDisplay,
            currentPage,
            dedicationSettings,
        }),
        // Override default values with data from donor account
        ...getDonorDetailsValues(
            donorAccount,
            systemFields,
            defaultCountry,
            isBillingNameDisplayed,
        ),
        // Override donor account values with data from URL shortcut
        ...getUrlShortcutsInitialValues({
            currentDisplay,
            systemFields,
            urlShortcuts,
        }),
        // Override url shortcut values with data from invoice
        ...getInvoiceFulfillmentInitialValues(),
    };

    // If the user had previously completed the details step, override any
    // initial or donor account data with the data that was entered & stored
    // when they previously completed the details step
    const initialValuesWithSavedValues = {
        ...initialValuesFromSettings,
        ...savedValues,
    };

    return initialValuesWithSavedValues;
};

export const getConfirmationFieldsInitialValues = ({
    defaultCountry,
    detailsPageFieldsData,
    donorAccount,
    formSettings,
    isBillingNameDisplayed,
    savedValues = {},
    systemFields,
}) => {
    const { type } = formSettings;
    // Confirmation Fields are never required
    const {
        defaultAnonymousFieldChecked,
        defaultOptInChecked,
        employerFieldIsRequired,
        phoneFieldIsRequired,
    } = detailsPageFieldsData;

    const { Employer, Phone } = getDonorDetailsValues(
        donorAccount,
        systemFields,
        defaultCountry,
        isBillingNameDisplayed,
    );

    const donorDetailsValuesToUse = { Employer, Phone };

    const initialValuesFromSettings = {
        ...getEmployerInitialValue(employerFieldIsRequired),
        ...getOptInInitialValue(defaultOptInChecked),
        ...getPhoneInitialValue(phoneFieldIsRequired),
        ...getPrivacyOptionsInitialValue(type, defaultAnonymousFieldChecked),
        // Replace all values with Donor Account values if signed in
        ...donorDetailsValuesToUse,
    };

    // If the user had previously completed the details step, override any
    // initial or donor account data with the data that was entered & stored
    // when they previously completed the details step
    const initialValuesWithSavedValues = {
        ...initialValuesFromSettings,
        ...savedValues,
    };

    return initialValuesWithSavedValues;
};
