import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { currencyString } from "@qgiv/core-js";
import { calcGiftAssistFee } from "@qgiv/core-donor";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";
import { selectIsCms } from "../../../../../redux/slices/configSettingsSlice";
import { selectHasActiveFulfillment } from "../../../../../redux/slices/promiseTransactionSlice";
import GiftAssist from "./GiftAssist";
import {
    selectGiftDetails,
    updateGiftDetails,
} from "../../../../../redux/slices/donationDetailsSlice";

const ConnectedGiftAssist = () => {
    const {
        currency,
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
        feeCoverageText,
        enableGiftAssistByDefault,
    } = useSelector(selectAllFormSettings);
    const isCms = useSelector(selectIsCms);

    const giftDetails = useSelector(selectGiftDetails);
    const { subtotal } = giftDetails;

    const dispatch = useDispatch();
    const { values, handleBlur, handleChange } = useFormikContext();
    const { Gift_Assist = false } = values;
    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    const renderCheckboxContent = () => {
        let feeDisplay = "";

        const fee = calcGiftAssistFee(subtotal, {
            feeCoverage,
            feeCoverageFlat,
            feeCoveragePercentage,
        });

        const formattedFee = currencyString(fee, currency);

        feeDisplay =
            fee > 0
                ? `(<span class="qg-vendor-gift-assist__amount gift-assist-container__amount">${formattedFee}</span>)`
                : "";

        const regex = /%Fee%/gi;
        const newLabel = feeCoverageText.replace(regex, feeDisplay);
        const createLabel = () => ({ __html: newLabel });
        return (
            <span
                className="-padding-right--10"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createLabel()}
            />
        );
    };
    // // Add event handler for checkbox
    const handleCheckboxChange = useCallback(
        (e) => {
            const isChecked = e.target.checked;
            handleChange(e);

            if (isChecked) {
                const giftAssistVal = Number(
                    calcGiftAssistFee(subtotal, {
                        feeCoverage,
                        feeCoverageFlat,
                        feeCoveragePercentage,
                    }),
                );
                dispatch(
                    updateGiftDetails({
                        giftAssistAmount: giftAssistVal,
                        Gift_Assist: true,
                    }),
                );
            } else {
                dispatch(
                    updateGiftDetails({
                        giftAssistAmount: 0,
                        Gift_Assist: false,
                    }),
                );
            }
        },
        [
            dispatch,
            feeCoverage,
            feeCoverageFlat,
            feeCoveragePercentage,
            handleChange,
            subtotal,
        ],
    );

    const isGiftAssistEnabled = Number(feeCoverage) > 0;

    const displayGiftAssist = isGiftAssistEnabled && !hasActiveFulfillment;

    const giftAssistProps = {
        dispatch,
        displayGiftAssist,
        enableGiftAssistByDefault,
        Gift_Assist,
        handleBlur,
        handleCheckboxChange,
        isCms,
        renderCheckboxContent,
    };

    return <GiftAssist {...giftAssistProps} />;
};

ConnectedGiftAssist.propTypes = {};

export default ConnectedGiftAssist;
