import axios from "axios";

/**
 * @public
 * @function authenticateMerchantValidation
 * @param {object} event The ApplePayValidateMerchantEvent object that contains
 *                 the validation URL.
 * @param {object} session The active Apple Pay session.
 * @returns {undefined}
 * @description Performs the API call to the backend that is responsible for
 *              validating the merchant.
 */
// eslint-disable-next-line import/prefer-default-export
export const authenticateMerchantValidation = (event, session) => {
    const { validationURL } = event;
    // eslint-disable-next-line prefer-template
    const url = "/common/apple_pay_authentication.php?u=" + validationURL;

    axios(url)
        .then((response) => {
            // Complete the validation for a merchant session and
            // enable the user to authorize a transaction
            const merchantSession = response.data;
            session.completeMerchantValidation(merchantSession);
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });
};
