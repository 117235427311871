/**
 * @public
 * @function getBankFieldInitialValue
 * @description function that returns initial values bank fields
 * @param {boolean} enableAchPayments form setting for allowing bank accounts to be used on the form
 * @returns {object} initial values for bank field
 */
const getBankFieldInitialValues = (enableAchPayments) => {
    if (enableAchPayments) {
        return {
            Account_Number: "",
            Routing_Number: "",
        };
    }
    return {};
};

export default getBankFieldInitialValues;
