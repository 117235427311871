import {
    stripNonDigits,
    validateRouting,
    validateCCNum,
    formatMDY,
    formatMY,
    validateFutureDate,
    validateMDY,
    validateMY,
} from "@qgiv/core-js";

const QMaskConfigs = {
    alphaNumericReplaceSpaces: {
        inputMode: "text",
        // Allowed characters: alphanumeric, "_", and " "
        mask: (val) => /^[a-zA-Z0-9_ ]*$/.test(val),
        // Replace spaces with underscores
        prepareChar: (str) => str.split(" ").join("_"),
    },

    dateMY: {
        inputMode: "numeric",
        mask: /^(0?[1-9]?|1[0-2])(\/([0-9][0-9]?))?$/,
        prepareChar: (input, QMask) => {
            const { _value } = QMask;
            const date = _value.split("/");
            const month = date[0];
            const year = date[1];
            const inputIsValid = /[\d/]/.test(input) ? input : "";
            const inputNumber = input !== "/" ? input : "";
            let newChars = "";

            const addLeadingZero = () => {
                QMask.value = "";
                newChars = "0" + _value + "/" + inputNumber;
            };

            if (!inputIsValid) return "";

            switch (month.length) {
                case 0: {
                    if (input !== "/" && input !== "0" && input !== "1") {
                        QMask.value = "";
                        newChars = "0" + inputNumber + "/";
                    } else if (!year || year?.length < 2) {
                        newChars = inputNumber;
                    }
                    break;
                }
                case 1: {
                    switch (_value) {
                        case "0": {
                            switch (input) {
                                case "/":
                                case "0": {
                                    break;
                                }
                                default: {
                                    newChars = input + "/";
                                }
                            }
                            break;
                        }
                        case "1": {
                            switch (input) {
                                case "0":
                                case "1":
                                case "2": {
                                    newChars = input + "/";
                                    break;
                                }
                                default: {
                                    addLeadingZero();
                                }
                            }
                            break;
                        }
                        default: {
                            addLeadingZero();
                        }
                    }
                    break;
                }
                case 2: {
                    if (year?.length < 4) {
                        newChars = inputNumber;
                    }
                    break;
                }
            }

            return newChars;
        },
        validate: () => {},
        commit: (value, QMask) => {
            const date = value.split("/");
            const month = date[0];
            const year = date[1];

            if (month && year && year.length === 2) {
                QMask.value = [month, "20" + year].join("/");
            }
        },
        placeholder: "MM/YYYY",
    },

    credit: {
        inputMode: "numeric",
        mask: "0000 0000 0000 0000 000",
    },

    cvv3: {
        inputMode: "numeric",
        mask: "000",
    },

    cvv4: {
        inputMode: "numeric",
        mask: "0000",
    },

    tel: (settings) => {
        const hasCountry = settings?.values?.country;
        const us = {
            inputMode: "tel",
            mask: "(000) 000-0000",
            placeholder: "(555) 555-5555",
        };
        const international = {
            inputMode: "tel",
            mask: "000000000000000",
            placeholder: "555555555555555",
        };
        if (hasCountry !== undefined && hasCountry !== "") {
            return settings?.values?.country === "US" ? us : international;
        }
        return us;
    },

    zip: {
        inputMode: "numeric",
        mask: "00000",
    },

    number: {
        inputMode: "decimal",
        mask: Number,
        thousandsSeparator: ",",
    },

    twoDecimals: {
        inputMode: "decimal",
        mask: Number,
        signed: false, // disallow negative
        thousandsSeparator: ",",
        normalizeZeros: true, // appends or removes zeros at ends
        padFractionalZeros: true, // adds zeros at end to the length of scale
        radix: ".", // fractional delimiter
    },

    // This config does a weird thing where it doesn't show the "%" unless you set lazy = false
    // However, seting lazy = false means the "%" shows even when no value has been input
    percent: {
        inputMode: "decimal",
        mask: "num%",
        lazy: false,
        blocks: {
            num: {
                mask: Number,
                thousandsSeparator: ",",
            },
        },
    },

    checkRouting: {
        inputMode: "numeric",
        mask: "000000000",
    },
};

/**
 * The QMaskValidators object holds the validation functions for the QMaskField component types.
 * It's keys match the type name also used in QMaskConfigs.
 * It's values are functions that accept the value of the QMaskField component as a param,
 * validate that the string matches some criteria, and return `true if validation passes or
 * `false` if it does not.
 */
const QMaskValidators = {
    dateMDY: (date) => validateMDY(date),
    dateMY: (date) =>
        validateFutureDate(formatMY(date) ? formatMY(date) : date) && // attempt to format date before passing to validateFutureDate
        validateMY(date),
    credit: (num) => validateCCNum(num),
    cvv3: (num) => num.length === 3 && !/\D/.test(num),
    cvv4: (num) => num.length === 4 && !/\D/.test(num),
    checkRouting: (num) => validateRouting(num),
};

/**
 * The QMaskFormatters object holds the formatting functions for the QMaskField component types.
 * It's keys match the type name also used in QMaskConfigs.
 * It's values are functions that accept the value of the QMaskField component as a param,
 * attempt to format the string in some way, and return the formatted string if formatting was successful or
 * `false` if it is not.
 */
const QMaskFormatters = {
    dateMDY: (date) => formatMDY(date),
    dateMY: (date) => formatMY(date),
    credit: (num) => stripNonDigits(num),
    cvv3: (num) => stripNonDigits(num),
    cvv4: (num) => stripNonDigits(num),
    checkRouting: (num) => stripNonDigits(num),
};

/**
 * @function getQMaskConfig
 * @param {string} maskType `QMaskConfigs` key name indicating type of QMask
 * @param {object} settings Object with settings for form
 * @returns {object} default configuration for specified mask type
 * @description Get default config for a given mask type
 */
export const getQMaskConfig = (maskType, settings) =>
    typeof QMaskConfigs[maskType] === "function"
        ? QMaskConfigs[maskType](settings)
        : QMaskConfigs[maskType];

/**
 * @function getQMaskValidator
 * @param {string} maskType `QMaskValidators` key name indicating type of QMask
 * @returns {Function} function used to validate QMask value
 * @description Get validation function for a given mask type
 */
export const getQMaskValidator = (maskType) => QMaskValidators[maskType];

/**
 * @function getQMaskFormatter
 * @param {string} maskType `QMaskFormatters` key name indicating type of QMask
 * @returns {Function} function used to format QMask value
 * @description Get formatting function for a given mask type
 */
export const getQMaskFormatter = (maskType) => QMaskFormatters[maskType];

/**
 * @function validQMaskTypes
 * @returns {object} Returns array of `QMaskConfigs` keys
 * @description Useful for dynamic looping over configs for testing, checking if a type is valid, etc.
 */
export const validQMaskTypes = Object.keys(QMaskConfigs);
