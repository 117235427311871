import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { UpdateShouldRevertPaymentType } from "@qgiv/core-donor";
import { selectGiftDetails } from "../../../redux/slices/donationDetailsSlice";

const ConnectedUpdateShouldRevertPaymentType = () => {
    const { total = 0 } = useSelector(selectGiftDetails);
    const { setValues, validateForm, values, initialValues } =
        useFormikContext();

    // Rename data into a variables that the component expects
    const initialPaymentTypeValue = initialValues.Payment_Type;
    const totalAmount = total;

    const updateShouldRevertPaymentTypeProps = {
        initialPaymentTypeValue,
        setValues,
        totalAmount,
        validateForm,
        values,
    };

    return (
        <UpdateShouldRevertPaymentType
            {...updateShouldRevertPaymentTypeProps}
        />
    );
};

export default ConnectedUpdateShouldRevertPaymentType;
