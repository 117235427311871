/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Tabs from "../Tabs";

const InstantPaymentMethods = (props) => {
    const {
        acceptedPayments,
        handleTabClick,
        hasToggledPaymentMethodView,
        numberOfStandardPaymentMethods,
    } = props;
    const multiplePaymentMethodsTabsRef = useRef();
    const singlePaymentMethodTitleRef = useRef();

    // If the use a new payment method was clicked move the focus to the tabs
    // or heading text so that the user can seamlessly tab through the new
    // payments method UI
    useEffect(() => {
        if (hasToggledPaymentMethodView) {
            if (numberOfStandardPaymentMethods > 1) {
                multiplePaymentMethodsTabsRef.current.focus();
            } else if (numberOfStandardPaymentMethods === 1) {
                singlePaymentMethodTitleRef.current.focus();
            }
        }
    }, [hasToggledPaymentMethodView, numberOfStandardPaymentMethods]);

    return numberOfStandardPaymentMethods > 1 ? (
        <Tabs
            handleClick={handleTabClick}
            size="regular"
            ref={multiplePaymentMethodsTabsRef}
        >
            {Object.keys(acceptedPayments).map((type) => (
                <section
                    id={`${type}-tab`}
                    label={acceptedPayments[type].label}
                    key={type}
                    // eslint-disable-next-line react/no-unknown-property
                    active={
                        acceptedPayments[type].active
                            ? acceptedPayments[type].active
                            : false
                    }
                >
                    {acceptedPayments[type].content}
                </section>
            ))}
        </Tabs>
    ) : (
        <>
            <h4 tabIndex="0" ref={singlePaymentMethodTitleRef}>
                {acceptedPayments[Object.keys(acceptedPayments)[0]].label}
            </h4>
            {acceptedPayments[Object.keys(acceptedPayments)[0]].content}
        </>
    );
};

InstantPaymentMethods.propTypes = {
    acceptedPayments: PropTypes.shape({}).isRequired,
    handleTabClick: PropTypes.func.isRequired,
    hasToggledPaymentMethodView: PropTypes.bool.isRequired,
    numberOfStandardPaymentMethods: PropTypes.number.isRequired,
};

export default InstantPaymentMethods;
