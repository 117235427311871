import React from "react";
import { Button } from "@qgiv/core-donor";
import { CancelUpgradeButtonSectionData } from "@qgiv/donation-form";

/**
 * CancelRecurringUpgradeButton component renders a button with text to cancel a recurring upgrade.
 * @param {object} props - The properties object.
 * @param {Function} props.handleCancelUpgradeButtonClick - function that proceeds with donation without changing to recurring
 * @param {CancelUpgradeButtonSectionData} props.cancelUpgradeButtonLabelSections - The data for the upgrade button section.
 * @returns {React.ReactElement} CancelRecurringUpgradeButton
 */
const CancelRecurringUpgradeButton = ({
    cancelUpgradeButtonLabelSections = {
        afterTagTextFromSplit: "",
        beforeTagTextFromSplit: "",
        formattedTotalAmount: "",
    },
    handleCancelUpgradeButtonClick = () => {},
}) => {
    const {
        afterTagTextFromSplit,
        beforeTagTextFromSplit,
        formattedTotalAmount,
    } = cancelUpgradeButtonLabelSections;
    return (
        <Button btnStyle="secondary" onClick={handleCancelUpgradeButtonClick}>
            {beforeTagTextFromSplit ? (
                <span>{beforeTagTextFromSplit}</span>
            ) : null}
            <strong>{formattedTotalAmount}</strong>
            {afterTagTextFromSplit ? (
                <span>{afterTagTextFromSplit}</span>
            ) : null}
        </Button>
    );
};

export default CancelRecurringUpgradeButton;
