import React from "react";
import PropTypes from "prop-types";

import "./TextWithDividerLine.scss";

const TextWithDividerLine = ({ text = "" }) => (
    <div className="text-with-divider-line -text--center">
        <span className="-padding-left--15 -padding-right--15 -type--small">
            {text}
        </span>
    </div>
);

TextWithDividerLine.propTypes = {
    text: PropTypes.string.isRequired,
};

export default TextWithDividerLine;
