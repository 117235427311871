import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    RecurringCallout,
    getRecurringValuesTextOptions,
} from "@qgiv/core-donor";
import { selectReceiptTransaction } from "../../../../redux/slices/receiptsSlice";

const ConnectedReceiptRecurringCallout = ({ control }) => {
    const transaction = useSelector(selectReceiptTransaction);

    const {
        hasCompletedPaymentForInvoice = false,
        recurring = null,
        status = "A",
    } = transaction;
    const { options = {} } = control;
    const {
        billDateInvoiceLabel = "Next invoice scheduled for",
        billDateNextLabel = "Next gift bills on",
        billDatePendingInvoiceLabel = "You haven’t been invoiced yet. Your first invoice is scheduled for",
        billDatePendingLabel = "You haven’t been charged yet. Your first gift bills on",
    } = options;
    const isInvoice = status === "PR";
    let recurringCalloutText = "";

    // Only display the recurring callout if the donor submits a recurring
    // donation. Need to force recurring into a bool if recurring is null
    // to ensure that displayRecurringCallout is always a boolean.
    const displayRecurringCallout =
        !!recurring &&
        !!recurring.frequency_description &&
        !hasCompletedPaymentForInvoice;

    const {
        formattedFirstBillingDate,
        isFirstBillingDayAfterToday,
        formattedNextBillingDate,
    } = getRecurringValuesTextOptions(displayRecurringCallout, recurring);
    if (displayRecurringCallout) {
        // Set value of recurring callout text
        if (isFirstBillingDayAfterToday && isInvoice) {
            recurringCalloutText = `${billDatePendingInvoiceLabel} ${formattedFirstBillingDate}`;
        } else if (isFirstBillingDayAfterToday && !isInvoice) {
            recurringCalloutText = `${billDatePendingLabel} ${formattedFirstBillingDate}`;
        } else if (!isFirstBillingDayAfterToday && isInvoice) {
            recurringCalloutText = `${billDateInvoiceLabel} ${formattedNextBillingDate}`;
        } else {
            recurringCalloutText = `${billDateNextLabel} ${formattedNextBillingDate}`;
        }
    }

    // Consolidate props into object while passing reassigned variables to the
    // appropriate key when needed.
    const receiptRecurringCalloutProps = {
        displayRecurringCallout,
        recurringCalloutText,
    };
    return <RecurringCallout {...receiptRecurringCalloutProps} />;
};

ConnectedReceiptRecurringCallout.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            billDateInvoiceLabel: PropTypes.string,
            billDateNextLabel: PropTypes.string,
            billDatePendingInvoiceLabel: PropTypes.string,
            billDatePendingLabel: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedReceiptRecurringCallout;
