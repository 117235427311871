import { useEffect } from "react";

// hijack Browser Back action hook

/**
 * @function useBrowserBackAction
 * @param {boolean} condition condition for when to hijack browser back action
 * @param {Function} handler Handler to call instead of back
 */
export default function useBrowserBackAction(condition, handler) {
    useEffect(() => {
        if (condition) {
            // If condition is met to hijack back, push the current location to window history
            window.history.pushState(null, "", window.location.href);

            // Hijack browser back action to stay in current location then run desired action
            window.onpopstate = () => {
                window.history.go(1);
                handler();
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href]);
}
