import log from "../log";

/** @enum {string} */
const CurrencySign = {
    Standard: "standard",
    Accounting: "accounting",
};
/**
 * @description gets a number format instance
 * @param {string} locale locale, page language
 * @param {boolean} showDecimal show decimal places
 * @param {string} currency currency, merchant account currency
 * @param {CurrencySign} currencySign currency sign, standard or accounting
 * @returns {Intl.NumberFormat} number format
 */
const getCurrencyNumberFormat = (
    locale,
    showDecimal,
    currency,
    currencySign = CurrencySign.Standard,
) =>
    new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
        minimumFractionDigits: showDecimal ? 2 : 0,
        maximumFractionDigits: showDecimal ? 2 : 0,
        currencySign,
    });

/**
 * @description for checking if a number has decimals
 * @param {number} value value to check
 * @returns {boolean} has decimal
 */
const hasDecimal = (value) => value % 1 !== 0;

/**
 * @description for formatting a currency in accounting format
 * @param {number} amount amount to format
 * @param {string} currency currency, from mechant account defaulted to USD
 * @param {string} locale locale, page language defaulted to en-US
 * @returns {string} formatted amount
 */
export const currencyStringAccounting = (
    amount,
    currency = "USD",
    locale = "en-US",
) =>
    getCurrencyNumberFormat(
        locale,
        hasDecimal(amount),
        currency,
        CurrencySign.Accounting,
    ).format(amount);

/**
 * @function currencyString
 * @param {string|number} number The number to format into a currency string
 * @param {object} currency The passed in currency settings object
 * @param {string|number} currency.format An enum to check for currency format
 * @returns {string} Number string formatted by a given currency
 */
export const currencyString = (number, { format }) => {
    const formats = {
        0: "en-US", // US Standard
        1: "de-DE", // 'German Standard'
        2: "sv-SE", // 'Swiss Standard'
        3: "fr-FR", // 'French Standard'
        4: "en-IN", // 'Indian Standard'
    };
    const ret = getCurrencyNumberFormat(
        formats[format],
        hasDecimal(number),
        "USD",
    ).format(number);
    /*--------------------------------------------------------------------------
    TODO: We need to set this up to regex search for the generated currency
    symbol, then move that symbol before or after based on the passed in
    'symbolposition' parameter. We also need to figure out the value for
    `currency` to pass into `toLocaleString` based on the currency symbol.
    --------------------------------------------------------------------------*/
    return ret;
};

/**
 * @function decodeHTMLEntities
 * @description Decodes strings of text that have html entities. E.g. &quot; is
 *      translated to "
 * @param {string} str String with html entities to decode
 * @returns {string} String with decoded html entities
 */
export const decodeHTMLEntities = (str) => {
    const el = document.createElement("div");
    if (str && typeof str === "string") {
        let ret = str;
        // strip script/html tags
        ret = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        ret = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        el.innerHTML = str;
        ret = el.textContent;
        el.textContent = "";
        return ret;
    }

    log("error", "Only pass strings into decodeHTMLEntities");
    return undefined;
};

/**
 * @function formatDecimalNumber
 * @param {string|number} number The number to format into a decimal string
 * @param {object} [options] Options to pass to Intl.NumberFormat
 * @returns {number} Formatted number (e.g. 1234567.89 => 1,234,567.89)
 */
export const formatDecimalNumber = (number, options) => {
    const integerFormatter = new Intl.NumberFormat("en-US", {
        style: "decimal",
        useGrouping: true,
        ...options,
    });

    return integerFormatter.format(number);
};

/**
 * @function formatPhoneNumber
 * @description Takes in phone number as stored in database which is a 10 digit
 *      number as string (example 5555555555 ) and returns
 *      the number formatted as (555) 555-5555
 * @param {string} phone String with unformatted phone number
 * @returns {string} String with formatted phone number
 */
export const formatPhoneNumber = (phone) => {
    if (phone) {
        const match = String(phone).match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phone;
    }
    return undefined;
};

/**
 * Get "a"/"an" article to precede provided string
 * @param {string} [string] String to get article for
 * @returns {"a"|"an"|""} Article
 */
export const getArticle = (string) => {
    if (!string) return "";

    const irregularConsonantPrefixes = ["honest", "honor", "honorable", "hour"];
    const irregularVowelPrefixes = [
        "one",
        "uni",
        "used",
        "usef",
        "user",
        "util",
    ];
    const vowels = ["a", "e", "i", "o", "u"];
    const _string = string.toLocaleLowerCase();
    const startsWithVowel = vowels.some((vowel) => _string.startsWith(vowel));
    const startsWithIrregularVowelPrefix = irregularVowelPrefixes.some(
        (prefix) => _string.startsWith(prefix),
    );
    const startsWithIrregularConsonantPrefix = irregularConsonantPrefixes.some(
        (prefix) => _string.startsWith(prefix),
    );

    if (
        (startsWithVowel && !startsWithIrregularVowelPrefix) ||
        startsWithIrregularConsonantPrefix
    ) {
        return "an";
    }

    return "a";
};

/**
 * Make a string plural
 * @param {string} string String to make plural
 * @returns {string} String in plural form
 * @see https://stackoverflow.com/questions/27194359/javascript-pluralize-an-english-string
 */
export const getPluralString = (string) => {
    if (!string) return "";

    const uncountable = [
        "sheep",
        "fish",
        "deer",
        "moose",
        "series",
        "species",
        "money",
        "rice",
        "information",
        "equipment",
        "data",
    ];

    // If singular and plural are the same, return the string
    if (uncountable.includes(string)) {
        return string;
    }

    const patterns = {
        "(move)$": "moves",
        "(foot)$": "feet",
        "(goose)$": "geese",
        "(sex)$": "sexes",
        "(child)$": "children",
        "(man)$": "men",
        "(tooth)$": "teeth",
        "(person)$": "people",
        "(die)$": "dice",
        "(quiz)$": "$1zes",
        "^(ox)$": "$1en",
        "([m|l])ouse$": "$1ice",
        "(matr|vert|ind)ix|ex$": "$1ices",
        "(x|ch|ss|sh)$": "$1es",
        "([^aeiouy]|qu)y$": "$1ies",
        "(hive)$": "$1s",
        "(?:([^f])fe|([lr])f)$": "$1$2ves",
        "(shea|lea|loa|thie)f$": "$1ves",
        "(sis)$": "ses",
        "([ti])um$": "$1a",
        "(tomat|potat|ech|her|vet)o$": "$1oes",
        "(bu)s$": "$1ses",
        "(alias)$": "$1es",
        "(ax|test)is$": "$1es",
        "(us)$": "$1es",
        "([^s]+)$": "$1s",
    };

    // look for a pattern that matches the string
    const pattern = Object.keys(patterns).find((singularRegEx) =>
        new RegExp(singularRegEx, "i").test(string),
    );

    // if we found a pattern, use it to convert the string to plural
    if (pattern) {
        return string.replace(new RegExp(pattern, "i"), patterns[pattern]);
    }

    // Could not find a pattern to make this plural. Just add "s".
    return `${string}s`;
};

/**
 * @function getSingularPluralWordForm
 * @description If default or customized text includes "(s)" and a number of
 *      subjects the string refers to, then we will evaluate the number, if it
 *      is 1 we will remove "(s)". Which will leave the word singular. Otherwise
 *      we will just remove the parentheses and leave a plural word. This
 *      function is LIMITED to use with only words that add/remove an "s" to
 *      indicate plural/singular form.
 * @param {string} originalString String to be manipulated
 * @param {number} subjectCount Number of items the word with "(s)" refers to
 * @returns {string} String with "(s)" replaced with either singular or plural
 *      version
 */
export const getSingularPluralWordForm = (originalString, subjectCount) => {
    let pluralCorrectedString = originalString;
    // Count the uses of "(s)", then iterate through and replace all
    const count = (originalString.match(/\(s\)/g) || []).length;
    for (let i = 0; i < count; i++) {
        if (
            subjectCount &&
            typeof subjectCount === "number" &&
            subjectCount === 1
        ) {
            pluralCorrectedString = pluralCorrectedString.replace("(s)", "");
        } else {
            pluralCorrectedString = pluralCorrectedString.replace("(s)", "s");
        }
    }

    return pluralCorrectedString;
};

/**
 * @function stringToType
 * @param {string} str The string to be parsed
 * @returns {boolean} Returns true if string is "true" or false if "false"
 * @description Attempts to parse string to typed value if possible. E.g. "true" => true or "false" => false
 */
export const stringToType = (str) => {
    if (str === "true") return true;
    if (str === "false") return false;
    return str;
};

/**
 * @function stripNonDigits
 * @param {string} num String to remove non-digit characters from
 * @returns {string} String of digits
 * @description Removes all non digit characters from the passed string
 */
export const stripNonDigits = (num) => num.split(/\D/).join("");

/**
 * @function truncateHTMLString
 * @description Takes in a string with HTML elements and a max character limit
 *      after which to truncate Need special function for formatted text because
 *      the html element get counted in the str count and the truncated area
 *      potentially becomes too small
 * @param {string} str Full string to be truncated
 * @param {number} maxLength Character limit to truncate
 * @returns {string} String truncated at specified character limit
 */
export const truncateHTMLString = (str, maxLength) => {
    // TODO: This needs look at container size rather than character count

    // Regex looks for all html tags, removes them and counts the new string
    const cleanStringCount = str.replace(/<[^>]*>/g, "").length;

    let strDifference = 0;
    strDifference = str.length - cleanStringCount;

    if (cleanStringCount <= maxLength) {
        return str;
    }

    // The number 7 is used to account for the <p></p> that will surround all strings of formatted text
    const formattedPTags = 7;
    if (strDifference > formattedPTags) {
        return `${str.slice(0, maxLength * 2)}...`;
    }

    return `${str.slice(0, maxLength - formattedPTags)}...`;
};

/**
 * @function truncateString
 * @description Takes in a string and a max character limit after which to
 *      truncate
 * @param {string} str Full string to be truncated
 * @param {number} maxLength Character limit to truncate
 * @returns {string} String truncated at specified character limit
 */
export const truncateString = (str, maxLength) => {
    const stringToTruncate = String(str);
    if (stringToTruncate.length <= maxLength) {
        return stringToTruncate;
    }
    return `${stringToTruncate.slice(0, maxLength)}...`;
};
