import React from "react";
import PropTypes from "prop-types";
import ConnectedDonorIsLoggedOut from "./ConnectedDonorIsLoggedOut";
import ConnectedDonorIsLoggedIn from "./ConnectedDonorIsLoggedIn";

const DonorLogin = ({ isLoggedIn }) => (
    <div className="qgiv-vender-login login">
        {isLoggedIn ? (
            // Donor is Logged into Donor Account
            <ConnectedDonorIsLoggedIn />
        ) : (
            // Donor has not Logged in yet
            <ConnectedDonorIsLoggedOut />
        )}
    </div>
);

DonorLogin.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};

export default DonorLogin;
