import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";

const SelectedRecipientActions = ({
    selectedRecipientSupportingLabel,
    handleEdit,
    handleRemove,
    isCms,
    hideRecipientActions,
}) => (
    <div className="selected-recipient__header">
        <p className="selected-recipient__header-text ignore-typography">
            {selectedRecipientSupportingLabel}
        </p>
        <div
            className={`selected-recipient__header-actions${
                hideRecipientActions || isCms
                    ? " selected-recipient__header-actions--preselected"
                    : ""
            }`}
        >
            <button
                type="button"
                onClick={handleEdit}
                aria-label="Edit"
                className="selected-recipient__header-actions-edit"
            >
                <Icon
                    glyph="pencil-alt-regular"
                    type="FontAwesome"
                    classNames={[
                        "selected-recipient__header-actions-icon -margin-right--10",
                    ]}
                />
            </button>
            <button
                type="button"
                onClick={handleRemove}
                aria-label="Remove"
                className="selected-recipient__header-actions-remove"
            >
                <Icon
                    glyph="trash-alt-regular"
                    type="FontAwesome"
                    classNames={["selected-recipient__header-actions-icon"]}
                />
            </button>
        </div>
    </div>
);

SelectedRecipientActions.propTypes = {
    selectedRecipientSupportingLabel: PropTypes.string.isRequired,
    hideRecipientActions: PropTypes.bool.isRequired,
    isCms: PropTypes.bool.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
};

export default SelectedRecipientActions;
