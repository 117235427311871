import { constants } from "@qgiv/core-js";
import { createSelector } from "@reduxjs/toolkit";
import { selectAllEventSettingsSelector } from "../eventSettingsSelectors/eventSettingsSelectors";

export const selectAllDonationSettingsSelector = (state) =>
    state.donationSettings;

export const selectEnableAbandonedGiftsSelector = (state) =>
    state.donationSettings.enableAbandonedGifts;

export const selectRecurringFrequencyByValueSelector = (state, value) => {
    const { allFrequencies } = selectAllDonationSettingsSelector(state);
    const selectedRecurringFrequency =
        allFrequencies.find((frequency) => frequency.value === value) || {};
    return selectedRecurringFrequency;
};

// Manual Matching is limited to P2P only. These settings live in hn_eventsettings which will not have properties
// when Donation Form is available on other products
export const selectMatchingGiftSettingsSelector = createSelector(
    [
        (state) => selectAllDonationSettingsSelector(state),
        (state) => selectAllEventSettingsSelector(state),
        (state) => state.formSettings,
    ],
    (donationSettings, eventSettings, formSettings) => {
        const {
            ENUMS: { MatchingType, ProductType },
        } = constants;
        const {
            enableMatching = false,
            matchingTokens = {},
            thirdPartyMatching,
        } = donationSettings;

        const formType = formSettings.type;
        const isP2P =
            formType === ProductType.HOBNOB &&
            Object.keys(eventSettings).length > 0;

        const matchingGiftSettings = {
            enableMatching: isP2P
                ? eventSettings.enableMatching
                : enableMatching,
            matchingTokens,
            thirdPartyMatching,
            matchingRatio: isP2P ? eventSettings.matchingRatio : "",
            matchingType: isP2P
                ? eventSettings.matchingType
                : MatchingType.AUTOMATIC,
        };

        return matchingGiftSettings;
    },
);
