/**
 * @public
 * @function getXYCoordinatesOfRecurringHeartAnimation
 * @param {object} calendarIcon HTML element. Heart calendar used on the ongoing tab
 * @param {object} calendarCoordinates React state. Object containing x and y coordinates
 *                 of the calendar icon
 * @description Uses the passed calendarIcon to determine the XY coordinates for
 *              use in positioning the lottie animation
 * @returns {object} Object containing XY coordinates
 */
export const getXYCoordinatesOfRecurringHeartAnimation = (
    calendarIcon,
    calendarCoordinates,
) => {
    const animationDiv = document.getElementById("recurring-heart-animation");
    const animationSize = animationDiv?.getBoundingClientRect();
    const calendarIconSize = calendarIcon?.getBoundingClientRect();

    // fail gracefully returning expected object
    if (!animationDiv || !animationSize || !calendarIconSize) {
        return {
            animationXCoordinates: 0,
            animationYCoordinates: 0,
        };
    }

    // Note: If changes are needed to adjust the X or Y position of the
    // animation do so here. Pi was arrived at by pure chance through
    // visual verification and has no significance. Here it represents
    // 31.84% the calendar icon width.
    const visualXAlignment = calendarIconSize.width / Math.PI - 0.23;
    const visualYAlignment = calendarIconSize.height / 20;
    // start at offset of calendar icon
    // subtracting half the animation width aligns the center of the heart
    // with the left border of the icon (<-)
    // visualXAlignment moves SVG to the right to align with
    // with the calendar icons heart (->)
    const animationXCoordinates =
        calendarCoordinates.x - animationSize.width / 2 + visualXAlignment;

    // start at offset of calendar icon and move up a bit to align with heart
    const animationYCoordinates = calendarCoordinates.y - visualYAlignment;

    return {
        animationXCoordinates,
        animationYCoordinates,
    };
};
