import React from "react";
import PropTypes from "prop-types";
import { Animation, Icon } from "@qgiv/core-react";
import "./SelectedRecipient.scss";
import SelectedRecipientThermometer from "./SelectedRecipientThermometer";
import SelectedRecipientMessage from "./SelectedRecipientMessage";
import SelectedRecipientActions from "./SelectedRecipientActions";

const SelectedRecipient = ({
    color,
    displayLottie = false,
    formattedFundraisingGoal,
    handleEdit = () => {},
    handleRemove = () => {},
    hasReachedGoal = false,
    hasSelectedRecurringDonation = false,
    hasValidFundraisingGoal = false,
    hideCelebrationAnimationsWhenGoalReached = false,
    isCms = false,
    isSmallScreen = false,
    hideRecipientActions = false,
    recipient = {},
    recipientIconGlyph = "user-duotone",
    percentOfGoalWithDonation,
    percentOfGoalToDisplayOnThermometer,
    selectedRecipientSupportingLabel = "",
    selectedRecipientOneTimeImpact = "",
    selectedRecipientRecurringImpact = "",
    selectedRecipientOneTimeReached = "",
    selectedRecipientRecurringReached = "",
    setDisplayLottie = () => {},
    subtotal,
}) => (
    <div className="selected-recipient">
        {hasReachedGoal &&
        displayLottie &&
        !hideCelebrationAnimationsWhenGoalReached ? (
            <Animation
                glyph="confetti"
                handleComplete={() => {
                    setDisplayLottie(false);
                }}
                style={{
                    bottom: "0",
                    transform: `translateY(${isSmallScreen ? "25" : "40"}%)`,
                }}
            />
        ) : null}
        <SelectedRecipientActions
            selectedRecipientSupportingLabel={selectedRecipientSupportingLabel}
            isCms={isCms}
            hideRecipientActions={hideRecipientActions}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
        />
        <div className="selected-recipient__details">
            <div className="selected-recipient__details-header">
                <Icon
                    glyph={recipientIconGlyph}
                    type="FontAwesome"
                    classNames={[
                        "selected-recipient__details-header-icon -margin-right--10",
                    ]}
                />
                <h3>{recipient.title}</h3>
            </div>
            {hasValidFundraisingGoal ? (
                <>
                    <SelectedRecipientThermometer
                        color={color}
                        hasReachedGoal={hasReachedGoal}
                        percentOfGoalToDisplayOnThermometer={
                            percentOfGoalToDisplayOnThermometer
                        }
                    />
                    <SelectedRecipientMessage
                        hasReachedGoal={hasReachedGoal}
                        hasSelectedRecurringDonation={
                            hasSelectedRecurringDonation
                        }
                        isCms={isCms}
                        percentOfGoalWithDonation={percentOfGoalWithDonation}
                        formattedFundraisingGoal={formattedFundraisingGoal}
                        selectedRecipientOneTimeImpact={
                            selectedRecipientOneTimeImpact
                        }
                        selectedRecipientRecurringImpact={
                            selectedRecipientRecurringImpact
                        }
                        selectedRecipientOneTimeReached={
                            selectedRecipientOneTimeReached
                        }
                        selectedRecipientRecurringReached={
                            selectedRecipientRecurringReached
                        }
                        subtotal={subtotal}
                    />
                </>
            ) : null}
        </div>
    </div>
);

SelectedRecipient.propTypes = {
    color: PropTypes.string.isRequired,
    displayLottie: PropTypes.bool.isRequired,
    formattedFundraisingGoal: PropTypes.string.isRequired,
    handleEdit: PropTypes.func,
    handleRemove: PropTypes.func,
    hasReachedGoal: PropTypes.bool.isRequired,
    hasSelectedRecurringDonation: PropTypes.bool.isRequired,
    hasValidFundraisingGoal: PropTypes.bool.isRequired,
    hideCelebrationAnimationsWhenGoalReached: PropTypes.bool.isRequired,
    isCms: PropTypes.bool,
    isSmallScreen: PropTypes.bool.isRequired,
    hideRecipientActions: PropTypes.bool.isRequired,
    recipient: PropTypes.shape({
        title: PropTypes.string,
    }).isRequired,
    recipientIconGlyph: PropTypes.string,
    percentOfGoalWithDonation: PropTypes.number.isRequired,
    percentOfGoalToDisplayOnThermometer: PropTypes.number.isRequired,
    selectedRecipientSupportingLabel: PropTypes.string.isRequired,
    selectedRecipientOneTimeImpact: PropTypes.string.isRequired,
    selectedRecipientRecurringImpact: PropTypes.string.isRequired,
    selectedRecipientOneTimeReached: PropTypes.string.isRequired,
    selectedRecipientRecurringReached: PropTypes.string.isRequired,
    setDisplayLottie: PropTypes.func.isRequired,
    subtotal: PropTypes.number.isRequired,
};

export default SelectedRecipient;
