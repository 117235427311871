import React from "react";
import ConnectedOtherAmountRadioOption from "./OtherAmountRadioOption";
import ConnectedStandardAmountsRadioOptions from "./StandardAmountsRadioOptions";

/**
 * @typedef {import("./restrictionCardsAmountsTypes").PropsToShareAmongAmountsComponents} PropsToShareAmongAmountsComponents
 */

// -------------------------------------------------------------------------
// TODO:
// There is a low priority spacing issue that I am running into here. The
// issue exists because I added space below all of the previous elements (name,
// description & image) and the amounts add spacing above them. This is
// resulting in 2x the amount of space being present b/w the bottom of the
// name, description, image and the top of the amounts. I have left this
// comment in as a reminder to address this but I don't plan on taking care
// of this problem until the final Multi-Restriction PR.
// -------------------------------------------------------------------------
/**
 *
 * @param {object} props
 * @param {PropsToShareAmongAmountsComponents} props.otherAmountRadioOptionProps
 * @param {boolean} props.showOtherAmount
 * @param {boolean} props.showStandardAmounts
 * @param {PropsToShareAmongAmountsComponents} props.standardAmountsRadioOptionsProps
 * @returns {React.ReactElement}
 */
const RestrictionCardAmounts = ({
    otherAmountRadioOptionProps,
    showOtherAmount,
    showStandardAmounts,
    standardAmountsRadioOptionsProps,
}) => (
    <div className="-padding-bottom--15 -padding-left--15 -padding-right--15">
        <div className="grid">
            {showStandardAmounts && (
                <ConnectedStandardAmountsRadioOptions
                    {...standardAmountsRadioOptionsProps}
                />
            )}
            {showOtherAmount && (
                <ConnectedOtherAmountRadioOption
                    {...otherAmountRadioOptionProps}
                />
            )}
        </div>
    </div>
);

export default RestrictionCardAmounts;
