import { useEffect } from "react";
import PropTypes from "prop-types";

const UpdateShouldReauthorizePayPal = ({
    hasCompletedPayPalAuthorizationFlow,
    hasInitiatedPayPalAuthorizationFlow,
    handleResetPayPalAuthorizationFlow,
    payPalSDKScriptProviderProps,
}) => {
    const {
        forceReRender = [],
        isRecurringDonation = false,
        paymentMethodResetCount = 0,
    } = payPalSDKScriptProviderProps;

    // If, for any reason the PayPal JS SDK needs to be re-rendered run the
    // handleResetPayPalAuthorizationFlow() function. This is done in order to
    // prevent the user from submitting the form without re-authorizing their
    // PayPal transaction using the new PayPal data. In order to to this each
    // implementation of this function will need to:
    // 1. Wipe all of the pre-existing hidden PayPal values and replace them with
    // the initial versions of these values.
    // 2. Update any PayPal related data in the Redux store.
    useEffect(() => {
        if (
            hasInitiatedPayPalAuthorizationFlow ||
            hasCompletedPayPalAuthorizationFlow
        ) {
            handleResetPayPalAuthorizationFlow();
        }
        // -------------------------------------------------------------------------
        // NOTE: This rule is intentionally being ignored here as each implementation
        // of the PayPal JS SDK contains a dynamic list of curated values that
        // should trigger a re-render of the PayPal UI when changed. When this
        // happens any previously generated authorization tokens should be removed
        // to ensure  that the user cannot submit their form using outdated tokens
        // that have been stored in these hidden fields. In addition to this any
        // authenticated message should be reset to its original state. Instances
        // when the PayPal UI should be reset to its original state include:
        // 1. When any value that is passed into the forceReRender list changes as
        // this is a list of values that we want to monitor and trigger a reset of
        // PayPal UI whenever they change.
        // 2. If the user changes the type of donation being submitted from a one
        // time donation to a recurring donation as in this case we will need to
        // reload the PayPal SDK with the updated config options.
        // 3. If the paymentMethodResetCount value is event incremented as
        // this means that the user clicked the use a different payment method
        // button and they have to go through the entire authorization process
        // again.
        // -------------------------------------------------------------------------
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...forceReRender, isRecurringDonation, paymentMethodResetCount]);

    return null;
};

UpdateShouldReauthorizePayPal.propTypes = {
    hasCompletedPayPalAuthorizationFlow: PropTypes.bool.isRequired,
    hasInitiatedPayPalAuthorizationFlow: PropTypes.bool.isRequired,
    handleResetPayPalAuthorizationFlow: PropTypes.func.isRequired,
    payPalSDKScriptProviderProps: PropTypes.shape({
        forceReRender: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]).isRequired,
        ).isRequired,
        isRecurringDonation: PropTypes.bool.isRequired,
        paymentMethodResetCount: PropTypes.number.isRequired,
    }).isRequired,
};

export default UpdateShouldReauthorizePayPal;
