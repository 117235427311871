import React from "react";
import PropTypes from "prop-types";

import { isMobile } from "../../../utility";

import { Field } from "../Field";

const Select = ({ children, disableBlankOption = false, id, ...props }) => {
    return (
        <Field
            {...props}
            id={id ? id : props.name}
            type="select"
            descIcon={{
                glyph: "chevron-down-solid",
                type: "FontAwesome",
            }}
            cursor="pointer"
        >
            {!disableBlankOption && <option></option>}
            {children}
            {isMobile && isMobile() && (
                <optgroup label=""></optgroup>
                // Empty optgroup fixes ios option auto text resizing for options
                // with really long text.
            )}
        </Field>
    );
};

Select.propTypes = {
    disableBlankOption: PropTypes.bool,
    disableInlineLabel: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    helpText: PropTypes.string,
    descText: PropTypes.string,
    descIcon: PropTypes.shape({
        glyph: PropTypes.string,
        type: PropTypes.string,
    }),
    multiline: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    error: PropTypes.bool,
    errorText: PropTypes.string,
};

export default Select;
