import React from "react";
import { useSelector } from "react-redux";
import MultiRestrictions from "./MultiRestrictions";
import { selectMultiRestrictionGroups } from "../../../../../redux/slices/restrictionSettingsSlice";

/**
 *
 * @param {object} props Props
 * @param {number} props.controlId Id of CMS control
 * @returns {React.ReactElement} MultiRestrictions
 */
const ConnectedMultiRestrictions = ({ controlId }) => {
    const multiRestrictionGroups = useSelector(selectMultiRestrictionGroups);

    const hasMultiRestrictionGroups = multiRestrictionGroups.length > 0;

    const multiRestrictionsContainerProps = {
        controlId,
        hasMultiRestrictionGroups,
    };

    return <MultiRestrictions {...multiRestrictionsContainerProps} />;
};

export default ConnectedMultiRestrictions;
