import React from "react";
import PropTypes from "prop-types";

// In order for this component to display:
// 1. Either the show minimum message or the show maximum message needs to be on.
// 2. The UI conditions for the display of this message need to be met. These
// conditions include either:
// a. The form uses standard amounts and the user has focused on the other
// amount field.
// b. The multi-restriction amount UI has been rendered.
const MinMaxMessage = ({
    displayMaxMessage = false,
    displayMinMessage = false,
    maximumGiftMessage = "",
    minimumGiftMessage = "",
    shouldConsiderDisplayOfMinMaxMessage = false,
}) => {
    const displayMinMaxMessage =
        shouldConsiderDisplayOfMinMaxMessage &&
        (displayMaxMessage || displayMinMessage);

    return displayMinMaxMessage ? (
        <div className="col col--12 -text--center">
            <small>
                {displayMinMessage && minimumGiftMessage}
                {displayMinMessage && displayMaxMessage && ` | `}
                {displayMaxMessage && maximumGiftMessage}
            </small>
        </div>
    ) : null;
};

MinMaxMessage.propTypes = {
    displayMaxMessage: PropTypes.bool.isRequired,
    displayMinMessage: PropTypes.bool.isRequired,
    maximumGiftMessage: PropTypes.string.isRequired,
    minimumGiftMessage: PropTypes.string.isRequired,
    shouldConsiderDisplayOfMinMaxMessage: PropTypes.bool.isRequired,
};

export default MinMaxMessage;
