import React, { useEffect, useRef } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useOnClickOutside, Icon } from "@qgiv/core-react";
import Message from "../Message";
import { handleModalKeyDown } from "../../utility";
import Button from "../Button";

import "./Modal.scss";

const Modal = ({
    buttons,
    children,
    classes = "",
    dark,
    glyph,
    modalContainerID = "portal-root",
    modalMessage,
    onClose,
    setModalMessage,
    modalType,

    // Modal settings
    closeOnClickOutside = true,
}) => {
    const ref = useRef();

    const modalClasses = cx(
        "qg-vendor-modal",
        "modal",
        dark && `modal--dark`,
        classes,
    );

    const _onClose = () => {
        if (setModalMessage) {
            setModalMessage({});
        }
        onClose();
    };

    const getModalContainer = () => {
        // if the modal container does not exist, create it
        if (document.getElementById(modalContainerID) === null) {
            const container = document.createElement("div");
            container.id = modalContainerID;
            document.getElementById("app").after(container);
        }
        // return the element to be used by ReactDOM.createPortal
        return document.getElementById(modalContainerID);
    };

    // call onClose() if clicking outside the modal currently in reference
    if (closeOnClickOutside) {
        useOnClickOutside(ref, _onClose);
    }

    useEffect(() => {
        // Make modal visible on screen when it renders
        ref.current.scrollIntoView({ behavior: "smooth" });
    }, [children, ref]);

    return ReactDOM.createPortal(
        <div className={modalClasses}>
            <div
                className="modal__window"
                ref={ref}
                onKeyDown={(e) => handleModalKeyDown(e, ref)}
            >
                {modalType !== "upgrade" && (
                    <button
                        aria-label="Close"
                        className="modal__close -cursor--pointer"
                        onClick={_onClose}
                        autoFocus={true}
                    >
                        <Icon glyph="cross" type="Icomoon" title="Close" />
                    </button>
                )}
                <div className="qg-vendor-modal__content modal__content grid grid--tight-gutters grid--justify-content-center -text--center">
                    {modalMessage && modalMessage.message && (
                        <div className="col col--11 col--sm-10 col--no-top-gutter">
                            <Message
                                message={modalMessage}
                                setMessage={setModalMessage}
                            />
                        </div>
                    )}
                    {glyph && (
                        <div className="col col--11 col--sm-10 col--no-top-gutter">
                            <Icon classNames={["modal__icon"]} {...glyph} />
                        </div>
                    )}
                    {children}
                    {buttons && (
                        <div className="col col--11 col--sm-10 -margin-top--10">
                            <div className="qg-vendor-modal__buttons modal__buttons grid grid--tight-gutters">
                                {Object.keys(buttons).map((buttonType) => {
                                    const buttonProps = buttons[buttonType];
                                    return (
                                        <div
                                            className="col col--12"
                                            key={buttonType}
                                        >
                                            <Button
                                                {...buttonProps}
                                                text={undefined} // remove `text` from passed props
                                                btnStyle={
                                                    buttonType ===
                                                    "rejectButton"
                                                        ? "secondary"
                                                        : "primary"
                                                }
                                            >
                                                {buttonProps.text}
                                            </Button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="qg-vendor-modal__bg modal__bg" />
        </div>,
        getModalContainer(),
    );
};

Modal.propTypes = {
    buttons: PropTypes.shape({
        acceptButton: PropTypes.object,
        dismissButton: PropTypes.object,
        rejectButton: PropTypes.object,
    }),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    classes: PropTypes.string,
    /**
     * Allows modal to be dismissed when clicking outside of it's content.
     */
    closeOnClickOutside: PropTypes.bool,
    dark: PropTypes.bool,
    glyph: PropTypes.shape({
        glyph: PropTypes.string,
        type: PropTypes.string,
    }),
    modalContainerID: PropTypes.string,
    modalMessage: PropTypes.object,
    onClose: PropTypes.func,
    setModalMessage: PropTypes.func,
    modalType: PropTypes.string,
};

export default Modal;
