import React from "react";
import PropTypes from "prop-types";

const ReceiptTransactionSummaryRecipient = ({
    donationRecipientLabel = "",
    entityName = "",
}) => {
    const replacedNameWithSpan = donationRecipientLabel.replace(
        "%RECIPIENTNAME%",
        `<span class="-font-weight--bold">${entityName}</span>`,
    );
    return (
        <>
            <br />
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: replacedNameWithSpan }} />
        </>
    );
};

ReceiptTransactionSummaryRecipient.propTypes = {
    donationRecipientLabel: PropTypes.string,
    entityName: PropTypes.string,
};

export default ReceiptTransactionSummaryRecipient;
