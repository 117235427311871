/* eslint-disable import/prefer-default-export */
import { constants } from "@qgiv/core-js";

/**
 * @public
 * @function getShouldDisplayAmountsAsCards
 * @param {Array} activeAmounts Array of active donation amounts.
 * @returns {boolean} Boolean that represents whether any of the donation
 *          amounts have a name, image or description. If any do then all
 *          amounts should be displayed as cards.
 */

export const getShouldDisplayAmountsAsCards = (
    activeAmounts,
    isRecurringDisplay,
) => {
    const {
        ENUMS: {
            ItemType: { OTHER },
        },
    } = constants;

    const shouldDisplayAmountsAsCards = activeAmounts
        .filter(
            (amount) =>
                parseInt(amount.amountType, 10) !== OTHER &&
                (isRecurringDisplay
                    ? amount.displayForRecurring
                    : amount.displayForOneTime),
        )
        .some(
            (amount) =>
                (amount.name && amount.name.length > 0) ||
                (amount.descr && amount.descr.length > 0) ||
                (amount.image && amount.image.length > 0),
        );
    return shouldDisplayAmountsAsCards;
};
