import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
    Dedication,
    getDisplayableActiveDedications,
} from "@qgiv/donation-form";
import { selectCmsControlById } from "../../../redux/slices/cmsSettingsSlice";
import { selectDedicationSettings } from "../../../redux/slices/dedicationSettingsSlice";
import { selectCurrentDisplay } from "../../../redux/slices/configSettingsSlice";
import { updateDonationDedication } from "../../../redux/slices/donationDetailsSlice";

const ConnectedDedication = ({ controlId }) => {
    const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
        useFormikContext();

    const { dedications, enableDedNotif, fields } = useSelector(
        selectDedicationSettings,
    );

    const currentDisplay = useSelector(selectCurrentDisplay);
    const dispatch = useDispatch();

    const {
        Has_Dedication = false,
        Dedication_Type = "",
        Dedication_Name = "",
        Dedication_Email = "",
        Dedication_Message = "",
    } = values;

    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    const displayableActiveDedications = getDisplayableActiveDedications({
        dedications,
        currentDisplay,
    });

    const handleHasDedicationChange = (e) => {
        dispatch(
            updateDonationDedication({ Has_Dedication: e.target.checked }),
        );
        // -------------------------------------------------------------------------
        // NOTE: If there is only one dedication then we need to manually add the
        // formik value since the type select does not display.
        // -------------------------------------------------------------------------
        if (displayableActiveDedications.length === 1) {
            const dedicationTypeId = displayableActiveDedications[0]?.id;
            setFieldValue("Dedication_Type", String(dedicationTypeId));
        }
        handleChange(e);
    };

    const {
        options: {
            dedicationLabel = "Dedicate this gift",
            dedicationTypeLabel = "What type of dedication?",
            dedicationNameLabel = "Who do you want to recognize?",
            notificationLabel = "Send a message?",
            notificationEmailLabel = "Email",
            notificationMessageLabel = "Message",
        },
    } = control;

    const dedicationNotificationProps = {
        Dedication_Email,
        Dedication_Message,
        errors,
        handleBlur,
        handleChange,
        notificationEmailLabel,
        notificationMessageLabel,
        notificationLabel,
        touched,
    };

    const dedicationTypeProps = {
        dedicationTypeLabel,
        dedicationNameLabel,
        displayableActiveDedications,
        handleBlur,
        handleChange,
        Dedication_Type,
        Dedication_Name,
        errors,
        touched,
    };

    const dedicationFieldsProps = {
        currentDisplay,
        errors,
        fields,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        values,
    };

    const dedicationProps = {
        dedicationFieldsProps,
        dedicationLabel,
        dedicationNotificationProps,
        dedicationTypeProps,
        errors,
        handleBlur,
        handleChange: handleHasDedicationChange,
        Has_Dedication,
        hasDedicationFields: fields.length > 0,
        hasDedicationNotificationEnabled: !!enableDedNotif,
        touched,
    };

    return <Dedication {...dedicationProps} />;
};

ConnectedDedication.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDedication;
