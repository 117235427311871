import React from "react";
import PropTypes from "prop-types";
import { decodeHTMLEntities, formatPhoneNumber } from "@qgiv/core-js";
import "./ReceiptPersonalDetails.scss";

const ReceiptPersonalDetails = ({
    personalDetailsLabel,
    company,
    salutation,
    fname,
    lname,
    suffix,
    email,
    address,
    city,
    state,
    zip,
    country,
    phone,
}) => {
    const hasPersonalDetails =
        company ||
        salutation ||
        fname ||
        lname ||
        suffix ||
        email ||
        address ||
        city ||
        state ||
        zip ||
        country ||
        phone;
    const hasSalutation = salutation && salutation.length > 0;
    if (hasPersonalDetails) {
        return (
            <div className="col col--12 col--sm-6 -margin-top--15">
                <section>
                    <h3>{personalDetailsLabel}</h3>
                    <p className="-type--small -margin-top--15">
                        {hasSalutation && decodeHTMLEntities(salutation)}{" "}
                        {fname} {lname} {suffix}
                        {((hasSalutation && decodeHTMLEntities(salutation)) ||
                            fname ||
                            lname ||
                            suffix) && <br />}
                        {company}
                        {company && <br />}
                        {email && (
                            <>
                                <a
                                    className="-color--theme-primary"
                                    href={`mailto:${email}`}
                                >
                                    <strong className="receipt-details__email">
                                        {email}
                                    </strong>
                                </a>
                                <br />
                            </>
                        )}
                        {address}
                        {address && <br />}
                        {city}
                        {city && (state || zip) && ","} {state} {zip}
                        {(city || state || zip) && <br />}
                        {country}
                        {country && <br />}
                        {formatPhoneNumber(phone)}
                    </p>
                </section>
            </div>
        );
    } else {
        return null;
    }
};

ReceiptPersonalDetails.propTypes = {
    personalDetailsLabel: PropTypes.string,
    company: PropTypes.string,
    salutation: PropTypes.string,
    fname: PropTypes.string,
    lname: PropTypes.string,
    suffix: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
};

export default ReceiptPersonalDetails;
