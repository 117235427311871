import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "@qgiv/core-js";
import { initMutationObserver } from "../../MutationObservers/initMutationObservers";
import {
    selectCurrentPage,
    setCurrentPage,
    selectFormStatusType,
    selectIsSinglePageForm,
} from "../../../redux/slices/appSettingsSlice";
import {
    selectHasTopBottomContentByPage,
    selectAllCmsSettings,
    selectCmsControlsByPage,
} from "../../../redux/slices/cmsSettingsSlice";
import { selectEventListSettings } from "../../../redux/slices/eventListSettingsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import DonationFormEmbed from "./DonationFormEmbed";

const ConnectedDonationFormEmbed = () => {
    const dispatch = useDispatch();
    const {
        ENUMS: { CmsPageStandardizer, DonationStatusType, EntityType },
    } = constants;
    const pages = useSelector(selectAllCmsSettings);
    const currentPage = useSelector(selectCurrentPage);
    const eventListSettings = useSelector(selectEventListSettings);
    const currentFormStatusType = useSelector(selectFormStatusType);
    const eventsPageControls = useSelector((state) =>
        selectCmsControlsByPage(state, CmsPageStandardizer.QGIV_EVENTS),
    );
    const isSinglePageForm = useSelector(selectIsSinglePageForm);

    const isEventListWidgetOnEventsLandingPage =
        eventsPageControls &&
        eventsPageControls.length &&
        eventsPageControls[0].entityType === EntityType.QGIV_EVENT;
    let displayWidgetBorder = true;
    if (isEventListWidgetOnEventsLandingPage) {
        displayWidgetBorder =
            eventsPageControls[0].options?.displayWidgetBorder === 1;
    }

    const { Events = [], Settings = {} } = eventListSettings;
    const {
        enableBanner = false,
        options = {},
        displayEventListDirectLink = false,
        displayDonationsDirectLink = false,
    } = Settings;
    const { eventBannerDefaultView = "donations" } = options;

    const { donationActive, enableRecur } = useSelector(selectAllFormSettings);
    const hasSettingsToDisplayBanner =
        (donationActive || enableRecur) && enableBanner;
    const hasActiveEvents = Events && Events.length > 0;

    // When rendering the QGIV event widget, the BE still sends the entire
    // initial state. This was to avoid crashing errors across the app
    // where it expected multiple pages to be present. In order to display the correct
    // page, the BE passes the QGIV_EVENTS page as the first page in the pageOrder
    // TODO: as this is the only unique identifier we have to determine if we are rendering
    // the widget vs the event list, we want to have the BE pass a equivalent flag for this
    // rather than this cryptic pageOrder check.
    const isNotEventWidget =
        pages.pageOrder[0] !== CmsPageStandardizer.QGIV_EVENTS;

    // As of now, the only page we do not display the event banner is on the confirmation page.
    const hasValidPage = currentPage !== CmsPageStandardizer.CONFIRMATION;

    const hasValidStatus =
        currentFormStatusType !== DonationStatusType.FORM_CLOSED;

    // Evaluation for displaying banner based on form donation settings
    const getDisplayBanner = useCallback(() => {
        if (
            hasSettingsToDisplayBanner &&
            hasActiveEvents &&
            isNotEventWidget &&
            hasValidPage &&
            hasValidStatus
        ) {
            return true;
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValidPage]);

    useEffect(() => {
        const observer = initMutationObserver();
        return observer?.disconnect;
    }, []);

    const shouldDisplayEventList =
        eventBannerDefaultView === "events" || displayEventListDirectLink;

    // if eventsListSettings has a default of events list, we will
    // update the formStep to QGIV_EVENT. Only happens on load and not
    // on subsequent rerenders. Also considers event banner
    // settings direct link usage
    useEffect(() => {
        if (
            shouldDisplayEventList &&
            !displayDonationsDirectLink &&
            enableBanner &&
            hasActiveEvents
        ) {
            dispatch(setCurrentPage(CmsPageStandardizer.QGIV_EVENTS));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { hasTopContent = false, hasBottomContent = false } = useSelector(
        (state) =>
            selectHasTopBottomContentByPage(
                state,
                currentPage,
                isSinglePageForm,
            ),
    );

    let topBottomContentClasses = "";
    if (hasTopContent) {
        topBottomContentClasses += " -margin-top--15";
    }
    if (hasBottomContent) {
        topBottomContentClasses += " -margin-bottom--15";
    }

    const donationFormEmbedProps = {
        displayBanner: getDisplayBanner(),
        topBottomContentClasses,
        displayWidgetBorder,
    };

    return <DonationFormEmbed {...donationFormEmbedProps} />;
};

export default ConnectedDonationFormEmbed;
