import React, { useEffect, useState } from "react";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import { Provider } from "react-redux";
import { initMatomoAnalyticsInstance } from "@qgiv/core-donor";
// import DonationFormModalLoading from "./DonationFormModal/DonationFormModalLoading";
import ConnectedRoutes from "./ConnectedRoutes";
import store from "../redux";

const DonationFormApp = (hasEnumsLoaded) => {
    const matomoAnalyticsInstance = initMatomoAnalyticsInstance();
    const [appLoading, setAppLoading] = useState(true);

    useEffect(() => {
        if (hasEnumsLoaded) {
            setAppLoading(false);
        }
    }, [hasEnumsLoaded]);

    return (
        <Provider store={store}>
            <MatomoProvider value={matomoAnalyticsInstance}>
                <ConnectedRoutes appLoading={appLoading} />
            </MatomoProvider>
        </Provider>
    );
};

export default DonationFormApp;
