import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { translateTransaction, OptInCallout } from "@qgiv/core-donor";
import { selectReceiptTransaction } from "../../../../redux/slices/receiptsSlice";

const ConnectedOptInCallout = ({ control }) => {
    const transaction = useSelector(selectReceiptTransaction);
    const { options = {} } = control;
    const { optInCallOut = "You opted-in for news and updates." } = options;
    // Convert any of the data that is on the transaction object that is a
    // number to a string before it is displayed on the receipt page
    const transactionWithDataAsStrings = translateTransaction(transaction);
    const { optIn = "n" } = transactionWithDataAsStrings;
    const displayOptInCallout = optIn === "y";

    // Consolidate props into object
    const optInCallOutProps = {
        displayOptInCallout,
        optInCallOut,
    };
    return <OptInCallout {...optInCallOutProps} />;
};

ConnectedOptInCallout.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            optIn: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedOptInCallout;
