import React from "react";
import PropTypes from "prop-types";
import { TextWithDividerLine } from "@qgiv/core-donor";
import ConnectedApplePayPaymentMethod from "./ConnectedApplePayPaymentMethod";
import ConnectedUseADifferentPaymentMethodButton from "./ConnectedUseADifferentPaymentMethodButton";
import ConnectedPayPalSDKScriptProvider from "./ConnectedPayPalSDKScriptProvider";
import ConnectedPayPalAuthenticatedMessage from "./ConnectedPayPalAuthenticatedMessage";

const DigitalWalletPaymentOptions = ({
    controlId,
    displayApplePayPaymentMethod = false,
    displayOrText = false,
    displayPayPalAuthenticatedMessage = false,
    displayPayPalSDKScriptProvider = false,
    displayUseADifferentPaymentMethodButton = false,
}) => {
    const displayCallout =
        displayApplePayPaymentMethod ||
        displayPayPalSDKScriptProvider ||
        displayPayPalAuthenticatedMessage;

    // Adds 15 px of margin below the Apple Pay button if the PayPal button is
    // also present
    return (
        <div className="col--12 -padding-left--0 -text--center">
            {displayCallout && (
                <div className="-callout">
                    {displayApplePayPaymentMethod && (
                        <ConnectedApplePayPaymentMethod />
                    )}
                    {displayPayPalSDKScriptProvider && (
                        <ConnectedPayPalSDKScriptProvider />
                    )}
                    {displayPayPalAuthenticatedMessage && (
                        <ConnectedPayPalAuthenticatedMessage />
                    )}
                </div>
            )}
            {displayOrText && <TextWithDividerLine text="or" />}
            {displayUseADifferentPaymentMethodButton && (
                <div className="col col--12 -padding-left--0">
                    <ConnectedUseADifferentPaymentMethodButton
                        controlId={controlId}
                    />
                </div>
            )}
        </div>
    );
};

DigitalWalletPaymentOptions.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    displayApplePayPaymentMethod: PropTypes.bool,
    displayOrText: PropTypes.bool,
    displayPayPalSDKScriptProvider: PropTypes.bool,
    displayPayPalAuthenticatedMessage: PropTypes.bool,
    displayUseADifferentPaymentMethodButton: PropTypes.bool,
};

export default DigitalWalletPaymentOptions;
