import React from "react";
import PropTypes from "prop-types";
import ConnectedPageHeader from "../../common/PageHeader";
import ConnectedDonationFormProgressBar from "./ConnectedDonationFormProgressBar";
import "./HeaderControl.scss";
import ConnectedErrorDisplay from "../../common/ErrorDisplay/ConnectedErrorDisplay";
// import ConnectedInvoiceTab from "./ConnectedInvoiceTab";

const HeaderControl = ({
    control,
    displayDetailSignInTextSinglePage = false,
    displayHeaderContent,
    displayProgressBar,
    displayLockIconSinglePage = false,
    displayTextOrInvoiceHeaderForSinglePage = false,
    isSinglePageForm = false,
}) => (
    <>
        {displayHeaderContent ? (
            <>
                <header className="qg-vendor-header header">
                    <div className="header__content">
                        {displayProgressBar && !isSinglePageForm && (
                            <ConnectedDonationFormProgressBar />
                        )}

                        <ConnectedPageHeader
                            control={control}
                            displayDetailSignInTextSinglePage={
                                displayDetailSignInTextSinglePage
                            }
                            displayLockIconSinglePage={
                                displayLockIconSinglePage
                            }
                            displayTextOrInvoiceHeaderForSinglePage={
                                displayTextOrInvoiceHeaderForSinglePage
                            }
                        />
                    </div>
                </header>
                {!isSinglePageForm && <ConnectedErrorDisplay />}
            </>
        ) : null}
        {/* <ConnectedInvoiceTab /> */}
    </>
);

HeaderControl.propTypes = {
    control: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    displayDetailSignInTextSinglePage: PropTypes.bool.isRequired,
    displayHeaderContent: PropTypes.bool.isRequired,
    displayProgressBar: PropTypes.bool.isRequired,
    displayLockIconSinglePage: PropTypes.bool.isRequired,
    displayTextOrInvoiceHeaderForSinglePage: PropTypes.bool.isRequired,
    isSinglePageForm: PropTypes.bool.isRequired,
};

export default HeaderControl;
