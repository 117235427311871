import { createSlice } from "@reduxjs/toolkit";
import { constants } from "@qgiv/core-js";
import {
    getSelectedRecipientInitialValues,
    filterDonationDetailsValuesByType,
    getDonationMessageInitialValues,
} from "@qgiv/donation-form";

import { getGiftDetailsBucketInitialState } from "../utilities/initialStateHelpers";

// import shaped initial state values from respective slices
import { formSettingsInitialState as formSettings } from "./formSettingsSlice";
import { donationSettingsInitialState as donationSettings } from "./donationSettingsSlice";
import { cmsSettingsInitialState as cmsSettings } from "./cmsSettingsSlice";
import { urlShortcutsInitialState as urlShortcuts } from "./urlShortcutsSlice";
import { restrictionSettingsInitialState as restrictionSettings } from "./restrictionSettingsSlice";
import { configInitialState as config } from "./configSettingsSlice";
import { fieldSettingsInitialState as fieldSettings } from "./fieldsSlice";
import { eventSettingsInitialState as eventSettings } from "./eventSettingsSlice";
import { promiseTransactionInitialState as promiseTransaction } from "./promiseTransactionSlice";
import { smsDataInitialState as smsData } from "./smsDataSlice";

// preselectedRecipient data does not have its own slice, comes directly from initialState.js
import { preselectedRecipient } from "../initialState";

import { getUpdatedTotals } from "../actions/donationDetailsActions/donationDetailsActions";
import { selectHasDonationMessageSelector } from "../selectors/fieldSelectors";
import { selectHideDonationRecipientOnEventPagesSelector } from "../selectors/cmsSettingsSelectors";
import {
    selectStoredFormikGiftDetailsSelector,
    selectSubtotalGiftSelector,
    selectTotalGiftSelector,
} from "../selectors/donationDetailsSelectors";
// -------------------------------------------------------------------------
// TODO: Once BE has smsData sending correctly in initial state
//       remove test values from import here and uncomment import of
//       smsData from initialState above
// -------------------------------------------------------------------------
const fieldState = { fields: fieldSettings };

const hasDonationMessage = selectHasDonationMessageSelector(
    fieldState,
    config.currentDisplay,
);

const { hasActiveFulfillment = false } = promiseTransaction;

const giftDetailsBucket = getGiftDetailsBucketInitialState({
    cmsSettings,
    config,
    donationSettings,
    formSettings,
    hasActiveFulfillment,
    restrictionSettings,
    smsData,
    urlShortcuts,
    promiseTransaction,
});

// need to bring this in for showing selected recipient data
// on cms in the SelectedRecipient component. Currently this will
// only display if hide if hideDonationRecipientOnEventPages is true
const { cms } = constants;
const isCms = !!cms;
const cmsState = { cmsSettings };
const donationRecipientCmsData =
    selectHideDonationRecipientOnEventPagesSelector(cmsState);

const initialState = {
    donorDetails: {},
    customFields: {},
    payment: {},
    billing: {},
    // payFulfillmentSelected: false,
    submitDonation: false,
    matching: {},
    dedication: {},
    shouldDisplayRecipientValidationError: false,
    giftDetails: giftDetailsBucket,
    donationMessage: { ...getDonationMessageInitialValues(hasDonationMessage) },
    ...getSelectedRecipientInitialValues({
        donationRecipientCmsData,
        eventSettings,
        formId: formSettings.id,
        isCms,
        preselectedRecipient,
    }),
};

const slice = createSlice({
    name: "donationDetails",
    initialState,
    reducers: {
        setDonationDetailsFromSessionStorage: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        updateDonationCustomFields: (state, action) => {
            const newFields = {
                ...state.customFields,
                ...action.payload,
            };

            return {
                ...state,
                customFields: newFields,
            };
        },
        updateGiftDetails: (state, action) => {
            const newDetails = {
                ...state.giftDetails,
                ...action.payload,
            };
            const newTotalValues = getUpdatedTotals(newDetails);
            return {
                ...state,
                giftDetails: { ...newDetails, ...newTotalValues },
            };
        },
        updateGiftDetailsWithoutTotalCalc: (state, action) => {
            const newDetails = {
                ...state.giftDetails,
                ...action.payload,
            };
            return {
                ...state,
                giftDetails: newDetails,
            };
        },
        updateDonorDetails: (state, action) => {
            // Allow a single system field to be updated without overwriting the rest
            const newDetails = {
                ...state.donorDetails,
                ...action.payload,
            };
            const newDonorDetails = filterDonationDetailsValuesByType(
                "systemFields",
                newDetails,
            );

            return {
                ...state,
                donorDetails: newDonorDetails,
            };
        },

        updateSubmitDonation: (state, action) => {
            state.submitDonation = action.payload;
        },

        updateDonationBilling: (state, action) => {
            state.billing = action.payload;
        },
        updateDonationPayment: (state, action) => {
            state.payment = action.payload;
        },
        updateAnonymity: (state, action) => {
            state.anonymity = action.payload;
        },
        updateDonationMatching: (state, action) => {
            const newMatching = filterDonationDetailsValuesByType(
                "matchingGift",
                action.payload,
            );

            return {
                ...state,
                matching: newMatching,
            };
        },
        updateDonationDedication: (state, action) => {
            const newDedication = filterDonationDetailsValuesByType(
                "dedication",
                action.payload,
            );

            return {
                ...state,
                dedication: newDedication,
            };
        },
        updateDonationMessage: (state, action) => {
            state.donationMessage = action.payload;
        },
        updateSelectedRecipient: (state, action) => {
            state.selectedRecipient = action.payload;
        },
        updateShouldDisplayRecipientValidationError: (state, action) => {
            state.shouldDisplayRecipientValidationError = action.payload;
        },
        // updatePayFulfillmentSelected: (state, action) => {
        //     state.payFulfillmentSelected = action.payload;
        // },
    },
});

// actions
export const {
    updateAnonymity,
    updateDonationCustomFields,
    updateSubmitDonation,
    updateDonationBilling,
    updateDonationPayment,
    updateDonationMatching,
    updateDonationDedication,
    updateGiftDetails,
    updateGiftDetailsWithoutTotalCalc,
    updateGiftTotals,
    updateDonorDetails,
    setDonationDetailsFromSessionStorage,
    updateDonationMessage,
    updateSelectedRecipient,
    updateShouldDisplayRecipientValidationError,
    // updatePayFulfillmentSelected,
} = slice.actions;

// selectors
export const selectAllDonationDetails = (state) => state.donationDetails;

export const selectGiftDetails = (state) => state.donationDetails.giftDetails;

export const selectTotalGift = (state) => selectTotalGiftSelector(state);

export const selectSubtotalGift = (state) => selectSubtotalGiftSelector(state);

export const selectStoredFormikGiftDetails = (state) =>
    selectStoredFormikGiftDetailsSelector(state);

export const selectHasSelectedRecurringDonation = (state) =>
    state.donationDetails.giftDetails.hasSelectedRecurringDonation;

export const selectDonationDetails = (state) => state.donationDetails.details;
export const selectDonorDetails = (state) => state.donationDetails.donorDetails;

export const selectSubmitDonation = (state) =>
    state.donationDetails.submitDonation;

export const selectDonationBilling = (state) => state.donationDetails.billing;

export const selectAnonymity = (state) => state.donationDetails.anonymity;

export const selectMatchingValues = (state) => state.donationDetails.matching;

export const selectDedicationsValues = (state) =>
    state.donationDetails.dedication;

export const selectCustomFieldValues = (state) =>
    state.donationDetails.customFields;

export const selectDonationMessage = (state) =>
    state.donationDetails.donationMessage;

export const selectSelectedRecipient = (state) =>
    state.donationDetails.selectedRecipient;

export const selectDonationRecipientControlExistsInCms = (state) =>
    state.donationDetails.donationRecipientControlExistsInCms;

export const selectShouldDisplayRecipientValidationError = (state) =>
    state.donationDetails.shouldDisplayRecipientValidationError;

export const selectSelectedRecipientIsPreset = (state) =>
    state.donationDetails.presetRecipient;

export default slice.reducer;
