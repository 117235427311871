import React from "react";
import PropTypes from "prop-types";

// This is specifically with tags %AmountWithFrequency% and %RecipientName% in mind
const ReceiptSubHeading = ({
    receiptSubHeading,
    replaceTags,
    entityName,
    formattedAmountWithFrequency,
}) => {
    const hasReplaceTagInSubHeading = replaceTags.some((tag) =>
        receiptSubHeading.includes(tag),
    );
    if (hasReplaceTagInSubHeading) {
        const subHeadingHTML = replaceTags.reduce((finalString, currentTag) => {
            if (!finalString.includes(currentTag)) {
                return finalString;
            }
            const spanClass =
                currentTag === "%AMOUNTWITHFREQUENCY%"
                    ? "-color--theme-primary"
                    : "-font-weight--bold -color--system-dark";
            const newString = finalString.replace(
                currentTag,
                `<span class="${spanClass}">${
                    currentTag === "%AMOUNTWITHFREQUENCY%"
                        ? formattedAmountWithFrequency
                        : entityName
                }</span>`,
            );
            return newString;
        }, receiptSubHeading);
        return (
            <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: subHeadingHTML,
                }}
            />
        );
    }
    return <span>{receiptSubHeading}</span>;
};

ReceiptSubHeading.propTypes = {
    receiptSubHeading: PropTypes.string.isRequired,
    replaceTags: PropTypes.arrayOf(PropTypes.string).isRequired,
    entityName: PropTypes.string.isRequired,
    formattedAmountWithFrequency: PropTypes.string.isRequired,
};

export default ReceiptSubHeading;
