import React from "react";
import "./ConfirmationFieldsCmsMessage.scss";

const ConfirmationFieldsCmsMessage = () => (
    <div className="confirmation-fields-cms-message -padding-top--15 -padding-bottom--15 -padding-left--30  -padding-right--30 -text--center -type--large">
        If a donor uses Express Donate and skips fields on the Donor Details and
        Additional Details steps, we&apos;ll show those here!
    </div>
);

export default ConfirmationFieldsCmsMessage;
