import { constants } from "@qgiv/core-js";

// Used to determine which values to pre-load into the form based on the
// transaction data that is available on form load
const getTransactionValuesToLoad = (transaction) => {
    const {
        email = "",
        id = "",
        company = "",
        fname = "",
        lname = "",
    } = transaction;
    // Instead of grabbing all of the transaction data only grab the data
    // that is relevant to the donor account form
    const transactionValuesToLoad = {
        Email: email,
        Transaction_ID: id,
        Company_Donation: !!company,
        First_Name: fname,
        Last_Name: lname,
    };

    return transactionValuesToLoad;
};

// Loads the initial values in both the FE version of the form and in CMS
const getInitialValues = (initialValuesSettings = {}) => {
    const { ENUMS } = constants;
    const { transaction } = initialValuesSettings;
    const { GlobalAccountAction } = ENUMS;
    // Only the donor details that are relevant to this form and
    const transactionValuesToLoad = getTransactionValuesToLoad(transaction);

    // This form is always used to activate an account. First and last name
    // values are always empty when used on the receipt page but need to be
    // added as they are required by the BE. Global_Account_Action is not
    // sent to the BE but allows us to generate the appropriate validation
    // logic for this particular use case.
    let values = {
        First_Name: "",
        Last_Name: "",
        Email: "",
        Global_Account_Action: GlobalAccountAction.ACTIVATE_ACCOUNT,
        Password: "",
        Password_Confirm: "",
        Transaction_ID: "",
    };

    // If transaction data is present, override any initial value data with
    // data from the transaction
    if (Object.keys(transactionValuesToLoad).length > 0) {
        values = {
            ...values,
            ...transactionValuesToLoad,
        };
    }

    return values;
};

export default getInitialValues;
