import React from "react";
import PropTypes from "prop-types";
import BackButtonButton from "./BackButtonButton";
import BackButtonLink from "./BackButtonLink";
import "./BackButton.scss";

// -------------------------------------------------------------------------
// NOTE: Back Button Has 3 Variants:
//       Link to React Router display (withRouter: true)
//       <a> tag to outside of React Router (withRouter: false)
//       Button which runs an onClick event passed through props (backLinkUrl not sent in props)
//
//       BackButtonLink contains both link variants of the Back Button
//       When displaying a link variant, we remove the wrapped button from accessibility tree
//       so that the link is what gets focused on in keyboard interactions
//       BackButtonButton contains the actual button displayed by all views
// -------------------------------------------------------------------------
const BackButton = ({
    backLinkUrl = "",
    isCms = true,
    centered = false,
    ...props
}) => (
    <div
        className={`back-button ${
            !centered ? "back-button__submit-align" : ""
        }`}
    >
        {/* Always shows button version when viewing on cms */}
        {backLinkUrl && !isCms ? (
            <BackButtonLink backLinkUrl={backLinkUrl} {...props} />
        ) : (
            <BackButtonButton {...props} ariaHidden={false} />
        )}
    </div>
);

BackButton.propTypes = {
    // Label to display on button from cms settings
    backLinkLabel: PropTypes.string.isRequired,
    // If button functions as link, this is the url to navigate to
    backLinkUrl: PropTypes.string,
    // cms from constants for displaying button instead of link on cms page
    // defaulted to true so that it renders button and not link if it doesn't exist
    isCms: PropTypes.bool.isRequired,
    // withRouter means link is within React Router
    withRouter: PropTypes.bool,
    // handleClick is the onClick function for the button
    // not sent when button is acting as link
    handleClick: PropTypes.func,
    // centered describes if text should always be centered
    // back buttons on submit sections align text to left on xl breakpoints
    // when centered is true, does not add text align left for any breakpoints
    centered: PropTypes.bool,
};

export default BackButton;
