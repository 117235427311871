import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import InstantPaymentMethods from "./InstantPaymentMethods";
import InvoiceButton from "./InvoiceButton";
import InvoiceText from "./InvoiceText";

const InstantPaymentToggle = (props) => {
    const {
        instantPaymentMethodProps,
        invoiceButtonProps,
        invoiceTextProps,
        Payment_Type,
        hasActiveFulfillment,
        enableInvoice,
    } = props;
    const {
        ENUMS: { PaymentType },
    } = constants;

    // If invoices are turned on & the user has decided to pay later, display
    // the invoice text instead of the instant payment methods
    return (
        <>
            {!enableInvoice || Payment_Type !== PaymentType.PROMISE ? (
                <InstantPaymentMethods {...instantPaymentMethodProps} />
            ) : (
                <InvoiceText {...invoiceTextProps} />
            )}
            {!hasActiveFulfillment && enableInvoice && (
                <InvoiceButton {...invoiceButtonProps} />
            )}
        </>
    );
};

InstantPaymentToggle.propTypes = {
    instantPaymentMethodProps: PropTypes.shape({}).isRequired,
    invoiceButtonProps: PropTypes.shape({}).isRequired,
    invoiceTextProps: PropTypes.shape({}).isRequired,
    Payment_Type: PropTypes.number.isRequired,
    enableInvoice: PropTypes.bool.isRequired,
    hasActiveFulfillment: PropTypes.bool.isRequired,
};

export default InstantPaymentToggle;
