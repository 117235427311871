import { log, qfetch } from "@qgiv/core-js";

// -------------------------------------------------------------------------
// MARK: Utility
// -------------------------------------------------------------------------
const accountCreateUrl = "/account/create/";
const standardDonationAccountCreateUrl = "/api/v1/donor/create/";
const accountLoginUrl = "/account/login/";

// -------------------------------------------------------------------------
// MARK: Account Login
// -------------------------------------------------------------------------
export const accountCreate = (
    data,
    useStandardDonationAccountCreateUrl = false,
) =>
    qfetch.post(
        useStandardDonationAccountCreateUrl
            ? standardDonationAccountCreateUrl
            : accountCreateUrl,
        data,
    );

export const accountLogin = (data) =>
    qfetch.post(accountLoginUrl, data).catch((error) => log("error", error));

export const accountLogout = (data) => accountLogin({ ...data, logout: 1 });
