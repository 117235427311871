import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { constants, isDisplayable } from "@qgiv/core-js";
import { isMailingFieldGroupEnabled } from "@qgiv/core-donor";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import { selectAllSystemFields } from "../../../../../redux/slices/fieldsSlice";
import {
    selectCurrentDisplay,
    selectIsCms,
} from "../../../../../redux/slices/configSettingsSlice";
import { selectDonorDetails } from "../../../../../redux/slices/donationDetailsSlice";
import BillingAddress from "./BillingAddress";
import { selectIsSinglePageForm } from "../../../../../redux/slices/appSettingsSlice";

const ConnectedBillingAddress = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
            EntityType: {
                SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
                SYSTEM_FIELD_GROUP_BILLING_ADDRESS,
            },
        },
    } = constants;

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );

    const { values } = useFormikContext();

    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const allFieldsAndGroups = useSelector(selectAllSystemFields);
    const currentDisplay = useSelector(selectCurrentDisplay);

    const addressGroupDisplayOptions =
        allFieldsAndGroups.find(
            (field) =>
                Number(field.type) === SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
        )?.options || {};

    const billingAddressFieldGroup = allFieldsAndGroups.find(
        (field) => Number(field.type) === SYSTEM_FIELD_GROUP_BILLING_ADDRESS,
    );

    const billingAddressFieldGroupFields = billingAddressFieldGroup?.fields;

    const isAddressGroupEnabled =
        isMailingFieldGroupEnabled(allFieldsAndGroups);
    // check the status of the mailing address group and include in logic that defines isAddressGroupDisplayable
    // as the displayOption can be true but the parent group's status is 5 or inactive.
    const isAddressGroupDisplayable =
        isAddressGroupEnabled &&
        isDisplayable(addressGroupDisplayOptions, currentDisplay);

    const isAnyBillingFieldDisplayable = billingAddressFieldGroupFields.some(
        (field) => isDisplayable(field, currentDisplay),
    );

    const isCms = useSelector(selectIsCms);
    const storedDonorDetails = useSelector(selectDonorDetails);
    const defaultCmsMailingAddressValues = {
        Address: "Address",
        Address_2: "Address_2",
        City: "City",
        State: "State",
        Zip: "12345",
        Country: "US",
    };

    const mailingAddressValues = isCms
        ? defaultCmsMailingAddressValues
        : storedDonorDetails;

    const {
        options: {
            sameAsMailingAddressCheckboxLabel = "Same as Mailing Address",
            billingAddressSectionHeading = "Billing Address",
            billingAddressAdditionalText = "",
        },
    } = control;

    const {
        handleBlur,
        handleChange,
        values: { Billing_Address_Use_Mailing },
    } = useFormikContext();

    const billingAddressProps = {
        billingAddressAdditionalText,
        billingAddressSectionHeading,
        billingAddressFieldGroup,
        Billing_Address_Use_Mailing,
        handleBlur,
        handleChange,
        isAddressGroupDisplayable,
        isAnyBillingFieldDisplayable,
        mailingAddressValues: isSinglePageForm ? values : mailingAddressValues,
        sameAsMailingAddressCheckboxLabel,
        isCms,
    };

    return <BillingAddress {...billingAddressProps} />;
};

ConnectedBillingAddress.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedBillingAddress;
