import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
    determineCorrespondingEmptyValueToUpdate,
    getHasSelectedOtherAmount,
    getOtherInputProps,
} from "@qgiv/donation-form";
import { useFormikContext } from "formik";
import { selectAllDonationSettings } from "../../../redux/slices/donationSettingsSlice";
import {
    selectGiftDetails,
    updateGiftDetails,
} from "../../../redux/slices/donationDetailsSlice";
import { selectAbandonedGiftOtherAmountData } from "../../../redux/slices/abandonedGiftSlice";
import OtherAmountRadioOption from "./OtherAmountRadioOption";

const ConnectedOtherAmountRadioOption = ({
    standardAndOtherAmountRefs,
    amountsToDisplay,
    buttonStyle,
    // Button selected
    fieldName,
}) => {
    const { setFieldValue, values } = useFormikContext();

    const dispatch = useDispatch();

    // -------------------------------------------------------------------------
    // TODO: Place injectedUnformattedCurrencyValue in a more general place in redux
    // and reference it by field name. Not the fieldName above, but otherAmountInputFieldName
    // -------------------------------------------------------------------------
    // I would like to refactor this with being able to use a common area to
    // inject the other amount effectively than just abandonedGift data
    const abandonedGiftOtherAmountData = useSelector(
        selectAbandonedGiftOtherAmountData,
    );
    const { injectedUnformattedCurrencyValue = "0" } =
        abandonedGiftOtherAmountData;

    const { activeAmounts } = useSelector(selectAllDonationSettings);

    const giftDetails = useSelector(selectGiftDetails);
    const { hasSelectedRecurringDonation } = giftDetails;

    const [shouldInputBeFocused, setShouldInputBeFocused] = useState(false);

    const hasSelectedOtherAmount = getHasSelectedOtherAmount({
        activeAmounts,
        values,
        fieldName,
    });

    const otherSelectInputProps = getOtherInputProps({
        fieldName,
        hasSelectedOtherAmount,
        hasSelectedRecurringDonation,
        amountsToDisplay,
    });

    const {
        buttonSelectedFieldName,
        otherAmountInputFieldName,
        idOfOtherAmount,
        indexOfOtherAmount,
        otherLabel,
    } = otherSelectInputProps;

    // Replaces the original placeholder text with the currency symbol and some
    // white space after a user focuses on the Other Input field using
    // their keyboard
    const handleOtherInputButtonFocus = (e) => {
        e.target.placeholder = "";

        setShouldInputBeFocused(true);

        // give focus to button if input is clicked
        setFieldValue(buttonSelectedFieldName, parseInt(idOfOtherAmount, 10));
        dispatch(
            updateGiftDetails({
                [buttonSelectedFieldName]: parseInt(idOfOtherAmount, 10),
            }),
        );
        const correspondingValueToSet =
            determineCorrespondingEmptyValueToUpdate({
                activeAmounts,
                buttonSelectedFieldName,
                idOfOtherAmount,
                values,
            });

        if (Object.keys(correspondingValueToSet).length > 0) {
            setFieldValue(
                correspondingValueToSet.name,
                parseInt(idOfOtherAmount, 10),
            );
            dispatch(
                updateGiftDetails({
                    [correspondingValueToSet.name]: parseInt(
                        idOfOtherAmount,
                        10,
                    ),
                }),
            );
        }
    };

    const handleOtherAmountChange = (eventChangeValue) => {
        // This function expects that an amount value will be passed in.
        // If the value is a Synthetic Event object, we should not update
        // redux with the full event object.
        const isExpectedAmountValueNotSyntheticEvent =
            typeof eventChangeValue === "string" ||
            typeof eventChangeValue === "number";

        if (isExpectedAmountValueNotSyntheticEvent) {
            // Update Other amount in Redux
            dispatch(
                updateGiftDetails({
                    [otherAmountInputFieldName]: eventChangeValue || "0.00",
                }),
            );
        } else {
            // If we are in this function and the value is a Synthetic Event object we are likely on iOS.
            // In this case we need to manually call the handleOtherInputButtonFocus function to set the
            // selected amount id and update the corresponding value in the formik form. iOS does not call the
            // regular onFocus event handler when the input is focused.
            handleOtherInputButtonFocus(eventChangeValue);
        }

        // TODO: I don't think this is necessary. The value is already being set in the
        // handleOtherInputButtonFocus function. However I would like to leave it for now until
        // I can confirm that it is not needed.
        const correspondingValueToSet =
            determineCorrespondingEmptyValueToUpdate({
                activeAmounts,
                otherAmountInputFieldName,
                eventChangeValue,
                values,
                isOtherInput: true,
                idOfOtherAmount,
            });

        if (Object.keys(correspondingValueToSet).length > 0) {
            setFieldValue(correspondingValueToSet.name, eventChangeValue);
            dispatch(
                updateGiftDetails({
                    [correspondingValueToSet.name]: eventChangeValue,
                }),
            );
        }
    };

    // Resets the placeholder value of the Currency Input to its initial value
    // after a user blurs a field
    const handleOtherInputButtonBlur = (e) => {
        e.target.placeholder = otherLabel;
        setShouldInputBeFocused(false);
    };

    const handleOtherInputButtonKeydown = ({ key, target }) => {
        const { selectionStart } = target;
        const isCursorAtStartOfValue = selectionStart === 0;
        const isCursorAtEndOfValue = selectionStart === target.value.length;
        const hasAnotherAmountRadioButton =
            standardAndOtherAmountRefs.length > 1;
        // Confirm that there is at least one other amount before attempting to
        // shift focus using the arrow keys
        if (hasAnotherAmountRadioButton) {
            // Get the index of the amount before the last amount as that is
            // where we potentially want to navigate the user to as the other
            // amount is always last
            const indexOfLastAmount = standardAndOtherAmountRefs.length - 1;
            const indexOfAmountBeforeLastAmount = indexOfLastAmount - 1;
            const refOfAmountBeforeLastAmount =
                standardAndOtherAmountRefs[indexOfAmountBeforeLastAmount]
                    .current;
            const refOfFirstAmount = standardAndOtherAmountRefs[0].current;

            const idOfAmountBeforeLastAmount =
                refOfAmountBeforeLastAmount.value;
            const idOfFirstAmount = refOfFirstAmount.value;
            // Faux navigate user to first or last `Select_Amount` radio
            // when user is navigating cursor with arrow keys and is at
            // the beginning/end of the input's value
            switch (key) {
                case "ArrowUp":
                case "ArrowLeft":
                    if (isCursorAtStartOfValue) {
                        refOfAmountBeforeLastAmount.focus();
                        setFieldValue(
                            buttonSelectedFieldName,
                            parseInt(idOfAmountBeforeLastAmount, 10),
                        );
                    }
                    break;
                case "ArrowDown":
                case "ArrowRight":
                    if (isCursorAtEndOfValue) {
                        refOfFirstAmount.focus();
                        setFieldValue(
                            buttonSelectedFieldName,
                            parseInt(idOfFirstAmount, 10),
                        );
                    }
                    break;
                default:
                    break;
            }
        }
    };

    const otherAmountProps = {
        buttonStyle,
        handleChange: handleOtherAmountChange,
        handleOtherInputButtonBlur,
        handleOtherInputButtonFocus,
        handleOtherInputButtonKeydown,
        index: indexOfOtherAmount,
        injectedUnformattedCurrencyValue,
        standardAndOtherAmountRefs,
        shouldInputBeFocused,
        ...otherSelectInputProps,
    };

    return <OtherAmountRadioOption {...otherAmountProps} />;
};

ConnectedOtherAmountRadioOption.propTypes = {
    amountsToDisplay: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    buttonStyle: PropTypes.string.isRequired,
    controlId: PropTypes.number.isRequired,
    fieldName: PropTypes.string.isRequired,
    standardAndOtherAmountRefs: PropTypes.arrayOf(
        PropTypes.shape({ current: PropTypes.number }),
    ).isRequired,
};

export default ConnectedOtherAmountRadioOption;
