import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectCmsControlById } from "../../../../redux/slices/cmsSettingsSlice";
import ConfirmationFieldsSubmit from "./ConfirmationFieldsSubmit";

const ConnectedConfirmationFieldsSubmit = ({ controlId }) => {
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );
    const { options = {} } = control;
    const { submitButtonLabel = "Send" } = options;

    const confirmationFieldsSubmitProps = {
        submitButtonLabel,
    };

    return <ConfirmationFieldsSubmit {...confirmationFieldsSubmitProps} />;
};

ConnectedConfirmationFieldsSubmit.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedConfirmationFieldsSubmit;
