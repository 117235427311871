import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getActiveDisplayableRestrictions,
    getHasDisplayableSubRestrictions,
    getRestrictionDescription,
    getRestrictionName,
    getInitialSubRestrictionValue,
    Restrictions,
} from "@qgiv/donation-form";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectRestrictions } from "../../../../../redux/slices/restrictionSettingsSlice";
import { selectCurrentDisplay } from "../../../../../redux/slices/configSettingsSlice";

const ConnectedRestrictions = ({ controlId }) => {
    const { values, handleBlur, handleChange, setFieldValue } =
        useFormikContext();
    const restrictions = useSelector(selectRestrictions);
    const currentPage = useSelector(selectCurrentPage);
    const currentDisplay = useSelector(selectCurrentDisplay);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const {
        options: { restrictionLabel = "Please direct my support" },
    } = control;
    const { Standard_Restriction_Id } = values;

    // The BE hands us all active restrictions regardless of whether or not
    // they should be shown on this device
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );

    const handleRestrictionChange = (e) => {
        const hasDisplayableSubRestrictions = getHasDisplayableSubRestrictions(
            restrictions,
            currentDisplay,
        );
        const idOfSelectedRestriction = parseInt(e.target.value, 10);
        const selectedRestrictionName = getRestrictionName(
            restrictions,
            idOfSelectedRestriction,
        );
        const initialSubRestrictionValue = getInitialSubRestrictionValue(
            restrictions,
            idOfSelectedRestriction,
        );

        if (hasDisplayableSubRestrictions) {
            setFieldValue(
                "Standard_Restriction_Sub_Restriction",
                initialSubRestrictionValue,
            );
        }

        setFieldValue("Standard_Restriction_Name", selectedRestrictionName);
        handleChange(e);
    };

    const selectedRestrictionDescription = getRestrictionDescription(
        activeDisplayableRestrictions,
        Standard_Restriction_Id,
    );

    const restrictionsProps = {
        activeDisplayableRestrictions,
        ariaLabel: restrictionLabel.length
            ? restrictionLabel
            : "Choose a Restriction",
        fieldId: "Standard_Restriction",
        fieldName: "Standard_Restriction_Id",
        handleBlur,
        handleRestrictionChange,
        restrictionLabel,
        selectedRestrictionDescription,
        showRestrictionDescription: selectedRestrictionDescription.length > 0,
        Standard_Restriction_Id,
    };

    return <Restrictions {...restrictionsProps} />;
};

ConnectedRestrictions.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedRestrictions;
