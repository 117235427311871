import React from "react";
import PropTypes from "prop-types";
import {
    Checkbox,
    CheckboxWithTooltip,
    useTooltip,
    useHover,
} from "@qgiv/core-donor";
import "./GiftAssist.scss";

const GiftAssist = ({
    displayGiftAssist,
    enableGiftAssistByDefault,
    Gift_Assist,
    handleBlur = () => {},
    handleCheckboxChange = () => {},
    isCms,
    renderCheckboxContent = () => {},
}) => {
    const tooltipMessage =
        "Help cover the service, transaction, and credit card fees that come with donating online.";
    const getTooltip = useTooltip({
        message: tooltipMessage,
        placement: "bottom",
    });
    const deviceHasHover = useHover();

    return displayGiftAssist ? (
        <div className="qg-vendor-gift-assist gift-assist-container col col--12 col--no-left-gutter">
            <div className="gift-assist__content">
                <div className="checkbox-container">
                    {deviceHasHover ? (
                        <Checkbox
                            name="Gift_Assist"
                            id="Gift-Assist"
                            label="gift-assist"
                            handleChange={handleCheckboxChange}
                            handleBlur={handleBlur}
                            checked={
                                Gift_Assist ||
                                (isCms && enableGiftAssistByDefault)
                            }
                            ignoreBottomPadding
                        >
                            {renderCheckboxContent()}
                            {getTooltip}
                        </Checkbox>
                    ) : (
                        <CheckboxWithTooltip
                            name="Gift_Assist"
                            id="Gift-Assist"
                            label={renderCheckboxContent()}
                            handleChange={handleCheckboxChange}
                            handleBlur={handleBlur}
                            checked={
                                Gift_Assist ||
                                (isCms && enableGiftAssistByDefault)
                            }
                            tooltipContent={tooltipMessage}
                            moreInfoPosition="center"
                        />
                    )}
                </div>
            </div>
        </div>
    ) : null;
};

GiftAssist.propTypes = {
    displayGiftAssist: PropTypes.bool.isRequired,
    enableGiftAssistByDefault: PropTypes.bool.isRequired,
    Gift_Assist: PropTypes.bool.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    isCms: PropTypes.bool.isRequired,
    renderCheckboxContent: PropTypes.func.isRequired,
};

export default GiftAssist;
