import React from "react";
import PropTypes from "prop-types";
import { DetailsPageContainers } from "../../../Containers";

const DetailsPageContent = ({ containers = [], displayCaptcha }) => {
    const detailsPageContentProps = {
        containers,
        displayCaptcha,
    };

    return <DetailsPageContainers {...detailsPageContentProps} />;
};

DetailsPageContent.propTypes = {
    containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    displayCaptcha: PropTypes.bool.isRequired,
};

export default DetailsPageContent;
