import { constants } from "@qgiv/core-js";
import { getFieldIsVisibleBasedOnConditionals } from "@qgiv/core-donor";

const {
    ENUMS: { FieldType },
} = constants;

/**
 * @public
 * @function getHasOneTimeAmountToDisplay
 * @param {Array} activeAmounts Array of active donation amounts.
 * @returns {boolean} Boolean that represents whether any of the donation
 *          amounts should be displayed on the one time tab.
 */
export const getHasOneTimeAmountToDisplay = (activeAmounts) => {
    // Confirm that at least one of the active amounts is supposed to be
    // displayed on the one time tab
    const firstOneTimeDonationAmount = activeAmounts.find(
        (amount) => amount.displayForOneTime,
    );
    const hasOneTimeDonationAmountToDisplay = Boolean(
        firstOneTimeDonationAmount,
    );

    return hasOneTimeDonationAmountToDisplay;
};

/**
 * @public
 * @function getHasRecurringAmountToDisplay
 * @param {Array} activeAmounts Array of active donation amounts.
 * @param {Array} activePlans Array of active giving plans.
 * @param {string} pledgeActive String the represents whether sustaining
 *        gifts setting or the giving plans setting is toggled on in the
 *        form's recurring settings.
 * @returns {boolean} Boolean that represents whether any of the donation
 *           amounts should be displayed on the Ongoing tab.
 */
export const getHasRecurringAmountToDisplay = (
    activeAmounts,
    activePlans,
    pledgeActive,
) => {
    const hasGivingPlansEnabled = pledgeActive;
    // Confirm that at least one of the active amounts is supposed to be
    // displayed on the ongoing tab
    const firstRecurringDonationAmount = activeAmounts.find(
        (amount) => amount.displayForRecurring,
    );
    const hasSustainingGiftToDisplay = Boolean(firstRecurringDonationAmount);
    const hasGivingPlanToDisplay = activePlans && activePlans.length > 0;
    let hasRecurringDonationAmountToDisplay = hasSustainingGiftToDisplay;

    // If giving plans are enabled, check if there is a giving plan to display
    if (hasGivingPlansEnabled) {
        hasRecurringDonationAmountToDisplay = hasGivingPlanToDisplay;
    }

    return hasRecurringDonationAmountToDisplay;
};

/**
 * @public
 * @function getSkipAdditionalDetailsPageValue
 * @param {object} params - The parameters object.
 * @param {Array} params.additionalDetailsPageCustomFields - Array of custom fields on the additional details page.
 * @param {Array} params.allFieldsAndGroups - Array of all fields and groups.
 * @param {object} params.conditionalLogicOptions - Options for conditional logic.
 * @param {boolean} params.hasDisplayableDedicationOnAdditionalDetailsPage - Boolean indicating if there is a displayable dedication on the additional details page.
 * @param {boolean} params.hasDisplayableMatchingGiftOnAdditionalDetailsPage - Boolean indicating if there is a displayable matching gift on the additional details page.
 * @param {object} params.values - The current form values.
 * @param {boolean} params.hasActiveFulfillment - Boolean indicating if there is an active fulfillment.
 * @returns {boolean} Boolean indicating whether to skip the additional details page.
 */
export const getSkipAdditionalDetailsPageValue = ({
    additionalDetailsPageCustomFields,
    allFieldsAndGroups,
    conditionalLogicOptions,
    hasDisplayableDedicationOnAdditionalDetailsPage,
    hasDisplayableMatchingGiftOnAdditionalDetailsPage,
    values,
    hasActiveFulfillment,
}) => {
    const visibleAdditionalDetailsPageCustomFields =
        additionalDetailsPageCustomFields
            // remove hidden field types
            .filter(
                (currentField) => currentField.type !== FieldType.HIDDEN_FIELD,
            )
            // remove non-visible custom fields
            .filter((currentField) => {
                const condition = currentField?.conditions?.[0] || {};
                const hasCondition = Object.keys(condition).length > 0;
                if (hasCondition) {
                    return getFieldIsVisibleBasedOnConditionals({
                        condition,
                        allFieldsAndGroups,
                        options: conditionalLogicOptions,
                        values,
                    });
                }
                return true;
            });
    const hasVisibleCustomFieldsOnAdditionalDetailsPage =
        visibleAdditionalDetailsPageCustomFields.length > 0;

    const skipAdditionalDetailsPage =
        hasActiveFulfillment ||
        (!hasVisibleCustomFieldsOnAdditionalDetailsPage &&
            !hasDisplayableDedicationOnAdditionalDetailsPage &&
            !hasDisplayableMatchingGiftOnAdditionalDetailsPage);

    return skipAdditionalDetailsPage;
};

/**
 * @public
 * @function getCmsPageByContainerId
 * @param {object} params - The parameters object.
 * @param {Array} params.pages - Array of all pages from cmsSettings.
 * @param {number} params.containerId - The id of the container pulled from the control to check.
 * @returns {number} Enum for the page this containers control is in. Single Step in cms can not
 *  rely on the current page to determine the page the control is on. This function will return the
 *  page Enum based on the container property in the control.
 */
export const getCmsPageByContainerId = ({ pages, containerId }) => {
    const pageKeys = Object.keys(pages);

    let pageEnum = 0;

    for (let i = 0; i < pageKeys.length; i++) {
        const currentPageToCheck = pages[pageKeys[i]];

        const container = currentPageToCheck.find(
            (currentContainer) => currentContainer.id === containerId,
        );

        // All of the containers on a page should have the same step value
        if (container && currentPageToCheck) {
            pageEnum = currentPageToCheck[0]?.options?.step;
        }
    }
    return pageEnum;
};
