import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props
 * @param {string} props.formattedSummaryAmount - The formatted plan amount including currency symbol and amount
 * @param {string} props.formattedSummaryText - The formatted summary text. Includes amount of installments if
 * on flexible giving plans
 * @returns {React.ReactElement} The rendered GivingPlanCardSummary component.
 */
const GivingPlanCardSummary = ({
    formattedSummaryAmount,
    formattedSummaryText,
}) => (
    <div>
        <span className="-type--small">
            <Icon
                classNames={["-margin-right--10", "-type--regular"]}
                glyph="calendar-alt-regular"
                type="FontAwesome"
            />
            <strong>{formattedSummaryAmount}</strong>
            {formattedSummaryText}
        </span>
    </div>
);

export default GivingPlanCardSummary;
