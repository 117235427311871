import React from "react";
import PropTypes from "prop-types";
import { Yup } from "@qgiv/core-validation";
import { Formik, Form } from "formik";
import { Icon } from "@qgiv/core-react";
import { FormikField } from "../../Fields/Field";
import Button from "../../Button";
import "./WidgetSocialSharingEmailModalContent.scss";

const WidgetSocialSharingEmailModalContent = ({
    contentEmailMessage = "",
    contentEmailSubject = "",
    emailModalAdditionalText = "",
    emailModalHeading = "",
    handleRemoveSocialSharingEmailModal = () => {},
    handleSubmitSharingEmailPath = () => {},
    labelButtonCancel = "",
    labelButtonSubmit = "",
    labelEmailAddress = "",
    labelEmailMessage = "",
    labelEmailSubject = "",
    linkFinePrint = "",
    transactionId = "",
}) => {
    // The initial Email_Address should always be an empty string as you don't
    // know who the donor is going to want to send an email to.
    const initialValues = {
        Email_Address: "",
        Email_Message: contentEmailMessage,
        Email_Subject: contentEmailSubject,
    };

    // Validation
    const validationShape = {
        Email_Address: Yup.string()
            .email(`Please enter a valid ${labelEmailAddress}.`)
            .required(`${labelEmailAddress} is required.`),
        Email_Message: Yup.string().required(
            `${labelEmailMessage} is required.`,
        ),
        Email_Subject: Yup.string().required(
            `${labelEmailSubject} is required.`,
        ),
    };

    const validationSchema = Yup.object().shape(validationShape);

    // -------------------------------------------------------------------------
    // NOTE:
    // In the legacy version of this component, the error message for the modal
    // was posted at the top of the modal & the modal was left open. However at
    // first glance we were able to do that because <Modal/> had a modalMessage
    // prop that made this possible. Even though I am sure that this is possible
    // with the new UI, this looks like it may take a little bit of time to
    // work this functionality into the UI. And considering this appears more
    // like "nice to have" instead of "must have" functionality I decided to
    // move on to the other parts of this task that appear to be more important
    // to me.
    // -------------------------------------------------------------------------
    // Submission Logic
    const onSubmit = (values) => {
        const data = {
            ...values,
            transactionId,
        };
        // Platform agnostic API request
        handleSubmitSharingEmailPath(data);
    };

    // Assemble the Formik config into a single object
    const formikProps = {
        initialValues,
        onSubmit,
        validationSchema,
    };

    // Close the modal when a user clicks the cancel button
    const handleCancelButtonClick = () => {
        handleRemoveSocialSharingEmailModal(false);
    };

    return (
        // Vertical spacing comes from margin-top plus built in 30px of padding
        // from modal
        <div className="-margin-top--30 -margin-bottom--60">
            {/* eslint-disable-next-line tailwindcss/classnames-order */}
            <div className="grid grid--justify-content-center ">
                <div className="col col--11 col--sm-9 -text--center">
                    <Icon
                        glyph="envelope-duotone"
                        type="FontAwesome"
                        classNames={[
                            "widget-social-sharing-email-modal-content__icon",
                        ]}
                    />
                    <h1 className="-margin-top--10">{emailModalHeading}</h1>
                    {emailModalAdditionalText && (
                        <p>{emailModalAdditionalText}</p>
                    )}
                </div>
            </div>
            <Formik {...formikProps}>
                <Form>
                    {/* Form fields */}
                    {/* eslint-disable-next-line tailwindcss/classnames-order */}
                    <div className="grid grid--justify-content-center -margin-top--20 -margin-bottom--20">
                        <div className="col col--11 col--sm-9">
                            <FormikField
                                type="email"
                                name="Email_Address"
                                id="Email-Address"
                                label={labelEmailAddress}
                            />
                        </div>
                        <div className="col col--11 col--sm-9">
                            <FormikField
                                type="text"
                                name="Email_Subject"
                                id="Email-Subject"
                                label={labelEmailSubject}
                            />
                        </div>
                        <div className="col col--11 col--sm-9">
                            <FormikField
                                type="text"
                                name="Email_Message"
                                id="Email-Message"
                                label={labelEmailMessage}
                                multiline
                            />
                        </div>
                        <div className="col col--11 col--sm-9 -text--center">
                            <small>{linkFinePrint}</small>
                        </div>
                    </div>
                    {/* Buttons */}
                    {/* eslint-disable-next-line tailwindcss/classnames-order */}
                    <div className="grid grid--justify-content-center">
                        <div className="col col--11 col--sm-10">
                            <Button type="submit">{labelButtonSubmit}</Button>
                        </div>
                        <div className="col col--11 col--sm-10">
                            <Button
                                classes="button button--secondary"
                                onClick={handleCancelButtonClick}
                            >
                                {labelButtonCancel}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

WidgetSocialSharingEmailModalContent.propTypes = {
    contentEmailMessage: PropTypes.string.isRequired,
    contentEmailSubject: PropTypes.string.isRequired,
    emailModalAdditionalText: PropTypes.string.isRequired,
    emailModalHeading: PropTypes.string.isRequired,
    handleRemoveSocialSharingEmailModal: PropTypes.func.isRequired,
    handleSubmitSharingEmailPath: PropTypes.func.isRequired,
    labelButtonCancel: PropTypes.string.isRequired,
    labelButtonSubmit: PropTypes.string.isRequired,
    labelEmailAddress: PropTypes.string.isRequired,
    labelEmailMessage: PropTypes.string.isRequired,
    labelEmailSubject: PropTypes.string.isRequired,
    linkFinePrint: PropTypes.string.isRequired,
    transactionId: PropTypes.string.isRequired,
};

export default WidgetSocialSharingEmailModalContent;
