import { useState, useEffect } from "react";
import { breakpoints as displayOptionsBreakpoints } from "@qgiv/core-js";

import useWindowSize from "../useWindowSize";

// Hook
/**
 * @function useBreakpoints
 * @description React hook for determining the breakpoint updated by
 *      useWindowSize hook. Can be used to conditionally render components based
 *      on breakpoints.
 * @returns {object} Object of booleans for each breakpoint
 */
export default function useBreakpoints() {
    const {
        xs: breakpointXs,
        xsm: breakpointXsm,
        sm: breakpointSm,
        md: breakpointMd,
        lg: breakpointLg,
        xl: breakpointXl,
    } = displayOptionsBreakpoints;

    const detectBreakpoint = (bp) =>
        window.matchMedia(`screen and (max-width: ${bp})`).matches;

    // Marking every screen width >= the Extra Large Breakpoint as Greater Than Extra Large
    const detectGreaterThanExtraLarge = (width) => {
        if (width === undefined) {
            // need this to be accurate on load, cannot wait for debounce
            // ⬇️ Grandfathered in from before Airbnb rules
            // eslint-disable-next-line no-param-reassign
            width = window.innerWidth;
        }
        const extraLargePixels = Number(breakpointXl.split("px")[0]);
        return width >= extraLargePixels;
    };

    // Marking every screen width <= the Extra Small Mobile Breakpoint as Less Than Extra Small Mobile
    const detectLessThanExtraSmallMobile = (width) => {
        if (width === undefined) {
            // need this to be accurate on load, cannot wait for debounce
            // ⬇️ Grandfathered in from before Airbnb rules
            // eslint-disable-next-line no-param-reassign
            width = window.innerWidth;
        }
        const extraSmallPixels = Number(breakpointXsm.split("px")[0]);

        return width < extraSmallPixels;
    };

    // Get width of window currently
    const windowSize = useWindowSize();
    const { width } = windowSize;

    // Set Boolean for initial state of each breakpoint
    const [breakpoints, setBreakpoints] = useState({
        isLessThanExtraSmallMobile: detectLessThanExtraSmallMobile(width),
        isExtraSmallScreen: detectBreakpoint(breakpointXs),
        isExtraSmallMobileScreen: detectBreakpoint(breakpointXsm),
        isSmallScreen: detectBreakpoint(breakpointSm),
        isMediumScreen: detectBreakpoint(breakpointMd),
        isLargeScreen: detectBreakpoint(breakpointLg),
        isExtraLargeScreen: detectBreakpoint(breakpointXl),
        isGreaterThanXLS: detectGreaterThanExtraLarge(width),
    });

    // Updates all breakpoints if the width of the window changes
    useEffect(() => {
        setBreakpoints({
            isLessThanExtraSmallMobile: detectLessThanExtraSmallMobile(width),
            isExtraSmallScreen: detectBreakpoint(breakpointXs),
            isExtraSmallMobileScreen: detectBreakpoint(breakpointXsm),
            isSmallScreen: detectBreakpoint(breakpointSm),
            isMediumScreen: detectBreakpoint(breakpointMd),
            isLargeScreen: detectBreakpoint(breakpointLg),
            isExtraLargeScreen: detectBreakpoint(breakpointXl),
            isGreaterThanXLS: detectGreaterThanExtraLarge(width),
        });
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    return breakpoints;
}
