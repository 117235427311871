import React from "react";
import PropTypes from "prop-types";
import ConnectedDonorAccountCreation from "./ConnectedDonorAccountCreation";
import ConnectedSavePaymentMethod from "./ConnectedSavePaymentMethod";

const PaymentDonorAccountContent = ({
    controlId,
    displayDonorAccountCreation,
}) => (
    <>
        {displayDonorAccountCreation && (
            <div className="grid">
                <div className="col col--12 -padding-top--30">
                    <ConnectedDonorAccountCreation controlId={controlId} />
                </div>
            </div>
        )}

        <ConnectedSavePaymentMethod controlId={controlId} />
    </>
);

PaymentDonorAccountContent.propTypes = {
    controlId: PropTypes.number.isRequired,
    displayDonorAccountCreation: PropTypes.bool.isRequired,
};

export default PaymentDonorAccountContent;
