import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import { selectCmsContainersByPage } from "../../../../redux/slices/cmsSettingsSlice";
import GiftPageContent from "./GiftPageContent";

const ConnectedGiftPageContent = ({ displayCaptcha }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS },
        },
    } = constants;

    const containers = useSelector((state) =>
        selectCmsContainersByPage(state, DONATION_AMOUNTS),
    );

    return (
        <GiftPageContent
            containers={containers}
            displayCaptcha={displayCaptcha}
        />
    );
};

ConnectedGiftPageContent.propTypes = {
    displayCaptcha: PropTypes.bool.isRequired,
};

export default ConnectedGiftPageContent;
