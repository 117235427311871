import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { constants } from "@qgiv/core-js";
import { Icon, Tooltip, useBreakpoints } from "@qgiv/core-react";
import { MoreInfoModal } from "../../MoreInfoModal";
import "./CheckboxWithTooltip.scss";

const CheckboxWithTooltip = ({
    // HTML attributes
    ariaRequired = "false",
    checked = false,
    disabled = false,
    fieldType = "",
    indeterminate = false,
    name,
    id,
    required = false,
    value = "",

    // change handler
    handleBlur = () => {},
    handleChange = () => {},

    // error
    error = false,
    errorText = "",

    // other
    inset = false,
    innerPadding = true,
    label = "",
    moreInfoPosition = "",
    tooltipContent,
}) => {
    const { FieldType } = constants.ENUMS;
    const checkboxFieldClasses = cx(
        "qg-vendor-input-box",
        "input-box",
        checked && "input-box--checked",
        disabled && "input-box--disabled",
        inset && "input-box--inset",
        error && "input-box--error",
    );
    const checkboxInnerClasses = cx(
        "qg-vendor-input-box__inner",
        "input-box__inner",
        innerPadding && "input-box__inner--padding",
        !disabled && "-cursor--pointer",
        id === "Legal" && "input-box__inner--legal",
        "input-box__inner--with-tooltip",
    );

    let checkedIcon;
    const roleAttribute = {};

    if (indeterminate) {
        checkedIcon = (
            <Icon
                classNames={["input-box__svg"]}
                glyph="minus"
                type="FontAwesome"
            />
        );
    } else {
        checkedIcon = (
            <Icon
                classNames={["input-box__svg input-box__svg--checkmark"]}
                glyph="check-solid"
                type="FontAwesome"
            />
        );
    }

    // Assign the appropriate role attribute as checkboxes that are part of a
    // multiple selection are nested within an element that has an ARIA role
    // of listbox and list boxes contain children whose role is option.
    if (Number(fieldType) === FieldType.MULTIPLE_SELECTION) {
        roleAttribute.role = "option";
    } else {
        roleAttribute.role = "checkbox";
    }

    const { isSmallScreen } = useBreakpoints();

    const renderTooltip = () => {
        if (isSmallScreen) {
            return (
                <span className="-padding--left-10 tooltip-container">
                    <MoreInfoModal position={moreInfoPosition}>
                        {tooltipContent}
                    </MoreInfoModal>
                </span>
            );
        }
        return (
            <span className="-padding--left-10">
                <Tooltip
                    placement="bottom"
                    content={tooltipContent}
                    theme="dark"
                >
                    <Icon glyph="question-circle-regular" type="FontAwesome" />
                </Tooltip>
            </span>
        );
    };

    return (
        <div className={checkboxFieldClasses}>
            <span className={checkboxInnerClasses}>
                <span className="qg-vendor-input-box__icon input-box__icon -cursor--pointer">
                    {checkedIcon}
                    <input
                        {...roleAttribute}
                        aria-required={ariaRequired}
                        className="input-box__input -cursor--pointer"
                        id={id}
                        type="checkbox"
                        checked={checked}
                        disabled={disabled}
                        name={name}
                        required={required}
                        value={value}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                </span>
                <label className="input-box__label" htmlFor={id}>
                    {label}
                </label>
                {renderTooltip()}
            </span>
            {error && errorText?.length > 0 && (
                <div className="input-box__error-text -padding-left--30">
                    {errorText}
                </div>
            )}
        </div>
    );
};

CheckboxWithTooltip.propTypes = {
    ariaRequired: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    fieldType: PropTypes.string,
    indeterminate: PropTypes.bool,
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    inset: PropTypes.bool,
    innerPadding: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    moreInfoPosition: PropTypes.string,
    tooltipContent: PropTypes.string.isRequired,
};

export default CheckboxWithTooltip;
