import React from "react";
import { useSelector } from "react-redux";
import { BackButton } from "@qgiv/core-donor";
import {
    selectConfig,
    selectIsCms,
} from "../../../redux/slices/configSettingsSlice";

const ConnectedBackButton = () => {
    const config = useSelector(selectConfig);

    const { HTTP_HOST, path } = config;

    const isCms = useSelector(selectIsCms);

    const backButtonProps = {
        backLinkLabel: "Back to Fundraiser",
        backLinkUrl: `${HTTP_HOST}/event/${path}`,
        isCms,
        withRouter: false,
        centered: true,
    };
    return <BackButton {...backButtonProps} />;
};

export default ConnectedBackButton;
