import React from "react";
import PropTypes from "prop-types";

const WidgetText = ({ control: { options } }) => (
    <div className="widget-text grid -margin-top--15">
        <div className="col col--12">
            <p className="qg-vendor-widget-text">{options.text}</p>
        </div>
    </div>
);

WidgetText.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired,
    }),
};

export default WidgetText;
