// pulling the enums.json file from the root frontend directory
// eslint-disable-next-line import/no-relative-packages
import enums from "./enums.json";

// Globally accessible values that do not change.
const constants = {
    /**
     * @type {string|null|undefined} csrf token value generated by server on page load
     */
    csrfToken: document.getElementById("csrfToken")?.getAttribute("value"),

    /**
     * @type {string|null|undefined|false} flag that we're in cms; necessary for
     * field components
     */
    cms: document.getElementById("cms-flag")
        ? document.getElementById("cms-flag")?.getAttribute("value")
        : false,

    /**
     * @type {import("./enums.json")} Object to hold enums fetched from server
     */
    ENUMS: enums,

    /**
     * @type {string} Google Analytics Universal Analytics ID that is used in
     * production.
     */
    uaProdTrackingId: "UA-652400-10",

    /**
     * @type {string} Google Analytics Universal Analytics Tracking ID that is
     * used in dev & test environments.
     */
    uaDevTrackingId: "UA-652400-13",

    /**
     * @type {string} Google Tag Manager Tracking ID that is used in production.
     * This GTM Container also serves up thew new Google Analytics 4 scripts
     * which replace the soon to be retired UA scripts.
     */
    gtmProdTrackingId: "GTM-WV4HKMR",

    /**
     * @type {string} Google Tag Manager Tracking ID that is used in dev and
     * test environments. This GTM Container also serves up thew new Google
     * Analytics 4 scripts which replace the soon to be retired UA scripts.
     */
    gtmDevTrackingId: "GTM-MGGZT3J",

    /** @type {string} Version pulled from config.json */
    // @ts-ignore
    version: typeof VERSION !== "undefined" ? VERSION : "0",
};

export default constants;
