import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";

const PrivacyOptionsCallout = ({
    classNames = "",
    message = "",
    iconGlyph = "exclamation-circle-solid",
    iconType = "FontAwesome",
}) =>
    message ? (
        <div className={`col col--12 ${classNames}`}>
            <div className="privacy-options-callout -callout">
                <Icon
                    glyph={iconGlyph}
                    type={iconType}
                    classNames={["-margin-right--10 -type--regular"]}
                />
                <span
                    className="-type--regular"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: message,
                    }}
                />
            </div>
        </div>
    ) : null;

PrivacyOptionsCallout.propTypes = {
    classNames: PropTypes.string,
    iconGlyph: PropTypes.string,
    iconType: PropTypes.string,
    message: PropTypes.string,
};

export default PrivacyOptionsCallout;
