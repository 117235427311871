import { log, qfetch } from "@qgiv/core-js";

const matchingUrl = `/services/matching/validate.php`;

/**
 * @public
 * @function makeMatchingSearchRequest
 * @param {object} data { formKey: (path / alias), formType: (1/2/3/4/5), csrfToken, prefix: (employer), email: (work-email) }
 * @description Performs ajax call to matching endpoint to get search results from given employer/work-email
 * @returns {object} returns response from matching employer search
 */
// eslint-disable-next-line import/prefer-default-export, arrow-body-style
export const makeMatchingSearchRequest = (data = {}) => {
    return qfetch.post(matchingUrl, data).catch((error) => log("error", error));
};
