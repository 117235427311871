import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import ConnectedHeaderLogin from "./ConnectedHeaderLogin";

const PageHeader = ({
    additionalText,
    control,
    sectionHeading,
    showAdditionalText,
    showHeaderLogin,
    showLockIconInHeading,
}) => (
    <>
        <h2 className="qg-vendor-header__title header__title">
            {showLockIconInHeading && (
                <Icon
                    glyph="lock-alt-solid"
                    type="FontAwesome"
                    classNames={["-type--xxlarge -margin-right--10"]}
                />
            )}
            {sectionHeading}
        </h2>
        {showHeaderLogin && <ConnectedHeaderLogin control={control} />}

        {showAdditionalText && <p>{additionalText}</p>}
    </>
);
PageHeader.propTypes = {
    additionalText: PropTypes.string.isRequired,
    control: PropTypes.shape({}).isRequired,
    sectionHeading: PropTypes.string.isRequired,
    showAdditionalText: PropTypes.bool.isRequired,
    showHeaderLogin: PropTypes.bool.isRequired,
    showLockIconInHeading: PropTypes.bool.isRequired,
};

export default PageHeader;
