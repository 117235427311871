import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@qgiv/core-donor";

const WriteTheirOwnSubRestriction = ({
    ariaLabel,
    handleBlur,
    handleChange,
    label,
    name,
    value,
}) => (
    <TextField
        aria-label={ariaLabel}
        handleBlur={handleBlur}
        handleChange={handleChange}
        label={label}
        name={name}
        type="text"
        value={value}
    />
);

WriteTheirOwnSubRestriction.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default WriteTheirOwnSubRestriction;
