import React, { forwardRef, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { addDays, isAfter, format, isValid } from "date-fns";
import cx from "classnames";
import { useBreakpoints } from "@qgiv/core-react";
import { useFormikContext } from "formik";
import DatePickerFieldCustomHeader from "./DatePickerFieldCustomHeader";
import { getDateInstanceFromString } from "../../../utility";
import "./DatePickerField.scss";

const ButtonDatePicker = forwardRef(
    ({ value = "", onClick = () => {} }, ref) => {
        const valueToDisplay = value || "MM/DD/YY";
        return (
            <button
                type="button"
                className="custom-input-button"
                onClick={onClick}
                ref={ref}
            >
                <span>{valueToDisplay}</span>
            </button>
        );
    },
);

ButtonDatePicker.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

const DatePickerField = ({ inputProps }) => {
    const { name, value } = inputProps;
    // stored/initial dates provided are in the "MM/DD/YYYY" format
    // we need to convert those starting values to be date instances
    const dateFormattedValue = getDateInstanceFromString({
        dateString: value,
    });

    const {
        setFieldValue,
        values: { Start_Date, End_Date },
    } = useFormikContext();
    const { isSmallScreen } = useBreakpoints();

    // Remove props we do not want to pass down
    // from inputProps to date picker input
    const remainingInputProps = { ...inputProps };
    // uses internal react forward ref, our ref is not able to be passed down
    delete remainingInputProps.ref;
    // value is set by setting the selected date
    // which is referenced through the selected key
    delete remainingInputProps.value;

    // Mobile view replaces input with button to
    // open/close the date-picker
    const datePickerMobileProps = useMemo(
        () =>
            isSmallScreen
                ? {
                      customInput: <ButtonDatePicker />,
                      onClickOutside: () => {},
                  }
                : {},
        [isSmallScreen],
    );

    // -------------------------------------------------------------------------
    // MARK: Start/End Date Logic
    // -------------------------------------------------------------------------
    const startEndDateProps = useMemo(() => {
        // frame of reference for today's date
        const defaultDate = new Date();

        if (name === "Start_Date") {
            return { minDate: defaultDate };
        }
        if (name === "End_Date") {
            let dateFormattedStartDate = Start_Date;
            if (!Start_Date) {
                return { minDate: defaultDate };
            }
            if (Start_Date && typeof Start_Date === "string") {
                dateFormattedStartDate = getDateInstanceFromString({
                    dateString: Start_Date,
                });
            }
            return { minDate: addDays(dateFormattedStartDate, 1) };
        }
        return {};
    }, [Start_Date, name]);

    // need to unset End_Date if a stored End_Date value is older than
    // the stored Start_Date value
    useEffect(() => {
        // prevent logic from running when not start or end date fields
        if (
            Start_Date !== "" &&
            End_Date !== "" &&
            (name === "Start_Date" || name === "End_Date")
        ) {
            // ensure stored values are date objects
            let dateFormattedStartDate = Start_Date;
            let dateFormattedEndDate = End_Date;
            if (Start_Date && typeof Start_Date === "string") {
                dateFormattedStartDate = getDateInstanceFromString({
                    dateString: Start_Date,
                });
            }
            if (End_Date && typeof End_Date === "string") {
                dateFormattedEndDate = getDateInstanceFromString({
                    dateString: End_Date,
                });
            }
            // unset end date if before/same as start date
            if (
                Start_Date &&
                End_Date &&
                !isAfter(dateFormattedEndDate, dateFormattedStartDate)
            ) {
                setFieldValue("End_Date", "");
            }
        }
    }, [Start_Date, End_Date, name, setFieldValue]);

    // Because the calendar is displayed either above or below the date field
    // based on where there is room available we need to make sure there is
    // enough space for it to be displayed in either location without an issue
    const getVerticalRoomNeededToDisplayTooltip = () => {
        const heightOfDateField = 55;
        const heightOfCalendar = 350;
        const heightNeededToGoAboveOrBelow = heightOfCalendar * 2;
        const verticalRoomNeededToDisplayTooltip =
            heightOfDateField + heightNeededToGoAboveOrBelow;

        return verticalRoomNeededToDisplayTooltip;
    };

    // -------------------------------------------------------------------------
    // TODO: Checking for the presence of appContainer is just a band-aid fix
    // that was put in place at the last minute to ensure that date fields do
    // not crash forms that do not have an #app-container element to lock into.
    // However, we should probably revisit this to see if this is the ideal
    // solution for us to use in different environments. The decision to pay
    // attention to #app-container was borrowed from mutationObserver.js that
    // was used in form & we will probably need to clean up this logic for
    // legibility and to make sure that it works in a variety of environments.
    // -------------------------------------------------------------------------
    // Display calendar as modal if we cannot rely on there being enough
    // vertical room to position the date picker above or below the date field
    const getDisplayCalendarAsTooltip = () => {
        let displayCalendarAsToolTip = true;
        const verticalRoomNeededToDisplayTooltip =
            getVerticalRoomNeededToDisplayTooltip();
        const appContainer = document.getElementById("app-container");

        if (appContainer) {
            const boundingClientRect = appContainer.getBoundingClientRect();
            const { height: verticalRoomAvailable = 0 } = boundingClientRect;
            displayCalendarAsToolTip =
                verticalRoomAvailable > verticalRoomNeededToDisplayTooltip;
        }

        return displayCalendarAsToolTip;
    };

    const displayCalendarAsToolTip = getDisplayCalendarAsTooltip();

    // If withPortal is true the calendar will be displayed as a modal
    const withPortal = !displayCalendarAsToolTip;

    return (
        <DatePicker
            // passed down from FieldInput
            {...remainingInputProps}
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            name={name}
            // selected refers to date selected
            selected={dateFormattedValue}
            onChange={(date) => {
                const isValidDate = isValid(date);
                // we want to store null dates as empty strings
                const valueToSave = isValidDate
                    ? format(date, "MM/dd/yyyy")
                    : "";
                setFieldValue(name, valueToSave);
            }}
            popperClassName={cx(
                "custom-calendar-popout",
                name === "End_Date" &&
                    !isSmallScreen &&
                    "custom-calendar-popout--right-aligned",
            )}
            // did not have success with being able to change this value
            popperPlacement="top-start"
            // differs from the documentation setup
            popperModifiers={{
                flip: { behavior: ["bottom"] },
                preventOverflow: { enabled: true },
                strategy: "fixed",
            }}
            // allows us to style the day container
            dayClassName={() => "custom-day-container"}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
            }) => {
                const datePickerFieldCustomHeaderProps = {
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    isStartEndDateField:
                        name === "Start_Date" || name === "End_Date",
                };
                return (
                    <DatePickerFieldCustomHeader
                        {...datePickerFieldCustomHeaderProps}
                    />
                );
            }}
            withPortal={withPortal}
            // mobile view props
            {...datePickerMobileProps}
            // sets min date for Start_Date, End_Date fields
            {...startEndDateProps}
        />
    );
};

DatePickerField.propTypes = {
    inputProps: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
        ]),
    }).isRequired,
};

export default DatePickerField;
