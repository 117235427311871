import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { RefundCallouts, getFormattedPrice } from "@qgiv/core-donor";
import { selectReceiptTransaction } from "../../../../redux/slices/receiptsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";

const ConnectedRefundCallouts = () => {
    const transaction = useSelector(selectReceiptTransaction);
    const { refunds = [] } = transaction;

    const hasRefunds = refunds?.length > 0;

    const { currency } = useSelector(selectAllFormSettings);
    const formattedRefunds = refunds.map((refund) => {
        const { id, amount = 0, dateCreated = "" } = refund;
        // these are using a legacy BE Receipt function, so they are not strongly
        // typed and the amount value comes back as a negative string. Need to
        // convert these to fit into the structure the helper function is looking for...

        const idToUse = Number(id);
        const numberAmount = Number(amount);
        const positiveAmount = Math.abs(numberAmount);

        const formattedAmount = getFormattedPrice(positiveAmount, currency);
        const formattedDate = moment(dateCreated).format("MM/DD/YYYY");
        const displayRefund =
            positiveAmount > 0 && !!formattedAmount && !!formattedDate;
        const formattedRefund = {
            displayRefund,
            formattedAmount,
            formattedDate,
            id: idToUse,
        };

        return formattedRefund;
    });
    // Because older refunds were created before newer refunds, the ids of
    // older refunds are lower than newer refunds. And because of this we can
    // use the id values to sort the refunds so that the older refunds are
    // at the top of the UI and the newer refunds are at the bottom of the UI
    formattedRefunds.sort((firstRefund, secondRefund) => {
        if (firstRefund.id < secondRefund.id) {
            return -1;
        }
        if (firstRefund.id > secondRefund.id) {
            return 1;
        }
        return 0;
    });

    return (
        <RefundCallouts
            hasRefunds={hasRefunds}
            formattedRefunds={formattedRefunds}
        />
    );
};

export default ConnectedRefundCallouts;
