import React from "react";
import PropTypes from "prop-types";
import ConnectedReceiptDetailsGifts from "./ConnectedReceiptDetailsGifts";
import ConnectedReceiptTotal from "./ConnectedReceiptTotal";
import ConnectedRefundCallouts from "./ConnectedRefundCallouts";

const ReceiptDetails = ({ control = {} }) => (
    <div className="col col--12">
        <div className="grid">
            <ConnectedReceiptDetailsGifts control={control} />
            <div className="col col--12 col--no-top-gutter">
                <ConnectedReceiptTotal control={control} />
            </div>
            <ConnectedRefundCallouts control={control} />
        </div>
    </div>
);

ReceiptDetails.propTypes = {
    control: PropTypes.shape({}).isRequired,
};

export default ReceiptDetails;
