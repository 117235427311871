import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";

import "./UpgradeNudge.scss";
import { Button } from "@qgiv/core-donor";

const UpgradeNudge = ({ formattedNudgeMessage, handleClick }) => (
    <div className="col col--12">
        <div className="qg-vendor-upgrade-nudge donation-upgrade-nudge">
            <Button
                btnStyle="tertiary"
                name="Recurring_Donation"
                value
                onClick={handleClick}
            >
                <p>
                    <Icon
                        classNames={[
                            "-color--theme-primary",
                            "-type--xlarge",
                            "-margin-right--10",
                            "-vertical-align--middle",
                        ]}
                        glyph="qgiv-impact"
                        type="Custom"
                    />
                    {formattedNudgeMessage}
                </p>
            </Button>
        </div>
    </div>
);

UpgradeNudge.propTypes = {
    formattedNudgeMessage: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default UpgradeNudge;
