import React from "react";
import PropTypes from "prop-types";

import useImageSliderContext from "../../hooks/useImageSliderContext";

const useThumbnailClick = (imageKey, onClick) => {
    const { onToggle, activeImageKey } = useImageSliderContext();

    return (event) => {
        // Logic for toggling with image thumbnail selection
        onToggle(imageKey === activeImageKey ? activeImageKey : imageKey);

        if (onClick) {
            onClick(event);
        }
    };
};

const ImageThumbnail = ({
    element: Component = "div",
    imageKey,
    onClick,
    children,
    size = "regular",
    shape = "rounded",
}) => {
    const { activeImageKey } = useImageSliderContext();
    const thumbnailClick = useThumbnailClick(imageKey, onClick);

    return (
        <Component
            onClick={thumbnailClick}
            shape={shape}
            size={size}
            className={`thumbnail ${
                activeImageKey === imageKey
                    ? "thumbnail--active"
                    : "thumbnail--inactive"
            }`}
        >
            <div
                className={`thumbnail__border--${shape} thumbnail__size--${size}`}
            >
                {children}
            </div>
        </Component>
    );
};

ImageThumbnail.propTypes = {
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.object.isRequired,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    imageKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    onClick: PropTypes.func,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    shape: PropTypes.string.isRequired,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    size: PropTypes.string.isRequired,
};

export default ImageThumbnail;
