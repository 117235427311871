import { constants } from "@qgiv/core-js";
import {
    getMultiRestrictionFieldValues,
    getShouldRenderMultiRestriction,
    getOtherOneTimeAmountInitialValue,
    getOtherRecurringAmountInitialValue,
    getRecurringFrequencyInitialValue,
    getSelectedOneTimeId,
    getSelectedStandardRecurringId,
    getStandardRestrictionInitialValue,
    getPreselectedInitialValues,
    getUrlShortcutsInitialValues,
    getHasEnabledActiveStandardRestrictions,
    getTextToDonateInitialValues,
} from "@qgiv/donation-form";
import moment from "moment";
import { selectCmsControlByTypeByPage } from "../slices/cmsSettingsSlice";

const {
    ENUMS: { CmsPageStandardizer, CMS_Control, ItemType, GivingPlanSystemType },
} = constants;

// -------------------------------------------------------------------------
// NOTE: Order of operation. Leaving this here for now until we add in
// urlShorts, invoices and plans. These will likely be separated.
// -------------------------------------------------------------------------
// TODO: Can this logic be cleaned up? Should these sections be separated by
// Plan, Invoice, urlShortcuts?
// -------------------------------------------------------------------------
// 1. Check for invoice data. If invoice is recurring, return true,
//    otherwise return false
// 2. Check for a plan. Plan always returns true
// 3. Check if the url contains a urlShortcut, an amount, a recurring
//    flag and if recurring donations are actually enabled
// 4. Check if the url contains a urlShortcut, an amount, a onetime
//    flag and if onetime donations are actually enabled
// 5. Check if the url contains a urlShortcut, an amount and a
//    onetime flag
//--------------------------------------------------------------------------
// Basic Standard Settings
//--------------------------------------------------------------------------
// 6. If recurring donations are enabled check the default tab setting
//    from cms
// 7. If one time donations are enabled check the default tab setting
//    from cms
//  --------------------------------------------------------------
// 8. If one time donations are enabled, recurring donations are
//    enabled there is at least one one-time donation to display
//    and there are no recurring donations to display
// 9. If one time donations are enabled, recurring donations are
//    enabled there are no one time donations to display but there
//    is a recurring donation to display
// 10. If all else fails, check that one time donations are disabled,
//    and that recurring donations are enabled
// -------------------------------------------------------------------------
/**
 * @public
 * @function getHasSelectedRecurringDonationInitialState
 * @description sets the recurring tab on page load
 * @param {object} formSettings form object from initial state
 * @param {object} donationSettings donation settings from initial state
 * @param {object} cmsSettings cms settings from initial state
 * @returns {boolean} Boolean of whether we are on recurring tab
 */
export const getHasSelectedRecurringDonationInitialState = (
    formSettings,
    donationSettings,
    cmsSettings,
) => {
    const { enableRecur, donationActive } = formSettings;
    const { activeAmounts } = donationSettings;
    const hasSomeOneTimeAmounts = activeAmounts.some(
        (amount) => amount.displayForOneTime,
    );
    const hasSomeRecurringAmounts = activeAmounts.some(
        (amount) => amount?.displayForRecurring,
    );

    const {
        options: { defaultTab },
    } = selectCmsControlByTypeByPage(
        { cmsSettings },
        CMS_Control.STANDARD_DONATION_GIFT_CONTENT,
        CmsPageStandardizer.DONATION_AMOUNTS,
    );

    let shouldRecurringTabBeSelected = false;

    const hasRecur = enableRecur && hasSomeRecurringAmounts;
    const isRecurDefault = defaultTab === "ongoing";

    const hasOneTime = donationActive && hasSomeOneTimeAmounts;

    if ((hasRecur && isRecurDefault) || !hasOneTime) {
        shouldRecurringTabBeSelected = true;
    }

    return shouldRecurringTabBeSelected;
};

/**
 * @public
 * @function getRecurringFrequencyInitialState
 * @description sets the recurring frequency on page load
 * @param {object} donationSettings donation settings from initial state
 * @param {object} formSettings form object from initial state
 * @returns {string} String for which frequency should be selected this should match
 *                  Formik's initial state
 */
export const getRecurringFrequencyInitialState = (
    donationSettings,
    formSettings,
) => {
    const settings = { donationSettings };

    const { enableRecur } = formSettings;

    // -------------------------------------------------------------------------
    // NOTE: This function is used to set both the initial Formik value and the
    // corresponding initial Redux value. Sharing this function allows
    // us to maintain one source of initial value truth and prevents us from
    // having to listen for the Formik value to change on page load in order to
    // update the Redux value. The Recurring_Frequency value should only be added
    // if enableRecur is true.
    // -------------------------------------------------------------------------

    return enableRecur
        ? { Recurring_Frequency: getRecurringFrequencyInitialValue(settings) }
        : {};
};

/**
 * @public
 * @function getSelectedRecurringIdInitialState
 * @description sets the standard selected recurring id on page load
 * @param {object} donationSettings donation settings from initial state
 * @param {object} formSettings form object from initial state
 * @returns {string} object with Selected Recurring Id name and value
 * should be selected this should match Formik's initial state
 */
export const getSelectedRecurringIdInitialState = (
    donationSettings,
    formSettings,
) => {
    const { enableRecur } = formSettings;
    const { activeAmounts, enableEndDate, pledgeActive } = donationSettings;
    const hasSomeRecurringAmounts = activeAmounts.some(
        (amount) => amount.displayForRecurring,
    );
    const hasOtherRecurring = activeAmounts.some(
        (amount) =>
            amount.displayForRecurring &&
            parseInt(amount.amountType, 10) === ItemType.OTHER,
    );
    const recurringInput = hasSomeRecurringAmounts
        ? {
              Selected_Recurring_Id: getSelectedStandardRecurringId({
                  donationSettings,
                  formSettings,
              }),
          }
        : {};
    const recurringOtherInput = hasOtherRecurring
        ? {
              Other_Recurring_Amount: getOtherRecurringAmountInitialValue({
                  donationSettings,
                  formSettings,
              }),
          }
        : {};

    // -------------------------------------------------------------------------
    // NOTE: Even if enableStartDate is Off, today will be set as the start date.
    // -------------------------------------------------------------------------
    const recurringStartDate =
        hasSomeRecurringAmounts || hasOtherRecurring
            ? {
                  Start_Date: moment().format("MM/DD/YYYY"),
              }
            : {};

    const recurringEndDate =
        (hasSomeRecurringAmounts || hasOtherRecurring) && enableEndDate
            ? {
                  End_Date: "",
              }
            : {};

    return (
        enableRecur &&
        !pledgeActive && {
            ...recurringInput,
            ...recurringOtherInput,
            ...recurringEndDate,
            ...recurringStartDate,
        }
    );
};

/**
 * @public
 * @function getSelectedOneTimeIdInitialState
 * @description sets the standard selected OneTime id on page load
 * @param {object} donationSettings donation settings from initial state
 * @param {object} formSettings form object from initial state
 * @returns {string} object with Selected OneTime Id name and value
 * should be selected this should match Formik's initial state
 */
export const getSelectedOneTimeIdInitialState = (
    donationSettings,
    formSettings,
) => {
    const { donationActive } = formSettings;
    const { activeAmounts } = donationSettings;
    const hasSomeOneTimeAmounts = activeAmounts.some(
        (amount) => amount.displayForOneTime,
    );
    const hasOtherOneTime = activeAmounts.some(
        (amount) =>
            amount.displayForOneTime &&
            parseInt(amount.amountType, 10) === ItemType.OTHER,
    );

    const oneTimeInput = hasSomeOneTimeAmounts
        ? {
              Selected_One_Time_Id: getSelectedOneTimeId({
                  donationSettings,
                  formSettings,
              }),
          }
        : {};
    const oneTimeOtherInput = hasOtherOneTime
        ? {
              Other_One_Time_Amount: getOtherOneTimeAmountInitialValue(),
          }
        : {};

    return (
        donationActive && {
            ...oneTimeInput,
            ...oneTimeOtherInput,
        }
    );
};

/**
 * @public
 * @function getGivingPlansInitialState
 * @description sets the initial values for giving plans
 * @param {object} donationSettings form donationSettings object from initial state
 * @returns {object} object containing giving plan initial values based on giving
 * plan configuration in control panel
 */
const getGivingPlansInitialState = (donationSettings) => {
    const { activePlans, defaultRecurringFrequency, givingPlanSystemType } =
        donationSettings;

    const createYourOwnPlan =
        activePlans.find(
            (plan) =>
                parseInt(plan.pledgeType, 10) === ItemType.CREATE_YOUR_OWN,
        ) || {};

    const hasCreateYourOwnPlan = Object.keys(createYourOwnPlan).length > 0;

    const standardGivingPlans =
        activePlans.find(
            (plan) =>
                parseInt(plan.pledgeType, 10) !== ItemType.CREATE_YOUR_OWN,
        ) || {};

    const hasPredefinedGivingPlans =
        Object.keys(standardGivingPlans).length > 0 &&
        givingPlanSystemType === GivingPlanSystemType.PREDEFINED;

    const hasFlexibleGivingPlans =
        Object.keys(standardGivingPlans).length > 0 &&
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    const createYourOwnPlanInitialValues = {
        Selected_Recurring_Id: "",
        Other_Recurring_Amount: "",
    };

    const predefinedGivingPlansInitialValues = {
        Selected_Plan: "",
    };

    const flexibleGivingPlanInitialValues = {
        Installment_Count: 0,
        Selected_Plan: "",
    };

    return {
        ...(hasCreateYourOwnPlan && { ...createYourOwnPlanInitialValues }),
        ...(hasPredefinedGivingPlans && {
            ...predefinedGivingPlansInitialValues,
        }),
        ...(hasFlexibleGivingPlans && { ...flexibleGivingPlanInitialValues }),
        Start_Date: "",
        End_Date: "",
        Recurring_Frequency: defaultRecurringFrequency.value,
    };
};

const getInvoiceFulfillmentInitialState = (promiseTransaction) => ({
    Invoice_Amount_Paid: promiseTransaction.amount,
    Invoice_Complete_Fulfillment: false,
    Invoice_Fulfill_Outstanding: false,
    Invoice_Token: promiseTransaction.token,
    total: Number(promiseTransaction.amount),
    subtotal: Number(promiseTransaction.amount),
});

/**
 * @public
 * @function getGiftAssistInitialState
 * @description sets the standard selected OneTime id on page load
 * @param {object} formSettings form object from initial state
 * @returns {string} object with Selected OneTime Id name and value
 * should be selected this should match Formik's initial state
 */
export const getGiftAssistInitialState = (formSettings) => {
    const { feeCoverage, enableGiftAssistByDefault } = formSettings;

    const giftAssistInput = {
        Gift_Assist: enableGiftAssistByDefault,
        giftAssistAmount: 0,
    };

    if (Number(feeCoverage) > 0) {
        return giftAssistInput;
    }
    return {};
};

// -------------------------------------------------------------------------
// TODO: Tests need to be added for all of the multi-restriction specific
// functions in here before the multi-restriction project has wrapped up.
// -------------------------------------------------------------------------
/**
 * @public
 * @function getMultiRestrictionInitialState
 * @param {object} settings settings object
 * @param {number} settings.currentDisplay current display enum from initial state
 * @param {object[]} settings.restrictions restrictions array from initial state
 * @returns {object} object with data for the different multi-restriction fields
 */
const getMultiRestrictionInitialState = ({ currentDisplay, restrictions }) => {
    const multiRestrictionFieldValues = getMultiRestrictionFieldValues({
        currentDisplay,
        restrictions,
    });

    return {
        ...multiRestrictionFieldValues,
    };
};

/**
 * @public
 * @function getTotalsInitialState
 * @description sets the initial state for total and subtotal, if urlShortcuts or preselected amounts are present
 * those new totals will override these from preselectedInitialValues.js and urlShortcutsInitialValues.js
 * @returns {object} object with total, subtotal and giftAssistAmount beginning balance which may not be 0 if we start
 *  with urlShortcuts
 */
export const getTotalsInitialState = () => {
    const subtotal = 0.0;
    const giftAssistAmount = 0.0;

    const total = subtotal + giftAssistAmount;

    const totalInitialState = {
        // total of donation amounts only
        subtotal,
        // total donation amounts + gift assist
        total,
    };

    return { ...totalInitialState };
};

// TODO: Should be false if no oneTime frequency or Invoice or Multi restriction [Later]
/**
 * @function getShouldBuildStandardOneTime
 * @description getter for if form should build onetime amount default values
 * @param {object} settings passed argument
 * @param  {boolean} settings.donationActive flag for one time donations enabled
 * @param {boolean} settings.hasActiveFulfillment flag for form loading invoice fulfillment
 * @returns {boolean} shouldBuildStandardOneTime
 */
export const getShouldBuildStandardOneTime = ({
    donationActive = false,
    hasActiveFulfillment = false,
}) => donationActive && !hasActiveFulfillment;

// TODO: Should be false if !enableRecur || is invoice || is Multi restriction || is Plan
// Notice, this will need to be included/changed for giving plans branch
/**
 * @function getShouldBuildStandardRecurring
 * @description getter for if form should build recurring amount default values
 * @param {object} settings passed arguments
 * @param {boolean} settings.enableRecur flag for ongoing donations enabled
 * @param {boolean} settings.hasActiveFulfillment flag for form loading invoice fulfillment
 * @param {object} settings.pledgeActive flag for giving plans donation system
 * @returns {boolean} shouldBuildStandardRecurring
 */
export const getShouldBuildStandardRecurring = ({
    enableRecur = false,
    hasActiveFulfillment = false,
    pledgeActive = false,
}) => enableRecur && !hasActiveFulfillment && !pledgeActive;

/**
 * @function getShouldBuildGivingPlans
 * @description getter for if form should build giving plan default values
 * @param {object} settings passed arguments
 * @param {boolean} settings.activePlans object containing all giving plan data
 * @param {boolean} settings.pledgeActive flag for form using Giving Plans system
 * @param {boolean} settings.hasActiveFulfillment flag for form loading invoice fulfillment
 * @returns {boolean} shouldBuildGivingPlans
 */
export const getShouldBuildGivingPlans = ({
    activePlans,
    pledgeActive,
    hasActiveFulfillment,
}) => !hasActiveFulfillment && activePlans?.length > 0 && pledgeActive;

// TODO: Should be true if enablePreselectAmount && not is invoice && not is Multi restriction && not is Plan
/**
 * @function getPreselectedInitialValues
 * @description getter for if form should build preselected amounts data
 * @param {object} settings passed argument
 * @param {boolean} settings.enablePreselectAmount flag for preselected amounts are enabled
 * @param {boolean} settings.hasActiveFulfillment flag for form loading invoice fulfillment
 * @returns {boolean} shouldBuildPreselectedAmounts
 */
export const getShouldBuildPreselectValues = ({
    enablePreselectAmount = false,
    hasActiveFulfillment = false,
}) => !!enablePreselectAmount && !hasActiveFulfillment;

/**
 * @function getShouldBuildGiftAssist
 * @description getter for if form should build gift assist default values
 * @param {object} settings passed argument
 * @param {number} settings.feeCoverage number that denotes type of gift assist that is enabled or is disabled
 * @param {boolean} settings.hasActiveFulfillment flag for form loading invoice fulfillment
 * @returns {boolean} shouldBuildGiftAssist
 */
export const getShouldBuildGiftAssist = ({
    feeCoverage = 0,
    hasActiveFulfillment = false,
}) => feeCoverage > 0 && !hasActiveFulfillment;

/**
 *
 * @function getShouldBuildUrlShortcuts
 * @description getter for if form should build selected values from urlShortcuts
 * @param {object} urlShortcuts data from initial state
 * @param {number} [urlShortcuts.amount] number which is the amount's id to select
 * @param {boolean} [urlShortcuts.onetime] flag for if amount is a one time amount
 * @param {boolean} [urlShortcuts.recurring] flag for if amount is a recurring amount
 * @param {number} [urlShortcuts.restriction] number which is the restriction's id to select
 * @returns {boolean} shouldBuildUrlShortcuts
 */
export const getShouldBuildUrlShortcuts = (urlShortcuts) => {
    const urlShortcutsHasKeys = Object.keys(urlShortcuts).length > 0;
    // acceptedKeys are when urlShortcut data is used to build initial selected state
    const acceptedKeys = [
        "recurring",
        "onetime",
        "amount",
        "restriction",
        "pledge",
        "amountValue",
        "otherAmount",
    ];
    if (urlShortcutsHasKeys) {
        const hasValidKey = acceptedKeys.some(
            (acceptedKey) => !!urlShortcuts?.[acceptedKey],
        );
        return hasValidKey;
    }
    return false;
};

/**
 * @function getShouldBuildTextToDonate
 * @description getter for if form should build selected values from smsData
 * @param {object} settings passed arguments
 * @param {object} settings.smsData data from initial state for sms
 * @param {boolean} settings.hasActiveFulfillment flag for form loading invoice fulfillment
 * @returns {boolean} shouldBuildTextToDonate
 */
export const getShouldBuildTextToDonate = ({
    smsData = { amount: 0 },
    hasActiveFulfillment = false,
}) => {
    const { amount = 0 } = smsData;
    const shouldBuildTextToDonate = amount > 0 && !hasActiveFulfillment;
    return shouldBuildTextToDonate;
};

export const getShouldBuildFlagsForInitialValues = ({
    currentDisplay,
    donationActive,
    enablePreselectAmount,
    enableRecur,
    feeCoverage,
    hasActiveFulfillment,
    restrictionGivingType,
    restrictionSettings,
    pledgeActive,
    activePlans,
    smsData,
    urlShortcuts,
}) => {
    const { restrictions = [] } = restrictionSettings;
    const shouldBuildStandardOneTime = getShouldBuildStandardOneTime({
        donationActive,
        hasActiveFulfillment,
    });

    const shouldBuildStandardRecurring = getShouldBuildStandardRecurring({
        enableRecur,
        hasActiveFulfillment,
    });

    const shouldBuildGivingPlans = getShouldBuildGivingPlans({
        activePlans,
        pledgeActive,
        hasActiveFulfillment,
    });

    const shouldBuildPreselectValues = getShouldBuildPreselectValues({
        enablePreselectAmount,
        hasActiveFulfillment,
    });

    const shouldBuildGiftAssist = getShouldBuildGiftAssist({
        feeCoverage,
        hasActiveFulfillment,
    });

    const shouldBuildUrlShortcuts = getShouldBuildUrlShortcuts(urlShortcuts);

    const shouldBuildStandardRestriction =
        getHasEnabledActiveStandardRestrictions({
            restrictions,
            hasActiveFulfillment,
            currentDisplay,
            restrictionGivingType,
        });

    const shouldBuildMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    const shouldBuildTextToDonate = getShouldBuildTextToDonate({
        smsData,
        hasActiveFulfillment,
    });

    return {
        shouldBuildGiftAssist,
        shouldBuildPreselectValues,
        shouldBuildStandardOneTime,
        shouldBuildStandardRecurring,
        shouldBuildGivingPlans,
        shouldBuildMultiRestriction,
        shouldBuildStandardRestriction,
        shouldBuildTextToDonate,
        shouldBuildUrlShortcuts,
    };
};

export const getGiftDetailsBucketInitialState = ({
    cmsSettings,
    config,
    donationSettings,
    formSettings,
    hasActiveFulfillment,
    restrictionSettings,
    smsData,
    urlShortcuts,
    promiseTransaction,
}) => {
    const { donationActive, enableRecur, feeCoverage } = formSettings;
    const {
        enablePreselectAmount,
        restrictionGivingType,
        pledgeActive,
        activePlans,
    } = donationSettings;
    const { currentDisplay } = config;
    const { restrictions = [] } = restrictionSettings;

    const hasSelectedRecurringDonation =
        getHasSelectedRecurringDonationInitialState(
            formSettings,
            donationSettings,
            cmsSettings,
        );

    const hasAcceptedRecurringUpgrade = false;

    const {
        shouldBuildGiftAssist,
        shouldBuildPreselectValues,
        shouldBuildStandardOneTime,
        shouldBuildStandardRecurring,
        shouldBuildGivingPlans,
        shouldBuildMultiRestriction,
        shouldBuildStandardRestriction,
        shouldBuildTextToDonate,
        shouldBuildUrlShortcuts,
    } = getShouldBuildFlagsForInitialValues({
        currentDisplay,
        donationActive,
        enablePreselectAmount,
        enableRecur,
        feeCoverage,
        hasActiveFulfillment,
        restrictionGivingType,
        restrictionSettings,
        smsData,
        urlShortcuts,
        pledgeActive,
        activePlans,
    });

    const giftDetails = {
        hasAcceptedRecurringUpgrade,
        hasSelectedRecurringDonation,
        // Totals before preselect or urlShortcuts
        ...getTotalsInitialState(),
        // Standard Amounts
        ...(shouldBuildStandardOneTime && {
            ...getSelectedOneTimeIdInitialState(donationSettings, formSettings),
        }),
        // Standard Recurring
        ...(shouldBuildStandardRecurring && {
            ...getSelectedRecurringIdInitialState(
                donationSettings,
                formSettings,
            ),
            ...getRecurringFrequencyInitialState(
                donationSettings,
                formSettings,
            ),
        }),
        // Giving Plans
        ...(shouldBuildGivingPlans && {
            ...getGivingPlansInitialState(donationSettings),
        }),
        // GiftAssist
        ...(shouldBuildGiftAssist && {
            ...getGiftAssistInitialState(formSettings),
        }),
        // Restrictions
        ...(shouldBuildStandardRestriction && {
            ...getStandardRestrictionInitialValue({
                restrictionSettings,
                currentDisplay: config.currentDisplay,
                donationSettings,
            }),
        }),
        // Multi-Restrictions
        ...(shouldBuildMultiRestriction && {
            ...getMultiRestrictionInitialState({
                currentDisplay,
                restrictions,
            }),
        }),

        // -------------------------------------------------------------------------
        // NOTE: PreSelected and urlShortcuts are set here on page load
        // -------------------------------------------------------------------------

        // Override initial Totals and standard values with Preselected Amount
        ...(shouldBuildPreselectValues && {
            ...getPreselectedInitialValues({
                donationSettings,
                formSettings,
                hasSelectedRecurringDonation,
            }),
        }),

        // Override initial Totals and standard values with TextToDonate
        ...(shouldBuildTextToDonate && {
            ...getTextToDonateInitialValues({
                donationSettings,
                formSettings,
                smsData,
            }),
        }),

        // Override initial Totals, standard values, and Preselected Amount with url shortcuts
        ...(shouldBuildUrlShortcuts && {
            ...getUrlShortcutsInitialValues({
                formSettings,
                donationSettings,
                urlShortcuts,
                restrictions,
                hasActiveFulfillment,
                currentDisplay,
            }),
        }),

        // override all with invoice data
        ...(hasActiveFulfillment && {
            ...getInvoiceFulfillmentInitialState(promiseTransaction),
        }),
    };
    return giftDetails;
};
