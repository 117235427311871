import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Tab from "./Tab";
import "./Tabs.scss";

const Tabs = forwardRef(
    ({ size = "regular", children, handleClick = () => {} }, ref) => {
        const _children = React.Children.toArray(children);
        const initialActiveChild =
            _children[_children.findIndex((child) => child.props.active)];
        const getActiveTabId = () =>
            typeof initialActiveChild !== "undefined"
                ? initialActiveChild.props.id
                : _children[0].props.id;
        const [activeTab, setActiveTab] = useState(getActiveTabId());
        const getActiveIndex = () => {
            let ret =
                _children.findIndex((child) => child.props.id === activeTab) +
                1;
            return ret > 0 ? ret : 1;
        };
        const indicatorClasses = cx(
            "tabs__indicator",
            `tabs__indicator--tabs-${_children.length}`,
            `tabs__indicator--active-tab-${getActiveIndex()}-of-${
                _children.length
            }`,
        );

        // Go through all of the elements that have been passed into <Tabs/> as
        // children, return an array and if any of the values in that array has
        // changed there has been a change to active prop value passed to that
        // element. And once this value has changed, update the activeTab value
        useEffect(() => {
            setActiveTab(getActiveTabId());
        }, [
            ..._children.map((child) =>
                child.props.active ? child.props.active : undefined,
            ),
        ]);

        // Use the tab id to update the activeTab value whenever a tab is clicked
        // before passing the tab id to any additional logic has been passed down
        // and is supposed to run whenever a tab is clicked
        const handleTabClick = (id) => {
            setActiveTab(id);
            handleClick(id);
        };

        return (
            <div className="qg-vendor-tabs tabs">
                <nav className="tabs__nav">
                    <ul
                        className="tabs__ul"
                        tabIndex="0"
                        role="tablist"
                        ref={ref}
                    >
                        {_children.map((child) => {
                            const { id, label } = child.props;
                            return (
                                <Tab
                                    id={id}
                                    activeTab={activeTab}
                                    key={id}
                                    label={label}
                                    handleTabClick={handleTabClick}
                                    size={size}
                                />
                            );
                        })}
                    </ul>
                </nav>
                <div className="qg-vendor-tabs__indicator-ui tabs__indicator-ui">
                    <div className="tabs__indicator-bar"></div>
                    <div className={indicatorClasses}></div>
                </div>
                <div className="qg-vendor-tabs__content tabs__content">
                    {_children.map((child) => {
                        if (child.props.id !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        );
    },
);

Tabs.displayName = "Tabs";

Tabs.propTypes = {
    size: PropTypes.string,
    children: PropTypes.any.isRequired,
    handleClick: PropTypes.func,
};

export default Tabs;
