import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const PopperTrigger = forwardRef(
    ({ children, className, onClick, ...rest }, ref) => (
        <button
            className={cx("popper__trigger", className)}
            type="button"
            {...{ onClick, ref }}
            {...rest}
        >
            {children}
        </button>
    ),
);

PopperTrigger.displayName = "PopperTrigger";

PopperTrigger.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default PopperTrigger;
