import React from "react";
import PropTypes from "prop-types";
import ConnectedReceiptHeader from "./ConnectedReceiptHeader";
import ConnectedErrorDisplay from "../../common/ErrorDisplay/ConnectedErrorDisplay";
import ConnectedMessageDisplay from "../../common/MessageDisplay/ConnectedMessageDisplay";

const ReceiptHeaderControl = ({ controlId }) => (
    <>
        <ConnectedErrorDisplay />
        <ConnectedMessageDisplay />
        <ConnectedReceiptHeader controlId={controlId} />
    </>
);

ReceiptHeaderControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ReceiptHeaderControl;
