import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";

const PaymentPageContainers = ({ containers }) => {
    const containerProps = {
        page: "payment-page",
    };

    return containers.map((container) => (
        <Container
            key={container.id}
            container={container}
            {...containerProps}
        />
    ));
};

PaymentPageContainers.propTypes = {
    containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PaymentPageContainers;
