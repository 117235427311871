import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { stringToType } from "@qgiv/core-js";
import useTooltip from "../../../hooks/useTooltip";
import TextField from "../TextField";
import "./BankFields.scss";

const BankFields = ({
    accountNumberLabel = "Account Number",
    billingNameLabel = "Name on Account",
    routingNumberLabel = "Routing Number",
    showBillingNameField = true,
}) => {
    const { errors, handleBlur, handleChange, setValues, touched, values } =
        useFormikContext();

    const { Billing_Name, Account_Number, Routing_Number } = values;

    const _handleChange = (e, QMask) => {
        const currentTarget = e.currentTarget || QMask.el.input;
        const { name } = currentTarget;
        let value = "";
        if (QMask) {
            value = stringToType(QMask.unmaskedValue);
        } else {
            value = stringToType(currentTarget.value);
        }
        switch (name) {
            default:
                setValues({
                    ...values,
                    [name]: value,
                });
                break;
        }
        if (typeof handleChange === "function") {
            handleChange(e);
        }
    };

    const getAccountTooltip = useTooltip({
        message: ` Your bank account number is the second set
        of numbers at the bottom of your check. It
        can often be found in your online banking
        account, too.`,
    });

    const getRoutingTooltip = useTooltip({
        message: `Your bank’s routing number is the first set
        of numbers on the bottom left corner of your
        check. It can often be found on your bank’s
        website, too.`,
    });

    return (
        <div className="-callout -margin-top--15 -padding-top--0 bank-fields-container">
            <div className="grid">
                {showBillingNameField && (
                    <div className="col col--12">
                        <TextField
                            type="text"
                            name="Billing_Name"
                            label={billingNameLabel}
                            autoComplete="cc-name"
                            handleBlur={handleBlur}
                            handleChange={_handleChange}
                            value={Billing_Name || ""}
                            error={
                                touched.Billing_Name &&
                                errors.Billing_Name &&
                                errors.Billing_Name.length > 0
                            }
                            errorText={errors.Billing_Name}
                        />
                    </div>
                )}

                <div className="col col--12 routing-input">
                    <TextField
                        name="Routing_Number"
                        type="checkRouting"
                        label={routingNumberLabel}
                        value={Routing_Number}
                        handleBlur={handleBlur}
                        handleChange={_handleChange}
                        error={
                            touched.Routing_Number &&
                            errors.Routing_Number &&
                            errors.Routing_Number.length > 0
                        }
                        errorText={errors.Routing_Number}
                        ariaLabel={routingNumberLabel}
                        ariaRequired="true"
                    />
                    <span className="routing-input__tooltip">
                        {getRoutingTooltip}
                    </span>
                </div>

                <div className="col col--12 account-input">
                    <TextField
                        name="Account_Number"
                        label={accountNumberLabel}
                        value={Account_Number}
                        handleBlur={handleBlur}
                        handleChange={_handleChange}
                        error={
                            touched.Account_Number &&
                            errors.Account_Number &&
                            errors.Account_Number.length > 0
                        }
                        errorText={errors.Account_Number}
                        ariaLabel={accountNumberLabel}
                        ariaRequired="true"
                    />
                    <span className="account-input__tooltip">
                        {getAccountTooltip}
                    </span>
                </div>
            </div>
        </div>
    );
};

BankFields.propTypes = {
    accountNumberLabel: PropTypes.string,
    billingNameLabel: PropTypes.string,
    routingNumberLabel: PropTypes.string,
    showBillingNameField: PropTypes.bool,
};

export default BankFields;
