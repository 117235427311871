import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { WidgetSocialSharing } from "@qgiv/core-donor";
import {
    selectConfig,
    selectIsCms,
    selectWidgetSharingBaseUrl,
} from "../../../redux/slices/configSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../redux/slices/cmsSettingsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";

const ConnectedWidgetSocialSharing = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { CONFIRMATION },
        },
    } = constants;
    const formSettings = useSelector(selectAllFormSettings);
    const config = useSelector(selectConfig);
    const { embed } = config;
    const { orgName } = formSettings;
    // If the form is not embedded on an external website, we need to generate
    // a url for use by social sharing options.
    const nonEmbeddedRedirectUrl = useSelector(selectWidgetSharingBaseUrl);
    const isCms = useSelector(selectIsCms);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, CONFIRMATION),
    );
    const { options } = control;
    const {
        heading = "Let your friends know!",
        additionalText = "Share with friends to help spread the word.",
        contentTweet = "I just donated to %ORGNAME%! Will you join me in supporting their cause?",
        enableFacebookSharing = "1",
        enableTwitterSharing = "1",
    } = options;

    // Emails are being ignored for now in events because emails are displayed
    // using a modal and we do not think displaying a modal on top of a modal
    // is a good idea right now
    const displayEmailButton = false;
    const displaySocialSharingEmailModal = false;
    const enableEmail = false;
    const handleDisplaySocialSharingEmailModal = () => {};
    const handleRemoveSocialSharingEmailModal = () => {};
    const handleSubmitSharingEmailPath = () => {};
    const widgetSocialSharingEmailModalProps = {};

    const enableFacebook = !!enableFacebookSharing;
    const enableTwitter = !!enableTwitterSharing;

    const sharingOptionsCount =
        (enableFacebook ? 1 : 0) + (enableTwitter ? 1 : 0);

    const buttonClass =
        sharingOptionsCount === 3 ? "col--4" : "col--6 col--sm-4";

    // Generate URLs and content that will be used for sharing on Facebook and Twitter
    const sharerUrl = embed && embed.url ? embed.url : nonEmbeddedRedirectUrl;
    const encodedFacebookURL = encodeURIComponent(sharerUrl);
    const encodedTwitterURL = encodeURIComponent(sharerUrl);
    const encodedTwitterContent = encodeURIComponent(
        contentTweet.replace("%ORGNAME%", orgName),
    );
    const facebookShareUrl = `http://www.facebook.com/sharer/sharer.php?u=${encodedFacebookURL}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodedTwitterURL}&text=${encodedTwitterContent}`;

    const handleFacebookButtonClick = () => {
        if (!isCms) {
            window.open(facebookShareUrl, "_blank", "height=475,width=550");
        }
    };

    const handleTwitterButtonClick = () => {
        if (!isCms) {
            window.open(twitterShareUrl, "_blank", "height=475,width=550");
        }
    };

    const sharingProps = {
        heading,
        additionalText,
        buttonClass,
        displayEmailButton,
        displaySocialSharingEmailModal,
        enableEmail,
        enableFacebook,
        enableTwitter,
        handleDisplaySocialSharingEmailModal,
        handleFacebookButtonClick,
        handleRemoveSocialSharingEmailModal,
        handleSubmitSharingEmailPath,
        handleTwitterButtonClick,
        isCms,
        widgetSocialSharingEmailModalProps,
    };

    return <WidgetSocialSharing {...sharingProps} />;
};

ConnectedWidgetSocialSharing.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default ConnectedWidgetSocialSharing;
