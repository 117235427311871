import React from "react";
import { useSelector } from "react-redux";
import { CCFields } from "@qgiv/core-donor";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import {
    selectCCFieldsData,
    selectHasBillingNameField,
} from "../../../../redux/slices/fieldsSlice";

const ConnectedCCFields = () => {
    const ccFieldsData = useSelector(selectCCFieldsData);

    const {
        cardNumberLabel = "Card Number",
        expirationDateLabel = "Exp. Date",
        cvvLabel = "CVV",
    } = ccFieldsData;

    const {
        exists = false,
        display_text_cc = "Name on card",
        required = false,
    } = useSelector(selectHasBillingNameField);

    const showBillingNameField = exists;
    const billingDescText = required ? "" : "Optional";
    const billingNameLabel = display_text_cc;

    const { VisaAmexDisc } = useSelector(selectAllFormSettings);

    const ccFieldsProps = {
        showBillingNameField,
        billingNameLabel,
        cardNumberLabel,
        expirationDateLabel,
        cvvLabel,
        VisaAmexDisc,
        billingDescText,
    };

    return <CCFields {...ccFieldsProps} />;
};

export default ConnectedCCFields;
