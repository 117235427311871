import React from "react";
import cx from "classnames";
import DonationFormEmbedContent from "./DonationFormEmbedContent";
import ConnectedEventsBanner from "../../EventsBanner/ConnectedEventsBanner";
import "./DonationFormEmbed.scss";

/**
 *
 * @param {object} props Props
 * @param {boolean} props.displayBanner Display
 * @param {string} props.topBottomContentClasses Classes for top and bottom content
 * @param {boolean} props.displayWidgetBorder
 * @returns {React.ReactElement} TopBottomContent
 */
const DonationFormEmbed = ({
    displayBanner,
    topBottomContentClasses,
    displayWidgetBorder,
}) => (
    <div className={`donation-form-embed${topBottomContentClasses}`}>
        {displayBanner && <ConnectedEventsBanner />}
        <div
            className={cx(
                "qg-vendor-form-container",
                "donation-form-embed-container",
                !displayWidgetBorder &&
                    "donation-form-embed-container--no-border",
            )}
        >
            <DonationFormEmbedContent />
        </div>
    </div>
);

export default DonationFormEmbed;
