import React from "react";
import PropTypes from "prop-types";
import { FieldStack } from "@qgiv/core-donor";

const DedicationNotification = ({
    Dedication_Email,
    Dedication_Message,
    errors,
    handleBlur,
    handleChange,
    notificationEmailLabel,
    notificationMessageLabel,
    notificationLabel,
    touched,
}) => (
    <div className="dedications__content--notification">
        <div className="grid">
            <div className="col col--12 -display--flex -flex-direction--row">
                <p>{notificationLabel}</p>
                <small>Optional</small>
            </div>
            <div className="col col--12 -padding-top--5 -padding-bottom--15">
                <FieldStack
                    setErrorBelowStack
                    fields={[
                        {
                            name: "Dedication_Email",
                            label: notificationEmailLabel,
                            value: Dedication_Email,
                            type: "text",
                            handleBlur,
                            handleChange,
                            error:
                                (touched?.Dedication_Email ||
                                    touched?.Dedication_Message) &&
                                errors?.Dedication_Email &&
                                errors?.Dedication_Email.length > 0,
                            errorText: errors?.Dedication_Email,
                        },
                        {
                            name: "Dedication_Message",
                            label: notificationMessageLabel,
                            value: Dedication_Message,
                            type: "text",
                            multiline: true,
                            handleBlur,
                            handleChange,
                            error:
                                touched?.Dedication_Message &&
                                errors?.Dedication_Message &&
                                errors?.Dedication_Message?.length > 0,
                            errorText: errors?.Dedication_Message,
                        },
                    ]}
                />
            </div>
        </div>
    </div>
);

DedicationNotification.propTypes = {
    Dedication_Email: PropTypes.string.isRequired,
    Dedication_Message: PropTypes.string.isRequired,
    errors: PropTypes.shape({
        Dedication_Email: PropTypes.string,
        Dedication_Message: PropTypes.string,
    }).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    notificationEmailLabel: PropTypes.string.isRequired,
    notificationMessageLabel: PropTypes.string.isRequired,
    notificationLabel: PropTypes.string.isRequired,
    touched: PropTypes.shape({
        Dedication_Email: PropTypes.bool,
        Dedication_Message: PropTypes.bool,
    }).isRequired,
};

export default DedicationNotification;
