import React from "react";
import PropTypes from "prop-types";
import { AdditionalDetailsPageContainers } from "../../../Containers";

const AdditionalDetailsPageContent = ({ containers }) => {
    const additionalDetailsPageContainersProps = {
        containers,
    };

    return (
        <AdditionalDetailsPageContainers
            {...additionalDetailsPageContainersProps}
        />
    );
};

AdditionalDetailsPageContent.propTypes = {
    containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AdditionalDetailsPageContent;
