import React from "react";
import PropTypes from "prop-types";
import { PoweredByQgiv } from "@qgiv/core-react";
import FormStatusIcon from "./FormStatusIcon";
import "./FormStatus.scss";
import ConnectedBackButton from "./ConnectedBackButton";

const FormStatus = ({
    renderMessage,
    iconProps,
    hasBackToFundraiserLink,
    hasSignInText,
    HTTP_HOST,
}) => (
    <div className="form-status">
        <div className="grid">
            <div className="col col--12 form-status__image-container">
                <FormStatusIcon {...iconProps} />
            </div>
            <div className="col col--12">
                <h2>
                    <div className="form-status__text">{renderMessage()}</div>
                </h2>
            </div>
            {hasBackToFundraiserLink ? (
                <div className="col col--12">
                    <div className="form-status__back-button-container">
                        <ConnectedBackButton />
                    </div>
                </div>
            ) : null}
            <div className="col col--12 form-status__powered-by-qgiv-container">
                {hasSignInText ? (
                    <div className="-type--small">
                        Are you the owner of this form?{" "}
                        <a className="-font-weight--bold" href={HTTP_HOST}>
                            Sign In
                        </a>
                    </div>
                ) : null}

                <PoweredByQgiv classNames={["-margin-top--15"]} dark />
            </div>
        </div>
    </div>
);

FormStatus.propTypes = {
    renderMessage: PropTypes.func.isRequired,
    iconProps: PropTypes.shape({
        type: PropTypes.string,
        glyph: PropTypes.string,
    }).isRequired,
    hasBackToFundraiserLink: PropTypes.bool.isRequired,
    hasSignInText: PropTypes.bool.isRequired,
    HTTP_HOST: PropTypes.string.isRequired,
};

export default FormStatus;
