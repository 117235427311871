import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { MailingAddressPreview, Checkbox } from "@qgiv/core-donor";
import ConnectedSystemFieldGroupBillingAddress from "./ConnectedSystemFieldGroupBillingAddress";
import BillingAddressHeading from "./BillingAddressHeading";

const BillingAddress = (props) => {
    const {
        billingAddressAdditionalText,
        billingAddressFieldGroup,
        billingAddressSectionHeading,
        Billing_Address_Use_Mailing = true,
        handleBlur,
        handleChange,
        isAddressGroupDisplayable,
        isAnyBillingFieldDisplayable,
        mailingAddressValues,
        sameAsMailingAddressCheckboxLabel,
        isCms,
    } = props;
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (isCms && !isAddressGroupDisplayable) {
            setFieldValue("Billing_Address_Use_Mailing", false);
        }
    }, [isCms, isAddressGroupDisplayable, setFieldValue]);

    return (
        <>
            {/* Heading */}
            <BillingAddressHeading
                isAnyBillingFieldDisplayable={isAnyBillingFieldDisplayable}
                billingAddressSectionHeading={billingAddressSectionHeading}
                billingAddressAdditionalText={billingAddressAdditionalText}
            />
            {/* Same as mailing checkbox */}
            {isAddressGroupDisplayable && isAnyBillingFieldDisplayable && (
                <Checkbox
                    id="Billing-Address-Use-Mailing"
                    name="Billing_Address_Use_Mailing"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    checked={Billing_Address_Use_Mailing}
                >
                    <p>{sameAsMailingAddressCheckboxLabel}</p>
                </Checkbox>
            )}
            {/* Address preview box */}
            {isAddressGroupDisplayable &&
            Billing_Address_Use_Mailing &&
            isAnyBillingFieldDisplayable ? (
                <MailingAddressPreview {...mailingAddressValues} />
            ) : null}

            {/* Billing Address Fields */}
            {!Billing_Address_Use_Mailing && isAnyBillingFieldDisplayable ? (
                <ConnectedSystemFieldGroupBillingAddress
                    billingAddressFieldGroup={billingAddressFieldGroup}
                />
            ) : null}
        </>
    );
};

BillingAddress.propTypes = {
    billingAddressAdditionalText: PropTypes.string.isRequired,
    billingAddressFieldGroup: PropTypes.shape({}).isRequired,
    billingAddressSectionHeading: PropTypes.string.isRequired,
    Billing_Address_Use_Mailing: PropTypes.bool,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    isAddressGroupDisplayable: PropTypes.bool.isRequired,
    isAnyBillingFieldDisplayable: PropTypes.bool.isRequired,
    mailingAddressValues: PropTypes.shape({}).isRequired,
    sameAsMailingAddressCheckboxLabel: PropTypes.string.isRequired,
    isCms: PropTypes.bool.isRequired,
};

export default BillingAddress;
