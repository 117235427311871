import React from "react";
import { useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { selectAllDonationSettings } from "../../../../../../../redux/slices/donationSettingsSlice";
import InstallmentFrequencySelect from "./InstallmentFrequencySelect";
import { updateGiftDetails } from "../../../../../../../redux/slices/donationDetailsSlice";
import { selectCurrentPage } from "../../../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../../../redux/slices/cmsSettingsSlice";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement} - The rendered InstallmentFrequencySelect component.
 */
const ConnectedInstallmentFrequencySelect = ({ controlId }) => {
    const dispatch = useDispatch();
    const { activeDonationFrequencies, allFrequencies, activePlans } =
        useSelector(selectAllDonationSettings);

    const { values, handleChange, handleBlur } = useFormikContext();

    const { Selected_Plan, Recurring_Frequency } = values;

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const { payInstallmentsLabel = "Pay Installments" } = options;

    const selectedPlan =
        activePlans.find((plan) => Number(plan.id) === Number(Selected_Plan)) ||
        {};

    const { defaultFrequencyEnum } = selectedPlan;

    const installmentActiveFrequencies = [...activeDonationFrequencies];

    // In the case that a Installment Plan was created using a default frequency which is no longer
    // enabled in activeDonationFrequencies, we continue to honor their original default frequency
    // in addition to those set in donation settings
    if (Object.keys(selectedPlan).length > 0) {
        const matchingFrequency =
            activeDonationFrequencies.find(
                (frequency) =>
                    String(frequency.value) === String(defaultFrequencyEnum),
            ) || {};
        const defaultFrequencyIsNoLongerActive =
            Object.keys(matchingFrequency).length === 0;

        if (defaultFrequencyIsNoLongerActive) {
            const inactiveFrequencyDetails =
                allFrequencies.find(
                    (frequency) =>
                        String(frequency.value) ===
                        String(defaultFrequencyEnum),
                ) || {};
            if (Object.keys(inactiveFrequencyDetails).length) {
                installmentActiveFrequencies.push(inactiveFrequencyDetails);
            }
        }
    }

    const handleFrequencyChange = (e) => {
        const { value } = e.target;
        const formikUpdateValues = {
            Recurring_Frequency: value,
        };
        dispatch(updateGiftDetails({ ...formikUpdateValues }));
        handleChange(e);
    };

    return (
        <InstallmentFrequencySelect
            activeDonationFrequencies={installmentActiveFrequencies}
            handleChange={handleFrequencyChange}
            handleBlur={handleBlur}
            values={values}
            Recurring_Frequency={Recurring_Frequency}
            label={payInstallmentsLabel}
        />
    );
};

export default ConnectedInstallmentFrequencySelect;
