import React from "react";
import { createRoot } from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import { loadFonts, initSentry, loadCss, log } from "@qgiv/core-js";
import loadIcons from "@qgiv/icons";
import { replaceAnimation } from "@qgiv/donation-form";
import { Provider } from "react-redux";
import {
    ConnectedDonationFormApp,
    ConnectedInjections,
    ConnectedTopBottomContent,
} from "./components";

import "./donationForm.scss";
import "./styles/global/_modifiers.scss"; // Modifiers remain last to override any other styles
import store from "./redux";

const loadCustomCss = () => {
    const state = store.getState();
    const { formSettings } = state;
    const {
        theme: { custom_css = "" },
    } = formSettings;

    loadCss("custom-css", custom_css);
};

log("---------- Dev Log: the OneForm™ donationForm.js loaded ----------");

// Load web fonts
loadFonts();

// Load Sentry performance monitoring
initSentry({ app: "Standard Donation Form" }, "react-fundraising-fe");

// Load Icons into DOM
loadIcons();

// Load custom CSS
loadCustomCss();

// replace loading animation on landing page
replaceAnimation();

// Render App
const appElement = document.getElementById("app");
const appRoot = createRoot(appElement);
appRoot.render(<ConnectedDonationFormApp />);

// Render Injections from Form and Skin Injections
const injectionsElement = document.getElementById("injection-container");
const injectionsRoot = createRoot(injectionsElement);
injectionsRoot.render(<ConnectedInjections />);

// Render top content
const topContentElement = document.getElementById("top-content");
const topContentRoot = createRoot(topContentElement);
topContentRoot.render(
    <Provider store={store}>
        <ConnectedTopBottomContent selector="header" />
    </Provider>,
);

// Render bottom content
const bottomContentElement = document.getElementById("bottom-content");
const bottomContentRoot = createRoot(bottomContentElement);
bottomContentRoot.render(
    <Provider store={store}>
        <ConnectedTopBottomContent selector="footer" />
    </Provider>,
);
