import React from "react";
import { Tooltip, Icon, useBreakpoints } from "@qgiv/core-react";
import { MoreInfoModal } from "../components/MoreInfoModal";

const useTooltip = ({
    iconGlyph = "question-circle-regular",
    message = "",
    // Used to render an element children rather than a string
    mobileMessage,
    placement = "right",
    position = "center",
    tooltipClassName = "-type--regular -font-family--font-theme",
    iconType = "FontAwesome",
    theme = "dark",
}) => {
    const { isSmallScreen } = useBreakpoints();

    return (
        <span>
            {isSmallScreen ? (
                <MoreInfoModal position={position}>
                    {mobileMessage || message}
                </MoreInfoModal>
            ) : (
                <Tooltip
                    placement={placement}
                    content={message}
                    theme={theme}
                    tooltipClassName={tooltipClassName}
                >
                    <Icon glyph={iconGlyph} type={iconType} />
                </Tooltip>
            )}
        </span>
    );
};

export default useTooltip;
