import { useState, useEffect } from "react";

import useDebounce from "../useDebounce";

// Hook
/**
 * @function useWindowSize
 * @description React hook for using the DOM window size
 * @returns {Function} Debounced getter function that returns window size
 */
export default function useWindowSize() {
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const debouncedWindowSize = useDebounce(windowSize, 300);

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return debouncedWindowSize;
}
