import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import ConnectedConfirmationFieldsContainer from "./ConfirmationFieldsContainer/ConnectedConfirmationFieldsContainer";

const ReceiptPageContainers = ({ containers = [] }) => {
    const containerProps = {
        page: "confirmation-page",
    };

    return containers.map((container = {}) => {
        const { options = {} } = container;
        const { isConfirmationFieldsContainer = false } = options;

        // Return a special container where we can set up Formik
        if (isConfirmationFieldsContainer) {
            return (
                <ConnectedConfirmationFieldsContainer
                    key={container.id}
                    container={container}
                    {...containerProps}
                />
            );
        }

        return (
            <Container
                key={container.id}
                container={container}
                {...containerProps}
            />
        );
    });
};

ReceiptPageContainers.propTypes = {
    containers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ReceiptPageContainers;
