import React from "react";

/**
 * @param {object} props
 * @param {string} props.header
 * @returns {React.ReactElement}
 */
const InstallmentDetailsHeader = ({ header }) => (
    <h3 className="-margin-bottom--15">{header}</h3>
);

export default InstallmentDetailsHeader;
