import React from "react";
import cx from "classnames";
import ConnectedRestrictionCardAmounts from "./RestrictionCardAmounts";
import ConnectedRestrictionCardImage from "./RestrictionCardImage";
import ConnectedRestrictionCardLabel from "./RestrictionCardLabel";
import ConnectedRestrictionCardError from "./RestrictionCardError";
import RestrictionCardSubRestrictions from "./RestrictionCardSubRestrictions";
import "./RestrictionCard.scss";

/**
 * @typedef {import("./restrictionCardsTypes").RestrictionCardProps} RestrictionCardProps
 */

/**
 *
 * @param {RestrictionCardProps} props
 * @returns {React.ReactElement}
 */
const RestrictionCard = ({
    handleRestrictionCardClick,
    restrictionCardAmountsProps,
    restrictionCardErrorProps,
    restrictionCardImageProps,
    restrictionCardLabelProps,
    restrictionCardSubRestrictionProps,
    selected,
    showAmounts,
    showError,
    showImage,
    showSubRestrictions,
}) => {
    const restrictionCardContainerClassNames = cx(
        "restriction-card__container",
        !selected && "-cursor--pointer",
        selected && "restriction-card__container--selected",
        showError && "restriction-card__container--error",
    );

    // We ignored the jsx-a11y/click-events-have-key-events and the
    // jsx-a11y/no-static-element-interactions rules as the designers
    // specifically requested that those elements be made clickable
    // padding-bottom is added on an element by element basis to ensure that
    // the vertical spacing between elements is consistent even as we add and
    // remove items from the UI
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            className="-margin-bottom--15"
            onClick={handleRestrictionCardClick}
        >
            <div className={restrictionCardContainerClassNames}>
                <ConnectedRestrictionCardLabel {...restrictionCardLabelProps} />
                {showImage && (
                    <ConnectedRestrictionCardImage
                        {...restrictionCardImageProps}
                    />
                )}
                {showSubRestrictions && (
                    <RestrictionCardSubRestrictions
                        {...restrictionCardSubRestrictionProps}
                    />
                )}
                {showAmounts && (
                    <ConnectedRestrictionCardAmounts
                        {...restrictionCardAmountsProps}
                    />
                )}
                {showError && (
                    <ConnectedRestrictionCardError
                        {...restrictionCardErrorProps}
                    />
                )}
            </div>
        </div>
    );
};

export default RestrictionCard;
