import React from "react";
import { CardImage } from "@qgiv/core-donor";
import cx from "classnames";
import GivingPlanCardDescription from "./GivingPlanCardDescription";
import GivingPlanAmountWithFrequency from "./GivingPlanAmountWithFrequency";
import GivingPlanCardRestriction from "./GivingPlanCardRestriction";
import GivingPlanCardSummary from "./GivingPlanCardSummary";
import GivingPlanCardStartDate from "./GivingPlanCardStartDate";
import "./GivingPlanCard.scss";

/**
 * @param {object} props
 * @param {string} [props.description]
 * @param {string} [props.formattedStartDate]
 * @param {string} props.formattedSummaryAmount
 * @param {string} props.formattedSummaryText
 * @param {React.ChangeEventHandler} props.handleChange
 * @param {number} props.id - The ID of the giving plan.
 * @param {object} [props.imageProps]
 * @param {string} props.name
 * @param {string} props.planAmount
 * @param {string} [props.planFrequency]
 * @param {string|null} [props.restrictionDetails]
 * @param {boolean} props.selected
 * @param {boolean} props.shouldDisplayDivider
 * @param {boolean} props.shouldDisplayFutureStartDate
 * @param {boolean} props.shouldDisplayImage
 * @param {boolean} props.shouldDisplayRestriction
 * @param {boolean} props.shouldDisplaySummary
 * @returns {React.ReactElement} The rendered GivingPlanCard component.
 */
const GivingPlanCard = ({
    description = "",
    formattedStartDate = "",
    formattedSummaryAmount,
    formattedSummaryText,
    handleChange,
    id,
    imageProps = {},
    name,
    planAmount,
    planFrequency = "",
    restrictionDetails = "",
    selected,
    shouldDisplayDivider,
    shouldDisplayFutureStartDate,
    shouldDisplayImage,
    shouldDisplayRestriction,
    shouldDisplaySummary,
}) => (
    <div className="col col--12">
        <label
            className="giving-plan-card-container"
            htmlFor={`Selected-Plan-${id}`}
        >
            <input
                id={`Selected-Plan-${id}`}
                className="-visually-hidden"
                type="radio"
                name="Selected_Plan"
                value={id}
                onChange={handleChange}
            />
            <div
                className={cx(
                    "giving-plan-card",
                    "-cursor--pointer",
                    selected && "giving-plan-card__selected",
                )}
            >
                <div className="qg-vendor-card__inner giving-plan-card__inner">
                    {shouldDisplayImage && (
                        <figure className="giving-plan-card__figure">
                            <CardImage {...imageProps} />
                        </figure>
                    )}

                    <div className="giving-plan-card__title">
                        {name && (
                            <>
                                <span className="-type--large">{name}</span>
                                <GivingPlanAmountWithFrequency
                                    planAmount={planAmount}
                                    planFrequency={planFrequency}
                                />
                            </>
                        )}
                    </div>
                    {description && (
                        <GivingPlanCardDescription description={description} />
                    )}
                    {shouldDisplayDivider && (
                        <div className="giving-plan-card__divider" />
                    )}
                    {shouldDisplayRestriction && (
                        <GivingPlanCardRestriction
                            restrictionDetails={restrictionDetails}
                        />
                    )}
                    {shouldDisplaySummary && (
                        <GivingPlanCardSummary
                            formattedSummaryAmount={formattedSummaryAmount}
                            formattedSummaryText={formattedSummaryText}
                        />
                    )}
                    {shouldDisplayFutureStartDate && (
                        <GivingPlanCardStartDate
                            formattedStartDate={formattedStartDate}
                        />
                    )}
                </div>
            </div>
        </label>
    </div>
);

export default GivingPlanCard;
