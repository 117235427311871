import React from "react";
import PropTypes from "prop-types";

import useAccordionContext from "../../hooks/useAccordionContext";

const AccordionCollapse = ({
    element: Component = "div",
    eventKey,
    children,
}) => {
    const { activeEventKeys } = useAccordionContext();

    return activeEventKeys && activeEventKeys.includes(eventKey) ? (
        <Component>{children}</Component>
    ) : null;
};

AccordionCollapse.propTypes = {
    children: PropTypes.object,
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AccordionCollapse;
