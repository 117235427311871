import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    getHasOneTimeAmountToDisplay,
    getHasRecurringAmountToDisplay,
    getHasEnabledActiveStandardRestrictions,
} from "@qgiv/donation-form";
import DonatedGiftControl from "./DonatedGiftControl";
import { selectAllDonationSettings } from "../../../redux/slices/donationSettingsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectGiftDetails } from "../../../redux/slices/donationDetailsSlice";
import { selectRestrictions } from "../../../redux/slices/restrictionSettingsSlice";
import { selectCurrentDisplay } from "../../../redux/slices/configSettingsSlice";
import { selectHasActiveFulfillment } from "../../../redux/slices/promiseTransactionSlice";

const ConnectedDonatedGiftControl = ({ controlId }) => {
    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    const { activeAmounts, activePlans, pledgeActive, restrictionGivingType } =
        useSelector(selectAllDonationSettings);
    const { donationActive, enableRecur } = useSelector(selectAllFormSettings);
    const hasOneTimeDonationAmountToDisplay =
        getHasOneTimeAmountToDisplay(activeAmounts);
    const { hasSelectedRecurringDonation } = useSelector(selectGiftDetails);
    const restrictions = useSelector(selectRestrictions);
    const currentDisplay = useSelector(selectCurrentDisplay);

    const hasRecurringDonationAmountToDisplay = getHasRecurringAmountToDisplay(
        activeAmounts,
        activePlans,
        pledgeActive,
    );

    const shouldDisplayGivingPlansContent =
        activePlans?.length > 0 && pledgeActive && hasSelectedRecurringDonation;

    const shouldDisplaySustainingGiftsContent =
        !hasActiveFulfillment &&
        hasSelectedRecurringDonation &&
        !shouldDisplayGivingPlansContent;

    const shouldDisplayOneTimeGiftContent =
        !hasActiveFulfillment && !hasSelectedRecurringDonation;

    const showDonationFrequencyTabs =
        donationActive &&
        enableRecur &&
        hasOneTimeDonationAmountToDisplay &&
        hasRecurringDonationAmountToDisplay &&
        !hasActiveFulfillment;

    const hasEnabledActiveStandardRestrictions =
        getHasEnabledActiveStandardRestrictions({
            restrictions,
            hasActiveFulfillment,
            currentDisplay,
            restrictionGivingType,
        });

    const enableStandardRestrictions = // (!hasSelectedRecurringDonation && pledgeActive) &&
        hasEnabledActiveStandardRestrictions;

    const shouldDisplayRestrictions =
        enableStandardRestrictions && !shouldDisplayGivingPlansContent;

    const donatedGiftControlProps = {
        hasActiveFulfillment,
        enableStandardRestrictions,
        showDonationFrequencyTabs,
        hasSelectedRecurringDonation,
        controlId,
        shouldDisplayGivingPlansContent,
        shouldDisplaySustainingGiftsContent,
        shouldDisplayOneTimeGiftContent,
        shouldDisplayRestrictions,
    };

    return <DonatedGiftControl {...donatedGiftControlProps} />;
};

ConnectedDonatedGiftControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonatedGiftControl;
