import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { constants } from "@qgiv/core-js";
import FieldContainer from "../Field/FieldContainer";
import "./InputBoxList.scss";

const InputBoxList = (props) => {
    const {
        ariaRequired = "false",
        inline,
        children,
        error,
        errorText,
        fieldType,
        helpText,
    } = props;
    const { FieldType } = constants.ENUMS;
    const inputBoxListInputClassNames = cx(
        "qg-vendor-input-box-list",
        "input-box-list",
        inline ? "input-box-list--inline" : "input-box-list--stacked",
        error && "input-box-list--error",
    );
    let list;
    let roleAttribute = {};

    if (inline) {
        list = (
            <FieldContainer>
                {children.map((child, key) =>
                    React.cloneElement(child, { error, key }),
                )}
            </FieldContainer>
        );
    } else {
        list = children.map((child, i) => (
            <FieldContainer selected={child.props.checked} key={i}>
                {React.cloneElement(child, { error })}
            </FieldContainer>
        ));
    }

    // Assign role attribute associated with different on the type of box list
    if (Number(fieldType) === FieldType.MULTIPLE_SELECTION) {
        roleAttribute.role = "listbox";
    } else if (Number(fieldType) === FieldType.RADIO_BUTTON) {
        roleAttribute.role = "radiogroup";
    }

    return (
        <fieldset
            {...roleAttribute}
            aria-required={ariaRequired}
            className={inputBoxListInputClassNames}
        >
            {props.listLabel && (
                <legend
                    className={cx(
                        "qg-vendor-input-box-list__label input-box-list__label",
                    )}
                >
                    <p>{props.listLabel}</p>
                </legend>
            )}

            {helpText && helpText.length > 0 && (
                <div className="grid">
                    <div className="col col--12  -padding-top--0 -padding-bottom--0">
                        <div className="field-container__help-text">
                            <small>{helpText}</small>
                        </div>
                    </div>
                </div>
            )}

            {error && errorText.length > 0 && (
                <div className="input-box-list__error-text">{errorText}</div>
            )}

            <div className="input-box-list-items__wrapper -padding-top--10">
                {list}
            </div>
        </fieldset>
    );
};

InputBoxList.propTypes = {
    ariaRequired: PropTypes.string,
    listLabel: PropTypes.string,
    inline: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    helpText: PropTypes.string,
    fieldType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputBoxList;
