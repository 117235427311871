const getInitialTouched = (initialTouchedSettings = {}) => {
    const { savedValues = {} } = initialTouchedSettings;
    const touched = {};
    if (Object.keys(savedValues).length > 0) {
        const savedTouched = Object.keys(savedValues).reduce(
            (_touched, key) => ({
                ..._touched,
                [key]: true,
            }),
            {},
        );
        return {
            ...touched,
            ...savedTouched,
        };
    }

    return touched;
};

export default getInitialTouched;
