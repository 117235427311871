import constants from "../constants";

/**
 * @function isDisplayable
 * @description Determines whether and entity should be displayed given it's
 *      display options and the current display
 * @param {object} displayOptions Display options object
 * @param {number|string} [currentDisplay] The current display. Should match a
 *      DisplayOn ENUM value.
 * @returns {boolean} Flag determining if an entity is displayable
 */
export const isDisplayable = (displayOptions, currentDisplay = "9") => {
    const { DisplayOn } = constants.ENUMS;
    const { displayFrontend, displayMobile, displayTxtToDonate } =
        displayOptions ?? {};

    switch (Number(currentDisplay)) {
        case DisplayOn.FRONTEND: {
            return Number(displayFrontend) === 1;
        }
        case DisplayOn.MOBILE: {
            return Number(displayMobile) === 1;
        }
        case DisplayOn.TEXT_TO_DONATE: {
            return Number(displayTxtToDonate) === 1;
        }
        default:
            return true;
    }
};

// need to keep this in sync with breakpoints map in _variables.scss
export const breakpoints = {
    xs: "0",
    xsm: "375px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
};
