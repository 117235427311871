import React from "react";
import { constants } from "@qgiv/core-js";
import { useSelector, useDispatch } from "react-redux";
import {
    selectCurrentPage,
    setCurrentPage,
} from "../../redux/slices/appSettingsSlice";
import { selectEventListSettings } from "../../redux/slices/eventListSettingsSlice";
import EventsBanner from "./EventsBanner";

/**
 * @returns {React.ReactElement}
 */
const ConnectedEventsBanner = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS, QGIV_EVENTS },
        },
    } = constants;
    const eventListSettings = useSelector(selectEventListSettings);

    const dispatch = useDispatch();

    const currentPage = useSelector(selectCurrentPage);

    const active = Number(currentPage) === QGIV_EVENTS ? "give" : "events";

    const newCurrentStepForEvents = QGIV_EVENTS;

    const newCurrentStepForGive = DONATION_AMOUNTS;

    const {
        Settings: { options },
    } = eventListSettings;

    const {
        eventsMessage = "Join us for an upcoming event!",
        eventsLinkText = "See Events",
        donationsMessage = "Your support goes a long way.",
        donationsLinkText = "Give Now",
    } = options;

    // All of the dynamic data that is associated with the events banner
    let bannerConfig = {
        glyph: "sparkles-regular",
        message: eventsMessage,
        newCurrentStep: newCurrentStepForEvents,
        to: "events",
        linkText: eventsLinkText,
    };

    // Toggle the icon, message, state, url and link text if a link to the
    // donation form needs to be displayed
    if (active === "give") {
        bannerConfig = {
            glyph: "gift-regular",
            message: donationsMessage,
            newCurrentStep: newCurrentStepForGive,
            to: "embed",
            linkText: donationsLinkText,
        };
    }

    const handleClick = () => {
        dispatch(setCurrentPage(bannerConfig.newCurrentStep));
    };

    return (
        <EventsBanner bannerConfig={bannerConfig} handleClick={handleClick} />
    );
};

export default ConnectedEventsBanner;
