import React from "react";
import PropTypes from "prop-types";
import ConnectedReceiptSummary from "../../Pages/ReceiptPage/ReceiptSummary";

const ReceiptDonationDetailsControl = ({ controlId }) => (
    <ConnectedReceiptSummary controlId={controlId} />
);

ReceiptDonationDetailsControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ReceiptDonationDetailsControl;
