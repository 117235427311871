import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { constants } from "@qgiv/core-js";
import {
    ReceiptTransactionSummary,
    translateTransaction,
} from "@qgiv/core-donor";
import {
    selectReceiptDonationMessage,
    selectReceiptDonationRecipientName,
    selectReceiptTransaction,
} from "../../../../redux/slices/receiptsSlice";
import { selectSpecificFormSetting } from "../../../../redux/slices/formSettingsSlice";
import { selectHasDonationMessage } from "../../../../redux/slices/fieldsSlice";

const ConnectedReceiptTransactionSummary = ({ control }) => {
    const {
        ENUMS: { FormTemplate },
    } = constants;
    const { options = {} } = control;
    const {
        dateLabel = "Registered on %TransactionDate%",
        donationRecipientLabel = "You're supporting %RECIPIENTNAME% with this gift",
        donationMessageLabel = "Your public message of support",
        recurringIdLabel = "Recurring ID %RecurringID%",
        summaryLabel = "Summary",
        transactionIdLabel = "Transaction %TransactionID%",
    } = options;

    const entityName = useSelector(selectReceiptDonationRecipientName);

    const donationMessage = useSelector(selectReceiptDonationMessage);
    const hasDonationMessage = useSelector(selectHasDonationMessage);
    const formTemplate = useSelector((state) =>
        selectSpecificFormSetting(state, "template"),
    );
    const isP2PTemplate = formTemplate === FormTemplate.P2P_STANDARD;
    const displayRecipient = entityName !== "" && isP2PTemplate;
    const displayDonationMessage = donationMessage !== "" && hasDonationMessage;

    const transaction = useSelector(selectReceiptTransaction);
    // Convert any of the data that is on the transaction object that is a
    // number to a string before it is displayed on the receipt page
    const transactionWithDataAsStrings = translateTransaction(transaction);
    const {
        date = "",
        id = "",
        tm_stamp = "",
        recurring = null,
    } = transactionWithDataAsStrings;

    // Use moment to format the date and time values
    const transactionDate = moment(date).format("MM/DD/YYYY");
    const transactionTmStamp = moment(tm_stamp).format("hh:mm A");
    const transactionIDLabel = transactionIdLabel;
    const transactionSummaryLabel = summaryLabel;
    const transactionId = id;
    // Will be null if the donor submitted a one time donation or an object if
    // the donor submitted a recurring donation
    const isRecurringDonation = !!recurring;
    const recurringId = recurring && recurring.id ? recurring.id : "";

    // Consolidate props into object while passing reassigned variable to the
    // appropriate key.
    const receiptTransactionSummaryProps = {
        dateLabel,
        displayRecipient,
        donationRecipientLabel,
        displayDonationMessage,
        donationMessageLabel,
        donationMessage,
        entityName,
        isRecurringDonation,
        recurringIdLabel,
        recurringId,
        transactionDate,
        transactionId,
        transactionIDLabel,
        transactionSummaryLabel,
        transactionTmStamp,
    };

    return <ReceiptTransactionSummary {...receiptTransactionSummaryProps} />;
};

ConnectedReceiptTransactionSummary.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            dateLabel: PropTypes.string,
            recurringIdLabel: PropTypes.string,
            summaryLabel: PropTypes.string,
            transactionIdLabel: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedReceiptTransactionSummary;
