import React from "react";
import { constants } from "@qgiv/core-js";
import { useSelector } from "react-redux";
import { WidgetEvents } from "@qgiv/core-donor";
import { selectEventListSettings } from "../../../redux/slices/eventListSettingsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectCmsControlById } from "../../../redux/slices/cmsSettingsSlice";
import { selectConfig } from "../../../redux/slices/configSettingsSlice";

/**
 * @param {object} props - Component properties
 * @param {number} props.controlId - The ID of the CMS control to retrieve settings for
 * @returns {React.ReactElement} The WidgetEvents component.
 */
const ConnectedWidgetEvents = (props) => {
    const {
        ENUMS: {
            Status,
            // TODO: will use this for displayWidgetBorder on eventsLandingPage
            // CmsPageStandardizer: { QGIV_EVENTS },
        },
    } = constants;

    // Control Settings (CMS)
    const { controlId } = props;
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );
    const { options: eventListOptions } = control;
    const { title, additionalText, displayWidgetBorder } = eventListOptions;

    // Event Settings (CP)
    const eventListSettings = useSelector(selectEventListSettings);
    const { Events } = eventListSettings;

    const { status, currency } = useSelector(selectAllFormSettings);
    const isFormOpen =
        Number(status) !== Status.CLOSEANDBILL &&
        Number(status) !== Status.CLOSED &&
        Number(status) !== Status.DELETED;
    const config = useSelector(selectConfig);
    const { path } = config;

    const checkAllPrivate = (statusPrivate) =>
        statusPrivate === 1 || statusPrivate === true;

    const eventPackages = Events.map((events) => events);
    const isPrivate = eventPackages.map((event) =>
        event.packages
            .map((pkg) => parseInt(pkg.private, 10))
            .every(checkAllPrivate),
    );

    const hasSomePublicPackages = isPrivate.length
        ? !isPrivate.every(checkAllPrivate)
        : false;

    // TODO: displayWidgetBorder is unique to events. this was originally checking default view
    // that may make more sense for the purposes of displaying on event landing page.
    // const currentPage = useSelector(selectCurrentPage);
    const hasNoBorders = displayWidgetBorder === 0;

    const displayEventList =
        Events.length && hasSomePublicPackages && isFormOpen;

    const widgetEventsProps = {
        title,
        additionalText,
        hasNoBorders,
        activeEvents: Events,
        displayEventList,
        status,
        currency,
        eventListOptions,
        path,
    };

    return <WidgetEvents {...widgetEventsProps} />;
};

export default ConnectedWidgetEvents;
