import { useEffect, useCallback } from "react";
import { defaultFormThemeColor } from "../utility";

/**
 * @param {object} props
 * @param {string} props.acceptReminderButtonText
 * @param {boolean} [props.acceptedGiftAssist]
 * @param {number} [props.amount] - subtotal value
 * @param {Function} [props.appSpecificPostMessage]
 * @param {string} props.cancelReminderButtonText
 * @param {string} [props.color]
 * @param {string} props.defaultHeader
 * @param {number} [props.embedId]
 * @param {string} [props.fontFamilyStack]
 * @param {Function} [props.handleReminderAcceptPostMessage]
 * @param {boolean} props.hasSubmittedForm
 * @param {boolean} props.ongoing
 * @param {string} props.qgiv_abandoned_gift token
 * @param {boolean} [props.receivedHandshake]
 * @param {string} [props.recurringFrequency]
 * @param {object} props.selectedRecipient
 * @param {boolean} props.shouldAllowCookieSetting
 * @returns {void}
 */
const useElements = ({
    acceptReminderButtonText,
    acceptedGiftAssist = false,
    amount = 0,
    appSpecificPostMessage = () => {},
    cancelReminderButtonText,
    color = defaultFormThemeColor,
    defaultHeader,
    embedId = 0,
    fontFamilyStack,
    handleReminderAcceptPostMessage = () => {},
    hasSubmittedForm,
    ongoing,
    qgiv_abandoned_gift,
    receivedHandshake = false,
    recurringFrequency = "m",
    selectedRecipient = {},
    shouldAllowCookieSetting,
    // TODO - coming later
    // personalizedHeader,
}) => {
    const handleIncomingPostMessage = useCallback(
        (message = {}) => {
            const { data = "" } = message;
            if (typeof data !== "string" || data === "recaptcha-setup") {
                return;
            }

            // ensure that the string coming in from the message is valid JSON
            let validJson = false;
            let parsedData = {};
            try {
                parsedData = JSON.parse(data);
                validJson = true;
            } catch {
                validJson = false;
            }

            if (
                !validJson ||
                typeof parsedData !== "object" ||
                Object.keys(parsedData).length === 0
            ) {
                return;
            }

            const { event = "" } = parsedData;
            if (event === "reminder-accept") {
                const messageData = parsedData?.data;
                const messageAmount = messageData?.amount;
                const messageOngoing = messageData?.ongoing;
                const messageRecurringFrequency =
                    messageData?.recurringFrequency;
                const messageAcceptedGiftAssist =
                    messageData?.acceptedGiftAssist;
                const reminderData = {
                    amount: messageAmount,
                    ongoing: messageOngoing,
                    recurringFrequency: messageRecurringFrequency,
                    acceptedGiftAssist: messageAcceptedGiftAssist,
                };
                handleReminderAcceptPostMessage(reminderData);
            }
        },
        [handleReminderAcceptPostMessage],
    );

    // listen for post message to update amount selected
    useEffect(() => {
        window.addEventListener("message", handleIncomingPostMessage);

        return () =>
            window.removeEventListener("message", handleIncomingPostMessage);
    }, [handleIncomingPostMessage]);

    // clear elements cookie when form has been submitted
    useEffect(() => {
        if (hasSubmittedForm) {
            appSpecificPostMessage("clearElementCookie");
        }
    }, [appSpecificPostMessage, hasSubmittedForm]);

    // send data to create cookie when an amount is set but updates
    // when other values are set/changed
    useEffect(() => {
        if (
            receivedHandshake &&
            Number(amount) > 0 &&
            shouldAllowCookieSetting
        ) {
            // want to ensure decimal amounts have 2 digits after the decimal
            // to prevent missing 0's at the end.
            let amountToStore = amount;
            const stringAmount = `${amount}`;
            const hasDecimal = stringAmount.includes(".");
            if (hasDecimal) {
                amountToStore = amount.toFixed(2);
            }
            const data = {
                acceptReminderButtonText,
                acceptedGiftAssist,
                amount: amountToStore,
                cancelReminderButtonText,
                color,
                defaultHeader,
                embedId,
                fontFamilyStack,
                ongoing,
                recurringFrequency,
                selectedRecipient,
                token: qgiv_abandoned_gift,
            };
            appSpecificPostMessage("setElementAmount", data);
        }
    }, [
        acceptReminderButtonText,
        acceptedGiftAssist,
        amount,
        appSpecificPostMessage,
        cancelReminderButtonText,
        color,
        defaultHeader,
        embedId,
        fontFamilyStack,
        ongoing,
        qgiv_abandoned_gift,
        receivedHandshake,
        recurringFrequency,
        selectedRecipient,
        shouldAllowCookieSetting,
    ]);
};

export default useElements;
