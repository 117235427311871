import { isDisplayable } from "@qgiv/core-js";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.Restriction} Restriction
 */

/**
 * @public
 * @function getHasEnabledActiveStandardRestrictions
 * @param {object} settings all settings
 * @param {Array} settings.restrictions active restrictions
 * @param {boolean} settings.hasActiveFulfillment Invoice is active
 * @param {number}  settings.currentDisplay form is displaying on Desktop, Mobile, Text-to-Donate
 * @param {string} settings.restrictionGivingType Type of Restrictions that are enabled
 * @description Checks if active restrictions are available and if they are not Multi Restrictions
 * and we are not viewing an invoice
 * @returns {boolean} True if requirements met for Standard Restrictions
 */
export const getHasEnabledActiveStandardRestrictions = ({
    restrictions,
    hasActiveFulfillment,
    currentDisplay,
    restrictionGivingType,
}) => {
    const hasDisplayableRestrictions =
        restrictions.length > 0 &&
        !hasActiveFulfillment &&
        restrictions.some((restriction) =>
            isDisplayable(restriction, currentDisplay),
        );

    // -------------------------------------------------------------------------
    // NOTE: RestrictionGivingType has two possible values in DB
    // "amounts" = Standard Registrations that are displayed below the Standard
    //      Amounts for Onetime and Recurring
    // "simple" = Multi Restrictions that replace the Standard Amounts
    //
    // It's not at all confusing.
    // -------------------------------------------------------------------------
    const isStandardRestrictionEnabled = restrictionGivingType === "amounts";
    return !!(hasDisplayableRestrictions && isStandardRestrictionEnabled);
};

/**
 *
 * @param {Restriction} restriction
 * @param {number} currentDisplay
 * @returns {boolean} If restriction should be displayed on current device.
 */
export const getIsRestrictionDisplayable = (restriction, currentDisplay) => {
    const {
        displayFrontend = false,
        displayMobile = false,
        displayTxtToDonate = false,
    } = restriction;

    const displayOptions = {
        displayFrontend,
        displayMobile,
        displayTxtToDonate,
    };
    const isRestrictionDisplayable = isDisplayable(
        displayOptions,
        currentDisplay,
    );

    return isRestrictionDisplayable;
};

/**
 *
 * @param {Restriction[]} restrictions
 * @param {number} currentDisplay
 * @returns {Restriction[]} Restrictions to be displayed on the current device.
 */
export const getActiveDisplayableRestrictions = (
    restrictions,
    currentDisplay,
) => {
    let activeDisplayableRestrictions = [];

    if (Array.isArray(restrictions)) {
        activeDisplayableRestrictions = restrictions.filter((restriction) => {
            const isRestrictionDisplayable = getIsRestrictionDisplayable(
                restriction,
                currentDisplay,
            );

            return isRestrictionDisplayable;
        });
    }

    return activeDisplayableRestrictions;
};

/**
 *
 * @param {object[]} restrictions
 * @returns {number[]}
 */
export const getRestrictionIds = (restrictions) => {
    const restrictionIds = restrictions.map((restriction) => restriction.id);
    return restrictionIds;
};

/**
 * @public
 * @function getRestrictionById
 * @param {Array} activeRestrictions active restrictions
 * @param {number} id Id of restriction we are looking for
 * @description Finds restriction object by id
 * @returns {object} Selected Restriction object
 */
export const getRestrictionById = (activeRestrictions, id) =>
    activeRestrictions.find((restriction) => restriction.id === Number(id)) ||
    {};

/**
 * @public
 * @function getRestrictionName
 * @param {Array} activeRestrictions active restrictions
 * @param {number} id Id of restriction we are looking for
 * @description Finds restriction name by id
 * @returns {string} Selected Restriction name
 */
export const getRestrictionName = (activeRestrictions, id) => {
    const restriction = getRestrictionById(activeRestrictions, id);
    return restriction && restriction?.name?.length ? restriction.name : "";
};

/**
 * @public
 * @function getRestrictionDescription
 * @param {Array} activeRestrictions active restrictions
 * @param {number} id Id of restriction we are looking for
 * @description Finds restriction description by id
 * @returns {string} Selected Restriction description
 */
export const getRestrictionDescription = (activeRestrictions, id) => {
    const restriction = getRestrictionById(activeRestrictions, id);
    return restriction && restriction?.description?.length
        ? restriction.description
        : "";
};
