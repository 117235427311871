import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "../../Button/Button.scss";
import "./TextInputButton.scss";
import { QMaskField, validQMaskTypes } from "../QMaskField";
import CurrencyInput from "../CurrencyInput";

const TextInputButton = ({
    active,
    children,
    error,
    errorText,
    handleBlur,
    handleFocus,
    id,
    inputClassnames = "",
    buttonClassnames = "",
    labelText,
    name,
    placeholder = "",
    txtInputBtnRef,
    type = "text",
    value,
    buttonStyle = "standard",
    selectable = true,
    ...rest
}) => {
    // state for saving active flag
    const [isFocused, setIsFocused] = useState(
        document.activeElement && document.activeElement.name === name,
    );
    const isQMask = validQMaskTypes.includes(type);

    const inputProps = {
        className: `button__input text-input-button ${inputClassnames}`,
        id,
        name,
        value,
        placeholder,
        ref: txtInputBtnRef,
        type,
        ...(isQMask ? { ariaLabel: labelText } : { ["aria-label"]: labelText }),
        ...rest,
        onFocus: (e) => {
            setIsFocused(
                document.activeElement && document.activeElement.name === name,
            );
            if (typeof handleFocus === "function") {
                handleFocus(e);
            }
        },
        onBlur: (e) => {
            setIsFocused(false);
            if (typeof handleBlur === "function") {
                handleBlur(e);
            }
        },
    };

    const getInput = () => {
        return isQMask ? (
            <QMaskField type={type} {...inputProps} />
        ) : // Currency Input
        type === "currency" ? (
            <CurrencyInput {...inputProps} ref={txtInputBtnRef} />
        ) : (
            // Fallback
            <input type="text" {...inputProps} />
        );
    };

    return (
        <>
            <label
                className={cx(
                    "qg-vendor-button--input",
                    // basic button styling
                    "button",
                    // regular button styling
                    "button--input",
                    // selectable button styling ie. Custom Amount Donation Amounts
                    selectable && `button--input__${buttonStyle}`,
                    // removes margin
                    "button--has-text-input",
                    // active state for input, selectable adds card like glow
                    isFocused &&
                        !error &&
                        (selectable
                            ? "button--active__selectable"
                            : "button--active"),
                    // non selectable focused/active button styling, ie. Optional Donation.
                    // mimics field focus/active input styling
                    (isFocused || active) && !selectable && `button--selected`,
                    // selectable focused/active input styling
                    (isFocused || active) &&
                        selectable &&
                        `button--selected__${buttonStyle}`,
                    value && value > 0 && buttonClassnames && buttonClassnames,
                    error && "button--has-error",
                )}
            >
                <div className="button__content">
                    {children}
                    {getInput()}
                </div>
            </label>
            {error && errorText.length > 0 && (
                <div className={"button-error"}>
                    <p>{errorText}</p>
                </div>
            )}
        </>
    );
};

TextInputButton.displayName = "TextInputButton";

TextInputButton.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.any,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,
    id: PropTypes.string.isRequired,
    inputClassnames: PropTypes.string,
    buttonClassnames: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    txtInputBtnRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    type: PropTypes.string,
    value: PropTypes.string,
    buttonStyle: PropTypes.string,
    selectable: PropTypes.bool,
};

export default TextInputButton;
