import { useEffect } from "react";

import useEventListener from "../useEventListener";

/**
 * @function useFocusTrap
 * @param {object} ref React.useRef() reference to element to trap focus in
 * @param {Array} [allowedKeys] Keys allowed for navigating through
 *      focusable elements
 * @param {boolean} [focusOnRender] Should first focusable element
 *      receive focus on render?
 * @param {boolean} [wrapFocus] If navigating forward and the last
 *      element is active, should the first element receive focus?
 */
export default function useFocusTrap(
    ref,
    allowedKeys = ["Tab"],
    focusOnRender = true,
    wrapFocus = true,
) {
    useEventListener("keydown", (event) => {
        const { key, shiftKey } = event;

        // only execute if allowed key is pressed
        if (!ref?.current || !allowedKeys.includes(key)) {
            return;
        }

        // query all focusable elements
        const focusable = ref.current.querySelectorAll(
            "a[href], button:not([disabled]), input, select, [tabindex], textarea",
        );

        // return if there are no focusable elements
        if (!focusable.length) return;

        const focusedElIndex = Array.from(focusable).indexOf(
            document.activeElement,
        );
        const firstElement = focusable[0];
        const lastElement = focusable[focusable.length - 1];
        const tabbingForward = !shiftKey && key === "Tab";
        const arrowingForward = key === "ArrowDown" || key === "ArrowRight";
        const tabbingBackward = shiftKey && key === "Tab";
        const arrowingBackward = key === "ArrowUp" || key === "ArrowLeft";

        // if navigating forward and the focused element is not in the modal,
        // focus first element
        if (
            (tabbingForward || arrowingForward) &&
            !ref.current.contains(document.activeElement)
        ) {
            firstElement.focus();
            event.preventDefault();
            return;
        }

        // if navigating backward and the focused element is not in the modal,
        // focus last element
        if (
            (tabbingBackward || arrowingBackward) &&
            !ref.current.contains(document.activeElement)
        ) {
            lastElement.focus();
            event.preventDefault();
            return;
        }

        // if navigating forward and lastElement is active focus first element
        if (
            (tabbingForward || arrowingForward) &&
            document.activeElement === lastElement
        ) {
            if (wrapFocus) {
                firstElement.focus();
            }
            event.preventDefault();
            return;
        }

        // if navigating backward and firstElement is active, focus last element
        if (
            (tabbingBackward || arrowingBackward) &&
            document.activeElement === firstElement
        ) {
            if (wrapFocus) {
                lastElement.focus();
            }
            event.preventDefault();
            return;
        }

        // if down or right arrow is pressed, focus next element
        if (arrowingForward) {
            focusable[focusedElIndex + 1].focus();
            event.preventDefault();
            return;
        }

        // if up or left arrow is pressed, focus previous element
        if (arrowingBackward) {
            focusable[focusedElIndex - 1].focus();
            event.preventDefault();
        }
    });

    useEffect(() => {
        const prevFocusedEl = document.activeElement;

        // do not continue if an element is already focused
        if (
            !focusOnRender ||
            !ref?.current ||
            ref?.current?.contains(document.activeElement)
        )
            return undefined;

        // query all focusable elements
        const focusable = ref.current.querySelectorAll(
            "a[href], button:not([disabled]), textarea, input, select",
        );

        // focus first focusable element
        focusable[0]?.focus();

        // return focus to element that was focused before opening the modal
        return () => prevFocusedEl?.focus();
    }, [focusOnRender, ref]);
}
