// Asynchronous sessionStorage interactions to ensure its processes are complete
// before moving on to the next set of instructions in the event loop
const asyncSessionStorage = {
    /**
     * @function set
     * @description set wraps a Promise around the sessionStorage.setItem method
     * @param {string} key key of the item to place in sessionStorage
     * @param {object} value value object that is to be placed in session storage
     * @returns {Promise} Promise object that refers to the setting of an item in sessionStorage
     */
    set: (key, value) => {
        const sessionPromise = new Promise((resolve, reject) => {
            try {
                sessionStorage.setItem(key, JSON.stringify(value));
                resolve();
            } catch (err) {
                // The only times this will error is when we don't have access to the parent window's
                // session storage. This is the case for Incognito browsers viewing embedded forms on external sites.
                reject(err);
            }
        });
        return sessionPromise;
    },
    /**
     * @function get
     * @description get wraps a Promise around the sessionStorage.getItem method
     * @param {string} key key of the item in sessionStorage
     * @returns {Promise} Promise object that refers to the getting of an item in sessionStorage
     */
    get: (key) => {
        const sessionPromise = new Promise((resolve, reject) => {
            try {
                const data = JSON.parse(sessionStorage.getItem(key));
                resolve(data);
            } catch (err) {
                // The only times this will error is when we don't have access to the parent window's
                // session storage. This is the case for Incognito browsers viewing embedded forms on external sites.
                reject(err);
            }
        });
        return sessionPromise;
    },
    /**
     * @function remove
     * @description remove wraps a Promise around the sessionStorage.removeItem method
     * @param {string} key key of the item in sessionStorage
     * @returns {Promise} Promise object that refers to the removing of an item in sessionStorage
     */
    remove: (key) => {
        const sessionPromise = new Promise((resolve, reject) => {
            try {
                sessionStorage.removeItem(key);
                resolve();
            } catch (err) {
                // The only times this will error is when we don't have access to the parent window's
                // session storage. This is the case for Incognito browsers viewing embedded forms on external sites.
                reject(err);
            }
        });
        return sessionPromise;
    },
};

export default asyncSessionStorage;
