import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const StandardAmountCardDescription = ({
    amountAlignment,
    descr = "",
    displayDescriptionOnNewLine,
}) => (
    <div
        className={cx(
            "grid",
            amountAlignment === "left" &&
                `amount-card__desc-text--sm-${amountAlignment}`,
        )}
    >
        <div
            className={`col col--${
                displayDescriptionOnNewLine ? "10" : "12"
            } col--no-top-gutter amount-card__extra-title-container -type--small`}
            // -------------------------------------------------------------------------
            // NOTE: Rich Text can be added in CP, so dangerouslySetInnerHTML is needed
            // to render the html
            // -------------------------------------------------------------------------
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: descr,
            }}
        />
    </div>
);

StandardAmountCardDescription.propTypes = {
    descr: PropTypes.string,
    displayDescriptionOnNewLine: PropTypes.bool.isRequired,
    amountAlignment: PropTypes.string.isRequired,
};

export default StandardAmountCardDescription;
