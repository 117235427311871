import React, { cloneElement, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import useEventListener from "../../hooks/useEventListener";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PopperPopOut from "./PopperPopOut";
import PopperTrigger from "./PopperTrigger";

import "./Popper.scss";

const Trigger = ({
    children,
    showPopOut,
    block,
    triggerRef,
    onTriggerClick,
}) => {
    const [trigger, popout] = children;

    return cloneElement(trigger, {
        "aria-haspopup": popout.props.role || "menu",
        "aria-expanded": showPopOut,
        className: cx(block && `${block}__trigger`, trigger.props.className),
        ref: triggerRef,
        onClick: onTriggerClick,
    });
};

const PopOut = ({ children, block, closePopOut }) => {
    const [, popout] = children;

    return cloneElement(popout, {
        className: cx(block && `${block}__pop-out`, popout.props.className),
        closePopOut,
    });
};

const Popper = (props) => {
    const { block, modifiers, onClick, onClose, onOpen } = props;
    const [showPopOut, setShowPopOut] = useState(false);
    const ref = useRef();
    const triggerRef = useRef();
    const popperClasses = cx(
        "popper",
        block,
        block &&
            modifiers?.length &&
            modifiers?.map((modifier) => `${block}--${modifier}`),
    );

    const closePopOut = (e) => {
        setShowPopOut(false);
        onClose?.call(null, e);
    };

    const openPopOut = (e) => {
        setShowPopOut(true);
        onOpen?.call(null, e);
    };

    const onTriggerClick = (e) => {
        if (showPopOut) {
            closePopOut(e);
        } else {
            openPopOut(e);
        }
        onClick?.call(null, e);
    };

    useOnClickOutside(ref, closePopOut);

    useEventListener("keydown", (e) => {
        // only execute if escape is pressed
        if (e.key !== "Escape") return;

        closePopOut(e);
    });

    return (
        <div className={popperClasses} ref={ref}>
            <Trigger
                {...props}
                {...{
                    onTriggerClick,
                    showPopOut,
                    triggerRef,
                }}
            />

            {showPopOut && (
                <PopOut
                    {...props}
                    {...{
                        closePopOut,
                    }}
                />
            )}
        </div>
    );
};

Popper.propTypes = {
    block: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
};

// Compound component allows you to only import the Popper component instead of
// all the pieces that you will need
Popper.PopOut = PopperPopOut;
Popper.Trigger = PopperTrigger;

export default Popper;
