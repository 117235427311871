import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    selectCurrentPage,
    selectIsSinglePageForm,
} from "../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectDonorAccount } from "../../../redux/slices/donorAccountSlice";
import { selectIsTextToDonate } from "../../../redux/slices/smsDataSlice";
import { selectHasActiveFulfillment } from "../../../redux/slices/promiseTransactionSlice";
import { selectIsCms } from "../../../redux/slices/configSettingsSlice";
import PageHeader from "./PageHeader";

const ConnectedPageHeader = ({
    control,
    displayDetailSignInTextSinglePage,
    displayLockIconSinglePage,
    displayTextOrInvoiceHeaderForSinglePage,
}) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS, DETAILS, PAYMENT_DETAILS },
        },
    } = constants;

    const { options = {} } = control;
    const {
        additionalText = "",
        signInText = "Have an account? %SIGNIN% to speed things up!",
        textToDonateTitle = "Complete Your Gift",
        title = "Choose Your Gift",
        payInvoiceTitle = "Your Invoice",
    } = options;
    const { enableDonorLogins } = useSelector(selectAllFormSettings);
    const currentPage = useSelector(selectCurrentPage);
    const donorAccount = useSelector(selectDonorAccount);
    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);
    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const isCms = useSelector(selectIsCms);

    const isTextToDonate = useSelector(selectIsTextToDonate);
    const shouldUseTextToDonateTitle =
        isTextToDonate &&
        ((currentPage === DONATION_AMOUNTS && !isSinglePageForm) ||
            displayTextOrInvoiceHeaderForSinglePage);

    const shouldUseInvoiceTitle =
        hasActiveFulfillment &&
        ((currentPage === DONATION_AMOUNTS && !isSinglePageForm) ||
            displayTextOrInvoiceHeaderForSinglePage);

    const isDetailsPageWithDonorAccountEnabled =
        (currentPage === DETAILS || displayDetailSignInTextSinglePage) &&
        enableDonorLogins;

    const showHeaderLogin =
        !hasActiveFulfillment &&
        signInText &&
        isDetailsPageWithDonorAccountEnabled &&
        ((donorAccount && Object.keys(donorAccount).length === 0) || isCms);

    const showLockIconInHeading =
        currentPage === PAYMENT_DETAILS || displayLockIconSinglePage;

    const showAdditionalText =
        !!additionalText && !isDetailsPageWithDonorAccountEnabled;

    const getSectionHeading = () => {
        if (shouldUseTextToDonateTitle) {
            return textToDonateTitle;
        }
        if (shouldUseInvoiceTitle) {
            return payInvoiceTitle;
        }
        return title;
    };

    const sectionHeading = getSectionHeading();

    const pageHeaderProps = {
        additionalText,
        control,
        sectionHeading,
        showAdditionalText,
        showHeaderLogin,
        showLockIconInHeading,
    };
    return <PageHeader {...pageHeaderProps} />;
};

ConnectedPageHeader.propTypes = {
    control: PropTypes.shape({
        id: PropTypes.number.isRequired,
        options: PropTypes.shape({
            additionalText: PropTypes.string,
            signInText: PropTypes.string,
            textToDonateTitle: PropTypes.string,
            title: PropTypes.string,
        }),
    }).isRequired,
    displayDetailSignInTextSinglePage: PropTypes.bool.isRequired,
    displayLockIconSinglePage: PropTypes.bool.isRequired,
    displayTextOrInvoiceHeaderForSinglePage: PropTypes.bool.isRequired,
};

export default ConnectedPageHeader;
