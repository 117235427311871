import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "../../analytics";

const HistoryTracking = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        trackPageView();
    }, [pathname]);

    return null;
};

export default HistoryTracking;
