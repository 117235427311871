import React from "react";
import PropTypes from "prop-types";
import { DonorAccountActionLink } from "@qgiv/core-donor";
import { Icon } from "@qgiv/core-react";

const DonorIsLoggedIn = ({ donorIsLoggedInProps, myAccountProps }) => (
    <>
        <DonorAccountActionLink {...myAccountProps} />
        <Icon
            glyph="circle-solid"
            type="FontAwesome"
            classNames={["login__dot", "-margin--5", "-vertical-align--middle"]}
        />
        <DonorAccountActionLink {...donorIsLoggedInProps} />
    </>
);

DonorIsLoggedIn.propTypes = {
    donorIsLoggedInProps: PropTypes.shape({}).isRequired,
    myAccountProps: PropTypes.shape({}).isRequired,
};

export default DonorIsLoggedIn;
