import React from "react";
import PropTypes from "prop-types";

import TextField from "../Fields/TextField";

const DonorAccountUsername = (props) => {
    const {
        handleChange,
        handleBlur,
        touched,
        errors,
        values: { First_Name, Last_Name },
    } = props;

    return (
        <>
            <div className="col col--12 col--sm-6 col--md-6">
                <TextField
                    id="First_Name"
                    name="First_Name"
                    label="First name"
                    type="text"
                    value={First_Name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    error={Boolean(touched.First_Name && errors.First_Name)}
                    errorText={errors.First_Name}
                />
            </div>
            <div className="col col--12 col--sm-6 col--md-6">
                <TextField
                    id="Last_Name"
                    name="Last_Name"
                    label="Last name"
                    type="text"
                    value={Last_Name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    error={Boolean(touched.Last_Name && errors.Last_Name)}
                    errorText={errors.Last_Name}
                />
            </div>
        </>
    );
};

DonorAccountUsername.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    values: PropTypes.shape({
        First_Name: PropTypes.string,
        Last_Name: PropTypes.string,
    }),
};

export default DonorAccountUsername;
