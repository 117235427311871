import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectCmsContainersByPage } from "../../../../redux/slices/cmsSettingsSlice";
import EventsPageContent from "./EventsPageContent";

/**
 * @returns {React.ReactElement}
 */
const ConnectedEventsPageContent = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { QGIV_EVENTS },
        },
    } = constants;

    const containers = useSelector((state) =>
        selectCmsContainersByPage(state, QGIV_EVENTS),
    );

    return <EventsPageContent containers={containers} />;
};

export default ConnectedEventsPageContent;
