import { constants } from "@qgiv/core-js";

/**
 * @public
 * @function getSelectedStandardRecurringId
 * @description Determines starting value of Selected_Recurring_Id
 * @returns {string} Selected One Time amount initial value
 */
export const getSelectedStandardRecurringId = () => {
    const defaultValue = "";

    return defaultValue;
};

/**
 * @public
 * @function getOtherRecurringAmountInitialValue
 * @description Determines starting value of Other_Amount
 * @returns {string} Other amount initial value
 */
export const getOtherRecurringAmountInitialValue = () => {
    const otherAmount = "";

    return otherAmount;
};

/**
 * @public
 * @function getRecurringFrequencyInitialValue
 * @param {object} settings Form Page settings object
 * @description Determines starting value of Other_Amount
 * @returns {string} Other amount initial value
 */
export const getRecurringFrequencyInitialValue = (settings) => {
    const {
        donationSettings: { defaultRecurringFrequency },
    } = settings;
    let frequencyValue = "";
    // -------------------------------------------------------------------------
    // NOTE: Plans and Invoices will set their own initial frequency so
    // we don't need to worry about them here
    // -------------------------------------------------------------------------

    // Default is to use the default frequency, or
    //    if all else fails, "m".
    frequencyValue = defaultRecurringFrequency?.value || "m";

    return frequencyValue;
};

const getStandardRecurringInitialValues = (settings) => {
    const {
        ENUMS: { ItemType },
    } = constants;

    const {
        donationSettings: { activeAmounts, enableStartDate, enableEndDate },
        formSettings: { enableRecur },
    } = settings;

    // Filter out the Other Amount then check if there are any displayed for recurring
    const hasActiveRecurringAmounts = activeAmounts
        ?.filter(
            (standardAmount) => standardAmount.amountType === ItemType.CUSTOM,
        )
        ?.some((recurringAmount) => recurringAmount.displayForRecurring);

    const hasActiveRecurringOtherAmount = activeAmounts?.find(
        (recurringAmount) => recurringAmount.amountType === ItemType.OTHER,
    )?.displayForRecurring;

    const shouldRenderStandardRecurringDonationInputs =
        enableRecur &&
        activeAmounts?.length > 0 &&
        // TODO: Add not multi restriction condition
        (hasActiveRecurringAmounts || hasActiveRecurringOtherAmount);

    const hasRecurringStartDate = enableStartDate;
    const hasRecurringEndDate = enableEndDate;

    const values = shouldRenderStandardRecurringDonationInputs
        ? {
              // Donation Values
              ...(hasActiveRecurringAmounts && {
                  Selected_Recurring_Id:
                      getSelectedStandardRecurringId(settings),
              }),

              ...(hasActiveRecurringOtherAmount && {
                  Other_Recurring_Amount:
                      getOtherRecurringAmountInitialValue(settings),
              }),
              // Donation Frequency
              Recurring_Frequency: getRecurringFrequencyInitialValue(settings),
              // Recurring Dates
              ...(hasRecurringStartDate && {
                  Start_Date: "",
              }),
              ...(hasRecurringEndDate && {
                  End_Date: "",
              }),
          }
        : {};

    return values;
};

export default getStandardRecurringInitialValues;
