import React from "react";
import PropTypes from "prop-types";
import ConnectedGiftAssist from "../../Pages/GiftPage/DonatedGiftSection/GiftAssist";

const GiftAssistControl = ({ controlId }) => (
    <ConnectedGiftAssist controlId={controlId} />
);

GiftAssistControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default GiftAssistControl;
