import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getMinMaxMessageProps } from "@qgiv/donation-form";
import { MinMaxMessage } from "@qgiv/core-donor";
import { selectAllDonationSettings } from "../../../../../redux/slices/donationSettingsSlice";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";

const ConnectedMinMaxMessage = ({
    controlId,
    shouldConsiderDisplayOfMinMaxMessage,
}) => {
    const { currency } = useSelector(selectAllFormSettings);
    const donationSettings = useSelector(selectAllDonationSettings);

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options } = control;

    const { maximumGiftMessage = "", minimumGiftMessage = "" } = options;

    const minMaxMessageProps = getMinMaxMessageProps({
        maximumGiftMessage,
        minimumGiftMessage,
        currency,
        donationSettings,
        shouldConsiderDisplayOfMinMaxMessage,
    });

    return <MinMaxMessage {...minMaxMessageProps} />;
};

ConnectedMinMaxMessage.propTypes = {
    controlId: PropTypes.number.isRequired,
    shouldConsiderDisplayOfMinMaxMessage: PropTypes.bool.isRequired,
};

export default ConnectedMinMaxMessage;
