import React from "react";
import { constants } from "@qgiv/core-js";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getActiveDisplayableRestrictions,
    getAmountsToDisplay,
} from "@qgiv/donation-form";
import { selectConfig } from "../../../../../../redux/slices/configSettingsSlice";
import {
    selectAllDonationDetails,
    updateGiftDetails,
} from "../../../../../../redux/slices/donationDetailsSlice";
import { selectCmsControlByIdByPage } from "../../../../../../redux/slices/cmsSettingsSlice";
import { selectAllDonationSettings } from "../../../../../../redux/slices/donationSettingsSlice";
import { selectAllFormSettings } from "../../../../../../redux/slices/formSettingsSlice";
import {
    selectMultiRestrictionGroups,
    selectRestrictions,
} from "../../../../../../redux/slices/restrictionSettingsSlice";
import {
    getRestrictionsForCards,
    getRestrictionCards,
} from "./restrictionCardsHelpers";
import RestrictionCards from "./RestrictionCards";

// -------------------------------------------------------------------------
// NOTE: When working with Multi-Restriction logic there are 3 event
// handlers you need to be aware of:
// 1. handleRestrictionCardClick() in in restrictionCardsHelpers.js
// Handles clicks in the restriction card that are made outside of the
// restriction label. Responsible for selecting but not de-selecting a
// restriction.
// 2. handleRestrictionCardLabelClick() in ConnectedRestrictionCardLabel.js
// Handles clicks in the restriction card that are made inside the label.
// Can be used for selecting & de-selecting a restriction.
// 3. handleStandardAmountChange() in in restrictionCardAmountHelpers.js
// Changes the value of the amount. Does not do anything to the checked
// status as changes to the amount also trigger the handleClick() in in
// restrictionCardsHelpers.js which will handle the logic associated with
// the checked value. This was done as I cannot stop the bubbling of a
// click event from within a change event.
// -------------------------------------------------------------------------
/**
 *
 * @param {object} props
 * @param {number} props.controlId
 * @param {number[]} props.restrictionIdsForRestrictionGroup
 * @returns {React.ReactElement}
 */
const ConnectedRestrictionCards = ({
    controlId,
    restrictionIdsForRestrictionGroup,
}) => {
    const { ENUMS } = constants;
    const config = useSelector(selectConfig);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(
            state,
            controlId,
            ENUMS.CmsPageStandardizer.DONATION_AMOUNTS,
        ),
    );
    const dispatch = useDispatch();
    const donationDetails = useSelector(selectAllDonationDetails);
    const donationSettings = useSelector(selectAllDonationSettings);
    const formikContext = useFormikContext();
    const formSettings = useSelector(selectAllFormSettings);
    const multiRestrictionGroups = useSelector(selectMultiRestrictionGroups);
    const restrictions = useSelector(selectRestrictions);
    const { currentDisplay = ENUMS.DisplayOn.FRONTEND } = config;
    const { giftDetails = {} } = donationDetails;
    const { activeAmounts = [], maxAmt = 0, minAmt = 0 } = donationSettings;
    const { donationActive = true, enableRecur = true } = formSettings;
    const { hasSelectedRecurringDonation = false } = giftDetails;

    // The BE hands us all active restrictions regardless of whether or not
    // they should be shown on this device
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    const restrictionsForCards = getRestrictionsForCards({
        activeDisplayableRestrictions,
        multiRestrictionGroups,
        restrictionIdsForRestrictionGroup,
    });

    const amountsToDisplay = getAmountsToDisplay({
        activeAmounts,
        donationActive,
        enableRecur,
        hasSelectedRecurringDonation,
        maxAmt,
        minAmt,
    });

    const restrictionCards = getRestrictionCards({
        amountsToDisplay,
        config,
        control,
        controlId,
        dispatch,
        formikContext,
        hasSelectedRecurringDonation,
        restrictionsForCards,
        updateGiftDetails,
    });

    const restrictionCardsProps = {
        restrictionCards,
    };

    return <RestrictionCards {...restrictionCardsProps} />;
};

export default ConnectedRestrictionCards;
