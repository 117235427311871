import loadIcons from "./source/icons";

// @ts-ignore
const Custom = require.context("./source/Custom", false);
// @ts-ignore
const FontAwesome = require.context("./source/FontAwesome", false);
// @ts-ignore
const Icomoon = require.context("./source/Icomoon", false);

export default loadIcons;

export { Custom, FontAwesome, Icomoon };
