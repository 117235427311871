// -------------------------------------------------------------------------
// MARK: Array.prototype.find() Helpers
// -------------------------------------------------------------------------
/**
 * @function findEntityByKeyValue
 * @param {string} key The key to search by on a given entity
 * @param {string} value The value to match against the entity's searched key
 * @description Helper func that generates a callback func to pass to an array
 *      func to search an array of entities by a specified key value pair E.g.
 *      `myArray.find(findEntityByKeyValue("id", "unique-id"))`
 * @returns {Function} Array filter helper func
 */
export const findEntityByKeyValue = (key, value) => (entity) =>
    entity[key] === value;

/**
 * @function findEntityById
 * @param {string} id The id to search an array of entities by
 * @description Helper func that generates a callback func to pass to an array
 *      func to search an array of entities by a specified id E.g.
 *      `myArray.find(findEntityById("unique-id"))`
 * @returns {Function} Array filter helper func
 */
export const findEntityById = (id) => findEntityByKeyValue("id", id);

/**
 * @function findEntityByName
 * @param {string} name The name to search an array of entities by
 * @description Helper func that generates a callback func to pass to an array
 *      func to search an array of entities by a specified name E.g.
 *      `myArray.find(findEntityByName("name"))`
 * @returns {Function} Array filter helper func
 */
export const findEntityByName = (name) => findEntityByKeyValue("name", name);

// -------------------------------------------------------------------------
// MARK: Array.prototype.sort() Helpers
// -------------------------------------------------------------------------
/**
 * @function compareWeightAsc
 * @description A comparison function used to sort an array of objects that have
 *      a key of "weight". Pass this to Array.prototype.sort() when attempting
 *      to sort objects by their weight in ascending order.
 * @param {object} a Object "a" from Array.prototype.sort()
 * @param {object} b Object "b" from Array.prototype.sort()
 * @returns {number} -1 or 1 or 0
 */
export const compareWeightAsc = (a, b) => {
    const aWeight = parseInt(a.weight, 10);
    const bWeight = parseInt(b.weight, 10);
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line no-nested-ternary
    return aWeight < bWeight ? -1 : aWeight > bWeight ? 1 : 0;
};

// -------------------------------------------------------------------------
// MARK: Array Merge
// -------------------------------------------------------------------------
/**
 * @function mergeArraysByIds
 * @description Merges two arrays. `arr1` contains old objects. `arr2` contains
 *      new objects. The resulting array will replace any existing objects with
 *      their new counterpart, and keeps any pre-existing objects in `arr1` that
 *      do not exist in `arr2`. Objects are matched by ids.
 * @param {Array} [arr1] Array with potentially outdated objects
 * @param {Array} [arr2] Array with new objects
 * @returns {Array} New array with updated objects
 */
export const mergeArraysByIds = (arr1 = [], arr2 = []) =>
    arr1
        .map(
            (oldObj) =>
                arr2.find((newObj) => newObj.id === oldObj.id) || oldObj,
        )
        .concat(
            arr2.filter(
                (newObj) => !arr1.find((oldObj) => oldObj.id === newObj.id),
            ),
        );

// -------------------------------------------------------------------------
// MARK: Sort array alphabetically by key
// -------------------------------------------------------------------------
/**
 * @function sortArrayAlphabeticallyByKey
 * @description Sorts an array of objects alphabetically by a given key
 * @param {Array} arrayToSort Array that you want to sort alphabetically
 * @param {string} key Object key in array item that you want to sort by
 * @returns {Array} new instance of array, sorted alphabetically
 */
export const sortArrayAlphabeticallyByKey = (arrayToSort, key) =>
    arrayToSort.sort((a, b) => a[key].localeCompare(b[key]));

/**
 * @function filterOutDuplicateItemsInArrayBasedOnKey
 * @description Filters out duplicate items in an array based on a specified key.
 * @param {Array} arrayToBeFiltered The array from which duplicates should be removed.
 * @param {string} key The key in the objects of the array to determine uniqueness.
 * @returns {Array} A new array with duplicates removed based on the specified key.
 * @example
 * const array = [
 *     { id: 1, name: 'Alice' },
 *     { id: 2, name: 'Bob' },
 *     { id: 1, name: 'Alice' },
 *     { id: 3, name: 'Charlie' }
 * ];
 * const uniqueArray = filterOutDuplicateItemsInArrayBasedOnKey(array, 'id');
 * // Output: [
 * //     { id: 1, name: 'Alice' },
 * //     { id: 2, name: 'Bob' },
 * //     { id: 3, name: 'Charlie' }
 * // ]
 */
export const filterOutDuplicateItemsInArrayBasedOnKey = (
    arrayToBeFiltered = [],
    key = "",
) => {
    if (key === "") return arrayToBeFiltered;
    const arrayWithoutDuplicates = arrayToBeFiltered.reduce(
        (finalArray, currentItem) => {
            const currentItemKeyValue = currentItem?.[key];
            if (currentItemKeyValue !== undefined) {
                const existingItemWithSameValue =
                    finalArray.find(
                        (item) => item?.[key] === currentItemKeyValue,
                    ) || {};
                return Object.keys(existingItemWithSameValue).length === 0
                    ? [...finalArray, currentItem]
                    : finalArray;
            }
            return finalArray;
        },
        [],
    );
    return arrayWithoutDuplicates;
};
