import React from "react";
import PropTypes from "prop-types";
import ConnectedSystemFieldGroupPrivacyOptions from "../../Pages/DetailsPage/PrivacyOptions";

const PrivacyOptionsControl = ({ controlId }) => (
    <ConnectedSystemFieldGroupPrivacyOptions controlId={controlId} />
);

PrivacyOptionsControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default PrivacyOptionsControl;
