import React from "react";
import PropTypes from "prop-types";
import { PoweredByQgiv } from "@qgiv/core-react";
import { SEO } from "@qgiv/core-donor";
import ConnectedReceiptPageContent from "./ReceiptPageContent";
import ConnectedDonationFormReceiptBase from "../templates/DonationFormReceiptBase";
import ConnectedReceiptMatchingWidget from "./ConnectedReceiptMatchingWidget";

const ReceiptPage = ({ documentTitle = "", showMatchingGifts = false }) => (
    <ConnectedDonationFormReceiptBase>
        <SEO title={documentTitle} description="" />
        <ConnectedReceiptPageContent />
        {showMatchingGifts && <ConnectedReceiptMatchingWidget />}
        <PoweredByQgiv dark />
    </ConnectedDonationFormReceiptBase>
);

ReceiptPage.propTypes = {
    documentTitle: PropTypes.string,
    showMatchingGifts: PropTypes.bool,
};

export default ReceiptPage;
