import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import { useDispatch, useSelector } from "react-redux";
import {
    determineCorrespondingEmptyValueToUpdate,
    getShouldDisplayAmountsAsCards,
    getStandardAmounts,
    getSelectedAmount,
} from "@qgiv/donation-form";
import { useFormikContext } from "formik";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectAllDonationSettings } from "../../../redux/slices/donationSettingsSlice";
import {
    updateGiftDetails,
    selectGiftDetails,
} from "../../../redux/slices/donationDetailsSlice";
import StandardAmountsRadioOptions from "./StandardAmountsRadioOptions";

const ConnectedStandardAmountsRadioOptions = ({
    amountsToDisplay,
    buttonStyle,
    donationAmountAlignment,
    fieldName,
    standardAndOtherAmountRefs,
    isDisplayedAsPartOfCYOP = false,
    isRecurringDisplay = false,
}) => {
    const {
        ENUMS: { GivingPlanSystemType },
    } = constants;

    const { setFieldValue, values } = useFormikContext();

    const dispatch = useDispatch();

    const { currency = {}, imageSize = 1 } = useSelector(selectAllFormSettings);

    const giftDetails = useSelector(selectGiftDetails);
    const { hasSelectedRecurringDonation } = giftDetails;

    const { activeAmounts, givingPlanSystemType = null } = useSelector(
        selectAllDonationSettings,
    );

    const shouldDisplayAmountsAsCards = getShouldDisplayAmountsAsCards(
        activeAmounts,
        isRecurringDisplay,
    );

    const isInstallment =
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    const idOfSelectedAmount = values[fieldName];

    const standardAmounts = getStandardAmounts({
        shouldDisplayAmountsAsCards,
        amountsToDisplay,
        buttonStyle,
        currency,
        donationAmountAlignment,
        fieldName,
        idOfSelectedAmount,
        imageSize,
        isDisplayedAsPartOfCYOP,
        standardAndOtherAmountRefs,
        isRecurringDisplay,
    });

    const handleStandardAmountChange = (e) => {
        const { currentTarget } = e;
        const { name, value } = currentTarget;

        setFieldValue(name, parseInt(value, 10));
        dispatch(updateGiftDetails({ [name]: parseInt(value, 10) }));

        // handles flexible giving plan create your own plan
        if (isInstallment && hasSelectedRecurringDonation) {
            const customFlexibleAmount = getSelectedAmount(
                activeAmounts,
                value,
            );
            dispatch(
                updateGiftDetails({
                    customFlexibleGivingPlanTotal: customFlexibleAmount?.amount,
                }),
            );
        }

        const otherTabValueToSet = determineCorrespondingEmptyValueToUpdate({
            activeAmounts,
            name,
            value,
            values,
        });

        // Update corresponding value if there is one and it does not already have a value.
        // For example, if user is on One Time tab and they select the Other amount we want
        // to also update the Recurring Other amount to be selected only if there is not already
        // a value for Selected_Recurring_Id
        if (Object.keys(otherTabValueToSet).length > 0) {
            setFieldValue(otherTabValueToSet.name, parseInt(value, 10));
            dispatch(
                updateGiftDetails({
                    [otherTabValueToSet.name]: parseInt(value, 10),
                }),
            );
        }
    };

    return (
        <StandardAmountsRadioOptions
            standardAmounts={standardAmounts}
            handleStandardAmountChange={handleStandardAmountChange}
        />
    );
};

ConnectedStandardAmountsRadioOptions.propTypes = {
    amountsToDisplay: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    buttonStyle: PropTypes.string.isRequired,
    donationAmountAlignment: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    isDisplayedAsPartOfCYOP: PropTypes.bool,
    isRecurringDisplay: PropTypes.bool,
    standardAndOtherAmountRefs: PropTypes.arrayOf(PropTypes.shape({}))
        .isRequired,
};

export default ConnectedStandardAmountsRadioOptions;
