import moment from "moment";
import {
    constants,
    currencyString,
    findEntityByKeyValue,
    getFormattedEventDate,
} from "@qgiv/core-js";
import countries from "country-region-data/data.json";

// -------------------------------------------------------------------------
// MARK: Generic Helpers
// -------------------------------------------------------------------------
/**
 * @public
 * @function calcGiftAssistFee
 * @param {string} donationAmount Current donation amount
 * @param {object} settings Settings object
 * @param {string|number} settings.feeCoverage Indicates whether GiftAssist is a percentage or flat fee
 * @param {string} settings.feeCoverageFlat GiftAssist amount if it is a flat fee
 * @param {string} settings.feeCoveragePercentage GiftAssist percentage if it is a percentage
 * @returns {string} Returns calculated flat amount, rounded to two decimal places
 * @description Calculates the flat GiftAssist amount using form settings
 */
export const calcGiftAssistFee = (
    donationAmount,
    { feeCoverage, feeCoverageFlat, feeCoveragePercentage },
) => {
    const _feeCoverage = feeCoverage ? Number(feeCoverage) : 0;
    const isPercentageFee = _feeCoverage === 1;
    const isFlatFee = _feeCoverage === 2;
    const _donationAmount = parseFloat(donationAmount) || 0;
    const _feeCoveragePercentage = parseFloat(feeCoveragePercentage);
    const percentageFee = (_feeCoveragePercentage / 100) * _donationAmount;
    const feeAmount =
        isPercentageFee && percentageFee
            ? percentageFee
            : isFlatFee
              ? parseFloat(feeCoverageFlat)
              : 0;
    // prettier-ignore
    const roundedFeeAmount = qRound(feeAmount * 100 / 100);
    return `${roundedFeeAmount}`;
};

/**
 * @public
 * @function calEarlyBirdPrice
 * @description A calculation of Early Bird pricing based on type
 * @param {number} type The number representing either amount or percentage
 * @param {number} discount The number subtracted or percentage taken off
 * @param {number} cost The original package price
 * @returns {number} Early bird price
 */
export const calEarlyBirdPrice = (type, discount, cost) => {
    cost = cost || 0;
    discount = discount || 0;
    let earlyBirdPrice = cost;

    if (type == 1) {
        earlyBirdPrice = cost - discount;
    } else if (type == 2) {
        earlyBirdPrice = cost - cost * (discount / 100);
    }
    return earlyBirdPrice;
};

/**
 * @public
 * @function findEntityByCleanedName
 * @param {string} cleanedName The name to search an array of entities by
 * @description Helper func that generates a callback func to pass to
 *              an array func to search an array of entities by a specified
 *              cleanedName that has been stripped of all special characters
 *              E.g. `myArray.find(findEntityByName("name"))`
 * @returns {Function} Array find helper func
 */
export const findEntityByCleanedName = (cleanedName) => {
    return findEntityByKeyValue("cleanedName", cleanedName);
};

/**
 * @public
 * @function isValidFrequencyLetter
 * @param {string} letter One letter string representing a recurring frequency
 * @param {string} don_frequencies Comma separated frequency letters
 *                                 e.g "w,b,m,a"
 * @description Given a string of enabled recurring frequencies, determine
 *              if a given frequency letter is valid.
 * @returns {boolean} Flag determining whether a given frequency letter is valid
 */
export const isValidFrequencyLetter = (letter = "", don_frequencies = "") => {
    const isValidLetter = typeof letter === "string" && letter.length === 1;
    const isEnabled =
        isValidLetter &&
        typeof don_frequencies === "string" &&
        don_frequencies.length > 0 &&
        don_frequencies.includes(letter);
    return isEnabled;
};

/**
 * @public
 * @function getValidUrlShortcutFrequency
 * @description Given an object of url shortcuts, determine if the frequency
 *              shortcut exists and is valid
 * @param {object} urlShortcuts Url shortcuts object
 * @param {string} don_frequencies Donation frequency comma separated string
 * @returns {string|null} Either the valid shortcut frequency letter or null
 */
export const getValidUrlShortcutFrequency = (
    urlShortcuts = null,
    don_frequencies = null,
) => {
    if (urlShortcuts) {
        return (
            urlShortcuts &&
            isValidFrequencyLetter(urlShortcuts.frequency, don_frequencies) &&
            urlShortcuts.frequency
        );
    } else {
        return null;
    }
};

/**
 * @public
 * @function qRound
 * @param  {string|number} amount Amount to apply fee to
 * @description This is how we round things at Qgiv. Maybe one day we'll use Math.round()
 * @returns {number} amount rounded to the hundredths place
 */
export const qRound = (amount) => {
    const value = `${amount}`.split(".");

    let num = value[0] && value[0].length > 0 ? parseInt(value[0]) : 0;
    const decimal = value[1] && value[1].length > 0 ? value[1] : "0000";

    let tenths = parseInt(decimal[0]);
    let hundredths = parseInt(decimal[1] ? decimal[1] : "0");
    const thousandths = parseInt(decimal[2] ? decimal[2] : "0");
    const tenThousandths = parseInt(decimal[3] ? decimal[3] : "0");

    if (thousandths > 5 || (thousandths === 5 && tenThousandths > 0)) {
        // round up
        if (hundredths === 9) {
            hundredths = 0;

            if (tenths === 9) {
                tenths = 0;
                num++;
            } else {
                tenths++;
            }
        } else {
            hundredths++;
        }
    }

    const ret = parseFloat(`${num}.${tenths}${hundredths}`).toFixed(2);
    return ret;
};

/**
 * @public
 * @function getFormattedPrice
 * @description Helper func to get either a currency formatted number
 *      or a custom string of text from p2p settings.  Currently used only in Standard P2P, however
 *      this has potential to be used elsewhere if the situation arises.
 * @param {number} currentCostToEvaluate Price to be formatted
 * @param {object} currency The passed in currency settings object
 * @param {number} feeDisplaySetting CP selected fee display setting for a $0 value
 * @param {string} feeDisplayCustomText Custom text to represent a $0, (ex. Free)
 * @returns {string} Formatted Price is returned as a string of currency and number or the $0 representation
 *      determined by the fee display settings
 */
export const getFormattedPrice = (
    currentCostToEvaluate,
    currency,
    feeDisplaySetting,
    feeDisplayCustomText,
    hasPromo = false,
) => {
    const {
        ENUMS: {
            RegistrationFeeDisplay: {
                DISPLAY_CUSTOM_TEXT,
                DISPLAY_STRING_0,
                DISPLAY_NOTHING,
            },
        },
    } = constants;
    const isFree = currentCostToEvaluate === 0;
    const formattedPrice = currencyString(currentCostToEvaluate, currency);

    if (isFree) {
        switch (feeDisplaySetting) {
            case DISPLAY_CUSTOM_TEXT: {
                return feeDisplayCustomText;
            }
            case DISPLAY_STRING_0:
                return formattedPrice;
            case DISPLAY_NOTHING:
                // When promo code brings price to $0 we should still
                // show the $0 price
                if (hasPromo) {
                    return formattedPrice;
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }

    return formattedPrice;
};

/**
 * @public
 * @function getCountryNameFromShortCode
 * @param {string} country The country shortCode to be translated
 * @description Finds country name from countries json by shortCode and returns full name
 * @returns {string} Full country name
 */
export const getCountryNameFromShortCode = (country) => {
    let countryName = country
        ? countries.find(
              (countryObj) => countryObj.countryShortCode === country,
          )?.countryName
        : "";
    return countryName;
};

// -------------------------------------------------------------------------
// MARK: Accessibility Helpers
// -------------------------------------------------------------------------

/**
 * @public
 * @function radioKeyboardEventHandler
 * @description function for triggering focus events to happen on correct input refs when using arrow key
 *              on non-native radio group/radio button elements
 * @param {object} event event object from onKeyDown event
 * @param {number} index position in the array of the refs which caused the event to be triggered
 * @param {Array} refArray array of the React refs that are available
 * @returns {void}
 */
export const radioKeyboardEventHandler = (event, index = 0, refArray = []) => {
    const actionKeys = ["ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight"];
    const { key } = event;
    if (actionKeys.includes(key)) {
        event.preventDefault();

        const lastIndex = refArray.length - 1;

        switch (key) {
            case "ArrowUp":
            case "ArrowLeft":
                // moving to previous ref
                if (index - 1 >= 0) {
                    return refArray[index - 1].current.focus();
                }
                return refArray[lastIndex].current.focus();
            case "ArrowDown":
            case "ArrowRight":
                // moving to next ref
                if (index + 1 <= lastIndex) {
                    return refArray[index + 1].current.focus();
                }
                return refArray[0].current.focus();
            default:
                break;
        }
    }
};

/**
 * @public
 * @function handleModalKeyDown
 * @description Ensures that once the modal is open focus is not able to go outside of
                the modal by redirecting focus when it is moved to the previous
                focusable element from the first focusable element or to the next
                focusable element from the last focusable element.
 * @param {object} [event] keydown event
 * @param {object} [ref] React reference from useRef React hook
 * @returns {void}
 */
export const handleModalKeyDown = (event, ref) => {
    const isTabPressed = event.key === "Tab" || event.keyCode === 9;
    // quick return for all other types of key press
    if (!isTabPressed) {
        return;
    }
    // Selectors that correspond with the list of possible elements that
    // will receive focus from the browser
    const focusableElements = [
        "button:not([disabled])",
        "input:not([disabled])",
        "select:not([disabled])",
        "textarea:not([disabled])",
        'a[href]:not([tabindex="-1"]):not([disabled])',
        '[tabindex]:not([tabindex="-1"]):not([disabled])',
    ];

    // Get a list of all of the focusable content that is nested within the
    // window including any markup that is passed down via props.children
    const elementQueryString = focusableElements.join(", ");
    const allFocusableContentInModal =
        ref.current.querySelectorAll(elementQueryString);

    const hasFocusableContentInModal = allFocusableContentInModal.length > 0;
    const elementThatHasFocus = document.activeElement;
    const firstFocusableElementInModal =
        hasFocusableContentInModal && allFocusableContentInModal[0];
    let lastFocusableElementInModal =
        hasFocusableContentInModal &&
        allFocusableContentInModal[allFocusableContentInModal.length - 1];

    // For the full screen modal, querySelectorAll is including elements that
    // are not within the modal content. To prevent focus from leaving the modal
    // we are setting the last element to be the last tabbable element in the modal
    // which is currently the Digicert image. If another focusable element gets added
    // to the modal footer, we will need to update this code.
    const isFullScreenModal = ref.current.className.includes(
        "modal2__content-wrapper--full-width",
    );
    if (isFullScreenModal) {
        // this element gets added through a DigiCert script without a class or id
        // so we are grabbing it by its alt text
        const digicertImage = document.querySelector(
            'img[alt="DigiCert Secured Site Seal"]',
        );
        lastFocusableElementInModal = digicertImage;
    }

    if (event.shiftKey) {
        // The Shift and Tab keys are pressed at the same time
        if (elementThatHasFocus === firstFocusableElementInModal) {
            lastFocusableElementInModal.focus();
            event.preventDefault();
        }
    } else {
        // The Tab key is pressed without the Shift key
        if (elementThatHasFocus === lastFocusableElementInModal) {
            firstFocusableElementInModal.focus();
            event.preventDefault();
        }
    }
};

/**
 * @public
 * @function isMailingFieldGroupEnabled
 * @description Helper function that determines whether or not the
 * mailing address field group has been toggled on or off
 * @param {object} allFieldsAndGroups object returned from redux
 * store containing all system fields and field groups
 * @returns {bool} true or false
 */
export const isMailingFieldGroupEnabled = (allFieldsAndGroups) => {
    const {
        ENUMS: {
            EntityType: { SYSTEM_FIELD_GROUP_MAILING_ADDRESS },
            Status: { ACTIVE },
        },
    } = constants;

    const mailingAddressFieldGroup =
        allFieldsAndGroups.find(
            (field) =>
                Number(field.type) === SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
        ) || {};

    const mailingAddressFieldGroupStatus = mailingAddressFieldGroup?.status;
    if (!mailingAddressFieldGroupStatus) {
        return false;
    }

    return Number(mailingAddressFieldGroupStatus) === ACTIVE;
};

/**
 * @public
 * @description Returns a flag that indicates whether the component that contains the
 *              Apple Pay and PayPal buttons should be displayed.
 * @function getDisplayDigitalWalletPaymentOptions
 * @param {object} options Object that contains enablePaypalPayments and shouldDisplayApplePay keys.
 * @param {boolean} options.enablePaypalPayments Flag that indicates whether PayPal payments are accepted.
 * @param {boolean} options.shouldDisplayApplePay Flag that indicates whether the Apple Pay button should be displayed.
 * @returns {boolean} Boolean that represents whether or not the component
 *                    that contains the digital wallet payment options should be displayed.
 */
export const getDisplayDigitalWalletPaymentOptions = ({
    enablePaypalPayments,
    shouldDisplayApplePay,
}) => {
    if (shouldDisplayApplePay || enablePaypalPayments) {
        return true;
    }

    return false;
};

/**
 * @public
 * @description Returns a flag that indicates whether the payment tabs
 *              component that contains the credit card and bank UIs
 *              should be displayed.
 * @function getDisplayStandardPaymentMethods
 * @param {object} options Object that contains enableAchPayments, enableCCPayments, isCms and values object.
 * @param {boolean} options.enableAchPayments Flag that indicates whether bank payments are accepted.
 * @param {object} options.enableCCPayments Flag that indicates whether credit card payments are accepted.
 * @param {boolean} options.isCms Flag that indicates whether the user is in CMS.
 * @param {object} options.values Object that contains all of the Formik values.
 * @returns {boolean} Boolean that represents whether or not the component
 *                    that contains the payment tabs should be displayed.
 */
export const getDisplayStandardPaymentMethods = ({
    enableAchPayments,
    enableCCPayments,
    isCms,
    values,
}) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const hasClickedApplePayOutsideOfCMS =
        !isCms && values.Payment_Type === PaymentType.APPLEPAY;
    const hasClickedPayPalOutsideOfCMS =
        !isCms && values.Payment_Type === PaymentType.PAYPAL;

    if (!enableAchPayments && !enableCCPayments) {
        return false;
    }

    if (hasClickedApplePayOutsideOfCMS || hasClickedPayPalOutsideOfCMS) {
        return false;
    }

    return true;
};

/**
 * @public
 * @description Returns a flag that indicates whether the billing address
 *              field group, the donor password and the payment nickname should
 *              be displayed.
 * @function getDisplayAddressPasswordAndNickname
 * @param {object} options Object that contains isCms and values object.
 * @param {boolean} options.isCms Flag that indicates whether the user is in CMS.
 * @param {object} options.values Object that contains all of the Formik values.
 * @returns {boolean} Boolean that represents whether or not the component that
 *                    contains the billing address fields, the password and the
 *                    payment nickname UI components should be displayed.
 */
export const getDisplayAddressPasswordAndNickname = ({ isCms, values }) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const hasClickedApplePayOutsideOfCMS =
        !isCms && values.Payment_Type === PaymentType.APPLEPAY;

    if (hasClickedApplePayOutsideOfCMS) {
        return false;
    }

    return true;
};

/**
 * @public
 * @description Returns a flag that indicates whether the submit button
 *              should be displayed.
 * @function getDisplaySubmit
 * @param {object} options Object that contains isCms and values object.
 * @param {boolean} options.isCms Flag that indicates whether the user is in CMS.
 * @param {object} options.values Object that contains all of the Formik values.
 * @returns {boolean} Boolean that represents whether or not the submit button
 *                    should be displayed.
 */
export const getDisplaySubmit = ({ isCms, values }) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const hasClickedApplePayOutsideOfCMS =
        !isCms && values.Payment_Type === PaymentType.APPLEPAY;

    if (hasClickedApplePayOutsideOfCMS) {
        return false;
    }

    return true;
};

/**
 * @public
 * @function getEventDateAndLocationProps
 * @description Extracts data from a number of different keys in the Redux
 *        store so that they can be passed to the different instances of the
 *        EventDateAndLocation component. This is done as the different
 *        instances of this component require identical data sets with the
 *        exception of the different displayEventDate and displayEventLocation
 *        keys which have different values depending on the instance that is
 *        being viewed.
 * @param {object} eventSettings Event settings object from the Redux store.
 * @param {string} [eventSettings.address] Event street address
 * @param {string} [eventSettings.city] Event city
 * @param {string} [eventSettings.e_date_begin] Start date of event
 * @param {string} [eventSettings.e_date_end] Event end date
 * @param {string} [eventSettings.e_time_begin] Event start time
 * @param {string} [eventSettings.e_time_end] Event end time
 * @param {string} [eventSettings.location] Event venue
 * @param {string} [eventSettings.state] Event state
 * @param {number} [eventSettings.timezone] Event time zone
 * @param {string} [eventSettings.zip] Event zip code
 * @param {object} displayOptions Object that contains display flags from CMS
 * @param {boolean} [displayOptions.displayEventDate] CMS flag to display event date.
 * @param {boolean} [displayOptions.displayEventLocation] CMS flag to display event location.
 * @returns {object} Object that contains all of the data from state that is
 *           shared across every instance of the <EventDateAndLocation/>
 *           component.
 */
export const getEventDateAndLocationProps = (eventSettings, displayOptions) => {
    const {
        ENUMS: { Timezone },
    } = constants;
    // Default values for date and time settings are the values used when the
    // event has start or end dates toggle is set to off. This format matches
    // the format that we use in the redesigned form.
    const {
        address = "",
        city = "",
        e_date_begin = "",
        e_date_end = "",
        e_time_begin = "00:00:00",
        e_time_end = "00:00:00",
        location = "",
        state = "",
        timezone = Timezone.EASTERN,
        zip = "",
        country = "",
    } = eventSettings;
    const { displayEventDate = true, displayEventLocation = true } =
        displayOptions;
    // Use moment to determine whether or not we have valid start and end dates
    // and if the event is a multi-day event
    const eventStartDate = moment(e_date_begin);
    const eventEndDate = moment(e_date_end);
    const hasValidStartAndEndDate =
        eventStartDate.isValid() && eventEndDate.isValid();
    // Store the venue name in a semantic variable as a means of distinguishing
    // it from the location string displayed on the page
    const venueName = location;
    // A falsy date string ensures that the date string will not render if the
    // start and end date are not valid;
    let formattedEventDateString = "";
    let formattedEventLocationString = venueName;
    let zipAsString;
    let addressElements;
    let addressElementsThatArePresent;
    let startDate;
    let endDate;

    // If the start and end dates are valid, use the moment objects to come up
    // with strings that are formatted in a way that the
    // getFormattedDateString function expects
    if (hasValidStartAndEndDate) {
        startDate = `${eventStartDate.format("YYYY-MM-DD")} ${e_time_begin}`;
        endDate = `${eventEndDate.format("YYYY-MM-DD")} ${e_time_end}`;
        formattedEventDateString = getFormattedEventDate({
            startDate,
            endDate,
            timezone,
        });
    }

    // If the venue name is not present, make zip a string as when it is
    // present it is a number, remove any elements that are not present in the
    // address and format it into a comma separated string
    if (!venueName) {
        zipAsString = String(zip);
        // By concatenating state and zip we can ensure there will be no comma once joined below
        // Trimming the space removes the extra space if one or both does not have a value.
        const formattedStateZip = `${state} ${zipAsString}`.trim();
        const countryName = getCountryNameFromShortCode(country);
        addressElements = [address, city, formattedStateZip, countryName];
        addressElementsThatArePresent = addressElements.filter(
            (addressElement) => addressElement.length > 0,
        );
        formattedEventLocationString = addressElementsThatArePresent.join(", ");
    }

    // Display event date and display event location are set to true by default,
    // but gets set to "0" once unchecked and "1" when checked again
    const isDisplayEventDateChecked =
        displayEventDate === true || displayEventDate === "1";
    const isDisplayEventLocationChecked =
        displayEventLocation === true || displayEventLocation === "1";
    const displayFormattedEventDateString = Boolean(
        isDisplayEventDateChecked && formattedEventDateString,
    );
    const displayFormattedEventLocationString = Boolean(
        isDisplayEventLocationChecked && formattedEventLocationString,
    );
    // Only display the line with the event date and location if one of the
    // items are present
    const displayEventDateAndLocation = Boolean(
        displayFormattedEventDateString || displayFormattedEventLocationString,
    );
    // Only display the separator dot if both the event date and venue name
    // strings are going to be displayed
    const displaySeparatorDot = Boolean(
        displayEventDateAndLocation &&
            displayFormattedEventDateString &&
            displayFormattedEventLocationString,
    );

    const eventDateAndLocationProps = {
        displayEventDateAndLocation,
        displayFormattedEventDateString,
        displayFormattedEventLocationString,
        displaySeparatorDot,
        formattedEventDateString,
        formattedEventLocationString,
    };

    return eventDateAndLocationProps;
};

/**
 * @public
 * @description Returns a flag that indicates whether the button that allows
 *              a user to toggle between new and saved payment methods should
 *              be displayed.
 * @function getDisplayTogglePaymentMethodsButton
 * @param {object} options Object that contains hasStoredPaymentMethods, isCms and
 *                  values object.
 * @param {boolean} options.hasStoredPaymentMethods Flag that indicates whether
 *                  the user has saved payment methods associated with their donor
 *                  account.
 * @param {boolean} options.isCms Flag that indicates whether the user is in CMS.
 * @param {object} options.values Object that contains all of the Formik values.
 * @returns {boolean} Boolean that represents whether or not he button that
 *                   allows a user to toggle between new and saved payment
 *                   methods should be displayed.
 */

export const getDisplayTogglePaymentMethodsButton = ({
    hasStoredPaymentMethods,
    isCms,
    values,
}) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const hasClickedApplePayOutsideOfCMS =
        !isCms && values.Payment_Type === PaymentType.APPLEPAY;

    if (
        !hasStoredPaymentMethods ||
        (hasStoredPaymentMethods && hasClickedApplePayOutsideOfCMS)
    ) {
        return false;
    }

    return true;
};

/**
 * @public
 * @function getIsWidget
 * @description Generates a flag that indicates whether a form is being
 *              rendered on an internal landing page or as a widget on an
 *              external domain.
 * @param {null|object} embed Object that contains embed id & url.
 * @returns {boolean} Flag that indicates whether the form is part of a widget.
 */
export const getIsWidget = (embed = {}) => {
    const isWidget = embed && embed.id ? true : false;

    return isWidget;
};
