import React, { useCallback } from "react";
import { constants } from "@qgiv/core-js";
import ConnectedReceiptPage from "../Pages/ReceiptPage";
import ConnectedSinglePage from "../SinglePageForm";
import EventsPage from "../Pages/EventsPage";

/**
 * DonationFormSinglePageSwitch
 * @param {object} props
 * @param {number} props.currentDonationFormPage
 * @returns {React.ReactElement} ConnectedSinglePage | ConnectedReceiptPage
 */
const DonationFormSinglePageSwitch = ({ currentDonationFormPage }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: {
                DONATION_AMOUNTS,
                CONFIRMATION,
                QGIV_EVENTS,
            },
        },
    } = constants;

    const renderPage = useCallback(() => {
        switch (currentDonationFormPage) {
            case DONATION_AMOUNTS:
                return <ConnectedSinglePage />;
            case CONFIRMATION:
                return <ConnectedReceiptPage />;
            case QGIV_EVENTS:
                return <EventsPage />;
            default:
                return <div>I am a default</div>;
        }
    }, [CONFIRMATION, DONATION_AMOUNTS, QGIV_EVENTS, currentDonationFormPage]);

    return renderPage();
};

export default DonationFormSinglePageSwitch;
