import React from "react";

/**
 * @param {object} props
 * @param {string} props.planAmount
 * @param {string} props.planFrequency
 * @returns {React.ReactElement} The rendered GivingPlanAmountWithFrequency component.
 */
const GivingPlanAmountWithFrequency = ({ planAmount, planFrequency = "" }) => (
    <div className="giving-plan-card__amount-with-frequency -type--large">
        <span>
            {planAmount}
            {planFrequency && (
                <span className="-type--regular">/{planFrequency}</span>
            )}
        </span>
    </div>
);

export default GivingPlanAmountWithFrequency;
