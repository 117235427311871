import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
    useAsyncSessionStorageData,
    getSessionStorageKey,
    alertSessionStorageUsed,
} from "@qgiv/core-donor";

import { constants } from "@qgiv/core-js";
import { setCurrentPage } from "../redux/slices/appSettingsSlice";

const UpdateFormDataFromSessionStorage = ({
    id,
    replaceDonationDetailsState,
    template,
    isSinglePageForm,
}) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS, PAYMENT_DETAILS },
        },
    } = constants;

    const dispatch = useDispatch();

    const sessionStorageKey = getSessionStorageKey(id);
    const parsedFormDataObject = useAsyncSessionStorageData(sessionStorageKey);
    const checkoutPage = isSinglePageForm ? DONATION_AMOUNTS : PAYMENT_DETAILS;

    useEffect(
        () => {
            if (parsedFormDataObject) {
                // 1.let BE know that we loaded form data from session storage
                alertSessionStorageUsed(id, template);

                // gather data to replace state with
                const newDonationDetails = parsedFormDataObject;
                // replace state
                setTimeout(() => {
                    replaceDonationDetailsState(
                        sessionStorageKey,
                        newDonationDetails,
                    );
                    // move to the payment page
                    dispatch(setCurrentPage(checkoutPage));
                }, 100);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [parsedFormDataObject],
    );

    return null;
};

UpdateFormDataFromSessionStorage.propTypes = {
    id: PropTypes.number.isRequired,
    replaceDonationDetailsState: PropTypes.func.isRequired,
    template: PropTypes.number.isRequired,
};

export default UpdateFormDataFromSessionStorage;
