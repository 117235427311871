import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectIsCms } from "../../../redux/slices/configSettingsSlice";
import ConfirmationFieldsCmsMessage from "../../Pages/ReceiptPage/ConfirmationFields/ConfirmationFieldsCmsMessage";
import ConnectedConfirmationFields from "../../Pages/ReceiptPage/ConfirmationFields/ConnectedConfirmationFields";

const ConfirmationFieldsControl = ({ controlId }) => {
    const isCms = useSelector(selectIsCms);

    // Because these controls contain data that has been copied over from
    // controls that actually live on the donor details and the additional
    // details steps, we render some text in CMS that explains to the org
    // that they are viewing a special control that does  not have any
    // settings when they are viewing this control in CMS.
    return isCms ? (
        <ConfirmationFieldsCmsMessage />
    ) : (
        <ConnectedConfirmationFields controlId={controlId} />
    );
};

ConfirmationFieldsControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConfirmationFieldsControl;
