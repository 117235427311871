import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Checkbox } from "../Checkbox";
import DatePickerField from "../DatePickerField";
import { QMaskField, validQMaskTypes } from "../QMaskField";
import "./FieldInput.scss";

const FieldInput = ({
    ariaLabel,
    ariaLabelledBy,
    ariaRequired = "false",
    autoComplete,
    disableInlineLabel,
    id,
    name,
    type,
    maxChar,
    multiline = false,
    value,
    handleBlur,
    handleChange,
    handleFocus,
    inputOffset,
    inputRef,
    error = false,
    config,
    readOnly,
    ...props
}) => {
    const isQMask = validQMaskTypes && validQMaskTypes.includes(type);
    const fieldInputClassNames = cx(
        "qg-vendor-field-input",
        "field-input",
        multiline && !isQMask && "field-input--multiline",
        error && "field-input--error",
    );

    const style = { paddingRight: `${inputOffset}px` };

    const inputProps = {
        autoComplete,
        id,
        name,
        value,
        className: cx(
            "field-input__input",
            disableInlineLabel && "field-input__input--disable-inline-label",
        ),
        onFocus: handleFocus,
        onBlur: handleBlur,
        onChange: handleChange,
        ref: inputRef,
        ...(props?.country && { country: props.country }),
        ...(ariaLabelledBy && { "aria-labelledby": ariaLabelledBy }),
    };

    if (error) {
        const keyString = isQMask ? "ariaInvalid" : "aria-invalid";
        inputProps[keyString] = "true";
    }

    // Only apply offset style if offset has a valid value
    if (inputOffset > 0) {
        inputProps.style = style;
    }

    // P2P customFields can be of type "A", "B", "C" and "D" rather than "text", "select", etc.
    // If that's the case and we're dealing with a p2p custom field we need to have its
    // type "translated" so our switch statement below can return the correct elements
    const translateFieldType = (type) => {
        const fieldTypes = {
            A: "text",
            B: "text",
            C: "checkbox",
            D: "select",
        };
        return fieldTypes[type] || type;
    };
    const translatedFieldType = translateFieldType(type);

    // enforce input maxLength if maxChar was passed in
    const maxLength = parseInt(maxChar) || "";
    // Any aria attribute should be camelCased when being passed to another React
    // component but kebab-cased when being passed directly to an HTML element:
    // https://reactjs.org/docs/dom-elements.html
    const getInput = () => {
        if (isQMask) {
            return (
                <QMaskField
                    ariaRequired={ariaRequired}
                    ariaLabel={ariaLabel}
                    type={type}
                    {...inputProps}
                    config={config}
                />
            );
        }
        switch (translatedFieldType) {
            case "dateMDY": {
                return <DatePickerField inputProps={inputProps} />;
            }
            case "text":
                if (multiline) {
                    return (
                        <textarea
                            aria-label={ariaLabel}
                            aria-required={ariaRequired}
                            maxLength={maxLength}
                            {...inputProps}
                        />
                    );
                }
                return (
                    <input
                        type={type}
                        aria-label={ariaLabel}
                        aria-required={ariaRequired}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        {...inputProps}
                    />
                );
            case "select":
                return (
                    <select
                        aria-label={ariaLabel}
                        aria-required={ariaRequired}
                        {...inputProps}
                    >
                        {props.children}
                    </select>
                );
            case "checkbox":
                return (
                    <Checkbox
                        ariaLabel={ariaLabel}
                        ariaRequired={ariaRequired}
                        {...inputProps}
                    >
                        {props.children}
                    </Checkbox>
                );
            default:
                return (
                    <input
                        aria-required={ariaRequired}
                        aria-label={ariaLabel}
                        type={type}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        {...inputProps}
                    />
                );
        }
    };

    const containerProps = {
        className: fieldInputClassNames,
    };

    return <div {...containerProps}>{getInput()}</div>;
};

FieldInput.propTypes = {
    ariaRequired: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    autoComplete: PropTypes.string,
    disableInlineLabel: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    maxChar: PropTypes.string,
    multiline: PropTypes.bool,
    value: PropTypes.string.isRequired,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleFocus: PropTypes.func,
    inputOffset: PropTypes.number,
    inputRef: PropTypes.shape({ current: PropTypes.any }),
    children: PropTypes.any,
    error: PropTypes.bool,
    config: PropTypes.object,
    readOnly: PropTypes.bool,
};

export default FieldInput;
