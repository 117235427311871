import React from "react";
import PropTypes from "prop-types";
import ConnectedDonationMessage from "../../Pages/GiftPage/DonatedGiftSection/DonationMessage";

const DonationMessageControl = ({ controlId }) => (
    <ConnectedDonationMessage controlId={controlId} />
);

DonationMessageControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default DonationMessageControl;
