import React from "react";
import PropTypes from "prop-types";
import { Field } from "../Field";

const TextField = (props) => {
    const {
        ariaRequired = "false",
        ariaLabel,
        autoComplete,
        autoFocus,
        type = "text",
        id,
        maxChar,
        name,
        label,
        helpText,
        multiline,
        value,
        descText,
        descIcon,
        handleBlur,
        handleChange,
        handleClick,
        handleFocus,
        innerBackground = false,
        error,
        errorText,
        config,
        readOnly = false,
    } = props;
    // For setting acceptable dates for End_Date Field
    const selectedStartDate =
        name === "End_Date" ? props.selectedStartDate : "";

    return (
        <Field
            ariaRequired={ariaRequired}
            ariaLabel={ariaLabel}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            type={type}
            id={id ? id : name}
            name={name}
            label={label}
            helpText={helpText}
            innerBackground={innerBackground}
            descText={descText}
            descIcon={descIcon}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleClick={handleClick}
            handleFocus={handleFocus}
            maxChar={maxChar}
            multiline={multiline}
            value={value}
            cursor="text"
            error={error}
            errorText={errorText}
            config={config}
            readOnly={readOnly}
            selectedStartDate={selectedStartDate}
            country={props?.country ? props.country : ""}
        />
    );
};

TextField.propTypes = {
    ariaRequired: PropTypes.string,
    ariaLabel: PropTypes.string,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    // We can pass labels as elements or strings
    label: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleClick: PropTypes.func,
    handleFocus: PropTypes.func,
    helpText: PropTypes.string,
    innerBackground: PropTypes.bool,
    descText: PropTypes.string,
    descIcon: PropTypes.shape({
        glyph: PropTypes.string,
        type: PropTypes.string,
    }),
    maxChar: PropTypes.string,
    multiline: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    config: PropTypes.object,
    selectedStartDate: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default TextField;
