import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setErrors,
    selectErrors,
} from "../../../redux/slices/appSettingsSlice";
import ErrorDisplay from "./ErrorDisplay";

const ConnectedErrorDisplay = () => {
    const errors = useSelector(selectErrors);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setErrors([]));
    };

    const errorProps = {
        errors,
        handleClose,
    };

    return <ErrorDisplay {...errorProps} />;
};

export default ConnectedErrorDisplay;
