import { calcGiftAssistFee } from "@qgiv/core-donor";
import { constants } from "@qgiv/core-js";
import { getRestrictionName } from "../../standardRestrictionsHelpers";
import {
    getHasDisplayableSubRestrictions,
    getInitialSubRestrictionValue,
    getSelectedSubRestrictionFromList,
    getSubRestrictionsList,
} from "../../subRestrictionHelpers";
import {
    getAllPropsToDetermineTypeOfUrlShortcutsToRender,
    getCustomAndOtherProps,
    getShouldBuildHasSelectedRecurringDonationInitialValues,
    getShouldBuildRecurringFrequencyInitialValues,
    getShouldRenderStandardRestriction,
} from "../../urlShortcutsHelpers";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.UrlShortcuts.UrlShortcuts} UrlShortcuts
 */

/**
 * @public
 * @function getStandardRestrictionInitialValues
 * @param {object} settings all settings needed for initial values
 * @param {number} settings.currentDisplay form is displaying on Desktop, Mobile, Text-to-Donate
 * @param {Array} settings.restrictions active restrictions
 * @param {object} settings.urlShortcuts object from initialState of url params
 * @description takes in conditions and returns the values for restrictions that should be set initially
 * @returns {object} Return Formik/Redux values to be set initially
 */
export const getStandardRestrictionInitialValues = ({
    currentDisplay,
    restrictions,
    urlShortcuts,
}) => {
    const restrictionId = urlShortcuts?.restriction || "";
    const hasDisplayableSubRestrictions = getHasDisplayableSubRestrictions(
        restrictions,
        currentDisplay,
    );
    const subRestrictionsList = getSubRestrictionsList(
        restrictions,
        restrictionId,
    );
    const selectedSubRestrictionFormList = getSelectedSubRestrictionFromList(
        subRestrictionsList,
        urlShortcuts?.subRestriction,
    );
    const initialSubRestrictionValue = getInitialSubRestrictionValue(
        restrictions,
        restrictionId,
    );

    const restrictionName =
        getRestrictionName(restrictions, urlShortcuts?.restriction) || "";
    const subRestrictionName = urlShortcuts?.subRestriction
        ? selectedSubRestrictionFormList?.name
        : initialSubRestrictionValue;

    const standardRestrictionInitialValues = {
        Standard_Restriction_Id: restrictionId,
        Standard_Restriction_Name: restrictionName,
        ...(hasDisplayableSubRestrictions && {
            Standard_Restriction_Sub_Restriction: subRestrictionName,
        }),
    };

    return standardRestrictionInitialValues;
};

/**
 * @public
 * @function getStackedOneTimeInitialValues
 * @param {object} settings all settings needed for initial values
 * @param {object} settings.customAmountObject custom amount found by id
 * @param {boolean} settings.donationActive  onetime gifts are enabled
 * @param {boolean} settings.hasCustomAmountShortcut urlShortcut included for custom amount
 * @param {boolean} settings.hasOtherAmountShortcut urlShortcut included for other amount
 * @param {string} settings.otherAmountId id for other amount
 * @param {object} settings.otherAmountObject other amount found by id
 * @param {object} settings.urlShortcuts object from initialState of url params
 * @description takes in conditions and returns the values for onetime that should be set initially
 * @returns {object} Return Formik/Redux values to be set initially
 */
export const getStackedOneTimeInitialValues = ({
    customAmountObject,
    donationActive,
    hasCustomAmountShortcut,
    hasOtherAmountShortcut,
    otherAmountId,
    otherAmountObject,
    urlShortcuts,
}) => {
    const { isOneTimeEnabledForCustomValue, isOneTimeEnabledForOtherAmount } =
        getCustomAndOtherProps({ customAmountObject, otherAmountObject });

    const customAmountIdFromUrl = customAmountObject?.id || urlShortcuts.amount;

    const oneTimeShortcutInitialValue = donationActive
        ? {
              ...(hasCustomAmountShortcut &&
                  isOneTimeEnabledForCustomValue && {
                      Selected_One_Time_Id: customAmountIdFromUrl,
                      hasSelectedRecurringDonation: false,
                  }),

              ...(hasOtherAmountShortcut &&
                  isOneTimeEnabledForOtherAmount && {
                      Selected_One_Time_Id: otherAmountId,
                      Other_One_Time_Amount: parseFloat(
                          urlShortcuts.otherAmount,
                      ).toFixed(2),
                      hasSelectedRecurringDonation: false,
                  }),
          }
        : {};

    return oneTimeShortcutInitialValue;
};

/**
 * @public
 * @function getRecurringShortcutInitialValue
 * @param {object} settings all settings needed for initial values
 * @param {object} settings.customAmountObject custom amount found by id
 * @param {boolean} settings.enableRecur  recurring gifts are enabled
 * @param {boolean} settings.hasCustomAmountShortcut urlShortcut included for custom amount
 * @param {boolean} settings.hasFrequencyShortcut urlShortcut included for frequency
 * @param {boolean} settings.hasOtherAmountShortcut urlShortcut included for other amount
 * @param {string} settings.otherAmountId id for other amount
 * @param {object} settings.otherAmountObject other amount found by id
 * @param {object} settings.urlShortcuts object from initialState of url params
 * @description takes in conditions and returns the values for recurring that should be set initially
 * @returns {object} Return Formik/Redux values to be set initially
 */
export const getStackedRecurringInitialValues = ({
    customAmountObject,
    enableRecur,
    hasCustomAmountShortcut,
    hasFrequencyShortcut,
    hasOtherAmountShortcut,
    otherAmountId,
    otherAmountObject,
    urlShortcuts,
}) => {
    const {
        isRecurringEnabledForCustomValue,
        isRecurringEnabledForOtherAmount,
    } = getCustomAndOtherProps({ customAmountObject, otherAmountObject });

    const customAmountIdFromUrl = customAmountObject?.id || urlShortcuts.amount;

    const recurringShortcutInitialValue = enableRecur
        ? {
              ...(hasCustomAmountShortcut &&
                  isRecurringEnabledForCustomValue && {
                      Selected_Recurring_Id: customAmountIdFromUrl,
                  }),
              ...((urlShortcuts?.recurring || hasFrequencyShortcut) && {
                  hasSelectedRecurringDonation: true,
              }),
              ...(hasOtherAmountShortcut &&
                  isRecurringEnabledForOtherAmount && {
                      Selected_Recurring_Id: otherAmountId,
                      Other_Recurring_Amount: parseFloat(
                          urlShortcuts.otherAmount,
                      ).toFixed(2),
                  }),
              ...(hasFrequencyShortcut && {
                  Recurring_Frequency: urlShortcuts.frequency,
              }),
          }
        : {};

    return recurringShortcutInitialValue;
};

/**
 * @public
 * @function getGivingPlanInitialValues
 * @param {object} settings all settings needed for initial values
 * @param {object} settings.urlShortcuts object from initialState of url params
 * @param {Array} settings.activePlans array containing enabled plans
 * @param {number} settings.givingPlanSystemType number representing the current giving plan system type
 * @description takes in conditions and returns the values for giving plans that should be set initially
 * @returns {object} Return Formik/Redux values to be set initially
 */
const getGivingPlanInitialValues = ({
    urlShortcuts,
    activePlans,
    givingPlanSystemType,
}) => {
    const {
        ENUMS: { GivingPlanSystemType },
    } = constants;
    const providedPlan =
        activePlans.find((plan) => plan.id === Number(urlShortcuts.pledge)) ||
        {};

    if (Object.keys(providedPlan).length === 0) {
        return {};
    }
    const shouldAssignRestriction =
        Object.keys(providedPlan).length > 0 &&
        providedPlan.restriction.length > 0;

    const shouldBuildFlexiblePreselectData =
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    const givingPlanInitialValue =
        !!urlShortcuts.pledge && Object.keys(providedPlan).length > 0
            ? {
                  hasSelectedRecurringDonation: true,
                  Selected_Plan: urlShortcuts.pledge,
                  Recurring_Frequency: shouldBuildFlexiblePreselectData
                      ? providedPlan.defaultFrequencyEnum
                      : providedPlan.frequencyEnum,
                  Start_Date: providedPlan.begin_date,
                  End_Date: providedPlan.end_date,
                  ...(shouldBuildFlexiblePreselectData && {
                      Installment_Count:
                          providedPlan.defaultNumberOfInstallments,
                  }),
                  ...(shouldAssignRestriction && {
                      Standard_Restriction: providedPlan.restriction,
                      Standard_Restriction_Id: providedPlan.restrictionId,
                      Standard_Restriction_Sub_Restriction:
                          providedPlan.subrestriction,
                  }),
              }
            : {};

    return givingPlanInitialValue;
};

/**
 * @public
 * @function getAmountOnlyInitialValues
 * @param {object} settings all settings needed for initial values
 * @param {object} settings.customAmountObject custom amount found by id
 * @param {boolean} settings.donationActive  onetime gifts are enabled
 * @param {boolean} settings.enableRecur  recurring gifts are enabled
 * @param {boolean} settings.hasCustomAmountShortcut urlShortcut included for custom amount
 * @param {boolean} settings.hasOtherAmountShortcut urlShortcut included for other amount
 * @param {string} settings.otherAmountId id for other amount
 * @param {object} settings.otherAmountObject other amount found by id
 * @param {object} settings.urlShortcuts object from initialState of url params
 * @description takes in conditions and returns the values for amount only that should be set initially
 * @returns {object} Return Formik/Redux values to be set initially
 */
export const getAmountOnlyInitialValues = ({
    customAmountObject,
    donationActive,
    enableRecur,
    hasCustomAmountShortcut,
    hasOtherAmountShortcut,
    otherAmountId,
    otherAmountObject,
    urlShortcuts,
}) => {
    const {
        isOneTimeEnabledForOtherAmount,
        isOneTimeEnabledForCustomValue,
        isRecurringEnabledForCustomValue,
        isRecurringEnabledForOtherAmount,
    } = getCustomAndOtherProps({ customAmountObject, otherAmountObject });

    const customAmountIdFromUrl = customAmountObject?.id || urlShortcuts.amount;

    const amountOnlyInitialValues = {
        ...(hasCustomAmountShortcut && {
            ...(donationActive &&
                isOneTimeEnabledForCustomValue && {
                    Selected_One_Time_Id: customAmountIdFromUrl,
                }),
            ...(enableRecur &&
                isRecurringEnabledForCustomValue && {
                    Selected_Recurring_Id: customAmountIdFromUrl,
                }),
        }),
        ...(hasOtherAmountShortcut && {
            ...(donationActive &&
                isOneTimeEnabledForOtherAmount && {
                    Selected_One_Time_Id: otherAmountId,
                    Other_One_Time_Amount: parseFloat(
                        urlShortcuts.otherAmount,
                    ).toFixed(2),
                }),
            ...(enableRecur &&
                isRecurringEnabledForOtherAmount && {
                    Selected_Recurring_Id: otherAmountId,
                    Other_Recurring_Amount: parseFloat(
                        urlShortcuts.otherAmount,
                    ).toFixed(2),
                }),
        }),
    };

    return amountOnlyInitialValues;
};

/**
 *
 * @param {object} params
 * @param {Array} params.activeAmounts
 * @param {boolean} params.donationActive
 * @param {boolean} params.enableRecur
 * @param {object} params.urlShortcuts
 * @returns {object}
 */
export const getAmountValueInitialValues = ({
    activeAmounts,
    donationActive,
    enableRecur,
    urlShortcuts,
}) => {
    const {
        ENUMS: { ItemType },
    } = constants;
    const { amountValue, recurring, frequency } = urlShortcuts;
    const customAmountThatMatchesAmountValue =
        activeAmounts.find(
            (activeAmount) => activeAmount.amount === amountValue,
        ) || {};
    const hasCustomAmountThatMatchedAmountValue =
        Object.keys(customAmountThatMatchesAmountValue).length > 0;

    const otherAmount =
        activeAmounts.find(
            (activeAmount) => activeAmount.amountType === ItemType.OTHER,
        ) || {};

    const hasOtherAmount = Object.keys(otherAmount).length > 0;

    if (!hasCustomAmountThatMatchedAmountValue && !hasOtherAmount) {
        // there is nothing we can do here
        return {};
    }

    // overflow behavior
    const customAmountDisplayForOneTime =
        customAmountThatMatchesAmountValue?.displayForOneTime || false;
    const customAmountDisplayForRecurring =
        customAmountThatMatchesAmountValue?.displayForRecurring || false;
    const otherAmountDisplayForOneTime =
        otherAmount?.displayForOneTime || false;
    const otherAmountDisplayForRecurring =
        otherAmount?.displayForRecurring || false;

    const shouldUseCustomAmountOneTime =
        donationActive && !recurring && customAmountDisplayForOneTime;
    const shouldUseCustomAmountRecurring =
        enableRecur && recurring && customAmountDisplayForRecurring;
    const shouldUseOtherAmountOneTime =
        donationActive &&
        !recurring &&
        !customAmountDisplayForOneTime &&
        otherAmountDisplayForOneTime;
    const shouldUseOtherAmountRecurring =
        enableRecur &&
        recurring &&
        !customAmountDisplayForRecurring &&
        otherAmountDisplayForRecurring;

    if (shouldUseCustomAmountOneTime) {
        return {
            Selected_One_Time_Id: customAmountThatMatchesAmountValue.id,
            hasSelectedRecurringDonation: false,
        };
    }
    if (shouldUseCustomAmountRecurring) {
        return {
            Selected_Recurring_Id: customAmountThatMatchesAmountValue.id,
            hasSelectedRecurringDonation: true,
            // do we need to conditionally add freq?
            Recurring_Frequency: frequency,
        };
    }
    // other amounts are going to have to trigger focus blur, yay
    if (shouldUseOtherAmountOneTime) {
        return {
            Selected_One_Time_Id: otherAmount.id,
            Other_One_Time_Amount: `${amountValue}`,
            hasSelectedRecurringDonation: false,
        };
    }
    if (shouldUseOtherAmountRecurring) {
        return {
            Selected_Recurring_Id: otherAmount.id,
            Other_Recurring_Amount: `${amountValue}`,
            hasSelectedRecurringDonation: true,
            Recurring_Frequency: frequency,
        };
    }

    return {};
};

/**
 *
 * @param {object} settings
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {object}
 */
export const getHasSelectedRecurringDonationInitialValues = ({
    urlShortcuts,
}) => {
    const hasPreselectedRecurringDonation = urlShortcuts.recurring;
    const hasSelectedRecurringDonation = !!hasPreselectedRecurringDonation;

    const hasSelectedRecurringDonationInitialValues = {
        hasSelectedRecurringDonation,
    };

    return hasSelectedRecurringDonationInitialValues;
};

/**
 *
 * @param {object} settings
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {object}
 */
export const getRecurringFrequencyInitialValues = ({ urlShortcuts }) => {
    const recurringFrequencyInitialValues = {
        Recurring_Frequency: urlShortcuts.frequency,
    };

    return recurringFrequencyInitialValues;
};

// name collision with getGiftAssistInitialValue
export const getGiftAssistInitialValueFromUrlShortcut = (settings) => {
    const { formSettings, urlShortcuts } = settings;
    const { feeCoverage } = formSettings;
    if (Number(feeCoverage) > 0 && urlShortcuts?.giftAssist) {
        return {
            Gift_Assist: true,
        };
    }
    return {};
};

/**
 * @public
 * @function getTotalsWithUrlShortcutsInitialValues
 * @param {object} settings all settings needed for initial values
 * @param {object} values previously set initial values from the urlShortcuts
 * @description Determines starting value of total, subtotal and giftAssistAmount
 * @returns {object} total, subtotal and giftAssistAmount initial value
 */
export const getTotalsWithUrlShortcutsInitialValues = (
    settings,
    values = {},
) => {
    const {
        ENUMS: { ItemType },
    } = constants;
    const {
        formSettings: {
            feeCoverage,
            feeCoverageFlat,
            feeCoveragePercentage,
            enableGiftAssistByDefault,
        },
        donationSettings: { activeAmounts, activePlans },
        urlShortcuts,
    } = settings;

    let subtotal = 0.0;
    let giftAssistAmount = 0.0;

    // Since this is being run after all the other urlShortcut values are
    // evaluated and returned, we can look at the current values that are
    // generated and create totals based on them.
    const {
        Selected_Plan = "",
        Selected_One_Time_Id = 0,
        Selected_Recurring_Id = 0,
        Other_One_Time_Amount = 0,
        Other_Recurring_Amount = 0,
        hasSelectedRecurringDonation,
    } = values;

    // Amount flag
    const idOfAmountToEvaluate = hasSelectedRecurringDonation
        ? Selected_Recurring_Id
        : Selected_One_Time_Id;
    const amountToEvaluate =
        activeAmounts.find(
            (activeAmount) => activeAmount.id === idOfAmountToEvaluate,
        ) || {};
    const hasAmountToEvaluate = Object.keys(amountToEvaluate).length > 0;

    // Giving Plans
    const idOfPlanToEvaluate = Number(Selected_Plan);
    const planToEvaluate =
        activePlans.find((plan) => plan.id === idOfPlanToEvaluate) || {};
    const hasPlanToEvaluate = Object.keys(planToEvaluate).length > 0;

    // Gift Assist flag
    const shouldCalculateGiftAssist =
        enableGiftAssistByDefault || urlShortcuts?.giftAssist;

    // Calculate subtotal
    if (hasAmountToEvaluate) {
        const amountToEvaluateIsCustomAmount =
            amountToEvaluate.amountType === ItemType.CUSTOM;

        if (amountToEvaluateIsCustomAmount) {
            subtotal = amountToEvaluate.amount;
        } else {
            subtotal = hasSelectedRecurringDonation
                ? Number(Other_Recurring_Amount)
                : Number(Other_One_Time_Amount);
        }
    } else if (hasPlanToEvaluate) {
        subtotal = Number(planToEvaluate.amount);
    }

    // Calculate gift assist amount
    if (subtotal > 0 && shouldCalculateGiftAssist && Number(feeCoverage) > 0) {
        giftAssistAmount = Number(
            calcGiftAssistFee(subtotal, {
                feeCoverage,
                feeCoverageFlat,
                feeCoveragePercentage,
            }),
        );
    }

    const total = subtotal + giftAssistAmount;

    return {
        giftAssistAmount,
        subtotal,
        total,
    };
};

/**
 * @public
 * @function getUrlShortcutsInitialValues
 * @param {object} settings all settings needed for initial values
 * @description Determines starting values based on url shortcuts
 * @returns {object} Returns all initial values set by urlShortcuts that should over ride Preselect values
 */
export const getUrlShortcutsInitialValues = (settings) => {
    const {
        formSettings: { enableRecur, donationActive },
        donationSettings: {
            activeAmounts,
            activeDonationFrequencies,
            restrictionGivingType,
            pledgeActive,
            activePlans,
            givingPlanSystemType,
        },
        urlShortcuts,
        restrictions,
        hasActiveFulfillment,
        currentDisplay,
    } = settings;

    const {
        customAmountObject,
        hasAmountOnly,
        hasAmountValueShortcut,
        hasCustomAmountShortcut,
        hasFrequencyShortcut,
        hasGivingPlanShortcut,
        hasOneTimeShortcuts,
        hasOtherAmountShortcut,
        hasRecurringShortcuts,
        otherAmountId,
        otherAmountObject,
    } = getAllPropsToDetermineTypeOfUrlShortcutsToRender({
        activeAmounts,
        urlShortcuts,
    });

    const {
        isOneTimeEnabledForCustomValue,
        isOneTimeEnabledForOtherAmount,
        isRecurringEnabledForCustomValue,
        isRecurringEnabledForOtherAmount,
    } = getCustomAndOtherProps({ customAmountObject, otherAmountObject });

    // -------------------------------------------------------------------------
    // MARK: Render conditions
    // -------------------------------------------------------------------------
    const shouldRenderRecurringAmount =
        (hasAmountOnly || hasRecurringShortcuts || hasAmountValueShortcut) &&
        (isRecurringEnabledForCustomValue || isRecurringEnabledForOtherAmount);

    const shouldRenderOneTimeAmount =
        (hasAmountOnly || hasOneTimeShortcuts || hasAmountValueShortcut) &&
        (isOneTimeEnabledForCustomValue || isOneTimeEnabledForOtherAmount);

    // Flags that are used to determine whether the URL shortcut logic should run
    const hasValidAmountBasedUrlShortcut =
        hasAmountOnly ||
        hasAmountValueShortcut ||
        hasFrequencyShortcut ||
        hasOneTimeShortcuts ||
        hasRecurringShortcuts;

    const hasValidAmountToPlaceUrlShortcut =
        hasAmountValueShortcut ||
        shouldRenderOneTimeAmount ||
        shouldRenderRecurringAmount;

    const shouldBuildGivingPlanInitialValues =
        pledgeActive && activePlans?.length > 0 && hasGivingPlanShortcut;

    const shouldBuildHasSelectedRecurringDonationInitialValues =
        getShouldBuildHasSelectedRecurringDonationInitialValues({
            activeAmounts,
            activePlans,
            donationActive,
            enableRecur,
            pledgeActive,
            urlShortcuts,
        });

    const shouldBuildRecurringFrequencyInitialValues =
        getShouldBuildRecurringFrequencyInitialValues({
            activeAmounts,
            activeDonationFrequencies,
            activePlans,
            enableRecur,
            pledgeActive,
            urlShortcuts,
        });

    const shouldBuildStandardRestrictionInitialValues =
        getShouldRenderStandardRestriction({
            currentDisplay,
            hasActiveFulfillment,
            restrictionGivingType,
            restrictions,
            urlShortcuts,
        });

    // Flag that actually determines whether the URL shortcut logic runs
    const shouldRenderUrlShortcutsInitialValues =
        (hasValidAmountBasedUrlShortcut && hasValidAmountToPlaceUrlShortcut) ||
        shouldBuildGivingPlanInitialValues ||
        shouldBuildHasSelectedRecurringDonationInitialValues ||
        shouldBuildRecurringFrequencyInitialValues ||
        shouldBuildStandardRestrictionInitialValues;

    // Exit early if no gift values in url
    if (!shouldRenderUrlShortcutsInitialValues) return {};

    // Prevent other shortcuts from colliding with amountValue shortcut
    const shouldBuildStackedOneTimeInitialValues =
        hasOneTimeShortcuts && donationActive && !hasAmountValueShortcut;

    const shouldBuildStackedRecurringInitialValues =
        hasRecurringShortcuts && enableRecur && !hasAmountValueShortcut;

    const shouldBuildAmountOnlyInitialValues =
        hasAmountOnly && !hasAmountValueShortcut;

    const shouldBuildAmountValueInitialValues = hasAmountValueShortcut;

    // -------------------------------------------------------------------------
    // MARK: Build initial values
    // -------------------------------------------------------------------------
    const values = {
        ...(shouldBuildStackedOneTimeInitialValues && {
            ...getStackedOneTimeInitialValues({
                donationActive,
                hasCustomAmountShortcut,
                hasOtherAmountShortcut,
                customAmountObject,
                otherAmountObject,
                otherAmountId,
                urlShortcuts,
            }),
        }),
        ...(shouldBuildStackedRecurringInitialValues && {
            ...getStackedRecurringInitialValues({
                enableRecur,
                hasCustomAmountShortcut,
                hasFrequencyShortcut,
                hasOtherAmountShortcut,
                customAmountObject,
                otherAmountObject,
                otherAmountId,
                urlShortcuts,
            }),
        }),
        ...(shouldBuildAmountOnlyInitialValues && {
            ...getAmountOnlyInitialValues({
                donationActive,
                enableRecur,
                hasCustomAmountShortcut,
                hasOtherAmountShortcut,
                customAmountObject,
                otherAmountObject,
                otherAmountId,
                urlShortcuts,
            }),
        }),
        ...(shouldBuildAmountValueInitialValues && {
            ...getAmountValueInitialValues({
                activeAmounts,
                donationActive,
                enableRecur,
                urlShortcuts,
            }),
        }),
        ...(shouldBuildHasSelectedRecurringDonationInitialValues && {
            ...getHasSelectedRecurringDonationInitialValues({ urlShortcuts }),
        }),
        ...(shouldBuildRecurringFrequencyInitialValues && {
            ...getRecurringFrequencyInitialValues({ urlShortcuts }),
        }),
        ...(shouldBuildStandardRestrictionInitialValues && {
            ...getStandardRestrictionInitialValues({
                currentDisplay,
                restrictions,
                urlShortcuts,
            }),
        }),
        ...(shouldBuildGivingPlanInitialValues && {
            ...getGivingPlanInitialValues({
                urlShortcuts,
                activePlans,
                givingPlanSystemType,
            }),
        }),
        ...getGiftAssistInitialValueFromUrlShortcut(settings),
    };

    const valuesWithTotals = {
        ...values,
        ...getTotalsWithUrlShortcutsInitialValues(settings, values),
    };

    return valuesWithTotals;
};
