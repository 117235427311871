import React from "react";
import PropTypes from "prop-types";
import ConnectedSubmit from "../../Pages/GiftPage/Submit";
import ConnectedExpressCheckout from "../../Pages/GiftPage/ExpressCheckout/ConnectedExpressCheckout";

const DonationAmountsPageFooterControl = ({
    controlId,
    displayExpressCheckout,
}) => (
    <>
        {displayExpressCheckout && <ConnectedExpressCheckout />}
        <ConnectedSubmit controlId={controlId} />
    </>
);

DonationAmountsPageFooterControl.propTypes = {
    controlId: PropTypes.number.isRequired,
    displayExpressCheckout: PropTypes.bool.isRequired,
};

export default DonationAmountsPageFooterControl;
