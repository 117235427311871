import React from "react";

import InputBoxList from "../InputBox";

const RadioGroup = (props) => {
    return <InputBoxList {...props}>{props.children}</InputBoxList>;
};

RadioGroup.propTypes = InputBoxList.propTypes;

export default RadioGroup;
