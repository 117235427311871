// -------------------------------------------------------------------------
// MARK: Default Data for Receipt page
// Needed to set default data for the receipts page to be able to process
// information for display in CMS.
// -------------------------------------------------------------------------
export const DEFAULT_RECEIPT_ID = "defaultReceiptId1234";

const receiptDefaultValues = {
    transaction: {
        address: "123 Testing Road",
        anonymous: "n",
        assocInfo: "",
        bill_address: "123 Testing Road",
        bill_city: "Testing City",
        bill_country: "US",
        bill_state: "FL",
        bill_zip: "33809-5034",
        ccLastFour: 1111,
        ccName: "John Doe",
        ccType: "VS",
        city: "Testing City",
        company: "",
        country: "US",
        creatingTransactionFor: 0, // no idea what this means
        date: "2021-04-26",
        demo: "y",
        donations: [
            {
                message: "example donation message",
                entityName: "Recipient's Name",
            },
        ],
        email: "john_doe@test.com",
        fname: "John",
        fields: [],
        form: 10417,
        id: DEFAULT_RECEIPT_ID,
        lname: "Doe",
        optin: "",
        org: 18419,
        originalTransaction: {
            total: 55,
            donationAmount: 50,
            giftAssistAmount: 5,
        },
        phone: 1234567890,
        receiptId: DEFAULT_RECEIPT_ID,
        recurring: "",
        salutation: "",
        source: "don_form",
        state: "Florida",
        status: "A",
        tm_stamp: "2021-04-26 10:10:15",
        zip: "33809-5304",
    },
    total: 100,
};

export default receiptDefaultValues;
