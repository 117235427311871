import React, { forwardRef, memo, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "../../Button/Button.scss";
import "./InputButton.scss";

const InputButton = memo(
    forwardRef(
        (
            {
                type = "checkbox",
                checked = false,
                id,
                name,
                value,
                inputText = "",
                handleChange,
                handleFocus = () => {},
                handleBlur = () => {},
                children,
                buttonStyle = "standard",
                ...props
            },
            ref,
        ) => {
            const [active, setActive] = useState(false);
            const _handleFocus = (e) => {
                setActive(true);
                handleFocus(e);
            };
            const _handleBlur = (e) => {
                setActive(false);
                handleBlur(e);
            };

            return (
                <label className="input-button">
                    <div
                        className={cx(
                            `qg-vendor-button--input__${buttonStyle}`,
                            "button",
                            `button--input__${buttonStyle}`,
                            active && "button--active__selectable",
                            checked && `button--selected__${buttonStyle}`,
                        )}
                    >
                        <input
                            className="-visually-hidden"
                            type={type}
                            id={id}
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onFocus={_handleFocus}
                            onBlur={_handleBlur}
                            checked={checked}
                            ref={ref}
                            {...props}
                        />
                        <div className="button__content">
                            {typeof children !== "undefined" && children.length
                                ? children
                                : inputText}
                        </div>
                    </div>
                </label>
            );
        },
    ),
);

InputButton.displayName = "InputButton";

InputButton.propTypes = {
    /**
     * @name type
     * @description either 'radio' or 'checkbox'
     */
    type: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,
    inputText: PropTypes.string,
    children: PropTypes.any,
    buttonStyle: PropTypes.string,
};

export default InputButton;
