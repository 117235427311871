import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./PayPalAuthenticatedMessage.scss";

const PayPalAuthenticatedMessage = ({
    authenticatedAmountString,
    paypalPaymentSource,
}) => {
    // Display the appropriate logo based on whether the user paid using PayPal
    // or Venmo
    const paymentSourceLogo =
        paypalPaymentSource === "venmo"
            ? "/resources/core/images/logo-venmo-horizontal.png"
            : "/resources/core/images/logo-paypal-horizontal.png";

    return (
        <div className="-text--center">
            <div className="paypal-authenticated-message__container">
                <Icon
                    classNames={["-margin-right--10"]}
                    type="FontAwesome"
                    glyph="check-solid"
                />
                <span className="paypal-authenticated-message__container__text">
                    Authenticated for ${authenticatedAmountString}
                </span>
                <img
                    src={paymentSourceLogo}
                    alt="Payment Source"
                    title="Payment Source"
                    className="paypal-authenticated-message__payment-source-logo"
                />
            </div>
        </div>
    );
};

PayPalAuthenticatedMessage.propTypes = {
    authenticatedAmountString: PropTypes.string.isRequired,
    paypalPaymentSource: PropTypes.string.isRequired,
};

export default PayPalAuthenticatedMessage;
