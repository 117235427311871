import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Button.scss";

const Button = memo(
    forwardRef(
        (
            {
                ariaLabel = "",
                classes,
                type = "button",
                btnStyle = "primary",
                size = "large",
                role = "button",
                ...props
            },
            ref,
        ) => {
            const buttonClasses = cx(
                "qg-vendor-button",
                "button",
                btnStyle && `button--${btnStyle}`,
                size && `button--${size}`,
                typeof classes !== "undefined" && classes,
            );

            return (
                <button
                    aria-label={ariaLabel}
                    className={buttonClasses}
                    role={role}
                    ref={ref}
                    type={type}
                    {...props}
                >
                    <span className={"button__content"}>{props.children}</span>
                </button>
            );
        },
    ),
);

Button.displayName = "Button";

Button.propTypes = {
    ariaLabel: PropTypes.string,
    btnStyle: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.any,
    type: PropTypes.string,
    classes: PropTypes.string,
    role: PropTypes.string,
};

export default Button;
