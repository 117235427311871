import { createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "@qgiv/core-js";
import {
    appSettings as initialState,
    defaultView,
    promiseTransaction,
    receipts,
} from "../initialState";
import { cmsSettingsInitialState as cmsSettings } from "./cmsSettingsSlice";
import { configInitialState as config } from "./configSettingsSlice";
import { pageEnumMap } from "../selectors/cmsSettingsSelectors";
import { ModalContentReplacerEnums } from "../../components/common/ModalContentReplacer";
import { formSettingsInitialState as formSettings } from "./formSettingsSlice";

const {
    ENUMS: { CmsPageStandardizer },
} = constants;

const getAppSettingsInitialState = () => {
    const { pageOrder = [] } = cmsSettings;
    const { appearance = 2 } = formSettings;
    const { isCms } = config;
    const hasActiveFulfillment = Object.keys(promiseTransaction).length > 0;

    const isSinglePageForm = !!(appearance === 1);
    const skipAdditionalDetailsOnSingle =
        isSinglePageForm && hasActiveFulfillment && !isCms;

    // Single Page Form can have a different first page, but we want it to always
    // start on the donation amounts page if there are no receipts. All other
    // forms should start on the first page in the page order including the Event List
    // widget.
    let firstPage =
        isSinglePageForm &&
        Object.keys(receipts).length === 0 &&
        pageOrder[0] !== CmsPageStandardizer.QGIV_EVENTS
            ? CmsPageStandardizer.DONATION_AMOUNTS
            : pageOrder[0];

    // If viewing the form from CMS, we need to make sure that
    // the currentPage in state refers to the page being viewed in CMS
    if (isCms && defaultView > 0) {
        const pageEnum = pageEnumMap[defaultView];
        const pageIndex = pageOrder.indexOf(pageEnum);
        firstPage = pageOrder[pageIndex];
    }

    return {
        ...initialState,
        appLoading: true,
        appModalOpen: true,
        currentPage: firstPage,
        hasSentStartAnalyticsEvents: false,
        hasSubmittedForm: false,
        shouldDisplayApplePay: false,
        shouldScrollToTop: false,
        skipAdditionalDetailsPage: skipAdditionalDetailsOnSingle || false,
        contentToDisplay: ModalContentReplacerEnums.DEFAULT_CONTENT, // references form content
        isSubmittingUsingExpressCheckout: false,
        shouldDisplayConfirmationFieldsBasedOnUserInteraction: true,
        payPal: {
            hasCompletedPayPalAuthorizationFlow: false,
            hasCompletedPayPalInitialRender: false,
            hasInitiatedPayPalAuthorizationFlow: false,
            paymentMethodResetCount: 0,
            payPalPaymentSource: "paypal",
            forceReRenderCount: 0,
        },
        expressCheckout: {
            displayExpressCheckout: false,
            displayPayPalSDKScriptProvider: false,
            displayApplePayPaymentMethod: false,
        },
        directLoginModalHasOpened: false,
        receivedHandshake: false,
        isSinglePageForm,
    };
};

export const appSettingsInitialState = getAppSettingsInitialState();

const slice = createSlice({
    name: "appSettings",
    initialState: appSettingsInitialState,
    reducers: {
        setAppLoading: (state, action) => {
            state.appLoading = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
            state.errors = [];
        },
        setAppModalOpen: (state, action) => {
            state.appModalOpen = action.payload;
            state.errors = [];
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setHasSentStartAnalyticsEvents: (state, action) => {
            state.hasSentStartAnalyticsEvents = action.payload;
        },
        setHasSubmittedForm: (state, action) => {
            state.hasSubmittedForm = action.payload;
        },
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setCurrentPagePreviousPage: (state) => {
            const { currentPage } = state;
            const { pageOrder = [] } = cmsSettings;
            const currentIndex = pageOrder.indexOf(currentPage);
            const previousIndex = currentIndex - 1;

            const indexOfAdditionalDetails = pageOrder.indexOf(
                CmsPageStandardizer.ADDITIONAL_DETAILS,
            );

            const previousPageIsAdditionalDetailsPage =
                previousIndex === indexOfAdditionalDetails;

            if (
                previousPageIsAdditionalDetailsPage &&
                state.skipAdditionalDetailsPage
            ) {
                state.currentPage = pageOrder[previousIndex - 1];
                state.shouldScrollToTop = true;
                return;
            }

            if (previousIndex > -1) {
                state.currentPage = pageOrder[previousIndex];
                state.shouldScrollToTop = true;
            }
        },
        setCurrentPageNextPage: (state) => {
            const { currentPage } = state;
            const { pageOrder = [] } = cmsSettings;
            const currentIndex = pageOrder.indexOf(currentPage);
            const nextIndex = currentIndex + 1;
            // we might need to skip the index of the additional details page
            const indexOfAdditionalDetails = pageOrder.indexOf(
                CmsPageStandardizer.ADDITIONAL_DETAILS,
            );

            const nextPageIsAdditionalDetailsPage =
                nextIndex === indexOfAdditionalDetails;

            if (
                nextPageIsAdditionalDetailsPage &&
                state.skipAdditionalDetailsPage
            ) {
                state.currentPage = pageOrder[nextIndex + 1];
                state.shouldScrollToTop = true;
                return;
            }

            state.currentPage = pageOrder[nextIndex];
            state.shouldScrollToTop = true;
        },
        updateContentToDisplay: (state, action) => {
            state.contentToDisplay = action.payload;
        },
        updateSkipAdditionalDetailsPage: (state, action) => {
            // There are two types of setting this value
            // 1. On load, where are just checking dedication, matching, and custom field settings
            // 2. IF custom fields exists on additional details, need to check conditional logic for those fields
            // The last part will be implemented with conditional custom fields for PDR.
            state.skipAdditionalDetailsPage = action.payload;
        },
        incrementPaymentMethodResetCount: (state) => {
            state.payPal.paymentMethodResetCount += 1;
        },
        setHasCompletedPayPalAuthorizationFlow: (state, action) => {
            state.payPal.hasCompletedPayPalAuthorizationFlow = action.payload;
        },
        setHasInitiatedPayPalAuthorizationFlow: (state, action) => {
            state.payPal.hasInitiatedPayPalAuthorizationFlow = action.payload;
        },
        setPayPalPaymentSource: (state, action) => {
            state.payPal.payPalPaymentSource = action.payload;
        },
        setShouldDisplayApplePay: (state, action) => {
            state.shouldDisplayApplePay = action.payload;
        },
        setShouldScrollToTop: (state, action) => {
            state.shouldScrollToTop = action.payload;
        },
        setHasCompletedPayPalInitialRender: (state) => {
            state.payPal.hasCompletedPayPalInitialRender = true;
        },
        setIsSubmittingUsingExpressCheckout: (state, action) => {
            state.isSubmittingUsingExpressCheckout = action.payload;
        },
        setExpressCheckoutDisplayFlags: (state, action) => {
            state.expressCheckout = action.payload;
        },
        incrementForceReRenderCount: (state) => {
            state.payPal.forceReRenderCount += 1;
        },
        setShouldDisplayConfirmationFieldsBasedOnUserInteraction: (
            state,
            action,
        ) => {
            state.shouldDisplayConfirmationFieldsBasedOnUserInteraction =
                action.payload;
        },
        setDirectLoginModalHasOpened: (state, action) => {
            state.directLoginModalHasOpened = action.payload;
        },
        setReceivedHandshake: (state, action) => {
            state.receivedHandshake = action.payload;
        },
    },
});

// actions
export const {
    setAppLoading,
    setCurrentPage,
    setAppModalOpen,
    setErrors,
    setHasSentStartAnalyticsEvents,
    setHasSubmittedForm,
    setMessages,
    setCurrentPagePreviousPage,
    setCurrentPageNextPage,
    updateContentToDisplay,
    updateSkipAdditionalDetailsPage,
    incrementPaymentMethodResetCount,
    setHasCompletedPayPalAuthorizationFlow,
    setHasCompletedPayPalInitialRender,
    setHasInitiatedPayPalAuthorizationFlow,
    setPayPalPaymentSource,
    setShouldDisplayApplePay,
    setShouldScrollToTop,
    setIsSubmittingUsingExpressCheckout,
    setExpressCheckoutDisplayFlags,
    incrementForceReRenderCount,
    setShouldDisplayConfirmationFieldsBasedOnUserInteraction,
    setDirectLoginModalHasOpened,
    setReceivedHandshake,
} = slice.actions;

// selectors
export const selectAppSettings = (state) => state.appSettings;

export const selectAppLoading = (state) => state.appSettings.appLoading;

export const selectAppModalOpen = (state) => state.appSettings.appModalOpen;

export const selectFormStatusType = (state) => state.appSettings.formStatusType;

export const selectCurrentPage = (state) => state.appSettings.currentPage;

export const selectIsSinglePageForm = (state) =>
    state.appSettings.isSinglePageForm;

export const selectErrors = (state) => state.appSettings.errors;

export const selectHasSentStartAnalyticsEvents = (state) =>
    state.appSettings.hasSentStartAnalyticsEvents;

export const selectHasSubmittedForm = (state) =>
    state.appSettings.hasSubmittedForm;

export const selectMessages = (state) => state.appSettings.messages;

export const selectSkipAdditionalDetailsPage = (state) =>
    state.appSettings.skipAdditionalDetailsPage;

export const selectStateForSessionStorage = createSelector(
    [(state) => state.donationDetails, (state) => state.appSettings],
    (donationDetails, appSettings) => {
        const { currentPage } = appSettings;
        return {
            currentPage,
            donationDetails,
        };
    },
);

export const selectContentToDisplay = (state) =>
    state.appSettings.contentToDisplay;

export const selectHasInitiatedPayPalAuthorizationFlow = (state) =>
    state.appSettings.payPal.hasInitiatedPayPalAuthorizationFlow;

export const selectHasCompletedPayPalAuthorizationFlow = (state) =>
    state.appSettings.payPal.hasCompletedPayPalAuthorizationFlow;

export const selectPaymentMethodResetCount = (state) =>
    state.appSettings.payPal.paymentMethodResetCount;

export const selectPayPalPaymentSource = (state) =>
    state.appSettings.payPal.payPalPaymentSource;

export const selectShouldDisplayApplePay = (state) =>
    state.appSettings.shouldDisplayApplePay;

export const selectShouldScrollToTop = (state) =>
    state.appSettings.shouldScrollToTop;

export const selectHasCompletedPayPalInitialRender = (state) =>
    state.appSettings.payPal.hasCompletedPayPalInitialRender;

export const selectIsSubmittingUsingExpressCheckout = (state) =>
    state.appSettings.isSubmittingUsingExpressCheckout;

export const selectExpressCheckoutDisplayFlags = (state) =>
    state.appSettings.expressCheckout;

export const selectDisplayExpressCheckout = (state) =>
    state.appSettings.expressCheckout.displayExpressCheckout;

export const selectDisplayPayPalSDKScriptProvider = (state) =>
    state.appSettings.expressCheckout.displayPayPalSDKScriptProvider;

export const selectDisplayApplePayPaymentMethod = (state) =>
    state.appSettings.expressCheckout.displayApplePayPaymentMethod;

export const selectForceReRenderCount = (state) =>
    state.appSettings.payPal.forceReRenderCount;

export const selectShouldDisplayConfirmationFieldsBasedOnUserInteraction = (
    state,
) => state.appSettings.shouldDisplayConfirmationFieldsBasedOnUserInteraction;

export const selectDirectLoginModalHasOpened = (state) =>
    state.appSettings.directLoginModalHasOpened;

export const selectReceivedHandshake = (state) =>
    state.appSettings.receivedHandshake;

export default slice.reducer;
