import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Icon from "../Icon/Icon";
import ScrollTo from "../ScrollTo";
import "./Message.scss";

const iconGlyphs = {
    error: {
        glyph: "frown-open-solid",
        type: "FontAwesome",
    },
    fcRegistration: {
        glyph: "user-plus",
        type: "FontAwesome",
    },
    info: {
        glyph: "info-circle-solid",
        type: "FontAwesome",
    },
    primary: {
        glyph: "info",
        type: "Icomoon",
    },
    returning: {
        glyph: "hand-sparkles-solid",
        type: "FontAwesome",
    },
    success: {
        glyph: "check-circle-solid",
        type: "FontAwesome",
    },
    warn: {
        glyph: "exclamation-circle-solid",
        type: "FontAwesome",
    },
};

const Message = ({
    active = false,
    closeButton = false,
    children = undefined,
    onClose = () => {},
    scrollToMessage = true,
    style = undefined,
    timeout,
    type = "error",
}) => {
    const [isActive, setIsActive] = useState(active);
    const ref = React.createRef();
    const hasIcon = iconGlyphs[type]?.glyph && iconGlyphs[type]?.type;
    const messageClasses = cx(
        "message",
        `message--${type}`,
        hasIcon && "message--has-icon",
        isActive && "message--active",
    );

    useEffect(() => {
        setIsActive(true);
        if (timeout && timeout !== 0) {
            setTimeout(onClose, timeout);
        }
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={messageClasses} ref={ref} role="alert" style={style}>
            {scrollToMessage && <ScrollTo ref={ref} />}
            <div className="message__icons">
                <div className="message__icons__container">
                    {hasIcon && (
                        <Icon
                            glyph={iconGlyphs[type].glyph}
                            type={iconGlyphs[type].type}
                            classNames={["message__icon"]}
                        />
                    )}
                    {closeButton && (
                        <button
                            aria-label="close"
                            className="message__close -cursor--pointer"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                onClose();

                                if (!active) {
                                    setIsActive(false);
                                }
                            }}
                        >
                            <Icon glyph="cross" type="Icomoon" />
                        </button>
                    )}
                </div>
            </div>
            <div className="message__content">{children}</div>
        </div>
    );
};

Message.propTypes = {
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    active: PropTypes.bool,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    closeButton: PropTypes.bool,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    children: PropTypes.any,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    onClose: PropTypes.func,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    scrollToMessage: PropTypes.bool,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    style: PropTypes.object,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    timeout: PropTypes.number,
    // ⬇️ Grandfathered in from before Airbnb rules
    // eslint-disable-next-line react/require-default-props
    type: PropTypes.oneOf([
        "banner",
        "error",
        "fcRegistration",
        "info",
        "primary",
        "returning",
        "success",
        "warn",
        "striped-warning",
    ]),
};

export default Message;
