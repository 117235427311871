import { getHasDisplayableDedicationOnThisPage } from "@qgiv/donation-form";
import { constants } from "@qgiv/core-js";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectDedicationsValues,
    selectDonationMessage,
    selectDonationRecipientControlExistsInCms,
    selectGiftDetails,
    selectSelectedRecipient,
    selectStoredFormikGiftDetails,
    selectSubmitDonation,
    updateSubmitDonation,
} from "../../redux/slices/donationDetailsSlice";
import { buildGiftPageFormikSettings } from "./pageDataHelper";
import { selectAllFormSettings } from "../../redux/slices/formSettingsSlice";
import { selectAllDonationSettings } from "../../redux/slices/donationSettingsSlice";
import { selectDedicationSettings } from "../../redux/slices/dedicationSettingsSlice";
import { selectCurrentDisplay } from "../../redux/slices/configSettingsSlice";
import {
    selectContentToDisplay,
    selectIsSubmittingUsingExpressCheckout,
} from "../../redux/slices/appSettingsSlice";
import { selectSmsData } from "../../redux/slices/smsDataSlice";
import { selectRestrictions } from "../../redux/slices/restrictionSettingsSlice";

const useGiftPageData = () => {
    const {
        ENUMS: {
            FormTemplate: { P2P_STANDARD },
            CmsPageStandardizer,
        },
    } = constants;
    // Get data from Redux store
    const dispatch = useDispatch();
    const formSettings = useSelector(selectAllFormSettings);
    const donationSettings = useSelector(selectAllDonationSettings);
    const dedicationSettings = useSelector(selectDedicationSettings);
    const currentDisplay = useSelector(selectCurrentDisplay);
    // -------------------------------------------------------------------------
    // NOTE: Current Page becomes unreliable when form is Single Page. Since we
    // are using this data only on the Gift page, we can hardcode.
    // -------------------------------------------------------------------------
    const currentPage = CmsPageStandardizer.DONATION_AMOUNTS;
    const selectedRecipient = useSelector(selectSelectedRecipient);
    const smsData = useSelector(selectSmsData);
    const restrictions = useSelector(selectRestrictions);
    const donationRecipientExistsInCms = useSelector(
        selectDonationRecipientControlExistsInCms,
    );
    const giftDetails = useSelector(selectGiftDetails);
    const selectedContentToDisplay = useSelector(selectContentToDisplay);
    const isSubmittingUsingExpressCheckout = useSelector(
        selectIsSubmittingUsingExpressCheckout,
    );
    const submitDonation = useSelector(selectSubmitDonation);
    const storedFormikGiftDetails = useSelector(selectStoredFormikGiftDetails);
    const storedDedicationValues = useSelector(selectDedicationsValues);
    const storedDonationMessageValues = useSelector(selectDonationMessage);

    const { template } = formSettings;
    const isP2PStandard = template === P2P_STANDARD;

    const { hasSelectedRecurringDonation } = giftDetails;
    const { Has_Dedication } = storedDedicationValues;

    const hasValidSelectedRecipient =
        // products without donation recipient control in cms
        // will have selected recipient come back as undefined
        // and we want to allow those donations to move forward
        // in the form.
        selectedRecipient !== undefined &&
        Object.keys(selectedRecipient).length > 0;

    const shouldThrowRecipientValidationError =
        donationRecipientExistsInCms && !hasValidSelectedRecipient;

    const hasDisplayableDedicationOnThisPage =
        getHasDisplayableDedicationOnThisPage({
            currentDisplay,
            currentPage,
            dedicationSettings,
        });

    const giftPageData = useMemo(() => {
        const handleUpdateSubmitDonation = () => {
            dispatch(updateSubmitDonation(true));
        };

        const storedInitialValuesFromRedux = {
            ...storedFormikGiftDetails,
            ...storedDedicationValues,
            ...storedDonationMessageValues,
        };

        // Build settings objects for Formik
        const formikSettings = buildGiftPageFormikSettings({
            currentDisplay,
            dedicationSettings,
            donationSettings,
            formSettings,
            Has_Dedication,
            hasDisplayableDedicationOnThisPage,
            hasSelectedRecurringDonation,
            restrictions,
            currentPage,
            smsData,
            storedFormikGiftDetails,
        });

        // return data to be used in the GiftPage component
        return {
            dispatch,
            displayCaptcha: false,
            documentTitle: "Gift",
            donationRecipientExistsInCms,
            formikSettings,
            handleUpdateSubmitDonation,
            hasDisplayableDedicationOnThisPage,
            isP2PStandard,
            isSubmittingUsingExpressCheckout,
            selectedContentToDisplay,
            shouldThrowRecipientValidationError,
            storedInitialValuesFromRedux,
            submitDonation,
        };
    }, [
        Has_Dedication,
        currentDisplay,
        currentPage,
        dedicationSettings,
        dispatch,
        donationRecipientExistsInCms,
        donationSettings,
        formSettings,
        hasDisplayableDedicationOnThisPage,
        hasSelectedRecurringDonation,
        isP2PStandard,
        isSubmittingUsingExpressCheckout,
        restrictions,
        selectedContentToDisplay,
        shouldThrowRecipientValidationError,
        smsData,
        storedDedicationValues,
        storedDonationMessageValues,
        storedFormikGiftDetails,
        submitDonation,
    ]);

    return giftPageData;
};

export default useGiftPageData;
