import { useEffect } from "react";
import { useFormikContext } from "formik";

// This component can be used within each form page to watch for errors when submitting
// and then scroll to the first error on the page
// Field level errors will always be first in the array since those are validated first
// and the error order is dependent on validation order
// To account for this, if there are more than one error, we compare the positions
// of the elements with errors on the document to determine which is closer to the top of the page
// then scroll to that input

const UpdateFieldErrorFocus = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            const keys = Object.keys(errors);
            let selector;
            // If more than one error, we need to find the position of each
            if (keys.length > 1) {
                // Get array of element's position from top of the DOM
                const inputPositions = keys.map((key) => {
                    // Link the account errors to the Email input
                    const currentSelector = `[name="${
                        key.includes("Verified_Email") ? "Email" : key
                    }"]`;

                    const currentErrorElement =
                        document.querySelector(currentSelector);

                    if (currentErrorElement && currentErrorElement !== null) {
                        return currentErrorElement.getBoundingClientRect().top;
                    }
                    return [];
                });

                // Get the index of the key first appearing on the DOM
                const topElementIndex = inputPositions.indexOf(
                    Math.min(...inputPositions),
                );
                //-------------------------------------------------------------
                // TODO:
                // Figure out why credit card errors are visible even though
                // the payment method UI is not visible after cancelling an
                // Apple Pay transaction and modifying the registration so that
                // the total is now 0.
                //-------------------------------------------------------------
                selector = `[name="${
                    keys[topElementIndex] &&
                    keys[topElementIndex].includes("Verified_Email")
                        ? "Email"
                        : keys[topElementIndex]
                }"]`;
            } else if (keys.length === 1) {
                // If there is only one error, we don't need to get it's position
                selector = `[name="${
                    keys[0].includes("Verified_Email") ? "Email" : keys[0]
                }"]`;
            }

            const errorElement = selector && document.querySelector(selector);
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: "smooth" });
                errorElement?.focus();
            } else {
                // check for event custom fields since their names are crazy
                // using the aria-invalid property
                // will be either DOM element or null
                const erroredField = document.querySelector(
                    '[aria-invalid]:not([aria-invalid="false"])',
                );
                if (erroredField) {
                    erroredField?.scrollIntoView({ behavior: "smooth" });
                    erroredField?.focus();
                }
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
};

export default UpdateFieldErrorFocus;
