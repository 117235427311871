import React from "react";
import PropTypes from "prop-types";

import "./RecurringDates.scss";
import { Accordion2 as Accordion, TextField } from "@qgiv/core-donor";

const RecurringDates = ({
    // CP Settings
    enableEndDate,
    enableStartDate,
    // CMS settings
    changeDatesLabel,
    endDateLabel,
    startDateLabel,
    // Formik
    End_Date = "",
    errors,
    handleBlur,
    handleChange,
    Start_Date,
    touched,

    // Accordion/UI
    activeEventKeys,
    dateFieldColumnClasses,
    setActiveEventKeys,
    showDateFields,
    showToggleDateFieldsButton,
    yourDetailsEventKey,
}) => (
    <Accordion activeEventKeys={activeEventKeys} onToggle={setActiveEventKeys}>
        {/* Create a containing element in order to center the toggle button */}
        {showToggleDateFieldsButton && (
            <div className="recurring-dates__toggle-container">
                <div>
                    <Accordion.Toggle
                        eventKey={yourDetailsEventKey}
                        element={Accordion.Header}
                    >
                        <p>
                            <strong>{changeDatesLabel}</strong>
                        </p>
                    </Accordion.Toggle>
                </div>
            </div>
        )}
        {/* Date fields nested inside of an accordion */}
        {showDateFields && (
            <Accordion.Collapse eventKey={yourDetailsEventKey}>
                <section>
                    {/* eslint-disable-next-line tailwindcss/classnames-order */}
                    <div className="grid recurring-dates__main-content">
                        {enableStartDate && (
                            <div className={dateFieldColumnClasses}>
                                <TextField
                                    autoComplete="off"
                                    type="dateMDY"
                                    name="Start_Date"
                                    label={startDateLabel}
                                    value={Start_Date}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    descIcon={{
                                        glyph: "calendar-alt-regular",
                                        type: "FontAwesome",
                                    }}
                                    error={
                                        touched.Start_Date &&
                                        errors.Start_Date &&
                                        errors.Start_Date.length > 0
                                    }
                                    errorText={errors.Start_Date}
                                />
                            </div>
                        )}
                        {enableEndDate && (
                            <div className={dateFieldColumnClasses}>
                                <TextField
                                    autoComplete="off"
                                    type="dateMDY"
                                    name="End_Date"
                                    label={endDateLabel}
                                    value={End_Date}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    descIcon={{
                                        glyph: "calendar-alt-regular",
                                        type: "FontAwesome",
                                    }}
                                    error={
                                        touched.End_Date &&
                                        errors.End_Date &&
                                        errors.End_Date.length > 0
                                    }
                                    errorText={errors.End_Date}
                                    selectedStartDate={Start_Date}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </Accordion.Collapse>
        )}
    </Accordion>
);

RecurringDates.propTypes = {
    // CP Settings
    enableEndDate: PropTypes.bool.isRequired,
    enableStartDate: PropTypes.bool.isRequired,
    // CMS settings
    changeDatesLabel: PropTypes.string.isRequired,
    endDateLabel: PropTypes.string.isRequired,
    startDateLabel: PropTypes.string.isRequired,
    // Formik
    End_Date: PropTypes.string,
    errors: PropTypes.shape({
        Start_Date: PropTypes.string,
        End_Date: PropTypes.string,
    }).isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    Start_Date: PropTypes.string.isRequired,
    touched: PropTypes.shape({
        Start_Date: PropTypes.bool,
        End_Date: PropTypes.bool,
    }).isRequired,

    // Accordion/UI
    activeEventKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
    dateFieldColumnClasses: PropTypes.string.isRequired,
    setActiveEventKeys: PropTypes.func.isRequired,
    showDateFields: PropTypes.bool.isRequired,
    showToggleDateFieldsButton: PropTypes.bool.isRequired,
    yourDetailsEventKey: PropTypes.number.isRequired,
};

export default RecurringDates;
