import { initMutationObserverModal } from "./modalMutationObserver";
import { initMutationObserverEmbed } from "./embedMutationObserver";
import store from "../../redux";

/**
 * Initializes the appropriate MutationObserver based on the current configuration.
 * If the form is in a modal, the modal mutation observer is used.
 * If the form is embedded, the embed mutation observer is used.
 * @function
 * @name initMutationObserver
 * @returns {void}
 */
export const initMutationObserver = () => {
    const state = store.getState();
    const {
        config: { isDisplayTypeModal },
    } = state;

    if (isDisplayTypeModal) {
        return initMutationObserverModal();
    }
    return initMutationObserverEmbed();
};
