import React from "react";
import PropTypes from "prop-types";
import HelpText from "./HelpText";
import MaxChar from "./MaxChar";
import ErrorText from "./ErrorText";
import "./TextBelowField.scss";

const TextBelowField = ({
    errorTextProps,
    helpTextProps,
    maxCharProps,
    showErrorText,
    showHelpText,
    showMaxChar,
}) => {
    // If no help text is present but the max char is then the max char should
    // be displayed at the end of the line and any error messaging should be
    // displayed on the same line but before the error text. If no error text
    // is displayed here rendering an empty div allows us to easily keep the
    // max char aligned to the right using justify-content: space-between.
    if (!showHelpText && showMaxChar) {
        return (
            <div className="grid">
                <div className="col text-below-field__first-line -padding-top--5">
                    <div>
                        {showErrorText && <ErrorText {...errorTextProps} />}
                    </div>
                    {showMaxChar && <MaxChar {...maxCharProps} />}
                </div>
            </div>
        );
    }

    // Else display the help text and the max char on the same line and any
    // error messaging on an additional line below that
    return (
        <>
            {showHelpText && (
                <div className="grid">
                    <div className="col text-below-field__first-line -padding-top--5">
                        {showHelpText && <HelpText {...helpTextProps} />}
                        {showMaxChar && <MaxChar {...maxCharProps} />}
                    </div>
                </div>
            )}
            {showErrorText && (
                <div className="grid">
                    <div className="col -padding-top--0 -padding-bottom--0">
                        <ErrorText {...errorTextProps} />
                    </div>
                </div>
            )}
        </>
    );
};

TextBelowField.propTypes = {
    errorTextProps: PropTypes.shape({
        errorText: PropTypes.string.isRequired,
    }).isRequired,
    helpTextProps: PropTypes.shape({
        helpText: PropTypes.string.isRequired,
    }).isRequired,
    maxCharProps: PropTypes.shape({
        maxChar: PropTypes.string,
        value: PropTypes.string,
    }).isRequired,
    showErrorText: PropTypes.bool.isRequired,
    showHelpText: PropTypes.bool.isRequired,
    showMaxChar: PropTypes.bool.isRequired,
};

export default TextBelowField;
