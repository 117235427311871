import React from "react";

/**
 * @param {object} props
 * @param {string} props.description
 * @returns {React.ReactElement} - The rendered GivingPlanCardDescription component.
 */
const GivingPlanCardDescription = ({ description }) => (
    <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
            __html: `<span class="-type--small">${description}</span>`,
        }}
    />
);

export default GivingPlanCardDescription;
