import React from "react";
import { Formik, Form } from "formik";
import { UpdateFieldErrorFocus, SEO } from "@qgiv/core-donor";
import ConnectedPaymentPageContent from "./PaymentPageContent";
import ConnectedDonationFormBase from "../templates/DonationFormBase";
import ConnectedSubmitDonation from "../../common/SubmitDonation";
import ModalContentReplacer from "../../common/ModalContentReplacer";

/**
 * @param {object} props
 * @param {React.ReactElement} props.alternateContent
 * @param {string} props.documentTitle
 * @param {object} props.formikProps
 * @param {string} props.selectedContentToDisplay
 * @param {Function} props.submitDonation
 * @returns {React.ReactElement}
 */
const PaymentPage = ({
    alternateContent,
    documentTitle = "",
    formikProps,
    selectedContentToDisplay,
    submitDonation,
}) => (
    <Formik {...formikProps}>
        <ConnectedDonationFormBase>
            <SEO title={documentTitle} description="" />
            <ModalContentReplacer
                selectedContentToDisplay={selectedContentToDisplay}
                alternateContent={alternateContent}
            >
                <Form>
                    <ConnectedPaymentPageContent />
                </Form>
            </ModalContentReplacer>
            {submitDonation && <ConnectedSubmitDonation />}
            <UpdateFieldErrorFocus />
        </ConnectedDonationFormBase>
    </Formik>
);

export default PaymentPage;
