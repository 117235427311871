import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { LoadingScreen2 } from "@qgiv/core-donor";
import "./DonationFormLoading.scss";

const DonationFormLoading = ({ active = false }) => (
    <div
        className={cx(
            "donation-form-modal-loading-screen",
            active && "donation-form-modal-loading-screen--active",
        )}
    >
        <div className="donation-form-modal-loading-screen__content">
            <LoadingScreen2 active={active} eventModal color="#FFFFFF" />
        </div>
    </div>
);

DonationFormLoading.propTypes = {
    active: PropTypes.bool,
};

export default DonationFormLoading;
