import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import {
    TogglePaymentMethodsButton,
    getDisplayTogglePaymentMethodsButton,
} from "@qgiv/core-donor";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectHasStoredPaymentMethods } from "../../../../redux/slices/donorAccountSlice";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";

const ConnectedTogglePaymentMethodsButton = ({
    controlId,
    displayStoredPaymentMethods,
    handleTogglePaymentMethodsButtonClick,
}) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
        },
    } = constants;
    const hasStoredPaymentMethods = useSelector(selectHasStoredPaymentMethods);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );
    const isCms = useSelector(selectIsCms);
    const { values } = useFormikContext();
    const {
        options: {
            newPaymentHeading = "Or use a new payment method",
            savedPaymentHeading = "Or use a saved payment method",
        },
    } = control;

    // Only display the toggle payment method button if the user has the option
    // to pay using a stored payment method and the user has not clicked the
    // Apple Pay button
    const displayTogglePaymentMethodsButton =
        getDisplayTogglePaymentMethodsButton({
            hasStoredPaymentMethods,
            isCms,
            values,
        });
    const text = displayStoredPaymentMethods
        ? newPaymentHeading
        : savedPaymentHeading;

    const togglePaymentMethodsButtonProps = {
        controlId,
        displayTogglePaymentMethodsButton,
        handleTogglePaymentMethodsButtonClick,
        text,
    };

    return <TogglePaymentMethodsButton {...togglePaymentMethodsButtonProps} />;
};

ConnectedTogglePaymentMethodsButton.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    displayStoredPaymentMethods: PropTypes.bool.isRequired,
    handleTogglePaymentMethodsButtonClick: PropTypes.func.isRequired,
};

export default ConnectedTogglePaymentMethodsButton;
