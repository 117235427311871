import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { ImageLoader } from "@qgiv/core-react";

/**
 * @typedef {import("../../types").ComponentTypes.CardImageProps} CardImageProps
 */

/**
 *
 * @param {CardImageProps} settings
 * @returns {React.ReactElement}
 */
const CardImage = ({ url, altText, display = "standard" }) => {
    let size;
    const getImageSizeFromMeta = (url, callback) => {
        const img = new Image();

        img.onload = function () {
            size = { w: this.naturalWidth, h: this.naturalHeight };
            // We have the size of the image, now what should we do with it?
            callback();
        };

        img.src = url;
    };
    let [imageClass, setImageClass] = useState("");
    // Delay is necessary to ensure image has loaded first
    const translateSizeToSquareImageClass = () => {
        let imageSize = "";
        // Assign class to handle the styling for tall or long images
        // within a square container
        if (parseInt(size.w) > parseInt(size.h)) {
            imageSize = "-long";
        } else {
            imageSize = "-tall";
        }
        // We need to handle those images that are very close to square
        // by over riding the value for imageSize
        const sizeDifference = Math.abs(size.w - size.h);
        if (sizeDifference < 10) {
            imageSize = "";
        }
        setImageClass(imageSize);
    };

    if (display === "square") {
        getImageSizeFromMeta(url, translateSizeToSquareImageClass);
    }

    const imgAspectRatioClasses = cx(
        "card__img-aspect-ratio",
        display === "wide" && `card__img-aspect-ratio--wide`,
        display === "standard" && `card__img-aspect-ratio--standard`,
        display === "square" && `card__img-aspect-ratio--square${imageClass}`,
    );

    return (
        <div className={imgAspectRatioClasses}>
            <div className="card__img-container">
                <ImageLoader
                    className="card__img"
                    src={url}
                    alt={`${altText}`}
                />
            </div>
        </div>
    );
};

CardImage.propTypes = {
    url: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    display: PropTypes.string,
};

export default CardImage;
