import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import {
    getHasSelectedOtherAmount,
    getOtherAmountErrorProps,
    getOtherInputProps,
} from "@qgiv/donation-form";
import { useSelector } from "react-redux";
import { selectAllDonationSettings } from "../../../../../redux/slices/donationSettingsSlice";
import { selectGiftDetails } from "../../../../../redux/slices/donationDetailsSlice";
import OtherAmountError from "../../../../common/OtherAmountError";

const ConnectedOtherAmountError = ({ amountsToDisplay, fieldName }) => {
    const { errors, values } = useFormikContext();
    const { activeAmounts } = useSelector(selectAllDonationSettings);
    const { hasSelectedRecurringDonation } = useSelector(selectGiftDetails);

    const hasSelectedOtherAmount = getHasSelectedOtherAmount({
        activeAmounts,
        values,
        fieldName,
    });

    const { otherAmountInputFieldName } = getOtherInputProps({
        fieldName,
        hasSelectedOtherAmount,
        hasSelectedRecurringDonation,
        amountsToDisplay,
    });

    const otherAmountErrorMessage =
        errors && errors[otherAmountInputFieldName]
            ? errors[otherAmountInputFieldName]
            : "";

    const otherAmountErrorProps = getOtherAmountErrorProps({
        otherAmountErrorMessage,
        hasSelectedOtherAmount,
    });

    return <OtherAmountError {...otherAmountErrorProps} />;
};

ConnectedOtherAmountError.propTypes = {
    amountsToDisplay: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    fieldName: PropTypes.string.isRequired,
};

export default ConnectedOtherAmountError;
