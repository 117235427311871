import React from "react";
import PropTypes from "prop-types";
import ConnectedCompleteAnalyticsEvents from "./ConnectedCompleteAnalyticsEvents";
import ConnectedStartAnalyticsEvents from "./ConnectedStartAnalyticsEvents";
import ConnectedStepChangeAnalyticsEvents from "./ConnectedStepChangeAnalyticsEvents";

const AnalyticsEvents = ({ shouldRunAnalyticsEvents = true }) =>
    shouldRunAnalyticsEvents ? (
        <>
            <ConnectedStartAnalyticsEvents />
            <ConnectedStepChangeAnalyticsEvents />
            <ConnectedCompleteAnalyticsEvents />
        </>
    ) : null;

AnalyticsEvents.propTypes = {
    shouldRunAnalyticsEvents: PropTypes.bool.isRequired,
};

export default AnalyticsEvents;
