import moment from "moment";

import constants from "../constants";

/**
 * @function translatePaymentType
 * @param {string} type A two letter string indicating payment method type
 * @param {string} [channel] a string representing the TransactionChannel
 * @returns {string} The payment method translation
 */
export const translatePaymentType = (type, channel) => {
    const { PaymentTypes, TransactionChannel } = constants.ENUMS;

    switch (type) {
        case PaymentTypes.AMERICAN_EXPRESS:
            return "American Express";
        case PaymentTypes.VISA:
            return "Visa";
        case PaymentTypes.DISCOVER:
            return "Discover";
        case PaymentTypes.MASTERCARD:
            return "MasterCard";
        case PaymentTypes.ECHECK:
            return "E-Check";
        case PaymentTypes.NO_CHARGE:
            return "No charge";
        case PaymentTypes.CASH:
            return "Cash";
        case PaymentTypes.CHECK:
            return "Check";
        case PaymentTypes.PAYPAL:
            if (Number(channel) === TransactionChannel.VENMO) {
                return "Venmo";
            }
            return "PayPal";
        case PaymentTypes.MATCHING_GIFT:
            return "Matching";
        case PaymentTypes.BILLTOCARRIER:
            return "Bill to Carrier";
        case PaymentTypes.FACEBOOK:
            return "Facebook";
        default:
            return undefined;
    }
};

export const translatePaymentTypeToFontAwesomeIcon = (type) => {
    const { PaymentTypes } = constants.ENUMS;

    switch (type) {
        case PaymentTypes.AMERICAN_EXPRESS:
            return "cc-amex-brands";
        case PaymentTypes.VISA:
            return "cc-visa-brands";
        case PaymentTypes.DISCOVER:
            return "cc-discover-brands";
        case PaymentTypes.MASTERCARD:
            return "cc-mastercard-brands";
        case PaymentTypes.ECHECK:
            return "university-regular";
        case PaymentTypes.CASH:
        case PaymentTypes.CHECK:
            return "money-check-alt-solid";
        case PaymentTypes.PAYPAL:
            return "cc-paypal-brands";
        case PaymentTypes.FACEBOOK:
            return "facebook-brands";
        case PaymentTypes.MATCHING_GIFT:
        case PaymentTypes.BILLTOCARRIER:
        case PaymentTypes.NO_CHARGE:
        default:
            return undefined;
    }
};

/**
 * @function translatePaymentStatus
 * @param {string} status Status value as a string
 * @returns {string|number} Payment status as a string or enum number
 */
export const translatePaymentStatus = (status) => {
    const { PaymentStatus } = constants.ENUMS;
    const isNumeric = (num) => !Number.isNaN(num) && Number.isFinite(num);

    if (isNumeric(status)) {
        switch (Number(status)) {
            case PaymentStatus.ACCEPTED:
                return "Accepted";
            case PaymentStatus.CR:
                return "Offline";
            case PaymentStatus.DECLINED:
                return "Declined";
            case PaymentStatus.ERROR:
                return "Error";
            case PaymentStatus.PENDING:
                return "Pending";
            case PaymentStatus.PROMISE:
                return "Promise";
            case PaymentStatus.TESTING:
                return "Testing";
            case PaymentStatus.UNVERIFIED:
                return "Unverified";
            case PaymentStatus.UPLOADED:
                return "Uploaded";
            case PaymentStatus.VERIFIED:
                return "Verified";
            default:
                return undefined;
        }
    } else if (status.length === 1 || status.length === 2) {
        switch (status) {
            case "A":
                return "Accepted";
            case "CR":
                return "CR";
            case "D":
                return "Declined";
            case "E":
                return "Error";
            case "P":
                return "Pending";
            case "T":
                return "Testing";
            case "U":
                return "Voided";
            case "V":
                return "Verified";
            case "UV":
                return "Unverified";
            case "PR":
                return "Promise";
            default:
                return undefined;
        }
    } else {
        switch (status) {
            case "accepted":
                return PaymentStatus.ACCEPTED;
            case "cr":
                return PaymentStatus.CR;
            case "declined":
                return PaymentStatus.DECLINED;
            case "error":
                return PaymentStatus.ERROR;
            case "pending":
                return PaymentStatus.PENDING;
            case "testing":
                return PaymentStatus.TESTING;
            case "uploaded":
                return PaymentStatus.UPLOADED;
            case "verified":
                return PaymentStatus.VERIFIED;
            case "unverified":
                return PaymentStatus.UNVERIFIED;
            case "promise":
                return PaymentStatus.PROMISE;
            default:
                return undefined;
        }
    }
};

/**
 * @function translateProductType
 * @param {number} type ProductType enum
 * @returns {"Fundraising Hub"|"Auction"|"Political"|"Peer-to-Peer"|"Qgiv Event"|"Qgiv"}
 *      Human-friendly product type
 */
export const translateProductType = (type) => {
    const { ProductType } = constants.ENUMS;

    switch (type) {
        case ProductType.AGGREGATOR:
            return "Fundraising Hub";
        case ProductType.AUCTION:
            return "Auction";
        case ProductType.BARNSTORM:
            return "Political";
        case ProductType.HOBNOB:
            return "Peer-to-Peer";
        case ProductType.QGIV_EVENT:
            return "Qgiv Event";
        case ProductType.QGIV:
        default:
            return "Qgiv";
    }
};

/**
 * @function translateProductTypeStringToEnum
 * @param {string} type
 *      Human-friendly product type
 * @returns {number} type ProductType enum
 */
export const translateProductTypeStringToEnum = (type) => {
    const { ProductType } = constants.ENUMS;

    switch (type) {
        case "Fundraising Hub":
            return ProductType.AGGREGATOR;
        case "Auction":
            return ProductType.AUCTION;
        case "Political":
            return ProductType.BARNSTORM;
        case "Peer-to-Peer":
            return ProductType.HOBNOB;
        case "Qgiv":
            return ProductType.QGIV;
        case "Qgiv Event":
            return ProductType.QGIV_EVENT;
        default:
            return 0;
    }
};

/**
 * @function translateRecurringFrequency
 * @param {string} frequency A letter string indication frequency type (e.g. 'a', 'b', 'd', 'm', 'n', 'q', 's', 'w')
 * @returns {string} The frequency type translation
 */
export const translateRecurringFrequency = (frequency) => {
    const { RecurringFrequency } = constants.ENUMS;

    switch (frequency) {
        case RecurringFrequency.ONCE:
            return "One Time";
        case RecurringFrequency.DAILY:
            return "Daily";
        case RecurringFrequency.WEEKLY:
            return "Weekly";
        case RecurringFrequency.BI_WEEKLY:
            return "Every Other Week";
        case RecurringFrequency.MONTHLY:
            return "Monthly";
        case RecurringFrequency.QUARTERLY:
            return "Quarterly";
        case RecurringFrequency.SEMI_ANNUALLY:
            return "Semiannually";
        case RecurringFrequency.ANNUALLY:
            return "Annually";
        default:
            return undefined;
    }
};

/**
 * @function translateRecurringUnit
 * @param {string} frequency A letter string indication frequency type (e.g. 'a', 'b', 'd', 'm', 'q', 's', 'w')
 * @returns {string} The translated frequency unit
 */
export const translateRecurringUnit = (frequency) => {
    const { RecurringFrequency } = constants.ENUMS;

    switch (frequency) {
        case RecurringFrequency.DAILY:
            return "Day";
        case RecurringFrequency.WEEKLY:
            return "Week";
        case RecurringFrequency.BI_WEEKLY:
            return "Two Weeks";
        case RecurringFrequency.MONTHLY:
            return "Month";
        case RecurringFrequency.QUARTERLY:
            return "Quarter";
        case RecurringFrequency.SEMI_ANNUALLY:
            return "Six Months";
        case RecurringFrequency.ANNUALLY:
            return "Year";
        default:
            return undefined;
    }
};

/**
 * @function translateTimezone
 * @description Given a timezone enum, translate it into a human readable
 *      abbreviated string
 * @param {number} timezone Timezone enum to translate
 * @returns {string} Timezone translated as an abbreviated string
 */
export const translateTimezone = (timezone) => {
    // There is a legacy implementation on line 388 of
    // resources/admin/js/admin.qgiveventsettings.js which will need edited in
    // case timezones are added / deleted. The daylight savings adjustment does
    // not impact the legacy implementation
    const { Timezone } = constants.ENUMS;
    const isDaylightSavings = moment().isDST();
    let translatedTimezone = "";

    switch (timezone) {
        case Timezone.EASTERN:
            translatedTimezone = "EST";
            break;
        case Timezone.CENTRAL:
            translatedTimezone = "CST";
            break;
        case Timezone.MOUNTAIN:
            translatedTimezone = "MST";
            break;
        case Timezone.PACIFIC:
            translatedTimezone = "PST";
            break;
        case Timezone.HAWAIIAN:
            translatedTimezone = "HST";
            break;
        case Timezone.ALASKAN:
            translatedTimezone = "AKST";
            break;
        case Timezone.ARIZONA:
            translatedTimezone = "MST";
            break;
        default:
            translatedTimezone = "";
    }
    // matching daylight savings behavior from Enums.php translateTimezone
    // set daylight savings time symbol, except for Arizona and Hawaii
    if (timezone !== Timezone.ARIZONA && timezone !== Timezone.HAWAIIAN) {
        if (translatedTimezone && isDaylightSavings) {
            translatedTimezone = translatedTimezone.replace("S", "D");
        }
    }

    return translatedTimezone;
};

/**
 * @function translateUnabbreviatedTimezone
 * @description Given a timezone enum, translate it into a human readable
 *      unabbreviated string
 * @param {number} timezone Timezone enum to translate
 * @returns {string} Timezone translated as a string in full format
 */
export const translateUnabbreviatedTimezone = (timezone) => {
    const { Timezone } = constants.ENUMS;

    switch (timezone) {
        case Timezone.EASTERN:
            return "America/New_York";
        case Timezone.CENTRAL:
            return "America/Chicago";
        case Timezone.MOUNTAIN:
            return "America/Denver";
        case Timezone.PACIFIC:
            return "America/Los_Angeles";
        case Timezone.HAWAIIAN:
            return "Pacific/Honolulu";
        case Timezone.ALASKAN:
            return "America/Anchorage";
        case Timezone.ARIZONA:
            return "America/Phoenix";
        default:
            return "";
    }
};

/**
 * @function translateFrequencyUnit
 * @param {string} frequency A letter string indication frequency type (e.g. 'a', 'b', 'd', 'm', 'q', 's', 'w')
 * @returns {string} The translated frequency unit
 */
export const translateFrequencyUnit = (frequency) => {
    const { RecurringFrequency } = constants.ENUMS;

    switch (frequency) {
        case RecurringFrequency.DAILY:
            return "day";
        case RecurringFrequency.WEEKLY:
        case RecurringFrequency.BI_WEEKLY:
            return "week";
        case RecurringFrequency.MONTHLY:
        case RecurringFrequency.QUARTERLY:
        case RecurringFrequency.SEMI_ANNUALLY:
            return "month";
        case RecurringFrequency.ANNUALLY:
            return "year";
        default:
            return undefined;
    }
};

/**
 * @function translateImageSize
 * @param {string|number} imageSize A number or number string indicating image size
 * @returns {string} The image size translation
 */
export const translateImageSize = (imageSize) => {
    const _imageSize = parseInt(imageSize, 10);
    const { ImageSize } = constants.ENUMS;

    switch (_imageSize) {
        case ImageSize.STANDARD:
            return "standard";
        case ImageSize.WIDE:
            return "wide";
        default:
            return undefined;
    }
};

/**
 * @function translateOldFieldType
 * @param {string} fieldType A string to compare against old field types
 * @returns {string|number} Returns a translated enum or the passed in fieldType
 */
export const translateOldFieldType = (fieldType) => {
    const { FieldType } = constants.ENUMS;

    switch (fieldType) {
        case FieldType.OLD_SHORT_TEXT:
            return FieldType.SHORT_TEXT;
        case FieldType.OLD_PARAGRAPH_TEXT:
            return FieldType.PARAGRAPH_TEXT;
        case FieldType.OLD_CHECKBOX:
            return FieldType.CHECKBOX;
        case FieldType.OLD_DROPDOWN:
            return FieldType.DROPDOWN;
        default:
            return fieldType;
    }
};

/**
 * @function translateStoreIconClassToGlyph
 * @param {string} iconName A string to compare against old field types
 * @returns {object} Returns a translated icon from class name to glyph name and type
 */
export const translateStoreIconClassToGlyph = (iconName) => {
    switch (iconName) {
        case "icon-shirt":
            return {
                glyph: "tshirt-solid",
                type: "FontAwesome",
            };
        case "icon-star-full":
            return {
                glyph: "star-solid",
                type: "Icomoon",
            };
        case "icon-gift":
            return {
                glyph: "gift-store",
                type: "Icomoon",
            };
        case "icon-store":
            return {
                glyph: "store",
                type: "Icomoon",
            };
        case "icon-tag":
        default:
            return {
                glyph: "tag",
                type: "Icomoon",
            };
    }
};

/**
 * @function translateDataLayerEvent
 * @param {number} event Convert the ENUM that we use in our application to an
 *        event name that is specified by the analytics documentation.
 * @returns {string} Event name that is specified by the analytics documentation
 */
export const translateDataLayerEvent = (event) => {
    const { DataLayerEvent } = constants.ENUMS;

    // QGIV_DONATION_PAGE_VIEW & QGIV_REGISTRATION_PAGE_VIEW intentionally emit
    // the same event. However, different ENUMs were needed as the payload that
    // is sent varies based off whether the data is being emitted from a
    // donation or a registration form
    switch (event) {
        case DataLayerEvent.QGIV_DONATION_START:
            return "QGIV.donationStart";
        case DataLayerEvent.QGIV_DONATION_CLOSE:
            return "QGIV.donationClose";
        case DataLayerEvent.QGIV_DONATION_COMPLETE:
            return "QGIV.donationComplete";
        case DataLayerEvent.QGIV_DONATION_STEP_CHANGE:
            return "QGIV.donationStepChange";
        case DataLayerEvent.QGIV_DONATION_PAGE_VIEW:
            return "QGIV.pageView";
        case DataLayerEvent.QGIV_REGISTRATION_START:
            return "QGIV.registrationStart";
        case DataLayerEvent.QGIV_REGISTRATION_CLOSE:
            return "QGIV.registrationClose";
        case DataLayerEvent.QGIV_REGISTRATION_COMPLETE:
            return "QGIV.registrationComplete";
        case DataLayerEvent.QGIV_REGISTRATION_STEP_CHANGE:
            return "QGIV.registrationStepChange";
        case DataLayerEvent.QGIV_REGISTRATION_PAGE_VIEW:
            return "QGIV.pageView";
        case DataLayerEvent.QGIV_GA4_PURCHASE:
            return "QGIV.ga4Purchase";
        default:
            return "";
    }
};
