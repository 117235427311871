import { useEffect, useCallback } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";

const UpdateIncrementForceReRenderCount = ({
    dispatchIncrementForceReRenderCount = () => {},
    dispatchSetHasCompletedPayPalInitialRender = () => {},
    hasCompletedInitialRender = false,
    valuesThatShouldForceAReRender = {},
}) => {
    // Appeared to be the best value for preventing unnecessary reloads after
    // running a series of experiments where I was manually typed values into
    // the Other amount and observing the number of requests sent by PayPal.
    // This was tested using the Other amount as this was the use case with the
    // most acute version of the problem.
    const timeToWaitForNextValueChange = 700;

    // Need to cache the debounce function so that the same instance of this
    // function is available to run the next time the component re-renders.
    // This rule is ignored as we know that all of the values that are used
    // here will never change. And because of that we will never need to re-un
    // this logic again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cachedShouldForceReRender = useCallback(
        debounce(
            dispatchIncrementForceReRenderCount,
            timeToWaitForNextValueChange,
        ),
        [],
    );

    // The initial render flag is necessary in order to prevent a re-render of
    // the PayPal by this function immediately after page is first loaded. If a
    // value that affects a PayPal transaction is not included in this list then
    // we will end up including old and outdated data in the createOrder()
    // request that is sent to the BE when initiating a transaction.
    useEffect(() => {
        if (hasCompletedInitialRender) {
            cachedShouldForceReRender();
        } else {
            dispatchSetHasCompletedPayPalInitialRender();
        }
        // We need to allow different values to be passed into this array in
        // order for this component to be reusable across the different apps.
        // The most important thing however is that the values that are passed
        // into this component in a particular instance of a function remain
        // consistent and do not change from one render to the next.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...valuesThatShouldForceAReRender]);

    return null;
};

UpdateIncrementForceReRenderCount.propTypes = {
    dispatchIncrementForceReRenderCount: PropTypes.func.isRequired,
    dispatchSetHasCompletedPayPalInitialRender: PropTypes.func.isRequired,
    hasCompletedInitialRender: PropTypes.bool.isRequired,
    // We need to allow any value to be passed into this array if we want this
    // component to be reusable in multiple locations
    // eslint-disable-next-line react/forbid-prop-types
    valuesThatShouldForceAReRender: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default UpdateIncrementForceReRenderCount;
