import React from "react";
import ConnectedSubRestrictions from "./ConnectedSubRestrictions";

/**
 *
 * @description Contains markup that adds the necessary white space for this
 * implementation of the sub-restriction component.
 * @param {object} props
 * @param {number} props.restrictionId
 * @returns {React.ReactElement}
 */
const RestrictionCardSubRestrictions = ({ restrictionId }) => (
    <div className="-padding-left--15 -padding-right--15">
        {/* eslint-disable-next-line tailwindcss/classnames-order */}
        <div className="grid -padding-left--15">
            <div className="col col--12 -padding-top--0">
                <ConnectedSubRestrictions restrictionId={restrictionId} />
            </div>
        </div>
    </div>
);

export default RestrictionCardSubRestrictions;
