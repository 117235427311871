import React from "react";
import PropTypes from "prop-types";
import ConnectedSelectedRecipient from "./SelectedRecipient";
import ConnectedChooseRecipient from "./ChooseRecipient";

const DonationRecipientControl = ({
    controlId,
    controlRef,
    hasSelectedRecipient = false,
    showDonationRecipientControl = true,
    showCmsVersionOfSelectedRecipient = false,
}) => {
    if (showDonationRecipientControl) {
        return (
            <div
                ref={controlRef}
                className="col col--12 col--no-left-gutter -padding-top--30"
            >
                {hasSelectedRecipient ? (
                    <ConnectedSelectedRecipient controlId={controlId} />
                ) : (
                    <ConnectedChooseRecipient controlId={controlId} />
                )}
            </div>
        );
    }
    if (showCmsVersionOfSelectedRecipient) {
        return (
            <>
                <div className="selected-recipient -margin-bottom--15 -text--center">
                    <p>
                        If a donor gives via an eligible recipient&apos;s
                        fundraising page, we&apos;ll credit and display that
                        recipient.
                    </p>
                </div>
                <ConnectedSelectedRecipient controlId={controlId} />
            </>
        );
    }

    return null;
};

DonationRecipientControl.propTypes = {
    controlId: PropTypes.number.isRequired,
    controlRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    hasSelectedRecipient: PropTypes.bool,
    showCmsVersionOfSelectedRecipient: PropTypes.bool,
    showDonationRecipientControl: PropTypes.bool.isRequired,
};

export default DonationRecipientControl;
