import PropTypes from "prop-types";
import { useEffect } from "react";

// Necessary in order to be able to access the handleBeforeUnload() function
// so that the event handler can be run after the registrant is taken to the
// receipt page
let handleBeforeUnload;

const StartAnalyticsEvents = ({
    currentPage,
    dataLayerOptions,
    getClosedAnalyticsEvents,
    hasSubmittedForm,
    sendStartAnalyticsEvents,
    setHasSentStartAnalyticsEventsValue,
    shouldRunStartAnalyticsEvents,
}) => {
    useEffect(() => {
        // Send the start events before updating the flag that ensures we only
        // run the start analytics events once
        if (shouldRunStartAnalyticsEvents) {
            sendStartAnalyticsEvents(dataLayerOptions);
            setHasSentStartAnalyticsEventsValue();

            // Retrieves the registration closed analytics event and fires it
            handleBeforeUnload = () => {
                const sendClosedAnalyticsEvents =
                    getClosedAnalyticsEvents(dataLayerOptions);
                sendClosedAnalyticsEvents();
            };
            window.addEventListener("beforeunload", handleBeforeUnload, {});
        }

        // Remove handler to ensure the closed event doesn't fire when the
        // donor leaves the receipt page after submitting a registration
        if (hasSubmittedForm) {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    return null;
};

StartAnalyticsEvents.propTypes = {
    currentPage: PropTypes.number.isRequired,
    dataLayerOptions: PropTypes.shape({}).isRequired,
    getClosedAnalyticsEvents: PropTypes.func.isRequired,
    hasSubmittedForm: PropTypes.bool.isRequired,
    sendStartAnalyticsEvents: PropTypes.func.isRequired,
    setHasSentStartAnalyticsEventsValue: PropTypes.func.isRequired,
    shouldRunStartAnalyticsEvents: PropTypes.bool.isRequired,
};

export default StartAnalyticsEvents;
