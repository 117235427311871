import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { log } from "@qgiv/core-js";
import { submitDonationAPI } from "@qgiv/donation-form";
import { asyncSessionStorage } from "@qgiv/core-donor";

const SubmitDonation = ({
    preparedValues,
    setSubmitDonationFalse,
    setReceiptData,
    setCurrentPageValue,
    setDonorAccountData,
    setHasSubmittedFormValue,
    setSubmitting,
    setErrorsFromAPICall,
    sessionStorageKey,
    sessionStorageData,
}) => {
    const handleSuccess = useCallback(
        (response) => {
            // eslint-disable-next-line no-console
            log({ response });
            const {
                data: { errors, success },
            } = response;

            // Set `submitting` in Formik & Redux to false
            setSubmitting(false);
            setSubmitDonationFalse(false);

            if (success) {
                // eslint-disable-next-line no-console
                log({ success });
                // remove sessionStorage relating to this transaction
                // comment out below line for local testing so that data persists in sessionStorage
                asyncSessionStorage.remove(sessionStorageKey);
                // store transaction, receipt ID in redux

                const {
                    feeCoverage = 0,
                    optionalDonationAmount = 0,
                    total = 0,
                    transaction = {},
                    donorAccount = {},
                } = success;
                const { receiptId } = transaction;
                const receipt = {
                    feeCoverage,
                    optionalDonationAmount,
                    total,
                    transaction,
                };
                const receipts = {
                    [`${receiptId}`]: {
                        ...receipt,
                    },
                };
                setReceiptData(receiptId, receipts);
                setDonorAccountData(donorAccount);
                setHasSubmittedFormValue();
                setCurrentPageValue();

                // transition currentPage to Receipt Page
            }
            if (errors) {
                // eslint-disable-next-line no-console
                log({ errors });
            }
        },
        [
            sessionStorageKey,
            setCurrentPageValue,
            setDonorAccountData,
            setHasSubmittedFormValue,
            setReceiptData,
            setSubmitDonationFalse,
            setSubmitting,
        ],
    );

    const handleFailure = useCallback(
        (err) => {
            // Set `submitting` in Formik & Redux to false
            setSubmitting(false);
            setSubmitDonationFalse(false);
            // Handle response if unsuccessful
            setErrorsFromAPICall(err.message);
        },
        [setSubmitting, setSubmitDonationFalse, setErrorsFromAPICall],
    );

    const submitDonation = useCallback(
        (apiCallMounted) => {
            submitDonationAPI(preparedValues)
                .then((response) => {
                    if (apiCallMounted) {
                        handleSuccess(response);
                    }
                })
                .catch((err) => {
                    if (apiCallMounted) handleFailure(err);
                })
                .finally(() => {
                    setSubmitDonationFalse();
                });
        },
        [handleFailure, handleSuccess, preparedValues, setSubmitDonationFalse],
    );

    useEffect(() => {
        let apiCallMounted = true;
        // 1. store pieces of state needed to fill out the form in sessionStorage
        asyncSessionStorage
            .set(sessionStorageKey, sessionStorageData)
            .then(() => {
                // 2. submit transaction to BE
                submitDonation(apiCallMounted);
            })
            // need to call submitDonation in catch block
            // since this will throw an error if we don't have
            // access to session storage. This is the expected
            // behavior when in Incognito mode on embedded forms on external sites.
            .catch(() => {
                // 2. submit transaction to BE
                submitDonation(apiCallMounted);
            });
        // unsubscribe to api call when component dies
        return () => {
            apiCallMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

SubmitDonation.propTypes = {
    preparedValues: PropTypes.shape({}).isRequired,
    setSubmitDonationFalse: PropTypes.func.isRequired,
    setReceiptData: PropTypes.func.isRequired,
    setCurrentPageValue: PropTypes.func.isRequired,
    setDonorAccountData: PropTypes.func.isRequired,
    setHasSubmittedFormValue: PropTypes.func.isRequired,
    setSubmitting: PropTypes.func.isRequired,
    setErrorsFromAPICall: PropTypes.func.isRequired,
    sessionStorageData: PropTypes.shape({}),
    sessionStorageKey: PropTypes.string.isRequired,
};

export default SubmitDonation;
