import {
    getShouldBuildAddress,
    getShouldBuildAddress2,
    getShouldBuildCity,
    getShouldBuildCompany,
    getShouldBuildEmail,
    getShouldBuildFirstName,
    getShouldBuildLastName,
    getShouldBuildPhone,
    getShouldBuildState,
    getShouldBuildValuesFromPersonalInfo,
    getShouldBuildZip,
} from "../../urlShortcutsHelpers";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FieldSettings.SystemField} SystemField
 * @typedef {import("@qgiv/donation-form").ReduxTypes.UrlShortcuts.UrlShortcuts} UrlShortcuts
 */

// -------------------------------------------------------------------------
// TODO:
// 1. Ask Sandra about the custom fields functionality.
// -------------------------------------------------------------------------
/**
 * @description Only uses the URL shortcut to add a value if:
 * 1. The field is active in the Control Panel
 * 2. The field's display settings ensure it is visible on the current device.
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {object}
 */
const getValuesFromPersonalInfo = (settings) => {
    const { urlShortcuts } = settings;
    const { personalInfo = {} } = urlShortcuts;
    const {
        address1 = "",
        address2 = "",
        city = "",
        company = "",
        email = "",
        first_name = "",
        last_name = "",
        phone = 0,
        state = "",
        zip = 0,
    } = personalInfo;
    let values = {};

    // Generate flags
    const shouldBuildAddress = getShouldBuildAddress(settings);
    const shouldBuildAddress2 = getShouldBuildAddress2(settings);
    const shouldBuildCity = getShouldBuildCity(settings);
    const shouldBuildCompany = getShouldBuildCompany(settings);
    const shouldBuildEmail = getShouldBuildEmail(settings);
    const shouldBuildFirstName = getShouldBuildFirstName(settings);
    const shouldBuildLastName = getShouldBuildLastName(settings);
    const shouldBuildPhone = getShouldBuildPhone(settings);
    const shouldBuildState = getShouldBuildState(settings);
    const shouldBuildZip = getShouldBuildZip(settings);

    // Build values
    if (shouldBuildAddress) {
        values = {
            ...values,
            Address: address1,
        };
    }

    if (shouldBuildAddress2) {
        values = {
            ...values,
            Address_2: address2,
        };
    }

    if (shouldBuildCity) {
        values = {
            ...values,
            City: city,
        };
    }

    if (shouldBuildCompany) {
        values = {
            ...values,
            Company: company,
        };
    }

    if (shouldBuildEmail) {
        values = {
            ...values,
            Email: email,
        };
    }

    if (shouldBuildFirstName) {
        values = {
            ...values,
            First_Name: first_name,
        };
    }

    if (shouldBuildLastName) {
        values = {
            ...values,
            Last_Name: last_name,
        };
    }

    // Ensure that this is a string because if the field gets handed a number
    // the FE will crash
    if (shouldBuildPhone) {
        values = {
            ...values,
            Phone: String(phone),
        };
    }

    if (shouldBuildState) {
        values = {
            ...values,
            State: state,
        };
    }

    // Ensure that this is a string because if the field gets handed a number
    // the FE will crash
    if (shouldBuildZip) {
        values = {
            ...values,
            Zip: String(zip),
        };
    }

    return values;
};

/**
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {SystemField[]} settings.systemFields
 * @param {UrlShortcuts} settings.urlShortcuts
 * @returns {object}
 */
export const getUrlShortcutsInitialValues = (settings) => {
    const { currentDisplay, systemFields, urlShortcuts } = settings;
    const shouldBuildValuesFromPersonalInfo =
        getShouldBuildValuesFromPersonalInfo({ urlShortcuts });

    let values = {};

    // Conditional logic that is set up to enable us to easily load custom
    // field data from URL shortcuts in the future
    if (shouldBuildValuesFromPersonalInfo) {
        values = {
            ...getValuesFromPersonalInfo({
                currentDisplay,
                systemFields,
                urlShortcuts,
            }),
        };
    }

    return values;
};
