import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./AnonymityCallout.scss";

const AnonymityCallout = ({
    displayAnonymityCallout = false,
    anonymousCallOut = "We won't show your name publicly",
}) =>
    displayAnonymityCallout ? (
        <div className="col col--12">
            <div className="anonymity-callout">
                <p>
                    <Icon
                        glyph="eye-slash-solid"
                        type="FontAwesome"
                        classNames={["-margin-right--10"]}
                    />
                    {anonymousCallOut}
                </p>
            </div>
        </div>
    ) : null;

AnonymityCallout.propTypes = {
    displayAnonymityCallout: PropTypes.bool.isRequired,
    anonymousCallOut: PropTypes.string.isRequired,
};

export default AnonymityCallout;
