import React from "react";
import PropTypes from "prop-types";
import { constants, translatePaymentType } from "@qgiv/core-js";

const ReceiptPaymentDetails = ({
    paymentDetailsLabel,
    endingInText,
    ccType,
    ccLastFour,
    ccName,
    bill_address,
    bill_city,
    bill_state,
    bill_zip,
    bill_country,
    status,
    email,
    channel,
}) => {
    const { PaymentTypes } = constants.ENUMS;
    const paymentMethodName = translatePaymentType(ccType, channel);
    // When a user submits a transaction via PayPal they do so by logging into
    // an account using their email address and not by entering a long uniquely
    // identifiable value such as a credit card or account number
    const displayFormattedEndingInText =
        ccType === PaymentTypes.PAYPAL ? false : true;
    const formattedEndingInText = (
        <>
            {" "}
            {endingInText}
            <strong>
                {/* Dot dot */}
                {"\u00B7\u00B7"}
            </strong>
            {ccLastFour.substring(2)}
        </>
    );

    return (
        <div className="col col--12 col--sm-5 -margin-top--15">
            <section>
                <h3>{paymentDetailsLabel}</h3>
                <p className="-type--small -margin-top--15">
                    {status === "PR" ? (
                        <>
                            Invoice sent to: <br />
                            <a
                                className="-color--theme-primary"
                                href={`mailto:${email}`}
                            >
                                <strong>{email}</strong>
                            </a>
                            <br />
                        </>
                    ) : (
                        <>
                            {paymentMethodName}
                            {displayFormattedEndingInText &&
                                formattedEndingInText}
                            <br />
                            {ccName}
                            {ccName.trim().length > 0 && <br />}
                            {bill_address}
                            {bill_address && <br />}
                            {bill_city}
                            {bill_city && (bill_state || bill_zip) && ","}{" "}
                            {bill_state} {bill_zip}
                            {(bill_city || bill_state || bill_zip) && <br />}
                            {bill_country}
                        </>
                    )}
                </p>
            </section>
        </div>
    );
};

ReceiptPaymentDetails.propTypes = {
    paymentDetailsLabel: PropTypes.string,
    endingInText: PropTypes.string,
    ccType: PropTypes.string,
    ccLastFour: PropTypes.string,
    ccName: PropTypes.string,
    bill_address: PropTypes.string,
    bill_city: PropTypes.string,
    bill_state: PropTypes.string,
    bill_zip: PropTypes.string,
    bill_country: PropTypes.string,
    status: PropTypes.string,
    email: PropTypes.string,
    channel: PropTypes.string,
};

export default ReceiptPaymentDetails;
