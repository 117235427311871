import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { constants } from "@qgiv/core-js";
import {
    selectCurrentPage,
    selectFormStatusType,
    selectIsSinglePageForm,
    selectShouldScrollToTop,
    setShouldScrollToTop,
} from "../../../redux/slices/appSettingsSlice";
import {
    getResizeMessageToSend,
    scrollTop,
} from "../../PostMessage/postMessage";
import DonationFormClosedPage from "../../Pages/DonationFormClosedPage/DonationFormClosedPage";
import ConnectedUpdateFormDataFromSessionStorage from "../../ConnectedUpdateFormDataFromSessionStorage";
import ConnectedAnalyticsEvents from "../../DonationFormSharedContent/AnalyticsEvents";
import ConnectedUpdateShouldDisplayApplePay from "../../DonationFormSharedContent/ConnectedUpdateShouldDisplayApplePay";
import ConnectedDonationFormPageSwitch from "../../DonationFormSharedContent/ConnectedDonationFormPageSwitch";
import ConnectedDonationFormSinglePageSwitch from "../../DonationFormSharedContent/ConnectedDonationFormSinglePageSwitch";

const DonationFormEmbedContent = () => {
    const {
        ENUMS: { DonationStatusType },
    } = constants;
    const ref = useRef();
    const dispatch = useDispatch();
    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const currentPage = useSelector(selectCurrentPage);
    const formStatusType = useSelector(selectFormStatusType);
    const shouldScrollToTop = useSelector(selectShouldScrollToTop);

    useEffect(() => {
        const stats = ref?.current?.getBoundingClientRect();
        if (stats !== undefined) {
            const { height, top } = stats;
            const total = height + top;
            getResizeMessageToSend(total);
        }
    }, [currentPage]);

    // shouldScrollToTop in appSettings can be used to trigger a scroll to top
    // from anywhere in the app
    useEffect(() => {
        if (shouldScrollToTop) {
            scrollTop();
            dispatch(setShouldScrollToTop(false));
        }
    }, [dispatch, shouldScrollToTop]);

    const isDonationFormClosed =
        formStatusType !== DonationStatusType.DONATIONS_AVAILABLE;

    return (
        <main
            className={cx(
                "qg-vendor-page__content donation-form-content__embed-view",
            )}
            ref={ref}
        >
            {/* if Form State is Closed load Closed Component else load below */}
            {isDonationFormClosed ? (
                <DonationFormClosedPage />
            ) : (
                <>
                    {isSinglePageForm ? (
                        <ConnectedDonationFormSinglePageSwitch />
                    ) : (
                        <ConnectedDonationFormPageSwitch />
                    )}

                    {/* <ConnectedUpdateCmsSettings /> */}

                    {/* Used for DataDome rerender */}
                    <ConnectedUpdateFormDataFromSessionStorage />
                    <ConnectedAnalyticsEvents />
                    <ConnectedUpdateShouldDisplayApplePay />
                </>
            )}
        </main>
    );
};

export default DonationFormEmbedContent;
