import { createSlice } from "@reduxjs/toolkit";
import { urlShortcuts as initialState } from "../initialState";

const getUrlShortcutsInitialState = () => initialState;
export const urlShortcutsInitialState = getUrlShortcutsInitialState();

const slice = createSlice({
    name: "urlShortcuts",
    initialState: urlShortcutsInitialState,
    reducers: {},
});

// selectors
export const selectUrlShortcuts = (state) => state.urlShortcuts;

export const selectPreventRefreshOnClose = (state) =>
    state.urlShortcuts.preventRefreshOnClose;

export const selectAssocInfoUrlShortcuts = (state) => {
    if (state?.urlShortcuts?.info) {
        return state.urlShortcuts.info;
    }
    return "";
};

export default slice.reducer;
