import React, { useEffect, useState } from "react";
import { ErrorBoundary, SimpleErrorDisplay } from "@qgiv/core-react";
import { submitErrorData } from "@qgiv/core-donor";
import DonationFormApp from "./DonationFormApp";
import DonationFormLoading from "./DonationFormSharedContent/DonationFormLoading";

const ConnectedDonationFormApp = () => {
    const [hasEnumsLoaded, setHasEnumsLoaded] = useState(false);

    useEffect(() => {
        setHasEnumsLoaded(true);
    }, []);

    return (
        <ErrorBoundary
            ErrorComponent={
                <SimpleErrorDisplay submitErrorData={submitErrorData} />
            }
        >
            {hasEnumsLoaded ? (
                <div className="donation-form-container">
                    <DonationFormApp hasEnumsLoaded={hasEnumsLoaded} />
                </div>
            ) : (
                <DonationFormLoading active />
            )}
        </ErrorBoundary>
    );
};

export default ConnectedDonationFormApp;
