import React from "react";
import PropTypes from "prop-types";

import "./DonationFormBaseContent.scss";

const DonationFormBaseContent = ({ children }) => (
    <div className="qg-vendor-form donation-form-base-content">{children}</div>
);

DonationFormBaseContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default DonationFormBaseContent;
