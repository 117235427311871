import React from "react";
import PropTypes from "prop-types";
import "./HelpText.scss";

const HelpText = ({ helpText = "" }) => (
    <div className="help-text">
        <small>{helpText}</small>
    </div>
);

HelpText.propTypes = {
    helpText: PropTypes.string,
};

export default HelpText;
