import React, { useCallback } from "react";
import SinglePage from "./SinglePage";
import useSinglePageData from "../PageDataHooks/useSinglePageData";
import {
    getInitialTouched,
    getInitialValues,
    getValidationSchema,
} from "./formik";
import ConnectedRecurringUpgrade from "../Pages/PaymentPage/RecurringUpgrade";
import { ModalContentReplacerEnums } from "../common/ModalContentReplacer";

const ConnectedSinglePage = () => {
    const singlePageData = useSinglePageData();

    const {
        conditionalLogicOptions,
        displayCaptcha,
        documentTitle,
        formikSettings,
        handleUpdateSubmitDonation,
        hasActiveFulfillment,
        hasDisplayableDedicationOnAdditionalDetailsPage,
        hasDisplayableMatchingGiftOnAdditionalDetailsPage,
        selectedContentToDisplay,
        shouldDisplayRecurringUpgrade,
        storedInitialValuesFromRedux,
        submitDonation,
    } = singlePageData;

    // -------------------------------------------------------------------------
    // Note: Recipient search is not used in Single Page currently however may
    // be needed in the future if P2P has a single page option.
    // -------------------------------------------------------------------------
    const getAlternateContent = useCallback(() => {
        const alternateContent = [];
        if (shouldDisplayRecurringUpgrade) {
            alternateContent.push({
                component: <ConnectedRecurringUpgrade />,
                type: ModalContentReplacerEnums.UPGRADE_RECURRING_UI,
            });
        }
        return alternateContent;
    }, [shouldDisplayRecurringUpgrade]);

    const alternateContent = getAlternateContent();

    const onSubmit = (values) => {
        const { Payment_Type } = values;
        handleUpdateSubmitDonation(Payment_Type);
    };

    const {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    } = formikSettings;

    // Move this to connected like Gift Page hook
    const formikProps = {
        initialTouched: getInitialTouched(
            hasActiveFulfillment,
            initialTouchedSettings,
            storedInitialValuesFromRedux,
        ),
        initialValues: getInitialValues(
            hasActiveFulfillment,
            initialValuesSettings,
            storedInitialValuesFromRedux,
        ),
        validationSchema: getValidationSchema(
            hasActiveFulfillment,
            validationSettings,
        ),
        onSubmit,
    };

    const skipAdditionalDetailsPageProps = {
        conditionalLogicOptions,
        hasActiveFulfillment,
        hasDisplayableDedicationOnAdditionalDetailsPage,
        hasDisplayableMatchingGiftOnAdditionalDetailsPage,
    };

    const singlePageProps = {
        alternateContent,
        displayCaptcha,
        documentTitle,
        formikProps,
        selectedContentToDisplay,
        skipAdditionalDetailsPageProps,
        submitDonation,
    };

    return <SinglePage {...singlePageProps} />;
};

export default ConnectedSinglePage;
