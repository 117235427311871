import React from "react";
import { constants } from "@qgiv/core-js";
import { useSelector } from "react-redux";
import {
    selectCurrentPage,
    selectFormStatusType,
    selectHasSubmittedForm,
} from "../../../redux/slices/appSettingsSlice";
import AnalyticsEvents from "./AnalyticsEvents";

const ConnectedAnalyticsEvents = () => {
    const {
        ENUMS: { CmsPageStandardizer, DonationStatusType },
    } = constants;

    // Get data from state
    const currentPage = useSelector(selectCurrentPage);
    const formStatusType = useSelector(selectFormStatusType);
    const hasSubmittedForm = useSelector(selectHasSubmittedForm);

    // Convert data into keys that are needed
    const isDonationFormOpen =
        formStatusType === DonationStatusType.DONATIONS_AVAILABLE;
    const isReceiptPageViewedDirectly =
        !hasSubmittedForm && currentPage === CmsPageStandardizer.CONFIRMATION;
    const shouldRunAnalyticsEvents =
        isDonationFormOpen && !isReceiptPageViewedDirectly;

    const props = {
        shouldRunAnalyticsEvents,
    };

    return <AnalyticsEvents {...props} />;
};

export default ConnectedAnalyticsEvents;
