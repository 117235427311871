import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ModalContentReplacerEnums from "./ModalContentReplacerEnums";
import "./ModalContentReplacer.scss";

const ModalContentReplacer = ({
    children,
    alternateContent = [],
    selectedContentToDisplay,
}) => {
    const componentClassName = (componentType) =>
        componentType === selectedContentToDisplay
            ? "modal-content-replacer--visible"
            : "modal-content-replacer--hidden";

    // testing animations, these will not be permanent
    const animationTypeMap = {
        0: "fade-in",
        1: "scale-me",
        2: "fade-in-translate-rotate",
        3: "twirling-right",
        4: "clip-path-fun",
        5: "clip-path-swipe",
    };
    const animationType = 0;

    // Need to place class of initial on default content when loading component
    // for the first time. This will prevent animations from being added on first load.
    const childrenContainerRef = useRef();

    useEffect(() => {
        if (childrenContainerRef.current) {
            childrenContainerRef.current.className =
                "modal-content-replacer modal-content-replacer--visible initial";
        }
    }, []);

    return (
        <div className="modal-content-replacer-container">
            <div
                ref={childrenContainerRef}
                className={`modal-content-replacer ${componentClassName(
                    ModalContentReplacerEnums.DEFAULT_CONTENT,
                )} ${animationTypeMap[animationType]}`}
            >
                {children}
            </div>
            {alternateContent.map((alternateComponent) => {
                const RenderComponent = alternateComponent.component;
                return (
                    <div
                        key={alternateComponent.type}
                        className={`modal-content-replacer ${componentClassName(
                            alternateComponent.type,
                        )} ${animationTypeMap[animationType]}`}
                    >
                        {RenderComponent}
                    </div>
                );
            })}
        </div>
    );
};

ModalContentReplacer.propTypes = {
    children: PropTypes.node.isRequired,
    alternateContent: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.number,
            component: PropTypes.node,
        }),
    ),
    selectedContentToDisplay: PropTypes.number.isRequired,
};

export default ModalContentReplacer;
