import { constants } from "@qgiv/core-js";
import { getFieldIsVisibleBasedOnConditionals } from "@qgiv/core-donor";
import { getHasSelectedOtherAmount } from "../amountHelpers";
import { filterDonationDetailsValuesByType } from "../fieldHelpers";
import { getMultiRestrictionFieldName } from "../multiRestrictionHelpers";
import { getActiveDisplayableRestrictions } from "../standardRestrictionsHelpers";

/**
 * @typedef {import("@qgiv/core-donor").FieldType} FieldType
 * @typedef {import("@qgiv/core-donor").FieldGroupType} FieldGroupType
 * @typedef {import("@qgiv/core-donor").ConditionType} ConditionType
 */

const {
    ENUMS: {
        FieldType: { HIDDEN_FIELD },
        ProductType,
    },
} = constants;

export const smartAmountGiftOneTimeDataExtractor = (values, activeAmounts) => {
    const { Selected_One_Time_Id, Other_One_Time_Amount } = values;
    // BE needs the Recurring_Frequency value to be sent for one time gifts as "n";
    const hasSelectedOtherAmount = getHasSelectedOtherAmount({
        activeAmounts,
        values,
        fieldName: "Selected_One_Time_Id",
    });

    const smartAmountSelected = !hasSelectedOtherAmount
        ? activeAmounts.find((amount) =>
              Number(Selected_One_Time_Id === Number(amount.id)),
          )
        : {};

    const Smart_Amount = smartAmountSelected?.amount || 0;
    const Smart_Amount_Other = Other_One_Time_Amount;

    const smartAmountValues = {
        ...(!hasSelectedOtherAmount && Smart_Amount && { Smart_Amount }),
        ...(hasSelectedOtherAmount &&
            Smart_Amount_Other && { Smart_Amount_Other }),
    };

    const Recurring_Frequency = "n";

    return { ...smartAmountValues, Recurring_Frequency };
};

export const smartAmountGiftRecurringDataExtractor = (
    values,
    activeAmounts,
) => {
    const {
        Selected_Recurring_Id,
        Other_Recurring_Amount,
        Recurring_Frequency,
        Start_Date,
        End_Date,
    } = values;

    const hasSelectedOtherAmount = getHasSelectedOtherAmount({
        activeAmounts,
        values,
        fieldName: "Selected_Recurring_Id",
    });

    const smartAmountSelected = !hasSelectedOtherAmount
        ? activeAmounts.find((amount) =>
              Number(Selected_Recurring_Id === Number(amount.id)),
          )
        : {};

    const Smart_Amount = smartAmountSelected?.amount || 0;
    const Smart_Amount_Other = Other_Recurring_Amount;

    const smartAmountValues = {
        ...(!hasSelectedOtherAmount && Smart_Amount && { Smart_Amount }),
        ...(hasSelectedOtherAmount &&
            Smart_Amount_Other && { Smart_Amount_Other }),
    };

    return {
        ...smartAmountValues,
        Recurring_Frequency,
        Start_Date,
        End_Date,
    };
};

export const smartAmountGiftDataExtractor = ({
    values,
    activeAmounts,
    isOneTimeDonation,
    isRecurringDonationOrCreateYourOwnPlan,
}) => {
    if (isOneTimeDonation) {
        return smartAmountGiftOneTimeDataExtractor(values, activeAmounts);
    }
    if (isRecurringDonationOrCreateYourOwnPlan) {
        return smartAmountGiftRecurringDataExtractor(values, activeAmounts);
    }
    return {};
};

export const oneTimeGiftDataExtractor = (values, hasActiveFulfillment) => {
    const { Selected_One_Time_Id, Other_One_Time_Amount } = values;
    // BE needs the Recurring_Frequency value to be sent for one time gifts as "n";

    const Recurring_Frequency = "n";
    return !hasActiveFulfillment
        ? { Selected_One_Time_Id, Other_One_Time_Amount, Recurring_Frequency }
        : {};
};

export const recurringGiftDataExtractor = (values) => {
    const {
        Selected_Recurring_Id,
        Other_Recurring_Amount,
        Recurring_Frequency,
        Start_Date,
        End_Date,
    } = values;

    return {
        Selected_Recurring_Id,
        Other_Recurring_Amount,
        Recurring_Frequency,
        Start_Date,
        End_Date,
    };
};

/**
 * @function givingPlanGiftDataExtractor
 * @param {object} values - The formik values object.
 * @returns {object} The extracted data for a giving plan gift.
 */
export const givingPlanGiftDataExtractor = (values) => {
    const {
        Selected_Plan,
        Start_Date,
        End_Date,
        Recurring_Frequency,
        hasSelectedRecurringDonation,
        Selected_Recurring_Id,
    } = values;

    const isGivingPlanDonation =
        hasSelectedRecurringDonation && Selected_Plan && !Selected_Recurring_Id;

    const isFlexibleCreateYourOwnPlan =
        hasSelectedRecurringDonation && Selected_Plan && Selected_Recurring_Id;

    if (isGivingPlanDonation || isFlexibleCreateYourOwnPlan) {
        return {
            Selected_Plan,
            Start_Date,
            End_Date,
            Recurring_Frequency,
        };
    }
    return {};
};

/**
 * @function
 * @param {object} values - The formik values object.
 * @param {number} values.Invoice_Amount_Paid - The amount paid for the invoice.
 * @param {boolean} values.Invoice_Complete_Fulfillment - The status indicating if the invoice is completely fulfilled.
 * @param {boolean} values.Invoice_Fulfill_Outstanding - The status indicating if the outstanding invoice is fulfilled.
 * @param {string} values.Invoice_Token - The token associated with the invoice.
 * @returns {object} The extracted invoice fulfillment gift data.
 * @property {number} Invoice_Amount_Paid - The amount paid for the invoice.
 * @property {boolean} Invoice_Complete_Fulfillment - The status indicating if the invoice is completely fulfilled.
 * @property {boolean} Invoice_Fulfill_Outstanding - The status indicating if the outstanding invoice is fulfilled.
 * @property {string} Invoice_Token - The token associated with the invoice.
 */
export const invoiceFulfillmentGiftDataExtractor = (values) => {
    const {
        Invoice_Amount_Paid,
        Invoice_Complete_Fulfillment,
        Invoice_Fulfill_Outstanding,
        Invoice_Token,
    } = values;
    return {
        Invoice_Amount_Paid,
        Invoice_Complete_Fulfillment,
        Invoice_Fulfill_Outstanding,
        Invoice_Token,
    };
};

export const recurringGiftAcceptedUpgradeDataExtractor = (values) => {
    const { Other_Recurring_Amount, Recurring_Frequency, Start_Date } = values;

    return {
        Has_Accepted_Recurring_Upgrade: true,
        Other_Recurring_Amount,
        Recurring_Frequency,
        Start_Date,
    };
};

const multiRestrictionDataExtractor = ({
    multiRestrictionFieldValue,
    valuesToUse,
    id,
}) => {
    const {
        End_Date,
        hasAcceptedRecurringUpgrade,
        hasSelectedRecurringDonation,
        Recurring_Frequency,
        Start_Date,
    } = valuesToUse;
    const isOneTimeDonation = !hasSelectedRecurringDonation;
    const { Sub_Restriction } = multiRestrictionFieldValue;
    const restrictionId = id;
    const subRestriction = Sub_Restriction;

    // It is very unfortunate that we are not being consistent our naming
    // convention here but we are camelCasing our restriction data elsewhere in
    // this file so I decided to leave it as is
    let multiRestrictionAmountData = {
        restrictionId,
        subRestriction,
    };

    // Need to extract all of the data that is important to ongoing
    // donations that is not in the multi-restriction field value if the
    // donation is ongoing
    if (hasAcceptedRecurringUpgrade) {
        multiRestrictionAmountData = {
            ...multiRestrictionAmountData,
            ...recurringGiftAcceptedUpgradeDataExtractor({
                ...multiRestrictionFieldValue,
                Recurring_Frequency,
                Start_Date,
            }),
        };
    } else if (isOneTimeDonation) {
        multiRestrictionAmountData = {
            ...multiRestrictionAmountData,
            ...oneTimeGiftDataExtractor({
                ...multiRestrictionFieldValue,
            }),
        };
    } else if (multiRestrictionFieldValue.Checked) {
        multiRestrictionAmountData = {
            ...multiRestrictionAmountData,
            ...recurringGiftDataExtractor({
                ...multiRestrictionFieldValue,
                End_Date,
                Recurring_Frequency,
                Start_Date,
            }),
        };
    }

    return multiRestrictionAmountData;
};

export const multiRestrictionsDataExtractor = ({
    currentDisplay,
    restrictions,
    valuesToUse,
}) => {
    const multiRestrictionAmountsData = [];
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );

    activeDisplayableRestrictions.forEach((activeDisplayableRestriction) => {
        const { id = 0 } = activeDisplayableRestriction;
        const multiRestrictionFieldName = getMultiRestrictionFieldName(id);
        const multiRestrictionFieldValue =
            valuesToUse[multiRestrictionFieldName];

        if (multiRestrictionFieldValue.Checked) {
            const multiRestrictionAmountData = multiRestrictionDataExtractor({
                id,
                multiRestrictionFieldValue,
                valuesToUse,
            });
            multiRestrictionAmountsData.push(multiRestrictionAmountData);
        }
    });

    return multiRestrictionAmountsData;
};

export const giftDataExtractor = (
    values,
    isUsingSmartAmounts,
    activeAmounts,
    hasActiveFulfillment,
) => {
    const {
        Selected_Recurring_Id,
        hasAcceptedRecurringUpgrade = false,
        hasSelectedRecurringDonation,
    } = values;
    const isOneTimeDonation = !hasSelectedRecurringDonation;
    const isRecurringDonationOrCreateYourOwnPlan =
        hasSelectedRecurringDonation && Selected_Recurring_Id;

    if (hasAcceptedRecurringUpgrade) {
        return recurringGiftAcceptedUpgradeDataExtractor(values);
    }

    if (isUsingSmartAmounts) {
        return smartAmountGiftDataExtractor({
            values,
            activeAmounts,
            isOneTimeDonation,
            isRecurringDonationOrCreateYourOwnPlan,
        });
    }

    if (isRecurringDonationOrCreateYourOwnPlan) {
        return { ...recurringGiftDataExtractor(values) };
    }

    if (isOneTimeDonation) {
        return { ...oneTimeGiftDataExtractor(values, hasActiveFulfillment) };
    }

    return {};
};

export const creditCardPaymentTypeExtractor = (values) => {
    const { Card_Number, Card_Exp_Date, Card_CVV, Payment_Type } = values;
    return { Card_Number, Card_Exp_Date, Card_CVV, Payment_Type };
};

export const bankPaymentTypeExtractor = (values) => {
    const { Account_Number, Routing_Number, Payment_Type } = values;
    return { Account_Number, Routing_Number, Payment_Type };
};

export const applePayPaymentTypeExtractor = (values) => {
    const { Apple_Pay_Token, Payment_Type } = values;
    return { Apple_Pay_Token, Payment_Type };
};

export const paypalPaymentTypeExtractor = (values) => {
    const {
        PayPal_Token,
        PayPal_Payer_ID,
        PayPal_Agreement_ID,
        PayPal_Is_Venmo,
        Payment_Type,
    } = values;
    return {
        PayPal_Token,
        PayPal_Payer_ID,
        PayPal_Agreement_ID,
        PayPal_Is_Venmo,
        Payment_Type,
    };
};

export const storedPaymentTypeExtractor = (values) => {
    const { Stored_Payment_ID } = values;
    return { Stored_Payment_ID };
};

export const paymentTypeExtractor = (values, PaymentTypeEnum) => {
    const { Payment_Type, Billing_Name, Save_Payment } = values;
    let paymentTypeValues = {};
    switch (Number(Payment_Type)) {
        case PaymentTypeEnum.STORED:
            paymentTypeValues = storedPaymentTypeExtractor(values);
            break;
        case PaymentTypeEnum.CREDITCARD:
            paymentTypeValues = creditCardPaymentTypeExtractor(values);
            break;
        case PaymentTypeEnum.ECHECK:
            paymentTypeValues = bankPaymentTypeExtractor(values);
            break;
        case PaymentTypeEnum.PAYPAL:
            paymentTypeValues = paypalPaymentTypeExtractor(values);
            break;
        case PaymentTypeEnum.APPLEPAY:
            paymentTypeValues = applePayPaymentTypeExtractor(values);
            break;
        default:
            paymentTypeValues = values;
            break;
    }
    const returnPaymentData = {
        Payment_Type,
        ...paymentTypeValues,
        ...(Billing_Name && { Billing_Name }),
        ...(Save_Payment && { Save_Payment }),
    };
    return returnPaymentData;
};

export const billingDataExtractor = (values) => {
    const {
        Billing_Address,
        Billing_Address_2,
        Billing_City,
        Billing_State,
        Billing_Zip,
        Billing_Country,
        Billing_Address_Use_Mailing,
    } = values;
    return {
        Billing_Address,
        Billing_Address_2,
        Billing_City,
        Billing_State,
        Billing_Zip,
        Billing_Country,
        Billing_Address_Use_Mailing,
    };
};

export const matchingDataExtractor = (values) => {
    const matchingValues = filterDonationDetailsValuesByType(
        "matchingGift",
        values,
    );
    return matchingValues;
};

export const donorAccountActionsDataExtractor = (values) => {
    const {
        Create_Account_Password,
        Password,
        Password_Confirm,
        Donor_Account_Id,
    } = values;

    const returnDonorAccountActionsData = {
        ...(Create_Account_Password && { Password, Password_Confirm }),
        ...(Donor_Account_Id && { Donor_Account_Id }),
    };

    return returnDonorAccountActionsData;
};

export const giftAssistExtractor = (values, giftAssistFee) => {
    const { Gift_Assist } = values;

    const returnGiftAssistData = {
        ...(Gift_Assist &&
            giftAssistFee > 0 && {
                donorIsCoveringFees: Gift_Assist,
                feeCoverage: giftAssistFee,
            }),
    };
    return returnGiftAssistData;
};

export const donorDetailsDataExtractor = (values) => {
    const { Address, Address_2, City, State, Zip, Country } = values;
    const mailingAddressInformation = {
        Address,
        Address_2,
        City,
        State,
        Zip,
        Country,
    };

    return {
        First_Name: values.First_Name,
        Last_Name: values.Last_Name,
        Email: values.Email,
        // ...(shouldSendMailingAddressInformation && {
        //     ...mailingAddressInformation,
        // }),
        ...mailingAddressInformation,
        ...(values.Donor_Account_Id && {
            Donor_Account_Id: values.Donor_Account_Id,
        }),
        ...(values.Phone && {
            Phone: values.Phone,
        }),
        // we need to exclude titles when making company donation
        ...(values.Title &&
            !values.Company_Donation && {
                Title: values.Title,
            }),
        ...(values.Suffix && { Suffix: values.Suffix }),
        ...(values.Legal && {
            Legal: values.Legal,
        }),
        ...(values.Employer && {
            Employer: values.Employer,
        }),
        ...(values.Company &&
            values.Company_Donation && {
                Company: values.Company,
            }),
        ...(values.Company_Donation && {
            Company_Donation: values.Company_Donation,
        }),
        ...(values.Opt_In && {
            Opt_In: values.Opt_In,
        }),
    };
};

/**
 * @description Because we have not brought the privacy options field group to
 * the year round form we need to change the data that we are sending based on
 * product type. In addition to this the database values for the privacy
 * options field group is stored as showAmount and showName while the label
 * reads the opposite (Do not show my name publicly). Because we decided to
 * make the name of the value in Formik align with the name of the label we
 * need to invert the values (from "Do not show" to "Show") before we can send
 * these values to the BE.
 * @param {number} type
 * @param {object} values
 * @returns {object}
 */
export const privacyOptionsDataExtractor = (type, values) => {
    const { Anonymity, Do_Not_Show_Amount, Do_Not_Show_Name } = values;

    // Unlike the privacy options field group the anonymous field can be turned
    // on or off on a form and we want to force this value into a boolean before
    // it is sent to the BE.
    if (type === ProductType.QGIV) {
        return {
            Anonymity: !!Anonymity,
        };
    }

    return {
        Show_Amount: !Do_Not_Show_Amount,
        Show_Name: !Do_Not_Show_Name,
    };
};

/**
 * @typedef GetCurrentFieldVisibilityFlagsReturnType
 * @property {ConditionType|{}} condition either Condition or empty object
 * @property {boolean} hasCondition evaluation if the Condition is valid
 * @property {number} fieldId id of the currentField
 * @property {*} fieldValue value from values for currentField
 */

/**
 * @function getCurrentFieldVisibilityFlags
 * @description helper function to get needed flags to use in determining a field's visibility
 * @param {FieldType} currentField field to be evaluated for visibility
 * @param {object} values values object representing saved custom fields values
 * @returns {GetCurrentFieldVisibilityFlagsReturnType} object with necessary flags
 */
export const getCurrentFieldVisibilityFlags = (currentField, values) => {
    const condition = currentField?.conditions?.[0] || {};
    const hasCondition = Object.keys(condition).length > 0;
    const fieldId = currentField.id;
    const fieldValue = values[fieldId];
    return {
        condition,
        hasCondition,
        fieldId,
        fieldValue,
    };
};

export const customFieldsDataExtractor = ({
    allFieldsAndGroups,
    conditionalLogicOptions,
    customFields,
    values,
}) => {
    // values will contain key-value pairs for custom fields
    // we do not have to change the keys for submission
    // but we do have to make sure that the fields should be submitted
    // based on conditional logic/display settings. Display settings
    // should be handled before we reach this step, but conditional logic
    // may have changed since when the field was stored on redux
    const valuesToSubmit = customFields
        // create object with key-value fieldId: true for easy exists lookup
        .reduce((visibleFieldsValueObject, currentField) => {
            const { condition, hasCondition, fieldId, fieldValue } =
                getCurrentFieldVisibilityFlags(currentField, values);

            // Hidden fields might be on a skipped additional details page
            // so it's important that we add the hidden_value from the field
            // on submission as well.
            const isHiddenFieldType = currentField?.type === HIDDEN_FIELD;
            const isVisible = hasCondition
                ? getFieldIsVisibleBasedOnConditionals({
                      condition,
                      allFieldsAndGroups,
                      options: conditionalLogicOptions,
                      values,
                  })
                : true;

            if (isVisible) {
                return {
                    ...visibleFieldsValueObject,
                    [fieldId]: isHiddenFieldType
                        ? currentField?.hidden_value
                        : fieldValue,
                };
            }

            return visibleFieldsValueObject;
        }, {});
    return valuesToSubmit;
};

export const standardRestrictionExtractor = (values) => {
    const {
        Standard_Restriction_Id,
        Standard_Restriction_Name,
        Standard_Restriction_Sub_Restriction,
    } = values;

    const returnStandardRestrictionData = {
        ...(Standard_Restriction_Id && {
            restrictionId: Standard_Restriction_Id,
            restriction: Standard_Restriction_Name,
        }),
        ...(Standard_Restriction_Sub_Restriction && {
            subRestriction: Standard_Restriction_Sub_Restriction,
        }),
    };

    return returnStandardRestrictionData;
};

export const dedicationDataExtractor = (values) => {
    const {
        Has_Dedication,
        Dedication_Type,
        Dedication_Name,
        Dedication_Email,
        Dedication_Message,
    } = values;

    const valueKeys = Object.keys(values);
    const dedicationFieldValueKeys = valueKeys.filter((key) =>
        key.includes("Dedication_Field"),
    );
    const dedicationFieldValues = dedicationFieldValueKeys.reduce(
        (dedicationFieldsObject, currentDedicationFieldKey) => {
            const currentDedicationFieldId = currentDedicationFieldKey.replace(
                "Dedication_Field_",
                "",
            );
            const fieldObj = {
                ...dedicationFieldsObject,
                [currentDedicationFieldId]: values[currentDedicationFieldKey],
            };
            return fieldObj;
        },
        {},
    );

    const dedicationData = {
        ...(Has_Dedication && {
            Has_Dedication,
            Dedication_Type,
            Dedication_Name,
            Dedication_Email,
            Dedication_Message,
            Fields: dedicationFieldValues,
        }),
    };

    return dedicationData;
};

export const donationMessageDataExtractor = (values) => {
    const { Has_Donation_Message, Donation_Message } = values;

    if (Has_Donation_Message && Donation_Message.length > 0) {
        return { Donation_Message };
    }
    return {};
};

export const textToDonateDataExtractor = ({ smsData = {}, subtotal = 0 }) => {
    const { isTextToDonate = false, token = "" } = smsData;
    if (isTextToDonate) {
        // Since the subtotal will always be what we want to send as the Promise_Amount
        // and we are using the amount from smsData to set subtotal in initial values,
        // we don't need to worry about the amount from smsData here.
        const Promise_Amount = subtotal;
        return {
            Promise_Amount,
            Shortcode: token,
        };
    }
    return {};
};
