import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import Field from "./Field";

const FormikField = ({
    ariaRequired = "false",
    descText = "",
    label = "",
    maxChar = "",
    helpText = "",
    name,
    validate = () => {},
    renderError = () => {},
    ...props
}) => {
    const formikProps = {
        name,
        validate,
    };
    const [{ onBlur, onChange, value, ...field }, meta] = useField(formikProps);
    const { setFieldValue } = useFormikContext();

    // enforce maxChar constraint
    const isWithinMaxCharLimit = (text) =>
        !(parseInt(maxChar, 10) && text.length > parseInt(maxChar, 10));

    // If passed QMask argument, a modified change handler is needed to save unmasked value
    const handleChange = (e, QMask) => {
        if (QMask) {
            const currentTarget = QMask.el.input;
            const fieldName = currentTarget.name;
            const unmaskedFieldValue = QMask.unmaskedValue;
            setFieldValue(fieldName, unmaskedFieldValue);
        } else if (isWithinMaxCharLimit(e.target.value)) {
            onChange(e);
        }
    };
    // Transform the error and touched values into a boolean that can be used
    // to determine whether or not the error message should be displayed
    const hasError = Boolean(meta.error);
    const hasBeenTouched = meta.touched;
    const shouldDisplayError = hasError && hasBeenTouched;
    const fieldProps = {
        ariaRequired,
        descText,
        handleBlur: onBlur,
        handleChange,
        helpText,
        label,
        value: value || "",
        error: shouldDisplayError,
        errorText:
            meta.error === "Required" ? `${label} is required` : meta.error,
        renderError,
        // overwriting handleBlur here for EmailRegistrationField
        ...props,
        ...field,
    };

    return <Field {...fieldProps} />;
};

FormikField.propTypes = {
    ariaRequired: PropTypes.string,
    // Displays label like "Optional" inside the input (on the right side)
    descText: PropTypes.string,
    // Field name
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    maxChar: PropTypes.string,
    helpText: PropTypes.string,
    // Optional field-level validation
    validate: PropTypes.func,
    // Optional custom error component
    renderError: PropTypes.func,
};

export default FormikField;
