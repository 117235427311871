import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BackButtonButton from "./BackButtonButton";
import "./BackButton.scss";

// -------------------------------------------------------------------------
// NOTE: BackButtonLink displays BackButtonButton
// -------------------------------------------------------------------------
const BackButtonLink = ({ backLinkUrl, backLinkLabel, withRouter = false }) =>
    withRouter ? (
        <Link className="back-button__link" to={backLinkUrl}>
            <BackButtonButton backLinkLabel={backLinkLabel} ariaHidden />
        </Link>
    ) : (
        // added before eslint-plugin-jsx-a11y@6.8.0
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <a href={backLinkUrl} className="back-button__link">
            <BackButtonButton backLinkLabel={backLinkLabel} ariaHidden />
        </a>
    );

BackButtonLink.propTypes = {
    // Label for the Back Button to display
    backLinkLabel: PropTypes.string.isRequired,
    // Url to redirect to for the link
    backLinkUrl: PropTypes.string.isRequired,
    // Flag which determines if link is wrapped in React Router, defaults to false
    withRouter: PropTypes.bool.isRequired,
};

export default BackButtonLink;
