import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import { useSelector } from "react-redux";
import { getCmsPageByContainerId } from "@qgiv/donation-form";
import {
    selectAllCmsPages,
    selectCmsControlById,
} from "../../../redux/slices/cmsSettingsSlice";
import {
    selectCurrentPage,
    selectIsSinglePageForm,
} from "../../../redux/slices/appSettingsSlice";
import { selectIsCms } from "../../../redux/slices/configSettingsSlice";
import HeaderControl from "./HeaderControl";
// import { selectHasCompletedFulfillment } from "../../../redux/slices/promiseTransactionSlice";

const ConnectedHeaderControl = ({ controlId, page }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { QGIV_EVENTS, PAYMENT_DETAILS, DETAILS },
        },
    } = constants;
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    const currentPage = useSelector(selectCurrentPage);
    // Progress Bar is displayed on all pages except QGIV_EVENTS
    const displayProgressBar = currentPage !== QGIV_EVENTS;
    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const isCms = useSelector(selectIsCms);
    const pages = useSelector(selectAllCmsPages);
    const pageFromContainerIdForSinglePage = getCmsPageByContainerId({
        pages,
        containerId: control.container,
    });

    // Single Page Forms need to use page of control passed in rather than current page
    const displayDetailSignInTextSinglePage =
        isSinglePageForm &&
        (page === "details-page" ||
            (isCms && pageFromContainerIdForSinglePage === DETAILS));

    const displayLockIconSinglePage =
        isSinglePageForm &&
        (page === "payment-page" ||
            (isCms && pageFromContainerIdForSinglePage === PAYMENT_DETAILS));

    const displayTextOrInvoiceHeaderForSinglePage =
        isSinglePageForm && page === "gift-page";

    // returns false when there is not an active fulfillment transaction
    // const hasCompletedFulfillment = useSelector(selectHasCompletedFulfillment);
    // const displayHeaderContent = !hasCompletedFulfillment;

    return (
        <HeaderControl
            control={control}
            displayDetailSignInTextSinglePage={
                displayDetailSignInTextSinglePage
            }
            displayHeaderContent
            displayProgressBar={displayProgressBar}
            displayLockIconSinglePage={displayLockIconSinglePage}
            displayTextOrInvoiceHeaderForSinglePage={
                displayTextOrInvoiceHeaderForSinglePage
            }
            isSinglePageForm={isSinglePageForm}
        />
    );
};

ConnectedHeaderControl.propTypes = {
    controlId: PropTypes.number.isRequired,
    page: PropTypes.string.isRequired,
};

export default ConnectedHeaderControl;
