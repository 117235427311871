import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { currencyString, constants } from "@qgiv/core-js";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";
import { selectPromiseTransaction } from "../../../../../redux/slices/promiseTransactionSlice";
import InvoiceFulfillmentOption from "./InvoiceFulfillmentOption";

/**
 * @param {object} props - Component properties
 * @param {number} props.controlId - The control ID for the component
 * @param {Function} props.handleSubtotalChange - Function to handle subtotal changes upon selection
 * of invoice payment option
 * @returns {React.ReactElement} The PayOutstandingInvoices component.
 */
const ConnectedPayOutstandingInvoices = ({
    controlId,
    handleSubtotalChange,
}) => {
    const promiseTransaction = useSelector(selectPromiseTransaction);
    const formSettings = useSelector(selectAllFormSettings);
    const { currency } = formSettings;
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const { payOutstandingInvoiceLabel = "Pay This + Outstanding Invoices" } =
        options;
    const { handleBlur, values } = useFormikContext();
    const { Invoice_Fulfill_Outstanding } = values;
    const { InvoiceFulfillmentType } = constants.ENUMS;

    const hasRecurringInvoice = promiseTransaction.recurring !== "0";

    const outstandingInvoicesAdditionalText = `<span class="-type--small">Includes ${promiseTransaction.invoiceOutstandingCount} invoices from<br/> ${promiseTransaction.invoiceOutstandingDateRange}</span>`;

    const outstandingInvoiceProps = {
        cardRadioProps: {
            id: "outstanding-invoices",
            name: "Invoice_Complete_Fulfillment",
            title: payOutstandingInvoiceLabel,
            extraTitle: `${currencyString(
                promiseTransaction.invoiceOutstandingAmount || 0,
                currency,
            )}`,
            value: InvoiceFulfillmentType.OUTSTANDING,
            handleBlur,
            handleChange: handleSubtotalChange,
            checked: Invoice_Fulfill_Outstanding,
        },
        additionalText: outstandingInvoicesAdditionalText,
        hasRecurringInvoice,
    };

    return <InvoiceFulfillmentOption {...outstandingInvoiceProps} />;
};

ConnectedPayOutstandingInvoices.propTypes = {
    controlId: PropTypes.number.isRequired,
    handleSubtotalChange: PropTypes.func.isRequired,
};

export default ConnectedPayOutstandingInvoices;
