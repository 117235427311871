const cmsDefaultRecipient = {
    type: "participant",
    title: "Selected Recipient",
    fundraisingGoal: 500,
    totalRaised: 100,
    id: "cms-1",
    firstName: "Name of",
    lastName: "Fundraising Recipient",
};

export default cmsDefaultRecipient;
