/* eslint-disable react/prop-types */
import React from "react";

/**
 * @param {object} props - Component properties
 * @param {string} props.eventName - The name of the event to display
 * @param {string} props.eventUrl - The URL of the event to link to
 * @returns {React.ReactElement} The WidgetEventsListItemName component.
 */
const WidgetEventsListItemName = ({ eventName, eventUrl }) => (
    <h3 className="widget-events__name">
        <a href={eventUrl} target="_blank" rel="noopener noreferrer">
            {eventName}
        </a>
    </h3>
);

export default WidgetEventsListItemName;
