/**
 * @param {string} fileName Name of file in /resources/${directory}/images/
 * @param {object} [settings] Optional settings object
 * @param {string} [settings.directory] Name of directory in /resources/
 * @returns {string} Returns either a full URL or a relative path to the
 *      provided image, depending on the current origin.
 */
export const getImageUrl = (fileName, settings = {}) => {
    const { directory = "core" } = settings;
    const origin =
        window.location.hostname?.search(/localhost/gi) > -1
            ? "https://docker.dev.qgiv.com"
            : "";

    return `${origin}/resources/${directory}/images/${fileName}`;
};

/**
 * @function getRangeOfNumbers
 * @description Function to create an array of numbers from a start and end
 *      number
 * @param {number} start Number to start the array on
 * @param {number} end Number to end the array with
 * @returns {number[]} Array of numbers.
 *      Ex. getRangeOfNumbers(1, 5) === [1, 2, 3, 4, 5]
 */
export const getRangeOfNumbers = (start, end) =>
    Array(Number(end) - Number(start) + 1)
        .fill()
        .map((_, idx) => Number(start) + idx);

/**
 * @function getTextWidth
 * @description Given a string of text, return it's calculated pixel width
 * @param {string} textString A string of text to calculate width for
 * @param {string} [font] A canvas font string (e.g. '400 16px Arial')
 * @returns {number} Calculate width of text
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/measureText
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/font
 */
export const getTextWidth = (textString, font = null) => {
    const id = "Qg-Text-Canvas";
    const _canvas = document.createElement("canvas");
    _canvas.id = id;
    document.body.appendChild(_canvas);
    const canvas = document.querySelector(`#${id}`);
    const ctx = canvas.getContext("2d");
    if (font) {
        ctx.font = font;
    }
    const text = ctx.measureText(textString);
    const width = Math.ceil(text.width);
    document.body.removeChild(canvas);
    return width;
};

/**
 * @function hexToRgb
 * @param {string} hex Hex value of color to be converted to RGB (e.g. '#00FF00')
 * @returns {object} Returns object with r, g, and b keys for the corresponding colors (e.g. {r: 0, g: 255, b: 0})
 * @description Converts hex color value to RGB
 * @see https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 */
export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

/**
 * @function loadCss
 * @description Given an id selector and styles as a string, inject them into
 *      the DOM. The element targeted by the id selector should be a `<style>`
 *      tag.
 * @param {string} id Id selector
 * @param {string} styles Styles to inject
 */
export const loadCss = (id, styles) => {
    const target = document.getElementById(id);
    target.innerHTML = styles;
};

/**
 * @function scrollToBottom
 * @description Scroll to the bottom of the page
 */
export const scrollToBottom = () => {
    setTimeout(
        () =>
            window.scroll({
                top: document.body.scrollHeight,
                behavior: "smooth",
            }),
        50,
    );
};

/**
 * @function capitalizeFirstAndLastName
 * @description capitalizes the first letter of both first and last name
 * @param {string} firstName first name
 * @param {string} lastName last name
 * @returns {string} concatenated first and last name with capitalized first letter
 */
export const capitalizeFirstAndLastName = (firstName, lastName) =>
    `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${
        lastName.charAt(0).toUpperCase() + lastName.slice(1)
    }`;
