import { constants } from "@qgiv/core-js";

/**
 * @typedef {import("@qgiv/donation-form").FormikTypes.GiftPage.MultiRestrictionFieldValue} MultiRestrictionFieldValue
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationDetails.GiftDetails} GiftDetails
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.Restriction} Restriction
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.MultiRestrictionGroup} MultiRestrictionGroup
 * @typedef {import("@qgiv/donation-form").ReduxTypes.SMSData.SMSData} SMSData
 */

const { ENUMS } = constants;
const { ItemType, RestrictionAmounts } = ENUMS;

// -------------------------------------------------------------------------
// TODO: Tests need to be added for these functions once the
// multi-restriction project is closer to wrapping up.
// -------------------------------------------------------------------------
/**
 *
 * @param {number} restrictionId
 * @returns {string}
 */
export const getMultiRestrictionFieldName = (restrictionId) =>
    `Multi_Restriction_${restrictionId}`;

/**
 *
 * @param {Restriction[]} restrictions
 * @returns {string[]} The names of the multi-restriction fields in Formik.
 */
export const getMultiRestrictionFieldNames = (restrictions) => {
    const multiRestrictionFieldNames = restrictions.map((restriction) =>
        getMultiRestrictionFieldName(restriction.id),
    );
    return multiRestrictionFieldNames;
};

/**
 *
 * @param {boolean} hasSelectedRecurringDonation
 * @param {string} multiRestrictionFieldName
 * @returns {string} String that can be used to uniquely identify restriction, amount
 * and type of field being modified.
 */
export const getMultiRestrictionAmountIdFieldName = (
    hasSelectedRecurringDonation,
    multiRestrictionFieldName,
) => {
    const multiRestrictionAmountIdFieldName = hasSelectedRecurringDonation
        ? `${multiRestrictionFieldName}_Selected_Recurring_Id`
        : `${multiRestrictionFieldName}_Selected_One_Time_Id`;

    return multiRestrictionAmountIdFieldName;
};

/**
 *
 * @param {string} restrictionGivingType
 * @param {SMSData} smsData
 * @returns {boolean}
 */
export const getShouldRenderMultiRestriction = (
    restrictionGivingType,
    smsData,
) => {
    const { isTextToDonate = false } = smsData;
    if (restrictionGivingType === "simple" && !isTextToDonate) {
        return true;
    }
    return false;
};

/**
 *
 * @description Helper function ensures that we only have to use the
 * "magic strings" needed here in a single location.
 * @param {"selected"|"always"} amountAndSubRestrictionsDisplay Donation Amounts CMS Control
 * @returns {boolean}
 */
export const getAlwaysDisplayAmountsAndSubRestrictions = (
    amountAndSubRestrictionsDisplay,
) => amountAndSubRestrictionsDisplay === "always";

/**
 *
 * @param {Amount[]} amounts
 * @param {Restriction} restriction
 * @returns {Amount[]}
 */
export const getSpecificAmountsAttachedToRestriction = (
    amounts,
    restriction,
) => {
    const { attachedAmounts = [] } = restriction;

    const specificAmountsAttachedToRestriction = amounts.filter(
        (standardAmountToDisplay) => {
            const isAmountAssociatedWithRestriction = attachedAmounts.includes(
                standardAmountToDisplay.id,
            );
            if (isAmountAssociatedWithRestriction) {
                return true;
            }
            return false;
        },
    );

    return specificAmountsAttachedToRestriction;
};

/**
 *
 * @param {Amount[]} amounts
 * @param {Restriction} restriction
 * @returns {Amount[]}
 */
export const getAmountsFromRestrictionSettings = (amounts, restriction) => {
    const { attachAmounts = RestrictionAmounts.ALL } = restriction;
    let amountsFromRestrictionSettings = [];

    if (attachAmounts === RestrictionAmounts.ALL) {
        amountsFromRestrictionSettings = amounts;
    } else {
        amountsFromRestrictionSettings =
            getSpecificAmountsAttachedToRestriction(amounts, restriction);
    }

    return amountsFromRestrictionSettings;
};

/**
 *
 * @param {MultiRestrictionFieldValue} multiRestrictionFieldValue
 * @param {Amount} amount
 * @returns {number}
 */
export const getOneTimeSubtotalForRestriction = (
    multiRestrictionFieldValue,
    amount,
) => {
    // Account for the initial value of the other amount is an empty string
    const otherAmountSubTotal = Number(
        multiRestrictionFieldValue.Other_One_Time_Amount,
    );
    const existingSubTotalForRestriction =
        amount && amount.amountType === ItemType.OTHER
            ? otherAmountSubTotal
            : amount.amount;

    return existingSubTotalForRestriction;
};

/**
 *
 * @param {Restriction[]} restrictions
 * @param {GiftDetails} giftDetails
 * @returns {Restriction[]}
 */
export const getSelectedRestrictions = (restrictions, giftDetails) => {
    const selectedRestrictions = restrictions.filter((restriction) => {
        const { id = 0 } = restriction;
        const multiRestrictionFieldName = getMultiRestrictionFieldName(id);
        const multiRestrictionFieldValue =
            giftDetails[multiRestrictionFieldName];

        if (multiRestrictionFieldValue.Checked) {
            return true;
        }

        return false;
    });

    return selectedRestrictions;
};

/**
 *
 * @param {number[]} idsOfAmountsToDisplay
 * @param {number[]} attachedAmounts
 * @returns {number[]}
 */
export const getIdsOfSpecificAmountsToDisplay = (
    idsOfAmountsToDisplay,
    attachedAmounts,
) => {
    const idsOfSpecificAmountsToDisplay = attachedAmounts.filter(
        (attachedAmount) => {
            if (idsOfAmountsToDisplay.includes(attachedAmount)) {
                return true;
            }
            return false;
        },
    );

    return idsOfSpecificAmountsToDisplay;
};

/**
 *
 * @description Creates an object that contains a restriction id and a dynamic
 * list of amounts that should be displayed along with that restriction based
 * on the current state of the UI. I have not created a type for this as I am
 * unsure of how to do this but this would be something we should figure out
 * how to do in the future. However, as things stand the shape of the object
 * returned by this function is as follows:
 * {
 *    [restrictionId1]: [amountId1, amountId2, amountId3]
 *    [restrictionId2]: [amountId1, amountId2, amountId3]
 * }
 * @param {number[]} idsOfAmountsToDisplay
 * @param {Restriction[]} restrictions
 * @returns {object}
 */
export const getAttachedAmountsToDisplay = (
    idsOfAmountsToDisplay,
    restrictions,
) => {
    const attachedAmountsToDisplay = {};

    /**
     *
     * @param {Restriction} restriction
     * @returns {undefined}
     */
    const addAttachedAmountsToDisplay = (restriction) => {
        const { attachAmounts = RestrictionAmounts.ALL, attachedAmounts = [] } =
            restriction;
        let idsOfAttachedAmountsToDisplay = [];

        // If all amounts are set to display we need to avoid the attachAmounts
        // data it contains the ids of the amounts that were last selected when
        // the selected amounts setting was turned on
        if (attachAmounts === RestrictionAmounts.ALL) {
            idsOfAttachedAmountsToDisplay = idsOfAmountsToDisplay;
        } else if (attachAmounts === RestrictionAmounts.SPECIFIC) {
            idsOfAttachedAmountsToDisplay = getIdsOfSpecificAmountsToDisplay(
                attachedAmounts,
                idsOfAmountsToDisplay,
            );
        }

        if (idsOfAttachedAmountsToDisplay.length > 0) {
            attachedAmountsToDisplay[restriction.id] =
                idsOfAttachedAmountsToDisplay;
        }
    };

    restrictions.forEach(addAttachedAmountsToDisplay);

    return attachedAmountsToDisplay;
};

/**
 *
 * @param {number[]} restrictionIds
 * @param {object} attachedAmountsToDisplay
 * @returns {number[]}
 */
const getRestrictionIdsWithAmountsToDisplay = (
    restrictionIds,
    attachedAmountsToDisplay,
) => {
    const restrictionIdsWithAmountsToDisplay = restrictionIds.filter(
        (restrictionId) => {
            if (attachedAmountsToDisplay[restrictionId]) {
                return true;
            }
            return false;
        },
    );

    return restrictionIdsWithAmountsToDisplay;
};

/**
 *
 * @param {number[]} restrictionIds Restrictions associated with a group.
 * @param {number[]} idsOfDisplayableRestrictions
 * @returns {boolean}
 */
export const getHasDisplayableRestrictionInGroup = (
    restrictionIds,
    idsOfDisplayableRestrictions,
) => {
    const hasDisplayableRestriction = restrictionIds.some((restrictionId) => {
        if (idsOfDisplayableRestrictions.includes(restrictionId)) {
            return true;
        }
        return false;
    });

    return hasDisplayableRestriction;
};

/**
 *
 * @param {object} settings
 * @param {object} settings.attachedAmountsToDisplay Contains the restriction
 * id and a dynamic list of amounts that should be displayed along with that
 * restriction based on the current state of the UI.
 * @param {number[]} settings.idsOfDisplayableRestrictions
 * @param {MultiRestrictionGroup[]} settings.multiRestrictionGroups
 * @returns {MultiRestrictionGroup[]}
 */
export const getRestrictionGroupsToDisplay = ({
    attachedAmountsToDisplay,
    idsOfDisplayableRestrictions,
    multiRestrictionGroups,
}) => {
    const restrictionGroupsToDisplay = [];

    /**
     *
     * @description The group needs to have at least one restriction that can
     * be displayed on the current device with amounts that can also be
     * displayed in order for it to be rendered.
     * @param {MultiRestrictionGroup} multiRestrictionGroup
     * @returns {undefined}
     */
    const addRestrictionGroupToDisplay = (multiRestrictionGroup) => {
        const { restrictionIds = [] } = multiRestrictionGroup;
        const restrictionIdsWithAmountsToDisplay =
            getRestrictionIdsWithAmountsToDisplay(
                restrictionIds,
                attachedAmountsToDisplay,
            );

        const hasDisplayableRestrictionInGroup =
            getHasDisplayableRestrictionInGroup(
                restrictionIds,
                idsOfDisplayableRestrictions,
            );
        const hasRestrictionWithAmountsToDisplay =
            restrictionIdsWithAmountsToDisplay.length > 0;

        if (
            hasDisplayableRestrictionInGroup &&
            hasRestrictionWithAmountsToDisplay
        ) {
            const newRestrictionIds = restrictionIdsWithAmountsToDisplay;
            const restrictionGroupToDisplay = {
                ...multiRestrictionGroup,
                restrictionIds: newRestrictionIds,
            };
            restrictionGroupsToDisplay.push(restrictionGroupToDisplay);
        }
    };

    multiRestrictionGroups.forEach(addRestrictionGroupToDisplay);

    return restrictionGroupsToDisplay;
};

/**
 *
 * @param {object} settings
 * @param {Amount[]} settings.amountsToDisplayWithRestriction
 * @param {MultiRestrictionFieldValue} settings.existingMultiRestrictionFieldValue
 * @param {"Selected_Recurring_Id"|"Selected_One_Time_Id"} settings.nameOfAmountIdField
 *  @returns {MultiRestrictionFieldValue}
 */
export const getNewFieldValueOnClick = ({
    amountsToDisplayWithRestriction,
    existingMultiRestrictionFieldValue,
    nameOfAmountIdField,
}) => {
    const { Checked } = existingMultiRestrictionFieldValue;
    const hasSingleAmount = amountsToDisplayWithRestriction.length === 1;
    const firstAmount = amountsToDisplayWithRestriction[0];
    const isFirstAmountOnBothTabs =
        firstAmount.displayForOneTime && firstAmount.displayForRecurring;
    const shouldOnlySetValueOnCurrentTab = !isFirstAmountOnBothTabs;

    let newMultiRestrictionFieldValue = {
        ...existingMultiRestrictionFieldValue,
    };

    // Other amount fields are not overwritten because we want the
    // values in this field to persist after de-selection
    if (Checked) {
        newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            Checked: false,
            Selected_One_Time_Id: "",
            Selected_Recurring_Id: "",
        };
    }

    if (!Checked && !hasSingleAmount) {
        newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            Checked: true,
        };
    }

    // If the amount is not available on both tabs we cannot auto-select the
    // amount in both tabs
    if (!Checked && hasSingleAmount && shouldOnlySetValueOnCurrentTab) {
        newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            Checked: true,
            [nameOfAmountIdField]: firstAmount.id,
        };
    }

    if (!Checked && hasSingleAmount && !shouldOnlySetValueOnCurrentTab) {
        newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            Checked: true,
            Selected_One_Time_Id: firstAmount.id,
            Selected_Recurring_Id: firstAmount.id,
        };
    }

    return newMultiRestrictionFieldValue;
};
