import WebFont from "webfontloader";

import log from "../log";

const config = (family) =>
    family
        ? {
              google: {
                  families: [family],
              },
          }
        : {
              custom: {
                  families: [
                      "Proxima Nova:n1,i1,n4,i4,n5,i5,n7,i7,n8,i8",
                      "adelle",
                  ],
                  urls: [
                      "https://secure.qgiv.com/resources/core/fonts/fonts.css",
                  ],
              },
          };

const googleFonts = [
    "Arvo",
    "Baskervville",
    "Open Sans",
    "PT Sans",
    "PT Serif",
    "Roboto",
    "Source Sans Pro",
];

const loadFonts = () => {
    const style = getComputedStyle(document.body);
    const stack = style.getPropertyValue("--user-theme__font");
    if (stack.includes("Proxima Nova") || !stack) {
        WebFont.load(config());
    } else {
        const family = googleFonts.find((font) => stack.includes(font));
        if (family) {
            WebFont.load(config(family));
        }
        // if font isn't in google fonts it doesn't need to be loaded
    }
    log("Dev Log: Fonts Initialized");
};

export default loadFonts;
