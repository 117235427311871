import { constants } from "@qgiv/core-js";
import {
    getIsOtherAmount,
    getNameOfAmountIdField,
    getNameOfOtherAmountField,
    getSelectedAmount,
} from "../amountHelpers";
import { getMultiRestrictionFieldName } from "../multiRestrictionHelpers";
import { getActiveDisplayableRestrictions } from "../standardRestrictionsHelpers";

const {
    ENUMS: { DisplayOn, ProductType },
} = constants;

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationDetails.DonorDetails} DonorDetails
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FormSettings.FormSettings} FormSettings
 */

/**
 *
 * @description Because we have not brought the privacy options field group to
 * the year round form we need to change the field that we are paying attention
 * to here based on product type.
 * @param {DonorDetails} donorDetails
 * @param {FormSettings} formSettings
 * @returns {boolean}
 */
export const getGivenAnonymously = (donorDetails, formSettings) => {
    const { Anonymity = false, Do_Not_Show_Name = false } = donorDetails;
    const { type = ProductType.QGIV } = formSettings;
    const givenAnonymously =
        type === ProductType.QGIV ? Anonymity : Do_Not_Show_Name;

    return givenAnonymously;
};

/**
 *
 * @param {object} options
 * @param {object} options.donationSettings
 * @param {object} options.giftDetails
 * @param {object} options.fieldValue
 * @returns {number}
 */
export const getLineAmountForMultiRestrictions = ({
    donationSettings,
    giftDetails,
    fieldValue,
}) => {
    const { activeAmounts = [] } = donationSettings;
    const { hasSelectedRecurringDonation = false } = giftDetails;

    // Get names of relevant fields based on the state of the UI
    const nameOfAmountIdField = getNameOfAmountIdField(
        hasSelectedRecurringDonation,
    );
    const nameOfOtherAmountField = getNameOfOtherAmountField(
        hasSelectedRecurringDonation,
    );

    // Get amount data from those fields
    const amountId = fieldValue[nameOfAmountIdField];
    const selectedAmount = getSelectedAmount(activeAmounts, amountId);
    const otherAmountFieldValue = Number(fieldValue[nameOfOtherAmountField]);
    const hasSelectedOtherAmount = getIsOtherAmount(selectedAmount);

    const lineAmount = hasSelectedOtherAmount
        ? otherAmountFieldValue
        : selectedAmount.amount;

    return lineAmount;
};

/**
 *
 * @param {object} options
 * @param {object} options.config
 * @param {object} options.donationSettings
 * @param {object} options.giftDetails
 * @param {object} options.restrictionSettings
 * @returns {object}
 */
export const getMultiRestrictionOptions = ({
    config,
    donationSettings,
    giftDetails,
    restrictionSettings,
}) => {
    const { currentDisplay = DisplayOn.FRONTEND } = config;
    const { restrictions = [] } = restrictionSettings;
    const restrictionsOptions = {};

    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );

    /**
     *
     * @param {object} restriction
     */
    const addRestriction = (restriction) => {
        const { id = 0, name = "" } = restriction;
        const multiRestrictionFieldName = getMultiRestrictionFieldName(id);
        const fieldValue = giftDetails[multiRestrictionFieldName];

        if (fieldValue && fieldValue.Checked) {
            const lineAmount = getLineAmountForMultiRestrictions({
                donationSettings,
                giftDetails,
                fieldValue,
            });
            const subRestriction = fieldValue.Sub_Restriction;

            let restrictionOption = {
                lineAmount,
            };

            // Only add sub-restriction data when a sub-restriction has been
            // associated with the restriction
            if (fieldValue.Sub_Restriction) {
                restrictionOption = {
                    ...restrictionOption,
                    subRestriction,
                };
            }

            restrictionsOptions[name] = restrictionOption;
        }
    };

    // Build out data for each restriction if the restriction has been selected
    activeDisplayableRestrictions.forEach(addRestriction);

    return restrictionsOptions;
};

/**
 *
 * @param {object} options
 * @param {object} options.giftDetails
 * @returns {object}
 */
export const getStandardRestrictionOptions = ({ giftDetails }) => {
    const {
        Standard_Restriction_Name = "",
        Standard_Restriction_Sub_Restriction = "",
        subtotal = 0,
    } = giftDetails;
    const restrictionsOptions = {};

    const hasAddedStandardRestriction = !!Standard_Restriction_Name;
    const hasAddedASubRestriction = !!Standard_Restriction_Sub_Restriction;

    // The subtotal value is used instead of total as total includes amounts
    // such as gift assist while subtotal does not
    if (hasAddedStandardRestriction) {
        const lineAmount = Number(subtotal);
        restrictionsOptions[Standard_Restriction_Name] = {
            lineAmount,
        };
    }

    // Only add sub-restriction data when a sub-restriction has been
    // associated with the restriction
    if (hasAddedASubRestriction) {
        const subRestriction = Standard_Restriction_Sub_Restriction;
        restrictionsOptions[Standard_Restriction_Name] = {
            ...restrictionsOptions[Standard_Restriction_Name],
            subRestriction,
        };
    }

    return restrictionsOptions;
};
