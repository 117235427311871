import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import FieldError from "../Field/FieldError";
import Checkbox from "./Checkbox";

const FormikCheckbox = ({ name, validate = () => {}, ...props }) => {
    const formikProps = {
        name,
        type: "checkbox",
        validate,
    };
    const [{ onBlur, onChange, ...field }, meta] = useField(formikProps);
    const fieldProps = {
        handleBlur: onBlur,
        handleChange: onChange,
        ...props,
        ...field,
    };
    const fieldErrorProps = {
        label: props?.label || "", // field's label might be used to compose the error message
        ...meta,
    };

    return (
        <>
            <Checkbox {...fieldProps} />
            <FieldError {...fieldErrorProps} />
        </>
    );
};

FormikCheckbox.propTypes = {
    // Field name
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    // Optional field-level validation
    validate: PropTypes.func,
};

export default FormikCheckbox;
