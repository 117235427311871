import React from "react";
import PropTypes from "prop-types";
import ConnectedDonationFormModal from "./ModalWrapper/ConnectedDonationFormModal";
import ConnectedDonationFormEmbed from "./EmbedWrapper/ConnectedDonationFormEmbed";

const DonationFormWrappers = ({ isDisplayTypeModal }) =>
    isDisplayTypeModal ? (
        <ConnectedDonationFormModal />
    ) : (
        <ConnectedDonationFormEmbed />
    );

DonationFormWrappers.propTypes = {
    isDisplayTypeModal: PropTypes.bool.isRequired,
};

export default DonationFormWrappers;
