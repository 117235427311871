import React, { useCallback } from "react";
import { ModalContentReplacerEnums } from "../../common/ModalContentReplacer";
import { usePaymentPageData } from "../../PageDataHooks";
import {
    getInitialTouched,
    getInitialValues,
    getValidationSchema,
} from "./formik";
import ConnectedRecurringUpgrade from "./RecurringUpgrade";
import PaymentPage from "./PaymentPage";

const ConnectedPaymentPage = () => {
    const paymentPageData = usePaymentPageData();
    const {
        documentTitle,
        formikSettings,
        handleUpdateSubmitDonation,
        selectedContentToDisplay,
        shouldDisplayRecurringUpgrade,
        submitDonation,
    } = paymentPageData;

    const getAlternateContent = useCallback(() => {
        const alternateContent = [];
        if (shouldDisplayRecurringUpgrade) {
            alternateContent.push({
                component: <ConnectedRecurringUpgrade />,
                type: ModalContentReplacerEnums.UPGRADE_RECURRING_UI,
            });
        }
        return alternateContent;
    }, [shouldDisplayRecurringUpgrade]);

    const alternateContent = getAlternateContent();

    const onSubmit = (values) => {
        const { Payment_Type } = values;
        handleUpdateSubmitDonation(Payment_Type);
    };

    const {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    } = formikSettings;

    const formikProps = {
        initialTouched: getInitialTouched(initialTouchedSettings),
        initialValues: getInitialValues(initialValuesSettings),
        validationSchema: getValidationSchema(validationSettings),
        onSubmit,
    };

    const paymentPageProps = {
        alternateContent,
        documentTitle,
        formikProps,
        selectedContentToDisplay,
        submitDonation,
    };
    return <PaymentPage {...paymentPageProps} />;
};

export default ConnectedPaymentPage;
