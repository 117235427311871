import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import {
    selectCurrentPage,
    selectDisplayExpressCheckout,
} from "../../../../redux/slices/appSettingsSlice";
import { selectGiftDetails } from "../../../../redux/slices/donationDetailsSlice";
import { selectRecurringFrequencyByValue } from "../../../../redux/slices/donationSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectIsTextToDonate } from "../../../../redux/slices/smsDataSlice";
import SubmitButtonLabelWithContent from "../../../common/Submit/SubmitButtonLabelWithContent";
import Submit from "../../../common/Submit";
import { selectIsDisplayTypeModal } from "../../../../redux/slices/configSettingsSlice";

const ConnectedSubmit = ({ controlId }) => {
    const { isSubmitting } = useFormikContext();
    const isDisplayTypeModal = useSelector(selectIsDisplayTypeModal);

    const giftDetails = useSelector(selectGiftDetails);
    const {
        hasSelectedRecurringDonation,
        Recurring_Frequency,
        subtotal,
        total,
    } = giftDetails;

    const { currency } = useSelector(selectAllFormSettings);

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const {
        continueButtonLabel = "Give %AMOUNTWITHFREQUENCY%",
        textToDonateContinueButtonLabel = "Give %AMOUNTWITHFREQUENCY%",
    } = options;

    const isTextToDonate = useSelector(selectIsTextToDonate);

    const selectedFrequency = useSelector((state) =>
        selectRecurringFrequencyByValue(state, Recurring_Frequency),
    );

    const getButtonLabel = () => {
        if (isTextToDonate) {
            return textToDonateContinueButtonLabel;
        }
        return continueButtonLabel;
    };

    const buttonLabel = getButtonLabel();

    const frequencyUnitLabel = selectedFrequency?.labelPerAmount;
    const submitButtonLabelWithContentProps = {
        buttonLabel,
        currency,
        frequencyUnitLabel,
        hasSelectedRecurringDonation,
        total,
    };

    const displayExpressCheckout = useSelector(selectDisplayExpressCheckout);
    const addTopPaddingAndGutter = !displayExpressCheckout;

    // -------------------------------------------------------------------------
    // NOTE: We are looking at subtotal here instead of total. This will prevent
    // a static gift assist to progress without a donation amount selected.
    // -------------------------------------------------------------------------
    const disabled = subtotal === 0;

    const submitProps = {
        addTopPaddingAndGutter,
        disabled,
        displayAcceptedPaymentMethods: true,
        displayBackButton: false,
        isPreview: false,
        isSubmitting,
        nextButtonLabel: (
            <SubmitButtonLabelWithContent
                {...submitButtonLabelWithContentProps}
            />
        ),
        total,
        showBackground: !!isDisplayTypeModal,
    };

    return <Submit {...submitProps} />;
};

ConnectedSubmit.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedSubmit;
