import React from "react";
import { SinglePageFooterContainers } from "../../Containers";

/**
 * @param {object} props - The component props.
 * @param {Array} props.containers - An array of container objects to be rendered in the footer.
 * @returns {React.ReactElement} The rendered component.
 */
const SinglePageFooterContent = ({ containers = [] }) => {
    const singlePageContentProps = {
        containers,
    };

    return <SinglePageFooterContainers {...singlePageContentProps} />;
};

export default SinglePageFooterContent;
