import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    FormInjectionBody,
    getInjectionsToRenderInReactApp,
} from "@qgiv/core-donor";
import { selectActiveInjections } from "../../../redux/slices/activeInjectionsSlice";
import { selectCurrentPage } from "../../../redux/slices/appSettingsSlice";
import { selectEnableAnalyticsOutsideIframe } from "../../../redux/slices/internalSettingsSlice";

const ConnectedFormInjectionBody = () => {
    const {
        ENUMS: { FormInjectionPosition },
    } = constants;
    const activeInjections = useSelector(selectActiveInjections);
    const currentPage = useSelector(selectCurrentPage);
    const enableAnalyticsOutsideIframe = useSelector(
        selectEnableAnalyticsOutsideIframe,
    );

    // Organize data so that it can be passed into the helper function
    const activeFormInjections = activeInjections;
    const currentPosition = FormInjectionPosition.BODY;
    const currentStep = currentPage;
    const isProductThatUsesIframe = true;

    // Script elements are the only elements that can be injected into the
    // document body
    const activeInjectionsInBody = getInjectionsToRenderInReactApp({
        activeFormInjections,
        currentPosition,
        currentStep,
        enableAnalyticsOutsideIframe,
        isProductThatUsesIframe,
    });

    const formInjectionBodyProps = {
        activeInjectionsInBody,
    };

    return <FormInjectionBody {...formInjectionBodyProps} />;
};

export default ConnectedFormInjectionBody;
