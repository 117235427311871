import { constants, isDisplayable } from "@qgiv/core-js";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FieldSettings.SystemField} SystemField
 */

/**
 * @public
 * @function sortSystemAndCustomFieldsFromValues
 * @description function that shapes system fields and custom fields into
 * their own objects for storing into the correct place in redux
 * @param {object} values formik values from page
 * @returns {object} object that contains system fields object and custom fields object with their values
 */
export const sortSystemAndCustomFieldsFromValues = (values) =>
    Object.keys(values).reduce(
        (valuesObj, currentKey) => {
            const { systemFieldValues, customFieldValues } = valuesObj;
            // custom fields are stored by their id, which is a string number / number
            const valueIsSystemField = Number.isNaN(Number(currentKey));
            if (valueIsSystemField) {
                return {
                    systemFieldValues: {
                        ...systemFieldValues,
                        [currentKey]: values[currentKey],
                    },
                    customFieldValues,
                };
            }
            return {
                systemFieldValues,
                customFieldValues: {
                    ...customFieldValues,
                    [currentKey]: values[currentKey],
                },
            };
        },
        { systemFieldValues: {}, customFieldValues: {} },
    );

/**
 * @public
 * @function filterDonationDetailsValuesByType
 * @description Custom Fields, System Fields, Matching Gifts and Dedications may all be Formik values
 * on the Donor Details and Additional Details page.  We are storing these separately in Redux and
 * will need a way to only filter and return values we need.
 * @param {string} type Type of values to pull out
 * @param {object} values formik values from page
 * @returns {object} object that contains only the values requested
 */
export const filterDonationDetailsValuesByType = (type, values) => {
    let valuesByType = {};

    switch (type) {
        case "customFields":
            valuesByType = Object.keys(values)
                .filter((key) => !Number.isNaN(Number(key)))
                .reduce((obj, key) => {
                    const newObject = {
                        ...obj,
                    };
                    newObject[key] = values[key];
                    return newObject;
                }, {});

            break;

        case "matchingGift":
            valuesByType = Object.keys(values)
                .filter(
                    (key) =>
                        key === "Work_Email" ||
                        key === "Employer" ||
                        key.includes("Match"),
                )
                .reduce((obj, key) => {
                    const newObject = {
                        ...obj,
                    };
                    newObject[key] = values[key];
                    return newObject;
                }, {});

            break;

        case "dedication":
            valuesByType = Object.keys(values)
                .filter((key) => key.includes("Dedication"))
                .reduce((obj, key) => {
                    const newObject = {
                        ...obj,
                    };
                    newObject[key] = values[key];
                    return newObject;
                }, {});
            break;

        case "systemFields":
            valuesByType = Object.keys(values)
                .filter(
                    (key) =>
                        !key.includes("Dedication") &&
                        !key.includes("Match") &&
                        Number.isNaN(Number(key)) && // Custom Fields
                        key !== "Work_Email" &&
                        !key.includes("Donation_Message"),
                )
                .reduce((obj, key) => {
                    const newObject = {
                        ...obj,
                    };
                    newObject[key] = values[key];
                    return newObject;
                }, {});
            break;
        case "donationMessage":
            valuesByType = Object.keys(values)
                .filter((key) => key.includes("Donation_Message"))
                .reduce((obj, key) => {
                    const newObject = {
                        ...obj,
                    };
                    newObject[key] = values[key];
                    return newObject;
                }, {});
            break;
        default:
            valuesByType = values;
            break;
    }

    return valuesByType;
};

/**
 * @public
 * @function getHasValidCustomFieldValue
 * @description function that determines whether a custom field has a value
 * that means that we an treat it as being "filled out" by the donor. In all
 * cases this would mean that the donor has filled out the field other than the
 * case where the field that is being examined is a hidden field which can
 * contain valid data even though its never actually visible to the donor
 * @param {object} customField settings data for an individual custom field
 * @param {string|boolean|Array} value value that is associated with the custom
 * field being examined
 * @returns {boolean} flag that denotes whether the field has a value that
 * means that we can treat it as being "filled out".
 */
export const getHasValidCustomFieldValue = (customField, value) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    let hasValue = false;

    if (Number(customField.type) === FieldType.CHECKBOX) {
        hasValue = !!value;
    } else {
        hasValue = !!value && !!value.length;
    }

    return hasValue;
};

/**
 *
 * @param {number} fieldType
 * @param {SystemField[]} systemFields
 * @returns {SystemField|{}}
 */
export const getSystemFieldByType = (fieldType, systemFields) => {
    const systemField =
        systemFields.find((field) => Number(field.type) === fieldType) || {};

    return systemField;
};

/**
 * @description In order for a field to be visible it needs to:
 * 1. Active in the Control Panel
 * 2. Have display settings that ensures it is visible on the current device.
 * @param {object} settings
 * @param {number} settings.currentDisplay
 * @param {number} settings.fieldType
 * @param {SystemField[]} settings.systemFields
 * @returns {boolean}
 */
export const getIsSystemFieldDisplayable = ({
    currentDisplay,
    fieldType,
    systemFields,
}) => {
    const systemField = getSystemFieldByType(fieldType, systemFields);

    // Inactive fields are not delivered to the FE
    const isActive = Object.keys(systemField).length > 0;
    const isDisplayableOnCurrentDevice = isDisplayable(
        systemField,
        currentDisplay,
    );

    if (isActive && isDisplayableOnCurrentDevice) {
        return true;
    }

    return false;
};
