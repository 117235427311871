import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { trackApplePay, ApplePayPaymentMethod } from "@qgiv/core-donor";
import { getApplePayData } from "@qgiv/donation-form";
import {
    selectShouldDisplayApplePay,
    setIsSubmittingUsingExpressCheckout,
} from "../../../../redux/slices/appSettingsSlice";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import {
    selectGiftDetails,
    updateDonationBilling,
    updateDonationPayment,
    updateDonorDetails,
} from "../../../../redux/slices/donationDetailsSlice";

const ConnectedApplePayPaymentMethod = () => {
    const {
        ENUMS: { FormTemplate, PaymentType },
    } = constants;
    const {
        handleSubmit,
        initialValues,
        isSubmitting,
        setFieldValue,
        setValues,
        validateForm,
        values,
    } = useFormikContext();
    const dispatch = useDispatch();
    const shouldDisplayApplePay = useSelector(selectShouldDisplayApplePay);
    const isCms = useSelector(selectIsCms);
    const { VisaAmexDisc = "nnn", template = FormTemplate.P2P_STANDARD } =
        useSelector(selectAllFormSettings);
    const giftDetails = useSelector(selectGiftDetails);
    const { total = 0 } = giftDetails;

    // Do not display Apple Pay processing state if Payment_Type is not Apple Pay
    const isApplePay = values.Payment_Type === PaymentType.APPLEPAY;
    // Disable Apple Pay button if no total has been selected
    const isDisabled = total === 0;

    // Use existing submission infrastructure to handle and display errors that
    // are thrown because of validation failing prior to the display of the
    // Apple Pay payment sheet
    const handleErrorsPriorToPaymentSheetDisplay = () => {
        handleSubmit();
    };

    // Form specific part of the payment request setup
    const onBeforeOpen = (
        defaultApplePayPaymentRequest,
        defaultAdditionalApplePayPaymentRequestData,
        formatAsAmount,
        formatAsNumber,
    ) => {
        let applePayPaymentRequest = {};
        // Copy all of the additional payment request data on to a new object
        const additionalApplePayPaymentRequestData = {
            ...defaultAdditionalApplePayPaymentRequestData,
        };

        // Prepare values for formatting and use in conditional logic by
        // ensuring that every value is being used is a number
        const totalAmountAsNumber = formatAsNumber(total);

        // -------------------------------------------------------------------------
        // NOTE: Previous implementations of Apple Pay included code in onBeforeOpen
        // that was dedicated to building out line items for things like gift assist,
        // optional donations, and so on. That existing line-item logic is seemingly
        // no longer compatible with Apple's payment sheet, and therefore no line items
        // will render on the payment sheet for our forms. Accordingly, we have removed
        // the line item logic here in PDR for now, until we can rework our Apple Pay
        // implementation to show line items again.
        // -------------------------------------------------------------------------

        const formattedTotalTotalAmount = formatAsAmount(totalAmountAsNumber);

        // Total amount displayed on payment sheet.
        additionalApplePayPaymentRequestData.total = {
            label: "Total",
            amount: formattedTotalTotalAmount,
        };

        // And add all of the additional data to the default payment request
        applePayPaymentRequest = {
            ...defaultApplePayPaymentRequest,
            ...additionalApplePayPaymentRequestData,
        };

        return applePayPaymentRequest;
    };

    // Form specific integration of the values returned by Apple Pay into the
    // values that are associated with the form that is going to be submitted
    const onBeforeSubmission = (
        platformAgnosticApplePayValues,
        billingContact,
    ) => {
        const platformSpecificApplePayValues = {
            ...platformAgnosticApplePayValues,
        };

        // Overwrite billing name  with the billing name provided by Apple Pay, as Apple data takes priority.
        // Do this regardless of whether or not Billing Name field is enabled.
        const billingName = `${billingContact.givenName} ${billingContact.familyName}`;
        platformSpecificApplePayValues.Billing_Name = billingName;

        const applePayBillingAddress2 = billingContact.addressLines[1];
        let billingAddress2 = initialValues.Billing_Address_2;

        // If Apple returned a billing address 2 overwrite the initial value
        if (applePayBillingAddress2) {
            billingAddress2 = applePayBillingAddress2;
        }

        // Overwrite the billing address 2 value as Apple data takes priority
        platformSpecificApplePayValues.Billing_Address_2 = billingAddress2;

        return platformSpecificApplePayValues;
    };

    // Use existing submission infrastructure to handle form submission after
    // an Apple Pay transaction has been successfully completed
    const handleSubmissionAfterAuthorization = () => {
        dispatch(setIsSubmittingUsingExpressCheckout(true));

        const formTemplateNumber = Number(template);

        // Run all of the typical Formik submission logic including
        // touching all fields so that errors are displayed
        handleSubmit();

        // Analytics tracking
        trackApplePay(formTemplateNumber);
    };

    const handleSubmissionUsingRedux = ({
        session,
        regions,
        ApplePaySession,
        payment,
    }) => {
        const { billingContact } = payment;
        const applePayBillingCountryRegions = regions.data;
        const applePayBillingStateName = applePayBillingCountryRegions.find(
            (region) =>
                region.stateCode ===
                billingContact.administrativeArea.toUpperCase(),
        ).stateName;

        const reduxData = getApplePayData({
            applePayBillingStateName,
            initialValues,
            payment,
        });

        const billingBucket = reduxData.billing;
        const paymentBucket = reduxData.payment;
        const donorDetailsBucket = reduxData.donorDetails;

        dispatch(updateDonationBilling(billingBucket));
        dispatch(updateDonationPayment(paymentBucket));
        dispatch(updateDonorDetails(donorDetailsBucket));

        // Use existing submission infrastructure to handle form submission
        // and analytics tracking after an Apple Pay transaction has been
        // successfully completed
        handleSubmissionAfterAuthorization();

        // Complete the payment authorization and dismiss the Apple
        // Pay payment sheet from the UI
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
    };

    const applePayPaymentMethodProps = {
        isApplePay,
        isCms,
        isSubmitting,
        setFieldValue,
        setValues,
        shouldDisplayApplePay,
        validateForm,
        values,
        VisaAmexDisc,
        // Form specific validation and event handlers
        handleErrorsPriorToPaymentSheetDisplay,
        handleSubmissionAfterAuthorization,
        handleSubmissionUsingRedux,
        onBeforeOpen,
        onBeforeSubmission,
        isDisabled,
    };

    return <ApplePayPaymentMethod {...applePayPaymentMethodProps} />;
};

export default ConnectedApplePayPaymentMethod;
