import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import "./BackButton.scss";

// -------------------------------------------------------------------------
// NOTE: BackButtonButton has default handleClick function that is overwritten in props
// -------------------------------------------------------------------------
const BackButtonButton = ({
    handleClick = () => {},
    backLinkLabel,
    ariaHidden = false,
}) => {
    const buttonProps = {
        className:
            "back-button__button -color--theme-primary -type--regular -font-weight--bold",
        onClick: handleClick,
        tabIndex: ariaHidden ? "-1" : "0",
    };

    if (ariaHidden) {
        buttonProps["aria-hidden"] = ariaHidden;
    }

    return (
        <button type="button" {...buttonProps}>
            <Icon
                glyph="chevron-circle-left-regular"
                classNames={["back-button__icon"]}
                type="FontAwesome"
                ariaHidden
            />
            <span>{backLinkLabel}</span>
        </button>
    );
};

BackButtonButton.propTypes = {
    // Flag for removing button from accessibility tree when wrapped in Link/a tag
    ariaHidden: PropTypes.bool.isRequired,
    // onClick function, default empty function for links
    handleClick: PropTypes.func,
    // text for button to display
    backLinkLabel: PropTypes.string.isRequired,
};

export default BackButtonButton;
