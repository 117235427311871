import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Animation } from "@qgiv/core-react";
import { getXYCoordinatesOfRecurringHeartAnimation } from "../../utility";

const RecurringHeartAnimation = ({ setDisplayRecurringHeartAnimation }) => {
    const [calendarCoordinates, setCalendarCoordinates] = useState({
        x: 0,
        y: 0,
    });

    // A class was used here instead of the id as the calendar icon was created
    // using the Icon component which is set up to accept classes as props
    const calendarIcon = document.getElementsByClassName(
        "donation-frequency-tabs__recurring-icon",
    )[0];

    // in case of resize, reassign the coordinates
    useEffect(() => {
        const calendarCoordinatesChanged =
            calendarIcon &&
            (calendarIcon.offsetLeft !== calendarCoordinates.x ||
                calendarIcon.offsetTop !== calendarCoordinates.y);
        if (calendarCoordinatesChanged) {
            setCalendarCoordinates({
                x: calendarIcon.offsetLeft,
                y: calendarIcon.offsetTop,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarIcon?.offsetLeft, calendarIcon?.offsetTop]);

    // Function responsible for generating the correct XY coordinates where
    // we place the absolute positioned animation.
    // NOTE: If icon size ever changes, this function will likely need to be fine tuned
    // to be positioned exactly in the center of the calendar as expected.
    const { animationXCoordinates, animationYCoordinates } =
        getXYCoordinatesOfRecurringHeartAnimation(
            calendarIcon,
            calendarCoordinates,
        );

    return (
        <Animation
            id="recurring-heart-animation"
            glyph="wiggle-heart"
            handleComplete={() => {
                setDisplayRecurringHeartAnimation(false);
            }}
            style={{
                height: "23px",
                top: "0",
                transform: `translate(${animationXCoordinates}px, ${animationYCoordinates}px)`,
            }}
        />
    );
};

RecurringHeartAnimation.propTypes = {
    setDisplayRecurringHeartAnimation: PropTypes.func.isRequired,
};

export default RecurringHeartAnimation;
