import React from "react";
import PropTypes from "prop-types";

const ConfirmationFieldsHeader = ({ heading }) => (
    <div className="confirmation-fields-header grid">
        <div className="col col--12 -text--center">
            <h2>{heading}</h2>
        </div>
    </div>
);

ConfirmationFieldsHeader.propTypes = {
    heading: PropTypes.string.isRequired,
};

export default ConfirmationFieldsHeader;
