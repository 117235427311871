import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props
 * @param {string|null} props.restrictionDetails
 * @returns {React.ReactElement} The rendered GivingPlanCardRestriction component.
 */
const GivingPlanCardRestriction = ({ restrictionDetails }) => (
    <div>
        <span className="-type--small">
            <Icon
                classNames={["-margin-right--10", "-type--regular"]}
                glyph="bullseye-arrow-regular"
                type="FontAwesome"
            />
            {restrictionDetails}
        </span>
    </div>
);

export default GivingPlanCardRestriction;
