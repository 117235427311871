import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../redux/slices/donorAccountSlice";
import PaymentDonorAccountContent from "./PaymentDonorAccountContent";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";

const ConnectedPaymentDonorAccountContent = ({ controlId }) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isCms = useSelector(selectIsCms);

    const displayDonorAccountCreation = !isLoggedIn || isCms;

    const paymentDonorAccountProps = {
        controlId,
        displayDonorAccountCreation,
    };

    return <PaymentDonorAccountContent {...paymentDonorAccountProps} />;
};

ConnectedPaymentDonorAccountContent.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedPaymentDonorAccountContent;
