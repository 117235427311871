import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import ReceiptSummary from "./ReceiptSummary";

const ConnectedReceiptSummary = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { CONFIRMATION },
        },
    } = constants;

    // Adding the event key to the list of activeEventKeys in the instance of
    // React context used in this instance of the accordion expands the
    // accordion so that element of the Accordion.Collapse is visible
    const yourDetailsEventKey = 0;
    // Setting the list of activeEventKeys to an empty array ensures that the
    // accordion is collapsed on page load
    const collapsedAccordionEventKeys = [];
    const [activeEventKeys, setActiveEventKeys] = useState(
        collapsedAccordionEventKeys,
    );

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, CONFIRMATION),
    );

    const {
        options: {
            accordionLabel = "Your Details",
            accordionAdditionalText = "A copy will be emailed shortly",
            printLinkLabel = "Print",
        },
    } = control;

    // In P2P and event registration it is possible for a donor to register for
    // an event without submitting a payment, however this is not a valid use
    // case for the donation form so we can hard code the displayPaymentDetails
    // value to true.
    const displayPaymentDetails = true;

    const receiptSummaryProps = {
        accordionLabel,
        accordionAdditionalText,
        activeEventKeys,
        control,
        displayPaymentDetails,
        printLinkLabel,
        setActiveEventKeys,
        yourDetailsEventKey,
    };

    return <ReceiptSummary {...receiptSummaryProps} />;
};

ConnectedReceiptSummary.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedReceiptSummary;
