import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import FieldContainer from "../Fields/Field/FieldContainer";
import CardImage from "./CardImage";
import "./Card.scss";

// -------------------------------------------------------------------------
// NOTE: THIS COMPONENT IS DEPRECATED. USE <CardInput/> instead
// -------------------------------------------------------------------------
const CardInner = ({
    checked,
    children,
    title,
    image,
    icon,
    extraTitle,
    renderExtraTitle = (extraTitle) => (
        <div className="card__extra-title">
            <div>
                <span className="-type--large">{extraTitle}</span>
            </div>
        </div>
    ),
}) => {
    const showTitle = title.length > 0;
    const showImage = image && image.url && image.url.length > 0;
    const getIconClassNames = () => {
        const iconClasses = ["card__icon"];
        if (icon && icon.classNames) iconClasses.push(icon.classNames);
        if (checked) iconClasses.push("checked");
        return iconClasses;
    };

    return (
        <div className="qg-vendor-card__inner card__inner">
            {icon && (
                <div className="card__inner__col">
                    <Icon {...icon} classNames={getIconClassNames()} />
                </div>
            )}
            <div className="card__inner__col">
                {showImage > 0 && (
                    <figure className="card__figure">
                        <CardImage {...image} />
                    </figure>
                )}
                <div
                    className={cx(
                        "card__label",
                        checked && "card__label--selected",
                    )}
                >
                    <div className="card__desc-text-container">
                        <div className="card__desc-text">
                            <div className="grid">
                                {showTitle && (
                                    <div className="col col--12 col--no-top-gutter">
                                        <span className="-type--large">
                                            {title}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {extraTitle && (
                            <div className="card__extra-title-container -text--right">
                                {renderExtraTitle(extraTitle)}
                            </div>
                        )}
                    </div>
                    {/* Card content includes breathing room to the right */}
                    <div className="grid">
                        <div className="col col--10 col--no-top-gutter">
                            <div className="card__content">{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CardInner.propTypes = {
    checked: PropTypes.bool,
    title: PropTypes.string.isRequired,
    extraTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    renderExtraTitle: PropTypes.func,
    icon: PropTypes.object,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        altText: PropTypes.string.isRequired,
        display: PropTypes.string,
    }),
    children: PropTypes.any,
};

const Card = memo(
    forwardRef((props, ref) => {
        const { id, value, checked = false, name, handleChange } = props;
        return (
            <FieldContainer
                selected={checked}
                selectedOnHover={true}
                htmlForName={id}
            >
                <label className="card-container">
                    <input
                        id={id}
                        className="-visually-hidden"
                        type="radio"
                        name={name}
                        value={value}
                        checked={checked}
                        onChange={handleChange}
                        ref={ref}
                    />
                    <div className="card -cursor--pointer">
                        <CardInner {...props} />
                    </div>
                </label>
            </FieldContainer>
        );
    }),
);

Card.displayName = "CardRadio";

Card.propTypes = {
    checked: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    extraTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    icon: PropTypes.object,
    name: PropTypes.string.isRequired,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        altText: PropTypes.string.isRequired,
        display: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    children: PropTypes.any,
};

export default Card;
