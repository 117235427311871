export const defaultMerchantAccount = {
    currency: "USD",
};

export const defaultTransaction = {
    address: "123 Testing Road",
    amount: "12.34",
    anonymous: "n",
    assocInfo: "",
    bill_address: "123 Testing Road",
    bill_city: "Testing City",
    bill_country: "US",
    bill_state: "Florida",
    bill_zip: "33809-5034",
    ccLastFour: "1111",
    ccName: "John Doe",
    ccType: "VS",
    channel: "0",
    checkNumber: null,
    city: "Testing City",
    company: "",
    country: "US",
    creatingTransactionFor: "0",
    date: "2024-02-29",
    dedication_fields: [],
    demo: "y",
    donations: [],
    email: "john_doe@test.com",
    events: [],
    fields: [],
    fname: "John",
    form: "12036",
    formName: "Test Form",
    hasCompletedPaymentForInvoice: false,
    hasSubmittedConfirmationFieldsForm: "0",
    id: "1234",
    isExpressCheckout: "0",
    lname: "Doe",
    merchantAccount: {
        ...defaultMerchantAccount,
    },
    optIn: "n",
    org: "12345",
    phone: "1234567890",
    receiptId: "w7jf26draj6rfc4w7dt3dwd3hp9wk7s3",
    recurring: null,
    refunds: [],
    salutation: "",
    source: "p2p",
    source_id: "0",
    state: "Florida",
    status: "A",
    tm_stamp: "2024-02-29 13:14:33",
    tm_stamp_unix: 1709230473,
    zip: "33809-5304",
};

export const defaultReceipt = {
    feeCoverage: null,
    total: 50,
    transaction: {
        ...defaultTransaction,
    },
};

export const defaultEmptyReceipt = {};
