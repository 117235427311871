import React from "react";
import PropTypes from "prop-types";

const SelectedRecipientMessage = ({
    formattedFundraisingGoal,
    hasReachedGoal,
    hasSelectedRecurringDonation,
    isCms,
    percentOfGoalWithDonation,
    selectedRecipientOneTimeImpact,
    selectedRecipientRecurringImpact,
    selectedRecipientOneTimeReached,
    selectedRecipientRecurringReached,
    subtotal,
}) => {
    const createMessageHTML = (message) => {
        const percentReplaceTag = "%GoalPercentage%";
        const messageSplitOnPercent = message.split(percentReplaceTag);
        const messageJoinedPercent = messageSplitOnPercent.join(
            `<span class="selected-recipient__message-flair">${
                percentOfGoalWithDonation > 0 ? percentOfGoalWithDonation : 1
            }%</span>`,
        );
        const goalReplaceTag = "%GoalAmount%";
        const messageSplitOnGoal = messageJoinedPercent.split(goalReplaceTag);
        const messageJoinedPercentAndGoal = messageSplitOnGoal.join(
            `<span class="-font-weight--bold">${formattedFundraisingGoal}</span>`,
        );

        return messageJoinedPercentAndGoal;
    };
    let currentMessage = "";
    if (hasReachedGoal && hasSelectedRecurringDonation) {
        currentMessage = createMessageHTML(selectedRecipientRecurringReached);
    } else if (hasReachedGoal && !hasSelectedRecurringDonation) {
        currentMessage = createMessageHTML(selectedRecipientOneTimeReached);
    } else if (!hasReachedGoal && hasSelectedRecurringDonation) {
        currentMessage = createMessageHTML(selectedRecipientRecurringImpact);
    } else if (!hasReachedGoal && !hasSelectedRecurringDonation) {
        currentMessage = createMessageHTML(selectedRecipientOneTimeImpact);
    } else {
        // this should never run, but doesn't hurt to have a default case
        currentMessage = createMessageHTML(selectedRecipientOneTimeImpact);
    }

    return subtotal > 0 || isCms ? (
        <div className="selected-recipient__message">
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: currentMessage }} />
        </div>
    ) : null;
};

SelectedRecipientMessage.propTypes = {
    formattedFundraisingGoal: PropTypes.string.isRequired,
    hasReachedGoal: PropTypes.bool.isRequired,
    hasSelectedRecurringDonation: PropTypes.bool.isRequired,
    isCms: PropTypes.bool.isRequired,
    percentOfGoalWithDonation: PropTypes.number.isRequired,
    selectedRecipientOneTimeImpact: PropTypes.string.isRequired,
    selectedRecipientRecurringImpact: PropTypes.string.isRequired,
    selectedRecipientOneTimeReached: PropTypes.string.isRequired,
    selectedRecipientRecurringReached: PropTypes.string.isRequired,
    subtotal: PropTypes.number.isRequired,
};

export default SelectedRecipientMessage;
