import { constants, translateDataLayerEvent } from "@qgiv/core-js";

/**
 *
 * @param {number} event DataLayerEvent ENUM.
 * @param {object} dataLayerOptions All of the potential objects that can
 *                 potentially be passed to the data layer.
 * @returns {object} Object with the payload in its final shape.
 */
const createDataLayerPayload = (event, dataLayerOptions) => {
    const {
        ENUMS: { DataLayerEvent },
    } = constants;
    const defaultPayload = {
        contact: {},
        form: {},
        transaction: {},
        utm: {},
    };
    let customDataLayerPayload = {};

    const eventName = translateDataLayerEvent(event);

    if (
        dataLayerOptions &&
        (event === DataLayerEvent.QGIV_DONATION_CLOSE ||
            event === DataLayerEvent.QGIV_DONATION_START)
    ) {
        customDataLayerPayload = {
            event: eventName,
            QGIV: {
                ...defaultPayload,
                contact: dataLayerOptions.contact,
                customFields: dataLayerOptions.customFields,
                dedication: dataLayerOptions.dedication,
                form: dataLayerOptions.form,
                restrictions: dataLayerOptions.restrictions,
            },
        };
    }

    if (
        dataLayerOptions &&
        (event === DataLayerEvent.QGIV_DONATION_COMPLETE ||
            event === DataLayerEvent.QGIV_DONATION_STEP_CHANGE ||
            event === DataLayerEvent.QGIV_DONATION_PAGE_VIEW)
    ) {
        customDataLayerPayload = {
            event: eventName,
            QGIV: {
                ...defaultPayload,
                contact: dataLayerOptions.contact,
                customFields: dataLayerOptions.customFields,
                dedication: dataLayerOptions.dedication,
                form: dataLayerOptions.form,
                restrictions: dataLayerOptions.restrictions,
                transaction: dataLayerOptions.transaction,
            },
        };
    }

    if (
        dataLayerOptions &&
        (event === DataLayerEvent.QGIV_REGISTRATION_CLOSE ||
            event === DataLayerEvent.QGIV_REGISTRATION_START)
    ) {
        customDataLayerPayload = {
            event: eventName,
            QGIV: {
                ...defaultPayload,
                contact: dataLayerOptions.contact,
                form: dataLayerOptions.form,
            },
        };
    }

    if (
        dataLayerOptions &&
        (event === DataLayerEvent.QGIV_REGISTRATION_COMPLETE ||
            event === DataLayerEvent.QGIV_REGISTRATION_STEP_CHANGE ||
            event === DataLayerEvent.QGIV_REGISTRATION_PAGE_VIEW)
    ) {
        customDataLayerPayload = {
            event: eventName,
            QGIV: {
                ...defaultPayload,
                contact: dataLayerOptions.contact,
                form: dataLayerOptions.form,
                transaction: dataLayerOptions.transaction,
            },
        };
    }

    // Create the a payload that is intended to create be received by Google Tag
    // Manager, forwarded along to GA4 and be received by GA4 as a GA4 purchase
    // event. Payload details can be seen here:
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
    if (event === DataLayerEvent.QGIV_GA4_PURCHASE && dataLayerOptions) {
        customDataLayerPayload = {
            event: eventName,
            ecommerce: dataLayerOptions.ecommerce,
        };
    }

    return customDataLayerPayload;
};

export default createDataLayerPayload;
