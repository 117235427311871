import { constants } from "@qgiv/core-js";
import {
    getDisplayApplePayPaymentMethod,
    getDisplayPalSDKScriptProvider,
} from "@qgiv/core-donor";

/**
 * @public
 * @function getDisplayExpressCheckoutOnSettings
 * @description Returns the display flag for Express Checkout overall
 * @param {object} settings an object containing the formSettings and hasLegalField values.
 * @returns {object} display flag for Express Checkout.
 */
export const getDisplayExpressCheckoutOnSettings = (settings) => {
    // -------------------------------------------------------------------------
    // NOTE: This implementation is different from getDisplayExpressCheckout in third-party-payment-helper.js,
    // due to the fact that it does not contain logic for displaying PayPal and Apple Pay. Also, the data for
    // the legal field is obtained through a more PDR-friendly means.
    // -------------------------------------------------------------------------
    const {
        ENUMS: { FormAppearance },
    } = constants;
    const { formSettings = {}, hasLegalField = false } = settings;
    const { appearance = "", expressCheckout = "" } = formSettings;
    // Whether the Express Checkout setting has been toggled on or off
    const hasEnabledExpressCheckout = expressCheckout === true;
    // Generate the individual flags that will be used to create the flag that
    // will be passed to the UI
    const isMultiStepForm = appearance === FormAppearance.MULTIPLE;

    const displayExpressCheckout =
        hasEnabledExpressCheckout && isMultiStepForm && !hasLegalField;

    return displayExpressCheckout;
};

/**
 * @public
 * @function getExpressCheckoutDisplayFlags
 * @description Returns the display flags for the Express Checkout feature.
 * @param {object} settings an object containing the formSettings and hasLegalField values.
 * @param {boolean} shouldDisplayApplePay display value for ApplePayPaymentMethod component
 * @returns {object} display flags for Apple Pay, PayPal, and Express Checkout.
 */
export const getExpressCheckoutDisplayFlags = (
    settings,
    shouldDisplayApplePay,
) => {
    const { formSettings = {} } = settings;
    const { paymentData = {} } = formSettings;
    const { payPalSDK = {}, enablePaypalPayments = false } = paymentData;

    // Generate flags that are used to determine whether or not the payment
    // methods that are supported by Express Checkout should be displayed
    const displayApplePayPaymentMethod = getDisplayApplePayPaymentMethod({
        shouldDisplayApplePay,
    });
    // NOTE: Be aware that 'getDisplayPalSDKScriptProvider' (using 'Pal' rather than 'PayPal')
    // is the spelling that is used within third-party-payment-helpers, and so it should be
    // maintained here, regardless of the typo.
    const displayPayPalSDKScriptProvider = getDisplayPalSDKScriptProvider({
        enablePaypalPayments,
        payPalSDK,
    });

    const displayExpressCheckoutOnSettings =
        getDisplayExpressCheckoutOnSettings(settings);

    const hasPaymentMethodThatSupportsExpressCheckout =
        displayPayPalSDKScriptProvider || displayApplePayPaymentMethod;

    // Generate the flag using the data that was collected so far
    const displayExpressCheckout =
        displayExpressCheckoutOnSettings &&
        hasPaymentMethodThatSupportsExpressCheckout;

    const expressCheckoutDisplayFlags = {
        displayExpressCheckout,
        displayPayPalSDKScriptProvider,
        displayApplePayPaymentMethod,
    };

    return expressCheckoutDisplayFlags;
};

/**
 * @public
 * @function getAddress
 * @description Returns address data received from Apple Pay and converts it to a format we can store in redux.
 * @param {object} args arguments object
 * @param {object} args.billingContact billing contact data received during Apple Pay authorization.
 * @param {string} args.applePayBillingStateName Billing State received from Apple Pay, converted to Qgiv-usable format.
 * @param {object} args.initialValues initialValues
 * @returns {object} object with Address, Address_2*, City, State, Zip and Country values
 */
export const getAddress = ({
    billingContact,
    applePayBillingStateName,
    initialValues,
}) => {
    const mailingAddress = {
        Address: billingContact.addressLines[0],
        City: billingContact.locality,
        State: applePayBillingStateName,
        Zip: billingContact.postalCode,
        Country: billingContact.countryCode,
    };

    const applePayAddress2 = billingContact.addressLines[1];
    let Address2 = initialValues.Address_2;

    // If Apple returned a billing address 2, overwrite the initial value
    if (applePayAddress2) {
        Address2 = applePayAddress2;
    }

    // Overwrite the billing address 2 value as Apple data takes priority
    mailingAddress.Address_2 = Address2;

    return mailingAddress;
};

/**
 * @public
 * @function getDonorDetailsBucket
 * @description Returns donor data received from Apple Pay and converts it to a format we can store in Donor Details in redux.
 * @param {object} args arguments object
 * @param {object} args.billingContact billing contact data received during Apple Pay authorization.
 * @param {string} args.applePayBillingStateName Billing State received from Apple Pay, converted to Qgiv-usable format.
 * @param {object} args.initialValues initialValues
 * @param {object} args.shippingContact shippingContact object received during Apple Pay authorization.
 * @returns {object} object with donor details data to be stored in redux.
 */
export const getDonorDetailsBucket = ({
    billingContact,
    applePayBillingStateName,
    initialValues,
    shippingContact,
}) => {
    const personal = {
        First_Name: billingContact.givenName,
        Last_Name: billingContact.familyName,
        Email: shippingContact.emailAddress,
    };

    const mailingAddress = getAddress({
        billingContact,
        applePayBillingStateName,
        initialValues,
    });

    const donorDetailsBucket = { ...personal, ...mailingAddress };

    return donorDetailsBucket;
};

/**
 * @public
 * @function getBillingBucket
 * @description Returns billing address data received from Apple Pay and converts it to a format we can store in Billing in redux.
 * @param {object} args arguments object
 * @param {object} args.billingContact billing contact data received during Apple Pay authorization.
 * @param {string} args.applePayBillingStateName Billing State received from Apple Pay, converted to Qgiv-usable format.
 * @param {object} args.initialValues initialValues
 * @returns {object} object with Billing_Address, Billing_Address_2*, Billing_City, Billing_State, Billing_Zip and Billing_Country values
 */
export const getBillingBucket = ({
    billingContact,
    applePayBillingStateName,
    initialValues,
}) => {
    const mailingAddress = getAddress({
        billingContact,
        applePayBillingStateName,
        initialValues,
    });
    const mailingAddressKeys = Object.keys(mailingAddress);
    const billingBucket = mailingAddressKeys.reduce(
        (totalBillingAddressObject, currentMailingAddressKey) => {
            const newTotalBillingAddressObject = {
                ...totalBillingAddressObject,
                [`Billing_${currentMailingAddressKey}`]:
                    mailingAddress[currentMailingAddressKey],
            };
            return newTotalBillingAddressObject;
        },
        { Billing_Address_Use_Mailing: false },
    );

    // Overwrite billing name with the billing name provided by Apple Pay, as Apple data takes priority.
    // Do this regardless of whether or not Billing Name field is enabled.
    const billingName = `${billingContact.givenName} ${billingContact.familyName}`;
    billingBucket.Billing_Name = billingName;

    return billingBucket;
};

/**
 * @public
 * @function getPaymentBucket
 * @description Returns payment data received from Apple Pay and converts it to a format we can store in Payment in redux.
 * @param {object} payment the Apple Pay Token data received during Apple Pay authorization
 * @returns {object} object with payment data to be stored in redux.
 */
export const getPaymentBucket = (payment) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const { billingContact } = payment;

    const paymentBucket = {
        Apple_Pay_Token: payment,
        Payment_Type: PaymentType.APPLEPAY,
        Billing_Name: `${billingContact.givenName} ${billingContact.familyName}`,
    };
    return paymentBucket;
};

/**
 * @public
 * @function getApplePayData
 * @description Returns data from Apple Pay authorization to be stored in redux.
 * @param {object} args arguments object
 * @param {string} args.applePayBillingStateName Billing State received from Apple Pay, converted to Qgiv-usable format.
 * @param {object} args.initialValues initialValues
 * @param {object} args.payment the Apple Pay Token data received during Apple Pay authorization
 * @returns {object} object with donor details, billing, and payment data to be stored in redux.
 */
export const getApplePayData = ({
    applePayBillingStateName,
    initialValues,
    payment,
}) => {
    const { billingContact, shippingContact } = payment;
    const dataBuckets = {
        billing: {
            ...getBillingBucket({
                billingContact,
                applePayBillingStateName,
                initialValues,
            }),
        },
        payment: {
            ...getPaymentBucket(payment),
        },
        donorDetails: {
            ...getDonorDetailsBucket({
                billingContact,
                shippingContact,
                applePayBillingStateName,
                initialValues,
            }),
        },
    };

    return dataBuckets;
};

/**
 * @function getHasDisplayableSystemFieldsInConfirmationFields
 * @description Returns whether there are any displayable system fields to be included in confirmation fields
 * @param {object} detailsPageFieldsData
 * @returns {boolean}
 */
export const getHasDisplayableSystemFieldsInConfirmationFields = (
    detailsPageFieldsData,
) => {
    const {
        defaultAnonymousFieldChecked,
        defaultOptInChecked,
        employerFieldIsRequired,
        phoneFieldIsRequired,
        privacyOptionsIsDisplayable,
    } = detailsPageFieldsData;
    const allConfirmationFieldSystemFieldData = [
        defaultAnonymousFieldChecked,
        defaultOptInChecked,
        employerFieldIsRequired,
        phoneFieldIsRequired,
        privacyOptionsIsDisplayable,
    ];
    const displayableSystemFieldData =
        allConfirmationFieldSystemFieldData.filter((field) => field.exists);
    const hasDisplayableSystemFields = !!displayableSystemFieldData.length;
    return hasDisplayableSystemFields;
};
