import React from "react";
import { currencyString } from "@qgiv/core-js";

/**
 * @typedef {import("@qgiv/donation-form").FormikTypes.GiftPage.MultiRestrictionFieldValue} MultiRestrictionFieldValue
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 * @typedef {import("@qgiv/donation-form").ReduxTypes.FormSettings.FormSettings} FormSettings
 * @typedef {import("./standardAmountsRadioOptionsTypes").StandardAmountProps} StandardAmountProps
 */

/**
 *
 * @param {object} settings
 * @param {string} settings.buttonStyle
 * @param {MultiRestrictionFieldValue} settings.existingMultiRestrictionFieldValue
 * @param {FormSettings} settings.formSettings
 * @param {string} settings.multiRestrictionAmountIdFieldName
 * @param {string} settings.nameOfAmountIdField
 * @param {Amount[]} settings.standardAmountsToDisplay
 * @param {React.RefObject[]} settings.standardAndOtherAmountRefs
 * @returns {StandardAmountProps[]}
 */
export const getStandardAmounts = ({
    buttonStyle,
    existingMultiRestrictionFieldValue,
    formSettings,
    multiRestrictionAmountIdFieldName,
    nameOfAmountIdField,
    standardAmountsToDisplay,
    standardAndOtherAmountRefs,
}) => {
    const { currency = {} } = formSettings;

    // Create variables that will be used in every standard amount instance
    // Hard code containerClasses & displayAsCard as amounts should never be
    // displayed as a card in the multi-restriction UI
    const containerClasses = " col--6 col--xsm-4 col--sm-3";
    const displayAsCard = false;
    // The name of the field has to be associated with the multi-restriction
    // field in order for the handleStandardAmountChange() function to know
    // which value needs to be modified. If it set to Selected_One_Time_Id
    // or Selected_Recurring_Id it will always modify the amount on the first
    // field
    const name = multiRestrictionAmountIdFieldName;

    /**
     *
     * @param {Amount} standardAmount Data for amount attached to restriction.
     * @returns {object} Data to pass into the StandardAmountsRadioOptions component.
     */
    const getStandardAmount = (standardAmount) => {
        const { id = 0, amount = 0 } = standardAmount;

        // Reassign and create variables as needed
        const checked =
            existingMultiRestrictionFieldValue[nameOfAmountIdField] ===
            standardAmount.id;
        // The label value appears correct when the form is using the default
        // version of the currency settings but it does not behave as expected
        // once the currency settings have been changed. However, I have decided
        // to leave this as is for now as I am using the exact same function call
        // and it behaves the exact same way when multi-restriction giving is
        // turned off.
        const label = currencyString(amount, currency);

        const standardAmountProps = {
            buttonStyle,
            checked,
            containerClasses,
            displayAsCard,
            // Used to generate the key values in the
            // StandardAmountsRadioOptions component
            id,
            label,
            name,
            standardAndOtherAmountRefs,
        };

        return standardAmountProps;
    };

    const standardAmounts = standardAmountsToDisplay.map(getStandardAmount);

    return standardAmounts;
};
