import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getAttributesForSubRestrictionOptionFromList,
    getMultiRestrictionFieldName,
    getRestrictionById,
    getSelectedSubRestrictionFromList,
    getSubRestrictionAriaLabel,
    getSubRestrictionDescription,
    getSubRestrictionLabel,
    getSubRestrictionShowDescription,
    getSubRestrictionShowList,
    getSubRestrictionsList,
    SubRestrictions,
} from "@qgiv/donation-form";
import { selectRestrictions } from "../../../../../../../redux/slices/restrictionSettingsSlice";

/**
 * @param {object} props
 * @param {number} props.restrictionId
 * @returns {React.ReactElement}
 */
const ConnectedSubRestrictions = ({ restrictionId }) => {
    const formikContext = useFormikContext();
    const restrictions = useSelector(selectRestrictions);
    const {
        handleBlur = () => {},
        setFieldValue = () => {},
        values = {},
    } = formikContext;

    // Gather restriction data
    const multiRestrictionFieldName =
        getMultiRestrictionFieldName(restrictionId);
    const existingMultiRestrictionFieldValue =
        values[multiRestrictionFieldName];
    const restriction = getRestrictionById(restrictions, restrictionId);

    // Get sub-restriction data
    const subRestrictionsList = getSubRestrictionsList(
        restrictions,
        restrictionId,
    );
    const selectedSubRestrictionFromList = getSelectedSubRestrictionFromList(
        subRestrictionsList,
        existingMultiRestrictionFieldValue.Sub_Restriction,
    );
    const subRestrictionLabel = getSubRestrictionLabel(
        restrictions,
        restrictionId,
    );
    const fieldNameInFormik = "Sub_Restriction";

    /**
     *
     * @param {React.BaseSyntheticEvent} e
     * @returns {undefined}
     */
    const handleChange = (e) => {
        const newSubRestrictionValue = e.currentTarget.value;
        const newFieldValue = {
            ...existingMultiRestrictionFieldValue,
            Sub_Restriction: newSubRestrictionValue,
        };

        setFieldValue(multiRestrictionFieldName, newFieldValue);
    };

    // Assemble data into props
    const ariaLabel = getSubRestrictionAriaLabel(subRestrictionLabel);
    const description = getSubRestrictionDescription(
        selectedSubRestrictionFromList,
    );
    const id = fieldNameInFormik;
    const label = subRestrictionLabel;
    const name = fieldNameInFormik;
    const showDescription = getSubRestrictionShowDescription(
        selectedSubRestrictionFromList,
    );
    const showList = getSubRestrictionShowList(restriction);
    const subRestrictionListOptions = showList
        ? subRestrictionsList.map(getAttributesForSubRestrictionOptionFromList)
        : [];
    const value = existingMultiRestrictionFieldValue.Sub_Restriction;

    // Consolidate props into objects that can be associated with the
    // components that are rendered by the SubRestrictions component. Unlike
    // the basic sub-restriction component we need to write some custom logic
    // to handle the change event instead of just relying on Formik's built
    // in handleChange function.
    const subRestrictionsListProps = {
        ariaLabel,
        handleBlur,
        handleChange,
        id,
        label,
        name,
        subRestrictionListOptions,
        value,
    };

    const writeTheirOwnSubRestrictionsProps = {
        ariaLabel,
        handleBlur,
        handleChange,
        name,
        label,
        value,
    };

    const subRestrictionsProps = {
        description,
        showDescription,
        showList,
        subRestrictionsListProps,
        writeTheirOwnSubRestrictionsProps,
    };

    return <SubRestrictions {...subRestrictionsProps} />;
};

export default ConnectedSubRestrictions;
