import { createSlice } from "@reduxjs/toolkit";
import { defaultFormThemeColor } from "@qgiv/core-donor";

import { formSettings as initialState } from "../initialState";

const getFormSettingsInitialState = () => initialState;
export const formSettingsInitialState = getFormSettingsInitialState();

export const slice = createSlice({
    name: "formSettings",
    initialState: formSettingsInitialState,
    reducers: {
        setFormSettings: (state, action) => action.payload,
    },
});

// actions
export const { setFormSettings } = slice.actions;

// selectors
export const selectAllFormSettings = (state) => state.formSettings;

export const selectPaymentData = (state) => state.formSettings.paymentData;

// Good for when you only need one key
export const selectSpecificFormSetting = (state, settingName) => {
    const settingToFind = settingName;

    return state.formSettings[settingToFind];
};

export const selectFormThemeColor = (state) =>
    state?.formSettings?.theme?.color || defaultFormThemeColor;

export const selectFormThemeFontStack = (state) =>
    state.formSettings?.theme?.font_family_stack || "";

export default slice.reducer;
