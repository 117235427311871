import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { WidgetField } from "@qgiv/core-donor";
import { selectCmsControlById } from "../../redux/slices/cmsSettingsSlice";
import { selectAllFieldsAndGroups } from "../../redux/slices/fieldsSlice";
import { selectConfig } from "../../redux/slices/configSettingsSlice";
import { selectAllDonationSettings } from "../../redux/slices/donationSettingsSlice";
import {
    selectGiftDetails,
    selectHasSelectedRecurringDonation,
} from "../../redux/slices/donationDetailsSlice";

const ConnectedWidgetFieldControl = ({ controlId }) => {
    // gets cms control data from redux
    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);

    const giftDetails = useSelector(selectGiftDetails);
    const { subtotal } = giftDetails;
    // The Optional display for fields needs this donationAmountFromRedux
    // value passed for determining the required value for the field. Previously
    // this was determined with "total", but after discussion both the Optional display
    // and the field's required status should be determined by the subtotal value.
    const donationAmountFromRedux = subtotal;

    const config = useSelector(selectConfig);
    const donationSettings = useSelector(selectAllDonationSettings);
    const { allow_company_contributions } = donationSettings;
    // Settings inferred for convenience
    const allowIndividualContributions =
        parseInt(allow_company_contributions, 10) < 2;
    const allowCompanyContributions =
        parseInt(allow_company_contributions, 10) > 0;

    const hasSelectedRecurringDonation = useSelector(
        selectHasSelectedRecurringDonation,
    );

    const conditionalLogicOptions = useMemo(
        () => ({
            donationAmountFromRedux,
            hasSelectedRecurringDonation,
            usesReduxDonationAmountValue: true,
            usesReduxRecurringValue: true,
        }),
        [donationAmountFromRedux, hasSelectedRecurringDonation],
    );

    const settings = useMemo(
        () => ({
            // TODO: to be added with invoices
            activePromiseTransaction: false,
            allFieldsAndGroups,
            allowCompanyContributions,
            allowIndividualContributions,
            conditionalLogicOptions,
            config,
            donationAmountFromRedux,
            useNewConditionalLogicFunctions: true,
        }),
        [
            allFieldsAndGroups,
            allowCompanyContributions,
            allowIndividualContributions,
            conditionalLogicOptions,
            config,
            donationAmountFromRedux,
        ],
    );

    const { errors, values, touched, setFieldValue, handleChange, handleBlur } =
        useFormikContext();

    const componentProps = useMemo(
        () => ({
            control,
            settings,
            errors,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
            values,
        }),
        [
            control,
            settings,
            errors,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
            values,
        ],
    );

    return <WidgetField {...componentProps} />;
};

ConnectedWidgetFieldControl.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default ConnectedWidgetFieldControl;
