import { useEffect } from "react";
import PropTypes from "prop-types";
import { constants, log } from "@qgiv/core-js";
import { validateFormAfterFormValuesHaveUpdated } from "@qgiv/core-validation";

const UpdateShouldRevertPaymentType = ({
    initialPaymentTypeValue,
    values,
    validateForm,
    setValues,
    totalAmount,
}) => {
    const { Payment_Type } = values;
    const {
        ENUMS: { PaymentType },
    } = constants;
    // If the Payment_Type has been set to Apple Pay because the user clicked
    // the Apple Pay button and the transaction fails or is cancelled, it is
    // possible for the user to modify the registration so that the total value
    // of the registration is now 0. If that happens the Payment_Type needs
    // to be reverted to its initial value from Apple Pay because as long as
    // the Payment_Type is set to Apple Pay the user will not be able to see
    // submit button on the payment page which they need to click in order to
    // complete registration when the total value of the registration is 0.
    useEffect(() => {
        if (Payment_Type === PaymentType.APPLEPAY && totalAmount <= 0) {
            const initialApplePayTokenValue = "";
            const initialApplePayValues = {
                Payment_Type: initialPaymentTypeValue,
                Apple_Pay_Token: initialApplePayTokenValue,
            };
            // Construct a new values object with initial Apple Pay data
            const valuesWithoutApplePayData = {
                ...values,
                ...initialApplePayValues,
            };

            const handleValidationSuccess = () => {
                log("ATTEMPT TO VALIDATE: SUCCESS");
            };

            const handleValidationError = (error) => {
                log("ATTEMPT TO VALIDATE: FAILURE");
                log(error);
            };

            // Reset Payment_Type and Apple_Pay_Token values
            setValues(valuesWithoutApplePayData);

            // Validate the form once the Payment_Type value has been updated so
            // that the appropriate errors object is generated
            validateFormAfterFormValuesHaveUpdated(
                validateForm,
                handleValidationSuccess,
                handleValidationError,
            );
        }
    }, [totalAmount]);

    return null;
};

UpdateShouldRevertPaymentType.propTypes = {
    initialPaymentTypeValue: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    totalAmount: PropTypes.number.isRequired,
};

export default UpdateShouldRevertPaymentType;
