export const defaultStandardRestrictions = [
    {
        id: 1234,
        org: 21213,
        form: 11699,
        name: "Restriction 1",
        export_data: "",
        boxHead: "",
        description: "",
        showDescription: false,
        children: [],
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: true,
        displayTxtToDonate: false,
        displayVT: true,
        weight: 0,
        custom: 0,
        customRestrictionType: 1,
        status: 1,
        created: "2023-12-28 15:17:48",
        lastAction: "2023-12-28 15:17:48",
        imageURL: "",
        useCustomAmounts: 0,
        image: "",
        attachAmounts: 1,
    },
    {
        id: 1235,
        org: 21213,
        form: 11699,
        name: "Restriction 2",
        export_data: "",
        boxHead: "",
        description: "Description for Restriction 2",
        showDescription: false,
        children: [],
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: true,
        displayTxtToDonate: false,
        displayVT: true,
        weight: 0,
        custom: 0,
        customRestrictionType: 1,
        status: 1,
        created: "2023-12-28 15:17:48",
        lastAction: "2023-12-28 15:17:48",
        imageURL: "",
        useCustomAmounts: 0,
        image: "",
        attachAmounts: 1,
    },
];

export const defaultStandardRestrictionsDesktopOnly = [
    {
        id: 1240,
        org: 21213,
        form: 11699,
        name: "Restriction 1",
        export_data: "",
        boxHead: "",
        description: "",
        showDescription: false,
        children: [],
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: false,
        displayTxtToDonate: false,
        displayVT: true,
        weight: 0,
        custom: 0,
        customRestrictionType: 1,
        status: 1,
        created: "2023-12-28 15:17:48",
        lastAction: "2023-12-28 15:17:48",
        imageURL: "",
        useCustomAmounts: 0,
        image: "",
        attachAmounts: 1,
    },
    {
        id: 1241,
        org: 21213,
        form: 11699,
        name: "Restriction 2",
        export_data: "",
        boxHead: "",
        description: "Description for Restriction 2",
        showDescription: false,
        children: [],
        displayFrontend: true,
        displayKiosk: false,
        displayMobile: false,
        displayTxtToDonate: false,
        displayVT: true,
        weight: 0,
        custom: 0,
        customRestrictionType: 1,
        status: 1,
        created: "2023-12-28 15:17:48",
        lastAction: "2023-12-28 15:17:48",
        imageURL: "",
        useCustomAmounts: 0,
        image: "",
        attachAmounts: 1,
    },
];

const restrictionLabel = "Please direct my support";
const selectedRestrictionDescription =
    defaultStandardRestrictions[0].description;

export const basicRestrictionProps = {
    activeDisplayableRestrictions: defaultStandardRestrictions,
    ariaLabel: restrictionLabel.length
        ? restrictionLabel
        : "Choose a Restriction",
    fieldId: "Standard_Restriction",
    fieldName: "Standard_Restriction_Id",
    handleBlur: () => {},
    handleRestrictionChange: () => {},
    restrictionLabel,
    selectedRestrictionDescription: "",
    showRestrictionDescription: selectedRestrictionDescription.length > 0,
    Standard_Restriction_Id: 1234,
};

export const basicRestrictionPropsWithDescription = {
    ...basicRestrictionProps,
    selectedRestrictionDescription: "Description goes here",
    showRestrictionDescription: true,
};
