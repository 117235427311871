import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { StoredPaymentMethods } from "@qgiv/core-donor";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectDonorAccount } from "../../../../redux/slices/donorAccountSlice";

const ConnectedStoredPaymentMethods = ({
    controlId,
    hasToggledPaymentMethodView,
}) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
        },
    } = constants;

    const { handleChange, values } = useFormikContext();

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );

    const {
        options: { savedPaymentMethodsSectionHeading },
    } = control;

    const donorAccount = useSelector(selectDonorAccount);

    return (
        <StoredPaymentMethods
            handleChange={handleChange}
            hasToggledPaymentMethodView={hasToggledPaymentMethodView}
            donorAccount={donorAccount}
            savedPaymentMethodsSectionHeading={
                savedPaymentMethodsSectionHeading
            }
            values={values}
        />
    );
};

ConnectedStoredPaymentMethods.propTypes = {
    controlId: PropTypes.number.isRequired,
    hasToggledPaymentMethodView: PropTypes.bool.isRequired,
};

export default ConnectedStoredPaymentMethods;
