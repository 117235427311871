import { constants, isDisplayable } from "@qgiv/core-js";
import { getRestrictionById } from "../standardRestrictionsHelpers";

const { CustomRestrictionType } = constants.ENUMS;

/**
 * @public
 * @function getSubRestrictionsList
 * @param {Array} activeRestrictions Active restrictions
 * @param {number} id Id of restriction whose sub-restrictions we are looking for
 * @returns {Array} Selected Restriction's sub-restrictions
 */
export const getSubRestrictionsList = (activeRestrictions, id) => {
    const restriction = getRestrictionById(activeRestrictions, id);
    return restriction && restriction?.children ? restriction.children : [];
};

/**
 * @public
 * @function getSubRestrictionLabel
 * @param {Array} activeRestrictions Active restrictions
 * @param {number} id Id of a restriction that has sub-restrictions
 * @returns {string} Sub-restriction label
 */
export const getSubRestrictionLabel = (activeRestrictions, id) => {
    const restriction = getRestrictionById(activeRestrictions, id);
    return restriction && restriction?.boxHead?.length > 0
        ? restriction.boxHead
        : "";
};

/**
 *
 * @param {object} selectedSubRestrictionFromList
 * @returns {string}
 */
export const getSubRestrictionDescription = (
    selectedSubRestrictionFromList,
) => {
    if (
        selectedSubRestrictionFromList &&
        selectedSubRestrictionFromList.description
    ) {
        return selectedSubRestrictionFromList.description;
    }
    return "";
};

/**
 *
 * @param {string} subRestrictionLabel
 * @returns {string}
 */
export const getSubRestrictionAriaLabel = (subRestrictionLabel) => {
    if (subRestrictionLabel && subRestrictionLabel.length) {
        return subRestrictionLabel;
    }
    return "Choose a Sub-Restriction";
};

/**
 *
 * @param {object} selectedSubRestrictionFromList
 * @returns {boolean}
 */
export const getSubRestrictionShowDescription = (
    selectedSubRestrictionFromList,
) => {
    if (
        selectedSubRestrictionFromList &&
        selectedSubRestrictionFromList.description
    ) {
        return selectedSubRestrictionFromList.description.length > 0;
    }
    return false;
};

/**
 *
 * @param {object} restriction
 * @returns {boolean}
 */
export const getSubRestrictionShowList = (restriction) =>
    restriction.customRestrictionType ===
    CustomRestrictionType.SELECT_FROM_LIST;

/**
 *
 * @param {Array} activeRestrictions Active restrictions
 * @param {number} id Id of a restriction that has sub-restrictions
 * @returns {object} Sub-restriction object for the first sub-restriction
 * associated with the restriction.
 */
export const getInitialSubRestrictionValue = (activeRestrictions, id) => {
    const restriction = getRestrictionById(activeRestrictions, id);
    const subRestrictionsList = getSubRestrictionsList(activeRestrictions, id);
    const initialWriteTheirOwnValue = "";

    // The custom key, not the children key is the flag that indicates that a
    // restriction has sub-restrictions as the children array is empty if the
    // org created a write your own sub-restriction
    const hasValueFromListToDisplay =
        !!restriction.custom &&
        restriction.customRestrictionType ===
            CustomRestrictionType.SELECT_FROM_LIST &&
        subRestrictionsList.length > 0 &&
        subRestrictionsList[0].name;
    const initialSubRestrictionListValue = hasValueFromListToDisplay
        ? subRestrictionsList[0].name
        : "";

    const initialSubRestrictionValue =
        initialSubRestrictionListValue || initialWriteTheirOwnValue;

    return initialSubRestrictionValue;
};

/**
 *
 * @param {Array} activeRestrictions Active restrictions
 * @param {number} currentDisplay Type of display that the form is being viewed on
 * @returns {boolean} Flag that denotes whether the form contains a restriction
 * with sub-restrictions that can be displayed on the current device
 */
export const getHasDisplayableSubRestrictions = (
    activeRestrictions,
    currentDisplay,
) => {
    const visibleRestrictions = activeRestrictions.filter((restriction) => {
        const isDisplayableRestriction = isDisplayable(
            restriction,
            currentDisplay,
        );
        return isDisplayableRestriction;
    });
    // The custom key, not the children key is the flag that indicates that a
    // restriction has sub-restrictions as the children array is empty if the
    // org created a write your own sub-restriction
    const hasDisplayableSubRestrictions = visibleRestrictions.some(
        (restriction) => !!restriction.custom,
    );
    return hasDisplayableSubRestrictions;
};

/**
 *
 * @param {object} activeRestriction An individual restriction
 * @returns {boolean} Flag that denotes whether restriction has a
 * sub-restriction we need to display.
 */
export const getHasDisplayableSubRestriction = (activeRestriction) => {
    // The custom key, not the children key is the flag that indicates that a
    // restriction has sub-restrictions as the children array is empty if the
    // org created a write your own sub-restriction
    const hasSubRestrictionsToDisplay = !!activeRestriction.custom;
    return hasSubRestrictionsToDisplay;
};

/**
 * @public
 * @function getSelectedSubRestrictionFromList
 * @param {Array} subRestrictionsList Sub-restrictions associated with a restriction
 * @param {string} name Name of sub-restriction we are searching for
 * @returns {object} Sub-restriction object
 */
export const getSelectedSubRestrictionFromList = (subRestrictionsList, name) =>
    subRestrictionsList.find(
        (subRestrictionListItem) => subRestrictionListItem?.name === name,
    ) || {};

/**
 *
 * @param {object} subRestrictionListItem Sub-restriction list item.
 * @param {number} i The index of the current element being processed in the array.
 * @returns {object} Object with all of the data needed by the different option
 * elements.
 */
export const getAttributesForSubRestrictionOptionFromList = (
    subRestrictionListItem,
    i,
) => {
    const key = `subRestriction_${i}`;
    const name = subRestrictionListItem.name || "";
    const value = subRestrictionListItem.name || "";
    const subRestrictionOption = {
        key,
        name,
        value,
    };
    return subRestrictionOption;
};
