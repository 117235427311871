import { createSlice } from "@reduxjs/toolkit";
import { currentReceipt as initialState } from "../initialState";

const getCurrentReceiptInitialState = () => initialState;
export const currentReceiptInitialState = getCurrentReceiptInitialState();

export const slice = createSlice({
    name: "currentReceipt",
    initialState: currentReceiptInitialState,
    reducers: {
        setCurrentReceipt: (state, action) => action.payload,
    },
});

// actions
export const { setCurrentReceipt } = slice.actions;

// selectors
export const selectCurrentReceipt = (state) => state.currentReceipt;

export default slice.reducer;
