import React from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getRangeOfNumbers } from "@qgiv/core-js";
import { selectAllDonationSettings } from "../../../../../../../redux/slices/donationSettingsSlice";
import NumberOfInstallmentsSelect from "./NumberOfInstallmentsSelect";
import { updateGiftDetails } from "../../../../../../../redux/slices/donationDetailsSlice";
import { selectCurrentPage } from "../../../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../../../redux/slices/cmsSettingsSlice";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement} - The rendered NumberOfInstallmentsSelect component.
 */
const ConnectedNumberOfInstallmentsSelect = ({ controlId }) => {
    // will need a way to grab the currently selected plans available installment counts
    const { activePlans } = useSelector(selectAllDonationSettings);
    const dispatch = useDispatch();
    const { values, handleChange, handleBlur } = useFormikContext();

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options = {} } = control;
    const {
        numberOfInstallmentsLabel = "Number of installments",
        numberOfInstallmentsPlaceholderText = "Select a plan to see options.",
    } = options;

    const { Installment_Count = 0 } = values;

    const selectedInstallment =
        activePlans.find((plan) => Number(plan.id) === values.Selected_Plan) ||
        {};

    const planSelected = Object.keys(selectedInstallment).length > 0;

    let availableInstallmentOptions = [];
    if (planSelected) {
        availableInstallmentOptions = getRangeOfNumbers(
            selectedInstallment.minimumNumberOfInstallments,
            selectedInstallment.maximumNumberOfInstallments,
        );
    }

    const handleInstallmentChange = (e) => {
        const { value } = e.target;
        const formikUpdateValues = {
            Installment_Count: Number(value),
        };
        dispatch(updateGiftDetails({ ...formikUpdateValues }));
        handleChange(e);
    };

    return (
        <NumberOfInstallmentsSelect
            availableInstallmentOptions={availableInstallmentOptions}
            disableBlankOption
            handleChange={handleInstallmentChange}
            Installment_Count={Number(Installment_Count)}
            handleBlur={handleBlur}
            label={numberOfInstallmentsLabel}
            placeholderText={numberOfInstallmentsPlaceholderText}
        />
    );
};

export default ConnectedNumberOfInstallmentsSelect;
