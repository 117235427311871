/* eslint-disable react/prop-types */
import React from "react";
import { SEO, UpdateFieldErrorFocus } from "@qgiv/core-donor";
import { Form, Formik } from "formik";
import ConnectedDonationFormBase from "../Pages/templates/DonationFormBase";
import ModalContentReplacer from "../common/ModalContentReplacer";
import ConnectedSinglePageContent from "./SinglePageContent";
import ConnectedSubmitDonation from "../common/SubmitDonation";

/**
 * @param {object} props - The component props.
 * @param {React.ReactElement} props.alternateContent - Alternate content to display in the form.
 * @param {string} props.documentTitle - The title of the document for SEO purposes.
 * @param {string} props.selectedContentToDisplay - The selected content to display in the form.
 * @param {object} props.formikProps - The Formik props to pass to the form validation, initial
 *    values and touched settings.
 * @param {object} props.skipAdditionalDetailsPageProps - The props to pass to the skip additional
 * @param {Function} props.submitDonation - The function to call when the form is submitted.
 * @returns {React.ReactElement} The rendered single-page donation form.
 */
const SinglePage = ({
    alternateContent,
    documentTitle = "",
    formikProps,
    selectedContentToDisplay,
    skipAdditionalDetailsPageProps,
    submitDonation,
}) => (
    <>
        <SEO title={documentTitle} description="" />
        <Formik {...formikProps}>
            <ConnectedDonationFormBase>
                <ModalContentReplacer
                    selectedContentToDisplay={selectedContentToDisplay}
                    alternateContent={alternateContent}
                >
                    <Form>
                        <ConnectedSinglePageContent
                            {...skipAdditionalDetailsPageProps}
                        />
                    </Form>
                </ModalContentReplacer>
                {submitDonation && <ConnectedSubmitDonation />}
                <UpdateFieldErrorFocus />
            </ConnectedDonationFormBase>
        </Formik>
    </>
);

export default SinglePage;
