import { constants } from "@qgiv/core-js";

const {
    ENUMS: { ItemType },
} = constants;

/**
 * @param {object} selectedPlan - The selected plan object.
 * @param {object} defaultRecurringFrequency - The default recurring frequency object.
 * @returns {string} The recurring frequency value.
 */
const getPlanRecurringFrequency = (selectedPlan, defaultRecurringFrequency) => {
    let recurringFrequency;
    const selectedPlanIsCreateYourOwnPlan =
        parseInt(selectedPlan.pledgeType, 10) === ItemType.CREATE_YOUR_OWN;
    if (selectedPlanIsCreateYourOwnPlan) {
        recurringFrequency = defaultRecurringFrequency.value;
    } else {
        recurringFrequency = selectedPlan.frequencyEnum;
    }

    return recurringFrequency;
};

export { getPlanRecurringFrequency };
