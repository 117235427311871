import { qfetch } from "@qgiv/core-js";

/**
 * @function alertSessionStorageUsed
 * @description sends notification to the BE that sessionStorage form data was used to fill out a form.
 *              currently, the only time this happens is when DataDome redirects a donor after completing a captcha.
 * @param {string|number} entity entity value from formSettings
 * @param {string|number} entityType entityType value from formSettings
 * @returns {Promise} qfetch api promise
 */
const alertSessionStorageUsed = (entity, entityType) =>
    qfetch.post("/api/v1/internal/log_datadome_refresh", {
        entity,
        entityType,
    });

export default alertSessionStorageUsed;
