import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import QFieldInput from "../QFieldInput";

const CustomFieldGroup = (props) => {
    const { field } = props;
    const {
        fields,
        options: { fieldsOnSeparateLines },
    } = field;
    const fieldColClassnames = cx(
        "qg-vendor-field-group__field",
        "col",
        fieldsOnSeparateLines === "1" && "col--12",
        "field-group__field",
    );
    return (
        <div className=" qg-vendor_field-group grid field-group">
            {fields.map((_field, i) => (
                <div className={fieldColClassnames} key={i}>
                    <QFieldInput {...props} field={_field} />
                </div>
            ))}
        </div>
    );
};

CustomFieldGroup.propTypes = {
    field: PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.object).isRequired,
        options: PropTypes.shape({
            fieldsOnSeparateLines: PropTypes.string,
        }).isRequired,
        title: PropTypes.string.isRequired,
    }),
};

export default CustomFieldGroup;
