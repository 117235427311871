import { constants } from "@qgiv/core-js";
import { calcGiftAssistFee } from "@qgiv/core-donor";
import {
    getActiveDisplayableRestrictions,
    getMultiRestrictionFieldNames,
    getNameOfAmountIdField,
    getNameOfOtherAmountField,
    getSelectedAmount,
    getShouldRenderMultiRestriction,
} from "@qgiv/donation-form";
import {
    config,
    donationSettings,
    formSettings,
    restrictionSettings,
    promiseTransaction,
} from "../../initialState";
import { smsDataInitialState as smsData } from "../../slices/smsDataSlice";

const {
    ENUMS: { DisplayOn, ItemType, GivingPlanSystemType },
} = constants;

const getGiftAssistTotal = (giftAssistData) => {
    const {
        Gift_Assist,
        subtotal,
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
    } = giftAssistData;
    return Gift_Assist
        ? Number(
              calcGiftAssistFee(subtotal, {
                  feeCoverage,
                  feeCoverageFlat,
                  feeCoveragePercentage,
              }),
          )
        : 0;
};

const getFlagsForDonationType = (updatedDonationDetails) => {
    const {
        activeAmounts,
        activePlans,
        pledgeActive,
        restrictionGivingType,
        givingPlanSystemType,
    } = donationSettings;

    const { hasSelectedRecurringDonation, Selected_Plan } =
        updatedDonationDetails;

    const shouldRenderMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    const selectedPlan =
        activePlans?.find(
            (plan) => Number(plan.id) === Number(Selected_Plan),
        ) || {};

    const selectedPlanIsCreateYourOwnPlan =
        parseInt(selectedPlan?.pledgeType, 10) === ItemType.CREATE_YOUR_OWN;

    const isInvoiceFulfillment = Object.keys(promiseTransaction).length > 0;

    const isPredefinedCreateYourOwnPlan =
        !isInvoiceFulfillment &&
        pledgeActive &&
        activePlans?.length > 0 &&
        hasSelectedRecurringDonation &&
        givingPlanSystemType === GivingPlanSystemType.PREDEFINED &&
        selectedPlanIsCreateYourOwnPlan;

    const isPredefinedGivingPlan =
        !isInvoiceFulfillment &&
        pledgeActive &&
        activePlans?.length > 0 &&
        hasSelectedRecurringDonation &&
        givingPlanSystemType === GivingPlanSystemType.PREDEFINED &&
        !selectedPlanIsCreateYourOwnPlan;

    const isFlexibleGivingPlan =
        !isInvoiceFulfillment &&
        pledgeActive &&
        activePlans?.length > 0 &&
        hasSelectedRecurringDonation &&
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE &&
        !selectedPlanIsCreateYourOwnPlan;

    const isFlexibleCreateYourOwnPlan =
        !isInvoiceFulfillment &&
        pledgeActive &&
        activePlans?.length > 0 &&
        hasSelectedRecurringDonation &&
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE &&
        selectedPlanIsCreateYourOwnPlan;

    // The multi-restriction UI is not displayed if the donor is submitting a
    // T2D donation, giving plans are enabled & the donor is on the ongoing tab
    const isMultiRestriction =
        shouldRenderMultiRestriction &&
        !isInvoiceFulfillment &&
        !isPredefinedGivingPlan &&
        !isPredefinedCreateYourOwnPlan;

    const isStandardOneTimeOrRecurring =
        !isInvoiceFulfillment &&
        !isMultiRestriction &&
        ((activeAmounts?.length > 0 && !pledgeActive) ||
            // Giving Plans can be enabled but not have activePlans
            // in which case only the one time tab is displayed
            (pledgeActive && !hasSelectedRecurringDonation));

    return {
        isMultiRestriction,
        isStandardOneTimeOrRecurring,
        isPredefinedCreateYourOwnPlan,
        isPredefinedGivingPlan,
        isFlexibleGivingPlan,
        isFlexibleCreateYourOwnPlan,
        isInvoiceFulfillment,
    };
};

export const getPredefinedGivingPlanUpdatedTotals = (
    updatedDonationDetails,
) => {
    const { activePlans, givingPlanSystemType } = donationSettings;
    const { feeCoverage, feeCoverageFlat, feeCoveragePercentage } =
        formSettings;
    const { Selected_Plan = "", Gift_Assist = "" } = updatedDonationDetails;

    const selectedPlanData =
        activePlans.find((plan) => plan.id === Number(Selected_Plan)) || {};

    let subtotal = 0;

    if (
        Object.keys(selectedPlanData).length > 0 &&
        givingPlanSystemType === GivingPlanSystemType.PREDEFINED
    ) {
        subtotal = parseFloat(selectedPlanData.amount) || 0;
    }

    const giftAssistData = {
        Gift_Assist,
        subtotal,
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
    };
    const giftAssistAmount = getGiftAssistTotal(giftAssistData);

    const total = Number(subtotal) + giftAssistAmount;

    const updatedTotals = {
        total,
        subtotal,
        giftAssistAmount,
    };
    return { ...updatedTotals };
};

// Due to rounding, we need to round the 2nd decimal point up to ensure
// the org always gets the Plan amount.
// Given a subtotal of 13.33333333, if the plan was $200 over 15 installments,
// we would end up just short of $200 with $199.95.
// Math.ceil(subtotal * 100) = 1334
// 1334 / 100 = 13.34
// 13.34 * 15 = $200.10
// This decision was made intentionally so that the org always gets their plan amount
// and the remainder is applied to the plan total
const roundFloatingValues = (subtotal) => Math.ceil(subtotal * 100) / 100;

export const getFlexibleGivingPlanUpdatedTotals = (updatedDonationDetails) => {
    const { activePlans, givingPlanSystemType } = donationSettings;
    const { feeCoverage, feeCoverageFlat, feeCoveragePercentage } =
        formSettings;
    const {
        Selected_Plan = "",
        Gift_Assist = "",
        Installment_Count = "",
    } = updatedDonationDetails;

    const selectedPlanData =
        activePlans.find((plan) => plan.id === Number(Selected_Plan)) || {};

    let subtotal = 0;

    if (
        Object.keys(selectedPlanData).length > 0 &&
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE
    ) {
        subtotal =
            parseFloat(
                Number(selectedPlanData.amount) / Number(Installment_Count),
            ) || 0;
        subtotal = roundFloatingValues(subtotal);
    }

    const giftAssistData = {
        Gift_Assist,
        subtotal,
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
    };
    const giftAssistAmount = getGiftAssistTotal(giftAssistData);

    const total = Number(subtotal) + giftAssistAmount;

    const updatedTotals = {
        total,
        subtotal,
        giftAssistAmount,
    };
    return { ...updatedTotals };
};

export const getFlexibleCreateYourOwnUpdatedTotals = (
    updatedDonationDetails,
) => {
    const { activeAmounts } = donationSettings;
    const { feeCoverage, feeCoverageFlat, feeCoveragePercentage } =
        formSettings;
    const {
        Selected_Recurring_Id = "",
        Other_Recurring_Amount = "",
        Gift_Assist = false,
        Installment_Count,
    } = updatedDonationDetails;

    // get calculated value based on stored hasSelectedRecurringDonation
    const currentAmountId = Selected_Recurring_Id;

    const otherAmount = Other_Recurring_Amount;

    const selectedAmountData = activeAmounts.find(
        (selectedAmount) => selectedAmount.id === Number(currentAmountId),
    );

    let subtotal = 0;
    if (
        selectedAmountData &&
        selectedAmountData?.amountType === ItemType.OTHER &&
        Installment_Count > 0
    ) {
        subtotal = parseFloat(otherAmount) / Installment_Count;
    } else if (
        selectedAmountData &&
        selectedAmountData?.amountType === ItemType.CUSTOM &&
        Installment_Count > 0
    ) {
        subtotal = selectedAmountData.amount / Installment_Count;
    }
    if (subtotal > 0) {
        subtotal = roundFloatingValues(subtotal);
    }

    const giftAssistData = {
        Gift_Assist,
        subtotal,
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
    };
    const giftAssistAmount = getGiftAssistTotal(giftAssistData);

    const total = Number(subtotal) + giftAssistAmount;

    const updatedTotals = {
        total,
        subtotal,
        giftAssistAmount,
    };
    return { ...updatedTotals };
};

export const getMultiRestrictionSubTotal = (updatedDonationDetails) => {
    const { currentDisplay = DisplayOn.FRONTEND } = config;
    const { activeAmounts = [] } = donationSettings;
    const { restrictions = [] } = restrictionSettings;
    const { hasSelectedRecurringDonation = false } = updatedDonationDetails;

    // Gather the restrictions and the data that is the same for every
    // multi-restriction value that we need to sum
    const activeDisplayableRestrictions = getActiveDisplayableRestrictions(
        restrictions,
        currentDisplay,
    );
    const multiRestrictionFieldNames = getMultiRestrictionFieldNames(
        activeDisplayableRestrictions,
    );
    const nameOfAmountIdField = getNameOfAmountIdField(
        hasSelectedRecurringDonation,
    );
    const otherAmountFieldName = getNameOfOtherAmountField(
        hasSelectedRecurringDonation,
    );

    const getMultiRestrictionSubtotal = (
        multiRestrictionSubtotal,
        multiRestrictionFieldName,
    ) => {
        const multiRestrictionFieldValue =
            updatedDonationDetails[multiRestrictionFieldName];
        let restrictionSubtotal = 0;

        if (multiRestrictionFieldValue.Checked) {
            const idOfSelectedAmount =
                multiRestrictionFieldValue[nameOfAmountIdField];
            const selectedAmountData = getSelectedAmount(
                activeAmounts,
                idOfSelectedAmount,
            );
            const hasSelectedAmount =
                Object.keys(selectedAmountData).length > 0;

            if (
                hasSelectedAmount &&
                selectedAmountData.amountType === ItemType.OTHER
            ) {
                restrictionSubtotal = Number(
                    multiRestrictionFieldValue[otherAmountFieldName],
                );
            } else if (hasSelectedAmount) {
                restrictionSubtotal = selectedAmountData.amount;
            }
        }

        return multiRestrictionSubtotal + restrictionSubtotal;
    };

    const subtotal = multiRestrictionFieldNames.reduce(
        getMultiRestrictionSubtotal,
        0,
    );

    return subtotal;
};

export const getMultiRestrictionUpdatedTotals = (updatedDonationDetails) => {
    const {
        feeCoverage = 0,
        feeCoverageFlat = 0,
        feeCoveragePercentage = 0,
    } = formSettings;
    const { Gift_Assist = false } = updatedDonationDetails;

    const subtotal = getMultiRestrictionSubTotal(updatedDonationDetails);
    const giftAssistAmount = getGiftAssistTotal({
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
        Gift_Assist,
        subtotal,
    });
    const total = subtotal + giftAssistAmount;

    const updatedTotals = {
        giftAssistAmount,
        subtotal,
        total,
    };

    return updatedTotals;
};

export const getStandardAmountUpdatedTotals = (updatedDonationDetails) => {
    const { activeAmounts } = donationSettings;
    const { feeCoverage, feeCoverageFlat, feeCoveragePercentage } =
        formSettings;
    const {
        hasSelectedRecurringDonation,
        Selected_One_Time_Id = "",
        Selected_Recurring_Id = "",
        Other_One_Time_Amount = "",
        Other_Recurring_Amount = "",
        Gift_Assist = false,
    } = updatedDonationDetails;

    // get calculated value based on stored hasSelectedRecurringDonation
    const currentAmountId = hasSelectedRecurringDonation
        ? Selected_Recurring_Id
        : Selected_One_Time_Id;
    const otherAmount = hasSelectedRecurringDonation
        ? Other_Recurring_Amount
        : Other_One_Time_Amount;
    const selectedAmountData = activeAmounts.find(
        (selectedAmount) => selectedAmount.id === Number(currentAmountId),
    );

    let subtotal = 0;
    if (
        selectedAmountData &&
        selectedAmountData?.amountType === ItemType.OTHER
    ) {
        subtotal = parseFloat(otherAmount) || 0;
    } else if (
        selectedAmountData &&
        selectedAmountData?.amountType === ItemType.CUSTOM
    ) {
        subtotal = selectedAmountData.amount;
    }

    const giftAssistData = {
        Gift_Assist,
        subtotal,
        feeCoverage,
        feeCoverageFlat,
        feeCoveragePercentage,
    };
    const giftAssistAmount = getGiftAssistTotal(giftAssistData);

    const total = Number(subtotal) + giftAssistAmount;

    const updatedTotals = {
        total,
        subtotal,
        giftAssistAmount,
    };
    return { ...updatedTotals };
};

const getInvoiceFulfillmentUpdatedTotals = (updatedDonationDetails) => {
    const { Invoice_Amount_Paid = 0 } = updatedDonationDetails;

    const subtotal = Number(Invoice_Amount_Paid);
    const total = subtotal;

    const updatedTotals = { total, subtotal };

    return { ...updatedTotals };
};

export const getUpdatedTotals = (updatedDonationDetails) => {
    const {
        isMultiRestriction,
        isStandardOneTimeOrRecurring,
        isPredefinedCreateYourOwnPlan,
        isPredefinedGivingPlan,
        isFlexibleGivingPlan,
        isFlexibleCreateYourOwnPlan,
        isInvoiceFulfillment,
    } = getFlagsForDonationType(updatedDonationDetails);

    if (isInvoiceFulfillment) {
        return getInvoiceFulfillmentUpdatedTotals(updatedDonationDetails);
    }

    // Giving Plans Create Your Own Plan recurring amounts treated as regular recurring donations
    if (isStandardOneTimeOrRecurring || isPredefinedCreateYourOwnPlan) {
        return getStandardAmountUpdatedTotals(updatedDonationDetails);
    }

    if (isPredefinedGivingPlan) {
        return getPredefinedGivingPlanUpdatedTotals(updatedDonationDetails);
    }

    if (isFlexibleGivingPlan) {
        return getFlexibleGivingPlanUpdatedTotals(updatedDonationDetails);
    }

    if (isFlexibleCreateYourOwnPlan) {
        return getFlexibleCreateYourOwnUpdatedTotals(updatedDonationDetails);
    }

    if (isMultiRestriction) {
        return getMultiRestrictionUpdatedTotals(updatedDonationDetails);
    }

    return { subtotal: 0, giftAssistAmount: 0, total: 0 };
};
