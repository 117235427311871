import React from "react";
import { Icon } from "@qgiv/core-react";

/**
 * @param {object} props
 * @param {string} props.formattedStartDate - The formatted start date (MM/DD/YYYY).
 * @returns {React.ReactElement} - The rendered GivingPlanCardStartDate component.
 */
const GivingPlanCardStartDate = ({ formattedStartDate }) => (
    <div>
        <span className="-type--small">
            <Icon
                classNames={["-margin-right--10", "-type--regular"]}
                glyph="hourglass-start"
                type="FontAwesome"
            />
            {`Starting ${formattedStartDate}`}
        </span>
    </div>
);

export default GivingPlanCardStartDate;
