/**
 * @function IS_DEV_HOST
 * @description Returns flag letting us know if the host is a dev host (e.g,
 *      returns `true` for https://docker.dev.qgiv.com)
 * @returns {boolean} Is dev host flag
 */
const IS_DEV_HOST = () =>
    window.location.hostname.search(
        /localhost|([^staging|^main]\.dev\.qgiv\.com)/gi,
    ) > -1;

/**
 * @function IS_STAGING_HOST
 * @description Returns flag letting us know if the host is staging (e.g,
 *      returns `true` for https://staging.dev.qgiv.com)
 * @returns {boolean} Is staging host flag
 */
const IS_STAGING_HOST = () =>
    window.location.hostname.search(/staging.dev\.qgiv\.com/gi) > -1;

/**
 * @function IS_PROD_HOST
 * @description Returns flag letting us know if the host is for production (e.g,
 *      returns `true` for https://main.dev.qgiv.com and
 *      https://secure.qgiv.com)
 * @returns {boolean} Is production host flag
 */
const IS_PROD_HOST = () =>
    window.location.hostname.search(
        /(secure\.qgiv\.com)|(main.dev\.qgiv\.com)/gi,
    ) > -1;

/**
 * @function IS_DEV
 * @description Returns the dev mode status.
 * @returns {boolean} Is dev environment flag
 */
const IS_DEV = () => PRODUCTION === false;

/**
 * @function IS_PROD
 * @description Returns the production mode status.
 * @returns {boolean} Is production environment flag
 */
const IS_PROD = () => typeof PRODUCTION !== "undefined" && PRODUCTION === true;

/**
 * @function IS_TEST
 * @description Returns the production mode status.
 * @returns {boolean} Is production environment flag
 */
const IS_TEST = () => process.env.NODE_ENV === "test";

/**
 * @member ENV
 * @description The applied node environment ref.
 */
const BUILD_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : "production";

export default {
    IS_DEV_HOST,
    IS_STAGING_HOST,
    IS_PROD_HOST,
    IS_DEV,
    IS_PROD,
    IS_TEST,
    BUILD_ENV,
};
