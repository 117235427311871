import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import Button from "../../Button";
import WidgetSocialSharingEmailModal from "./WidgetSocialSharingEmailModal";
import "./WidgetSocialSharing.scss";

const WidgetSocialSharing = ({
    additionalText = "",
    buttonClass = "",
    displayEmailButton = false,
    displaySocialSharingEmailModal = false,
    enableEmail = false,
    enableFacebook = false,
    enableTwitter = false,
    handleDisplaySocialSharingEmailModal = () => {},
    handleFacebookButtonClick = () => {},
    handleRemoveSocialSharingEmailModal = () => {},
    handleTwitterButtonClick = () => {},
    handleSubmitSharingEmailPath = () => {},
    heading = "",
    isCms = false,
    widgetSocialSharingEmailModalProps = {},
}) => {
    // Set up event handlers that can be used to open and close the email modal
    const onEmailButtonClick = () => {
        if (!isCms) {
            handleDisplaySocialSharingEmailModal();
        }
    };

    return (
        <div className="qg-vendor-widget-social widget-sharing grid">
            <div className="col col--12">
                <div className="qg-vendor-page__callout -callout -padding--30">
                    {/* eslint-disable-next-line tailwindcss/classnames-order */}
                    <div className="grid grid--justify-content-center">
                        {heading && (
                            <div className="col col--12 -padding-top--0 -text--center">
                                <h2>{heading}</h2>
                            </div>
                        )}

                        {additionalText && (
                            <div className="col col--12 -padding-top--5 -margin-bottom--15 -text--center">
                                <p>{additionalText}</p>
                            </div>
                        )}
                        <div className="col col--10 col--no-top-gutter -text--center">
                            {/* eslint-disable-next-line tailwindcss/classnames-order */}
                            <div className="grid grid--tight-gutters grid--justify-content-center">
                                {enableFacebook && (
                                    <div
                                        className={`col ${buttonClass} col--no-top-gutter`}
                                    >
                                        <Button
                                            type="button"
                                            classes="-background-color--facebook"
                                            onClick={handleFacebookButtonClick}
                                            aria-label="Share on Facebook"
                                            role="link"
                                        >
                                            <Icon
                                                glyph="facebook-brands"
                                                type="FontAwesome"
                                            />
                                        </Button>
                                    </div>
                                )}
                                {enableTwitter && (
                                    <div
                                        className={`col ${buttonClass} col--no-top-gutter`}
                                    >
                                        <Button
                                            type="button"
                                            classes="-background-color--twitter"
                                            onClick={handleTwitterButtonClick}
                                            aria-label="Share on X"
                                            role="link"
                                        >
                                            <Icon
                                                glyph="x-twitter-brands"
                                                type="FontAwesome"
                                            />
                                        </Button>
                                    </div>
                                )}
                                {enableEmail && displayEmailButton && (
                                    <div
                                        className={`col ${buttonClass} col--no-top-gutter`}
                                    >
                                        <Button
                                            type="button"
                                            onClick={onEmailButtonClick}
                                            aria-label="Email a friend"
                                        >
                                            <Icon
                                                glyph="envelope-solid"
                                                type="FontAwesome"
                                            />
                                        </Button>
                                    </div>
                                )}
                                {displaySocialSharingEmailModal &&
                                    enableEmail &&
                                    displayEmailButton && (
                                        <WidgetSocialSharingEmailModal
                                            {...widgetSocialSharingEmailModalProps}
                                            handleSubmitSharingEmailPath={
                                                handleSubmitSharingEmailPath
                                            }
                                            handleRemoveSocialSharingEmailModal={
                                                handleRemoveSocialSharingEmailModal
                                            }
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

WidgetSocialSharing.propTypes = {
    additionalText: PropTypes.string.isRequired,
    buttonClass: PropTypes.string.isRequired,
    displaySocialSharingEmailModal: PropTypes.bool.isRequired,
    displayEmailButton: PropTypes.bool.isRequired,
    enableEmail: PropTypes.bool.isRequired,
    enableFacebook: PropTypes.bool.isRequired,
    enableTwitter: PropTypes.bool.isRequired,
    handleDisplaySocialSharingEmailModal: PropTypes.func.isRequired,
    handleFacebookButtonClick: PropTypes.func.isRequired,
    handleRemoveSocialSharingEmailModal: PropTypes.func.isRequired,
    handleTwitterButtonClick: PropTypes.func.isRequired,
    handleSubmitSharingEmailPath: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired,
    isCms: PropTypes.bool.isRequired,
    widgetSocialSharingEmailModalProps: PropTypes.shape({}).isRequired,
};

export default WidgetSocialSharing;
