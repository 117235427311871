import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { currencyString } from "@qgiv/core-js";
import { translateTransaction } from "@qgiv/core-donor";
import { ReceiptDetailsGifts } from "@qgiv/form";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectReceipt } from "../../../../redux/slices/receiptsSlice";
import { selectSmsData } from "../../../../redux/slices/smsDataSlice";

// -------------------------------------------------------------------------
// TODO: Move the ReceiptDetailsGifts component that is being used by this
// file out of the /form package as that is where it is currently located.
// -------------------------------------------------------------------------
const ConnectedReceiptDetailsGifts = ({ control }) => {
    const { feeCoverage, transaction } = useSelector(selectReceipt);
    const { currency = {}, restrictionGivingType = "amounts" } = useSelector(
        selectAllFormSettings,
    );
    const smsData = useSelector(selectSmsData);
    const { recurring = null } = transaction;
    const { options } = control;

    const {
        dedicationLabel = "Dedicated to %DEDICATIONNAME%",
        giftAssistAdditionalText = "Thanks for giving a little extra!",
        giftAssistLabel = "Processing Fee",
        giftLabel = "Gift",
    } = options;
    // Mimic the bool created in reducers/index.js since we do not have access
    // to that data here
    const enableMultiRestrictionGiving =
        !smsData && restrictionGivingType === "simple";

    // Convert any of the data that is on the transaction object that is a
    // number to a string before it is displayed on the receipt page
    const transactionWithDataAsStrings = translateTransaction(transaction);
    const { donations = [] } = transactionWithDataAsStrings;
    // Rename variable to match the name of the component and only pass down
    // the data that is needed by the component
    const gifts = donations.map((donation) => {
        const {
            amount = "",
            amountName = "",
            id = "",
            restriction = "",
            subRestriction = "",
        } = donation;
        // Before we extract the name of the giving plan we need to protect against
        // the case where the donor submits a one time donation as recurring is
        // passed to the FE as null instead of an empty object in this use case. We
        // can then use this data inside of the call to .map() as this data is only
        // relevant to giving plans and giving plans are not available as part of
        // multi-restriction amounts
        const pledgeName =
            recurring && recurring.pledgeName ? recurring.pledgeName : "";
        let giftDetailLabel;

        // Change the gift detail label based on whether or not the donor
        // selected a giving plan or an amount that has a name. Amount names
        // should not be used if multi-restriction has been enabled as amount
        // names are not displayed if multi-restriction is enabled.
        if (pledgeName && !enableMultiRestrictionGiving) {
            giftDetailLabel = pledgeName;
        } else if (amountName && !enableMultiRestrictionGiving) {
            giftDetailLabel = amountName;
        } else {
            giftDetailLabel = giftLabel;
        }

        // Generate line item specific flags and formatted text
        const displayRestriction = restriction.length > 0;
        const displaySubRestriction = subRestriction.length > 0;
        const formattedAmount = currencyString(amount, currency);

        return {
            displayRestriction,
            displaySubRestriction,
            formattedAmount,
            giftDetailLabel,
            id,
            restriction,
            subRestriction,
        };
    });

    // Gift Assist flags and formatted text
    const displayGiftAssist = !!feeCoverage;
    const formattedFeeCoverage = currencyString(feeCoverage, currency);

    // Dedications
    // Grab the first instance of the dedication text that the donor entered as
    // it will be the same for all dedications.
    const dedication = donations.length > 0 ? donations[0].dedText : "";
    const displayDedication = !!dedication;

    const formattedDedicationLabel = dedicationLabel.replace(
        "%DEDICATIONNAME%",
        dedication,
    );

    // Consolidate props into object while passing reassigned variable to the
    // appropriate key.
    const receiptDetailsProps = {
        displayDedication,
        displayGiftAssist,
        formattedDedicationLabel,
        formattedFeeCoverage,
        giftAssistLabel,
        giftAssistAdditionalText,
        gifts,
    };
    return <ReceiptDetailsGifts {...receiptDetailsProps} />;
};

ConnectedReceiptDetailsGifts.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            dedicationLabel: PropTypes.string,
            giftAssistAdditionalText: PropTypes.string,
            giftAssistLabel: PropTypes.string,
            giftLabel: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedReceiptDetailsGifts;
