import React from "react";
import PropTypes from "prop-types";
import { Message } from "@qgiv/core-react";

const ErrorDisplay = ({ errors = [], handleClose = () => {} }) =>
    errors.length > 0 &&
    Array.isArray(errors) && (
        <div className="grid">
            <div className="col col--12">
                <Message closeButton onClose={handleClose} scrollToMessage>
                    <p>
                        {errors.map((error) => (
                            <span
                                className="-display--block"
                                key={error}
                            >{`${error} `}</span>
                        ))}
                    </p>
                </Message>
            </div>
        </div>
    );

ErrorDisplay.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    handleClose: PropTypes.func.isRequired,
};

export default ErrorDisplay;
