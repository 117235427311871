import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { selectCmsContainersByPage } from "../../../../redux/slices/cmsSettingsSlice";
import PaymentPageContent from "./PaymentPageContent";

const ConnectedPaymentPageContent = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
        },
    } = constants;

    const containers = useSelector((state) =>
        selectCmsContainersByPage(state, PAYMENT_DETAILS),
    );

    return <PaymentPageContent containers={containers} />;
};

export default ConnectedPaymentPageContent;
