import React from "react";
import { constants } from "@qgiv/core-js";
import { useSelector } from "react-redux";
import {
    getAmountsToDisplay,
    getHasSelectedOtherAmount,
    getShouldDisplayAmountsAsCards,
} from "@qgiv/donation-form";
import { useFormikContext } from "formik";
import { selectAllDonationSettings } from "../../../../../redux/slices/donationSettingsSlice";
import { selectAllFormSettings } from "../../../../../redux/slices/formSettingsSlice";
import { selectGiftDetails } from "../../../../../redux/slices/donationDetailsSlice";
import { selectCurrentPage } from "../../../../../redux/slices/appSettingsSlice";
import { selectCmsControlByIdByPage } from "../../../../../redux/slices/cmsSettingsSlice";
import Amounts from "./Amounts";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @param {boolean} props.isRecurringDisplay
 * @returns {React.ReactElement} The Amounts component.
 */
const ConnectedAmounts = ({ controlId, isRecurringDisplay = false }) => {
    const {
        ENUMS: { ItemType, StandardFormAmountsButtonStyle },
    } = constants;

    const { values } = useFormikContext();

    const { donationActive = false, enableRecur = false } = useSelector(
        selectAllFormSettings,
    );

    const {
        activeAmounts,
        maxAmt = 0,
        minAmt = 0,
        activePlans,
        pledgeActive,
    } = useSelector(selectAllDonationSettings);

    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const { options } = control;

    const {
        donationAmountButtonStyle = `${StandardFormAmountsButtonStyle.STANDARD}`,
        donationAmountAlignment = "right",
    } = options;

    const shouldDisplayAmountsAsCards = getShouldDisplayAmountsAsCards(
        activeAmounts,
        isRecurringDisplay,
    );

    // buttonStyle variable that is used by standard and other amounts
    const isButtonStyleStandard =
        Number(donationAmountButtonStyle) ===
        StandardFormAmountsButtonStyle.STANDARD;
    const buttonStyle =
        shouldDisplayAmountsAsCards || isButtonStyleStandard
            ? "standard"
            : "alternate";

    const { hasSelectedRecurringDonation } = useSelector(selectGiftDetails);

    const isDisplayedAsPartOfCYOP =
        activePlans?.length > 0 && pledgeActive && hasSelectedRecurringDonation;

    // A list of the amounts that should be displayed on the current donation
    // tab based off of the min/max (coming soon) donation settings, which tab is active and
    // the one-time/ongoing amount settings to generate a list of amounts to
    // display
    const amountsToDisplay = getAmountsToDisplay({
        activeAmounts,
        donationActive,
        enableRecur,
        hasSelectedRecurringDonation,
        maxAmt,
        minAmt,
    });

    // Refs that are used by handleTextInputButtonKeydown() to move focus from the
    // Other amount to the previous amount or the amount at the start of the
    // list of amounts when a user attempts to navigate through the list of
    // amounts using the arrow keys. We generate this list of refs here
    // because the keydown logic in ConnectedOtherAmountsRadioOptions needs
    // this data needs to be passed to both the ConnectedOtherAmountsRadioOptions
    // and ConnectedStandardAmountsRadioOptions component in order for a user to
    // navigate between the ConnectedStandardAmountsRadioOptions and
    // ConnectedStandardAmountsRadioOptions components using the keyboard.
    const standardAndOtherAmountRefs = amountsToDisplay.map(() =>
        React.createRef(),
    );

    const fieldName = hasSelectedRecurringDonation
        ? "Selected_Recurring_Id"
        : "Selected_One_Time_Id";

    const hasStandardAmountsToDisplay = amountsToDisplay.some(
        (amount) => Number(amount.amountType) === ItemType.CUSTOM,
    );
    const hasOtherAmountToDisplay = amountsToDisplay.some(
        (amount) => Number(amount.amountType) === ItemType.OTHER,
    );

    const shouldConsiderDisplayOfMinMaxMessage = getHasSelectedOtherAmount({
        activeAmounts,
        values,
        fieldName,
    });

    // Both Standard and Other Amounts need these props
    const sharedAmountsProps = {
        amountsToDisplay,
        buttonStyle,
        controlId,
        donationAmountAlignment,
        fieldName,
        standardAndOtherAmountRefs,
        hasStandardAmountsToDisplay,
        hasOtherAmountToDisplay,
        shouldConsiderDisplayOfMinMaxMessage,
        isDisplayedAsPartOfCYOP,
        isRecurringDisplay,
    };

    return <Amounts {...sharedAmountsProps} />;
};

export default ConnectedAmounts;
