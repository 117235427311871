import React from "react";
import PropTypes from "prop-types";
import "./AttendeeDetails.scss";
import { SystemFieldGroupAttendeeDetails } from "../QField/System";
import Divider from "../Divider";

const AttendeeDetails = ({
    showAttendeeDetails,
    hasCustomFieldsToShow,
    showCopyDetailsSelect,
    showRequiredCustomFieldText,
    renderCopyDetailsSelect = () => {},
    renderIncludeDetailsCheckbox = () => {},
    requiredCustomFieldsDisclaimer,
    attendeeDetailsFieldProps,
    showNotificationPrefences = false,
    renderNotificationPreferences = () => {},
}) => (
    <>
        <div className="qg-vendor-attendee-details attendee-details -callout -padding-bottom--0 -padding-top--0">
            {renderIncludeDetailsCheckbox()}
            {showCopyDetailsSelect && renderCopyDetailsSelect()}
            {showAttendeeDetails && (
                <>
                    <SystemFieldGroupAttendeeDetails
                        {...attendeeDetailsFieldProps}
                    />
                    {hasCustomFieldsToShow && (
                        <div className="-margin-top--15">
                            <Divider dashed />
                        </div>
                    )}
                </>
            )}
            {showRequiredCustomFieldText && (
                <p className="-type--regular -padding-top--15">
                    {requiredCustomFieldsDisclaimer}
                </p>
            )}
        </div>
        {showNotificationPrefences && renderNotificationPreferences()}
    </>
);

AttendeeDetails.propTypes = {
    showAttendeeDetails: PropTypes.bool.isRequired,
    hasCustomFieldsToShow: PropTypes.bool.isRequired,
    showCopyDetailsSelect: PropTypes.bool.isRequired,
    showRequiredCustomFieldText: PropTypes.bool.isRequired,
    renderCopyDetailsSelect: PropTypes.func.isRequired,
    attendeeDetailsFieldProps: PropTypes.shape({}).isRequired,
    renderIncludeDetailsCheckbox: PropTypes.func.isRequired,
    requiredCustomFieldsDisclaimer: PropTypes.string.isRequired,
    showNotificationPrefences: PropTypes.bool,
    renderNotificationPreferences: PropTypes.func,
};

AttendeeDetails.defaultProps = {
    showNotificationPrefences: false,
    renderNotificationPreferences: () => {},
};

export default AttendeeDetails;
