import React, { useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getExpressCheckoutDisplayFlags } from "@qgiv/donation-form";
import {
    selectShouldDisplayApplePay,
    setExpressCheckoutDisplayFlags,
} from "../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectHasLegalField } from "../../../redux/slices/fieldsSlice";
import DonationAmountsPageFooterControl from "./DonationAmountsPageFooterControl";

const ConnectedDonationAmountsPageFooterControl = ({ controlId }) => {
    const dispatch = useDispatch();
    const formSettings = useSelector(selectAllFormSettings);
    const shouldDisplayApplePay = useSelector(selectShouldDisplayApplePay);
    const legalFieldData = useSelector(selectHasLegalField);
    const hasLegalField = legalFieldData.exists;
    // -------------------------------------------------------------------------
    // TODO: Clean this up by moving all of this work to the BE, so that
    // we know on page load if we can display Apple Pay, PayPal, and Express Donate.
    // -------------------------------------------------------------------------
    const settings = useMemo(
        () => ({
            formSettings,
            hasLegalField,
        }),
        [formSettings, hasLegalField],
    );

    const expressCheckoutDisplayFlags = useMemo(
        () => getExpressCheckoutDisplayFlags(settings, shouldDisplayApplePay),
        [settings, shouldDisplayApplePay],
    );

    // Save Express Checkout display flags in Redux, so we can use them later.
    const updateExpressCheckoutDisplayFlags = useCallback(
        (expressCheckoutDisplayFlagsLocal) => {
            dispatch(
                setExpressCheckoutDisplayFlags(
                    expressCheckoutDisplayFlagsLocal,
                ),
            );
        },
        [dispatch],
    );

    useEffect(() => {
        updateExpressCheckoutDisplayFlags(expressCheckoutDisplayFlags);
    }, [expressCheckoutDisplayFlags, updateExpressCheckoutDisplayFlags]);

    const { displayExpressCheckout } = expressCheckoutDisplayFlags;

    const donationAmountsPageFooterControlProps = {
        controlId,
        displayExpressCheckout,
    };

    return (
        <DonationAmountsPageFooterControl
            {...donationAmountsPageFooterControlProps}
        />
    );
};

ConnectedDonationAmountsPageFooterControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonationAmountsPageFooterControl;
