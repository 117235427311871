import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";

const ReceiptTotal = ({
    displayHourGlassIcon = false,
    displayInvoiceIcon = false,
    displayInvoiceStatus = false,
    displayOriginalPrice = false,
    displayPendingStatus = false,
    displayPromoCodes = false,
    displayRecurringText = false,
    formattedOriginalPrice,
    hasPromo,
    invoicedStatus = "",
    pendingStatus = "",
    promoCodeAppliedLabel,
    promoCodes,
    recurringTotalText = "",
    total,
    totalLabel = "Total",
}) => {
    return (
        <>
            <div className="grid">
                <div className="col col-6 ">
                    <h3>{totalLabel}</h3>
                </div>
                <div className="col col-6 -text--right">
                    <p>
                        {/* Invoice icon or pending icon which is an hourglass */}
                        {displayInvoiceIcon && (
                            <>
                                <Icon
                                    classNames={[""]}
                                    glyph="receipt-regular"
                                    type="FontAwesome"
                                />{" "}
                            </>
                        )}
                        {/* Hourglass icon for recurring transactions */}
                        {/* Only displayed if the transaction was not an invoice and it starts after today */}
                        {displayHourGlassIcon && (
                            <>
                                <Icon
                                    glyph="hourglass-start"
                                    type="FontAwesome"
                                />{" "}
                            </>
                        )}
                        {/* Original price */}
                        {displayOriginalPrice && (
                            <span className="-text-decoration--line-through -type--large -color--system-dark">
                                {formattedOriginalPrice}
                            </span>
                        )}
                        {/* Total price formatted as dollar amount */}
                        <strong
                            className={cx(
                                hasPromo &&
                                    "-color--system-error -padding-left--5",
                                !hasPromo &&
                                    "-color--system-dark -padding-left--5",
                                "-type--large",
                            )}
                        >
                            {total}
                            {/* Recurring Text */}
                            {displayRecurringText && (
                                <>
                                    <br />
                                    <span className="-type--small">
                                        {recurringTotalText}
                                    </span>
                                </>
                            )}
                        </strong>
                        {/* Invoice status */}
                        {displayInvoiceStatus && (
                            <>
                                <br />
                                <small>{invoicedStatus}</small>
                            </>
                        )}
                        {/* Pending status for recurring transactions */}
                        {/* Only displayed if the transaction was not an invoice and it starts after today */}
                        {displayPendingStatus && (
                            <>
                                <br />
                                <small>{pendingStatus}</small>
                            </>
                        )}
                    </p>
                </div>
            </div>
            {displayPromoCodes && (
                <div className="grid">
                    {promoCodes.map((promo, i) => {
                        return (
                            <div
                                className="col col--12 -type--small -text--right"
                                key={i}
                            >
                                <span className="registration-summary__callout -margin-top--5">
                                    <Icon
                                        glyph="check-solid"
                                        type="FontAwesome"
                                        classNames={[
                                            "-type--regular -font-weight--bold -margin-right--10",
                                        ]}
                                    />
                                    <strong>{promo}</strong>{" "}
                                    {promoCodeAppliedLabel}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

ReceiptTotal.propTypes = {
    displayHourGlassIcon: PropTypes.bool,
    displayInvoiceIcon: PropTypes.bool,
    displayInvoiceStatus: PropTypes.bool,
    displayOriginalPrice: PropTypes.bool,
    displayPendingStatus: PropTypes.bool,
    displayPromoCodes: PropTypes.bool,
    displayRecurringText: PropTypes.bool,
    formattedOriginalPrice: PropTypes.string,
    hasPromo: PropTypes.bool,
    invoicedStatus: PropTypes.string,
    pendingStatus: PropTypes.string,
    promoCodeAppliedLabel: PropTypes.string,
    promoCodes: PropTypes.array,
    recurringTotalText: PropTypes.string,
    total: PropTypes.string.isRequired,
    totalLabel: PropTypes.string,
};

export default ReceiptTotal;
