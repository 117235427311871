import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { constants, currencyString } from "@qgiv/core-js";
import { ReceiptHeader } from "@qgiv/donation-form";
import { selectCmsControlByIdByPage } from "../../../redux/slices/cmsSettingsSlice";
import {
    selectReceiptTransaction,
    selectReceiptTotal,
    selectReceiptDonationRecipientName,
} from "../../../redux/slices/receiptsSlice";
import { selectSpecificFormSetting } from "../../../redux/slices/formSettingsSlice";
import ReceiptSubHeading from "./ReceiptSubHeading";

const ConnectedReceiptHeader = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { CONFIRMATION },
        },
    } = constants;
    const currency = useSelector((state) =>
        selectSpecificFormSetting(state, "currency"),
    );
    const total = useSelector(selectReceiptTotal);
    const transaction = useSelector(selectReceiptTransaction);
    const {
        demo = "n",
        fname = "",
        hasCompletedPaymentForInvoice = false,
        recurring = {},
        status = "A",
    } = transaction;

    const entityName = useSelector(selectReceiptDonationRecipientName);

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, CONFIRMATION),
    );

    const { options = {} } = control;

    const {
        invoicedMessage = "You're paying later by invoice",
        receiptHeading = "Thank you, %NAME%!",
        receiptSubHeading = "Your gift of %AMOUNTWITHFREQUENCY% supports %RECIPIENTNAME% and is making a difference!",
    } = options;

    const startOfToday = moment().startOf("day");
    const startOfFirstBillingDate =
        recurring && recurring.firstBill
            ? moment(recurring.firstBill).startOf("day")
            : startOfToday;

    // Only display the start billing text if the donation is a recurring
    // donation and the recurring donation starts on a day after today
    const displayStartBillingDate =
        recurring && recurring.firstBill
            ? startOfFirstBillingDate > startOfToday
            : false;

    // Format the date text as expected so that it can be displayed in the UI
    // while leaving space between the text and the hourglass icon
    const startBillingDateText =
        recurring && recurring.firstBill
            ? `Starting ${moment(recurring.firstBill).format("MM/DD/YYYY")}`
            : "";

    // Not checking for enableInvoice in case a user requested an invoice prior
    // to the org toggling off the setting
    const displayInvoicedMessage = status === "PR";

    // replace %NAME% with fname from transaction data
    const receiptHeadingText = receiptHeading.replace("%NAME%", fname);

    const displayDemoMessage = demo === "y";

    const invoicedMessageText = invoicedMessage;

    const amountText = currencyString(total, currency);
    const frequencyText =
        recurring &&
        recurring.frequency_description &&
        !hasCompletedPaymentForInvoice
            ? `/${recurring.frequency_description}`
            : ``;

    const formattedAmountWithFrequency = amountText + frequencyText;
    const glyph = "qgiv-confirmation";

    const receiptHeaderProps = {
        displayDemoMessage,
        displayInvoicedMessage,
        displayStartBillingDate,
        glyph,
        invoicedMessageText,
        receiptHeadingText,
        renderSubHeading: (
            <ReceiptSubHeading
                receiptSubHeading={receiptSubHeading}
                replaceTags={["%AMOUNTWITHFREQUENCY%", "%RECIPIENTNAME%"]}
                formattedAmountWithFrequency={formattedAmountWithFrequency}
                entityName={entityName}
            />
        ),
        startBillingDateText,
    };

    return <ReceiptHeader {...receiptHeaderProps} />;
};

ConnectedReceiptHeader.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedReceiptHeader;
