import { constants, sortArrayAlphabeticallyByKey } from "@qgiv/core-js";

const {
    ENUMS: { EntityType },
} = constants;

const iconMap = {
    participant: "user-duotone",
    team: "users-duotone",
    classification: "sitemap-duotone",
};

export const getRecipientIconGlyph = (type) => {
    const recipientIconGlyph = iconMap[type];

    return recipientIconGlyph;
};

export const reduceCaptainNamesToString = (captainsArray) => {
    const captainNamesString = captainsArray.reduce(
        (existingString, currentCaptain, index) => {
            const { firstName, lastName } = currentCaptain;
            const captainName = `${firstName} ${lastName}`;
            if (index === 0) {
                return captainName;
            }
            const newString = `${existingString}, ${captainName}`;
            return newString;
        },
        "",
    );
    return captainNamesString;
};

const getMappedParticipant = (participant) => ({
    ...participant,
    // need to ensure title is a string because we are going to be running
    // string methods on it.
    title: `${participant.firstName} ${participant.lastName}`,
    key: `participant-${participant.id}`,
    type: "participant",
    // value/type might change for this eval
    hasTeam: participant.teamId !== 0,
    iconGlyph: getRecipientIconGlyph("participant"),
});

const getMappedTeam = (team) => ({
    ...team,
    // need to ensure title is a string because we are going to be running
    // string methods on it.
    title: `${team.teamName}`,
    key: `team-${team.id}`,
    type: "team",
    iconGlyph: getRecipientIconGlyph("team"),
    hasCaptain: team?.captains?.length > 0,
    ...(team?.captains?.length > 0 && {
        // there can be more than one!
        captainName: reduceCaptainNamesToString(team.captains),
    }),
});

const getMappedClassification = (classification) => ({
    ...classification,
    // need to ensure title is a string because we are going to be running
    // string methods on it.
    title: `${classification.classificationName}`,
    key: `classification-${classification.id}`,
    type: "classification",
    iconGlyph: getRecipientIconGlyph("classification"),
    hasCaptain: classification?.captains?.length > 0,
    ...(classification?.captains?.length > 0 && {
        // there can be more than one!
        captainName: reduceCaptainNamesToString(classification.captains),
    }),
});

export const getMappedRecipient = (recipient) => {
    const { preselectedRecipientType } = recipient;
    switch (preselectedRecipientType) {
        case EntityType.REGISTRATION:
            return getMappedParticipant(recipient);
        case EntityType.TEAM:
            return getMappedTeam(recipient);
        case EntityType.CLASSIFICATION:
            return getMappedClassification(recipient);
        default:
            return getMappedParticipant(recipient);
    }
};

export const mapParticipantsForList = (participants) =>
    participants.map(getMappedParticipant);

export const mapTeamsForList = (teams) => teams.map(getMappedTeam);

// I am assuming captain data will come in the same shape as it does for teams
export const mapClassificationsForList = (classifications) =>
    classifications.map(getMappedClassification);

export const handleSuccessfulSearchCall = ({
    data,
    donationRecipientSettings,
    setSearchList,
    setListLoading,
}) => {
    const {
        results: { classifications, participants, teams },
    } = data;

    const {
        enableClassificationForDonation,
        enableDonationToParticipant,
        enableDonationToTeam,
    } = donationRecipientSettings;

    // map through entities to make sure they have the correct keys
    const mappedParticipants = enableDonationToParticipant
        ? mapParticipantsForList(participants)
        : [];

    const mappedTeams = enableDonationToTeam ? mapTeamsForList(teams) : [];

    const mappedClassifications = enableClassificationForDonation
        ? mapClassificationsForList(classifications)
        : [];

    // combine output to one array
    const fullList = [
        ...mappedParticipants,
        ...mappedTeams,
        ...mappedClassifications,
    ];

    // sort by title key alphabetically
    const sortedFullList = sortArrayAlphabeticallyByKey(fullList, "title");

    setSearchList(sortedFullList);
    setListLoading(false);
};

// -------------------------------------------------------------------------
// MARK: Selected Recipient Helpers
// -------------------------------------------------------------------------

export const getPercentOfGoalWithoutDonation = ({
    totalRaised,
    fundraisingGoal,
}) => Math.round((totalRaised / fundraisingGoal) * 100);

export const getPercentOfDonationTowardsGoal = ({ total, fundraisingGoal }) =>
    Math.floor((total / fundraisingGoal) * 100);

export const getPercentOfGoalWithDonation = ({
    percentOfGoalWithoutDonation,
    percentOfDonationTowardsGoal,
}) => percentOfGoalWithoutDonation + percentOfDonationTowardsGoal;

export const getHasReachedGoal = ({ total, totalRaised, fundraisingGoal }) =>
    total + totalRaised >= fundraisingGoal;

export const getPercentOfGoalToDisplayOnThermometer = ({
    percentOfGoalWithDonation,
    isSmallScreen,
}) => {
    if (percentOfGoalWithDonation >= 100) {
        return 100;
    }
    // We want to have a starting thermometer width even if the totalRaised is 0
    // this means we have to use a ratio of what the donation amount will be to
    // update the width of thermometer. Mobile is going to use a 94 / 100 ratio.
    // Desktop is going to use a 96 / 100 ratio.
    const ratioBasis = isSmallScreen ? 94 : 96;
    const remainder = 100 - ratioBasis;
    const magicRatio = ratioBasis / 100;
    const percentageToFill = percentOfGoalWithDonation * magicRatio;
    return percentageToFill + remainder;
};

export const getGoalAndDonationDataForSelectedRecipient = ({
    isSmallScreen,
    recipient,
    total,
}) => {
    const { fundraisingGoal, totalRaised } = recipient;
    const hasValidFundraisingGoal = fundraisingGoal > 0;

    const percentOfGoalWithoutDonation = hasValidFundraisingGoal
        ? getPercentOfGoalWithoutDonation({ totalRaised, fundraisingGoal })
        : 0;

    const percentOfDonationTowardsGoal = hasValidFundraisingGoal
        ? getPercentOfDonationTowardsGoal({ total, fundraisingGoal })
        : 0;

    const percentOfGoalWithDonation = getPercentOfGoalWithDonation({
        percentOfGoalWithoutDonation,
        percentOfDonationTowardsGoal,
    });

    const hasReachedGoal = hasValidFundraisingGoal
        ? getHasReachedGoal({
              total,
              totalRaised,
              fundraisingGoal,
          })
        : false;

    const percentOfGoalToDisplayOnThermometer =
        getPercentOfGoalToDisplayOnThermometer({
            isSmallScreen,
            percentOfGoalWithDonation,
        });

    return {
        hasValidFundraisingGoal,
        percentOfGoalWithoutDonation,
        percentOfDonationTowardsGoal,
        percentOfGoalWithDonation,
        hasReachedGoal,
        percentOfGoalToDisplayOnThermometer,
    };
};

export const getDonationMessageWithSelectedRecipient = ({
    helpText = "",
    selectedRecipient = {},
    eventTitle,
    replaceTag,
}) => {
    if (
        Object.keys(selectedRecipient).length === 0 ||
        !helpText ||
        helpText === ""
    ) {
        return "";
    }
    if (!helpText.includes(replaceTag)) {
        return helpText;
    }
    // classifications, teams, and participants should all have title key as selected recipient
    // default text has the possessive 's after the replace tag, so we shouldn't have to worry about that here.
    if (selectedRecipient?.title) {
        return helpText.replace(replaceTag, `${selectedRecipient.title}`);
    }
    return helpText.replace(replaceTag, `${eventTitle}`);
};

// Below are some functions which we can get rid of if the sorting
// remains at the current end of list generation level.

// export const mapWithSortParticipantsForList = (
//     participants,
//     sortEntityArray,
// ) => {
//     const mappedParticipants = mapParticipantsForList(participants);
//     if (sortEntityArray) {
//         return sortArrayAlphabeticallyByKey("title", mappedParticipants);
//     }
//     return mappedParticipants;
// };

// export const mapWithSortTeamsForList = (teams, sortEntityArray) => {
//     const mappedTeams = mapTeamsForList(teams);
//     if (sortEntityArray) {
//         return sortArrayAlphabeticallyByKey("title", mappedTeams);
//     }
//     return mappedTeams;
// };

// export const mapWithSortClassificationsForList = (
//     classifications,
//     sortEntityArray,
// ) => {
//     const mappedClassifications = mapClassificationsForList(classifications);
//     if (sortEntityArray) {
//         return sortArrayAlphabeticallyByKey("title", mappedClassifications);
//     }
//     return mappedClassifications;
// };
