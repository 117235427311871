import React from "react";
import PropTypes from "prop-types";
import { currencyString } from "@qgiv/core-js";
import { Icon } from "@qgiv/core-react";

const SubmitButtonLabelWithContent = ({
    buttonLabel = "Give %AMOUNTWITHFREQUENCY%",
    currency = {
        format: "0",
        symbol: "$",
        symbolLocation: "0",
    },
    frequencyUnitLabel = "month",
    hasSelectedRecurringDonation = false,
    total = 0,
}) => {
    const formattedTotal = currencyString(total, currency);

    const frequencyTag = "%AMOUNTWITHFREQUENCY%";
    const frequencyTagFormatted = frequencyTag.replace(
        "%AMOUNTWITHFREQUENCY%",
        `/${frequencyUnitLabel}`,
    );

    const [beforeAmountWithFreq, afterAmountWithFreq = ""] =
        buttonLabel.split(frequencyTag);

    return (
        <>
            {hasSelectedRecurringDonation && (
                <Icon
                    classNames={["-type--xlarge"]}
                    glyph="qgiv-recurring"
                    type="Custom"
                />
            )}

            <span className="-padding-left--10 -font-weight--light">
                {beforeAmountWithFreq}
            </span>
            {total > 0 && (
                <span className="-font-weight--light">
                    {" "}
                    <strong>
                        {total > 0 &&
                            buttonLabel.includes(frequencyTag) &&
                            formattedTotal}
                    </strong>
                    {hasSelectedRecurringDonation &&
                        buttonLabel.includes(frequencyTag) &&
                        frequencyTagFormatted}
                </span>
            )}
            <span className="-font-weight--light">{afterAmountWithFreq}</span>
        </>
    );
};

SubmitButtonLabelWithContent.propTypes = {
    buttonLabel: PropTypes.string,
    currency: PropTypes.shape({
        format: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        symbol: PropTypes.string,
        symbolLocation: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    }),
    frequencyUnitLabel: PropTypes.string,
    hasSelectedRecurringDonation: PropTypes.bool,
    total: PropTypes.number,
};

export default SubmitButtonLabelWithContent;
