import React from "react";
import { CardImage } from "@qgiv/core-donor";

/**
 * @typedef {import("@qgiv/core-donor").ComponentTypes.CardImageProps} CardImageProps
 */

/**
 *
 * @param {object} props
 * @param {CardImageProps} props.cardImageProps
 * @returns {React.ReactElement}
 */
const RestrictionCardImage = ({ cardImageProps }) => (
    <div className="-padding-bottom--15 -padding-left--15 -padding-right--15">
        {/* Remove the margin that is added to card_figure to make it easier to consistently space these elements */}
        <figure className="amount-card__figure -margin-bottom--0">
            <CardImage {...cardImageProps} />
        </figure>
    </div>
);

export default RestrictionCardImage;
