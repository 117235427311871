import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./SharedButtonStyles.scss";
import ConnectedStandardAmountCard from "./ConnectedStandardAmountCard";

// -------------------------------------------˝------------------------------
// NOTE:
// This component renders amounts that all "normal" amounts. In this case
// normal amounts include all amounts other than:
// 1. The Other Amount. (That is rendered by <OtherAmountRadioOption/>)
//
// As of now this means that this component is responsible for rendering
// "standard" looking amounts regardless of whether they are displayed as:
// 1. A button. (Default Appearance)
// 2. A card. (Appears when titles, descriptions or images are added <StandardAmountCard/>)

// -------------------------------------------------------------------------
const StandardAmountRadioOption = ({
    buttonStyle,
    checked,
    handleChange,
    id,
    index,
    label,
    name,
    displayAsCard,
    standardAndOtherAmountRefs,
    ...rest
}) => {
    const ref = standardAndOtherAmountRefs[index];
    const [active, setActive] = useState(false);

    const handleFocus = () => {
        setActive(true);
    };
    const handleBlur = () => {
        setActive(false);
    };

    const cardProps = {
        checked,
        label,
        ...rest,
    };

    return (
        <label htmlFor={`${name}_${id}`} className="input-button">
            <div
                className={cx(
                    `qg-vendor-button__input--${buttonStyle}`,
                    "amount-button",
                    `amount-button__input--${buttonStyle}`,
                    active && "amount-button__active--selectable",
                    checked && `amount-button__selected--${buttonStyle}`,
                )}
            >
                <input
                    type="radio"
                    className="-visually-hidden"
                    name={name}
                    value={id}
                    id={`${name}_${id}`}
                    checked={checked}
                    ref={ref}
                    tabIndex={0}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />

                <div className="amount-button__content">
                    {displayAsCard ? (
                        <ConnectedStandardAmountCard {...cardProps} />
                    ) : (
                        label
                    )}
                </div>
            </div>
        </label>
    );
};

StandardAmountRadioOption.propTypes = {
    buttonStyle: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    displayAsCard: PropTypes.bool.isRequired,
    standardAndOtherAmountRefs: PropTypes.arrayOf(PropTypes.shape({}))
        .isRequired,
};

export default StandardAmountRadioOption;
