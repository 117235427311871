import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectIsSinglePageForm } from "../../../redux/slices/appSettingsSlice";
import PaymentContentControl from "./PaymentContentControl";

const ConnectedPaymentContentControl = ({ controlId }) => {
    const { captcha } = useSelector(selectAllFormSettings);
    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const displayTotalGift = !isSinglePageForm;

    return (
        <PaymentContentControl
            controlId={controlId}
            displayCaptcha={captcha}
            displayTotalGift={displayTotalGift}
        />
    );
};

ConnectedPaymentContentControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedPaymentContentControl;
