import { getAdditionalDetailsInitialValues } from "@qgiv/donation-form";

const getInitialValues = (_settings, savedValues = {}) => {
    const initialValuesFromSettings = {
        ...getAdditionalDetailsInitialValues(_settings),
    };

    // Override any existing data with user-input data that has been stored
    const values = {
        ...initialValuesFromSettings,
        ...savedValues,
    };
    return values;
};

export default getInitialValues;
