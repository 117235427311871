import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { LoadingDots } from "@qgiv/core-react";

import "./LoadingScreen2.scss";

const LoadingScreen2 = ({
    active = false,
    eventModal = false,
    color = undefined,
}) => {
    const loadingScreenClassnames = useMemo(
        () =>
            cx({
                "loading-screen-2": true,
                "loading-screen-2--active": active && !eventModal,
                "loading-screen-2--event-modal": eventModal,
                "loading-screen-2--active-event-modal": active && eventModal,
            }),
        [active, eventModal],
    );

    const loadingDotsProps = {
        center: true,
    };

    if (color) {
        loadingDotsProps.color = color;
    }

    return (
        <div className={loadingScreenClassnames}>
            <LoadingDots {...loadingDotsProps} />
        </div>
    );
};

LoadingScreen2.propTypes = {
    active: PropTypes.bool,
    eventModal: PropTypes.bool,
    color: PropTypes.string,
};

export default LoadingScreen2;
