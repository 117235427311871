import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { constants, log } from "@qgiv/core-js";
import { Icon } from "@qgiv/core-react";
import { submitCreateBillingAgreement, submitCreateOrder } from "../../api";
import ProcessingDots from "../ProcessingDots";
import PayPalSDKButtons from "./PayPalSDKButtons";
import UpdateIncrementForceReRenderCount from "./UpdateIncrementForceReRenderCount";
import "./PayPalSDKScriptProvider.scss";

// -------------------------------------------------------------------------
// NOTE: setFieldValue() is intentionally used over setValues() throughout
// this file because once this data is passed into the PayPal SDK the data
// that is associated with these functions will never changes unless the
// PayPal SDK is re-rendered. Because of this if we pass the values object
// into setValues() we run the risk of resetting the form data to include
// a version of the values object that existed when this component was first
// rendered but does not include any of the updates that have been made to
// the form's values since this.
// -------------------------------------------------------------------------
const PayPalSDKScriptProvider = ({
    dispatchIncrementForceReRenderCount = () => {},
    dispatchSetHasCompletedPayPalInitialRender = () => {},
    displayUpdateIncrementForceReRenderCount = false,
    forceReRender = [],
    payPalScriptProviderOptions = {},
    getDataForCreateBillingAgreement = () => {},
    getDataForCreateOrder = () => {},
    handleCompletePayPalAuthorizationFlow = () => {},
    handleHasAnAmountToSendToPayPalError = () => {},
    handleInitiatePayPalAuthorizationFlow = () => {},
    handlePayPalAuthorizationError = () => {},
    hasAnAmountToSendToPayPal = false,
    hasCompletedInitialRender = false,
    isCms = false,
    isRecurringDonation = false,
    paymentMethodResetCount = 0,
    shouldLoadPayPalSDKScriptProvider = false,
    valuesThatShouldForceAReRender = [],
    showDisabledStateWhenNoAmountIsSelected = false,
}) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    // Create a flag that can be used to track when the type of donation has
    // changed from one time to recurring so that we can remove the old
    // PayPal JS SDK script and replace it with a new PayPal JS SDK that has
    // the updated configuration
    const [
        isReadyToLoadPayPalSDKWithUpdatedConfig,
        setIsReadyToLoadPayPalSDKWithUpdatedConfig,
    ] = useState(false);
    const [shouldDisplayPayPalError, setShouldDisplayPayPalError] =
        useState(false);
    const { handleSubmit, setFieldValue } = useFormikContext();

    // -------------------------------------------------------------------------
    // TODO:
    // See if there is a better way to ensure that the PayPal SDK reloads with
    // all of the appropriate configuration options as using a timeout value of
    // 700 was a band-aid solution that was implemented before the push freeze
    // when it was discovered that the PayPal transactions could not be
    // processed when the PayPal SDK was not being loaded in with all of the
    // appropriate configuration options when all of the following were true:
    // 1. The one time & ongoing tabs were being rendered at the same time as
    // the PayPal SDK.
    // 2. The donor selected an ongoing donations.
    // 3. The donor selected a recurring donation in Safari (aka the new IE).
    // This issue was not present in Chrome or Firefox.
    // -------------------------------------------------------------------------
    // Temporarily remove the PayPal JS SDK script before reloading it with
    // the new configuration options. The script should be reloaded every time:
    // 1. A user changes between one time & recurring donations.
    // 2. A user clicks the use a different payment method button.
    // All errors should be removed from the UI whenever the SDK is reloaded.
    useEffect(() => {
        const timeToWaitBeforeReloadingPayPalSDK = 700;
        setIsReadyToLoadPayPalSDKWithUpdatedConfig(false);

        setTimeout(() => {
            setShouldDisplayPayPalError(false);
            setIsReadyToLoadPayPalSDKWithUpdatedConfig(true);
        }, timeToWaitBeforeReloadingPayPalSDK);
    }, [isRecurringDonation, paymentMethodResetCount]);

    // The Donation form, P2P Registration App & Event Platforms all
    // contain an ancestral #app element
    const app = document.querySelector("#app");

    // -------------------------------------------------------------------------
    // NOTE:
    // Scroll the user to the center of the webpage so that any content
    // that is displayed by PayPal or Venmo is visible to the user. This
    // is important because when a donor attempts to use Venmo while in
    // production, a QR code is displayed in the center of the webpage that
    // the donor needs to be able to see in order to complete the
    // transaction. This is not an issue on forms that are not nested
    // within an iframe as this content is displayed in the center of the
    // page automatically, however when a form is nested within an iframe
    // this content is displayed in the center of the iframe, which may not
    // always be visible to a user. This is particularly true when using
    // the PayPal/Venmo button on a single step form. It is also worth
    // noting that the scrolling of the user up the page before taking them
    // back down is not ideal, however, during manual testing it appeared
    // to be the only way to reliably get the user back to the center of
    // the form as attempts to scroll the user to the center of the webpage
    // straight away did not appear to reliably take the user to the center
    // of the webpage. It is also worth noting that I experimented with
    // scrollTo() when implementing this functionality in attempt to top the
    // scroll up before scrolling down, however scrollTo() did not appear to
    // have any effect on the surrounding webpage when used within an iframe.
    // -------------------------------------------------------------------------
    const scrollUserToCenterOfWebpage = () => {
        if (app) {
            app.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });

            setTimeout(() => {
                app.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }, 3000);
        }
    };

    const scrollUserToBottomOfWebpage = () => {
        if (app) {
            app.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    };

    // -------------------------------------------------------------------------
    // NOTE: Handles all of the platform and and location specific UI updates
    // that affect application state after completing the PayPal SDK request.
    // -------------------------------------------------------------------------
    // Prepare all of the form data, display the PayPal / Venmo authorization
    // message and auto-submit the form
    const prepareDataAndSubmitForm = (data, options) => {
        const { orderID = "", agreementID = "" } = options;
        const { payerID = "", paymentSource = "paypal" } = data;
        // Because the order ID on the new PayPal integration serves the same
        // purpose as the token on the legacy PayPal integration platform
        // wanted to store the order ID in the PayPal_Token field as this value
        // gets treated the same way during processing.
        const token = orderID;
        const payPalPaymentSource = paymentSource;

        // Prep the form values that you need to set
        let payPalValues = {
            PayPal_Payer_ID: payerID,
            PayPal_Token: token,
        };

        // The billing agreement ID is required for recurring donations
        if (agreementID) {
            payPalValues = {
                PayPal_Agreement_ID: agreementID,
                ...payPalValues,
            };
        }

        // Pass venmo flag to determine payment channel
        if (paymentSource === "venmo") {
            payPalValues = {
                PayPal_Is_Venmo: true,
                ...payPalValues,
            };
        }

        // Platform and location specific logic
        handleCompletePayPalAuthorizationFlow({
            payPalPaymentSource,
            payPalValues,
        });
        // Wait until Formik has had time to update the form with the new
        // PayPal values before using the existing submission infrastructure to
        // send a submit request to the BE
        setTimeout(() => {
            handleSubmit();
        }, 0);
    };

    // -------------------------------------------------------------------------
    // MARK: Setup for the different props that can be passed into the
    // PayPalButtons component.
    // -------------------------------------------------------------------------
    // Called on button click. Makes an API request to internal server to set
    // up the transaction. Used for recurring donations only.
    const createBillingAgreement = () => {
        // Grab all of the platform specific data that the BE requires in order
        // to create a PayPal billing agreement and send it to our internal
        // create_billing_agreement API
        const dataForCreateBillingAgreement =
            getDataForCreateBillingAgreement();

        return submitCreateBillingAgreement(dataForCreateBillingAgreement)
            .then((response) => response.data)
            .then(
                (createBillingAgreementResponseData) =>
                    // The token_id key contains the billing token that PayPal
                    // expects to be returned by this function
                    createBillingAgreementResponseData.token_id,
            );
    };

    // Called on button click. Makes an API request to internal server to set
    // up the transaction. Refer to section 4.2.6  of the PayPal Design doc for
    // more info on how the createOrder() function that is passed into the
    // JavaScript SDK should be used in conjunction with the PayPal Commerce
    // Platform. The PayPal Commerce Platform is the use case that is relevant
    // to our implementation of this feature as are not a PayPal merchant but
    // are a PayPal partner that is processing transactions on behalf of
    // different PayPal merchants. Used for one time donations only.
    const createOrder = (data, actions) => {
        // Grab all of the platform specific data that the BE requires in order
        // to create a PayPal order and send it to our internal create_order
        // PayPal API.
        const dataToSubmitToCreateOrder = getDataForCreateOrder(data, actions);

        return submitCreateOrder(dataToSubmitToCreateOrder)
            .then((response) => response.data)
            .then(
                (createOrderResponseData) =>
                    // The id key contains the id of the order and PayPal will
                    // throw an error if the order id is not returned
                    createOrderResponseData.id,
            );
    };

    // Called when finalizing the transaction and is typically used to inform
    // the buyer that the transaction is complete. In the Legacy implementation
    // of the form, we extracted data from the e.data.token & e.data.payerID
    // keys in the useEventListener() message  handler and pass those along to
    // the BE in the PayPal_Token & PayPal_Payer_ID keys.
    const onApprove = (data, actions) => {
        // Take the user to the bottom of the webpage so that they can see the "submitting" animation
        scrollUserToBottomOfWebpage();

        // If processing a recurring donation, hit the internal create_order
        // endpoint in order to successfully create the order after setting up
        // the billing agreement and prepare the form for submission. If
        // handling a one time donation the create_order endpoint does not need
        // to be hit again and we can just prepare the form for submission.
        if (isRecurringDonation) {
            const { billingToken = "" } = data;
            // Grab all of the platform specific data that the BE requires in order
            // to create a PayPal order and send it to our internal create_order
            // PayPal API once a billing agreement has been created
            let dataToSubmitToCreateOrder = getDataForCreateOrder(
                data,
                actions,
            );

            dataToSubmitToCreateOrder = {
                ...dataToSubmitToCreateOrder,
                billingToken,
            };

            // For recurring donations, once the billing token has been generated,
            // the data needs to be passed to the internal create order API so that
            // the billing agreement can be executed and the order can be created.
            // The docs related to the createOrder() function that is part of
            // react-paypal's API are not relevant here as we are hitting our
            // internal create_order endpoint here and are not using the
            // createOrder() that is part of react-paypal.
            submitCreateOrder(dataToSubmitToCreateOrder)
                .then((response) => response.data)
                .then((createOrderResponseData) => {
                    // Get the billing agreement id and the order id from the
                    // response that is returned from the internal create_order
                    // API. The order id that the BE wants is located on the
                    // order.id key of the data returned by the create order
                    // API which is different tha data on the the orderID key
                    // of the data object that is passed into the onApprove()
                    // function when handling a recurring donation.
                    const orderID = createOrderResponseData.order.id;
                    const agreementID = createOrderResponseData.id;
                    const options = {
                        agreementID,
                        orderID,
                    };

                    prepareDataAndSubmitForm(data, options);
                });
        } else {
            // Extract the order id from the data object that is part of the
            // onApprove() function as that order id is correct when attempting
            // to process a one time donation
            const { orderID = "" } = data;
            const options = {
                orderID,
            };

            prepareDataAndSubmitForm(data, options);
        }
    };

    // -------------------------------------------------------------------------
    // NOTE: Handles all of the platform and location specific UI updates that
    // affect application state after initiating the PayPal SDK request.
    // -------------------------------------------------------------------------
    // Do not do anything in CMS. However if the user is not in CMS, and the
    // button has not been disabled, set all of the relevant Formik values,
    // validate the form and if the form passes validation, initiate the PayPal
    // authorization request. We also need to manually check if the button is
    // not disabled too because for some unknown reason PayPal still runs this
    // click logic even when the button is disabled.
    const onClick = () => {
        if (!isCms && !hasAnAmountToSendToPayPal) {
            handleHasAnAmountToSendToPayPalError();
        }

        if (!isCms && hasAnAmountToSendToPayPal) {
            setFieldValue("Payment_Type", PaymentType.PAYPAL);
            scrollUserToCenterOfWebpage();
            handleInitiatePayPalAuthorizationFlow(true);
        }
    };

    // A catch for all errors preventing buyer checkout. Often used to show
    // the buyer an error page. This is not just a nice to have as the PayPal
    // documentation requires that you implement an error message in order to
    // be approved
    const onError = (error) => {
        log({ error });
        setShouldDisplayPayPalError(true);
        handlePayPalAuthorizationError(false);
    };

    // Disable the PayPal button unless we can confirm that we have an amount
    // to send to PayPal to ensure that the PayPal button does not open a new
    // tab & generate a failed authorization request
    const disabled = !hasAnAmountToSendToPayPal;

    // Add class so that the button can be styled to appear enabled even though
    // is disabled to prevent PayPal from opening a new tab
    const className = showDisabledStateWhenNoAmountIsSelected
        ? ""
        : "qg-paypal-buttons";

    // Use the vertical button layout and render "PayPal" without any
    // additional text
    const style = {
        layout: "vertical",
        label: "paypal",
    };

    // -------------------------------------------------------------------------
    // NOTE: Do not pass the onShippingChange handler to the <PayPalSDKButtons/>
    // component as when you do this, the Venmo button stops displaying in the
    // UI. There was no mention of this behavior in the official PayPal
    // documentation so this behavior was only discovered after manual
    // experimentation & should be considered when making updates to this
    // component.
    // -------------------------------------------------------------------------
    // Consolidate all of the props that are going to be passed into the
    // PayPalButtons component into a single object
    let paypalButtonsProps = {
        className,
        disabled,
        forceReRender,
        onApprove,
        onClick,
        onError,
        style,
    };

    // Because react-paypal throws an error if createBillingAgreement() and
    // the createOrder() functions are passed into the same PayPalButtons
    // components, pass in the handler that is relevant to the type of
    // transaction that will be created
    if (
        shouldLoadPayPalSDKScriptProvider &&
        isReadyToLoadPayPalSDKWithUpdatedConfig &&
        isRecurringDonation
    ) {
        paypalButtonsProps = {
            ...paypalButtonsProps,
            createBillingAgreement,
            style,
        };
    }

    if (
        shouldLoadPayPalSDKScriptProvider &&
        isReadyToLoadPayPalSDKWithUpdatedConfig &&
        !isRecurringDonation
    ) {
        paypalButtonsProps = {
            ...paypalButtonsProps,
            createOrder,
        };
    }

    // -------------------------------------------------------------------------
    // NOTE: Consolidate all of the props that are going to be passed into the
    // UpdateIncrementForceReRenderCount component into a single object
    // -------------------------------------------------------------------------
    const updateIncrementForceReRenderCountProps = {
        dispatchIncrementForceReRenderCount,
        dispatchSetHasCompletedPayPalInitialRender,
        hasCompletedInitialRender,
        valuesThatShouldForceAReRender,
    };

    // -------------------------------------------------------------------------
    // MARK: The different versions of the markup that can be rendered to the UI
    // -------------------------------------------------------------------------
    // An alternative payment method was not suggested here to help ensure that
    // PayPal does not take exception to this during the certification process
    if (shouldDisplayPayPalError) {
        return (
            <div className="message message--error message--active">
                <Icon
                    glyph="frown-open-solid"
                    type="FontAwesome"
                    classNames={["message__icon"]}
                />
                <div className="message__content">
                    <div>
                        Oops! It looks like something went wrong with PayPal.
                        Please refresh the page to try again.
                    </div>
                </div>
            </div>
        );
    }

    // If there has been a change from one time to recurring donations, render
    // some placeholder markup instead of the PayPal JS SDK to ensure that the
    // PayPal JS SDK can be removed from the DOM and re-rendered with its
    // updated configuration. If it is not removed from the markup the script
    // will never be re-loaded using the updated config options required to
    // support either one time or recurring donations
    if (
        shouldLoadPayPalSDKScriptProvider &&
        !isReadyToLoadPayPalSDKWithUpdatedConfig
    ) {
        // Match the height of the container that surrounds the processing
        // animation to the height of a typical PayPal button
        return (
            <ProcessingDots
                containerStyle={{ height: "45px" }}
                spinnerStyle="dark"
            />
        );
    }

    // -------------------------------------------------------------------------
    // TODO: Remove the displayUpdateIncrementForceReRenderCount flag once we
    // confirm that the UpdateIncrementForceReRenderCount successfully resolved
    // QG-32309 and are able to spend time to introduce this solution everywhere.
    // -------------------------------------------------------------------------
    // Load the PayPal JS SDK and the PayPal Smart Buttons with the updated
    // config options
    if (
        shouldLoadPayPalSDKScriptProvider &&
        isReadyToLoadPayPalSDKWithUpdatedConfig
    ) {
        return (
            <PayPalScriptProvider options={payPalScriptProviderOptions}>
                {displayUpdateIncrementForceReRenderCount && (
                    <UpdateIncrementForceReRenderCount
                        {...updateIncrementForceReRenderCountProps}
                    />
                )}
                <PayPalSDKButtons {...paypalButtonsProps} />
            </PayPalScriptProvider>
        );
    }

    // Let the user know that we are missing a client id if one has not been
    // set up because they have not toggled the form level Testing setting on.
    // -------------------------------------------------------------------------
    // MARK: This is a use case that should only ever happen in a dev environment.
    // -------------------------------------------------------------------------
    return (
        <p>
            <strong>
                Your PayPal account is missing a PayPal Client ID. If you using
                this form in a testing environment toggle the Testing system
                option on at the form level. If you are a client please contact
                customer support.
            </strong>
        </p>
    );
};

PayPalSDKScriptProvider.propTypes = {
    displayUpdateIncrementForceReRenderCount: PropTypes.bool,
    dispatchIncrementForceReRenderCount: PropTypes.func,
    dispatchSetHasCompletedPayPalInitialRender: PropTypes.func,
    // Data that needs to be passed down to the PayPalButtons and
    // PayPalScriptProvider components
    forceReRender: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]).isRequired,
    ).isRequired,
    payPalScriptProviderOptions: PropTypes.shape({
        "client-id": PropTypes.string.isRequired,
        "merchant-id": PropTypes.string.isRequired,
        "enable-funding": PropTypes.string.isRequired,
        "disable-funding": PropTypes.string.isRequired,
        "data-partner-attribution-id": PropTypes.string.isRequired,
        intent: PropTypes.string.isRequired,
    }).isRequired,
    // Platform specific data that needs to be collected for one time and
    // recurring donations
    getDataForCreateBillingAgreement: PropTypes.func.isRequired,
    getDataForCreateOrder: PropTypes.func.isRequired,
    // Platform and implementation specific logic to be run on initiation and
    // completion of the PayPal request
    handleCompletePayPalAuthorizationFlow: PropTypes.func.isRequired,
    handleHasAnAmountToSendToPayPalError: PropTypes.func,
    handleInitiatePayPalAuthorizationFlow: PropTypes.func.isRequired,
    handlePayPalAuthorizationError: PropTypes.func,
    // Other flags
    hasAnAmountToSendToPayPal: PropTypes.bool.isRequired,
    hasCompletedInitialRender: PropTypes.bool,
    isCms: PropTypes.bool.isRequired,
    isRecurringDonation: PropTypes.bool.isRequired,
    paymentMethodResetCount: PropTypes.number.isRequired,
    shouldLoadPayPalSDKScriptProvider: PropTypes.bool.isRequired,
    // We need to allow different values to be passed into this array in
    // order for this component to be reusable across the different apps.
    // The most important thing however is that the values that are passed
    // into this component in a particular instance of a function remain
    // consistent and do not change from one render to the next
    // eslint-disable-next-line react/forbid-prop-types
    valuesThatShouldForceAReRender: PropTypes.arrayOf(PropTypes.any),
    showDisabledStateWhenNoAmountIsSelected: PropTypes.bool,
};

export default PayPalSDKScriptProvider;
