import React, { useCallback } from "react";
import { constants } from "@qgiv/core-js";
import ConnectedGiftPage from "../Pages/GiftPage";
import ConnectedDetailsPage from "../Pages/DetailsPage";
import ConnectedAdditionalDetailsPage from "../Pages/AdditionalDetailsPage";
import ConnectedPaymentPage from "../Pages/PaymentPage";
import ConnectedReceiptPage from "../Pages/ReceiptPage";
import EventsPage from "../Pages/EventsPage";

/**
 * DonationFormPageSwitch component renders the appropriate donation form page
 * based on the currentDonationFormPage prop.
 * @param {object} props
 * @param {number} props.currentDonationFormPage - The current page of the donation form.
 * @returns {React.ReactElement} The rendered donation form page.
 */
const DonationFormPageSwitch = ({ currentDonationFormPage }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: {
                DONATION_AMOUNTS,
                DETAILS,
                ADDITIONAL_DETAILS,
                PAYMENT_DETAILS,
                CONFIRMATION,
                QGIV_EVENTS,
            },
        },
    } = constants;

    const renderPage = useCallback(() => {
        switch (currentDonationFormPage) {
            case DONATION_AMOUNTS:
                return <ConnectedGiftPage />;
            case DETAILS:
                return <ConnectedDetailsPage />;
            case ADDITIONAL_DETAILS:
                return <ConnectedAdditionalDetailsPage />;
            case PAYMENT_DETAILS:
                return <ConnectedPaymentPage />;
            case CONFIRMATION:
                return <ConnectedReceiptPage />;
            case QGIV_EVENTS:
                return <EventsPage />;
            default:
                return <div>I am a default</div>;
        }
    }, [
        ADDITIONAL_DETAILS,
        CONFIRMATION,
        DETAILS,
        DONATION_AMOUNTS,
        PAYMENT_DETAILS,
        currentDonationFormPage,
        QGIV_EVENTS,
    ]);

    return renderPage();
};

export default DonationFormPageSwitch;
