import { isDisplayable } from "@qgiv/core-js";
import { getFieldValue } from "@qgiv/core-donor";

/**
 * @public
 * @function getDisplayableActiveDedications
 * @description Filter array of active dedications by current display type (Desktop, Mobile, Text-to-Donate)
 * @param {object} settings object containing all params
 * @param {Array} settings.dedications Type of values to pull out
 * @param {number} settings.currentDisplay current display type (Desktop, Mobile, Text-to-Donate)
 * @returns {Array} object that contains only the values enabled on current display
 */
export const getDisplayableActiveDedications = ({
    dedications,
    currentDisplay,
}) => {
    // Exit early if missing data
    if (!dedications) return [];

    return dedications.filter((dedication) =>
        isDisplayable(dedication, currentDisplay),
    );
};

/**
 * @public
 * @function getHasDisplayableDedicationOnThisPage
 * @description Check if there are displayable dedications for current page since dedications can be displayed
 *              on Gift, Donor Details or Additional Details page
 * @param {object} settings object containing all params
 * @param {number} settings.currentDisplay current display type (Desktop, Mobile, Text-to-Donate)
 * @param {number} settings.currentPage current page the user is on
 * @param {object} settings.dedicationSettings All dedication settings from initial state
 * @returns {boolean} Flag for whether there are displayable dedications and should they display on the current page
 */
export const getHasDisplayableDedicationOnThisPage = ({
    currentDisplay,
    currentPage,
    dedicationSettings,
}) => {
    // Exit early if missing data
    if (!dedicationSettings) return false;

    const { dedications = [], dedicationLocation = 0 } = dedicationSettings;

    if (!dedications || dedicationLocation !== currentPage) return false;

    const activeDisplayableDedications = getDisplayableActiveDedications({
        dedications,
        currentDisplay,
    });

    if (activeDisplayableDedications.length === 0) return false;

    return true;
};

/**
 * @public
 * @function getDisplayableDedicationFields
 * @description Filter array of dedication fields by current display type (Desktop, Mobile, Text-to-Donate)
 * @param {object} params object containing all params
 * @param {Array} params.fields Array of dedication fields to filter
 * @param {number} params.currentDisplay Current display type (Desktop, Mobile, Text-to-Donate)
 * @returns {object} Object that contains only the fields enabled on the current display
 */
export const getDisplayableDedicationFields = ({ fields, currentDisplay }) => {
    // Exit early if missing data
    if (!fields) return {};

    const displayableDedicationFields = fields.reduce((acc, field) => {
        if (isDisplayable(field, currentDisplay)) {
            acc[field.id] = field;
        }
        return acc;
    }, {});

    return displayableDedicationFields;
};

/**
 * @function getDedicationFieldsInitialValues
 * @param {object} displayableDedicationFields
 * @description Gets the initial values for any displayable dedication fields.
 * @returns {object}
 */
export const getDedicationFieldsInitialValues = (
    displayableDedicationFields,
) => {
    // return values object with key of dedication field id
    const dedicationFieldsInitialValues = displayableDedicationFields.reduce(
        (valuesObj, currentField) => {
            const fieldId = currentField.fieldNamePath;
            const value = getFieldValue(currentField);
            return {
                ...valuesObj,
                [fieldId]: value,
            };
        },
        {},
    );
    return dedicationFieldsInitialValues;
};
