import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getOtherAmountData,
    getStandardAmountsData,
} from "@qgiv/donation-form";
import { getPropsToShareAmongAmountsComponents } from "./restrictionCardAmountsHelpers";
import { selectAllDonationDetails } from "../../../../../../../redux/slices/donationDetailsSlice";
import { selectCmsControlByIdByPage } from "../../../../../../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../../../../../../redux/slices/appSettingsSlice";
import RestrictionCardAmounts from "./RestrictionCardAmounts";

/**
 * @typedef {import("@qgiv/donation-form").ReduxTypes.DonationSettings.Amount} Amount
 */

/**
 *
 * @param {object} props
 * @param {Amount[]} props.amountsToDisplayWithRestriction
 * @param {number} props.controlId
 * @param {number} props.restrictionId
 * @returns {React.ReactElement}
 */
const ConnectedRestrictionCardAmounts = ({
    amountsToDisplayWithRestriction,
    controlId,
    restrictionId,
}) => {
    const currentPage = useSelector(selectCurrentPage);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, currentPage),
    );
    const donationDetails = useSelector(selectAllDonationDetails);
    const formikContext = useFormikContext();

    // Data that is used to generate props
    const propsToShareAmongAmountsComponents =
        getPropsToShareAmongAmountsComponents({
            amountsToDisplayWithRestriction,
            control,
            formikContext,
            donationDetails,
            restrictionId,
        });
    const standardAmountsToDisplay = getStandardAmountsData(
        amountsToDisplayWithRestriction,
    );
    const otherAmountToDisplay = getOtherAmountData(
        amountsToDisplayWithRestriction,
    );

    // Reassign and create variables as needed
    const otherAmountRadioOptionProps = {
        ...propsToShareAmongAmountsComponents,
    };
    const showOtherAmount = Object.keys(otherAmountToDisplay).length > 0;
    const showStandardAmounts = standardAmountsToDisplay.length > 0;
    const standardAmountsRadioOptionsProps = {
        ...propsToShareAmongAmountsComponents,
    };

    const restrictionCardAmountsProps = {
        otherAmountRadioOptionProps,
        showOtherAmount,
        showStandardAmounts,
        standardAmountsRadioOptionsProps,
    };

    return <RestrictionCardAmounts {...restrictionCardAmountsProps} />;
};

export default ConnectedRestrictionCardAmounts;
