import React from "react";
import PropTypes from "prop-types";
import useAcceptReminder from "./useAcceptReminder";
import { GiftPageContainers } from "../../../Containers";

const GiftPageContent = ({ containers = [], displayCaptcha }) => {
    // useAcceptReminder will only ever be used when on the embed version
    // of the form. It is being placed at this level so that single step forms
    // will work with abandonedGift data. AbandonedGift data does not change
    // in the modal version of this form's life cycle
    useAcceptReminder();

    const giftPageContentProps = {
        containers,
        displayCaptcha,
    };

    return <GiftPageContainers {...giftPageContentProps} />;
};

GiftPageContent.propTypes = {
    containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    displayCaptcha: PropTypes.bool.isRequired,
};

export default GiftPageContent;
