import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { formikOptionConverter, WidgetHeading } from "@qgiv/core-donor";
import { selectCmsControlById } from "../../redux/slices/cmsSettingsSlice";
import { selectCurrentPage } from "../../redux/slices/appSettingsSlice";

const ConnectedWidgetHeadingControl = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { CONFIRMATION },
        },
    } = constants;

    const control = useSelector((state) =>
        selectCmsControlById(state, controlId),
    );

    const currentPage = useSelector(selectCurrentPage);
    // Confirmation page is not wrapped in formik
    // so useFormikContent will cause fatal error
    if (currentPage === CONFIRMATION) {
        const {
            options: { text = "" },
        } = control;

        return <WidgetHeading text={text} />;
    }

    return <ConnectedWidgetHeadingControlFormik control={control} />;
};

ConnectedWidgetHeadingControl.propTypes = {
    controlId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

const ConnectedWidgetHeadingControlFormik = ({ control }) => {
    const { values } = useFormikContext();
    const { options } = control;
    const { text } = formikOptionConverter(options, values);
    return <WidgetHeading text={text} />;
};

ConnectedWidgetHeadingControlFormik.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }).isRequired,
};

export default ConnectedWidgetHeadingControl;
