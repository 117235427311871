import React from "react";
import PropTypes from "prop-types";
import ReceiptPageContainers from "../../../Containers/ReceiptPageContainers";

const ReceiptPageContent = ({ containers = [] }) => (
    <ReceiptPageContainers containers={containers} />
);

ReceiptPageContent.propTypes = {
    containers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ReceiptPageContent;
