import React from "react";
import PropTypes from "prop-types";

import "./TextButton.scss";

const TextButton = (props) => {
    const {
        ariaHidden = false,
        ariaLabel = "",
        children,
        handleClick,
        styles,
    } = props;
    const buttonProps = {
        className: `qg-vendor-text-button text-button ${styles}`,
        onClick: handleClick,
        type: "button",
    };
    if (ariaHidden) {
        buttonProps["aria-hidden"] = ariaHidden;
    }
    if (ariaLabel) {
        buttonProps["aria-label"] = ariaLabel;
    }
    return <button {...buttonProps}>{children}</button>;
};

TextButton.propTypes = {
    ariaHidden: PropTypes.bool,
    ariaLabel: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    handleClick: PropTypes.func,
    styles: PropTypes.string,
};

export default TextButton;
