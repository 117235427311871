/**
 * @typedef {import("./restrictionGroupsTypes").RestrictionCardsProps} RestrictionCardsProps
 * @typedef {import("./restrictionGroupsTypes").RestrictionGroupProps} RestrictionGroupProps
 * @typedef {import("@qgiv/donation-form").ReduxTypes.RestrictionSettings.MultiRestrictionGroup} MultiRestrictionGroup
 */

/**
 *
 * @param {MultiRestrictionGroup} restrictionGroup
 * @returns {RestrictionCardsProps}
 * @description Need to pass down the list of ids so that we know which
 * restrictions to display in the group.
 */
export const getRestrictionCardsProps = (restrictionGroup) => {
    const { restrictionIds = [] } = restrictionGroup;

    const restrictionIdsForRestrictionGroup = restrictionIds;
    const restrictionCardsProps = {
        restrictionIdsForRestrictionGroup,
    };

    return restrictionCardsProps;
};

/**
 *
 * @param {MultiRestrictionGroup[]} restrictionGroupsToDisplay
 * @param {number} controlId
 * @returns {RestrictionGroupProps[]}
 */
export const getRestrictionGroups = (restrictionGroupsToDisplay, controlId) => {
    const numberOfRestrictionGroups = restrictionGroupsToDisplay.length;

    /**
     *
     * @param {MultiRestrictionGroup} restrictionGroup
     * @param {number} index
     * @returns {RestrictionGroupProps}
     */
    const getRestrictionGroupProps = (restrictionGroup, index) => {
        const { description = "", id = 0, title = "" } = restrictionGroup;

        const indexOfLastRestrictionGroup = numberOfRestrictionGroups - 1;
        const isLastRestrictionGroup = index === indexOfLastRestrictionGroup;
        // Because names are required if there is no name then we are going to
        // assume that it is  part of a restriction group
        const isPartOfRestrictionGroup = !!title;

        // Reassign and create variables as needed
        const key = `restriction_group_${id}`;
        const name = title;
        const restrictionCardsProps =
            getRestrictionCardsProps(restrictionGroup);
        const showDescription = !!description;
        const showDivider = !isLastRestrictionGroup;
        const showGroupDetails = isPartOfRestrictionGroup;

        const restrictionGroupProps = {
            controlId,
            description,
            key,
            name,
            restrictionCardsProps,
            showDescription,
            showDivider,
            showGroupDetails,
        };

        return restrictionGroupProps;
    };

    let restrictionGroups = [];

    if (Array.isArray(restrictionGroupsToDisplay)) {
        restrictionGroups = restrictionGroupsToDisplay.map(
            getRestrictionGroupProps,
        );
    }

    return restrictionGroups;
};
