import { qfetch } from "@qgiv/core-js";

const baseUrl =
    "/admin/qgivadmin/utilities/generateDonationFormEmbedErrorToLog.php";

const sendEmbedError = ({ entity, entityType, HTTP_HOST }) =>
    qfetch.get(
        `${HTTP_HOST}${baseUrl}?entity=${entity}&entityType=${entityType}`,
    );

export default sendEmbedError;
