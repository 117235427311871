import React from "react";
import cx from "classnames";
import ConnectedAmounts from "../../Amounts";
import ConnectedDonationFrequenciesSelect from "../../DonationFrequenciesSelect";
import ConnectedRecurringImpactAndDates from "../../RecurringImpactAndDates";
import ConnectedStandardRestrictionsContainer from "../../StandardRestrictionsContainer";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @param {string} props.createYourOwnPlanName
 * @param {string} props.cyopCustomizeLabel
 * @param {Function} props.handleChange
 * @param {number} props.id
 * @param {boolean} props.selected
 * @param {boolean} props.shouldDisplayRestrictions
 * @param {boolean} props.isInstallment
 * @returns {React.ReactElement} The Create Your Own Plan component.
 */
const CreateYourOwnPlan = ({
    controlId,
    createYourOwnPlanName = "Create Your Own",
    cyopCustomizeLabel = "Customize",
    handleChange = () => {},
    id,
    selected,
    shouldDisplayRestrictions,
    isInstallment,
}) => (
    <>
        <div className="col col--12">
            <label
                className="giving-plan-card-container"
                htmlFor={`Selected-Plan-${id}`}
            >
                <input
                    id={`Selected-Plan-${id}`}
                    className="-visually-hidden"
                    type="radio"
                    name="Selected_Plan"
                    value={id}
                    onChange={handleChange}
                />
                <div
                    className={cx(
                        "giving-plan-card",
                        "-cursor--pointer",
                        selected && "giving-plan-card__selected",
                    )}
                >
                    <div className="create-your-own-plan-card__inner">
                        <div>
                            <span className="-type--large">
                                {createYourOwnPlanName}
                            </span>
                        </div>

                        <span className="create-your-own-plan-card__label">
                            {cyopCustomizeLabel}
                        </span>
                    </div>
                </div>
            </label>
        </div>
        {selected && (
            <div className="col col--12">
                {!isInstallment && (
                    <ConnectedDonationFrequenciesSelect controlId={controlId} />
                )}

                <ConnectedAmounts controlId={controlId} />

                {shouldDisplayRestrictions && (
                    <ConnectedStandardRestrictionsContainer
                        controlId={controlId}
                    />
                )}

                <div className="grid">
                    <div className="col col--12">
                        <ConnectedRecurringImpactAndDates
                            controlId={controlId}
                        />
                    </div>
                </div>
            </div>
        )}
    </>
);

export default CreateYourOwnPlan;
