import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getHasDisplayableSubRestriction,
    getRestrictionById,
} from "@qgiv/donation-form";
import { selectRestrictions } from "../../../../../redux/slices/restrictionSettingsSlice";
import StandardRestrictionsContainer from "./StandardRestrictionsContainer";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement} The StandardRestrictionsContainer component.
 */
const ConnectedStandardRestrictionsContainer = ({ controlId }) => {
    const formikContext = useFormikContext();
    const restrictions = useSelector(selectRestrictions);
    const { values } = formikContext;

    const selectedRestriction = getRestrictionById(
        restrictions,
        values.Standard_Restriction_Id,
    );
    const hasDisplayableSubRestriction =
        getHasDisplayableSubRestriction(selectedRestriction);
    const showSubRestrictions = hasDisplayableSubRestriction;

    const standardRestrictionsContainerProps = {
        controlId,
        showSubRestrictions,
    };

    return (
        <StandardRestrictionsContainer
            {...standardRestrictionsContainerProps}
        />
    );
};

export default ConnectedStandardRestrictionsContainer;
