import React, { useEffect, useState } from "react";
import { constants } from "@qgiv/core-js";
import { Divider } from "@qgiv/core-donor";
import { useSelector, useDispatch } from "react-redux";
import { getSkipAdditionalDetailsPageValue } from "@qgiv/donation-form";
import { useFormikContext } from "formik";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectCmsPageOrder } from "../../../redux/slices/cmsSettingsSlice";
import {
    selectSkipAdditionalDetailsPage,
    updateSkipAdditionalDetailsPage,
} from "../../../redux/slices/appSettingsSlice";
import {
    selectAllAdditionalDetailsPageCustomFields,
    selectAllFieldsAndGroups,
} from "../../../redux/slices/fieldsSlice";
import {
    selectHasActiveFulfillment,
    selectHasCompletedFulfillment,
} from "../../../redux/slices/promiseTransactionSlice";
import ConnectedPaymentPageContent from "../../Pages/PaymentPage/PaymentPageContent";
import SinglePageContent from "./SinglePageContent";
import ConnectedGiftPageContent from "../../Pages/GiftPage/GiftPageContent";
import ConnectedDetailsPageContent from "../../Pages/DetailsPage/DetailsPageContent";
import ConnectedAdditionalDetailsPageContent from "../../Pages/AdditionalDetailsPage/AdditionalDetailsPageContent";

/**
 * @param {object} props
 * @param {object} props.conditionalLogicOptions - The conditional logic options for custom fields.
 * @param {boolean} props.hasDisplayableDedicationOnAdditionalDetailsPage - Flag indicating if there is a displayable dedication on the additional details page.
 * @param {boolean} props.hasDisplayableMatchingGiftOnAdditionalDetailsPage - Flag indicating if there is a displayable matching gift on the additional details page.
 * @returns {React.ReactElement} The rendered component.
 */
const ConnectedSinglePageContent = ({
    conditionalLogicOptions,
    hasDisplayableDedicationOnAdditionalDetailsPage,
    hasDisplayableMatchingGiftOnAdditionalDetailsPage,
}) => {
    const {
        ENUMS: {
            CmsPageStandardizer: {
                DETAILS,
                DONATION_AMOUNTS,
                ADDITIONAL_DETAILS,
                PAYMENT_DETAILS,
                CONFIRMATION,
                QGIV_EVENTS,
            },
        },
    } = constants;

    const dispatch = useDispatch();
    const { values } = useFormikContext();

    const pageOrder = useSelector(selectCmsPageOrder);

    const { captcha } = useSelector(selectAllFormSettings);

    const additionalDetailsPageCustomFields = useSelector(
        selectAllAdditionalDetailsPageCustomFields,
    );
    const allFieldsAndGroups = useSelector(selectAllFieldsAndGroups);

    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);
    const hasCompletedFulfillment = useSelector(selectHasCompletedFulfillment);

    const skipAdditionalDetailsPage = useSelector(
        selectSkipAdditionalDetailsPage,
    );
    const [expectedLastDividerIndex, setExpectedLastDividerIndex] = useState(
        skipAdditionalDetailsPage ? 1 : 2,
    );

    const newSkipAdditionalDetailsPageValue = getSkipAdditionalDetailsPageValue(
        {
            additionalDetailsPageCustomFields,
            allFieldsAndGroups,
            conditionalLogicOptions,
            hasDisplayableDedicationOnAdditionalDetailsPage,
            hasDisplayableMatchingGiftOnAdditionalDetailsPage,
            values,
            hasActiveFulfillment,
        },
    );

    useEffect(() => {
        if (newSkipAdditionalDetailsPageValue !== skipAdditionalDetailsPage) {
            dispatch(
                updateSkipAdditionalDetailsPage(
                    newSkipAdditionalDetailsPageValue,
                ),
            );
            setExpectedLastDividerIndex(
                newSkipAdditionalDetailsPageValue ? 1 : 2,
            );
        }
    }, [
        dispatch,
        newSkipAdditionalDetailsPageValue,
        skipAdditionalDetailsPage,
    ]);

    const pageSections = pageOrder.filter(
        (page) => page !== CONFIRMATION && page !== QGIV_EVENTS,
    );

    const renderSinglePageContent = () =>
        // Map through pageOrder and render the appropriate page content
        // Add a divider between each page content to separate them. Look
        // at the index and do not add a divider if it is the last page.
        pageSections.map((page, index) => {
            const displayCaptcha = false;
            // Determine if a divider should be shown based on the order of the pages.
            // This may change once I get the pageOrder data from the BE.

            const shouldShowDivider = index <= expectedLastDividerIndex;

            if (Number(page) === DONATION_AMOUNTS) {
                return (
                    <div key={page}>
                        <ConnectedGiftPageContent
                            displayCaptcha={displayCaptcha}
                        />
                        {shouldShowDivider && !hasCompletedFulfillment && (
                            <Divider
                                moreSpace
                                classNames="-margin-bottom--30 -margin-top--30"
                            />
                        )}
                    </div>
                );
            }
            if (Number(page) === DETAILS && !hasCompletedFulfillment) {
                return (
                    <div key={page}>
                        <ConnectedDetailsPageContent
                            displayCaptcha={displayCaptcha}
                        />
                        {shouldShowDivider && (
                            <Divider
                                moreSpace
                                classNames="-margin-bottom--30 -margin-top--30"
                            />
                        )}
                    </div>
                );
            }
            if (
                Number(page) === ADDITIONAL_DETAILS &&
                !skipAdditionalDetailsPage &&
                !hasCompletedFulfillment
            ) {
                return (
                    <div key={page}>
                        <ConnectedAdditionalDetailsPageContent
                            displayCaptcha={displayCaptcha}
                        />
                        {shouldShowDivider && (
                            <Divider
                                moreSpace
                                classNames="-margin-bottom--30 -margin-top--30"
                            />
                        )}
                    </div>
                );
            }
            if (Number(page) === PAYMENT_DETAILS && !hasCompletedFulfillment) {
                return (
                    <div key={page}>
                        <ConnectedPaymentPageContent
                            displayCaptcha={displayCaptcha}
                        />
                        {shouldShowDivider && (
                            <Divider
                                moreSpace
                                classNames="-margin-bottom--30 -margin-top--30"
                            />
                        )}
                    </div>
                );
            }

            return null;
        });
    const singlePageContentProps = {
        captcha,
        renderSinglePageContent,
        hasCompletedFulfillment,
    };

    return <SinglePageContent {...singlePageContentProps} />;
};

export default ConnectedSinglePageContent;
