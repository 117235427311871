import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    determineCorrespondingEmptyValueToUpdate,
    getStandardAmountsData,
} from "@qgiv/donation-form";
import { getStandardAmounts } from "./standardAmountsRadioOptionsHelpers";
import { selectAllFormSettings } from "../../../../../../../../redux/slices/formSettingsSlice";
import { updateGiftDetails } from "../../../../../../../../redux/slices/donationDetailsSlice";
import StandardAmountsRadioOptions from "../../../../../../../common/StandardAmountOption/StandardAmountsRadioOptions";

/**
 * @typedef {import("../restrictionCardsAmountsTypes").PropsToShareAmongAmountsComponents} PropsToShareAmongAmountsComponents
 */

/**
 *
 * @param {PropsToShareAmongAmountsComponents} props
 * @returns {React.ReactElement}
 */
const ConnectedStandardAmountsRadioOptions = ({
    amountsToDisplayWithRestriction,
    buttonStyle,
    existingMultiRestrictionFieldValue,
    multiRestrictionAmountIdFieldName,
    multiRestrictionFieldName,
    nameOfAmountIdField,
    standardAndOtherAmountRefs,
}) => {
    const dispatch = useDispatch();
    const formikContext = useFormikContext();
    const formSettings = useSelector(selectAllFormSettings);
    const { setFieldValue = () => {} } = formikContext;

    const standardAmountsToDisplay = getStandardAmountsData(
        amountsToDisplayWithRestriction,
    );

    /**
     *
     * @param {React.BaseSyntheticEvent} e
     * @returns {undefined}
     * @description Never used in the StandardAmountsRadioOptions component.
     * Instead it is renamed and passed into the StandardAmountRadioOption
     * component as the handleChange function. When an amount is clicked we
     * only change the value of an amount id as the click handler at the
     * restriction card level is responsible for checking and unchecking the
     * checkbox.
     * Because the multi-restriction implementation of amounts is different
     * to the regular implementation of amounts we need to take the following
     * into account when generating the otherTabAmountFieldToModify value.
     * 1. Pass in a restriction specific list of amounts.
     * 2. Generate a name based on whether or not the donation is one time or
     * ongoing as the name of the field is actually multi-restriction related.
     * 3. Pass in the object that exists in the multi-restriction field
     * directly as that is what contains the one time & recurring field values.
     */
    const handleStandardAmountChange = (e) => {
        const { currentTarget = {} } = e;
        const { value = "" } = currentTarget;
        const otherTabAmountFieldToModify =
            determineCorrespondingEmptyValueToUpdate({
                activeAmounts: standardAmountsToDisplay,
                name: nameOfAmountIdField,
                value,
                values: existingMultiRestrictionFieldValue,
            });
        const nameOfFieldInOtherTagToModify =
            otherTabAmountFieldToModify.name || "";
        const hasOtherAmountTabToModify = !!nameOfFieldInOtherTagToModify;

        let newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            [nameOfAmountIdField]: Number(value),
        };

        // Update the amount in the other tab if the situation dictates it
        if (hasOtherAmountTabToModify) {
            newMultiRestrictionFieldValue = {
                ...newMultiRestrictionFieldValue,
                [nameOfFieldInOtherTagToModify]: Number(value),
            };
        }

        // Update Formik and then Redux after the values for the fields on
        // both tabs have been generated
        setFieldValue(multiRestrictionFieldName, newMultiRestrictionFieldValue);
        dispatch(
            updateGiftDetails({
                [multiRestrictionFieldName]: newMultiRestrictionFieldValue,
            }),
        );
    };

    // Reassign and create variables as needed
    const standardAmounts = getStandardAmounts({
        buttonStyle,
        existingMultiRestrictionFieldValue,
        formSettings,
        multiRestrictionAmountIdFieldName,
        nameOfAmountIdField,
        standardAmountsToDisplay,
        standardAndOtherAmountRefs,
    });

    const standardAmountsRadioOptionsProps = {
        handleStandardAmountChange,
        standardAmounts,
    };

    return (
        <StandardAmountsRadioOptions {...standardAmountsRadioOptionsProps} />
    );
};

export default ConnectedStandardAmountsRadioOptions;
