import React from "react";
import PropTypes from "prop-types";
import {
    ConnectedDonatedGiftFrequencyTabs,
    ConnectedDonatedGiftTabOneTime,
    ConnectedDonatedGiftTabRecurring,
} from "../../Pages/GiftPage/DonatedGiftSection";
import ConnectedStandardRestrictionsContainer from "../../Pages/GiftPage/DonatedGiftSection/StandardRestrictionsContainer";
import ConnectedGivingPlans from "../../Pages/GiftPage/DonatedGiftSection/GivingPlans";
import { ConnectedDonatedGiftInvoiceTabs } from "../../Pages/GiftPage/DonatedGiftSection/Invoices";

const DonatedGiftControl = ({
    hasActiveFulfillment,
    showDonationFrequencyTabs,
    controlId,
    shouldDisplayGivingPlansContent,
    shouldDisplaySustainingGiftsContent,
    shouldDisplayOneTimeGiftContent,
    shouldDisplayRestrictions,
}) => (
    // eslint-disable-next-line tailwindcss/classnames-order
    <div className="qg-vendor-gift-step-control gift-control grid -padding-top--15">
        {/* Tab Section */}
        {showDonationFrequencyTabs && (
            <div className="col col--12">
                <ConnectedDonatedGiftFrequencyTabs controlId={controlId} />
            </div>
        )}
        {/* Shows the one time or ongoing tab content, or invoice tabs */}
        {hasActiveFulfillment && (
            <ConnectedDonatedGiftInvoiceTabs controlId={controlId} />
        )}
        {shouldDisplayGivingPlansContent && (
            <ConnectedGivingPlans controlId={controlId} />
        )}
        {shouldDisplaySustainingGiftsContent && (
            <ConnectedDonatedGiftTabRecurring controlId={controlId} />
        )}
        {shouldDisplayOneTimeGiftContent && (
            <ConnectedDonatedGiftTabOneTime controlId={controlId} />
        )}
        {shouldDisplayRestrictions && (
            <ConnectedStandardRestrictionsContainer controlId={controlId} />
        )}
    </div>
);

DonatedGiftControl.propTypes = {
    controlId: PropTypes.number.isRequired,
    hasActiveFulfillment: PropTypes.bool.isRequired,
    showDonationFrequencyTabs: PropTypes.bool.isRequired,
    shouldDisplayGivingPlansContent: PropTypes.bool.isRequired,
    shouldDisplaySustainingGiftsContent: PropTypes.bool.isRequired,
    shouldDisplayOneTimeGiftContent: PropTypes.bool.isRequired,
    shouldDisplayRestrictions: PropTypes.bool.isRequired,
};

export default DonatedGiftControl;
