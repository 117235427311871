import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
    translateTransaction,
    AnonymityCallout,
    PrivacyOptionsCallout,
    getAnonymousCallout,
    getPrivacyCalloutMessage,
} from "@qgiv/core-donor";
import { constants } from "@qgiv/core-js";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectReceiptTransaction } from "../../../../redux/slices/receiptsSlice";
import { selectSpecificEventSetting } from "../../../../redux/slices/eventSettingsSlice";

const ConnectedAnonymityCallout = ({ control }) => {
    const {
        ENUMS: { FieldType, ProductType },
    } = constants;
    const formSettings = useSelector((state) => selectAllFormSettings(state));
    const transaction = useSelector(selectReceiptTransaction);
    const displayAnonymousDonorInformation = useSelector((state) =>
        selectSpecificEventSetting(state, "displayAnonymousDonorInformation"),
    );
    const { type = ProductType.QGIV } = formSettings;
    const { options = {} } = control;

    // Convert any of the data that is on the transaction object that is a
    // number to a string before it is displayed on the receipt page
    const transactionWithDataAsStrings = translateTransaction(transaction);
    const { donations } = transactionWithDataAsStrings;

    const selectedRecipient =
        donations[0]?.entityName && donations[0]?.entityType
            ? {
                  title: donations[0].entityName,
                  entityType: Number(donations[0].entityType),
                  captains: [],
              }
            : {};

    let shouldShowAmount = false;
    let shouldShowName = false;
    if (donations[0]) {
        shouldShowName = donations[0]?.showName === "1";
        shouldShowAmount = donations[0]?.showAmount === "1";
    }

    const anonymousCallOut = getAnonymousCallout({
        options,
        shouldShowAmount,
        shouldShowName,
        type,
    });
    const displayAnonymityCallout = !shouldShowName || !shouldShowAmount;

    // Consolidate props into object
    const anonymityCalloutProps = {
        anonymousCallOut,
        displayAnonymityCallout,
    };

    const privacyOptionsAmountMessage = getPrivacyCalloutMessage({
        fieldType: FieldType.SHOW_AMOUNT,
        selectedRecipient,
        displayAnonymousDonorInformation,
    });
    const privacyOptionsNameMessage = getPrivacyCalloutMessage({
        fieldType: FieldType.SHOW_NAME,
        selectedRecipient,
        displayAnonymousDonorInformation,
    });

    return (
        <>
            <AnonymityCallout {...anonymityCalloutProps} />
            {displayAnonymousDonorInformation ? (
                <>
                    {!shouldShowName ? (
                        <PrivacyOptionsCallout
                            message={privacyOptionsNameMessage}
                        />
                    ) : null}
                    {!shouldShowAmount ? (
                        <PrivacyOptionsCallout
                            message={privacyOptionsAmountMessage}
                        />
                    ) : null}
                </>
            ) : null}
        </>
    );
};

ConnectedAnonymityCallout.propTypes = {
    control: PropTypes.shape({
        options: PropTypes.shape({
            hideNameCallout: PropTypes.string,
            hideAmountCallout: PropTypes.string,
            hideNameAndAmountCallout: PropTypes.string,
        }),
    }).isRequired,
};

export default ConnectedAnonymityCallout;
