import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import { getDisplaySubmit } from "@qgiv/core-donor";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectGiftDetails } from "../../../../redux/slices/donationDetailsSlice";
import { selectSpecificFormSetting } from "../../../../redux/slices/formSettingsSlice";
import { selectRecurringFrequencyByValue } from "../../../../redux/slices/donationSettingsSlice";
import {
    selectIsCms,
    selectIsDisplayTypeModal,
} from "../../../../redux/slices/configSettingsSlice";
import Submit from "../../../common/Submit";
import SubmitButtonLabelWithContent from "../../../common/Submit/SubmitButtonLabelWithContent";
import { setCurrentPagePreviousPage } from "../../../../redux/slices/appSettingsSlice";
import { selectIsTextToDonate } from "../../../../redux/slices/smsDataSlice";

const ConnectedSubmit = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
        },
    } = constants;

    const currency = useSelector((state) =>
        selectSpecificFormSetting(state, "currency"),
    );
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );
    const { total, hasSelectedRecurringDonation, Recurring_Frequency } =
        useSelector(selectGiftDetails);
    const selectedFrequency = useSelector((state) =>
        selectRecurringFrequencyByValue(state, Recurring_Frequency),
    );
    const isCms = useSelector(selectIsCms);

    const frequencyUnitLabel = selectedFrequency?.labelPerAmount;

    const { options = {} } = control;
    const {
        backLinkLabel = "Back",
        submitButtonLabel = "Give %AMOUNTWITHFREQUENCY%",
        textToDonateSubmitLabel = "Give %AMOUNTWITHFREQUENCY%",
    } = options;

    const isDisplayTypeModal = useSelector(selectIsDisplayTypeModal);
    const isTextToDonate = useSelector(selectIsTextToDonate);

    const getButtonLabel = () => {
        if (isTextToDonate) {
            return textToDonateSubmitLabel;
        }
        return submitButtonLabel;
    };

    const buttonLabel = getButtonLabel();

    const submitButtonLabelWithContentProps = {
        buttonLabel,
        currency,
        frequencyUnitLabel,
        hasSelectedRecurringDonation,
        total,
    };

    const backButtonLabel = backLinkLabel;
    // this might change when we make payment page movable in page order.
    const nextButtonLabel = (
        <SubmitButtonLabelWithContent {...submitButtonLabelWithContentProps} />
    );

    const { values, isSubmitting } = useFormikContext();
    const dispatch = useDispatch();
    const handleBackButtonClick = () => {
        dispatch(setCurrentPagePreviousPage());
    };

    // Display the submit button if the user is in CMS or the payment methods
    // other than Apple Pay are visible
    const displaySubmit = getDisplaySubmit({
        isCms,
        values,
    });

    const submitProps = {
        backButtonLabel,
        disabled: false,
        displayBackButton: true,
        displaySubmit,
        handleBackButtonClick,
        isPreview: false,
        isSubmitting,
        nextButtonLabel,
        scopeToIframe: !isDisplayTypeModal,
        showBackground: !!isSubmitting,
        submitTooltipMessage: "",
        total,
    };

    return <Submit {...submitProps} />;
};

ConnectedSubmit.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedSubmit;
