import { constants } from "@qgiv/core-js";
import { getPaymentTypeInitialValue } from "../../utility";

/**
 * @public
 * @function getStandardPaymentMethodProps
 * @description Generates an object that contains all of the props required by
 * the StandardPaymentMethods component
 * @param {object} options Object that contains the data required to generate
 * the props required by StandardPaymentMethods
 * @returns {object} The props that StandardPaymentMethods requires
 */
const getStandardPaymentMethodProps = (options) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    // paymentData is passed in as a separate object as the information
    // contained by this object exists directly on the formSettings object in
    // P2P, but is housed within a nested paymentData object in events
    const {
        acceptedPayments = {},
        control = {},
        formikContext = {},
        formSettings = {},
        hasToggledPaymentMethodView = false,
        registrationDetails = {},
        hasActiveFulfillment = false,
        paymentData = {},
    } = options;
    const {
        options: {
            invoiceHeading = "Request An Invoice",
            invoiceNotificationMessage = "Once you submit your gift, an invoice will be sent to the following email to be paid later:",
            payLaterLink = "Request an invoice to pay later",
            payNowLink = "Pay Now",
        },
    } = control;
    const {
        initialValues,
        setFieldValue,
        values: { Payment_Type },
    } = formikContext;
    const { enableInvoice = false } = formSettings;
    const {
        enableCCPayments = false,
        enableAchPayments = false,
        enablePaypalPayments = false,
    } = paymentData;
    const { Email = "" } = registrationDetails;
    const fieldName = "Payment_Type";
    const PAYMENT_TAB = {
        CREDIT: "credit-tab",
        ECHECK: "echeck-tab",
    };
    // Create a counter that can be used to determine if we need to render the
    // payment tabs and if we should be focusing on a payment tab or a single
    // payment method when toggling between the UIs for the new payment methods
    // and the saved payment methods.
    let numberOfStandardPaymentMethods = 0;

    if (enableAchPayments) {
        numberOfStandardPaymentMethods++;
    }

    if (enableCCPayments) {
        numberOfStandardPaymentMethods++;
    }

    const handleTabClick = (tab) => {
        switch (tab) {
            case PAYMENT_TAB.CREDIT: {
                setFieldValue(fieldName, PaymentType.CREDITCARD);
                break;
            }
            case PAYMENT_TAB.ECHECK: {
                setFieldValue(fieldName, PaymentType.ECHECK);
                break;
            }
            default: {
                setFieldValue(fieldName, "");
                break;
            }
        }
    };

    // Invoice text props
    const invoiceTextProps = {
        email: Email,
        invoiceHeading,
        invoiceNotificationMessage,
    };

    // -------------------------------------------------------------------------
    // NOTE: Loading Data from sessionStorage on event with a Promise transaction
    // selected would make the initialValue reference in formik be PaymentType.PROMISE
    // so after reloading the form, clicking the invoice text button would not do anything
    // -------------------------------------------------------------------------
    const initialPaymentType = initialValues.Payment_Type;

    const getRevertedPaymentType = () => {
        if (initialPaymentType === PaymentType.PROMISE) {
            return getPaymentTypeInitialValue(
                {
                    enableAchPayments,
                    enableCCPayments,
                    enablePaypalPayments,
                },
                // we can ignore donor account since this interaction takes place
                // on the newPaymentMethods view
                {
                    paymentInfo: [],
                },
            );
        }
        // if initial payment type is stored but they are on the invoice view, we need
        // to set the payment type to new payment as that is the view that will be displayed.
        if (
            initialPaymentType === PaymentType.STORED &&
            Payment_Type === PaymentType.PROMISE
        ) {
            return getPaymentTypeInitialValue(
                {
                    enableAchPayments,
                    enableCCPayments,
                    enablePaypalPayments,
                },
                // we can ignore donor account since this interaction takes place
                // on the newPaymentMethods view
                {
                    paymentInfo: [],
                },
            );
        }
        return initialPaymentType;
    };

    // Invoice button props
    const handleClick = () => {
        if (Payment_Type === PaymentType.PROMISE) {
            setFieldValue("Payment_Type", getRevertedPaymentType());
        } else {
            setFieldValue("Payment_Type", PaymentType.PROMISE);
        }
    };

    const text =
        Payment_Type === PaymentType.PROMISE ? payNowLink : payLaterLink;

    const invoiceButtonProps = {
        handleClick,
        text,
    };

    // Instant payment method props
    const instantPaymentMethodProps = {
        acceptedPayments,
        handleTabClick,
        hasToggledPaymentMethodView,
        numberOfStandardPaymentMethods,
    };

    // Invoice payment toggle props
    const instantPaymentToggleProps = {
        enableInvoice,
        hasActiveFulfillment,
        instantPaymentMethodProps,
        invoiceButtonProps,
        invoiceTextProps,
        Payment_Type,
    };

    // Standard payment method props
    const standardPaymentMethodProps = {
        numberOfStandardPaymentMethods,
        instantPaymentToggleProps,
    };

    return standardPaymentMethodProps;
};

export default getStandardPaymentMethodProps;
