import React from "react";
import PropTypes from "prop-types";
import Tabs from "../Tabs";

const DetailsStepCompanyTabs = ({
    handleTabClick,
    individualTabLabel = "I'm an individual",
    companyTabLabel = "I'm a company",
    isCompanyDonation,
}) => (
    <Tabs handleClick={handleTabClick}>
        <section
            id="individual-tab"
            active={!isCompanyDonation}
            label={individualTabLabel}
        ></section>
        <section
            id="company-tab"
            active={isCompanyDonation}
            label={companyTabLabel}
        ></section>
    </Tabs>
);

DetailsStepCompanyTabs.propTypes = {
    handleTabClick: PropTypes.func.isRequired,
    individualTabLabel: PropTypes.string,
    companyTabLabel: PropTypes.string,
    isCompanyDonation: PropTypes.bool.isRequired,
};

export default DetailsStepCompanyTabs;
