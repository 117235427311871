import moment from "moment";
import { constants, isValidDate } from "@qgiv/core-js";
import {
    getComparisonFieldPath,
    getCurrentFieldPathOptions,
} from "../eventHelpers";

/**
 * @typedef {import("../../types/fieldTypes").FieldType} FieldType
 * @typedef {import("../../types/fieldTypes").FieldGroupType} FieldGroupType
 * @typedef {import("../../types/fieldTypes").ConditionType} ConditionType
 * @typedef {import('moment').Moment} Moment
 */

const { ENUMS } = constants;
const { Operator, DonationType, FieldType, EntityType } = ENUMS;

/**
 * @function getFieldIdToFindComparisonField
 * @description helper function to get the field id for the comparison field, event registration custom fields makes this difficult because
 * the comparisonEntityFieldPath includes package and ticket information, where as regular custom fields will just contain the field id.
 * @param {object} params function parameters
 * @param {string} params.comparisonEntityFieldPath string for identifying comparison field
 * @param {boolean} params.isPackageOrTicketLevelEventField boolean for if field is package or ticket level event custom field
 * @returns {string} fieldIdToFindComparisonField string which will point to field in allFieldsAndGroups
 */
export const getFieldIdToFindComparisonField = ({
    comparisonEntityFieldPath = "",
    isPackageOrTicketLevelEventField = false,
}) => {
    if (isPackageOrTicketLevelEventField) {
        const comparisonFieldPathOptions = getCurrentFieldPathOptions(
            comparisonEntityFieldPath,
        );
        const { currentFieldId = "" } = comparisonFieldPathOptions;
        return currentFieldId;
    }
    return comparisonEntityFieldPath;
};

/**
 * @function getComparisonFieldOrGroup
 * @description Finds and returns the field or group from allFieldsAndGroups that matches the given fieldIdToFindComparisonField.
 * @param {object} params - The function parameters.
 * @param {Array.<FieldType|FieldGroupType>} params.allFieldsAndGroups - An array of all fields and groups from fields slice selector.
 * @param {string|number} params.fieldIdToFindComparisonField - The ID of the field or group to find.
 * @returns {object} The field or group that matches the given ID, or an empty object if no match is found.
 */
export const getComparisonFieldOrGroup = ({
    allFieldsAndGroups = [],
    fieldIdToFindComparisonField = "",
}) => {
    // There will be no matching field or group if:
    // 1. The comparison field is not going to be displayed in the form
    // 2. comparisonEntityId === "0" because the conditional logic is
    // dependent on Donation Amount or Donation Type instead of a condition
    // that is associated with a Custom Field.
    const comparisonFieldOrGroup =
        allFieldsAndGroups.find(
            (field) => `${field.id}` === `${fieldIdToFindComparisonField}`,
        ) || {};

    return comparisonFieldOrGroup;
};

/**
 * @function getHasConditionalLogic
 * @param {FieldType|FieldGroupType} comparisonFieldOrGroup The object that represents the
 * field or group that the current field or group is being compared to.
 * @returns {boolean} Whether or not that field or group has any
 *                    conditional logic
 */
export const getHasConditionalLogic = (comparisonFieldOrGroup) => {
    if (comparisonFieldOrGroup?.conditions) {
        const comparisonCondition =
            comparisonFieldOrGroup.conditions?.[0] || {};
        return Object.keys(comparisonCondition).length > 0;
    }
    return false;
};

/**
 * @function compareNumberValues
 * @param {object} params function parameters
 * @param {number} params.comparisonValue The comparison value number
 * @param {number} params.currentValue The current value number
 * @param {string|number} params.operator The comparison enum
 * @description Compares numbers given an operator enum
 * @returns {boolean} true/false
 */
export const compareNumberValues = ({
    comparisonValue,
    currentValue,
    operator,
}) => {
    switch (Number(operator)) {
        case Operator.EQUALTO: {
            return currentValue === comparisonValue;
        }
        case Operator.GREATERTHAN: {
            return currentValue > comparisonValue;
        }
        case Operator.GREATERTHANOREQUALTO: {
            return currentValue >= comparisonValue;
        }
        case Operator.LESSTHAN: {
            return currentValue < comparisonValue;
        }
        case Operator.LESSTHANOREQUALTO: {
            return currentValue <= comparisonValue;
        }
        default: {
            return false;
        }
    }
};

/**
 * @function compareDateValues
 * @param {object} params function parameters
 * @param {Moment} params.conditionDateValue The comparison value number
 * @param {Moment} params.currentDateValue The current value number
 * @param {string|number} params.operator The comparison enum
 * @description Compares numbers given an operator enum
 * @returns {boolean} true/false
 */
export const compareDateValues = ({
    conditionDateValue,
    currentDateValue,
    operator,
}) => {
    switch (Number(operator)) {
        case Operator.EQUALTO: {
            // Check if current date value is equal to condition date value
            return currentDateValue.isSame(conditionDateValue);
        }
        case Operator.GREATERTHAN: {
            // Check if current date value is after condition date value
            return currentDateValue.isAfter(conditionDateValue);
        }
        case Operator.LESSTHAN: {
            // Check if current date value is before condition date value
            return currentDateValue.isBefore(conditionDateValue);
        }
        default: {
            return true;
        }
    }
};

/**
 * @function getComparisonTypeIsDonationComparison
 * @description Determines if the comparison entity type is related to a donation.
 * @param {string|number} comparisonEntityType - The type of the comparison entity.
 * @returns {boolean} True if the comparison entity type is DONATION_AMOUNT or DONATION_TYPE, otherwise false.
 */
export const getComparisonTypeIsDonationComparison = (comparisonEntityType) => {
    const comparisonEntityTypeNumber = Number(comparisonEntityType);
    const isDonationComparison =
        comparisonEntityTypeNumber === EntityType.DONATION_AMOUNT ||
        comparisonEntityTypeNumber === EntityType.DONATION_TYPE;
    return isDonationComparison;
};

/**
 * @function getValueExists
 * @description Checks if a value exists for a given key in the values object.
 * @param {object} params - The parameters object.
 * @param {string|number} params.key - The key to check in the values object.
 * @param {object} params.values - The values object containing key-value pairs.
 * @returns {boolean} True if the value exists and is not undefined, otherwise false.
 */
export const getValueExists = ({ key, values }) => {
    const valueExists =
        Object.keys(values)
            .map((valueKey) => `${valueKey}`)
            .includes(String(key)) && values[key] !== undefined;
    return valueExists;
};

/**
 * @function getCurrentValue
 * @description Retrieves the current value for a given key from the values object.
 * @param {object} params - The parameters object.
 * @param {string|number} params.key - The key to retrieve the value for.
 * @param {object} params.values - The values object containing key-value pairs.
 * @returns {*} The current value associated with the given key, or undefined if the key does not exist.
 */
export const getCurrentValue = ({ key, values }) => {
    const currentValue = values?.[key];
    return currentValue;
};

/**
 * @function getComparisonValueHasLengthGreaterThanZero
 * @param {string|number} currentValueFromValues value of comparison field from values object
 * @returns {boolean} true if values length is greater than zero, false if not.
 */
export const getComparisonValueHasLengthGreaterThanZero = (
    currentValueFromValues,
) => `${currentValueFromValues}`.length !== 0;

/**
 * @function getComparisonValueIsTrueForCheckboxField
 * @description Checks if the current value from the values object is true for a checkbox field.
 * @param {boolean} currentValueFromValues - The current value of the comparison field from the values object.
 * @returns {boolean} True if the current value is true, otherwise false.
 */
export const getComparisonValueIsTrueForCheckboxField = (
    currentValueFromValues,
) => currentValueFromValues === true;

/**
 * @function getComparisonValueEqualsValueFromCondition
 * @description Checks if the current value from the values object equals the value from the condition.
 * @param {object} params - The parameters object.
 * @param {string|number} params.currentValueFromValues - The current value of the comparison field from the values object.
 * @param {string|number} params.valueFromConditional - The value from the condition to compare against.
 * @returns {boolean} True if the current value equals the value from the condition, otherwise false.
 */
export const getComparisonValueEqualsValueFromCondition = ({
    currentValueFromValues,
    valueFromConditional,
}) => currentValueFromValues === valueFromConditional;

/**
 * @function getComparisonValueArrayIncludesValueFromCondition
 * @description Checks if the current value array from the values object includes the value from the condition.
 * @param {object} params - The parameters object.
 * @param {Array.<string|number>} params.currentValueFromValues - The current value array of the comparison field from the values object.
 * @param {string|number} params.valueFromConditional - The value from the condition to check for inclusion.
 * @returns {boolean} True if the current value array includes the value from the condition, otherwise false.
 */
export const getComparisonValueArrayIncludesValueFromCondition = ({
    currentValueFromValues,
    valueFromConditional,
}) =>
    Array.isArray(currentValueFromValues) &&
    currentValueFromValues.indexOf(valueFromConditional) !== -1;

/**
 * @function getComparisonValueWithDateConditional
 * @description Compares the current date value from the values object with the date value from the condition using the specified operator.
 * @param {object} params - The parameters object.
 * @param {string} params.currentValueFromValues - The current date value of the comparison field from the values object.
 * @param {string} params.operator - The operator to use for comparison (e.g., '>', '<', '==').
 * @param {string|number} params.valueFromConditional - The date value from the condition to compare against.
 * @returns {boolean} True if the comparison is valid based on the operator, otherwise false.
 */
export const getComparisonValueWithDateConditional = ({
    currentValueFromValues,
    operator,
    valueFromConditional,
}) => {
    const isValid = isValidDate(currentValueFromValues);
    if (!isValid) {
        return false;
    }
    const currentDateValue = moment(currentValueFromValues);
    const conditionDateValue = moment(valueFromConditional);
    return compareDateValues({
        conditionDateValue,
        currentDateValue,
        operator,
    });
};

/**
 * @function getComparisonValueWithNumberConditional
 * @description Compares the current numeric value from the values object with the numeric value from the condition using the specified operator.
 * @param {object} params - The parameters object.
 * @param {string|number} params.currentValueFromValues - The current numeric value of the comparison field from the values object.
 * @param {string} params.operator - The operator to use for comparison (e.g., '>', '<', '==').
 * @param {string|number} params.valueFromConditional - The numeric value from the condition to compare against.
 * @returns {boolean} True if the comparison is valid based on the operator, otherwise false.
 */
export const getComparisonValueWithNumberConditional = ({
    currentValueFromValues,
    operator,
    valueFromConditional,
}) => {
    const currentNumberValue = Number(currentValueFromValues);
    const conditionNumberValue = Number(valueFromConditional);
    return compareNumberValues({
        currentValue: currentNumberValue,
        comparisonValue: conditionNumberValue,
        operator,
    });
};

/**
 * @function getDonationAmountComparison
 * @description Compares the current donation amount with a condition donation amount based on the specified operator.
 * @param {object} params - The function parameters.
 * @param {boolean} params.usesReduxDonationAmountValue - Flag indicating whether to use the Redux donation amount value.
 * @param {string|number} params.operator - The comparison operator.
 * @param {object} params.options - Additional options, including the Redux donation amount value.
 * @param {string|number} params.valueFromConditional - The condition donation amount value to compare against.
 * @param {object} params.values - The values object containing key-value pairs.
 * @returns {boolean} True if the comparison is valid based on the operator, otherwise false.
 */
export const getDonationAmountComparison = ({
    usesReduxDonationAmountValue,
    operator,
    options,
    valueFromConditional,
    values,
}) => {
    const currentDonationAmount = usesReduxDonationAmountValue
        ? options.donationAmountFromRedux
        : Number(getCurrentValue({ key: "Donation_Amount", values }));
    const conditionDonationAmount = Number(valueFromConditional);
    return compareNumberValues({
        currentValue: currentDonationAmount,
        comparisonValue: conditionDonationAmount,
        operator,
    });
};

/**
 * @function getDonationTypeComparison
 * @description Compares the current donation type (recurring or one-time) with a condition donation type.
 * @param {object} params - The function parameters.
 * @param {object} params.options - Additional options, including the Redux recurring donation value.
 * @param {boolean} params.usesReduxRecurringValue - Flag indicating whether to use the Redux recurring donation value.
 * @param {string|number} params.valueFromConditional - The condition donation type value to compare against.
 * @param {object} params.values - The values object containing key-value pairs.
 * @returns {boolean} True if the current donation type matches the condition donation type, otherwise false.
 */
export const getDonationTypeComparison = ({
    options,
    usesReduxRecurringValue,
    valueFromConditional,
    values,
}) => {
    const isRecurring = usesReduxRecurringValue
        ? options.hasSelectedRecurringDonation
        : getCurrentValue({ key: "Recurring_Donation", values });
    const currentDonationType = isRecurring
        ? DonationType.RECURRING
        : DonationType.ONE_TIME;
    const conditionDonationType = Number(valueFromConditional);
    // Check if current donation type matches condition donation type
    return currentDonationType === conditionDonationType;
};

/**
 * @function getFieldOrGroupIsVisible
 * @description Determines if a field or group should be visible based on various comparison conditions.
 * @param {object} params - The function parameters.
 * @param {string} params.comparisonEntityFieldPath - The path to the comparison entity field.
 * @param {string|number} params.comparisonEntityType - The type of the comparison entity from EntityType Enum.
 * @param {string} params.operator - The operator to use for comparison (e.g., '>', '<', '==') stored on the condition
 * @param {object} params.options - Additional options for the comparison.
 * @param {boolean} params.usesReduxDonationAmountValue - Flag indicating whether to use the Redux donation amount value.
 * @param {boolean} params.usesReduxRecurringValue - Flag indicating whether to use the Redux recurring donation value.
 * @param {string|number} params.valueFromConditional - The value from the stored condition
 * @param {object} params.values - The values object containing key-value pairs.
 * @returns {boolean} True if the field or group should be visible based on the comparison conditions, otherwise false.
 */
export const getFieldOrGroupIsVisible = ({
    comparisonEntityFieldPath,
    comparisonEntityType,
    operator,
    options,
    usesReduxDonationAmountValue,
    usesReduxRecurringValue,
    valueFromConditional,
    values,
}) => {
    // types for fields all have valueExists boolean and currentValue from values
    const valueExistsWithinValues = getValueExists({
        key: comparisonEntityFieldPath,
        values,
    });
    const currentValueFromValues = getCurrentValue({
        key: comparisonEntityFieldPath,
        values,
    });

    // only field comparisons care if values exists in values
    // and if they don't it always returns false
    const comparisonTypeIsDonationComparison =
        getComparisonTypeIsDonationComparison(comparisonEntityType);

    if (!comparisonTypeIsDonationComparison && !valueExistsWithinValues) {
        return false;
    }

    switch (Number(comparisonEntityType)) {
        case FieldType.SHORT_TEXT:
        case FieldType.PARAGRAPH_TEXT:
        case FieldType.PHONE_NUMBER:
        case FieldType.EMAIL_ADDRESS:
        case FieldType.WEBSITE:
        case FieldType.HIDDEN_FIELD: {
            return getComparisonValueHasLengthGreaterThanZero(
                currentValueFromValues,
            );
        }
        case FieldType.CHECKBOX: {
            return getComparisonValueIsTrueForCheckboxField(
                currentValueFromValues,
            );
        }
        case FieldType.DROPDOWN:
        case FieldType.RADIO_BUTTON: {
            return getComparisonValueEqualsValueFromCondition({
                currentValueFromValues,
                valueFromConditional,
            });
        }
        case FieldType.MULTIPLE_SELECTION: {
            return getComparisonValueArrayIncludesValueFromCondition({
                currentValueFromValues,
                valueFromConditional,
            });
        }
        case FieldType.DATE: {
            return getComparisonValueWithDateConditional({
                currentValueFromValues,
                operator,
                valueFromConditional,
            });
        }
        case FieldType.NUMBER: {
            return getComparisonValueWithNumberConditional({
                currentValueFromValues,
                operator,
                valueFromConditional,
            });
        }
        case EntityType.DONATION_AMOUNT: {
            return getDonationAmountComparison({
                usesReduxDonationAmountValue,
                operator,
                options,
                valueFromConditional,
                values,
            });
        }
        case EntityType.DONATION_TYPE: {
            return getDonationTypeComparison({
                options,
                usesReduxRecurringValue,
                valueFromConditional,
                values,
            });
        }
        default: {
            return true;
        }
    }
};

/**
 * @function recursivelyCheckComparisonFieldVisibility
 * @description Recursively checks the visibility of a comparison field based on its conditions and various options.
 * @param {object} params - The function parameters.
 * @param {Array} params.allFieldsAndGroups - An array of all fields and groups.
 * @param {string} params.comparisonEntityFieldPath - The path to the comparison entity field.
 * @param {object} params.comparisonFieldOrGroup - The comparison field or group object.
 * @param {Function} params.getFieldIsVisibleBasedOnConditionalsRecursive - A recursive function to check field visibility based on conditionals.
 * @param {boolean} params.isPackageOrTicketLevelEventField - Flag indicating if the field is a package or ticket level event field.
 * @param {object} params.options - Additional options for the comparison.
 * @param {boolean} params.usesReduxDonationAmountValue - Flag indicating whether to use the Redux donation amount value.
 * @param {boolean} params.usesReduxRecurringValue - Flag indicating whether to use the Redux recurring donation value.
 * @param {object} params.values - The values object containing key-value pairs.
 * @returns {boolean} True if the comparison field is visible based on the conditions, otherwise false.
 */
export const recursivelyCheckComparisonFieldVisibility = ({
    allFieldsAndGroups,
    comparisonEntityFieldPath,
    comparisonFieldOrGroup,
    getFieldIsVisibleBasedOnConditionalsRecursive,
    isPackageOrTicketLevelEventField,
    options,
    usesReduxDonationAmountValue,
    usesReduxRecurringValue,
    values,
}) => {
    const { conditions = [] } = comparisonFieldOrGroup;
    const comparisonFieldCondition = conditions[0];

    // We need to pass the donation amount value if it was being passed
    // to this function as a part of options so it can be compared with the
    // new comparison field.

    const _options = {};

    if (usesReduxDonationAmountValue) {
        _options.donationAmountFromRedux = options.donationAmountFromRedux;
        _options.usesReduxDonationAmountValue = usesReduxDonationAmountValue;
    }

    if (usesReduxRecurringValue) {
        _options.hasSelectedRecurringDonation =
            options.hasSelectedRecurringDonation;
        _options.usesReduxRecurringValue = usesReduxRecurringValue;
    }

    // Need to grab from the condition the id of the comparison field
    // and then run it through that generate fieldPath function, we can reference
    // comparisonEntityFieldPath to generate the new path.
    if (isPackageOrTicketLevelEventField) {
        const newComparisonId = comparisonFieldCondition.comparisonEntityId;
        const comparisonField = allFieldsAndGroups.find(
            (field) => field.id === newComparisonId,
        );
        const newComparisonFieldPath = getComparisonFieldPath(
            comparisonEntityFieldPath,
            comparisonField,
        );
        _options.conditionalFieldPath = newComparisonFieldPath;
    }

    return getFieldIsVisibleBasedOnConditionalsRecursive({
        condition: comparisonFieldCondition,
        allFieldsAndGroups,
        options: _options,
        values,
    });
};

/**
 * @typedef GetOptionsRelatedVariablesReturnType
 * @property {string|number} comparisonEntityFieldPath - The path to the comparison entity field.
 * @property {boolean} isPackageOrTicketLevelEventField - Flag indicating if the field is a package or ticket level event field.
 * @property {boolean} usesReduxDonationAmountValue - Flag indicating if the Redux donation amount value is used.
 * @property {boolean} usesReduxRecurringValue - Flag indicating if the Redux recurring value is used.
 */

/**
 * @function getOptionsRelatedVariables
 * @description Extracts and returns variables related to options for comparison conditions.
 * @param {object} params - The function parameters.
 * @param {string|number} params.comparisonEntityId - The ID of the comparison entity.
 * @param {object} params.options - The options object containing various flags and paths.
 * @returns {GetOptionsRelatedVariablesReturnType} An object containing the comparison entity field path, a flag indicating if it is a package or ticket level event field, and flags for using Redux values.
 */
export const getOptionsRelatedVariables = ({ comparisonEntityId, options }) => {
    const usesFieldPathName = !!options?.conditionalFieldPath;
    const usesReduxDonationAmountValue =
        !!options?.usesReduxDonationAmountValue;
    const usesReduxRecurringValue = !!options?.usesReduxRecurringValue;
    const isPackageOrTicketLevelEventField =
        usesFieldPathName && !!options?.conditionalFieldPath?.includes("-");

    const comparisonEntityFieldPath = usesFieldPathName
        ? options.conditionalFieldPath
        : comparisonEntityId;

    return {
        comparisonEntityFieldPath,
        isPackageOrTicketLevelEventField,
        usesReduxDonationAmountValue,
        usesReduxRecurringValue,
    };
};

// -------------------------------------------------------------------------
// NOTE: The only product currently using these new sets of functions for conditional
// logic is donationForm. To upgrade to this version, each app will have to:
// 1. Replace `isVisible` function calls with `getFieldIsVisibleBasedOnConditionals`
// 2. Pass to WidgetField and WidgetFieldGroup via Connected components:
//    1. useNewConditionalLogicFunctions prop as true
//    2. conditionalLogicOptions options for getFieldIsVisibleBasedOnConditionals.
//      These options currently contain booleans for using Redux values instead of formik values
//      and those values (donationAmount and hasSelectedRecurringDonation). This will
//      also be where conditionalFieldPath will be passed for event custom fields.
// These functions are built to be able to accept the weakly and strongly typed versions of
// field and condition data, but further testing will be needed to ensure weakly typed data
// performs as expected.
// -------------------------------------------------------------------------

/**
 * @function getFieldIsVisibleBasedOnConditionals
 * @description Determines if a field is visible based on its conditional logic and various options.
 * @param {object} params - The function parameters.
 * @param {object} params.condition - The condition object containing comparison details.
 * @param {Array.<FieldType|FieldGroupType>} params.allFieldsAndGroups - An array of all fields and groups.
 * @param {object} [params.options] - Additional options for the comparison.
 * @param {object} [params.values] - The values object containing key-value pairs.
 * @returns {boolean} True if the field is visible based on the conditional logic, otherwise false.
 */
export const getFieldIsVisibleBasedOnConditionals = ({
    condition,
    allFieldsAndGroups,
    options = {},
    values = {},
}) => {
    const { comparisonEntityId, comparisonEntityType, operator, value } =
        condition;

    const valueFromConditional = value;

    // Variables to check if settings exists to override original conditional logic
    const {
        comparisonEntityFieldPath,
        isPackageOrTicketLevelEventField,
        usesReduxDonationAmountValue,
        usesReduxRecurringValue,
    } = getOptionsRelatedVariables({ comparisonEntityId, options });

    // -------------------------------------------------------------------------
    // NOTE: Get the field or group that was used for the the comparison and
    //       determine whether or not it has conditional logic. If the field is
    //       visible evaluate whether the field that the field was being
    //       compared to is also visible by extracting the condition out into
    //       its own variable and recursively running the same logic
    // -------------------------------------------------------------------------
    const fieldIdToFindComparisonField = getFieldIdToFindComparisonField({
        // @ts-ignore
        comparisonEntityFieldPath,
        isPackageOrTicketLevelEventField,
    });
    const comparisonFieldOrGroup = getComparisonFieldOrGroup({
        allFieldsAndGroups,
        fieldIdToFindComparisonField,
    });

    const isFieldOrGroupVisible = getFieldOrGroupIsVisible({
        // @ts-ignore
        comparisonEntityFieldPath,
        comparisonEntityType,
        operator,
        options,
        usesReduxDonationAmountValue,
        usesReduxRecurringValue,
        valueFromConditional,
        values,
    });

    const comparisonFieldHasConditionalLogic = getHasConditionalLogic(
        comparisonFieldOrGroup,
    );

    // If the comparison field has conditional logic as well, we need
    // to recursively check if that field is also visible.
    if (isFieldOrGroupVisible && comparisonFieldHasConditionalLogic) {
        return recursivelyCheckComparisonFieldVisibility({
            allFieldsAndGroups,
            // @ts-ignore
            comparisonEntityFieldPath,
            comparisonFieldOrGroup,
            // pass this function along to recursive check
            getFieldIsVisibleBasedOnConditionalsRecursive:
                getFieldIsVisibleBasedOnConditionals,
            isPackageOrTicketLevelEventField,
            options,
            usesReduxDonationAmountValue,
            usesReduxRecurringValue,
            values,
        });
    }

    return isFieldOrGroupVisible;
};
