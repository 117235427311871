import React, {
    useState,
    useEffect,
    useLayoutEffect,
    forwardRef,
    useMemo,
    useRef,
    useCallback,
} from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import cx from "classnames";
import {
    getDisplayValue,
    getIndexOfNewChar,
    getValueWithoutSeparator,
    getCleanUnformattedFrequencyValue,
} from "../../../utility";
import "./CurrencyInput.scss";

const CurrencyInput = forwardRef(
    (
        {
            allowsZeroDollarValue = false,
            alwaysDisplayPlaceholder = false,
            centeredDisplay = false,
            className,
            id,
            injectedUnformattedCurrencyValue = "0",
            isDonationForm = false,
            isMultiRestrictionCustomAmount = false,
            name,
            onBlur = () => {},
            onChange = () => {},
            onFocus = () => {},
            onKeyDown = () => {},
            relatedCheckboxName = "", // set this to function test to be required with isMultiRestrictionCustomAmount is true
            type,
            updateCustomAmount = () => {},
            ...rest
        },
        ref,
    ) => {
        // currencySign can be found from settings
        // There is a task to resolve currency signs across our various products
        // where we will plug in the correct values as props instead of declaring them here.
        const radix = ".";
        const currencySign = "$";
        const separator = ",";
        const zeroDollarValues = ["0", "0.0", "0.00"];

        const numericKeys = `1234567890${radix}`.split("");
        const navigationKeys = [
            "ArrowRight",
            "ArrowLeft",
            "ArrowUp",
            "ArrowDown",
            "Tab",
        ];

        const { setFieldValue, values } = useFormikContext();

        // 1. First set of multi-restriction logic
        // get value of field in formik
        const getStoredValue = useCallback(() => {
            // safe return when field is not set up in formik
            // initial values, like in CMS
            if (Object.keys(values).includes(name)) {
                return isMultiRestrictionCustomAmount
                    ? values[relatedCheckboxName][name]
                    : values[name];
            }
            return "";
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        const storedValue = getStoredValue();

        // unformattedCurrencyValue to use with formik
        const [unformattedCurrencyValue, setUnformattedCurrencyValue] =
            useState(storedValue);
        // formattedCurrencyValue adds separators
        const [formattedCurrencyValue, setFormattedCurrencyValue] =
            useState("");
        const [updatedSelectionStart, setUpdatedSelectionStart] = useState(-1);
        const [displayCurrencySign, setDisplayCurrencySign] = useState(
            storedValue !== "",
        );
        // prevent value setting on component load
        // used only in multi-restrictions
        const [componentLoaded, setComponentLoaded] = useState(false);
        // prevent smashing of keys
        const [allowInput, setAllowInput] = useState(true);
        const [previouslyInjected, setPreviouslyInjected] = useState(false);
        const [placeholderInlineStyles, setPlaceholderInlineStyles] = useState(
            {},
        );

        const inputClassnames = cx(
            className,
            "currency-input",
            !centeredDisplay && "currency-input--left-aligned",
        );

        // left-aligned currency inputs need to have currency sign
        // follow the same font color patterns as regular inputs
        const currencySignClassnames = cx(
            "currency-sign",
            !centeredDisplay && "currency-sign-permanent",
            displayCurrencySign &&
                !centeredDisplay &&
                "currency-sign-permanent--active",
        );

        // Android OS has issues with "." key.
        // see comment inside currencyOnKeyDown function.
        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf("android") > -1;

        // -------------------------------------------------------------------------
        // NOTE: Rest props
        //       These will include props that are placed on the input like
        //       aria-label
        //       aria-required
        //       onClick event handler
        //       tabIndex
        // -------------------------------------------------------------------------
        const remainingProps = { ...rest };
        const remainingKeys = Object.keys(remainingProps);

        if (remainingKeys.includes("ariaRequired")) {
            remainingProps["aria-required"] = remainingProps.ariaRequired;
            delete remainingProps.ariaRequired;
        }

        if (remainingKeys.includes("settings")) {
            delete remainingProps.settings;
        }

        // Inject value into Currency Input
        // only use-case currently is cart abandonment
        useEffect(() => {
            if (
                Number(injectedUnformattedCurrencyValue) > 0 &&
                !previouslyInjected
            ) {
                setPreviouslyInjected(true);
                setUpdatedSelectionStart(0);

                setUnformattedCurrencyValue(injectedUnformattedCurrencyValue);
                // we were playing with timeouts here for blur and focus
                // but I believe the default spacing when there is a formatted value
                // is handling the $ placement correctly without it.
            }
        }, [injectedUnformattedCurrencyValue, ref, previouslyInjected]);

        // Update formattedCurrencyValue when unformattedCurrencyValue changes
        useEffect(() => {
            const updatedFormattedCurrencyValue = getDisplayValue({
                value: unformattedCurrencyValue,
                radix,
                separator,
            });
            // queueMicroTask will ensure that next renders will include updated value
            queueMicrotask(() => {
                setFormattedCurrencyValue(updatedFormattedCurrencyValue);
            });
        }, [unformattedCurrencyValue]);

        // 2. Second set of multi-restriction logic
        // use unformattedCurrencyValue value to update formik value(s)
        // this runs when the component loads which has the side effect of selecting the
        // custom amount for multi-restrictions if we don't prevent that initial value setting
        // Only form appears to have a name of "Custom_Amount" so any conditional logic that
        // is part of a Custom_Amount case is form specific.
        useEffect(() => {
            // Adds whatever is passed in as the name value to Formik
            setFieldValue(name, unformattedCurrencyValue);

            const shouldBeMultiRestrictionCustomAmount =
                name === "Custom_Amount" && isMultiRestrictionCustomAmount;

            if (
                (shouldBeMultiRestrictionCustomAmount || isDonationForm) &&
                componentLoaded
            ) {
                // multi-restriction
                onChange(unformattedCurrencyValue);
            } else if (
                name === "Custom_Amount" &&
                values.Selected_Amount === "custom"
            ) {
                // non multi-restriction
                updateCustomAmount(unformattedCurrencyValue);
            }
            setComponentLoaded(true);
            // including updateCustomAmount in dependency array causes update loop
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [unformattedCurrencyValue, setFieldValue, setComponentLoaded, name]);

        // put cursor where user expects after formattedCurrencyValue update
        useLayoutEffect(() => {
            if (updatedSelectionStart >= 0 && ref?.current) {
                setAllowInput(false);
                // force async behavior with timeless setTimeout
                // This method did not work prior to React 18 because
                // of scoping issues with useRef.
                setTimeout(() => {
                    ref?.current?.focus();
                    ref?.current?.setSelectionRange(
                        updatedSelectionStart,
                        updatedSelectionStart,
                    );
                    setUpdatedSelectionStart(-1);
                    setAllowInput(true);
                });
            }
        }, [formattedCurrencyValue, updatedSelectionStart, ref]);

        // track local value for CustomAmount.js helper functions
        const unformattedCurrencyValueDataProp = useMemo(
            () => ({
                "data-unformatted-currency-value": unformattedCurrencyValue,
            }),
            [unformattedCurrencyValue],
        );

        const currencyOnFocus = (e) => {
            setDisplayCurrencySign(true);

            // Clear out zero dollar values when entering field
            if (
                zeroDollarValues.includes(unformattedCurrencyValue) &&
                !allowsZeroDollarValue
            ) {
                setUnformattedCurrencyValue("");
            }

            if (ref?.current) {
                // set start and end to the same value so tabbing into input will not select text
                ref.current.setSelectionRange(
                    formattedCurrencyValue.length,
                    formattedCurrencyValue.length,
                    "forward",
                );
            }
            onFocus(e);
        };

        const currencyOnBlur = (e) => {
            onBlur(e);

            // if there is not a value, just hide the currency sign display
            if (unformattedCurrencyValue === "") {
                setDisplayCurrencySign(false);
                return;
            }

            // clean up values that include leading zeros and
            // poorly formatted radix, cents data
            const cleanedUnformattedCurrencyValue =
                getCleanUnformattedFrequencyValue(
                    unformattedCurrencyValue,
                    radix,
                );

            // Clear out zero dollar values when leaving field
            // if the field does not allow zero dollar values
            if (
                zeroDollarValues.includes(cleanedUnformattedCurrencyValue) &&
                !allowsZeroDollarValue
            ) {
                setDisplayCurrencySign(false);
                setUnformattedCurrencyValue("");
                return;
            }

            setUnformattedCurrencyValue(cleanedUnformattedCurrencyValue);
        };

        const currencyOnKeyDown = (e) => {
            // If someone starts spamming keys on the input
            // characters can sometimes be placed in the incorrect position
            if (!allowInput) {
                return;
            }
            // React's SyntheticEvent needs .persist called in order for the data from the event data to be used.
            // According to the docs, this is no longer necessary after React 17
            // so this is also something we need to look at during the upgrade to React 18.
            e.persist();
            const { target } = e;

            /*
                Android's default keyboard has a problem with the "." key and the keydown event.
                It is coming back as 'Unidentified'. We are checking specifically for the radix character (".")
                to be able to set the decimal on an amount. The work around for this is to check for Android OS
                in the navigator's userAgent. If it's on Android and the key is 'Unidentified' then we are going
                to set the key as "."
            */

            const keyToEval = e.key;
            let key = keyToEval;
            if (isAndroid && keyToEval === "Unidentified") {
                key = ".";
            }

            const start = target.selectionStart;
            const prev = target.value;
            const originalLength = prev.length;
            const withoutSep = getValueWithoutSeparator(prev, separator);

            // Standard form has onKeyDown listener for custom amount
            if (typeof onKeyDown === "function") {
                onKeyDown({ key, target });
            }

            // -------------------------------------------------------------------------
            // MARK: Backspace key handling
            // -------------------------------------------------------------------------
            if (key === "Backspace") {
                // backspace used on last character
                if (originalLength === start) {
                    const newValue = withoutSep.substring(
                        0,
                        withoutSep.length - 1,
                    );
                    setUnformattedCurrencyValue(newValue);
                    return;
                }
                // backspace used on a previous character
                const newValue = `${prev}`; // mutable copy
                const newValueSplit = newValue.split("");
                // remove previous index from array
                newValueSplit.splice(start - 1, 1);
                const updatedValue = newValueSplit.join("");
                const updatedValueWithoutSep = getValueWithoutSeparator(
                    updatedValue,
                    separator,
                );
                setUnformattedCurrencyValue(updatedValueWithoutSep);
                const currentDisplayValue = getDisplayValue({
                    value: updatedValueWithoutSep,
                    radix,
                    separator,
                });

                // if a separator is removed as part of the char remove
                // we need to increase the distance to move the cursor by an extra space
                let distance = 1;
                if (prev.length - currentDisplayValue.length > 1) {
                    distance = 2;
                }
                setUpdatedSelectionStart(start - distance);
                return;
            }

            // -------------------------------------------------------------------------
            // MARK: Numeric Key handling
            // -------------------------------------------------------------------------
            if (numericKeys.includes(key)) {
                // -------------------------------------------------------------------------
                // MARK: Radix key radix handling
                // -------------------------------------------------------------------------
                const hasRadix = withoutSep.includes(radix);
                const radixIndex = prev.indexOf(radix);
                const radixSplitValue = withoutSep.split(radix);
                // Bad Radix Behavior...
                // prevent more than 2 digits after the radix
                if (
                    hasRadix &&
                    radixSplitValue[1].length >= 2 &&
                    (originalLength === start || start > radixIndex)
                ) {
                    if (start > radixIndex) {
                        setUpdatedSelectionStart(start);
                    }
                    return;
                }
                // prevent more than one radix
                const tryingToAddMoreRadix = key === radix && hasRadix;
                // prevent adding radix in the wrong spot
                const tryingToPlaceRadixInWrongSpot =
                    key === radix && !hasRadix && originalLength - start > 2;

                if (tryingToAddMoreRadix || tryingToPlaceRadixInWrongSpot) {
                    setUpdatedSelectionStart(start);
                    return;
                }

                // prevent too big of numbers from being entered
                const hundredthBillionsSpot = 14;
                const addedRadixPossibleLength = 3;
                if (
                    (!hasRadix && originalLength > hundredthBillionsSpot) ||
                    (hasRadix &&
                        originalLength >
                            hundredthBillionsSpot + addedRadixPossibleLength)
                ) {
                    setUpdatedSelectionStart(start);
                    return;
                }

                // new character added at end of input value
                if (originalLength === start) {
                    const newValue = `${withoutSep + key}`;
                    setUpdatedSelectionStart(-1);
                    setUnformattedCurrencyValue(newValue);
                    return;
                }
                // new character added within existing input value
                // use new flag to track updated cursor placement
                const splitPrev = prev
                    .split("")
                    .map((char) => ({ value: char, new: false }));
                splitPrev.splice(start, 0, { value: key, new: true });
                const indexOfNewChar = getIndexOfNewChar({
                    currentArray: splitPrev,
                    radix,
                    separator,
                });
                const updatedValueArray = splitPrev.map((char) => char.value);
                const updatedValue = updatedValueArray.join("");
                const unformattedCurrencyValueToBe = getValueWithoutSeparator(
                    updatedValue,
                    separator,
                );
                setUnformattedCurrencyValue(unformattedCurrencyValueToBe);
                setUpdatedSelectionStart(indexOfNewChar + 1);
                return;
            }
            if (!navigationKeys.includes(key)) {
                setUpdatedSelectionStart(start);
            }
        };

        /// -------------------------------------------------------------------------
        // NOTE: Visually hidden element that contains the same content as
        //       the formattedCurrencyValue. This is used to programmatically move the placeholder
        //       left when the currency input is centered and its content changes (Amounts)
        // -------------------------------------------------------------------------
        const hiddenRef = useRef();

        // update style for currency sign span based on content width
        useLayoutEffect(() => {
            if (hiddenRef.current) {
                if (centeredDisplay) {
                    const hiddenRec =
                        hiddenRef?.current?.getBoundingClientRect();

                    // Inputs use font size of 18px
                    const fontSizePlusHalf = 27;
                    let relativeValue = 0;
                    if (hiddenRec.width > 0) {
                        relativeValue =
                            (hiddenRec.width + fontSizePlusHalf) / 2;
                    } else if (formattedCurrencyValue.length > 0) {
                        // just put some numbers there and move on
                        const numberOfChars = formattedCurrencyValue.length;
                        const charSize = 10;
                        relativeValue =
                            (numberOfChars * charSize + fontSizePlusHalf) / 2;
                    } else {
                        relativeValue = fontSizePlusHalf / 2;
                    }

                    setPlaceholderInlineStyles({
                        left: `calc(50% - ${relativeValue}px)`,
                    });
                } else {
                    setPlaceholderInlineStyles({
                        left: "20px",
                    });
                }
            } else {
                setPlaceholderInlineStyles({});
            }
        }, [centeredDisplay, hiddenRef, formattedCurrencyValue]);

        return (
            <>
                {/* visually hidden container with display value */}
                <div className="hidden-display-value" ref={hiddenRef}>
                    {formattedCurrencyValue}
                </div>
                <div className="currency-input-container">
                    {(displayCurrencySign || alwaysDisplayPlaceholder) && (
                        <span
                            className={currencySignClassnames}
                            style={placeholderInlineStyles}
                        >
                            {currencySign}
                        </span>
                    )}
                    <input
                        {...remainingProps}
                        {...unformattedCurrencyValueDataProp}
                        className={inputClassnames}
                        name={name}
                        id={name}
                        type="text"
                        inputMode="decimal"
                        value={formattedCurrencyValue}
                        onChange={() => {}}
                        onKeyDown={(e) => currencyOnKeyDown(e)}
                        onFocus={currencyOnFocus}
                        onBlur={currencyOnBlur}
                        ref={ref}
                        autoComplete="off"
                    />
                </div>
            </>
        );
    },
);

CurrencyInput.propTypes = {
    allowsZeroDollarValue: PropTypes.bool,
    // Flag for currency inputs like event and p2p registration
    alwaysDisplayPlaceholder: PropTypes.bool,
    className: PropTypes.string.isRequired,
    // Flag for currency inputs like Custom Amount in form, donation form
    centeredDisplay: PropTypes.bool,
    type: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // Flag to use onChange handler for New Donation Form
    isDonationForm: PropTypes.bool,
    // Amount string that gets injected into the unformatted value
    injectedUnformattedCurrencyValue: PropTypes.string,
    // Flag to use onChange handler for MultiRestrictions
    isMultiRestrictionCustomAmount: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    // used for name [id] of multi-restriction
    // set prop to required if isMultiRestrictionCustomAmount is true
    relatedCheckboxName(props) {
        const { isMultiRestrictionCustomAmount, relatedCheckboxName } = props;
        if (isMultiRestrictionCustomAmount === true && !relatedCheckboxName) {
            return new Error(
                "relatedCheckboxName is required for multi-restriction currency amounts",
            );
        }
        return null;
    },
    updateCustomAmount: PropTypes.func,
};

export default CurrencyInput;
