import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import {
    getInitialValues,
    getInitialTouched,
    getValidationSchema,
} from "./formik";
import { accountCreate } from "../../api";
import MyAccount from "../MyAccount";
import DonorAccountForm from "./DonorAccountForm";
import "./DonorAccount.scss";

const DonorAccount = ({
    additionalText = "Additional Text",
    additionalTextMyAccountInnerHtml = "",
    controlId,
    displayAdditionalText = false,
    displayDonorAccountForm = true,
    displayMyAccount = true,
    formId = "",
    handleAccountCreationError = () => {},
    handleAccountCreationSuccess = () => {},
    heading = "Heading Text",
    headingMyAccountInnerHtml = "",
    initialValuesSettings = {},
    saveButton = "Save",
    useStandardDonationAccountCreateUrl = false,
}) => {
    // Define the form submission handler that transforms the data into the
    // shape expected by the BE and submits a network request to create a new
    // account
    const onSubmit = (values) => {
        const submitValues = {
            firstName: values.First_Name,
            lastName: values.Last_Name,
            email: values.Email,
            password: values.Password,
            password2: values.Password_Confirm,
            transaction: values.Transaction_ID,
            form: formId,
        };
        accountCreate(submitValues, useStandardDonationAccountCreateUrl)
            .then(handleAccountCreationSuccess)
            .catch(handleAccountCreationError);
    };

    const formikProps = {
        onSubmit,
        initialTouched: getInitialTouched(),
        initialValues: getInitialValues(initialValuesSettings),
        validationSchema: getValidationSchema(),
    };

    // A ternary is not used to display the donor account form instead of the
    // my account UI and vice versa as both of these elements need to be
    // displayed one on top of the other at the same time in CMS
    return (
        <div className="grid">
            {displayDonorAccountForm && (
                <div className="col col--12">
                    <div className="donor-account__content -padding--30 -text--center">
                        <div className="grid">
                            <div className="col col--12 -padding-top--0 -padding-bottom--10">
                                <h2>{heading}</h2>
                            </div>
                            {displayAdditionalText && (
                                <div className="col col--12 -padding-top--5 -margin-bottom--15">
                                    <p>{additionalText}</p>
                                </div>
                            )}
                            <Formik {...formikProps}>
                                <Form className="donor-account__form-container">
                                    <DonorAccountForm
                                        controlId={controlId}
                                        saveButton={saveButton}
                                    />
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            )}
            {displayMyAccount && (
                <div className="col col--12">
                    <div className="donor-account__content -padding--30 -text--center">
                        <div className="grid">
                            <MyAccount
                                additionalTextInnerHtml={
                                    additionalTextMyAccountInnerHtml
                                }
                                headingInnerHtml={headingMyAccountInnerHtml}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

DonorAccount.propTypes = {
    additionalText: PropTypes.string.isRequired,
    additionalTextMyAccountInnerHtml: PropTypes.string.isRequired,
    controlId: PropTypes.string.isRequired,
    displayAdditionalText: PropTypes.bool.isRequired,
    displayDonorAccountForm: PropTypes.bool.isRequired,
    displayMyAccount: PropTypes.bool.isRequired,
    formId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    handleAccountCreationSuccess: PropTypes.func.isRequired,
    handleAccountCreationError: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired,
    headingMyAccountInnerHtml: PropTypes.string.isRequired,
    initialValuesSettings: PropTypes.shape({
        transaction: PropTypes.shape({}).isRequired,
    }).isRequired,
    saveButton: PropTypes.string.isRequired,
};

export default DonorAccount;
