import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Divider.scss";

const Divider = ({
    classNames = "",
    dark,
    dashed,
    spaceAboveOnly,
    moreSpace,
}) => (
    <div className={`qg-vendor-divider divider ${classNames}`}>
        <hr
            className={cx(
                "divider__hr",
                dashed && "divider__hr--dashed",
                dark && "divider__hr--dark",
                spaceAboveOnly && "divider__hr--space-above-only",
                moreSpace && "divider__hr--more-space",
            )}
        />{" "}
    </div>
);

Divider.propTypes = {
    // Extra classnames to add to divider for customization
    classNames: PropTypes.string,
    // Makes the divider line a dashed line
    dashed: PropTypes.bool,
    // Makes the divider line darker
    dark: PropTypes.bool,
    // Adds space above but not below divider
    spaceAboveOnly: PropTypes.bool,
    // Adds more space above and below the divider
    moreSpace: PropTypes.any,
};

export default Divider;
