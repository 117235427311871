import React from "react";
import PropTypes from "prop-types";
import ConnectedSubmit from "../../Pages/AdditionalDetailsPage/Submit/ConnectedSubmit";

const AdditionalDetailsPageFooterControl = ({ controlId }) => (
    <ConnectedSubmit controlId={controlId} />
);

AdditionalDetailsPageFooterControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default AdditionalDetailsPageFooterControl;
