import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";

import "./FormStatusIcon.scss";

const FormStatusIcon = ({ type, glyph }) => {
    // Dynamically set the default image based on form status type
    const image = (
        <Icon
            type={type}
            glyph={glyph}
            classNames={["form-status-image__icon"]}
        />
    );

    return <div className="form-status-image">{image}</div>;
};

FormStatusIcon.propTypes = {
    type: PropTypes.string.isRequired,
    glyph: PropTypes.string.isRequired,
};

export default FormStatusIcon;
