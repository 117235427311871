import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import GiftAssistControl from "./GiftAssistControl";
import { selectSpecificFormSetting } from "../../../redux/slices/formSettingsSlice";

const ConnectedGiftAssistControl = ({ controlId }) => {
    const feeCoverage = useSelector((state) =>
        selectSpecificFormSetting(state, "feeCoverage"),
    );

    const isGiftAssistEnabled = feeCoverage !== 0;

    return isGiftAssistEnabled ? (
        <GiftAssistControl controlId={controlId} />
    ) : null;
};

ConnectedGiftAssistControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedGiftAssistControl;
