import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import { TextButton } from "@qgiv/core-donor";
import "./TotalGift.scss";

const TotalGift = ({
    displayRecipientData = false,
    displayRecurringFrequency = false,
    editGiftLabel = "Change",
    formattedFrequency = "",
    formattedTotal = "",
    handleChangeClick = () => {},
    selectedRecipient = {},
    totalGiftLabel = "Total Gift",
}) => (
    <div className="qg-vendor-total-gift total-gift -ui-border-dashed">
        <div className="grid">
            <div className="col col--9 col--no-top-gutter">
                <strong className="-type--small -color--system-medium-dark -text-transform--uppercase">
                    {totalGiftLabel}
                </strong>
                <br />
                <span className="-type--xxlarge -color--system-dark">
                    {formattedTotal}
                    {displayRecurringFrequency && (
                        <span className="total-gift__formatted-frequency">
                            /{formattedFrequency}
                        </span>
                    )}
                </span>
                {displayRecipientData ? (
                    <p className="-margin-top--10">
                        <strong className="-type--small -color--system-medium-dark -text-transform--uppercase">
                            Supporting
                        </strong>
                        <br />
                        <Icon
                            glyph={selectedRecipient.iconGlyph}
                            type="FontAwesome"
                            classNames={["-margin-right--10 -type--xlarge"]}
                        />
                        <span className="-type--large -color--system-dark -font-weight--bold">
                            {selectedRecipient.title}
                        </span>
                    </p>
                ) : null}
            </div>
            <div className="total-gift__button col col--3 col--no-top-gutter">
                <TextButton
                    handleClick={handleChangeClick}
                    styles="-type--small -color--theme-primary"
                >
                    <p>
                        <strong>{editGiftLabel}</strong>
                    </p>
                </TextButton>
            </div>
        </div>
    </div>
);

TotalGift.propTypes = {
    displayRecurringFrequency: PropTypes.bool,
    displayRecipientData: PropTypes.bool,
    editGiftLabel: PropTypes.string,
    formattedFrequency: PropTypes.string,
    formattedTotal: PropTypes.string,
    handleChangeClick: PropTypes.func,
    totalGiftLabel: PropTypes.string,
    selectedRecipient: PropTypes.shape({
        title: PropTypes.string,
        iconGlyph: PropTypes.string,
    }),
};

export default TotalGift;
