// https://reacttraining.com/react-router/web/guides/scroll-restoration
import React, { useEffect, useState } from "react";

const ScrollTo = React.forwardRef((props, ref) => {
    const [currentLocation, setCurrentLocation] = useState(
        window.location.pathname,
    );

    const location = window.location.pathname;

    // This replaces the useLocation hook from react-router-dom
    useEffect(() => {
        if (location !== currentLocation) {
            setCurrentLocation(location);
        }
    }, [location, currentLocation]);

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        } else {
            document.getElementById("app").scrollIntoView(true);
        }
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation]);

    return null;
});

ScrollTo.displayName = "ScrollTo";

export default ScrollTo;
