import React from "react";
import PropTypes from "prop-types";
import { constants } from "@qgiv/core-js";
import { WidgetField, WidgetFieldGroup } from "@qgiv/core-donor";

const ConfirmationFields = ({ widgetFieldOrGroupProps }) => {
    const { CMS_Control } = constants.ENUMS;

    return Array.isArray(widgetFieldOrGroupProps)
        ? widgetFieldOrGroupProps.map((widgetFieldProps = {}) => {
              const { control = {} } = widgetFieldProps;
              const { id = "", type = "" } = control;

              if (
                  Number(type) === CMS_Control.FIELD_GROUP ||
                  Number(type) === CMS_Control.STANDARD_DONATION_PRIVACY_OPTIONS
              ) {
                  return <WidgetFieldGroup key={id} {...widgetFieldProps} />;
              }

              return <WidgetField key={id} {...widgetFieldProps} />;
          })
        : null;
};

ConfirmationFields.propTypes = {
    widgetFieldOrGroupProps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ConfirmationFields;
