import React from "react";
import PropTypes from "prop-types";
import ConnectedConfirmationFieldsSubmit from "../../Pages/ReceiptPage/ConfirmationFields/ConnectedConfirmationFieldsSubmit";

const ConfirmationFieldsSubmitControl = ({ controlId }) => (
    <ConnectedConfirmationFieldsSubmit controlId={controlId} />
);

ConfirmationFieldsSubmitControl.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConfirmationFieldsSubmitControl;
