import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { constants } from "@qgiv/core-js";
import moment from "moment";
import GivingPlans from "./GivingPlans";
import { selectAllDonationSettings } from "../../../../../redux/slices/donationSettingsSlice";
import { updateGiftDetails } from "../../../../../redux/slices/donationDetailsSlice";
import { getPlanRecurringFrequency } from "./givingPlanHelpers";

/**
 * @param {object} props
 * @param {number} props.controlId
 * @returns {React.ReactElement} The GivingPlans component.
 */
const ConnectedGivingPlans = ({ controlId }) => {
    const dispatch = useDispatch();
    // Formik
    const { values, initialValues, setValues } = useFormikContext();
    // Selectors
    const {
        activePlans,
        defaultRecurringFrequency,
        givingPlanSystemType = null,
    } = useSelector(selectAllDonationSettings);
    const {
        ENUMS: { ItemType, GivingPlanSystemType },
    } = constants;

    const isInstallment =
        givingPlanSystemType === GivingPlanSystemType.FLEXIBLE;

    const createdPlans = useCallback(
        () =>
            activePlans.filter(
                (plan) =>
                    parseInt(plan.pledgeType, 10) !== ItemType.CREATE_YOUR_OWN,
            ) || {},
        [activePlans, ItemType.CREATE_YOUR_OWN],
    );

    const updateSelectedPlan = (e) => {
        const { currentTarget } = e;
        const { value } = currentTarget;
        const id = value;
        const selectedPlan =
            activePlans.find((plan) => String(plan.id) === id) || {};

        // exit early if no selected plan data
        if (Object.keys(selectedPlan).length === 0) {
            return;
        }
        const selectedPlanIsCreateYourOwnPlan =
            parseInt(selectedPlan?.pledgeType, 10) === ItemType.CREATE_YOUR_OWN;

        const {
            restriction,
            restrictionId,
            begin_date = "",
            end_date = "",
            subrestriction,
            defaultFrequencyEnum = "",
        } = selectedPlan;

        const activePlanSelectedWithRestriction =
            restriction && !selectedPlanIsCreateYourOwnPlan;

        const activePlanSelectedWithSubRestriction =
            restriction && subrestriction;

        const isCreateYourOwnPlanAndRestrictionsEnabled =
            selectedPlanIsCreateYourOwnPlan &&
            initialValues.Standard_Restriction_Name?.length > 0;

        const formikUpdateValues = {};

        // If not create your own plan, reset Selected_Recurring_ID
        if (!selectedPlanIsCreateYourOwnPlan) {
            formikUpdateValues.Selected_Recurring_Id = "";
        }

        // Selected Plan
        formikUpdateValues.Selected_Plan = Number(id);

        if (!isInstallment) {
            formikUpdateValues.Recurring_Frequency = getPlanRecurringFrequency(
                selectedPlan,
                defaultRecurringFrequency,
            );
        }

        if (isInstallment && !selectedPlanIsCreateYourOwnPlan) {
            formikUpdateValues.Recurring_Frequency = defaultFrequencyEnum;
        }

        if (isInstallment && selectedPlanIsCreateYourOwnPlan) {
            formikUpdateValues.Recurring_Frequency =
                defaultRecurringFrequency.value;
        }

        // Restrictions
        if (activePlanSelectedWithRestriction) {
            formikUpdateValues.Standard_Restriction_Id = restrictionId;
            formikUpdateValues.Standard_Restriction_Name = restriction;
            if (activePlanSelectedWithSubRestriction) {
                formikUpdateValues.Standard_Restriction_Sub_Restriction =
                    subrestriction;
            }
        }

        if (isCreateYourOwnPlanAndRestrictionsEnabled) {
            formikUpdateValues.Standard_Restriction_Name =
                initialValues.Standard_Restriction_Name;
            formikUpdateValues.Standard_Restriction_Id =
                initialValues.Standard_Restriction_Id;
            formikUpdateValues.Standard_Restriction_Sub_Restriction =
                initialValues.Standard_Restriction_Sub_Restriction;
        }

        // Start and End Dates
        formikUpdateValues.Start_Date = moment(begin_date).format("MM/DD/YYYY");
        if (!isInstallment) {
            formikUpdateValues.End_Date = end_date;
        }

        if (isInstallment) {
            formikUpdateValues.Installment_Count =
                selectedPlan.defaultNumberOfInstallments;
        }

        // update Formik Values
        setValues({ ...values, ...formikUpdateValues });
        // update Redux gift details
        dispatch(updateGiftDetails({ ...formikUpdateValues }));
    };

    return (
        <GivingPlans
            activePlans={createdPlans()}
            controlId={controlId}
            handleChange={updateSelectedPlan}
            isInstallment={isInstallment}
        />
    );
};

export default ConnectedGivingPlans;
