import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { useFormikContext } from "formik";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectIsLoggedIn } from "../../../../redux/slices/donorAccountSlice";
import { selectGiftDetails } from "../../../../redux/slices/donationDetailsSlice";
import SavePaymentMethod from "./SavePaymentMethod";

const ConnectedSavePaymentMethod = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
            PaymentType,
        },
    } = constants;

    const giftDetails = useSelector(selectGiftDetails);
    const { hasSelectedRecurringDonation } = giftDetails;

    const isLoggedIn = useSelector(selectIsLoggedIn);

    const { handleBlur, handleChange, values } = useFormikContext();
    const {
        Account_Number,
        Card_Number,
        Create_Account_Password,
        Password_Confirm,
        Password,
        Payment_Type,
        Routing_Number,
        Save_Payment = false,
    } = values;

    const hasEnteredCreditCardInfo =
        Payment_Type === PaymentType.CREDITCARD && Card_Number.length > 0;
    const hasEnteredBankInfo =
        Payment_Type === PaymentType.ECHECK &&
        Routing_Number.length > 0 &&
        Account_Number.length > 0;

    const hasEnteredAPayment = hasEnteredCreditCardInfo || hasEnteredBankInfo;

    const isLoggedInAndHasEnteredPayment = isLoggedIn && hasEnteredAPayment;

    const isCreatingAnAccount =
        !isLoggedIn &&
        !!Create_Account_Password &&
        Password.length > 0 &&
        Password_Confirm.length > 0;

    const isLoggedOutAndCreatingAnAccount =
        !hasSelectedRecurringDonation &&
        hasEnteredAPayment &&
        isCreatingAnAccount;

    const displaySavePayment =
        isLoggedInAndHasEnteredPayment || isLoggedOutAndCreatingAnAccount;

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );

    const {
        options: {
            savePaymentMethodLabel = "Securely store my payment method",
        },
    } = control;

    const savePaymentMethodProps = {
        handleBlur,
        handleChange,
        savePaymentMethodLabel,
        Save_Payment,
    };

    return displaySavePayment ? (
        <div className="grid">
            <div className="col col--12">
                <SavePaymentMethod {...savePaymentMethodProps} />{" "}
            </div>
        </div>
    ) : null;
};

ConnectedSavePaymentMethod.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedSavePaymentMethod;
