import React, { useEffect } from "react";
import propTypes from "prop-types";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import {
    composeMultiplePaths,
    initGoogleAnalytics,
    initMatomoAnalyticsTracking,
    submitErrorData,
} from "@qgiv/core-donor";
import { HistoryTracking, SimpleErrorDisplay } from "@qgiv/core-react";
import { getAllRoutesByFormType } from "@qgiv/core-donor/source/utility/router-helpers";
import ConnectedDonationFormWrappers from "./DonationFormWrappers/ConnectedDonationFormWrappers";

const RootComponent = () => (
    // -------------------------------------------------------------------------
    // NOTE: useHistory is deprecated
    //
    // Because useHistory no longer is supported in react-router-dom v6
    // we have to load the analytic tracking within the router so we can
    // make use of useLocation. The HistoryTracking component does that from the
    // core-donor package
    // -------------------------------------------------------------------------
    <>
        <HistoryTracking />
        {/* Outlet will use the router to load the child routes */}
        <Outlet />
    </>
);

const Routes = ({ formId, formType, orgId, title }) => {
    const matomo = useMatomo();

    const { donationFormPage = [] } = getAllRoutesByFormType(formType);
    // Load Google Analytics & Matomo Analytics
    useEffect(() => {
        const options = {
            formId,
            formType,
            orgId,
            title,
        };
        initGoogleAnalytics(options);
        initMatomoAnalyticsTracking(matomo, options);
    }, [formId, formType, orgId, title, matomo]);

    const donationFormPagePaths = composeMultiplePaths({
        paths: donationFormPage,
        element: <ConnectedDonationFormWrappers />,
    });

    const router = createBrowserRouter([
        {
            path: "/",
            element: <RootComponent />,
            children: [...donationFormPagePaths],
            errorElement: (
                <SimpleErrorDisplay
                    submitErrorData={submitErrorData}
                    additionalText="Please contact support."
                />
            ),
        },
    ]);

    return <RouterProvider router={router} />;
};

Routes.propTypes = {
    formId: propTypes.number.isRequired,
    formType: propTypes.number.isRequired,
    orgId: propTypes.number.isRequired,
    title: propTypes.string.isRequired,
};

export default Routes;
