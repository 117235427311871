import React from "react";
import PropTypes from "prop-types";

const RecurringImpactStatement = ({
    classes = "",
    formattedRecurringImpactStatement,
    recurringImpactStatementHeading,
}) => (
    <div className={`${classes} recurring-impact`}>
        {recurringImpactStatementHeading.length > 0 && (
            <p className="recurring-impact__heading">
                <strong>{recurringImpactStatementHeading}</strong>
            </p>
        )}
        {formattedRecurringImpactStatement.length > 0 && (
            <p className="recurring-impact__statement">
                {formattedRecurringImpactStatement}
            </p>
        )}
    </div>
);

RecurringImpactStatement.propTypes = {
    classes: PropTypes.string,
    formattedRecurringImpactStatement: PropTypes.string.isRequired,
    recurringImpactStatementHeading: PropTypes.string.isRequired,
};

export default RecurringImpactStatement;
