// this type of selector wouldn't normally be brought over to a separate file
// but the other selectors used this one, so it was brought over so that
// we use the same function everywhere
export const selectDonorAccountSelector = (state) => state.donorAccount;

export const selectIsLoggedInSelector = (state) => {
    const donorAccount = selectDonorAccountSelector(state);
    const hasDonorAccountsEnabled = state?.formSettings?.enableDonorLogins;

    const isLoggedIn =
        hasDonorAccountsEnabled && Object.keys(donorAccount).length > 0;
    return isLoggedIn;
};

export const selectHasStoredPaymentMethodsSelector = (state) => {
    const donorAccount = selectDonorAccountSelector(state);
    const isLoggedIn = selectIsLoggedInSelector(state);
    const hasStoredPaymentMethods =
        isLoggedIn &&
        donorAccount.paymentInfo &&
        donorAccount.paymentInfo.length > 0;

    return hasStoredPaymentMethods;
};
