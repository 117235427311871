import { constants, translateOldFieldType } from "@qgiv/core-js";

/**
 * @public
 * @function getFieldValue
 * @description Determines the starting value of a given field
 * @param {object} field The field object
 * @param {string|number} field.type The type of field
 * @returns {string|boolean} A given field's initial value
 */
export const getFieldValue = (field) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    const { type } = field;
    const fieldType = translateOldFieldType(type);
    let value = "";
    switch (Number(fieldType)) {
        case FieldType.CHECKBOX: {
            const { checked_by_default } = field;
            value = checked_by_default === "1" || checked_by_default === 1;
            break;
        }
        case FieldType.MULTIPLE_SELECTION: {
            // -------------------------------------------------------------------------
            // NOTE: Multiple selection values are to be stored liked this:
            //      {
            //          ...values,
            //          [field id]: [option1, option2, option3]
            //      }
            // -------------------------------------------------------------------------
            value = [];
            break;
        }
        case FieldType.HIDDEN_FIELD: {
            const { hidden_value } = field;
            value = hidden_value;
            break;
        }
        default:
            break;
    }
    return value;
};

/**
 *
 * @param {object} field All of the settings for a particular field.
 * @param {object} urlShortcuts All the key and values passed in using the URL.
 * @returns {boolean} Any potential value that is passed in for the field being checked.
 */
const getCustomFieldValueFromUrl = (field, urlShortcuts) => {
    const reportingLabel = field?.ref || "";
    const customFieldDataInUrlShortcut = urlShortcuts?.customfields || {};
    const hasReportingLabel = Object.keys(
        customFieldDataInUrlShortcut,
    ).includes(reportingLabel);

    if (hasReportingLabel) {
        return customFieldDataInUrlShortcut[reportingLabel];
    }

    return "";
};

/**
 *
 * @param {object} field Object that represents a custom field.
 * @param {object} urlShortcuts Object that contains any URL params passed into the URL.
 * @returns {string|boolean} Value to load into Formik.
 */
export const getCustomFieldUrlShortcutValue = (field, urlShortcuts) => {
    let value = "";
    const {
        ENUMS: { FieldType },
    } = constants;

    if (urlShortcuts && urlShortcuts.customfields) {
        const { type = "" } = field;
        // Only check a checkbox if yes is passed in as the value
        const validCheckboxShortcut = "yes";
        // Right now we are only adding support for field types that are
        // being used by Mercy Ships as the lack of support for these specific
        // field types is preventing them from upgrading some of their forms
        const isSupportedFieldType =
            Number(type) === FieldType.CHECKBOX ||
            Number(type) === FieldType.DROPDOWN ||
            Number(type) === FieldType.HIDDEN_FIELD ||
            Number(type) === FieldType.PARAGRAPH_TEXT ||
            Number(type) === FieldType.SHORT_TEXT;

        const valueFromUrl = getCustomFieldValueFromUrl(field, urlShortcuts);

        // Convert the value to a boolean if the field is a checkbox
        if (
            valueFromUrl &&
            isSupportedFieldType &&
            Number(type) === FieldType.CHECKBOX
        ) {
            value = valueFromUrl === validCheckboxShortcut;
        } else if (valueFromUrl && isSupportedFieldType) {
            value = valueFromUrl;
        }
    }

    return value;
};

/**
 * @public
 * @function getMatchingGiftsInitialValues
 * @param {string|boolean} enableMatching Enable matching setting from form settings
 * @param {number} matchingType P2P setting for Manual or Auto, not passed for Standard Form
 * @returns {object} Matching gifts initial values
 */
export const getMatchingGiftsInitialValues = (enableMatching, matchingType) => {
    if (enableMatching === "1" || enableMatching === true) {
        if (matchingType === 1) {
            // Manual Matching Gifts
            return {
                Match_Gift: false,
                Employer: "",
                Matching_Gift_Amount: "",
            };
        } else {
            return {
                // Automatic Matching Gifts
                // Double the Donation
                Matching_Company_Name: "",
                Matching_Company_ID: "",
                Matching_Parent_Raw_ID: "",
                Matching_Contact_Address_Line_1: "",
                Matching_Contact_Address_Line_2: "",
                Matching_Contact_City: "",
                Matching_Contact_State: "",
                Matching_Contact_Zip: "",
                Matching_Contact_Phone: "",
                Matching_Contact_Email: "",
                // Cyber grants only
                Matching_Donor_Found: "",
                Matching_Donor_Verified: false,
            };
        }
    } else {
        return {
            Matching_Gift: {
                Matching_Donation_ID: "",
                Company_ID: "",
                Company_Name: "",
            },
        };
    }
};

/**
 * @public
 * @function getOptInInitialValue
 * @param {Array} systemFields Array of pre-filtered system fields
 * @returns {boolean} Initial value for opt in
 */
export const getOptInInitialValue = (systemFields) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    let value = false;
    const field = systemFields.find(
        (field) => Number(field.type) === FieldType.OPT_IN,
    );
    if (field) {
        const { checked_by_default } = field;
        value = checked_by_default === "1";
    }
    return value;
};

/**
 * @public
 * @function getPaymentTypeInitialValue
 * @param {object} formSettings State object with most of the form's settings
 * @param {boolean} formSettings.enableAchPayments Does the form accept eChecks
 * @param {boolean} formSettings.enableCCPayments Does the form accept credit cards
 * @param {boolean} formSettings.enablePaypalPayments Does the form accept PayPal
 * @param {object} donorAccount Optional donor account data
 * @param {Array} donorAccount.paymentInfo Array of stored payment methods
 * @description Determines which payment type should be selected on load
 *              based on which payment types are available
 * @returns {number} PaymentType enum
 */
export const getPaymentTypeInitialValue = (
    { enableAchPayments, enableCCPayments, enablePaypalPayments },
    // We need donor account to check for stored payment methods
    donorAccount = {},
) => {
    const { PaymentType } = constants.ENUMS;
    if (
        Object.keys(donorAccount).length > 0 &&
        donorAccount.paymentInfo &&
        donorAccount.paymentInfo.length > 0
    ) {
        return PaymentType.STORED;
    } else {
        return enableCCPayments
            ? PaymentType.CREDITCARD
            : enableAchPayments
              ? PaymentType.ECHECK
              : enablePaypalPayments
                ? PaymentType.PAYPAL
                : 0;
    }
};

/**
 * @public
 * @function getBillingNameDisplayStatus
 * @param {Array} allFieldsAndGroups Array of objects containing form settings pertaining to the event
 * @description Extracts Billing_Name status from allFieldsAndGroups
 * @returns {boolean} Passed as parameter in getDonorDetailsInitialValues and getDonorDetailsValues
 */
export const getBillingNameDisplayStatus = (allFieldsAndGroups) => {
    const {
        ENUMS: {
            Status: { ACTIVE },
            FieldType: { BILLING_NAME },
        },
    } = constants;
    const findBillingNameObject =
        allFieldsAndGroups &&
        allFieldsAndGroups.length > 0 &&
        allFieldsAndGroups.find((field) => Number(field.type) === BILLING_NAME);
    const billingNameField = findBillingNameObject ? findBillingNameObject : {};
    const isBillingNameDisplayed =
        billingNameField &&
        Object.keys(billingNameField).length > 0 &&
        Number(billingNameField.status) === ACTIVE
            ? true
            : false;
    return isBillingNameDisplayed;
};

/**
 * @public
 * @function getPersonalInfoUrlShortcutValues
 * @param {object} [urlShortcuts] Url shortcuts object
 * @description Given an object of url shortcuts,
 *              infer initial values for personal details
 * @returns {object} Spread this object over all other personal info values
 */
export const getPersonalInfoUrlShortcutValues = (urlShortcuts) => {
    const getPersonalInfoFieldName = (shortcutKey) => {
        switch (shortcutKey) {
            case "first_name":
                return "First_Name";
            case "last_name":
                return "Last_Name";
            case "address1":
                return "Address";
            case "address2":
                return "Address_2";
            case "city":
                return "City";
            case "state":
                return "State";
            case "zip":
                return "Zip";
            case "company":
                return "Employer";
            case "phone":
                return "Phone";
            case "email":
                return "Email";
            default:
                return;
        }
    };

    if (urlShortcuts && urlShortcuts.v) {
        return Object.keys(urlShortcuts.v).reduce((values, key) => {
            return {
                ...values,
                [getPersonalInfoFieldName(key)]: urlShortcuts.v[key],
            };
        }, {});
    } else {
        return {};
    }
};

/**
 * @public
 * @function getPersonalInfoPromiseTransaction
 * @param {object} [promiseTransaction] promise transaction object
 * @description Given an object of promiseTransaction,
 *              infer initial values for personal details
 * @returns {object} Spread this object over all other personal info values
 */
export const getPersonalInfoPromiseTransaction = (promiseTransaction) => {
    if (Object.keys(promiseTransaction).length > 0) {
        const getPersonalInfoFieldNamePR = (fieldKey) => {
            switch (fieldKey) {
                case "first_name":
                    return "First_Name";
                case "last_name":
                    return "Last_Name";
                case "address1":
                    return "Address";
                case "address2":
                    return "Address_2";
                case "city":
                    return "City";
                case "state":
                    return "State";
                case "zip":
                    return "Zip";
                case "company":
                    return "Employer";
                case "phone":
                    return "Phone";
                case "email":
                    return "Email";
                case "token":
                    return "Invoice_Token";
                case "salutation":
                case "employer":
                case "amount":
                case "invoiceCreated":
                case "country":
                case "recurring":
                case "amtPledged":
                case "nextBill":
                case "finalBill":
                case "frequency":
                case "remainingInvoices":
                    return false;
                default:
                    return;
            }
        };
        return Object.keys(promiseTransaction).reduce((values, key) => {
            if (getPersonalInfoFieldNamePR(key) === false) {
                return { ...values };
            }
            return {
                ...values,
                [getPersonalInfoFieldNamePR(key)]: promiseTransaction[key],
            };
        }, {});
    } else {
        return {};
    }
};

/**
 * @public
 * @function getStoredPaymentInitialValues
 * @param {object} donorAccount The donor account object
 * @description Determines initial values for
 *              Stored_Payment and Stored_Payment_ID
 * @returns {object} Spread this object into the initial values object
 */
export const getStoredPaymentInitialValues = (donorAccount) => {
    const { paymentInfo = [] } = donorAccount;
    if (paymentInfo.length > 0) {
        return {
            Stored_Payment: true,
            Stored_Payment_ID: paymentInfo[0].id,
        };
    } else {
        return {
            Stored_Payment: false,
            Stored_Payment_ID: "",
        };
    }
};

/**
 * @public
 * @function getDonorDetailsValues
 * @param {object} donorAccount Object returned when
 *                              logging in/creating a donor account
 * @param {Array} systemFields Array of visible system fields
 * @param {string} countryInitial String representing initial country
 * @param {boolean} isBillingNameDisplayed true/false representing billing_name display settings
 * @returns {object} Donor details values object
 */
export const getDonorDetailsValues = (
    donorAccount,
    systemFields,
    countryInitial,
    isBillingNameDisplayed,
) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    const getDonorDetail = (detail, fieldType) => {
        let isFieldVisible = true;
        // When a system field is inactive it is not included in the list of
        // system fields delivered to the FE
        if (fieldType && systemFields && systemFields.length) {
            isFieldVisible = systemFields.find((systemField) => {
                return Number(systemField.type) === fieldType;
            });
        }

        // set the default value to the passed in email address for system email field.
        let defaultValue = "";
        if (fieldType === FieldType.SYSTEM_EMAIL && window.location.search) {
            defaultValue =
                new URLSearchParams(window.location.search)?.get("email") ?? "";
        }

        // Fill the field with data when it is visible
        // We want these values to be strings so they can be
        // used by imask, otherwise they will throw an error.
        return isFieldVisible &&
            donorAccount?.contact &&
            donorAccount.contact[detail]
            ? `${donorAccount.contact[detail]}`
            : defaultValue;
    };

    return {
        Donor_Account_ID: donorAccount?.userId ? donorAccount.userId : "",
        Employer: getDonorDetail("organization", FieldType.EMPLOYER),
        First_Name: getDonorDetail("firstName", FieldType.FIRST_NAME),
        Last_Name: getDonorDetail("lastName", FieldType.LAST_NAME),
        Email: getDonorDetail("email", FieldType.SYSTEM_EMAIL),
        Address: getDonorDetail("address", FieldType.ADDRESS),
        Address_2: getDonorDetail("address2", FieldType.ADDRESS_2),
        City: getDonorDetail("city", FieldType.CITY),
        State: getDonorDetail("state", FieldType.STATE),
        Zip: getDonorDetail("zip", FieldType.ZIP),
        Country:
            getDonorDetail("country", FieldType.COUNTRY).length > 0
                ? getDonorDetail("country", FieldType.COUNTRY)
                : countryInitial,
        Phone: getDonorDetail("phone", FieldType.SYSTEM_PHONE), // 47
        // If BillingName Display is toggled on return donor name || first + last else return empty string
        Billing_Name: isBillingNameDisplayed
            ? getDonorDetail("name", FieldType.BILLING_NAME).length
                ? getDonorDetail("name", FieldType.BILLING_NAME)
                : getDonorDetail("firstName", FieldType.BILLING_NAME) +
                  (donorAccount.contact && donorAccount.contact.firstName
                      ? " "
                      : "") +
                  getDonorDetail("lastName", FieldType.BILLING_NAME)
            : "",
    };
};
