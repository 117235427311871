import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getFormValuesFromDonorAccount,
    onDonorAccountChangeSetFormik,
} from "@qgiv/donation-form";
import { useFormikContext } from "formik";
import { log } from "@qgiv/core-js";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import {
    selectDonorAccount,
    setDonorAccount,
} from "../../../redux/slices/donorAccountSlice";
import {
    selectConfig,
    selectDirectLinkLoginUrl,
} from "../../../redux/slices/configSettingsSlice";
import {
    selectAllFieldsInSystemFields,
    selectDefaultCountry,
    selectHasBillingNameField,
} from "../../../redux/slices/fieldsSlice";
import { sendEmbedLoginMessage } from "../../PostMessage/postMessage";
import DonorIsLoggedOut from "./DonorIsLoggedOut";

const ConnectedDonorIsLoggedOut = () => {
    const dispatch = useDispatch();
    const { id } = useSelector(selectAllFormSettings);
    const { userId = "" } = useSelector(selectDonorAccount);
    const { values = {}, setValues = () => {} } = useFormikContext() ?? {};
    const systemFields = useSelector(selectAllFieldsInSystemFields);
    const defaultCountry = useSelector(selectDefaultCountry);
    const billingNameDisplayedData = useSelector(selectHasBillingNameField);
    const isBillingNameDisplayed = billingNameDisplayedData?.exists;
    const directLinkLoginUrl = useSelector(selectDirectLinkLoginUrl);

    const {
        HTTP_HOST = "",
        isLoadingConfirmationPageDirectly = false,
        embed = {},
    } = useSelector(selectConfig);

    const donorAccountId = userId;

    const handleSignInClick = () => {
        const isDeployedAsWidget = embed && embed?.id && embed?.url;
        // When donation form is viewed without a landing page
        // (via Transaction Details or My Account Dashboard),
        // the sign in button does not work. In this case we will
        // redirect them to account log in page in a new tab.
        // TODO: We will be adding Recurring Summary Page to P2P
        // and will need to account for this here as
        // isLoadingConfirmationPageDirectly does not consider
        // the Recurring Summary Page. See FormReceiptBase/Login/ConnectedLogin.js
        // line 30 for implementation.
        if (isLoadingConfirmationPageDirectly) {
            const url = `${HTTP_HOST}/account/login`;
            window.open(url, "_blank");
        } else if (isDeployedAsWidget && directLinkLoginUrl) {
            window.top.location.href = directLinkLoginUrl;
        } else {
            sendEmbedLoginMessage(id);
        }
    };

    // on successful sign in, update state with donor data
    const handleSignIn = ({ data, origin }) => {
        // make sure data is passed
        if (data && typeof data === "string" && data !== "recaptcha-setup") {
            try {
                const { donorAccount } = JSON.parse(data);
                // make sure data came from the correct source, and that donor account data exists
                if (
                    origin === HTTP_HOST &&
                    donorAccount &&
                    donorAccount.contact
                ) {
                    // Update the donorAccount key in the Redux store after the user signs in
                    // to or out of their donor account
                    dispatch(setDonorAccount(donorAccount));

                    const formValuesFromDonorAccount =
                        getFormValuesFromDonorAccount({
                            donorAccount,
                            systemFields,
                            countryInitial: defaultCountry,
                            isBillingNameDisplayed,
                        });

                    if (values) {
                        onDonorAccountChangeSetFormik(
                            formValuesFromDonorAccount,
                            setValues,
                            values,
                        );
                    }
                }
            } catch (err) {
                log("trace", err);
            }
        }
    };

    // Adds an event listener that will run handleSignIn logic regardless of
    // where the sign in message was emitted from
    if (donorAccountId.length === 0) {
        window.addEventListener("message", handleSignIn);
    }

    const iconData = {
        glyph: "user-circle-regular",
        type: "FontAwesome",
        size: "regular",
    };

    const donorIsLoggedOutProps = {
        donorActionLinkLabel: "Sign In",
        handleLinkClick: handleSignInClick,
        iconData,
    };

    return <DonorIsLoggedOut donorIsLoggedOutProps={donorIsLoggedOutProps} />;
};

export default ConnectedDonorIsLoggedOut;
