import { useContext } from "react";

import ImageSliderContext from "../../components/ImageSlider/ImageSliderContext";

// Hook
/**
 * @function useImageSliderContext
 * @description React hook for passing the activeImageKey through the component
 *      tree without having to pass props manually at every level. Both the
 *      thumbnail and content will need to track this value.
 * @returns {number} Current activeImageKey
 */
export default function useImageSliderContext() {
    const context = useContext(ImageSliderContext);
    if (!context) {
        throw new Error(
            "ImageSlider components are compound components. Must be used inside ImageSlider.",
        );
    }
    return context;
}
